export class JsFluOppContactStatusModel {
    regionNumber: number;
    areaNumber: number;
    districtNumber: number;
    fromDate: Date
    toDate: Date
    outreachEffort: number;
    contacted: number;
    status: string;
    state: string;
    pageIndex:number;
}