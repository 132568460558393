import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionDetails } from '../../../../utility/session';
import { HeaderserviceService } from '../../../common/services/headerservice.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../../environments/environment';
import { UserRole } from '../../../../config-files/UserRole';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  siteMaintenanceDlg:boolean=false;
  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : (!environment.isSiteDeploying)?true:false;
  pageName: string = "landing";
  opportunitiesContacted: any;
  clinicsScheduled: any;
  vaccinesAdministered: any;
  srEventsScheduled: any;
  isDashboard: boolean = false;
  userProfile: any;
  loadAPI: Promise<any>;
  constructor(private router: Router, private spinner: NgxSpinnerService, private _headerservice: HeaderserviceService, private service: HeaderserviceService) { 
    document.body.style.overflow ="hidden";
    window.scrollTo(0,0);
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
      if(environment.isSiteDeploying){
        this.siteMaintenanceDlg=true;
        this.spinner.hide();
      }
  });

  }


  ngOnInit() {
    this.userProfile = SessionDetails.GetUserProfile();
        sessionStorage.removeItem('Old_immunizationForm');
    
    if(this.userProfile.userName==='sm_schedule.appointment'){
      return;
    }
    if(environment.isSiteDeploying){
      this.spinner.hide();
    }else{
      this.spinner.show();
    }
    SessionDetails.setIsPCA(false);
    SessionDetails.setIsSR(false);
    this.service.getWagsOutreachCounts().subscribe((data) => {
      this.opportunitiesContacted = data.opportunitiesContacted;
      this.clinicsScheduled = data.clinicsScheduled;
      this.vaccinesAdministered = data.vaccinesAdministered;
      this.srEventsScheduled = data.srEventsScheduled;
      let store_id = SessionDetails.GetStoreId()
      this._headerservice.getStoresProfile(this.userProfile.userPk, null, (store_id)?store_id:null).subscribe((res:any) => {
        if(res.errorS && res.errorS.errorCode===-1){         
          this.spinner.hide();
        }
        else{
          SessionDetails.setStoreProfileData(res.data);
          SessionDetails.StoreId(res.data[0].storeId);
          SessionDetails.StorePk(res.data[0].storePk);
          SessionDetails.setState(res.data[0].state);
          this.spinner.hide();          
        }
        document.body.style.overflow = "";
      });
    });

    if (this.userProfile.userRole.toLowerCase() == UserRole.STOREMANAGER || this.userProfile.userRole.toLowerCase() == UserRole.PHARMACYMANAGER) {
      this.isDashboard = true;
    }
    else {
      this.isDashboard = false;
    }
  }
  GoToDashboard() {
    if(this.userProfile.userName==='sm_schedule.appointment'){
      return;
    }
    if (this.userProfile.userRole.toLowerCase() === UserRole.DISTRICTMANAGER ||
      this.userProfile.userRole.toLowerCase() === UserRole.DIRECTORPHARMACYRETAILOPERATIONS || 
      this.userProfile.userRole.toLowerCase() === UserRole.DIRECTOR || 
      this.userProfile.userRole.toLowerCase() === UserRole.HEALTHCARESUPERVISOR || 
      this.userProfile.userRole.toLowerCase() === UserRole.REGIONALVICEPRESIDENT || 
      this.userProfile.userRole.toLowerCase() === UserRole.REGIONALHEALTHCAREDIRECTOR || 
      this.userProfile.userRole.toLowerCase() === UserRole.ADMIN) {
      this.router.navigate(['/communityoutreach/home']);
    }

    if (this.userProfile.userRole.toLowerCase() === UserRole.STOREMANAGER || this.userProfile.userRole.toLowerCase() === UserRole.PHARMACYMANAGER) {
      this.router.navigate(['/communityoutreach/storehome']);
    }
  }

  GoToOpportunities() {
    if(this.userProfile.userName==='sm_schedule.appointment'){
      return;
    }
    SessionDetails.SetStoreOppSortByDefaultVal('0');
    if (this.userProfile.userRole.toLowerCase() === UserRole.DISTRICTMANAGER || 
    this.userProfile.userRole.toLowerCase() === UserRole.DIRECTORPHARMACYRETAILOPERATIONS || 
    this.userProfile.userRole.toLowerCase() === UserRole.HEALTHCARESUPERVISOR || 
    this.userProfile.userRole.toLowerCase() === UserRole.REGIONALVICEPRESIDENT || 
    this.userProfile.userRole.toLowerCase() === UserRole.REGIONALHEALTHCAREDIRECTOR || 
    this.userProfile.userRole.toLowerCase().toLowerCase() === UserRole.ADMIN || 
    this.userProfile.userRole.toLowerCase() === UserRole.STOREMANAGER || 
    this.userProfile.userRole.toLowerCase() === UserRole.PHARMACYMANAGER) {
      this.router.navigateByUrl('/communityoutreach/storehome');
    }
  }
  GoTo() {
    if(this.userProfile.userName==='sm_schedule.appointment'){
      return;
    }
    SessionDetails.SetStoreOppSortByDefaultVal('0');
    if (this.userProfile.userRole.toLowerCase() === UserRole.DISTRICTMANAGER || 
    this.userProfile.userRole.toLowerCase() === UserRole.DIRECTORPHARMACYRETAILOPERATIONS || 
    this.userProfile.userRole.toLowerCase() === UserRole.HEALTHCARESUPERVISOR || 
    this.userProfile.userRole.toLowerCase() === UserRole.REGIONALVICEPRESIDENT || 
    this.userProfile.userRole.toLowerCase() === UserRole.REGIONALHEALTHCAREDIRECTOR|| 
    this.userProfile.userRole.toLowerCase().toLowerCase() === UserRole.ADMIN)
    {
      this.router.navigate(['/communityoutreach/home']);
    } 
    else if(this.userProfile.userRole.toLowerCase() === UserRole.STOREMANAGER || 
    this.userProfile.userRole.toLowerCase() === UserRole.PHARMACYMANAGER) {
      this.router.navigate(['/communityoutreach/storehome']);
    }
  }

  GoToSR(){
    if(this.userProfile.userName==='sm_schedule.appointment'){
      return;
    }
    SessionDetails.setIsSR(true);
    if (this.userProfile.userRole.toLowerCase() === UserRole.DISTRICTMANAGER || 
    this.userProfile.userRole.toLowerCase() === UserRole.DIRECTORPHARMACYRETAILOPERATIONS || 
    this.userProfile.userRole.toLowerCase() === UserRole.HEALTHCARESUPERVISOR || 
    this.userProfile.userRole.toLowerCase() === UserRole.REGIONALVICEPRESIDENT || 
    this.userProfile.userRole.toLowerCase() === UserRole.REGIONALHEALTHCAREDIRECTOR || 
    this.userProfile.userRole.toLowerCase().toLowerCase() === UserRole.ADMIN || 
    this.userProfile.userRole.toLowerCase() === UserRole.STOREMANAGER || 
    this.userProfile.userRole.toLowerCase() === UserRole.PHARMACYMANAGER) {
      this.router.navigate(['/communityoutreach/srhome']);
    }
  }
    
  GoToPCA() {
    if(this.userProfile.userName==='sm_schedule.appointment'){
      return;
    }
    if (this.userProfile.userRole.toLowerCase() === UserRole.DISTRICTMANAGER || 
    this.userProfile.userRole.toLowerCase() === UserRole.DIRECTORPHARMACYRETAILOPERATIONS || 
    this.userProfile.userRole.toLowerCase() === UserRole.HEALTHCARESUPERVISOR || 
    this.userProfile.userRole.toLowerCase() === UserRole.REGIONALVICEPRESIDENT || 
    this.userProfile.userRole.toLowerCase() === UserRole.REGIONALHEALTHCAREDIRECTOR || 
    this.userProfile.userRole.toLowerCase().toLowerCase() === UserRole.ADMIN) {
      SessionDetails.setIsPCA(true);
      SessionDetails.setIsSR(false);
      this.router.navigate(['/communityoutreach/pcadashboard']);
    } else if(this.userProfile.userRole.toLowerCase() === UserRole.STOREMANAGER || 
    this.userProfile.userRole.toLowerCase() === UserRole.PHARMACYMANAGER) {
      SessionDetails.setIsPCA(true);
      SessionDetails.setIsSR(false);
      this.router.navigate(['/communityoutreach/pcahome']);
    }
  }
 
  public loadScript() {        
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') !== null && scripts[i].getAttribute('src').includes("loader")) {
            isFound = true;
        }
    }

    if (!isFound) {
        var dynamicScripts = ["../../../../../assets/js/scripts.js"];

        for (let i = 0; i < dynamicScripts .length; i++) {
            let node = document.createElement('script');
            node.src = dynamicScripts [i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }

    }
}

signout(){
  sessionStorage.clear();
  this.router.navigateByUrl("/signout");
}
}

