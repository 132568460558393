export const ipDates = {
  "2013": {
    "startDate": "05/30/2013",
    "endDate": "04/30/2014"
  },
  "2014": {
    "startDate": "05/01/2014",
    "endDate": "05/20/2015"
  },
  "2015": {
    "startDate": "05/21/2015",
    "endDate": "05/25/2016"
  },
  "2016": {
    "startDate": "05/26/2016",
    "endDate": "04/30/2017"
  },
  "2017": {
    "startDate": "05/01/2017",
    "endDate": "06/10/2018"
  },
  "2018": {
    "startDate": "06/11/2018",
    "endDate": "05/06/2019"
  },
  "2019": {
    "startDate": "05/07/2019",
    "endDate": "04/30/2020"
  },
  "2020": {
    "startDate": "05/01/2020",
    "endDate": "04/30/2021"
  },
  "2021": {
    "startDate": "05/01/2021",
    "endDate": "04/30/2022"
  },
  "2022": {
    "startDate": "05/01/2022",
    "endDate": "04/30/2023"
  },
  "2023": {
    "startDate": "05/01/2023",
    "endDate": "04/30/2024"
  },
  "2024": {
    "startDate": "05/01/2024",
    "endDate": "04/30/2025"
  }
}
export const srDates = {
  "2013": {
    "startDate": "09/01/2013",
    "endDate": "08/31/2014"
  },
  "2014": {
    "startDate": "09/01/2014",
    "endDate": "08/31/2015"
  },
  "2015": {
    "startDate": "09/01/2015",
    "endDate": "08/31/2016"
  },
  "2016": {
    "startDate": "09/01/2016",
    "endDate": "08/31/2017"
  },
  "2017": {
    "startDate": "09/01/2017",
    "endDate": "08/31/2018"
  },
  "2018": {
    "startDate": "09/01/2018",
    "endDate": "08/31/2019"
  },
  "2019": {
    "startDate": "09/01/2019",
    "endDate": "08/31/2020"
  },
   "2020": {
    "startDate": "09/01/2020",
    "endDate": "08/31/2021"
  },
  "2021": {
   "startDate": "09/01/2021",
   "endDate": "08/31/2022"
 },
 "2022": {
  "startDate": "09/01/2022",
  "endDate": "08/31/2023"
},
"2023": {
  "startDate": "09/01/2023",
  "endDate": "08/31/2024"
},
"2024": {
   "startDate": "09/01/2024",
  "endDate": "08/31/2025"
}
}

export const covidDates = {
  "2020": {
    "startDate": "09/01/2020",
    "endDate": "08/31/2021"
  },
  
  "2021": {
    "startDate": "09/01/2021",
    "endDate": "08/31/2022"
  },
  "2022": {
    "startDate": "05/01/2022",
    "endDate": "04/30/2023"
  }
}

export const fluOppContactStatusDates = {
  "2022": {
    "startDate": "01/01/2022",
    "endDate": "12/31/2022"
  },
  "2023": {
    "startDate": "01/01/2023",
    "endDate": "12/31/2023"
  },
  "2024": {
    "startDate": "01/01/2024",
    "endDate": "12/31/2024"
  }
}