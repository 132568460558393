import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { OutreachProgramService } from '../../services/outreach-program.service';
import { DatePipe } from '@angular/common';
import {
  NgForm,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Message } from 'primeng/api';
import { OutReachProgramType } from '../../../../models/OutReachPrograms';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { states } from '../../../../JSON/States';
import { Util } from '../../../../utility/util';
import { SessionDetails } from '../../../../utility/session';
import { NgxSpinnerService } from "ngx-spinner";
import { ClinicalRequirementsImmunizations, ClinicalRequirementsImmunizationsContent, ClinicalRequirementsImmunizationsChkBoxContent, ClinicalRequirementInstruction } from '../../../../config-files/ClinicalRequirements';
import { String, StringBuilder } from 'typescript-string-operations';
import { Router } from '@angular/router';
import { ActionType } from '../../../../utility/enum';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-immunizations',
  templateUrl: './immunizations.component.html',
  styleUrls: ['./immunizations.component.css'],
  providers: [DatePipe]
})
export class ImmunizationsComponent implements OnInit, OnDestroy {
  immunizationForm: FormGroup;
  immClinicalRequirements: FormGroup;
  clinicImmQtyList: any[] = [];
  immunizations: any[];
  isInvoiceInformationProvided: boolean = false;
  isVoucherNeededDisable: boolean = false;
  isAmendAgreement: boolean = false;
  isNewAmendAgreement: boolean = false;
  isCopay: boolean = false;
  boundImmunizations: any[];
  selectedImmunization: any;
  selectedPaymentType: any;
  addedImmunizations: any[] = [];
  addedImmunizationsClinicalReqs: any[] = [];
  InvoiceInformation: any[] = [];
  PaymentTypes: any[] = [];
  price: any;
  sendInvoiceTo: boolean = false;
  contract: any;
  message: string;
  formq: NgForm;
  msgs: Message[] = [];
  errorse: any;
  errorstatus: any;
  states: any[];
  isVoucher: any = 0;
  isFluType: boolean = false;
  copay: any = "";
  display: boolean = false;
  displayDesMisMatchModal: boolean = false;
  dialogSummary: string;
  dialogMsg: string;
  today = new Date();
  fluvoucherExpirationDate: Date = new Date(this.today.getFullYear() + 1, this.today.getMonth(), this.today.getDate(), 12, 55, 55);
  flumaxDateValue: Date = this.fluvoucherExpirationDate;
  fluminDateValue: Date = new Date();
  routinevoucherExpirationDate: Date = new Date(this.today.getFullYear() + 1, this.today.getMonth(), this.today.getDate(), 12, 55, 55);
  covidvoucherExpirationDate: Date = new Date(this.today.getFullYear() + 1, this.today.getMonth(), this.today.getDate(), 12, 55, 55);
  isCovidVaccineAvailable: boolean = false;
  isCovidVoucherAvailable: boolean = false;
  isVoucherAvailable: boolean = false;
  contractedOpportunity;
  routinemaxDateValue: Date = this.routinevoucherExpirationDate;
  routineminDateValue: Date = new Date();
  @Input('outReachProgramType')
  outReachProgramType: string;
  @Input('fromCommunityOutreach') fromCommunityOutreach: boolean = false;
  isVoucherFieldNeeded = false;
  everGreenValue: any = '';
  displayDialog: boolean = false;
  paymentType: any;
  edit_button: boolean = false;
  btnContinue: boolean = true;
  btnUpdate: boolean = false;
  sortorderCount: number = 0;
  immunizationPk: any;
  rowIndex: any;
  clinic_agreement_pk: any = 0;
  validateLocForm: boolean = false;
  immunization_data: any[];
  immunization_data1: any[];
  cancel_check: boolean = false;
  ccRegex: RegExp = /^(?:[0-9]+(?:\.[0-9]{0,2})?)?$/;
  usZipCodeRegEx: RegExp = /^\d{5}$/;
  usPhoneNoRegEx: RegExp = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
  usFaxNoRegEx: RegExp = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
  showSendInvoiceTodialog = true;
  isEditClick = false;
  voucherExpirationDate: Date = null;
  clinicalRequirementsDiv: boolean = false;
  clinicalRequirementInstruction: string = ClinicalRequirementInstruction;
  CanAddNewImmunzsModal: boolean = false;
  canAddNewImmunzsSelected: boolean = false;
  CanAddNewImmunzsModalContinue: boolean = false;
  @Output() clinicSubmit: EventEmitter<number> = new EventEmitter();
  isAmendAgreementForCommOutreach: boolean;
  isApiCallSucceed: boolean = false;
  canDisplayExpiryDates = false;

  constructor(
    private formBuilder: FormBuilder, 
    private _localContractService: OutreachProgramService, 
    private router: Router,
    private utility: Util, 
    private datePipe: DatePipe, 
    private spinner: NgxSpinnerService
  ) {
    let date = new Date();
    this.fluvoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 12, 55, 55);
    this.flumaxDateValue = this.fluvoucherExpirationDate;
    this.fluminDateValue = date;
    this.routinevoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 12, 55, 55);
    this.routineminDateValue = date;
  }
  
  ngOnDestroy(): void {
    if(sessionStorage.getItem('Old_immunizationForm') !== null){
      sessionStorage.removeItem('Old_immunizationForm');
    }
  }

  ngOnInit() {
    //
    let date = new Date();
    this.routinevoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 12, 55, 55);
  
    this.routinemaxDateValue = new Date(this.routinevoucherExpirationDate);
    this.routinemaxDateValue.setDate(this.routinemaxDateValue.getDate() + 1);
    

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    this.spinner.show();
    this.formControls();
    this._localContractService.clearSelectedInvoiceAndVouchersData();
    this.clinic_agreement_pk = SessionDetails.GetAgreementPK();
    if (this.clinic_agreement_pk === null || this.clinic_agreement_pk === undefined || this.clinic_agreement_pk === "null") {
      this.spinner.hide();
      return;
    }
    if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
      this.isVoucherFieldNeeded = false;
    }
    let clinicAgreementPk: number = this.clinic_agreement_pk;
    if (this.outReachProgramType === OutReachProgramType.communityoutreach && sessionStorage["AmendAgreementForCommOutreach"] != "true") {
      clinicAgreementPk = 0;
    }

    if (sessionStorage["AmendAgreement"] && sessionStorage["AmendAgreement"] === "true") {
      this.isAmendAgreement = true;
    }

    if (sessionStorage["AmendAgreementForCommOutreach"] && sessionStorage["AmendAgreementForCommOutreach"] === "true") {
      this.isAmendAgreementForCommOutreach = true;
    }

    if (sessionStorage["NewAmendAgreement"] && sessionStorage["NewAmendAgreement"] === "true") {
      this.isNewAmendAgreement = true;
    }

    this._localContractService.getImmunizationsList(clinicAgreementPk, 1).subscribe((data: any) => {
      if (this.fromCommunityOutreach) {

        this.boundImmunizations = data.immunizationList.filter(item => item.isActive == true && item.paymentTypeName.toLowerCase() != "off-site: corporate to invoice employer directly");
        //bug 122
        this.boundImmunizations = this.boundImmunizations.filter(item => item.isActive == true && item.immunizationName.toLowerCase() != "covid19 vaccine voucher");
        this.boundImmunizations = this.boundImmunizations.filter(item => item.isActive == true && item.paymentTypeId !== 13);
      }
      else {
        this.boundImmunizations = data.immunizationList.filter(item => item.isActive == true);
      }
      this.immunizations = this.boundImmunizations;

      this.contractedOpportunity = SessionDetails.GetopportunitiesData();

      // we need to get contract details only for Contract not for CO.
      if (this.outReachProgramType == OutReachProgramType.contracts) {
        this.spinner.show();
        setTimeout(() => {
          this.getContractAgreementData(this.clinic_agreement_pk, this.isNewAmendAgreement);
        }, 3000)
        //this.spinner.hide();
      }
      else if (this.outReachProgramType == OutReachProgramType.communityoutreach) {

        this._localContractService.getContractAgreementData(this.clinic_agreement_pk, this.isNewAmendAgreement).subscribe((data: any) => {
          if (this.isAmendAgreementForCommOutreach) {
            this.spinner.hide();
            for (var i = 0; i < data.clinicImmunizationList.length; i++) {
              data.clinicImmunizationList[i].isSaved = true;
            }
            if (this.addedImmunizations.length <= 0) {
              this.addedImmunizations = data.clinicImmunizationList;
            } else {
              this.addedImmunizations = this._localContractService.getImmunizationsData();
            }
            this.setClinicalRequirementFlags();

            this._localContractService.SetIscanceled(false);
            this._localContractService.setCOSelectedImmunizations(data.clinicImmunizationList);

          }

          // old logic
          this.addedImmunizations = this._localContractService.getImmunizationsData();
          if (this.outReachProgramType == OutReachProgramType.communityoutreach && sessionStorage["AmendAgreementForCommOutreach"] == "true") {
            this.addedImmunizations = this._localContractService.getCOSelectedImmunizations();
          }
          this._localContractService.SetIscanceled(false);
          if (this.outReachProgramType == OutReachProgramType.communityoutreach) {
            this.immunization_data = Object.assign([], this._localContractService.getCOSelectedImmunizations());
          } else {
            this.immunization_data = Object.assign([], this._localContractService.getSelectedImmunizations());
          }
          if (this.addedImmunizations != null) {
            this.addedImmunizations.forEach(rec => {
              // handling of true verb getting from service so that data is populate correctly.
              rec.isTaxExempt >= 1 || rec.isTaxExempt === true ? rec.isTaxExempt = 1 : rec.isTaxExempt = 0;
              rec.isCopay >= 1 || rec.isCopay === true ? rec.isCopay = 1 : rec.isCopay = 0;
              rec.isVoucherNeeded >= 1 || rec.isVoucherNeeded === true ? rec.isVoucherNeeded = 1 : rec.isVoucherNeeded = 0;
              const index = this.boundImmunizations.findIndex(item => item.immunizationId == rec.immunizationPk && item.paymentTypeId == rec.paymentTypeId)
              if (index > -1) {
                this.boundImmunizations.splice(index, 1);
              }
            });
          }
          this.displayInvoiceInfo();
          this.setClinicalRequirementFlags();
          this.spinner.hide();
        });

      }
      else if (this.outReachProgramType == OutReachProgramType.equityevents) {

 
        this._localContractService.getContractAgreementData(this.clinic_agreement_pk, this.isNewAmendAgreement).subscribe((data: any) => {
          if (this.isAmendAgreementForCommOutreach) {
            this.spinner.hide();
             
            for (var i = 0; i < data.clinicImmunizationList.length; i++) {
              data.clinicImmunizationList[i].isSaved = true;
            }
            if (this.addedImmunizations.length <= 0) {
              this.addedImmunizations = data.clinicImmunizationList;
            } else {
              this.addedImmunizations = this._localContractService.getImmunizationsData();
            }
            this.setClinicalRequirementFlags();

            this._localContractService.SetIscanceled(false);
            this._localContractService.setCOSelectedImmunizations(data.clinicImmunizationList);

          }

          // old logic
          this.addedImmunizations = this._localContractService.getImmunizationsData();
          if (this.outReachProgramType == OutReachProgramType.equityevents && sessionStorage["AmendAgreementForCommOutreach"] == "true") {
             
            this.addedImmunizations = this._localContractService.getCOSelectedImmunizations();
          }
          this._localContractService.SetIscanceled(false);
          if (this.outReachProgramType == OutReachProgramType.equityevents) {
            this.immunization_data = Object.assign([], this._localContractService.getCOSelectedImmunizations());
          } else {
            this.immunization_data = Object.assign([], this._localContractService.getSelectedImmunizations());
          }
          if (this.addedImmunizations != null) {
            this.addedImmunizations.forEach(rec => {
              // handling of true verb getting from service so that data is populate correctly.
              rec.isTaxExempt >= 1 || rec.isTaxExempt === true ? rec.isTaxExempt = 1 : rec.isTaxExempt = 0;
              rec.isCopay >= 1 || rec.isCopay === true ? rec.isCopay = 1 : rec.isCopay = 0;
              rec.isVoucherNeeded >= 1 || rec.isVoucherNeeded === true ? rec.isVoucherNeeded = 1 : rec.isVoucherNeeded = 0;
              const index = this.boundImmunizations.findIndex(item => item.immunizationId == rec.immunizationPk && item.paymentTypeId == rec.paymentTypeId)
              if (index > -1) {
                this.boundImmunizations.splice(index, 1);
              }
            });
          }
          this.displayInvoiceInfo();
          this.setClinicalRequirementFlags();
          this.spinner.hide();
        });

      }
      if (!this.isAmendAgreementForCommOutreach)
        //this.spinner.hide();
        this.isApiCallSucceed = true;
    },
      err => {
        this.spinner.hide();
        switch (err) {
          case 500:
            this.showDialog(ErrorMessages['serverError'], err.json().Message);
            return false;
          case 400:
            this.showDialog(ErrorMessages['clientError'], err.json().Message);
            return false;
          default:
            this.showDialog(ErrorMessages['serverError'], err.json().Message);
            return false;
        }
      });

    this.states = states;
  }

  public get OutReachProgramType(): typeof OutReachProgramType {
    return OutReachProgramType;
  }

  setClinicalRequirementFlags() {
    this.addedImmunizations.forEach(imm => {

      let immunizationChkBxPresent = this.getIndexFromClinicaReqArray(imm.immunizationName);
      if (ClinicalRequirementsImmunizations.indexOf(imm.immunizationName) > -1) {
        this.clinicalRequirementsDiv = true;
      }
      if (immunizationChkBxPresent === -1) {
        let validationsReqd: boolean = false;
        let immClinicalRequirementsText: string = '';
        let immClinicalRequirementsChkBxText: string = '';

        if (ClinicalRequirementsImmunizations.indexOf(imm.immunizationName) > -1) {
          validationsReqd = true;
          immClinicalRequirementsText = ClinicalRequirementsImmunizationsContent[imm.immunizationName];
          immClinicalRequirementsChkBxText = ClinicalRequirementsImmunizationsChkBoxContent[imm.immunizationName];
        }

        let cliniclReqFA: FormArray = <FormArray>this.immunizationForm.controls['clinicalReqs'];
        const fg = this.formBuilder.group({
          immunizationId: this.formBuilder.control(imm.immunizationId, null),
          immunizationName: this.formBuilder.control(imm.immunizationName, null),
          isClinicalReqd: this.formBuilder.control(imm.isClinicalReqd != true ? null : imm.isClinicalReqd, validationsReqd ? [Validators.required] : null)
        });
        cliniclReqFA.push(fg);
        this.addedImmunizationsClinicalReqs.push({
          "immunizationId": imm.immunizationId,
          "immunizationName": imm.immunizationName,
          "immunizationSpanishName": imm.immunizationSpanishName,
          "isClinicalReqd": imm.isClinicalReqd,
          "immClinicalRequirementsText": immClinicalRequirementsText,
          "immClinicalRequirementsChkBxText": immClinicalRequirementsChkBxText
        });
      }
    });
  }

  displayInvoiceInfo() {
    if (this.addedImmunizations.length > 0) {
      let corp = [];
      if (this.addedImmunizations.filter(c => c.paymentTypeId == "13").length != 0) {
        corp = this.addedImmunizations.filter(c => c.paymentTypeId == "13");
        this.isVoucherFieldNeeded = true;
        this.isVoucher = true;
      }
      else {
        corp = this.addedImmunizations.filter(c => c.paymentTypeId == "6" || c.paymentTypeId == "7" || c.paymentTypeId == "11");
        this.isVoucherFieldNeeded = false;
        this.isVoucher = true;
      }
      this.addedImmunizations.forEach((element) => {
        element.isVoucher = '1'
      });

      if (corp.length > 0) {
        let routineExpDateLst = corp.filter(c => c.immunizationName.toLowerCase().search('flu') == -1)
        let flueExpDateLst = corp.filter(c => c.immunizationName.toLowerCase().search('flu') != -1)
        let date = new Date();
        let routinevoucherExpirationDate;
        let fluvoucherExpirationDate;
        let covidvoucherExpirationDate;
        const default_routine_covid_exp_date = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 18, 55, 55);
        const default_flu_exp_date = new Date(date.getFullYear() + 1, 3, 1, 12, 55, 55);
        
        this.isCovidVaccineAvailable = corp.filter(c => c.immunizationName.toLowerCase().search('covid') !== -1).length > 0;
        this.isCovidVoucherAvailable = this.isCovidVaccineAvailable && corp.filter(c => c.immunizationName.toLowerCase().search('voucher') !== -1).length > 0;
        
        if (routineExpDateLst.length > 0) {
          routinevoucherExpirationDate = routineExpDateLst[0].voucherExpirationDate;
          covidvoucherExpirationDate = routineExpDateLst[0].voucherExpirationDate;
        }
        else {
          if(sessionStorage.getItem('Old_immunizationForm')){
            const oldImmunizationFormValue = JSON.parse(sessionStorage.getItem('Old_immunizationForm'));
            routinevoucherExpirationDate = oldImmunizationFormValue.routineexpirationDate ? oldImmunizationFormValue.routineexpirationDate : default_routine_covid_exp_date;
            covidvoucherExpirationDate = oldImmunizationFormValue.fluexpirationDate ? oldImmunizationFormValue.fluexpirationDate : default_routine_covid_exp_date;
          }
          else{
            routinevoucherExpirationDate = default_routine_covid_exp_date;
            covidvoucherExpirationDate = default_routine_covid_exp_date;
          }
        }
        
        if (flueExpDateLst.length > 0) {
          fluvoucherExpirationDate = flueExpDateLst[0].voucherExpirationDate
        }
        else {
          if (date.getMonth() == 0 || date.getMonth() == 1 || date.getMonth() == 2) {
            fluvoucherExpirationDate = new Date(date.getFullYear(), 3, 1, 12, 55, 55);
          }
          else {
            if(sessionStorage.getItem('Old_immunizationForm')){
              const oldImmunizationFormValue = JSON.parse(sessionStorage.getItem('Old_immunizationForm'));
              fluvoucherExpirationDate = oldImmunizationFormValue?.fluexpirationDate ? oldImmunizationFormValue?.fluexpirationDate : default_flu_exp_date;
            }
            else{
              fluvoucherExpirationDate = default_flu_exp_date;
            }
          }
        }

        if (corp != null && corp.length > 0 && this.outReachProgramType === OutReachProgramType.contracts) {

          this.showSendInvoiceTodialog = false;
          this.isInvoiceInformationProvided = true;
          this.InvoiceInformation.push({
            "paymentTypeId": corp[0].paymentTypeId,
            "sendInvoiceTo": corp[0].sendInvoiceTo,
            "name": corp[0].name,
            "phone": corp[0].phone,
            "fax": corp[0].fax,
            "email": corp[0].email,
            "address1": corp[0].address1,
            "address2": corp[0].address2,
            "city": corp[0].city,
            "isTaxExempt": corp[0].isTaxExempt,
            "state": corp[0].state,
            "zip": corp[0].zip,
            "isCopay": corp[0].isCopay,
            "copayValue": corp[0].copayValue,
            "isVoucherNeeded": this.isVoucherFieldNeeded ? '1' : '0',
            "fluvoucherExpirationDate": this.isVoucherFieldNeeded == true ? fluvoucherExpirationDate : null,
            "routinevoucherExpirationDate": this.isVoucherFieldNeeded == true ? routinevoucherExpirationDate : null,
            "covidvoucherExpirationDate": this.isVoucherFieldNeeded == true ? covidvoucherExpirationDate : null,
          });
        }
        if (this.InvoiceInformation.length > 0) {
          this._localContractService.setInvoiceAndVoucherData(this.InvoiceInformation);

          if (this.isVoucherFieldNeeded) {
            this.canDisplayExpiryDates = true;
          }

          this.immunizationForm.controls['name'].setValue(corp[0].name);
          this.immunizationForm.controls['phone'].setValue(corp[0].phone);
          this.immunizationForm.controls['fax'].setValue(corp[0].fax);
          this.immunizationForm.controls['email1'].setValue(corp[0].email);
          this.immunizationForm.controls['email2'].setValue(corp[0].email);
          this.immunizationForm.controls['address1'].setValue(corp[0].address1);
          this.immunizationForm.controls['address2'].setValue(corp[0].address2);
          this.immunizationForm.controls['city'].setValue(corp[0].city);
          this.immunizationForm.controls['state'].setValue(corp[0].state);
          this.immunizationForm.controls['zip'].setValue(corp[0].zip);
          this.immunizationForm.controls['isTaxExempt'].setValue(corp[0].isTaxExempt);
          this.immunizationForm.controls['isVoucherNeeded'].setValue(this.isVoucherFieldNeeded ? '1' : '0');
          this.immunizationForm.controls['copayValue'].setValue(corp[0].copayValue);
          this.immunizationForm.controls['isCopay'].setValue(corp[0].isCopay);
          this.immunizationForm.controls['fluexpirationDate'].setValue(new Date(fluvoucherExpirationDate));
          this.immunizationForm.controls['routineexpirationDate'].setValue(new Date(routinevoucherExpirationDate));
        }
      }
    }
    
  }

  getContractAgreementData(clinic_agreement_pk, isNewAmendAgreement) {
    this._localContractService.fetchContractAgreementData$().subscribe((data: any) => {

      for (var i = 0; i < data.clinicImmunizationList.length; i++) {
        data.clinicImmunizationList[i].isSaved = true;
      }
      if (this.addedImmunizations.length <= 0) {
        this.addedImmunizations = data.clinicImmunizationList;
      } else {
        //
        this.addedImmunizations = this._localContractService.getImmunizationsData();
      }
      this.setClinicalRequirementFlags();

      this._localContractService.SetIscanceled(false);
      this.immunization_data = Object.assign([], this._localContractService.getSelectedImmunizations());
      this._localContractService.setImmunizationsData(data.clinicImmunizationList);

      if (data.isStoreBusinessClinicAdded) {
        sessionStorage["isStoreBusinessClinicAdded"] = "true";
      }
      if (this.addedImmunizations != null) {
        this.addedImmunizations.forEach(rec => {
          // handling of true verb getting from service so that data is populate correctly.
          rec.isTaxExempt >= 1 || rec.isTaxExempt === true ? rec.isTaxExempt = 1 : rec.isTaxExempt = 0;
          rec.isCopay >= 1 || rec.isCopay === true ? rec.isCopay = 1 : rec.isCopay = 0;
          rec.isVoucherNeeded >= 1 || rec.isVoucherNeeded === true ? rec.isVoucherNeeded = 1 : rec.isVoucherNeeded = 0;
          const index = this.boundImmunizations.findIndex(item => item.immunizationId == rec.immunizationPk && item.paymentTypeId == rec.paymentTypeId)

          // const ind = this.boundImmunizations.findIndex(item => item.immunizationId == this.selectedPaymentType[0].immunizationId && item.paymentTypeId == this.selectedPaymentType[0].paymentTypeId);

          if (index > -1) {
            this.boundImmunizations.splice(index, 1);
          }
        });


        if (sessionStorage["AmendAgreement"] && sessionStorage["AmendAgreement"] === "true") {
          if (this.addedImmunizations.length > 0) {
            sessionStorage["OldImmunizations"] = JSON.stringify(this.addedImmunizations);
            this.clinicImmQtyList = [];
            data.clinicList.forEach((loc, index) => {
              loc.clinicImzQtyList.forEach(ele => {
                ele.clinicPk = null;
                this.clinicImmQtyList.push(ele);
              });
            });

            if (this.clinicImmQtyList.length > 0) {
              sessionStorage["clinicList"] = JSON.stringify(this.clinicImmQtyList);
            }

            let corp = this.addedImmunizations.filter(c => c.paymentTypeId == "13");
            if (corp.length > 0 && corp[0].isVoucherNeeded)
              this.isVoucherNeededDisable = true;
          }
        }
      }
      this.displayInvoiceInfo();

      this.spinner.hide();
      this.isApiCallSucceed = true;
    },
      err => {
        this.spinner.hide();
        switch (err) {
          case 500:
            this.showDialog(ErrorMessages['serverError'], err.json().Message);
            return false;
          case 400:
            this.showDialog(ErrorMessages['clientError'], err.json().Message);
            return false;
          default:
            this.showDialog(ErrorMessages['serverError'], err.json().Message);
            return false;
        }
      });
  }

  formControls() {
    if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
      this.immunizationForm = this.formBuilder.group({
        immunizationSelected: ['', Validators.required],
        paymentsSelected: ['', Validators.required],
        name: ['', null],
        phone: ['', null],
        fax: ['', null],
        address1: ['', null],
        address2: [null, null],
        email1: [null, null],
        email2: [null, null],
        city: [null, null],
        state: ['', null],
        zip: ['', null],
        isTaxExempt: ['', null],
        isCopay: ['', null],
        copayValue: ['', null],
        isVoucherNeeded: ['', null],
        fluexpirationDate: [this.fluvoucherExpirationDate, null],
        routineexpirationDate: [this.routinevoucherExpirationDate, null],
        // covidexpirationDate: [this.routinevoucherExpirationDate, null],
        clinicalReqs: this.formBuilder.array([])
      });
    }
    else if (this.outReachProgramType === OutReachProgramType.equityevents) {
      this.immunizationForm = this.formBuilder.group({
        immunizationSelected: ['', Validators.required],
        paymentsSelected: ['', Validators.required],
        name: ['', null],
        phone: ['', null],
        fax: ['', null],
        address1: ['', null],
        address2: [null, null],
        email1: [null, null],
        email2: [null, null],
        city: [null, null],
        state: ['', null],
        zip: ['', null],
        isTaxExempt: ['', null],
        isCopay: ['', null],
        copayValue: ['', null],
        isVoucherNeeded: ['', null],
        fluexpirationDate: [this.fluvoucherExpirationDate, null],
        routineexpirationDate: [this.routinevoucherExpirationDate, null],
        // covidexpirationDate: [this.routinevoucherExpirationDate, null],
        clinicalReqs: this.formBuilder.array([])
      });
    }
    else {
      this.immunizationForm = this.formBuilder.group({
        immunizationSelected: ['', Validators.required],
        paymentsSelected: ['', Validators.required],
        // everGreen: [this._localContractService.getEverGreenData(), Validators.required],
        everGreen: [''],
        name: [null, Validators.required],
        phone: [null, Validators.required],
        fax: ['', Validators.required],
        address1: ['', Validators.required],
        address2: [null, null],
        email1: [null, Validators.required],
        email2: [null, Validators.required],
        city: [null, Validators.required],
        state: ['', Validators.required],
        zip: [null, Validators.required],
        isTaxExempt: ['', Validators.required],
        isCopay: ['', null],
        copayValue: ['', null],
        isVoucherNeeded: ['', null],
        fluexpirationDate: [this.fluvoucherExpirationDate, null],
        routineexpirationDate: [this.routinevoucherExpirationDate, null],
        // covidexpirationDate: [this.routinevoucherExpirationDate, null],
        clinicalReqs: this.formBuilder.array([])
      });
    }
  }

  ImmChanged(immunizationId: any) {
    //
    this.immunizationForm.controls['paymentsSelected'].setValue('');
    this.price = '';
    this.PaymentTypes = [];
    this.paymentType = '0';
    this.sortorderCount = 2;
    var re = /COVID19/gi;
    //
 
    this.PaymentTypes = this.boundImmunizations.filter(p => p.immunizationId == immunizationId);

    this.PaymentTypes.forEach((element) => {
      if (element.paymentTypeName == 'Off-Site: Corporate to Invoice Employer Directly') {

        element.SortOrder = 1
      }
      else if (element.paymentTypeName == 'Voucher: Corporate to Invoice Employer Directly') {
        element.SortOrder = 2
      }
      else {
        element.SortOrder = this.sortorderCount + 1

      }


    });


    let isCovidVaccineAvailable: number = this.PaymentTypes.filter(item => item.immunizationName.search(re) >= 0).length;
    let isFluVaccineAvailable = this.PaymentTypes.filter(item => item.immunizationName.toLowerCase().search(/flu/) >= 0).length;
    let isRoutineuVaccineAvailable = this.PaymentTypes.filter(item => item.immunizationName.toLowerCase().search(/flu/) == -1 && item.immunizationName.search(re) == -1).length;
    let isPfizerVaccineAvailable = this.PaymentTypes.filter(item => item.immunizationName.search(/Pfizer/) >= 0).length;
    let isModernaVaccineAvailable = this.PaymentTypes.filter(item => item.immunizationName.search(/Moderna/) >= 0).length;
    let isCovidVoucher = this.PaymentTypes.filter(item => item.immunizationName.includes('Vaccine Voucher')).length;
    let isJohnson = this.PaymentTypes.filter(item => item.immunizationName.includes('Johnson')).length;

    let isTYPHOID = this.PaymentTypes.filter(item => item.immunizationName.includes('TYPHOID (Live - Vivotif Typhoid)')).length;

    if (this.addedImmunizations.length > 0) {

      isCovidVaccineAvailable += this.addedImmunizations.filter(item => item.immunizationName.search(re) >= 0).length;
      this.isCovidVoucherAvailable = this.isCovidVoucherAvailable ? this.isCovidVoucherAvailable : this.isCovidVaccineAvailable && isCovidVoucher > 0;
      isFluVaccineAvailable += this.addedImmunizations.filter(item => item.immunizationName.toLowerCase().search(/flu/) >= 0).length;
      isRoutineuVaccineAvailable += this.addedImmunizations.filter(item => item.immunizationName.toLowerCase().search(/flu/) == -1 && item.immunizationName.search(re) == -1).length;
      isPfizerVaccineAvailable += this.addedImmunizations.filter(item => item.immunizationName.search(/Pfizer/) >= 0).length;
      isModernaVaccineAvailable += this.addedImmunizations.filter(item => item.immunizationName.search(/Moderna/) >= 0).length;
      isJohnson += this.addedImmunizations.filter(item => item.immunizationName.includes('Johnson')).length;
    }
 
    if (isCovidVaccineAvailable <= 0) {
      //this.PaymentTypes = this.PaymentTypes.filter(p=>p.paymentTypeName !=='HRSA (Uninsured)' && p.paymentTypeName != 'Off-Site: Corporate to Invoice Employer Directly'); //Temporarily remove the "Off-Site: Corporate to Invoice Employer Directly"
      if (isRoutineuVaccineAvailable > 0)
        this.PaymentTypes = this.PaymentTypes.filter(p => p.paymentTypeName !== 'HRSA (Uninsured)'); //Temporarily remove the "Off-Site: Corporate to Invoice Employer Directly"
      else {
        this.PaymentTypes = this.PaymentTypes.filter(p => p.paymentTypeName !== 'HRSA (Uninsured)'); //Temporarily remove the "Off-Site: Corporate to Invoice Employer Directly"
      }
    }
    if (isCovidVaccineAvailable > 0) {
      this.PaymentTypes = this.PaymentTypes.filter(p => p.paymentTypeName !== 'HRSA (Uninsured)');
    }
    if (isCovidVoucher > 0) {
      this.PaymentTypes = this.PaymentTypes.filter(p => p.paymentTypeName == 'Off-Site: Corporate to Invoice Employer Directly');
    }

    // if(isTYPHOID>0)
    // {

    //   this.PaymentTypes = this.PaymentTypes.filter(p=>p.paymentTypeName !=='Corporate to Invoice Employer Directly'); 
    // }

    if(this.outReachProgramType !== OutReachProgramType.equityevents  )
    {
      this.PaymentTypes = this.PaymentTypes.filter(p => p.paymentTypeName !== 'WAG Free Flu Voucher' );
    }
    else if(this.outReachProgramType == OutReachProgramType.equityevents && immunizationId!='31') 
    {
      this.PaymentTypes = this.PaymentTypes.filter(p => p.paymentTypeName !== 'WAG Free Flu Voucher' );
    }

    this.immunizationForm.controls['immunizationSelected'].updateValueAndValidity();
    this.immunizationForm.controls['paymentsSelected'].updateValueAndValidity();
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  checkUIValidations(ctrlName: string, index: number) {
    return {
      'has-error': this.isClinicalReqsFieldValid(ctrlName, index),
      'has-feedback': this.isClinicalReqsFieldValid(ctrlName, index)
    };
  }
  isFieldValid(field: string) {
    if (this.immunizationForm.get(field) != null)
      return !this.immunizationForm.get(field).valid && this.immunizationForm.get(field).touched;
  }
  isClinicalReqsFieldValid(ctrlName: string, index: number) {
    let isClinicalReqd = false;
    let clinicalReqsFA: FormArray = (<FormArray>this.immunizationForm.controls['clinicalReqs']);
    if (clinicalReqsFA.length > 0) {
      let fg: FormGroup = (<FormGroup>(clinicalReqsFA.controls[index]));
      isClinicalReqd = !fg.get(ctrlName).valid && fg.get(ctrlName).touched;
    }
    return isClinicalReqd;
  }
  PmtChanged(paymentTypeId: any) {

    this.paymentType = paymentTypeId;
    if (paymentTypeId != 0) {
      if (paymentTypeId == 6 || paymentTypeId == '6') {
        this.price = (this.PaymentTypes.filter(p => p.paymentTypeId == paymentTypeId)[0].directBillPrice);
      }
      else if (paymentTypeId == 13 || paymentTypeId == '13') {
        if (this.PaymentTypes.filter(p => p.paymentTypeId == paymentTypeId)[0].voucherBillPrice != undefined) {
          this.price = (this.PaymentTypes.filter(p => p.paymentTypeId == paymentTypeId)[0].voucherBillPrice);
        }
        else {
          this.price = (this.PaymentTypes.filter(p => p.paymentTypeId == paymentTypeId)[0].price);
        }
      }
      else {
        this.price = (this.PaymentTypes.filter(p => p.paymentTypeId == paymentTypeId)[0].price);
      }

      if (this.price == null) {
        this.price = "N/A";
      }

      //if(paymentTypeId==6 || paymentTypeId ==13 || paymentTypeId=='6' || paymentTypeId =='13')
      // {
      // }
      this.sendInvoiceTo = true;
      this.isVoucher = 1; //added by Rukesh

      this.selectedPaymentType = this.PaymentTypes.filter(p => p.paymentTypeId == paymentTypeId)
    }
    else {
      this.price = "";
      this.sendInvoiceTo = false;
    }
    this.immunizationForm.controls['immunizationSelected'].updateValueAndValidity();
    this.immunizationForm.controls['paymentsSelected'].updateValueAndValidity();
  }
  addimmunizationData() {
    //
    this.edit_button = false;
    if (this.selectedPaymentType != undefined && this.selectedPaymentType[0] !== undefined && this.selectedPaymentType[0].immunizationId > 0 && this.sendInvoiceTo) {

      if (this.immunizationForm.value.email1 != null && this.immunizationForm.value.email2 != null) {
        if (this.immunizationForm.value.email1.toLowerCase() != this.immunizationForm.value.email2.toLowerCase()) {
          let dtlMsg = ErrorMessages['noMatchingEmails'];
          let err = ErrorMessages['errMsg'];
          return;
        }
      }
    }

    if (this.sendInvoiceTo && this.isVoucher === 1) {
      if (this.selectedPaymentType[0].immunizationName.toLowerCase().search('flu') === -1) {
        this.voucherExpirationDate = this.immunizationForm.value.routineexpirationDate;
      }
      else if (this.selectedPaymentType[0].immunizationName.toLowerCase().search('flu') === -1 && !this.isCovidVaccineAvailable) {
        this.voucherExpirationDate = this.immunizationForm.value.routineexpirationDate;
      }
      else {
        this.voucherExpirationDate = this.immunizationForm.value.fluexpirationDate;
      }
    }

    if (this.selectedPaymentType == undefined) {
      if (this.addedImmunizations.length > 0) {
        let isInvoiceExist = false;
        let isVoucherExist = false;
        console.log(this.addedImmunizations);

        let filterData = this.addedImmunizations.find(x => x.paymentTypeName.toLowerCase().search('voucher'));
        if (this.addedImmunizations.find(x => x.paymentTypeId == 13) != undefined) {
          let count = this.addedImmunizations.find(x => x.paymentTypeId == 13);
          if (count != null)
            isVoucherExist = true;

        }
        else {
          isVoucherExist = false;
        }

        if (this.addedImmunizations.find(x => x.paymentTypeId == 6).paymentTypeId == 6) {
          isInvoiceExist = true;
        }
        //
        if (!isVoucherExist && isInvoiceExist) {
          // alert("Invoice exist");
          let corp = this.addedImmunizations.filter(c => c.paymentTypeId == "13");
          if (corp != null && corp.length > 0) {

            this.showSendInvoiceTodialog = false;
            this.isInvoiceInformationProvided = true;
            this.InvoiceInformation = [];
            this.InvoiceInformation.push({
              "paymentTypeId": corp[0].paymentTypeId,
              "sendInvoiceTo": corp[0].sendInvoiceTo,
              "name": corp[0].name,
              "phone": corp[0].phone,
              "fax": corp[0].fax,
              "email": corp[0].email,
              "address1": corp[0].address1,
              "address2": corp[0].address2,
              "city": corp[0].city,
              "isTaxExempt": corp[0].isTaxExempt,
              "state": corp[0].state,
              "zip": corp[0].zip,
              "isCopay": corp[0].isCopay,
              "copayValue": corp[0].copayValue,
              "isVoucherNeeded": false,
              "fluvoucherExpirationDate": null,
              "routinevoucherExpirationDate": null,
              "covidvoucherExpirationDate": null,
            });
          }
          this.isVoucherNeededDisable = true;
          if (this.InvoiceInformation.length > 0) {
            this._localContractService.setInvoiceAndVoucherData(this.InvoiceInformation);
          }
        }
      }
    }
    else if (this.selectedPaymentType[0] !== undefined && this.selectedPaymentType[0].immunizationId > 0) {
      this.selectedPaymentType.forEach(element => {
        if (element.paymentTypeName.toLowerCase().search('voucher') > -1) {
          this.isVoucherAvailable = true;
        }
      });

      this.isCovidVoucherAvailable = this.isCovidVoucherAvailable ? this.isCovidVoucherAvailable : this.isCovidVaccineAvailable && this.selectedPaymentType[0].immunizationName.toLowerCase().search('voucher') > -1;
      if (this.selectedPaymentType[0].immunizationName.toLowerCase().search('flu') === -1 &&
        this.isCovidVaccineAvailable && this.isCovidVoucherAvailable) {
        this.voucherExpirationDate = this.immunizationForm.value.routineexpirationDate;
      }
      if (this.selectedPaymentType && this.selectedPaymentType[0] !== undefined && this.selectedPaymentType[0] !== null) {
        let cliniclReqFA: FormArray = <FormArray>this.immunizationForm.controls['clinicalReqs'];
        let validationsReqd: boolean = false;
        let immClinicalRequirementsText: string = '';
        let immClinicalRequirementsChkBxText: string = '';
        if (ClinicalRequirementsImmunizations.indexOf(this.selectedPaymentType[0].immunizationName) > -1) {
          validationsReqd = true;
          immClinicalRequirementsText = ClinicalRequirementsImmunizationsContent[this.selectedPaymentType[0].immunizationName];
          immClinicalRequirementsChkBxText = ClinicalRequirementsImmunizationsChkBoxContent[this.selectedPaymentType[0].immunizationName];
        }

        if (!(this.addedImmunizations.find(imm => imm.immunizationName == this.selectedPaymentType[0].immunizationName) != null)) {
          const fg = this.formBuilder.group({
            immunizationId: this.formBuilder.control(this.selectedPaymentType[0].immunizationId, null),
            immunizationName: this.formBuilder.control(this.selectedPaymentType[0].immunizationName, null),
            isClinicalReqd: this.formBuilder.control(null, validationsReqd ? [Validators.required] : null)
          });
          cliniclReqFA.push(fg);
          if (!this.addedImmunizationsClinicalReqs.some(clinicalReqs => clinicalReqs.immunizationId == this.selectedPaymentType[0].immunizationId)) {
            this.addedImmunizationsClinicalReqs.push({
              "immunizationId": this.selectedPaymentType[0].immunizationId,
              "immunizationName": this.selectedPaymentType[0].immunizationName,
              "immunizationSpanishName": this.selectedPaymentType[0].immunizationSpanishName,
              "isClinicalReqd": null,
              "immClinicalRequirementsText": immClinicalRequirementsText,
              "immClinicalRequirementsChkBxText": immClinicalRequirementsChkBxText
            });
          }
        }
      }

      let is_voucher_needed_imz = this.InvoiceInformation.filter(item => item.sendInvoiceTo == 1);
      //alert(this.selectedPaymentType[0].voucherBillPrice);

      if (!this.addedImmunizations.some(immunization => immunization.immunizationPk == this.selectedPaymentType[0].immunizationId
        && immunization.paymentTypeId == this.selectedPaymentType[0].paymentTypeId)) {
        this.addedImmunizations.push({
          "immunizationPk": this.selectedPaymentType[0].immunizationId,
          "immunizationName": this.selectedPaymentType[0].immunizationName,
          "immunizationAbvNameEN": this.selectedPaymentType[0].immunizationAbvNameEN,
          "immunizationSpanishName": this.selectedPaymentType[0].immunizationSpanishName,
          "paymentTypeId": this.selectedPaymentType[0].paymentTypeId,
          "paymentTypeName": this.selectedPaymentType[0].paymentTypeName,
          "paymentTypeSpanishName": this.selectedPaymentType[0].paymentTypeSpanishName,
          "price": (this.selectedPaymentType[0].price == null || this.selectedPaymentType[0].price == "N/A" || this.selectedPaymentType[0].price == undefined) ? -1 : this.selectedPaymentType[0].paymentTypeId == "6" ? this.selectedPaymentType[0].directBillPrice : this.selectedPaymentType[0].price,
          "directBillPrice": (this.selectedPaymentType[0].directBillPrice == null || this.selectedPaymentType[0].directBillPrice == "N/A" || this.selectedPaymentType[0].directBillPrice == undefined) ? -1 : this.selectedPaymentType[0].paymentTypeId == "6" ? this.selectedPaymentType[0].directBillPrice : this.selectedPaymentType[0].price,
          "showprice": (this.selectedPaymentType[0].price == null || this.selectedPaymentType[0].price == "N/A" || this.selectedPaymentType[0].price < 0) ? 0 : 1,
          "sendInvoiceTo": this.sendInvoiceTo ? 1 : 0,
          "name": this.sendInvoiceTo ? this.immunizationForm.value.name : null,
          "phone": this.sendInvoiceTo ? this.immunizationForm.value.phone : null,
          "fax": this.sendInvoiceTo ? this.immunizationForm.value.fax : null,
          "email": this.sendInvoiceTo ? this.immunizationForm.value.email1 : null,
          "address1": this.sendInvoiceTo ? this.immunizationForm.value.address1 : null,
          "address2": this.sendInvoiceTo ? this.immunizationForm.value.address2 : null,
          "city": this.sendInvoiceTo ? this.immunizationForm.value.city : null,
          "isTaxExempt": this.sendInvoiceTo && this.selectedPaymentType[0].paymentTypeId == 6 || this.selectedPaymentType[0].paymentTypeId == 13 ? +this.immunizationForm.value.isTaxExempt : null,
          "state": this.sendInvoiceTo ? this.immunizationForm.value.state : null,
          "zip": this.sendInvoiceTo ? this.immunizationForm.value.zip : null,
          "isCopay": this.sendInvoiceTo && this.selectedPaymentType[0].paymentTypeId == 6 ? +this.immunizationForm.value.isCopay : null,
          "copayValue": (this.sendInvoiceTo && this.immunizationForm.value.isCopay == '1' ?
            +this.immunizationForm.value.copayValue : null), //this.outReachProgramType != OutReachProgramType.communityoutreach ?
          "isVoucherNeeded": this.sendInvoiceTo && this.selectedPaymentType[0].paymentTypeId == 13 ? + this.immunizationForm.value.isVoucherNeeded || (is_voucher_needed_imz.length > 0 && is_voucher_needed_imz[0].isVoucherNeeded) : null,
          "voucherExpirationDate": this.sendInvoiceTo && this.isVoucher == 1 ? this.voucherExpirationDate.toLocaleDateString('en-US') : null,
          "isClinicalReqd": null,
          "clinicalReqdApprovedBy": null,
          "clinicalReqApprovedDate": null,
          "voucherBillPrice": (this.selectedPaymentType[0].voucherBillPrice == null || this.selectedPaymentType[0].voucherBillPrice == "N/A" || this.selectedPaymentType[0].voucherBillPrice == undefined) ? this.price : this.selectedPaymentType[0].paymentTypeId == "13" ? this.selectedPaymentType[0].voucherBillPrice : this.selectedPaymentType[0].voucherBillPrice,
          "isVoucher": (this.selectedPaymentType[0].paymentTypeId == "13" ? '1' : '0')
        });
      }

      if (this.showSendInvoiceTodialog) {
        this.isInvoiceInformationProvided = true;

        if (this.InvoiceInformation.length == 0) {
          this.InvoiceInformation.push({
            "paymentTypeId": this.selectedPaymentType[0].paymentTypeId,
            "sendInvoiceTo": this.sendInvoiceTo ? 1 : 0,
            "name": this.sendInvoiceTo ? this.immunizationForm.value.name : null,
            "phone": this.sendInvoiceTo ? this.immunizationForm.value.phone : null,
            "fax": this.sendInvoiceTo ? this.immunizationForm.value.fax : null,
            "email": this.sendInvoiceTo ? this.immunizationForm.value.email1 : null,
            "address1": this.sendInvoiceTo ? this.immunizationForm.value.address1 : null,
            "address2": this.sendInvoiceTo ? this.immunizationForm.value.address2 : null,
            "city": this.sendInvoiceTo ? this.immunizationForm.value.city : null,
            "isTaxExempt": this.sendInvoiceTo && this.selectedPaymentType[0].paymentTypeId === 6 || this.selectedPaymentType[0].paymentTypeId === 13 ? +this.immunizationForm.value.isTaxExempt : null,
            "state": this.sendInvoiceTo ? this.immunizationForm.value.state : null,
            "zip": this.sendInvoiceTo ? this.immunizationForm.value.zip : null,
            "isCopay": this.sendInvoiceTo && this.selectedPaymentType[0].paymentTypeId === 6 || this.selectedPaymentType[0].paymentTypeId === 13 ? +this.immunizationForm.value.isCopay : null,
            "copayValue": (this.sendInvoiceTo && this.immunizationForm.value.isCopay === '1' ?
              + this.immunizationForm.value.copayValue : null), //this.outReachProgramType !== OutReachProgramType.communityoutreach ?
            "isVoucherNeeded": this.sendInvoiceTo && this.selectedPaymentType[0].paymentTypeId === 6 || this.selectedPaymentType[0].paymentTypeId === 13 ? +this.immunizationForm.value.isVoucherNeeded : this.isVoucherAvailable,
            "fluvoucherExpirationDate": this.sendInvoiceTo && this.isVoucher === 1 ? this.immunizationForm.value.fluexpirationDate : null,
            "routinevoucherExpirationDate": this.sendInvoiceTo && this.isVoucher === 1 ? this.immunizationForm.value.routineexpirationDate : null,
            // "covidvoucherExpirationDate": this.sendInvoiceTo && this.isVoucher === 1 ? this.immunizationForm.value.covidexpirationDate : null,
          });
        }
      }

      if (!this.showSendInvoiceTodialog && this.InvoiceInformation.length > 0) {
        let IsVoucherData = this.InvoiceInformation.filter(item => item.isVoucherNeeded == 1);
        this.selectedPaymentType.forEach(element => {
          if (element.paymentTypeName.toLowerCase().search('voucher') > -1) {
            this.isVoucherAvailable = true;
            /** refresh data */

            let corp = this.addedImmunizations.filter(c => c.paymentTypeId == "6" || c.paymentTypeId == "13");
            if (corp.length > 0) {
              //
              let routineExpDateLst = corp.filter(c => c.immunizationName.toLowerCase().search('flu') == -1)
              let flueExpDateLst = corp.filter(c => c.immunizationName.toLowerCase().search('flu') != -1)
              this.isCovidVaccineAvailable = corp.filter(c => c.immunizationName.toLowerCase().search('covid') !== -1).length > 0;
              this.isCovidVoucherAvailable = this.isCovidVaccineAvailable && corp.filter(c => c.immunizationName.toLowerCase().search('voucher') !== -1).length > 0;
              let date = new Date();
              let routinevoucherExpirationDate;
              let fluvoucherExpirationDate;
              let covidvoucherExpirationDate;
              if (routineExpDateLst.length > 0) {
                routinevoucherExpirationDate = routineExpDateLst[0].voucherExpirationDate;
                covidvoucherExpirationDate = routineExpDateLst[0].voucherExpirationDate;
              }
              else {
                routinevoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 18, 55, 55);
                covidvoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 18, 55, 55);
              }
              if (flueExpDateLst.length > 0) {
                //
                fluvoucherExpirationDate = flueExpDateLst[0].voucherExpirationDate
                if (fluvoucherExpirationDate == null) {
                  if (date.getMonth() == 0 || date.getMonth() == 1 || date.getMonth() == 2) {
                    fluvoucherExpirationDate = new Date(date.getFullYear(), 3, 1, 12, 55, 55);
                  }
                  else {
                    fluvoucherExpirationDate = new Date(date.getFullYear() + 1, 3, 1, 12, 55, 55);
                  }
                }
              }
              else {
                //
                if (date.getMonth() == 0 || date.getMonth() == 1 || date.getMonth() == 2) {
                  fluvoucherExpirationDate = new Date(date.getFullYear(), 3, 1, 12, 55, 55);
                }
                else {
                  fluvoucherExpirationDate = new Date(date.getFullYear() + 1, 3, 1, 12, 55, 55);
                }
              }

              if (corp != null && corp.length > 0) {
                this.showSendInvoiceTodialog = false;
                this.isInvoiceInformationProvided = true;
                this.InvoiceInformation = [];
                this.InvoiceInformation.push({
                  "paymentTypeId": corp[0].paymentTypeId,
                  "sendInvoiceTo": corp[0].sendInvoiceTo,
                  "name": corp[0].name,
                  "phone": corp[0].phone,
                  "fax": corp[0].fax,
                  "email": corp[0].email,
                  "address1": corp[0].address1,
                  "address2": corp[0].address2,
                  "city": corp[0].city,
                  "isTaxExempt": corp[0].isTaxExempt,
                  "state": corp[0].state,
                  "zip": corp[0].zip,
                  "isCopay": corp[0].isCopay,
                  "copayValue": corp[0].copayValue,
                  "isVoucherNeeded": this.isVoucherAvailable,
                  "fluvoucherExpirationDate": fluvoucherExpirationDate,
                  "routinevoucherExpirationDate": routinevoucherExpirationDate,
                  "covidvoucherExpirationDate": covidvoucherExpirationDate,
                });
              }

              if (this.InvoiceInformation.length > 0) {
                //
                this._localContractService.setInvoiceAndVoucherData(this.InvoiceInformation);
              }
            }
          }
        });

        this.addedImmunizations.filter(x => x.paymentTypeId == this.paymentType).forEach(im => {
          im.sendInvoiceTo = this.InvoiceInformation[0].sendInvoiceTo,
            im.name = this.InvoiceInformation[0].name,
            im.phone = this.InvoiceInformation[0].phone,
            im.fax = this.InvoiceInformation[0].fax,
            im.email = this.InvoiceInformation[0].email,
            im.address1 = this.InvoiceInformation[0].address1,
            im.address2 = this.InvoiceInformation[0].address2,
            im.city = this.InvoiceInformation[0].city,
            im.isTaxExempt = this.InvoiceInformation[0].isTaxExempt,
            im.state = this.InvoiceInformation[0].state,
            im.zip = this.InvoiceInformation[0].zip,
            im.isCopay = this.InvoiceInformation[0].isCopay,
            im.copayValue = this.InvoiceInformation[0].copayValue,
            im.isVoucherNeeded = this.isVoucherAvailable
          im.voucherExpirationDate = (im.immunizationName.toLowerCase().search('flu') == -1) ? this.InvoiceInformation[0].routinevoucherExpirationDate.toLocaleDateString('en-US') : this.InvoiceInformation[0].fluvoucherExpirationDate.toLocaleDateString('en-US');
        });
      }

      if (this.selectedPaymentType[0] !== undefined && this.selectedPaymentType[0].paymentTypeId == '6' || this.selectedPaymentType[0].paymentTypeId == '13') {
        this.edit_button = true;
        this.sendInvoiceTo = false;
        //this.clearImmFormFields();
      }
    }
    else {
      this.msgs = [];
      let dtlMsg = ErrorMessages['ContractCOImmunizationError'];
      let err = ErrorMessages['errMsg'];
      let crpInvEr = ErrorMessages['CorporateInvoiceError'];
      this.sendInvoiceTo ? this.msgs.push({ severity: 'error', summary: err, detail: crpInvEr }) :
        this.msgs.push({ severity: 'error', summary: err, detail: dtlMsg });
    }

    if (this.selectedPaymentType != 0 && this.selectedPaymentType != undefined) {
      const ind = this.boundImmunizations.findIndex(item => item.immunizationId == this.selectedPaymentType[0].immunizationId
        && item.paymentTypeId == this.selectedPaymentType[0].paymentTypeId);

      this.boundImmunizations.splice(ind, 1);
      this.immunizationForm.controls['immunizationSelected'].updateValueAndValidity();
      this.immunizationForm.controls['paymentsSelected'].updateValueAndValidity();

      this._localContractService.SetIscanceled(true);
      // if(this.paymentType==='6' || this.paymentType=='13'){
      this.showSendInvoiceTodialog = false;
      // }

      this.addedImmunizations.forEach((immunization) => {
        immunization.address1 = this.immunizationForm.value.address1;
        immunization.address2 = this.immunizationForm.value.address2;
        immunization.city = this.immunizationForm.value.city;
        immunization.copayValue = this.immunizationForm.value.copayValue;
        immunization.email = this.immunizationForm.value.email1;
        immunization.fax = this.immunizationForm.value.fax;
        immunization.isCopay = this.immunizationForm.value.isCopay;
        immunization.isTaxExempt = this.immunizationForm.value.isTaxExempt;
        immunization.isVoucherNeeded = this.immunizationForm.value.isVoucherNeeded;
        immunization.name = this.immunizationForm.value.name;
        immunization.phone = this.immunizationForm.value.phone;
        immunization.state = this.immunizationForm.value.state;
        immunization.zip = this.immunizationForm.value.zip;
      });

      this.InvoiceInformation.forEach((invoice) => {
        invoice.address1 = this.immunizationForm.value.address1;
        invoice.address2 = this.immunizationForm.value.address2;
        invoice.city = this.immunizationForm.value.city;
        invoice.copayValue = this.immunizationForm.value.copayValue;
        invoice.email = this.immunizationForm.value.email1;
        invoice.fax = this.immunizationForm.value.fax;
        invoice.isCopay = this.immunizationForm.value.isCopay;
        invoice.isTaxExempt = this.immunizationForm.value.isTaxExempt;
        invoice.isVoucherNeeded = this.immunizationForm.value.isVoucherNeeded;
        invoice.name = this.immunizationForm.value.name;
        invoice.phone = this.immunizationForm.value.phone;
        invoice.state = this.immunizationForm.value.state;
        invoice.zip = this.immunizationForm.value.zip;
      });

      this._localContractService.setImmunizationsData(this.addedImmunizations);
      if (this.InvoiceInformation.length > 0) {
        this._localContractService.setInvoiceAndVoucherData(this.InvoiceInformation);
      }
    }

  }

  addFieldValue() {

    if (this.outReachProgramType != OutReachProgramType.contracts) {
      this.showSendInvoiceTodialog = false;
    }

    if (this.outReachProgramType == OutReachProgramType.communityoutreach) {
      this.immunizationForm.controls['isVoucherNeeded'].setValidators(null);
      this.immunizationForm.controls['isVoucherNeeded'].setErrors(null);
      this.immunizationForm.controls['isVoucherNeeded'].updateValueAndValidity();

      this.immunizationForm.controls['fluexpirationDate'].setValidators(null);
      this.immunizationForm.controls['fluexpirationDate'].setErrors(null);
      this.immunizationForm.controls['fluexpirationDate'].updateValueAndValidity();

      this.immunizationForm.controls['routineexpirationDate'].setValidators(null);
      this.immunizationForm.controls['routineexpirationDate'].setErrors(null);
      this.immunizationForm.controls['routineexpirationDate'].updateValueAndValidity();
    }
    else if (this.outReachProgramType == OutReachProgramType.equityevents) {
      this.immunizationForm.controls['isVoucherNeeded'].setValidators(null);
      this.immunizationForm.controls['isVoucherNeeded'].setErrors(null);
      this.immunizationForm.controls['isVoucherNeeded'].updateValueAndValidity();

      this.immunizationForm.controls['fluexpirationDate'].setValidators(null);
      this.immunizationForm.controls['fluexpirationDate'].setErrors(null);
      this.immunizationForm.controls['fluexpirationDate'].updateValueAndValidity();

      this.immunizationForm.controls['routineexpirationDate'].setValidators(null);
      this.immunizationForm.controls['routineexpirationDate'].setErrors(null);
      this.immunizationForm.controls['routineexpirationDate'].updateValueAndValidity();
    }
    if (this.selectedPaymentType && this.selectedPaymentType[0] != undefined && this.selectedPaymentType[0] !== null) {
      this.immunizationPk = this.selectedPaymentType[0].immunizationId;
    }

    if (this.selectedPaymentType && ClinicalRequirementsImmunizations.indexOf(this.selectedPaymentType[0].immunizationName) > -1) {
      this.clinicalRequirementsDiv = true;
    }
    //
    let immunization = this.addedImmunizations.filter(item => (item.immunizationPk == this.immunizationPk && item.paymentTypeId == 13));

    this.isVoucher = 1;
    this.isCopay = false;
    let date = new Date();

    // following is the condition when selected immunization IS NOT A FLU VACCINE
    if (this.selectedPaymentType && this.selectedPaymentType[0].immunizationName.toLowerCase().search('flu') == -1) {

      if (
          this.selectedPaymentType 
          && this.selectedPaymentType[0].immunizationName.toLowerCase().search('covid') > -1
        ) {
        this.isCovidVaccineAvailable = true;
        this.isCovidVoucherAvailable = this.isCovidVaccineAvailable && this.selectedPaymentType && this.selectedPaymentType[0].immunizationName.toLowerCase().search('voucher') > -1;
      }

      if (
        (immunization != undefined || immunization != null)
        && (immunization[0] != undefined || immunization[0] != null)
        && (immunization[0].fluvoucherExpirationDate != null || immunization[0].routinevoucherExpirationDate != undefined)
        ) {
        this.routinevoucherExpirationDate = new Date(immunization[0].routinevoucherExpirationDate);
        this.covidvoucherExpirationDate = new Date(immunization[0].covidvoucherExpirationDate);
      } 
      else {
        this.routinevoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 18, 55, 55);
        this.covidvoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 18, 55, 55);
      }

      this.isFluType = false;
    }
    // following is the condition when selected immunization IS A FLU VACCINE
    else if (this.selectedPaymentType && this.selectedPaymentType[0].immunizationName.toLowerCase().search('flu') != -1) {

      if (date.getMonth() == 0 || date.getMonth() == 1 || date.getMonth() == 2) {
        if (
          (immunization != undefined || immunization != null)
          && (immunization[0] != undefined || immunization[0] != null)
          && (immunization[0].fluvoucherExpirationDate != null || immunization[0].fluvoucherExpirationDate != undefined)
        ) {
          this.fluvoucherExpirationDate = new Date(immunization[0].fluvoucherExpirationDate);
        } 
        else {
          this.fluvoucherExpirationDate = new Date(date.getFullYear(), 3, 1, 12, 55, 55);
        }
      }
      else {
        if (
          (immunization != undefined || immunization != null)
          && (immunization[0] != undefined || immunization[0] != null)
          && (immunization[0].fluvoucherExpirationDate != null || immunization[0].fluvoucherExpirationDate != undefined)
        ) {
          this.fluvoucherExpirationDate = new Date(immunization[0].fluvoucherExpirationDate);
        }
        else {
          this.fluvoucherExpirationDate = new Date(date.getFullYear() + 1, 3, 1, 12, 55, 55);
        }
      }

      this.isFluType = true;
    }

    this.flumaxDateValue = this.fluvoucherExpirationDate;
    this.fluminDateValue = date;
    this.immunizationForm.controls['fluexpirationDate'].setValue(this.fluvoucherExpirationDate);
    this.routinemaxDateValue = this.routinevoucherExpirationDate;
    this.routineminDateValue = date;
    if(!this.immunizationForm.get('routineexpirationDate')?.value && this.immunizationForm.get('routineexpirationDate')?.value !== this.routinevoucherExpirationDate) //need to verify why it is happening if we set the same value this making the value empty
      this.immunizationForm.controls['routineexpirationDate'].setValue(this.routinevoucherExpirationDate);

    if (
        (!this.selectedImmunization || this.selectedImmunization == 0) 
        && (!this.selectedPaymentType || this.selectedPaymentType?.length == 0)
      ) {
      let dtlMsg = ErrorMessages['contractImmunizationOption'];
      let err = ErrorMessages['errMsg'];
      this.showDialog(err, dtlMsg);
      this.utility.validateAllFormFields(this.immunizationForm);
      return;
    }

    this.addimmunizationData(); // this adds the data to local DS.    

    if (this.paymentType == '13') {
      this.immunizationForm.controls['isVoucherNeeded'].setValue('1');
      this.isVoucherNeededDisable = true;
      this.handleVoucher('1');
    }
    else {
      if (this.immunizationForm.controls['isVoucherNeeded']?.value != '1' || this.immunizationForm.controls['isVoucherNeeded']?.value != 1) {
        this.immunizationForm.controls['isVoucherNeeded'].setValue('0');
      }
      this.isVoucherNeededDisable = true;
      //this.handleVoucher('0');
    }

    this.immunizationForm.controls['immunizationSelected'].setValue('');
    this.immunizationForm.controls['paymentsSelected'].setValue('');
    this.immunizationForm.updateValueAndValidity();

    // data with 'Old_immunizationForm' key will be added to session storage 
    // when user changes 'Flu Voucher Expiration' or 'Routine Voucher Expiration' dates
    
    // if we don't have 'Old_immunizationForm' key then consider the default dates
    // else use the dates from the 'Old_immunizationForm' json stored in session storage
    if(!sessionStorage.getItem('Old_immunizationForm')){ 
      this.InvoiceInformation.forEach(info => {
        if (!info.routinevoucherExpirationDate) {
          info.routinevoucherExpirationDate = this.routinevoucherExpirationDate;
        }
  
        if (!info.fluvoucherExpirationDate) {
          info.fluvoucherExpirationDate = this.fluvoucherExpirationDate;
        }
      });
  
      this.addedImmunizations.forEach(immunization => {
        if (immunization.immunizationName?.search('flu') != -1) {
          immunization.voucherExpirationDate = this.fluvoucherExpirationDate;
        }
        else {
          immunization.voucherExpirationDate = this.routinevoucherExpirationDate;
        }
      });
    }
    else{
      const oldImmunizationFormData = JSON.parse(sessionStorage.getItem('Old_immunizationForm'));
      const routineVoucherExpDate = new Date(oldImmunizationFormData.routineexpirationDate);
      const fluvoucherExpDate = new Date(oldImmunizationFormData.fluexpirationDate);

      this.immunizationForm.get('routineexpirationDate').setValue(routineVoucherExpDate);
      this.immunizationForm.get('fluexpirationDate').setValue(fluvoucherExpDate);

      this.InvoiceInformation.forEach(info => {
        if (!info.routinevoucherExpirationDate) {
          info.routinevoucherExpirationDate = routineVoucherExpDate;
        }
  
        if (!info.fluvoucherExpirationDate) {
          info.fluvoucherExpirationDate = fluvoucherExpDate;
        }
      });
  
      this.addedImmunizations.forEach(immunization => {
        if (immunization.immunizationName?.search('flu') != -1) {
          immunization.voucherExpirationDate = fluvoucherExpDate;
        }
        else {
          immunization.voucherExpirationDate = routineVoucherExpDate;
        }
      });
    }

    if (this.addedImmunizations.length) {
      this.immunizationForm.controls['immunizationSelected'].setErrors(null);
      this.immunizationForm.controls['immunizationSelected'].setValidators(null);
      this.immunizationForm.controls['paymentsSelected'].setErrors(null);
      this.immunizationForm.controls['paymentsSelected'].setValidators(null);
      this.immunizationForm.updateValueAndValidity();

      this.canDisplayExpiryDates = this.addedImmunizations.some(immunization => immunization.paymentTypeId == 13 || immunization.paymentTypeId == '13');
    }

    if(
      (this.outReachProgramType == OutReachProgramType.contracts || this.outReachProgramType == OutReachProgramType.communityoutreach || this.outReachProgramType == OutReachProgramType.equityevents)
      && this.selectedPaymentType 
      && this.selectedPaymentType[0].immunizationName.includes('COVID') 
      ){
        this.showDialog(ErrorMessages['clinicDetailsErrHeader'], ErrorMessages['COVIDManufacturerReminder']);
    }
  }

  clearFormFields() {
    this.immunizationForm.value.immunizationSelected = '';
    this.immunizationForm.value.paymentsSelected = '';

    let cliniclReqFA: FormArray = <FormArray>this.immunizationForm.controls['clinicalReqs'];
    this.price = "";
    if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
      this.immunizationForm = this.formBuilder.group({
        immunizationSelected: ['', this.addedImmunizations.length > 0 ? null : Validators.required],
        paymentsSelected: ['', this.addedImmunizations.length > 0 ? null : Validators.required],
        name: [null, null],
        phone: [null, null],
        fax: ['', null],
        address1: ['', null],
        address2: [null, null],
        email1: [null, null],
        email2: [null, null],
        city: [null, null],
        state: ['', null],
        zip: [null, null],
        isTaxExempt: ['', null],
        isCopay: ['', null],
        copayValue: ['', null],
        isVoucherNeeded: ['', null],
        fluexpirationDate: [this.fluvoucherExpirationDate, null],
        routineexpirationDate: [this.routinevoucherExpirationDate, null],
        // covidexpirationDate: [this.routinevoucherExpirationDate, null],
        clinicalReqs: this.addedImmunizations.length > 0 ? cliniclReqFA : this.formBuilder.array([])
      });
    }
    else if(this.outReachProgramType === OutReachProgramType.equityevents) {
      this.immunizationForm = this.formBuilder.group({
        immunizationSelected: ['', this.addedImmunizations.length > 0 ? null : Validators.required],
        paymentsSelected: ['', this.addedImmunizations.length > 0 ? null : Validators.required],
        everGreen: [this._localContractService.getEverGreenData()],
        name: [null, null],
        phone: [null, null],
        fax: ['', null],
        address1: ['', null],
        address2: [null, null],
        email1: [null, null],
        email2: [null, null],
        city: [null, null],
        state: ['', null],
        zip: [null, null],
        isTaxExempt: ['', null],
        isCopay: ['', null],
        copayValue: ['', null],
        isVoucherNeeded: ['', null],
        fluexpirationDate: [this.fluvoucherExpirationDate, null],
        routineexpirationDate: [this.routinevoucherExpirationDate, null],
        // covidexpirationDate: [this.routinevoucherExpirationDate, null],
        clinicalReqs: this.addedImmunizations.length > 0 ? cliniclReqFA : this.formBuilder.array([])
      });
    }
    else {
      this.immunizationForm = this.formBuilder.group({
        immunizationSelected: ['', this.addedImmunizations.length > 0 ? null : Validators.required],
        paymentsSelected: ['', this.addedImmunizations.length > 0 ? null : Validators.required],
        everGreen: [this._localContractService.getEverGreenData()],
        name: [null, null],
        phone: [null, null],
        fax: ['', null],
        address1: ['', null],
        address2: [null, null],
        email1: [null, null],
        email2: [null, null],
        city: [null, null],
        state: ['', null],
        zip: [null, null],
        isTaxExempt: ['', null],
        isCopay: ['', null],
        copayValue: ['', null],
        isVoucherNeeded: ['', null],
        fluexpirationDate: [this.fluvoucherExpirationDate, null],
        routineexpirationDate: [this.routinevoucherExpirationDate, null],
        // covidexpirationDate: [this.routinevoucherExpirationDate, null],
        clinicalReqs: this.addedImmunizations.length > 0 ? cliniclReqFA : this.formBuilder.array([])
      });
    }
  }

  clearImmFormFields() {
    this.clearFormFields();
  }

  closeCTI() {
    this.sendInvoiceTo = false;
    //this.clearFormFields();
  }

  deleteFieldValue(item: any) {
    let immunization = this.addedImmunizations;
    let index = immunization.findIndex(p => p.immunizationPk == item.immunizationPk && p.paymentTypeId == item.paymentTypeId);

    this.boundImmunizations.push({
      "immunizationId": immunization[index].immunizationPk,
      "immunizationName": immunization[index].immunizationName,
      "immunizationSpanishName": immunization[index].immunizationSpanishName,
      "paymentTypeId": immunization[index].paymentTypeId,
      "paymentTypeName": immunization[index].paymentTypeName,
      "paymentTypeSpanishName": immunization[index].paymentTypeSpanishName,
      "price": immunization[index].price,
      //"directBillPrice":immunization[index].directBillPrice,
      "directBillPrice": immunization[index].paymentTypeId == "6" ? immunization[index].price : immunization[index].directBillPrice,
      "sendInvoiceTo": immunization[index].sendInvoiceTo,
      "showprice": immunization[index].showprice,
      "address1": immunization[index].address1,
      "address2": immunization[index].address2,
      "city": immunization[index].city,
      "copayValue": immunization[index].copayValue,
      "email": immunization[index].email,
      "isCopay": immunization[index].isCopay,
      "isTaxExempt": immunization[index].isTaxExempt,
      "isVoucherNeeded": immunization[index].isVoucherNeeded,
      "name": immunization[index].name,
      "phone": immunization[index].phone,
      "fax": immunization[index].fax,
      "state": immunization[index].state,
      "voucherExpirationDate": immunization[index].voucherExpirationDate,
      "zip": immunization[index].zip
    });

    this.addedImmunizations.splice(index, 1);

    // check if the immunization with voucher payment type is getting removed AND we dont have any other voucher payment types
    // if yes then we should not display expiration dates and make voucher needed as No
    if ((item.paymentTypeId == 13 || item.paymentTypeId == '13') && !this.addedImmunizations.some(immunization => immunization.paymentTypeId == 13 || immunization.paymentTypeId == '13')) {
      this.canDisplayExpiryDates = false;
      this.immunizationForm.controls['isVoucherNeeded'].setValue('0');
    }

    if (this.addedImmunizations.length && this.InvoiceInformation.length) {
      this.addedImmunizations.forEach((immunization) => {
        immunization.isVoucherNeeded = this.immunizationForm.value.isVoucherNeeded;
      });

      this.InvoiceInformation.forEach((invoice) => {
        invoice.isVoucherNeeded = this.immunizationForm.value.isVoucherNeeded;
      });
    }

    if (this.addedImmunizations.length <= 0) {
      this._localContractService.SetIscanceled(false);
    }
    this.clinicalRequirementsDiv = false;

    this.immunizationForm.controls['immunizationSelected'].updateValueAndValidity();
    this.immunizationForm.controls['paymentsSelected'].updateValueAndValidity();
    const clinicalRequirementsIndex = ClinicalRequirementsImmunizations.findIndex(reqImmunization => reqImmunization === item.immunizationName);

    if (clinicalRequirementsIndex !== -1) {
      this.addedImmunizationsClinicalReqs.splice(clinicalRequirementsIndex, 1);
    }

    this.addedImmunizations.forEach(element => {
      if (element.paymentTypeName.toLowerCase().search('voucher') > -1) {
        this.isVoucherAvailable = true;
      }
      else {
        this.isVoucherAvailable = false;
      }

      // if(this.isVoucherAvailable == false){
      //   this.addimmunizationData(); 
      // }
    });

    const clinicalReqsFormArray = this.immunizationForm.controls.clinicalReqs as FormArray;
    const clinicalReqCtrlValues: any[] = this.immunizationForm.controls.clinicalReqs.value;
    const clinicalReqsCtrls = clinicalReqsFormArray.controls;

    const ctrlIndexToBeRemoved = clinicalReqsCtrls.findIndex(ctrl => ctrl.value.immunizationName === item.immunizationName);
    const valueIndexToBeRemoved = clinicalReqCtrlValues.findIndex(ctrlValue => ctrlValue.immunizationName === item.immunizationName);

    if ((valueIndexToBeRemoved !== -1) && (ctrlIndexToBeRemoved !== -1)) {
      this.immunizationForm.controls.clinicalReqs.value.splice(valueIndexToBeRemoved, 1);
      clinicalReqsCtrls.splice(valueIndexToBeRemoved, 1);
    }

    //this.formControls();
    this.setClinicalRequirementFlags();
    this.selectedPaymentType = 0;
    this.selectedImmunization = 0;
    this.PaymentTypes = [];
    this.price = "";
    this.paymentType = '0'

    if (this.addedImmunizations.length == 0) {
      this.InvoiceInformation = [];
      this.showSendInvoiceTodialog = true;
      this.isInvoiceInformationProvided = false;
      this.isVoucher = 0;
    }

    this.closeCTI();
    this.validateLocForm = true;
  }
  getIndexFromClinicaReqArray(element: string) {
    let formGrpIndex: number = -1;
    let cliniclReqFA: FormArray = <FormArray>this.immunizationForm.controls['clinicalReqs'];
    for (let index = 0; index < cliniclReqFA.length; index++) {
      let formGrp: FormGroup = <FormGroup>cliniclReqFA.controls[index];
      // there should be one formgroup with this immunization name
      if (formGrp.controls['immunizationName'].value === element) {
        formGrpIndex = index;
        break;
      }
    }
    return formGrpIndex;
  }

  onfluClinicDateSelected(selectedDate: Date) {
    const updatedFluExpDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
    const updatedflu = updatedFluExpDate.toLocaleDateString('en-US');
    this.immunizationForm.controls["fluexpirationDate"].setValue(updatedflu);

    this.addedImmunizations.forEach(immunization => {
      if (immunization.immunizationName?.includes('flu')) {
        immunization.voucherExpirationDate = updatedflu;
      }
    });
  }

  onroutineClinicDateSelected(selectedDate: Date) {
    const updatedRoutineExpDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
    const updatedroutine = updatedRoutineExpDate.toLocaleDateString('en-US');
    this.immunizationForm.controls["routineexpirationDate"].setValue(updatedroutine);
   
    this.addedImmunizations.forEach(immunization => {
      if (immunization.immunizationName?.search('flu') == -1) {
        immunization.voucherExpirationDate = updatedroutine;
      }
    });
    SessionDetails.SetImmunizationdata(this.immunizationForm.value);
  }

  onCovidClinicDateSelected(selectedDate: Date) {
    this.immunizationForm.controls["covidexpirationDate"].setValue(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 18, 55, 55));
  }

  handleCopay(copayValue: any) {
    this.isCopay = copayValue == '1';
    if (copayValue == '1') {
      this.immunizationForm.controls['copayValue'].setValidators([Validators.required]);
      this.immunizationForm.controls['copayValue'].updateValueAndValidity();
    } else {
      this.immunizationForm.controls['copayValue'].setValidators(null);
      this.immunizationForm.controls['copayValue'].setErrors(null);
      this.immunizationForm.controls['copayValue'].updateValueAndValidity();
    }
  }

  handleVoucher(voucherneeded: any) {
    let immunization = this.addedImmunizations.filter(item => (item.immunizationPk == this.immunizationPk && item.paymentTypeId == 6));

    if (voucherneeded == '1') {

      // this.isCovidVaccineAvailable = false;

      if (this.addedImmunizations.length > 0 && immunization[0] && immunization[0].immunizationName.toLowerCase().search('covid') > -1) {
        this.isCovidVaccineAvailable = true;
        this.isCovidVoucherAvailable = this.isCovidVoucherAvailable && immunization[0].immunizationName.toLowerCase().search('voucher') > -1;
      }
      else if (this.selectedPaymentType[0] && this.selectedPaymentType[0].immunizationName.toLowerCase().search('covid') > -1) {
        this.isCovidVaccineAvailable = true;
        this.isCovidVoucherAvailable = this.isCovidVoucherAvailable && this.selectedPaymentType[0].immunizationName.toLowerCase().search('voucher') > -1;
      }
      this.isVoucher = 1;
      let date = new Date();
      if ((immunization != undefined || immunization != null)
        && (immunization[0] != undefined || immunization[0] != null)
        && (immunization[0].routinevoucherExpirationDate != null || immunization[0].routinevoucherExpirationDate != undefined)) {
        this.routinevoucherExpirationDate = new Date(immunization[0].routinevoucherExpirationDate);
        this.covidvoucherExpirationDate = new Date(immunization[0].routinevoucherExpirationDate);
      } else {
        this.routinevoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 12, 55, 55);
        this.covidvoucherExpirationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 12, 55, 55);
      }
      if (date.getMonth() == 0 || date.getMonth() == 1 || (date.getMonth() == 2 && date.getDate() >= 1 && date.getDate() <= 31)) {
        if ((immunization != undefined || immunization != null)
          && (immunization[0] != undefined || immunization[0] != null)
          && (immunization[0].fluvoucherExpirationDate != null || immunization[0].fluvoucherExpirationDate != undefined)) {
          this.fluvoucherExpirationDate = new Date(immunization[0].voucherExpirationDate);
        } else {
          this.fluvoucherExpirationDate = new Date(date.getFullYear(), 3, 1, 12, 55, 55);
        }
      }
      else {
        if ((immunization != undefined || immunization != null)
          && (immunization[0] != undefined || immunization[0] != null)
          && (immunization[0].fluvoucherExpirationDate != null || immunization[0].fluvoucherExpirationDate != undefined)) {
          this.fluvoucherExpirationDate = new Date(immunization[0].voucherExpirationDate);
        } else {
          this.fluvoucherExpirationDate = new Date(date.getFullYear() + 1, 3, 1, 12, 55, 55);
        }
      }
      this.flumaxDateValue = this.fluvoucherExpirationDate;
      this.fluminDateValue = date;
      this.immunizationForm.controls['fluexpirationDate'].patchValue(this.fluvoucherExpirationDate);
      this.routinemaxDateValue = this.routinevoucherExpirationDate;
      this.routineminDateValue = date;
      this.immunizationForm.controls['routineexpirationDate'].patchValue(this.routinevoucherExpirationDate);
      // this.immunizationForm.controls['covidexpirationDate'].setValue(this.covidvoucherExpirationDate);
    }
    else {
      this.isVoucher = 0;
      //this.isCovidVoucherAvailable = false;
    }

    if (this.addedImmunizations.length && this.InvoiceInformation.length) {
      this.addedImmunizations.forEach((immunization) => {
        immunization.isVoucherNeeded = this.immunizationForm.value.isVoucherNeeded;
      });

      this.InvoiceInformation.forEach((invoice) => {
        invoice.isVoucherNeeded = this.immunizationForm.value.isVoucherNeeded;
      });
    }
  }

  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  ClosedisplayDesMisMatchModal() {
    this.displayDesMisMatchModal = false;
  }
  CloseAddImmnzsModal() {

    this.CanAddNewImmunzsModal = false;
    this.canAddNewImmunzsSelected = true;
    // sessionStorage["canAddNewImmunzsSelected"] = "true";
  }

  ContinueToStepTwo() {

    this.canAddNewImmunzsSelected = false;
    this.CanAddNewImmunzsModal = false;
    this.CanAddNewImmunzsModalContinue = true;
    this.clinicSubmit.emit(2);
  }

  showMismatchDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.displayDesMisMatchModal = true;
  }

  CheckCanAddNewImmunzs() {

    let isFluVaccineAvailable = this.addedImmunizations.filter(item => item.immunizationName.toLowerCase().search(/flu/) >= 0).length > 0;
    let isRoutineuVaccineAvailable = this.addedImmunizations.filter(item => item.immunizationName.toLowerCase().search(/flu/) == -1).length > 0;

    if (isFluVaccineAvailable && isRoutineuVaccineAvailable) {
      this.CanAddNewImmunzsModalContinue = true;
      return "false";
    }
    else if (isFluVaccineAvailable && !isRoutineuVaccineAvailable) {
      return "routine";

    }
    else if (!isFluVaccineAvailable && isRoutineuVaccineAvailable) {
      return "flu";
    }
    else {
      return "false";
    }


  }

  radioCheck(radioChecked: any) {
    this.everGreenValue = radioChecked;
    this._localContractService.setEverGreenData(this.everGreenValue);
  }

  save(outReachPrgType?: string): boolean {
    if (this.addedImmunizations.length > 0) {
      this.setFormCtrlsValidations("immunizationSelected");
      this.setFormCtrlsValidations("paymentsSelected");
    }

    if (this.immunizationForm.valid && this.addedImmunizations.length > 0 && (this.outReachProgramType === OutReachProgramType.communityoutreach || this.outReachProgramType === OutReachProgramType.equityevents)) {
      if (!this.checkVoucherExpiryDatescompliance()) {
        return false;
      }
      // if (!this.checkDesigantionMismatch()) {
      //   return false;
      // }
      this.setclinicalReqsFlagsFromUI();
      this._localContractService.setCOSelectedImmunizations(this.addedImmunizations);
      this._localContractService.setCOCancelSelectedImmunizations(this.addedImmunizations);
      return true;
    }
    else if (this.immunizationForm.valid && this.addedImmunizations.length > 0) { //&& this._localContractService.getEverGreenData() != ''
      if (!this.checkVoucherExpiryDatescompliance()) { 
        return false; 
      }
      // if (!this.checkDesigantionMismatch()) {
      //   return false;
      // }
      this.setclinicalReqsFlagsFromUI();
      this._localContractService.setSelectedImmunizations(this.addedImmunizations);
      this._localContractService.setCancelSelectedImmunizations(this.addedImmunizations);
      let result = this.CheckCanAddNewImmunzs();
      if (result != "false") {
        if (this.CanAddNewImmunzsModalContinue)
          return true;

        if (result == 'flu') {
          this.dialogMsg = ErrorMessages['CanAddFluImms'];
          this.dialogSummary = ErrorMessages['clinicDetailsErrHeader'];
          this.CanAddNewImmunzsModal = true;
        }
        else {
          this.dialogMsg = ErrorMessages['CanAddRoutineImms'];
          this.dialogSummary = ErrorMessages['clinicDetailsErrHeader'];
          this.CanAddNewImmunzsModal = true;
        }
        return false;
      }
      else {
        return true;
      }
    }
    else {
      this.msgs = [];
      let dtlMsg = ErrorMessages['MandatoryFields'];
      if (this.addedImmunizations.length <= 0) {
        dtlMsg = ErrorMessages['Atleast1Immunization'];
      }
      else {
        if ((this.immunizationForm.controls['immunizationSelected'].valid && !this.immunizationForm.controls['paymentsSelected'].valid)) {
          this.setFormCtrlsValidations("immunizationSelected");
        }
        else if (!this.immunizationForm.controls['immunizationSelected'].valid && this.immunizationForm.controls['paymentsSelected'].valid) {
          this.setFormCtrlsValidations("paymentsSelected");
        }
        else {
          this.setFormCtrlsValidations("immunizationSelected");
          this.setFormCtrlsValidations("paymentsSelected");
          var clReqsFA = (<FormArray>this.immunizationForm.controls['clinicalReqs']);
          let clReqList: string = '';
          let clReqListCount: number = 0;
          for (let index = 0; index < clReqsFA.length; ++index) {
            let fg: FormGroup = <FormGroup>clReqsFA.controls[index];
            if (ClinicalRequirementsImmunizations.indexOf(fg.controls['immunizationName'].value) > -1 && (

              fg.controls['isClinicalReqd'].value != true)) {
              fg.controls['isClinicalReqd'].setValue(null);
              fg.controls['isClinicalReqd'].setValidators([Validators.required]);
              fg.controls['isClinicalReqd'].updateValueAndValidity();
              if (clReqListCount > 0) {
                clReqList += " and " + fg.controls['immunizationName'].value;
              }
              else {
                clReqList = fg.controls['immunizationName'].value;
              }
              clReqListCount++;
            }
            fg.updateValueAndValidity();
            if (clReqList != '') {
              dtlMsg = String.Format(ErrorMessages['clinicalRequirementsError'], clReqList)
            }

          }
        }
      }
      let err = ErrorMessages['errMsg'];
      this.showDialog(err, dtlMsg);
      this.utility.validateAllFormFields(this.immunizationForm);
      return false;
    }
  }

  setFormCtrlsValidations(frmCtrlName, setToNull = true) {
    this.immunizationForm.controls[frmCtrlName].setValidators(null);
    this.immunizationForm.controls[frmCtrlName].setErrors(null);
    this.immunizationForm.controls[frmCtrlName].updateValueAndValidity();
  }

  updateValidations(index, checked) {
    if (!checked) {
      let clinicalReqsFA: FormArray = (<FormArray>this.immunizationForm.controls['clinicalReqs']);
      if (clinicalReqsFA.length > 0) {
        let fg: FormGroup = (<FormGroup>(clinicalReqsFA.controls[index]));
        if (fg.controls['isClinicalReqd'].value != true) {
          fg.controls['isClinicalReqd'].setValue(null);
        }
        fg.controls['isClinicalReqd'].setValidators([Validators.required]);
        if (this.addedImmunizations.length > 0) {
          this.setFormCtrlsValidations("immunizationSelected");
          this.setFormCtrlsValidations("paymentsSelected");
        }
      }
      clinicalReqsFA.updateValueAndValidity();
    }
    else {
      const clinicalReqsFormArray = this.immunizationForm.controls.clinicalReqs as FormArray;
      const selectedClinicalReqsCtrl = clinicalReqsFormArray.controls[index];
      let a = selectedClinicalReqsCtrl.get('isClinicalReqd');
      selectedClinicalReqsCtrl.get('isClinicalReqd').setValue(checked);
      this.immunizationForm.updateValueAndValidity();
    }

    this.setclinicalReqsFlagsFromUI();
  }
  setclinicalReqsFlagsFromUI() {
    this.addedImmunizations.forEach(imm => {
      if (ClinicalRequirementsImmunizations.indexOf(imm.immunizationName) > -1) {
        let chkBxIndex: number = this.getIndexFromClinicaReqArray(imm.immunizationName);
        if (chkBxIndex > -1) {
          let fg: FormGroup = (<FormGroup>(<FormArray>this.immunizationForm.controls['clinicalReqs']).controls[chkBxIndex]);
          imm.isClinicalReqd = fg.controls['isClinicalReqd'].value;
          if (imm.isClinicalReqd != true) {
            fg.controls['isClinicalReqd'].setValue(null);
            fg.controls['isClinicalReqd'].setValidators(Validators.required);
          }
          if (imm.isClinicalReqd == true) {
            imm.clinicalReqdApprovedBy = SessionDetails.GetUserProfile().userPk;
            imm.clinicalReqApprovedDate = new Date();
          }
          else {
            imm.clinicalReqdApprovedBy = null;
            imm.clinicalReqApprovedDate = null;
          }
        }
        else {
          imm.isClinicalReqd = null;
        }
      }
      else {
        imm.isClinicalReqd = null;
      }
    });
  }
  simpleSave(outReachPrgType?: string): boolean {
    this.immunizationForm.controls['immunizationSelected'].updateValueAndValidity();
    this.immunizationForm.controls['paymentsSelected'].updateValueAndValidity();
    if ((this.immunizationForm.controls['immunizationSelected'].valid && !this.immunizationForm.controls['paymentsSelected'].valid) ||
      (!this.immunizationForm.controls['immunizationSelected'].valid && this.immunizationForm.controls['paymentsSelected'].valid)) {
      return false;
    }
    if (this.outReachProgramType === OutReachProgramType.communityoutreach || this.outReachProgramType === OutReachProgramType.equityevents) { //this.addedImmunizations.length > 0 &&
      this.setclinicalReqsFlagsFromUI();
      this._localContractService.setCOSelectedImmunizations(this.addedImmunizations);
      return true;
    }
    else { //&& this._localContractService.getEverGreenData() != '' if (this.addedImmunizations.length > 0)
      this.setclinicalReqsFlagsFromUI();
      this._localContractService.setSelectedImmunizations(this.addedImmunizations);
      return true;
    }
  }

  displayDialogCC() {
    this.selectedPaymentType = 0;
    this.selectedImmunization = 0;
    this.clearImmFormFields();
    this.PaymentTypes = [];
    this.price = "";
    this.displayDialog = false;
  }

  editCorporateToInvoice(index: any) {



    //
    this.showSendInvoiceTodialog = true;
    this.isEditClick = true;
    this.rowIndex = index;
    this.btnContinue = false;
    this.btnUpdate = true;


    console.log(this.InvoiceInformation);
    let immunization: any = [];
    if (this.InvoiceInformation.filter(item => (item.paymentTypeId == 13)).length > 0) {

      immunization = this.InvoiceInformation.filter(item => (item.paymentTypeId == 13));
    }
    else {

      immunization = this.InvoiceInformation.filter(item => (item.paymentTypeId == 6));
    }

    // item.paymentTypeId == 6 ||
    this.isCopay = immunization[0].isCopay == '1';
    this.isVoucher = immunization[0].isVoucherNeeded == '1';
    this.sendInvoiceTo = true;
    this.displayDialog = true;
    let date = new Date();
    this.routinemaxDateValue = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), 12, 55, 55);

    if (date.getMonth() == 0 || date.getMonth() == 1 || date.getMonth() == 2) {
      immunization[0].maxDateValue = new Date(date.getFullYear(), 3, 1, 12, 55, 55);
      this.flumaxDateValue = new Date(date.getFullYear(), 3, 1, 12, 55, 55);
    } else {
      immunization[0].maxDateValue = new Date(date.getFullYear() + 1, 3, 1, 12, 55, 55);
      this.flumaxDateValue = new Date(date.getFullYear() + 1, 3, 1, 12, 55, 55);
    }
    //
    immunization[0].minDateValue = date;
    let cliniclReqFA: FormArray = <FormArray>this.immunizationForm.controls['clinicalReqs'];
    this.immunizationForm =
      this.formBuilder.group({
        immunizationSelected: ['', null],
        paymentsSelected: ['', null],
        name: [immunization[0].name, Validators.required],
        phone: [immunization[0].phone, Validators.required],
        fax: [immunization[0].fax, null],
        address1: [immunization[0].address1, Validators.required],
        address2: [immunization[0].address2, null],
        email1: [immunization[0].email.toLowerCase(), Validators.email],
        email2: [immunization[0].email.toLowerCase(), Validators.email],
        city: [immunization[0].city, Validators.required],
        state: [immunization[0].state, Validators.required],
        zip: [immunization[0].zip, Validators.required],
        isTaxExempt: [immunization[0].isTaxExempt, Validators.required],
        isCopay: [immunization[0].isCopay, Validators.required],// == 'Yes'
        copayValue: [immunization[0].isCopay == '1' ? immunization[0].copayValue : '', this.immunizationForm.value.isCopay == '1' ? Validators.required : null],
        isVoucherNeeded: [immunization[0].isVoucherNeeded == true ? '1' : 0, Validators.required],
        fluexpirationDate: [new Date(immunization[0].fluvoucherExpirationDate), (this.immunizationForm.value.isVoucherNeeded == '1' || this.immunizationForm.value.isVoucherNeeded == 1) ? Validators.required : null],
        routineexpirationDate: [new Date(immunization[0].routinevoucherExpirationDate), (this.immunizationForm.value.isVoucherNeeded == '1' || this.immunizationForm.value.isVoucherNeeded == 1) ? Validators.required : null],
        // covidexpirationDate: [new Date(immunization[0].covidvoucherExpirationDate), (this.immunizationForm.value.isVoucherNeeded == '1' || this.immunizationForm.value.isVoucherNeeded == 1)&&this.isCovidVaccineAvailable===true ? Validators.required : null],
        clinicalReqs: this.addedImmunizations.length > 0 ? cliniclReqFA : this.formBuilder.array([])
      });
  }

  checkVoucherExpiryDatesValidity(immunizationName: string, immunizationPk: number, selectedDate: string) {

    let isVoucherValid: boolean = true;
    let isVaccineFluType: boolean = false;
    isVaccineFluType = immunizationName.toLowerCase().search('flu') !== -1 ? true : false;

    if (isVaccineFluType) {
      this.addedImmunizations.forEach(im => {

        if (
          (im.isVoucherNeeded === '1' || im.isVoucherNeeded === 1) && (im.sendInvoiceTo === '1' || im.sendInvoiceTo === 1) && im.immunizationName.toLowerCase().search('flu') !== -1 && im.immunizationPk !== immunizationPk
        ) {
          let imzDate: string = this.datePipe.transform(im.voucherExpirationDate, "MM/dd/yyyy");
          if (selectedDate.search(imzDate) === -1) {
            isVoucherValid = false;
            return isVoucherValid;
          }
        }
      });
    }
    else {
      this.addedImmunizations.forEach(im => {

        if (
          (im.isVoucherNeeded === '1' || im.isVoucherNeeded === 1) && (im.sendInvoiceTo === '1' || im.sendInvoiceTo === 1) && im.immunizationName.toLowerCase().search('flu') == -1 && im.immunizationPk !== immunizationPk
        ) {
          let imzDate: string = this.datePipe.transform(im.voucherExpirationDate, "MM/dd/yyyy");
          if (selectedDate.search(imzDate) === -1) {
            isVoucherValid = false;
            return isVoucherValid;
          }
        }
      });
    }
    return isVoucherValid;
  }

  checkVoucherExpiryDatescompliance(): boolean {
    let dtlMsg: string = '';
    try {
      this.addedImmunizations.forEach(im => {
        if (im.isVoucherNeeded == 1 || im.isVoucherNeeded == true) {
          if (!this.checkVoucherExpiryDatesValidity(im.immunizationName, im.immunizationPk,
            this.datePipe.transform(im.voucherExpirationDate, "MM/dd/yyyy"))) {

            if (im.immunizationName.toLowerCase().search('flu') !== -1) {
              dtlMsg = ErrorMessages['fluVoucherExpiryInValid'];
            } else {
              dtlMsg = ErrorMessages['routineVoucherExpiryInValid'];
            }

            throw false;
          }
        }
      });
    } catch (e) {
      let err = ErrorMessages['resolve'];
      this.showDialog(err, dtlMsg);
      return false;
    }
    return true;
  }

  checkDesigantionMismatch(): boolean {
    let dtlMsg: string = '';
    try {
      if (this.addedImmunizations.findIndex(x => x.immunizationName.includes("COVID")) > -1 && !this.contractedOpportunity.isCovidOpportunity) {
        if (this.outReachProgramType == OutReachProgramType.communityoutreach || sessionStorage["AmendAgreement"] == "true") {
          dtlMsg = ErrorMessages['DesignationMisMatchCovidCommOR'];
        }
        else {
          dtlMsg = ErrorMessages['DesignationMisMatchCovid'];
        }
        throw false;
      }
      if (this.addedImmunizations.findIndex(x => x.immunizationName.includes("COVID")) == -1 && !this.contractedOpportunity.isFluOpportunity) {
        if (this.outReachProgramType == OutReachProgramType.communityoutreach || sessionStorage["AmendAgreement"] == "true") {
          dtlMsg = ErrorMessages['DesignationMisMatchFluCommOR'];
        }
        else {
          dtlMsg = ErrorMessages['DesignationMisMatchFlu'];
        }

        throw false;
      }
    }
    catch (e) {
      let err = ErrorMessages['errMsg'];
      this.showMismatchDialog(err, dtlMsg);
      return false;
    }
    return true;
  }

  NavigateToStore() {

    SessionDetails.SetActionType(ActionType.editOportunity);
    this.router.navigateByUrl('/communityoutreach/opportunity');
  }

  cancelImmunization(): boolean {
    var compare_objects = false;
    this.cancel_check = this._localContractService.getIsCancel();
    compare_objects = this.cancel_check;// this.utility.compareTwoObjects(this.immunization_data, this.addedImmunizations);
    return compare_objects;
  }

  checkIfAnyImmunizationsAdded() {
    if (this.addedImmunizations.length > 0) {
      return true;
    } else {
      this.utility.validateAllFormFields(this.immunizationForm);
      return false;
    }
  }

  public isFormValid(): boolean {
    return this.immunizationForm.valid;
  }

  validateName(event) {
    if (event) {
      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.name = this.immunizationForm.value.name;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.name = this.immunizationForm.value.name;
        });
      }

      this.canAddImmunizationData();
    }
  }

  validatePhone(event) {
    if (event) {
      if ((this.immunizationForm.value.phone).indexOf("X") > -1) {
        this.immunizationForm.controls['phone'].setValue(null);
        this.immunizationForm.controls['phone'].updateValueAndValidity();
        this.utility.validateAllFormFields(this.immunizationForm);
        let dtlMsg = ErrorMessages['MandatoryFields'];
        let err = ErrorMessages['errMsg'];
        this.showDialog(err, dtlMsg);
        return false;
      }

      if (!this.usPhoneNoRegEx.test(this.immunizationForm.value.phone)) {
        let dtlMsg = ErrorMessages['invalidPhoneNo'];
        let err = ErrorMessages['errMsg'];
        this.showDialog(err, dtlMsg);
        return;
      }

      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.phone = this.immunizationForm.value.phone;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.phone = this.immunizationForm.value.phone;
        });
      }

      this.canAddImmunizationData();
    }
  }

  validateFax(event) {
    if (event) {
      if (this.immunizationForm.value.fax && !this.usFaxNoRegEx.test(this.immunizationForm.value.fax)) {
        this.immunizationForm.controls.fax.setValue(null);
        this.immunizationForm.controls.fax.updateValueAndValidity();
        let dtlMsg = ErrorMessages['invalidFaxNo'];
        let err = ErrorMessages['errMsg'];
        this.showDialog(err, dtlMsg);
        return;
      }

      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.fax = this.immunizationForm.value.fax;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.fax = this.immunizationForm.value.fax;
        });
      }

      this.canAddImmunizationData();
    }
  }

  validateEmail(event) {
    if (event) {
      if (this.immunizationForm.controls['email1'].value.toLowerCase()
        !== this.immunizationForm.controls['email2'].value.toLowerCase()) {
        let dtlMsg = ErrorMessages['VerifyEmail'];
        let err = ErrorMessages['errMsg'];
        this.showDialog(err, dtlMsg);
        return;
      }

      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.email = this.immunizationForm.value.email1;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.email = this.immunizationForm.value.email1;
        });
      }
      this.canAddImmunizationData();
    }
  }

  validateAddress1(event) {
    if (event) {
      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.address1 = this.immunizationForm.value.address1;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.address1 = this.immunizationForm.value.address1;
        });
      }
      this.canAddImmunizationData();
    }
  }

  validateAddress2(event) {
    if (event) {
      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.address2 = this.immunizationForm.value.address2;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.address2 = this.immunizationForm.value.address2;
        });
      }
      this.canAddImmunizationData();
    }
  }

  validateCity(event) {
    if (event) {
      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.city = this.immunizationForm.value.city;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.city = this.immunizationForm.value.city;
        });
      }
      this.canAddImmunizationData();
    }
  }

  validateState(event) {
    if (event) {
      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.state = this.immunizationForm.value.state;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.state = this.immunizationForm.value.state;
        });
      }
      this.canAddImmunizationData();
    }
  }

  validateCopay(event) {
    if (event) {
      if ((this.immunizationForm.value.isCopay == '1' || this.immunizationForm.value.isCopay == 1) && !this.ccRegex.test(this.immunizationForm.value.copayValue)) {
        let dtlMsg = ErrorMessages['dotNotAllowedCharacter'];
        let err = ErrorMessages['errMsg'];
        this.showDialog(err, dtlMsg);
        return;
      }
      this.canAddImmunizationData();
    }
  }

  validateZipCode(event) {
    if (event) {
      if (!this.usZipCodeRegEx.test(this.immunizationForm.value.zip)) {
        let dtlMsg = ErrorMessages['invalidZipCode'];
        let err = ErrorMessages['errMsg'];
        this.showDialog(err, dtlMsg);
        return;
      }

      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.zip = this.immunizationForm.value.zip;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.zip = this.immunizationForm.value.zip;
        });
      }
      this.canAddImmunizationData();
    }
  }

  validateTaxExempt(event) {
    if (event) {
      if (this.addedImmunizations.length && this.InvoiceInformation.length) {
        this.addedImmunizations.forEach((immunization) => {
          immunization.isTaxExempt = this.immunizationForm.value.isTaxExempt;
        });

        this.InvoiceInformation.forEach((invoice) => {
          invoice.isTaxExempt = this.immunizationForm.value.isTaxExempt;
        });
      }
      this.canAddImmunizationData();
    }
  }

  canAddImmunizationData() {

    const nameCtrlValid = this.immunizationForm.controls['name'].valid;
    const phoneCtrlValid = this.immunizationForm.controls['phone'].valid;
    const faxCtrlValid = this.immunizationForm.controls['fax'].valid;
    const email1CtrlValid = this.immunizationForm.controls['email1'].valid;
    const email2CtrlValid = this.immunizationForm.controls['email2'].valid;
    const address1CtrlValid = this.immunizationForm.controls['address1'].valid;
    const address2CtrlValid = this.immunizationForm.controls['address2'].valid;
    const cityCtrlValid = this.immunizationForm.controls['city'].valid;
    const stateCtrlValid = this.immunizationForm.controls['state'].valid;
    const zipCtrlValid = this.immunizationForm.controls['zip'].valid;
    const isTaxExemptCtrlValid = this.immunizationForm.controls['isTaxExempt'].valid;

    const isFormValid = nameCtrlValid && phoneCtrlValid && faxCtrlValid && email1CtrlValid && email2CtrlValid
      && address1CtrlValid && address2CtrlValid && cityCtrlValid && stateCtrlValid && zipCtrlValid && isTaxExemptCtrlValid

    if (isFormValid) {
      // this.selectedImmunization = this.selectedImmunizationValue;
      // this.selectedPaymentType = this.selectedPaymentTypeValue;
      this.addimmunizationData();
      this.selectedImmunization = 0;
      this.selectedPaymentType = 0;
    }
  }
}