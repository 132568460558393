
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, switchMap, tap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile.service';
import { FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { UserProfileModel, UserProfile } from '../../../../../models/UserProfileModel';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Util } from '../../../../../utility/util';
import { HeaderserviceService } from '../../../../common/services/headerservice.service';
import { SessionDetails } from '../../../../../utility/session';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  ddlist: string;
  assignments: string;
  RoleName: string;
  getData: string;
  districtNumber: string;
  districtName: string;
  cities: any[];
  hideElement: boolean = true;
  hideElement1: boolean = true;
  assignUserRole: string;
  emailField: string;
  disable: boolean = false;
  defaultName: string;
  user: any;
  UserProfile: FormGroup;
  userRoles: number;
  dropDownList: any;
  dropDownName: any;
  display: boolean = false;
  selectedUserRole: any = null;
  UserRoles: any = null;
  errorMessageShow: any;
  errorShow: boolean = false;
  userRolesList: any;
  isAreaRegion: boolean = false;
  list1: any[];
  searching: boolean = false;
  userProfile: any;
  multiSelectList: any[];
  list_data: any[] = [];
  isStoreDist: boolean = false;
  selected_value: any;
  userProfileData:any;
  disableFields:boolean = null;
  title:any = 'Add User';
  name:any;
  isSaveDisable:boolean = false;

  constructor(
    private _userservice: UserProfileService, 
    private _headerservice: HeaderserviceService, 
    private utility: Util, 
    private spinner: NgxSpinnerService, 
    private _router: Router, 
    private _location: Location, 
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.userProfile = SessionDetails.GetUserProfile();
    this.multiSelectList = [];
    this.UserProfile = this._fb.group({
      FirstName: ['', null],
      LastName: ['', null],
      Email: ['', Validators.required],
      // Phone: ['', Validators.required]
    });
    this.GetUserRoles();
    this.hideElement = true;
    if(SessionDetails.getProfilePk()!==null)
    {

      this.title = 'Add New User Role';
      this.disableFields = true;
      this.userProfileData = SessionDetails.getUserProfileData();
      this.UserProfile.controls['FirstName'].setValue(this.userProfileData.firstName);
      this.UserProfile.controls['LastName'].setValue(this.userProfileData.lastName);
      this.UserProfile.controls['Email'].setValue( this.userProfileData.email);
    }
  }
  public GetUserRoles(): void {
    this._userservice.GetUserRoles().subscribe((res:any) => {
      this.userRolesList = res.filter(i=>i.roleId!==2 && i.roleId!==3 && i.roleName.toLowerCase().search('test')===-1 );
    });
  }

  onChange(assignedLocation) {
    this.emailField = "";
    this.disable = false;
    this.assignUserRole = assignedLocation.toLowerCase();
    if (assignedLocation !== null && assignedLocation !== "") {
      this._userservice.GetAvailableLocations(assignedLocation).subscribe((res:any) => this.assignments = res);
      this.hideElement = false;
    }
    else {
      this.hideElement = true;
    }
  }
  onAssignmentChange(selectedDistrict) {
    this.emailField = "";
    this.disable = false;

    if (this.assignUserRole === "district manager" && selectedDistrict !== null) {
      this.emailField = "d" + selectedDistrict + ".dm@walgreens.com";
      this.disable = true;
    }
    else if (this.assignUserRole === "pharmacy manager" && selectedDistrict !== null)
      this.emailField = "rxm." + selectedDistrict + "@store.walgreens.com";
    // else if (this.assignUserRole === "district manager" && selectedDistrict !== null)
    //   this.emailField = "d" + selectedDistrict + ".dm@walgreens.com";

  }
  userRoleChange(event) {
    this.list_data = [];
    this.selected_value=null;
    this.selectedUserRole=null;
    this.userRoles = event;
    this.isAreaRegion = false;
    this.isStoreDist = false;
    this.display = false;
    if (event !== 'Admin') {
      this.display = true;
    }
    switch (event) {

      case 'Admin':
      this.userRoles = 1;
     break; 
      case 'Regional Healthcare Director':
         this.userRoles = 11;
        this.isAreaRegion = true;
        this.dropDownName = 'Region';
        this.name = 'Region';
        this.bindDropdownList(event);
        break;
      case 'Regional Vice President':
      this.userRoles = 10;
        this.isAreaRegion = true;
        this.dropDownName = 'Region';
        this.name = 'Region'
        this.bindDropdownList(event);
        break;
      case 'Healthcare Supervisor':
      this.userRoles = 8;
        this.isAreaRegion = true;
        this.dropDownName = 'Area';
        this.name = 'Area';
        this.bindDropdownList(event);
        break;
      case 'Director - Rx and Retail Ops':
      this.userRoles = 9;
        this.isAreaRegion = true;
        this.dropDownName = 'Area';
        this.name = 'Area';
        this.bindDropdownList(event);
        break;
      case 'District Manager':
        this.userRoles = 5;
        this.isStoreDist = true;
        this.dropDownName = 'District';
        this.name = 'District';
        this.bindDropdownList(event);
        break;
      case 'CP User':
          this.userRoles = 13; 
         break;
      case 'Pod Healthcare Supervisor':
        this.userRoles = 14;
        this.isAreaRegion = true;
        this.dropDownName = 'Pod';
        this.name = 'Pod';
        this.bindDropdownList(event);
        break;
      // case 'Pharmacy Manager':
      // this.userRoles = 2;
      //   //this.isStoreDist = true;
      //   this.dropDownName = 'Store';
      //   this.bindDropdownList(event);
      //   break;
      // case 'Store Manager':
      // this.userRoles = 3;
      // //  this.isStoreDist = true;
      //   this.dropDownName = 'Store';
      //   this.bindDropdownList(event);
      //   break;


    }
    // // var index = this.userRoles.findIndex(it => it.user_role === event.defaultValue);
    // // if (index == -1) {
    // //   this.userRoles.splice(index, 1);
    // // }
    // this.userRoles.push({ "user_role": event.defaultValue });
  }
  addUserProfile() {
       if (this.UserProfile.valid) {
      this.isSaveDisable = true;
      var user_roles = ['11', '10', '8','9','5', '14'];
      let obj = new UserProfileModel();
      obj.profile_model = new Array<UserProfile>();
      if(user_roles.indexOf(this.userRoles.toString())>-1)
      {
        if (this.list_data.length==0)
        {
          this.errorShow = true;
          this.errorMessageShow = "A user must have at least one assignment";
          this.isSaveDisable = false;
          return;
        }
      }
      if (this.list_data.length >= 1) {
        this.list_data.forEach(item => {
          let profile_model = new UserProfile();
          profile_model.firstName = this.UserProfile.controls["FirstName"].value;
          profile_model.lastName = this.UserProfile.controls["LastName"].value;
          profile_model.email = this.UserProfile.controls["Email"].value;
          profile_model.address = null;
          profile_model.address2 = null;
          profile_model.city = null;
          profile_model.state = null;
          profile_model.zip = null;
          //profile_model.phone = this.UserProfile.controls["Phone"].value;
          profile_model.fax = null;
          profile_model.company = null;
          profile_model.created = new Date();
          //profile_model.company = null;
          profile_model.createdBy = this.userProfile.email;
          profile_model.isDeleted = false;
          profile_model.updatedOn = new Date();
          //profile_model.updatedBy = null;
          profile_model.areaCode = null;
          profile_model.suiteNumber = null;
          profile_model.username =  this.getuserName(this.userRoles, this.UserProfile.controls["Email"].value);
          profile_model.userRole = this.userRoles;
          profile_model.password = null;
          profile_model.assignUserRole = Number(item.value.split('-')[0]);
          profile_model.isGenericEmail = false;
          profile_model.isEdit = SessionDetails.getProfilePk()!==null;
          obj.profile_model.push(profile_model);
        });
      }
      else {
        //
       // this.list_data.forEach(item=>{
        let profile_model = new UserProfile();
        profile_model.firstName = this.UserProfile.controls["FirstName"].value;
        profile_model.lastName = this.UserProfile.controls["LastName"].value;
        profile_model.email = this.UserProfile.controls["Email"].value;
        profile_model.address = null;
        profile_model.address2 = null;
        profile_model.city = null;
        profile_model.state = null;
        profile_model.zip = null;
       // profile_model.phone = this.UserProfile.controls["Phone"].value;
        profile_model.fax = null;
        profile_model.company = null;
        profile_model.created = new Date();
        //profile_model.company = null;
        profile_model.createdBy =  this.userProfile.email;
        profile_model.isDeleted = false;
        profile_model.updatedOn = new Date();
        //profile_model.updatedBy = null;
        profile_model.areaCode = null;
        profile_model.suiteNumber = null;
        profile_model.username =  this.getuserName(this.userRoles, this.UserProfile.controls["Email"].value);
        profile_model.userRole = this.userRoles;
        profile_model.password = null;
        profile_model.assignUserRole =SessionDetails.GetStoreId(); //Number(item.value.split('-')[0]);
        profile_model.isGenericEmail = false;
        profile_model.isEdit = SessionDetails.getProfilePk()!==null;
        obj.profile_model.push(profile_model);
      
      }
       
      this.spinner.show();
      this._userservice.AddUserProfile(obj, -1).subscribe((data:any) => {
        this.spinner.hide();
        if (data.errorS !== null) {
          if (data.errorS.errorCode !== 0) {
            this.errorShow = true;
            this.errorMessageShow = data.errorS.errorMessage;
            this.isSaveDisable = false;
            return;
          }
        }
        this._router.navigateByUrl("/communityoutreach/userprofile");
      },
        err => {
          this.spinner.hide();
          this.isSaveDisable = false;
          switch (err) {
            case 500:
              alert('ErrorCode 500 received from Service');
              break;
            case 400:
              alert('Unhandled exception error code received from Service');
              break;
            default:
              alert('Unhandled exception error code received from Service');
              break;
          }
        });
    }
    else {
      this.utility.validateAllFormFields(this.UserProfile);
    }
  }
  error() {
    this.errorShow = false;
  }

  Cancel() {
    this._location.back();
  }
  getuserName(user_role, user_email): string {
    let user_name = '';
    var email = user_email.split("@")[0];
    switch (user_role) {
      case 1:
        user_name = 'admin_' + email + '';
        return user_name;

      case 10:
        user_name = 'rvp_' + email + '';
        return user_name;

      case 11:
        user_name = 'rhd_' + email + '';
        return user_name;

      case 8:
        user_name = 'hcs_' + email + '';
        return user_name;

      case 5:
        user_name = 'dm_' + email + '';
        return user_name;

      case 2:
        user_name = 'pm_' + email + '';
        return user_name;

      case 3:
        user_name = 'sm_' + email + '';
        return user_name;
       case 9:
        user_name = 'dpr_' + email + '';
        return user_name;
       case 13: 
       user_name = 'cp_' + email + '';
       return user_name;
       case 14:
        user_name = 'pod_' + email + '';
        return user_name;

    }
  }
  bindDropdownList(user_role) {
    this.dropDownList = [];
    this.spinner.show();
    this._userservice.bindDropDownList(user_role).subscribe((data) => {

      this.dropDownList = data;
      this.spinner.hide();
    },
      err => {
        switch (err) {
          case 500:
            alert('ErrorCode 500 received from Service');
            break;
          case 400:
            alert('Unhandled exception error code received from Service');
            break;
          default:
            alert('Unhandled exception error code received from Service');
            break;
        }
      });
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  isFieldValid(field: string) {
    if (this.UserProfile.get(field) !== null)
      return !this.UserProfile.get(field).valid && this.UserProfile.get(field).touched;
  }
  formatter = (result: any) => result.storeId + " - " +
    result.address + ", " + result.city + ", "
    + result.state + " " + result.zip || '';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this._headerservice.storeSearch(this.userProfile.userPk, term.trim(), 0).pipe(
          tap(() => {
            // this.searchFailed = false
          }),
          catchError(() => {
            // this.searchFailed = true;
            return observableOf([]);
          }),)),
      tap(() => this.searching = false),);

  onStoreSelect(item) {
    this.selected_value = item.item.storeId + " - " +
      item.item.address + ", " + item.item.city + ", "
      + item.item.state + " " + item.item.zip || '';

  }
  onSelect(value) {
    this.selected_value = value;
    this.selectedUserRole = value;
  }
  AddRole() {
    
    if(this.selected_value!==undefined && this.selected_value!==null && this.selected_value!=='' && this.selected_value!=="null")
    {
    var index = this.list_data.findIndex(item => item.value.split('-')[0] === this.selected_value.split('-')[0]);
    if (index === -1) {
      this.list_data.push({ "value": this.selected_value });
      this.selected_value = null;
      this.selectedUserRole = null;
    }
  }
  }
  DeleteRole(delete_data) {
    
    var index = this.list_data.findIndex(item => item.value.split('-')[0] === delete_data.value.split('-')[0]);
    // if(this.list_data.length==1)
    // {
    //   this.errorShow = true;
    //   this.errorMessageShow = 'Sorry, you cannot remove this assignment. A user must have at least one assignment.';
    //   return;
    // }
    if (index > -1) {
      this.list_data.splice(index, 1);
    }
  }
}
