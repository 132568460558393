export const HintMessage: any = 
{	"contractapprove": {
		"admin": [{
				"hintHdng": "<h3>contractapprove Page admin User Heading 1</h3>",
				"hintMsg": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
			},
			{
				"hintHdng": "<h3>contractapprove Page admin User Heading 2</h3>",
				"hintMsg": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
			},
			{
				"hintHdng": "<h3>contractapprove Page admin User Heading 3</h3>",
				"hintMsg": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
			}
		]
	},
	"charityprogram": {
		"admin": [{
			"hintHdng": "<h3>Charity (WAG Free Flu Voucher) Details</h3>",
			"hintMsg": "<p class='mb-0'>1. Check the 'No Clinic - Voucher Distribution Only ' checkbox if there will not be a clinic held and you will only be providing vouchers to the opportunity.<br/><br/>2. If there will not be a clinic, just enter the contacts first and last name, phone, email, estimated shots and vouchers distributed. If there will be a clinic, also complete the clinic address, date and times.<br/><br/>3. Click 'Add Location +' to add more clinic locations and repeat the process from the beginning. When adding more locations, two setup options will become available.<br/></p><ul class='mt-0 mb-15'><li>Copy Previous Contact Info - Copies contact info from the location above it.</li><li>Copy Previous Location - Copies the address info from the location above it.</li></ul><p class='mt-0'>4. Click 'Submit' to complete the Charity (WAG Free Flu Voucher) setup. Once completed a Charity (WAG Free Flu Voucher) event will appear in the Upcoming Events list.</p>"
		}]
	},
	"communityoutreach": {
		"admin": [{
				"hintHdng": "<h3>Step 1 - Immunizations and Payment Methods</h3>",
				"hintMsg": "<p class='mb-0'>1. Select an Immunization.<br/>2. Select a Payment Method.<br/>3. Click the 'Add +' button.<br/><ul><li>The selections and rate will be added to the agreement.</li></ul>4. If additional immunizations or payment methods need to be added, repeat the process from the beginning until they are all displayed under 'Added Immunizations and Payment Methods'. Otherwise click 'Next' to continue or 'Save' to continue later.</p>"
			},
			{
				"hintHdng": "<h3>Step 2 - Clinic Locations</h3>",
				"hintMsg": "<p class='mb-0'>1. Enter the Estimated Shots for the first location.<br/><br/>2. Enter all of the contact, address, date and time information.<br/><br/>3. Click 'Add Clinic +' to add more clinics and repeat the process from the beginning. When adding more clinics, two setup options will become available.</p> <ul  class='mt-0 mb-15'><li>Copy Previous Contact Info - Copies contact info from the location above it.</li><li> Copy Previous Location - Copies the address info from the location above it.</li></ul><p  class='mt-0'>4. Select the Outreach Type that best describes the event you are setting up. If 'Other', enter your own description in the text field.<br/><br/>5. Click 'Submit' to complete the Community Outreach setup. Once completed a Community Outreach event will appear in the Upcoming Events list.</p>"
			}
		]
	},
	"equityevent": {
		"admin": [{
				"hintHdng": "<h3>Step 1 - Immunizations and Payment Methods</h3>",
				"hintMsg": "<p class='mb-0'>1. Select an Immunization.<br/>2. Select a Payment Method.<br/>3. Click the 'Add +' button.<br/><ul><li>The selections and rate will be added to the agreement.</li></ul>4. If additional immunizations or payment methods need to be added, repeat the process from the beginning until they are all displayed under 'Added Immunizations and Payment Methods'. Otherwise click 'Next' to continue or 'Save' to continue later.</p>"
			},
			{
				"hintHdng": "<h3>Step 2 - Clinic Locations</h3>",
				"hintMsg": "<p class='mb-0'>1. Check the 'No Clinic - Voucher Distribution Only' checkbox if there will not be a clinic held and you will only be providing vouchers to the opportunity.<br/><br/>2. If there will not be a clinic, enter the contacts first and last name, phone, email, estimated shots and vouchers distributed. If there will be a clinic, also complete the clinic address, date and times.<br/><br/>3. Click 'Add Clinic +' to add more clinics and repeat the process from the beginning. When adding more clinics, two setup options will become available.</p> <ul  class='mt-0 mb-15'><li>Copy Previous Contact Info - Copies contact info from the location above it.</li><li> Copy Previous Location - Copies the address info from the location above it.</li></ul><p  class='mt-0'>4. Select the Outreach Type that best describes the event you are setting up. If 'Other', enter your own description in the text field.<br/><br/>5. Click Submit to complete the Equity Event setup. Once completed an Equity Event will appear in the Upcoming Events list.</p>"
			}
		]
	},
	"charityprogramdetails ": {
		"admin": [{
				"hintHdng": "<h3>charityprogramdetails Page admin User Heading 1</h3>",
				"hintMsg": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
			},
			{
				"hintHdng": "<h3>charityprogramdetails Page admin User Heading 2</h3>",
				"hintMsg": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
			},
			{
				"hintHdng": "<h3>charityprogramdetails Page admin User Heading 3</h3>",
				"hintMsg": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
			}
		]
	},
	"corporateclinicprogramdetails": {
		"admin": []
	},
	"localclinicprogramdetails": {
		"admin": [{
				"hintHdng": "<h3>An Overview</h3>",
				"hintMsg": "<p>Clinic Details is where you will manage the individual clinic.</p><ul><li>Use it to review and update the details of the clinic prior to confirming or hosting a clinic.</li><li>Change the status by confirming or completing a clinic or cancel it when necessary.</li><li>Enable Appointment Scheduling.</li><li>Download clinic related documents, such as Vouchers and VAR forms.</li><li>Reassign the clinic</li><li>View the Approved Agreement</li><li>View the History Log for the clinic</li><li>Amend an Agreement’s immunizations and payment methods, update estimated quantities, and add clinic locations (Amendments will require the client to review, approve and sign the amended contract).</li></ul>"
			},
			{
				"hintHdng": "<h3>Changing Clinic Status - Steps 1 & 2</h3>",
				"hintMsg": "<p>All clinic events will start with Step 1 - Contract Approved completed.<p><p>Step 2 - Confirm Clinic</p><ol><li>Use the contact and phone number in the green 'Opportunity Information' section to contact and confirm the clinic. When confirming, go over all of the details in the magenta 'Billing & Vaccine Information' and orange 'Clinic Information' sections.</li><li>Determine with the client if the clinic requires Appointment Scheduling. If so, check one of the options in the orange 'Clinic Information' section under 'Enable Appointment Scheduling'. Options for appointment scheduling may not be available for all clinics. If there are multiple clinics for a client a scheduler will need to be enabled for each clinic individually.</li><li>Once all clinic details are confirmed with the contact, enter the name of the contact in the 'Confirmed By' field and click the 'Confirm' button to complete Step 2 - Confirmed Clinic. If the event is 'Vouchers Only', confirm that the vouchers were sent and received by the client, enter the name of the contact in the 'Confirmed By' field, and click the 'Provide Vouchers' button to complete Step 2 - Vouchers Provided.</li></ol>"
			},		
			{
				"hintHdng": "<h3>Appointment Scheduling</h3>",
				"hintMsg": "<p>Patients can select a specific appointment timeframe to improve patient flow.</p><ol><li>Enable Appointment Scheduling by checking one of the scheduling options (where available) in the orange 'Clinic Information' section.</li><li>Click 'Save Changes' or 'Confirm' to save the change.</li><li>An email will be sent to the clinic contact with links to the Appointment Scheduler and Appointment Tracking for the clinic. The client will be responsible for sharing the emailed scheduling link with their employees.</li><li>The 'Appointment Scheduling' section will open in the Clinic Details, providing you with links and a QR code to access the Scheduler and Appointment Tracking as well as links to share with the client if they need to be resent. If the Community Outreach Scheduler option was selected, this section will show the number of immunizers needed based on the estimated shots and duration of the clinic, as well as the required immunizers based on the registered appointments.</li><li>Registration is open through the day of the clinic.</li></ol>"
			},
			{
				"hintHdng": "<h3>History Log</h3>",
				"hintMsg": "<p>If you have any questions regarding when or by whom a clinic status or detail was updated, you can refer to the grey 'History Log' at the bottom of the page.</p>"
			},
			{
				"hintHdng": "<h3>Amending an Agreement</h3>",
				"hintMsg": "<p>Click ‘Amend Agreement’ to <b>add clinic locations, amend vaccine selections, payment methods, estimated quantities, and/or voucher options</b>. These changes will require a new agreement to be sent, approved and signed by the client contact. The initial agreement will be retained until the amended agreement has been approved and signed. Changes to the clinic dates and times do not require an amended contract and should be changed in the Clinic Details screen prior to amending an agreement.</p><ul><li>An amendment may be made after the clinic has been performed.</li><li>Vouchers may be added by including a 'Voucher: Corporate to Invoice Employer Directly' payment method.</li><li>Original Group IDs will be maintained.</li></ul>"
			},
			{
				"hintHdng": "<h3>Completing a Clinic - Steps 3</h3>",
				"hintMsg": "<p>The final step for most clinics is completing it.</p><ol><li>Enter the 'Total Administered' immunizations in the magenta 'Billing & Vaccine Information' for each immunization. If the clinic has had the CURA Patient Appointment Scheduler enabled these fields will be auto-filled.</li><li>Enter any notes about the clinic in the magenta 'Billing & Vaccine Information' 'section under Comments/Instructions'.</li><li>Click the 'Complete' button.</li></ol><p>If the clinic was cancelled, enter the reason in the magenta 'Billing & Vaccine Information' section under 'Comments/Instructions' and click the 'Cancel Clinic' button, answer the 'Who Cancelled the Clinic' question, and confirm Cancelling the clinic to Complete the clinic.</p>"
			}
		]
	},
	"equityeventprogramdetails": {
		"admin": [{
				"hintHdng": "<h3>An Overview</h3>",
				"hintMsg": "<p>Event Details is where you will manage the individual event.</p><ul><li>Use it to review and update the details of the event prior to hosting a clinic or providing vouchers.</li><li>Change the status by completing a clinic or cancel it when necessary.</li><li>Enable Appointment Scheduling.</li><li>Reassign the clinic.</li><li>View the History Log for the event.</li><li>Amend immunizations and payment methods.</li><li>Update estimated quantities.</li><li>Add clinics.</li></ul>"
			},
			{
				"hintHdng": "<h3>Appointment Scheduling</h3>",
				"hintMsg": "<p>Patients can select a specific appointment timeframe to improve patient flow.</p><ol><li>Enable Appointment Scheduling by checking 'Community Outreach Portal Scheduler' in the orange 'Clinic Information' section.</li><li>Click 'Save Changes' to save the change.</li><li>An email will be sent to the clinic contact with links to the Appointment Scheduler and Appointment Tracking for the clinic. The client will be responsible for sharing the emailed scheduling link with their employees.</li><li>The 'Appointment Scheduling' section will open in the Clinic Details, providing you with links and a QR code to access the Scheduler and Appointment Tracking as well as links to share with the client if they need to be resent. This section will show the number of immunizers needed based on the estimated shots and duration of the clinic, as well as the required immunizers based on the registered appointments.</li><li>Registration is open through the day of the clinic.</li></ol>"
			},
			{
				"hintHdng": "<h3>History Log</h3>",
				"hintMsg": "<p>If you have any questions regarding when or by whom a clinic status or detail was updated, you can refer to the grey 'History Log' at the bottom of the page.</p>"
			},
			{
				"hintHdng": "<h3>Amending an Agreement</h3>",
				"hintMsg": "<p>Click 'Amend Immunizations & Billing' to <b>amend vaccine selections and payment methods</b>. Changes to the clinic location, dates and times do not require an amendment and should be changed in the Event Details screen prior to amending the immunizations and billing.</p><ul><li>An amendment may be made after the clinic has been performed.</li><li>Vouchers may be added for the Influenza - Standard Injectable Quadrivalent by including a 'WAG Free Flu Voucher' payment method.</li></ul>"
			},
			{
				"hintHdng": "<h3>Completing an Event - Step 2</h3>",
				"hintMsg": "<p>Equity events will automatically be Confirmed, so you will only be required to Complete the clinic.</p><ol><li>Enter the 'Total Administered' immunizations in the magenta 'Billing & Vaccine Information' for each immunization.</li><li>Enter the 'Vouchers Redeemed' for each of the 'Total Administered' redeemed using a voucher.</li><li>Enter any notes about the clinic in the magenta 'Billing & Vaccine Information' 'section under Comments/Instructions'.</li><li>Click the 'Complete' button.</li></ol><p>If the clinic was cancelled, enter the reason in the magenta 'Billing & Vaccine Information' section under 'Comments/Instructions' and click the 'Cancel Clinic' button, answer the 'Who Cancelled the Clinic' question, and confirm Cancelling the clinic to Complete the clinic.</p>"
			}
		]
	},
	"communityoutreachprogramdetails": {
		"admin": [{
				"hintHdng": "<h3>An Overview</h3>",
				"hintMsg": "<p>Clinic Details is where you will manage the individual clinic.</p><ul><li>Use it to review and update the details of the clinic prior to hosting it.</li><li>Change the status by completing a clinic or cancel it when necessary.</li><li>Enable Appointment Scheduling.</li><li>Reassign the clinic.</li><li>View the History Log for the event.</li><li>Amend immunizations and payment methods.</li><li>Update estimated quantities.</li><li>Add clinics.</li></ul>"
			},
			{
				"hintHdng": "<h3>Appointment Scheduling</h3>",
				"hintMsg": "<p>Patients can select a specific appointment timeframe to improve patient flow.</p><ol><li>Enable Appointment Scheduling by checking 'Community Outreach Portal Scheduler' in the orange 'Clinic Information' section.</li><li>Click 'Save Changes' to save the change.</li><li>An email will be sent to the clinic contact with links to the Appointment Scheduler and Appointment Tracking for the clinic. The client will be responsible for sharing the emailed scheduling link with their employees.</li><li>The 'Appointment Scheduling' section will open in the Clinic Details, providing you with links and a QR code to access the Scheduler and Appointment Tracking as well as links to share with the client if they need to be resent. This section will show the number of immunizers needed based on the estimated shots and duration of the clinic, as well as the required immunizers based on the registered appointments.</li><li>Registration is open through the day of the clinic.</li></ol>"
			},
			{
				"hintHdng": "<h3>History Log</h3>",
				"hintMsg": "<p>If you have any questions regarding when or by whom a clinic status or detail was updated, you can refer to the grey 'History Log' at the bottom of the page.</p>"
			},
			{
				"hintHdng": "<h3>Amending an Agreement</h3>",
				"hintMsg": "<p>Click 'Amend Immunizations & Billing' to <b>amend vaccine selections and payment methods</b>. Changes to the clinic location, dates and times do not require an amendment and should be changed in the Clinic Details screen prior to amending the immunizations and billing.</p><ul><li>An amendment may be made after the clinic has been performed.</li></ul>"
			},
			{
				"hintHdng": "<h3>Completing an Event - Step 2</h3>",
				"hintMsg": "<p>Community Outreach clinics will automatically be Confirmed, so you will only be required to Complete the clinic.</p><ol><li>Enter the 'Total Administered' immunizations in the magenta 'Billing & Vaccine Information' for each immunization.</li><li>Enter any notes about the clinic in the magenta 'Billing & Vaccine Information' 'section under Comments/Instructions'.</li><li>Click the 'Complete' button.</li></ol><p>If the clinic was cancelled, enter the reason in the magenta 'Billing & Vaccine Information' section under 'Comments/Instructions' and click the 'Cancel Clinic' button, answer the 'Who Cancelled the Clinic' question, and confirm Cancelling the clinic to Complete the clinic.</p>"
			}
		]
	},
	"landing": {
		"admin": [{
			"hintHdng": "<h3>Need Help?</h3>",
			"hintMsg": "<p>If you're new to the Walgreens Outreach Portal or just need a hint on the next step to take, these helpful hint boxes will provide some answers and direction.</p><p>Once you're comfortable on your own, you can turn the hints off by checking the box at the bottom of the hints. But don't worry, if you need them there is a switch to turn them back on below the Walgreens logo in the footer of the portal.</p>"
		}, {
			"hintHdng": "<h3>Getting Started</h3>",
			"hintMsg": "<p>Click on the 'Community Outreach' button in the banner or one of the icons below to enter your Community Outreach Portal. The Immunization and Senior Outreach icons will open to outreach opportunities, while the Patient Care & Advocacy icon will open to PCA Organizations.<br/><br/>Please take note of any messages from Walgreens Administration below the icons. These messages will provide notices regarding current issues and reminders.<br/><br/>Stats provided at the bottom of the screen will give you an idea of how Outreach Programs are doing overall and how your contributions are adding up.</p>"
		}],
		"director – rx and retail ops, healthcare supervisor, regional vice president, regional healthcare director": [{
			"hintHdng": "<h3>Need Help?</h3>",
			"hintMsg": "<p>If you're new to the Walgreens Outreach Portal or just need a hint on the next step to take, these helpful hint boxes will provide some answers and direction.</p><p>Once you're comfortable on your own, you can turn the hints off by checking the box at the bottom of the hints. But don't worry, if you need them there is a switch to turn them back on below the Walgreens logo in the footer of the portal.</p>"
		}, {
			"hintHdng": "<h3>Getting Started</h3>",
			"hintMsg": "<p>Click on the 'Community Outreach' button in the banner or one of the icons below to enter your Community Outreach Portal. The Immunization and Senior Outreach icons will open to outreach opportunities, while the Patient Care & Advocacy icon will open to organizations.<br/><br/>Please take note of any messages from Walgreens Administration below the icons. These messages will provide notices regarding current issues and reminders.<br/><br/>Stats provided at the bottom of the screen will give you an idea of how Outreach Programs are doing overall and how your contributions are adding up.</p>"
		}],
		"district manager": [{
			"hintHdng": "<h3>Need Help?</h3>",
			"hintMsg": "<p>If you're new to the Walgreens Outreach Portal or just need a hint on the next step to take, these helpful hint boxes will provide some answers and direction.</p><p>Once you're comfortable on your own, you can turn the hints off by checking the box at the bottom of the hints. But don't worry, if you need them there is a switch to turn them back on below the Walgreens logo in the footer of the portal.</p>"
		}, {
			"hintHdng": "<h3>Getting Started</h3>",
			"hintMsg": "<p>Click on the 'Community Outreach' button in the banner or one of the icons below to enter your Community Outreach Portal. The Immunization and Senior Outreach icons will open to outreach opportunities, while the Patient Care & Advocacy icon will open to organizations.<br/><br/>Please take note of any messages from Walgreens Administration below the icons. These messages will provide notices regarding current issues and reminders.<br/><br/>Stats provided at the bottom of the screen will give you an idea of how Outreach Programs are doing overall and how your contributions are adding up.</p>"
		}],
		"store managersm": [{
			"hintHdng": "<h3>Need Help?</h3>",
			"hintMsg": "<p>If you're new to the Walgreens Outreach Portal or just need a hint on the next step to take, these helpful hint boxes will provide some answers and direction.</p><p>Once you're comfortable on your own, you can turn the hints off by checking the box at the bottom of the hints. But don't worry, if you need them there is a switch to turn them back on below the Walgreens logo in the footer of the portal.</p>"
		}, {
			"hintHdng": "<h3>Getting Started</h3>",
			"hintMsg": "<p>Click on the 'Community Outreach' button in the banner or one of the icons below to enter your Community Outreach Portal. The Immunization and Senior Outreach icons will open to outreach opportunities, while the Patient Care & Advocacy icon will open to organizations.<br/><br/>Please take note of any messages from Walgreens Administration below the icons. These messages will provide notices regarding current issues and reminders.<br/><br/>Stats provided at the bottom of the screen will give you an idea of how Outreach Programs are doing overall and how your contributions are adding up.</p>"
		}]
	},
	"storehome": {
		"admin": [{
				"hintHdng": "<h3>What are Outreach Opportunities?</h3>",
				"hintMsg": "<p>The list of Outreach Opportunities represents local businesses and organizations that have the potential to respond to one or more of the Walgreens Community Outreach programs. New opportunities will be added at the beginning of May each year. However, stores are also encouraged to add new opportunities of their own, by clicking the \"+\" add button at the top of the Opportunities list.<br/><br/>Each opportunity is listed with its phone number for contacting and the programs that are suggested for that opportunity. Additional information on the opportunity can be found by clicking on the opportunity and selecting Opportunity Profile from the menu.</p>"
			},
			{
				"hintHdng": "<h3>What Do I Do With These Outreach Opportunities?</h3>",
				"hintMsg": "<p>Your task is to try to develop a relationship with these opportunities by offering Walgreens' services through outreach programs. The suggested programs for that opportunity and their status are listed below the opportunity information. IP for Immunization Program and SR for Senior Outreach.</p>"
			},
			{
				"hintHdng": "<h3>Making Contact</h3>",
				"hintMsg": "<p>Opportunities are sorted by default with Previous Clients, marked with a green star, sorted to the top and listed in order of employee count (largest to smallest).  The sort order may be changed using the drop down at the top of the list to sort opportunities based on priority, previous clients, employee count, date contacted or outreach program. Priority opportunities flagged with a red exclamation point (submitted a contact form) or red mail icon (indicated they were interested in a clinic by email) should always be contacted as soon as possible. Otherwise, retaining previous clients should be a stores highest priority when deciding which opportunities to call first.<br/><br/>Prior to making a call, review some of the resource information and sample call script found under Resources. Looking for specific information about an Opportunity? Click the opportunity name and an actions menu will provide options to select:<br/><br/><b>View History</b> - Review a full current and past contact log and event history.<br/><br/><b>Opportunity Profile</b> - View or edit the full opportunity profile, including outreach programs</p>"
			},
			{
				"hintHdng": "<h3>After the Call</h3>",
				"hintMsg": "<p>After every contact, you will need to log the opportunity's response.<br/><br/>1. Click the opportunity name to open the actions menu and select Log Contact.<br/><br/>2. Review or fill in the contact information<br/><br/>3. Select the Outreach Program, if the opportunity is assigned to more than one.<br/><br/>4. Select an Outreach Status. This status will decide if further action should be taken with the opportunity. Additional information, forms or contracts may be required.<br/><br/>Once submitted the new status will appear for that program under Outreach Opportunities and additional action menu items will be provided based on the status. These will guide you to a contract, event form or follow-up reminder.</p>"
			},
			{
				"hintHdng": "<h3>Scheduled Events</h3>",
				"hintMsg": "<p>All approved clinics and senior events for the current season/year will appear in the right column.</p><p><b>Upcoming Events</b> - Lists events you will need to complete in order as well as their status.</p><p><b>Past Events</b> - Lists events past the current date along with their status.</p><p>Click on the events to view the details of that event and log status changes for clinics.</p>"
			},
			{
				"hintHdng": "<h3>Add Local Outreach Opportunities</h3>",
				"hintMsg": "<p>You are encouraged to add your own local opportunities by clicking the add (+) icon in the Outreach Opportunities header.</p>"
			},
			{ 
				"hintHdng": "<h3>Workflow Tasks</h3>",
				"hintMsg": "<p>When you have the following tasks that need attention, they will appear in colored blocks at the top of the screen along with the number of these tasks to complete.<br/><br/>Contact Client/Organization<br/>Host Event<br/>Complete<br/>Follow-Up<br/>Schedule SR Events</p>"
			}
		]
	},
	"home": {
		"admin, regional vice president, regional healthcare director, director - rx and retail ops": [{
				"hintHdng": "<h3>HCS Dashboard</h3>",
				"hintMsg": "<p>The dashboard will provide a heads up view of the Immunization Program. Guiding you on the responsibilities of the Healthcare Supervisors, District Managers and stores.</p><p><b>HCS Action Items</b> - The counts under the HCS column indicate all of the action items that require immediate attention from an HCS in the selected Area. The counts under the Store column indicate those actions for the currently selected store.</p><p><b>Metrics</b> - These counts are some key indicators across the Immunization Program.</p>"
			},
			{
				"hintHdng": "<h3>Intake Form Leads Awaiting Response</h3>",
				"hintMsg": "<ul><li>This dashboard is designed to help identify which leads are outstanding and require a response.</li><li>The dashboard will display leads that have accumulated within the last 90 days.</li><li>Field leaders have the ability to respond directly from the dashboard, enhancing efficiency and response time.</li></ul>"
			},
			{
				"hintHdng": "<h3>Viewing and Responding to Leads</h3>",
				"hintMsg": "<p>Choose an action from the menu located in the last column of 'Intake Form Leads Awaiting Response'.</p><ul><li><b>View Full Submission</b> to see all of the form selections and responses.</li><li><b>Reassign</b> to assign the opportunity to a different store than the store that was automatically assigned as the closest store.</li><li><b>Respond</b> 'Yes' or 'No' to the request.</li><ul><li>If the 'Yes' option is selected, the opportunity will be added to the store and marked as a priority.</li><li>If the 'No' option is selected, the opportunity will still be added to the store, but will be automatically logged as 'No Client Interest' with a disposition of 'Capacity Constraiint'.</li><li>In both cases, an email will be sent to the opportunity with the response.</li><li>Once responded to, the lead will be removed from 'Intake Form Leads Awaiting Response' and be shown in the 'Priority Opportunities to be Contacted'.</li></ul></ul>"
			},
			{
				"hintHdng": "<h3>Clinic Assignments</h3>",
				"hintMsg": "<p>Corporate, Local and Equity clinics requiring attention from an HCS or DM will show up in Clinics under Assigned to HCS and Assigned to DM. Clinics that have been assigned will show up under Assigned to Store.</p>"
			},
			{
				"hintHdng": "<h3>Approving Clinics</h3>",
				"hintMsg": "<p>If a Healthcare Supervisor or District Manager wants to approve the automated assignment and notify the store:</p><p class='mb-0'>1. Check the box in the first column of all of the clinics you want to approve.<br/><br/>2. Select the Bulk Action 'Approve & Email to Store'.<br/><br/>3. Clicking 'Submit Entry' will add the clinic to the assigned store's Upcoming Events list and will send an automated  email notifying the store to confirm the clinic with the client.<br/><br/></p><p>Once sent, the clinic will also appear under the Assigned to Store tab if other changes are required.</p>"
			},
			{
				"hintHdng": "<h3>Reassigning Clinics</h3>",
				"hintMsg": "<p>To reassign a clinic to a different store, DM or HCS:</p><p class='mb-0'>1. Click the Actions Menu icon in the last column to reassign an individual clinic to a different store, DM or HCS. To reassign multiple clinics to the same store, check the clinics and select 'Reassign' in the Bulk Actions.<br/><br/>2. To complete the reassignment, check one or more clinics and select the Bulk Action you would like to perform. This would depend on if it's approved to go to the store or if you want another DM or HCS to assign it to a store.<br/><br/></p><p> If you select 'Assign & Email to DM' an automated email will be sent to the DM asking them to approve and assign the clinic to a store. These clinics will now appear under the Assigned to District tab, until the district assigns the clinic to a store. Likewise , 'Assign & Email to HCS' will notify the HCS and move the clinic to their dashboard.</p>"
			},
			{
				"hintHdng": "<h3>Local Opportunities</h3>",
				"hintMsg": "<p>Healthcare Supervisors may be assigned Local Opportunities to review from the Assigned Local Opportunities. Based on their assessment, they may call the opportunity or assign it to either a District Manager, store or another Healthcare Supervisor to have them make the contact.<br/><br/>It's important to note that these opportunities are not visible to the stores while they are assigned to the HCS or DM.</p>"
			},
			{
				"hintHdng": "<h3>Priority Opportunities to be Contacted</h3>",
				"hintMsg": "<p>The “Priority Opportunities to be Contacted” tab is designed to highlight all the priority opportunities that have no outreach.</p><ul><li>These opportunities are those that have come from the web form after we have confirmed our ability to support, as well as from the email blast where the opportunity has indicated interest by clicking on 'I’m interested' but remain in 'No Outreach' status.</li><li>The aim is to ensure that every lead receives a response and that we protect as many patients as possible. Team members are to share information on vaccination offerings and suggest an off-site clinic, voucher program, or our in-store option if it suits the client’s needs.</li></ul>"
			},
			{
				"hintHdng": "<h3>Approving Local Opportunities</h3>",
				"hintMsg": "<p>To simply approve the automated assignment and notify the store:</p><p class='mb-0'>1. Check the box in the first column of all of the opportunities you want to approve.<br /><br/>2. Select the Bulk Action 'Approve & Email to Store'.<br/><br/>3. Clicking 'Submit Entry' will add the opportunity to the assigned store's Outreach Opportunities list and will send an automated  email notifying the store to contact the assigned opportunities.<br/><br/></p><p> Once sent, the assigned local opportunity will appear under the Assigned Local Opportunities tab if other changes are required. If you would like to Initiate the Contract yourself after assigning the business, you may navigate to the Store interface and initiate the contract as usual.</p>"
			},
			{
				"hintHdng": "<h3>Reassigning Local Opportunities</h3>",
				"hintMsg": "<p>To reassign opportunities to a different store, DM or HCS:</p><p class='mb-0'>1. Click the Actions Menu icon in the last column to reassign an individual opportunity to a different store, DM or HCS. To reassign multiple opportunities to the same store, check the clinics and select 'Reassign' in the Bulk Actions.<br /><br/>2. To complete the reassignment, check one or more opportunities and select the Bulk Action you would like to perform. This would depend on if it's approved to go to the store or if you want another DM or HCS to assign it to a store.<br/><br/></p><p> If you select 'Assign & Email to DM' an automated email will be sent to the DM asking them to approve and assign the opportunity to a store. These opportunities will now appear under the Local Opportunities Assigned to DM tab, until the district assigns the opportunity to a store. Likewise , 'Assign & Email to HCS' will notify the HCS and move the clinic to their dashboard.</p>"
			},
			{
				"hintHdng": "<h3>Inappropriate or Closed Opportunities</h3>",
				"hintMsg": "<p>If an opportunity does not seem appropriate to assign to a store or you have contacted the opportunity and it is closed, the opportunity status should be changed to 'Business Closed.' This status will insure that the business is removed with the annual data reset.<br/><br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'Business Closed' from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.<br/></p>"
			}
			,
			{
				"hintHdng": "<h3>Contact & Log Status Yourself</h3>",
				"hintMsg": "<p>If you decide to contact the opportunity yourself and want to log the contact, do one of the following:<br/><br/>Scheduled a Clinic<br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'HCS Contacted' from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.<br/>5. You will then need to assign the opportunity to a store to Initiate a Contract or schedule a clinic. Start by checking the box in the first column.<br/>6. Select the Bulk Action 'Approve & Email to Store'<br/>7. Clicking 'Submit Entry' will add the opportunity to the assigned store's Outreach Opportunities list and allow you to go to the store schedule the clinic.<br/><br/>No Client Interest<br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'No Client Interest'  from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.<br/></p>"
			}
		],
		"district manager": [{
				"hintHdng": "<h3>Clinic Assignments</h3>",
				"hintMsg": "<p>Corporate, Local and Equity clinics requiring your attention will show up in Clinics under the Assigned to DM tab.</p><p>As the District Manager, you are required to review,  and assign these clinics to the appropriate store. The Store # column will provide a store selected for the clinic based on closest location. You can either reassign the clinic to a different store or approve and email the store the assignment.</p>"
			},
			{
				"hintHdng": "<h3>Approving Clinics</h3>",
				"hintMsg": "<p>To simply approve the automated assignment and notify the store:</p><p class='mb-0'>1. Check the box in the first column of all of the clinics you want to approve.<br/><br/>2. Select the Bulk Action 'Approve & Email to Store'.<br/><br/>3.  Clicking 'Submit Entry' will add the clinic to the assigned store's Upcoming Events list and will send an automated  email notifying the store to confirm the clinic with the client.</p><p>Once sent, the clinic will also appear for you under the Assigned to Store tab if other changes are required.</p>"
			},
			{
				"hintHdng": "<h3>Reassigning Clinics</h3>",
				"hintMsg": "<p>To reassign a clinic to a different store, DM or HCS:</p><p class='mb-0'>1. Click the Actions Menu icon in the last column to reassign an individual clinic to a different store, DM or HCS. To reassign multiple clinics to the same store, check the clinics and select 'Reassign' in the Bulk Actions.<br/><br/>2. To complete the reassignment, check one or more clinics and select the Bulk Action you would like to perform. This would depend on if it's approved to go to the store or if you want another DM or HCS to assign it to a store.<br/><br/></p><p>   If you select 'Assign & Email to DM' an automated email will be sent to the DM asking them to approve and assign the clinic to a store. These clinics would then move to the other District Manager's dashboard. Likewise , 'Assign & Email to HCS' will notify the HCS and move the clinic to their dashboard.</p>"
			},
			{
				"hintHdng": "<h3>Local Opportunities</h3>",
				"hintMsg": "<p>As the District Manager, you may be asked to review the assignments of Local Opportunities. Based on your assessment, you may call the opportunity yourself or assign it to a store or another District Manager to have them make the contact.<br/><br/>It's important to note that these opportunities are not visible to the stores while they are in your queue.</p>"
			},
			{
				"hintHdng": "<h3>Approving Local Opportunities</h3>",
				"hintMsg": "<p>To simply approve the automated assignment and notify the store:</p><p class='mb-0'>1. Check the box in the first column of all of the opportunities you want to approve.<br /><br/>2. Select the Bulk Action 'Approve & Email to Store'.<br/><br/>3. Clicking 'Submit Entry' will add the opportunity to the assigned store's Outreach Opportunities list and will send an automated  email notifying the store to contact the assigned opportunities.<br/><br/></p><p> Once sent, the assigned local opportunity will appear under the Assigned Local Opportunities tab if other changes are required. If you would like to Initiate the Contract yourself after assigning the business, you may navigate to the Store interface and initiate the contract as usual.</p>"
			},
			{
				"hintHdng": "<h3>Reassigning Local Opportunities</h3>",
				"hintMsg": "<p>To reassign opportunities to a different store, DM or HCS:</p><p class='mb-0'>1. Click the Actions Menu icon in the last column to reassign an individual opportunity to a different store, DM or HCS. To reassign multiple opportunities to the same store, check the clinics and select 'Reassign' in the Bulk Actions.<br /><br/>2. To complete the reassignment, check one or more opportunities and select the Bulk Action you would like to perform. This would depend on if it's approved to go to the store or if you want another DM or HCS to assign it to a store.<br/><br/></p><p>  If you select 'Assign & Email to DM' an automated email will be sent to the DM asking them to approve and assign the opportunity to a store. These opportunities would then move to the other District Manager's dashboard. Likewise , 'Assign & Email to HCS' will notify the HCS and move the clinic to their dashboard.</p>"
			},
			{
				"hintHdng": "<h3>Inappropriate or Closed Opportunities</h3>",
				"hintMsg": "<p>If an opportunity does not seem appropriate to assign to a store or you have contacted the opportunity and it is closed, the opportunity status should be changed to 'Business Closed.' This status will insure that the business is removed with the annual data reset.<br/><br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'Business Closed' from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.</p>"
			},
			{
				"hintHdng": "<h3>Contact & Log Status Yourself</h3>",
				"hintMsg": "<p>If you decide to contact the opportunity yourself and want to log the contact, do one of the following:<br/><br/>Scheduled a Clinic<br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'HCS Contacted' from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.<br/>5. You will then need to assign the opportunity to a store to Initiate a Contract or schedule a clinic. Start by checking the box in the first column.<br/>6. Select the Bulk Action 'Approve & Email to Store'<br/>7. Clicking 'Submit Entry' will add the opportunity to the assigned store's Outreach Opportunities list and allow you to go to the store schedule the clinic.<br/><br/>No Client Interest<br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'No Client Interest'  from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.</p>"
			}
		],
		"healthcare supervisor": [{
				"hintHdng": "HCS Dashboard",
				"hintMsg": "<p>The dashboard will provide a heads up view of the Immunization Program in your area. Guiding you on your responsibilities and your stores'.</p><p><b>HCS Action Items</b> - The counts under the HCS column indicate all of the action items that require immediate attention in your area. The counts under the Store column indicate those actions for the currently selected store. To change the current store, use the 'Select Store' select menu in the upper right corner of the page.</p><p><b>Metrics</b> - These counts are some key indicators that should be followed to keep stores on track within your area.</p>"
			},
			{
				"hintHdng": "<h3>Clinic Assignments</h3>",
				"hintMsg": "<p>Corporate, Local and Equity clinics requiring your attention will show up in Clinics under the Assigned to HCS tab.</p><p>As the Healthcare Supervisor, you are required to review,  and assign these clinics to the appropriate store or to a DM who can select the appropriate store. The Store # column will provide a store selected for the clinic based on closest location. You can either reassign the clinic to a different store or approve and email the store the assignment.</p>"
			},
			{
				"hintHdng": "<h3>Approving Clinics</h3>",
				"hintMsg": "<p>To simply approve the automated assignment and notify the store:</p><p class='mb-0'>1. Check the box in the first column of all of the clinics you want to approve.<br/><br/>2. Select the Bulk Action 'Approve & Email to Store'.<br/><br/>3.  Clicking 'Submit Entry' will add the clinic to the assigned store's Upcoming Events list and will send an automated  email notifying the store to confirm the clinic with the client.</p><p>Once sent, the clinic will also appear for you under the Assigned to Store tab if other changes are required.</p>"
			},
			{
				"hintHdng": "<h3>Reassigning Clinics</h3>",
				"hintMsg": "<p>To reassign a clinic to a different store, DM or HCS:</p><p class='mb-0'>1. CClick the Actions Menu icon in the last column to reassign an individual clinic to a different store, DM or HCS. To reassign multiple clinics to the same store, check the clinics and select 'Reassign' in the Bulk Actions.<br/><br/>2. To complete the reassignment, check one or more clinics and select the Bulk Action you would like to perform. This would depend on if it's approved to go to the store or if you want another DM or HCS to assign it to a store.<br/><br/></p><p> If you select 'Assign & Email to DM' an automated email will be sent to the DM asking them to approve and assign the clinic to a store. These clinics will now appear under the Assigned to District tab, until the district assigns the clinic to a store. Likewise , 'Assign & Email to HCS' will notify the HCS and move the clinic to their dashboard.</p>"
			},
			{
				"hintHdng": "<h3>Local Opportunities</h3>",
				"hintMsg": "<p>As the Healthcare Supervisor, you may be assigned Local Opportunities to review. Based on your assessment, you may call the opportunity yourself or assign it to either a District Manager, store or another Healthcare Supervisor to have them make the contact.<br/><br/>It's important to note that these opportunities are not visible to the stores while they are in your queue.</p>"
			},
			{
				"hintHdng": "<h3>Approving Local Opportunities</h3>",
				"hintMsg": "<p>To simply approve the automated assignment and notify the store:</p><p class='mb-0'>1. Check the box in the first column of all of the opportunities you want to approve.<br /><br/>2. Select the Bulk Action 'Approve & Email to Store'.<br/><br/>3. Clicking 'Submit Entry' will add the opportunity to the assigned store's Outreach Opportunities list and will send an automated  email notifying the store to contact the assigned opportunities.<br/><br/></p><p> Once sent, the assigned local opportunity will appear under the Assigned Local Opportunities tab if other changes are required. If you would like to Initiate the Contract yourself after assigning the business, you may navigate to the Store interface and initiate the contract as usual.</p>"
			},
			{
				"hintHdng": "<h3>Reassigning Local Opportunities</h3>",
				"hintMsg": "<p>To reassign opportunities to a different store, DM or HCS:</p><p class='mb-0'>1. Click the Actions Menu icon in the last column to reassign an individual opportunity to a different store, DM or HCS. To reassign multiple opportunities to the same store, check the clinics and select 'Reassign' in the Bulk Actions.<br /><br/>2. To complete the reassignment, check one or more opportunities and select the Bulk Action you would like to perform. This would depend on if it's approved to go to the store or if you want another DM or HCS to assign it to a store.<br/><br/></p><p> If you select 'Assign & Email to DM' an automated email will be sent to the DM asking them to approve and assign the opportunity to a store. These opportunities will now appear under the Local Opportunities Assigned to DM tab, until the district assigns the opportunity to a store. Likewise , 'Assign & Email to HCS' will notify the HCS and move the clinic to their dashboard.</p>"
			},
			{
				"hintHdng": "<h3>Inappropriate or Closed Opportunities</h3>",
				"hintMsg": "<p>If an opportunity does not seem appropriate to assign to a store or you have contacted the opportunity and it is closed, the opportunity status should be changed to 'Business Closed.' This status will insure that the business is removed with the annual data reset.<br/><br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'Business Closed' from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.</p>"
			},
			{
				"hintHdng": "<h3>Contact & Log Status Yourself</h3>",
				"hintMsg": "<p>If you decide to contact the opportunity yourself and want to log the contact, do one of the following:<br/><br/>Scheduled a Clinic<br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'HCS Contacted' from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.<br/>5. You will then need to assign the opportunity to a store to Initiate a Contract or schedule a clinic. Start by checking the box in the first column.<br/>6. Select the Bulk Action 'Approve & Email to Store'<br/>7. Clicking 'Submit Entry' will add the opportunity to the assigned store's Outreach Opportunities list and allow you to go to the store schedule the clinic.<br/><br/>No Client Interest<br/>1. Click the Actions Menu icon in the last column.<br/>2. Select 'Change Status.'<br/>3. Select 'No Client Interest'  from the 'IP Outreach Status' menu.<br/>4. Click 'Save' to save the status change.</p>"
			}
		]
	},
	"pcastorehome": {
		"admin": [{
				"hintHdng": "<h3>What are PCA Organizations?</h3>",
				"hintMsg": "<p>PCA Organizations or Partners are specific to the Patient Care & Advocacy Events (PCA) Outreach Program. They are health departments and organizations that have teamed up with Walgreens to provide HIV, Hepatitis C and STI testing and screening services, HIV, Hepatitis C and STI counseling to individuals, Organ Donation Registration, and Mobile Mammography education & events.<br/><br/>Sort by \"PCA Organizations,\" to view the list of organizations assigned to a store.</p>"
			},
			{
				"hintHdng": "<h3>What Do I Do With These PCA Organizations?</h3>",
				"hintMsg": "<p>If your store is eligible* to host a testing or screening event, you may contact one of the testing organizations (Health Department, AIDS Service Organization, Community Based Organization or Cancer Screening Organizations) to hold an event at your location.<br/><br/>Any store may host an Organ Donor Registration event by contacting an Organ Procurement Organization.<br/><br/>* Eligible stores are those that have a clean and clutter free private health room with a door.</p><ul><li>Break rooms and training rooms are NOT an option for a testing location.</li><li>A testing organization can test at a Walgreens without a private health room if and only if the organization has a mobile testing unit and the store has an acceptable parking lot to accommodate the mobile testing unit.</li></ul>"
			},
			{
				"hintHdng": "<h3>What if I Don't See Any PCA Organizations?</h3>",
				"hintMsg": "<p>You may assign existing PCA Organizations or add a new organization to a store by clicking the add (+) icon at the top of the PCA Organizations/Outreach Opportunities list and then select 'Add PCA Organization.'</p>"
			},
			{
				"hintHdng": "<h3>Making Contact</h3>",
				"hintMsg": "<p>Opportunities are sorted by default with Previous Clients, marked with a green star, sorted to the top and listed in order of employee count (largest to smallest). The sort order may be changed using the drop down at the top of the list to sort opportunities based on program, date contacted or employee count. Opportunities flagged with a red exclamation point should always be contacted as soon as possible. Otherwise, retaining previous clients should be a stores highest priority when deciding which opportunities to call first.<br/><br/>Prior to making a call, review some of the resource information and sample call script found under Resources. Looking for specific information about an Opportunity? Click the opportunity name and an actions menu will provide options to select:<br/><br/><b>View History</b> - Review a full current and past contact log and event history.<br/><br/><b>Opportunity Profile</b> - View or edit the full opportunity profile, including outreach programs</p>"
			},
			{
				"hintHdng": "<h3>After the Call</h3>",
				"hintMsg": "<p>After every contact, you will need to log the organization's response. <br/><br/>1. Click the organization's name to open the actions menu and select Log Contact.<br/>3. Review or fill in the contact information<br/>4. Select an Outreach Status. This status will decide if further action should be taken with the opportunity. Additional information, forms or contracts may be required.<br/><br/>Once submitted, the last contact and new status will appear under the organization's name and additional screens will appear based on the status selected. These will guide you to the setup of a Limited Use License Agreement or a follow-up reminder.</p>"
			},
			{
				"hintHdng": "<h3>Scheduled Events</h3>",
				"hintMsg": "<p>All approved events will appear in the right column.<br/><br/><b>Upcoming Events</b> - Lists events you will need to complete in order as well as their status. <br/><b>Past Events</b> - Lists events past the current date along with their status.<br/><br/>Click on the events to view the details of that event and log status changes for those events.</p>"
			},
			{
				"hintHdng": "<h3>Workflow Tasks</h3>",
				"hintMsg": "<p>When you have the following tasks that need attention, they will appear in colored blocks at the top of the screen along with the number of these tasks to complete.<br/><br/>Contact Client/Organization<br/>Host Event<br/>Complete<br/>Follow-Up<br/>Schedule SR Events</p>"
			}, {
				"hintHdng": "<h3>Add PCA Organizations</h3>",
				"hintMsg": "<p>You may assign existing organizations or enter a new organization to a store by clicking the add (+) icon at the top of the store organization list and then select Add Organization.</p>"
			}
		]
	},
	"soresources": {
		"admin": []
	},
	"imzresources": {
		"admin": []
	},
	"userprofile": {
		"admin": []
	},
	"pcadetails": {
		"admin": [{
			"hintHdng": "<h3>Changing Event Status - Steps 1 & 2</h3>",
			"hintMsg": "<p>All PCA events will start with 'Step 1 - LULA Approved' completed.<br/><br/>'Step 2 - Confirm Event' will require a Walgreens user to complete.<br/><br/>   1. Use the contact and phone number in the green 'PCA Organization Information' section to contact and confirm the event. When confirming, go over the details in the magenta 'Services' and orange 'Location & Event' sections.<br/>   2. Once confirmed, enter the contact's name in the 'Confirmed By' field in the orange section and click the 'Confirm' button towards the bottom of the screen, completing Step 2.</p>"
		}, {
			"hintHdng": "<h3>Completing an Event - Step 3</h3>",
			"hintMsg": "<p>The final step to complete for any PCA event is 'Step 3 - Completed Event'.<br/><br/>   1. Enter the 'Actual Attendance,' 'Preliminary Positives' or 'Registered Donors' and any comments regarding the event in the blue 'Feedback/Notes' section.<br/>   2. Click the 'Complete' button at the bottom of the screen to complete the clinic.<br/><br/>If the PCA event was cancelled, enter the reason into the 'Additional Comments/Instructions' text area located in the  blue 'Feedback/Notes' section. Then click the 'Cancel Clinic' button to complete the clinic.</p>"
		}, {
			"hintHdng": "<h3>History Log</h3>",
			"hintMsg": "<p>If you have any questions regarding when or by whom an event  status or detail was updated, you can refer to the grey 'History Log' at the bottom of the page.</p>"
		}]
	},
	"addpca": {
		"admin": [{
			"hintHdng": "<h3>Search for an Existing Partner</h3>",
			"hintMsg": "<p>Before adding a PCA Organization by completing the lower form, check the Existing Partner list at the top of the screen to see if the organization has already been added to the portal for your state. If it is there, select the name and click the 'Add +' button. Review and update the organization's information where necessary and click 'Save.' The PCA Organization will be added to your store.</p>"
		}, {
			"hintHdng": "<h3>Add a New Organization</h3>",
			"hintMsg": "<p>If the organization is not found in the list of Existing Partners for your state, complete the Organization profile and click 'Save.'</p>"
		}]
	},
	"pcadashboard": {
		"admin": [{
			"hintHdng": "<h3>Patient Care & Advocacy Events Dashboard</h3>",
			"hintMsg": "<p>The dashboard will provide a heads up view of the Patient Care & Advocacy Events contracting and events.<br/><br/>RVP Action Items - The counts under the RVP column indicate all of the action items that require immediate attention from a RVP. The counts under the Store column indicate those actions for the currently selected store. Click on the numbers in these columns to open a full report. To change the current store, use the 'Select Store' select menu in the upper right corner of the page.<br/><br/>Metrics - These counts are some key indicators that should be followed to keep your region on track. Click on the report names to review the full report.<br/><br/></p>"
		}],
		"regional vice president, regional healthcare director": [{
			"hintHdng": "<h3>Patient Care & Advocacy Events Dashboard</h3>",
			"hintMsg": "<p>The dashboard will provide a heads up view of the Patient Care & Advocacy Events contracting and events in your region. Guiding you on your responsibilities and your stores'.<br/><br/>RVP Action Items - The counts under the RVP column indicate all of the action items that require immediate attention in your region. The counts under the Store column indicate those actions for the currently selected store. Click on the numbers in these columns to open a full report. To change the current store, use the 'Select Store' select menu in the upper right corner of the page.<br/><br/>Metrics - These counts are some key indicators that should be followed to keep your region on track. Click on the report names to review the full report.</p>"
		}]
	},
	"rhdcontractagreement": {
		"admin, regional healthcare director, regional vice president": [{
			"hintHdng": "<h3>Review, Approve and Sign</h3>",
			"hintMsg": "<p>1. An RVP will need to review the agreement that has been signed by the partnering organization in detail.<br/>2. Download and review the proof of insurance document(s).<br/>3. Check Approve or Reject in the side panel. If Approving, you will also need to electronically sign the document. If Rejecting, enter the revised location, date and time.<br/>4. Click 'Submit' and an email will be sent to the organization and the store with a link to the completed agreement for printing or download if approved, otherwise your rejection and revisions will be sent to the initiator of the agreement.<br/><br/>After approval, the scheduled event will appear under Upcoming Events for the hosting store.</p>"
		}] 
	},
	"scheduleevent": {
		"admin, director – rx and retail ops, healthcare supervisor, regional vice president, regional healthcare director, district manager, store manager": [{
			"hintHdng": "<h3>Scheduling a Senior Event</h3>",
			"hintMsg": "<p class='mb-0'>1.  Enter the event date, start time and end time along with the estimated number of attendees.<br/><br/>2. Select an event type.<br/><br/>3. Select the role Walgreens will play in the selected event. <br /><br /> 4. Check 'Yes' or 'No' for providing educational materials and describe those materials in the text area. <br /><br />5.  Click 'Save' to complete the scheduling of the SR Event. Once completed a SR event will appear in the Upcoming Events list</p>"
		}]
	},
	"followUp": {
		"admin": [{
				"hintHdng": "<h3>Follow-Up Reminder</h3>",
				"hintMsg": "<p>Logging Follow-Up as an opportunity's status provides the option to have an email reminder sent for a specific follow-up date. By default, the follow-up reminder will be set to send an email to the person who logged the Follow-Up status seven days from the current date. You may choose to change the email to assign the follow-up call to someone else, as well as changing the date to fit the contact's schedule.</p><p>You may also choose to set the status to Follow-up, but turn off the email reminder by checking the 'Do not send an email reminder' checkbox.</p><p>Please note, you must save your selections for the Follow-Up. If you cancel, the contact status will not be logged.</p>"
			},
			{
				"hintHdng": "<h3>Follow-Up Reminders</h3>",
				"hintMsg": "<p>Viewing Follow-Up Reminders allows you to review and edit a specific opportunity or testing organization's follow-up settings for all Outreach Programs. You may change the email to assign the follow-up call to someone else, modify the date the email reminder will be sent or turn the reminder on or off all together.</p>"
			}
		]
	},
	"viewcontactlogHistory": {
		"admin": [{
			"hintHdng": "<h3>Navigating the History</h3>",
			"hintMsg": "<p>The Contact Log History provides insight into an opportunity's or organization's activity within Outreach Programs. Each contact log or event is shown by date and logged status change. These logs are broken up into current  and past logs. <br/><br/>Click the log to view additional detail. Some logs will provide a link to a contract, follow up or event form where applicable. If a log is entered in error, it can also be removed by clicking the Remove Log Entry link.</p>"
		}]
	},
	"contract": {
		"admin": [{
				"hintHdng": "<h3>Step 1 - Immunizations and Payment Methods</h3>",
				"hintMsg": "<p class='mb-0'>1. Select an Immunization.<br/>2. Select a Payment Method.<br/>3. Click the 'Add +' button to add the selections to the agreement.<br/>4. If additional immunizations or payment methods need to be added, repeat steps 1-3 until they are all displayed under 'Added Immunizations and Payment Methods'.<br/>5. Complete the Invoice & Voucher Information section for invoicing the clinic service fee and any direct bill payment methods selected.<br/>6. Click 'Next' to continue or 'Save' to continue later.</p>"
			},
			{
				"hintHdng": "<h3>Step 2 - Clinic Locations</h3>",
				"hintMsg": "<p class='mb-0'>1. Enter the Estimated Number of Participants<br/><ul><li>There is a minimum of 50 participants per clinic.</li><li>A Clinic Service fee will be applied to each clinic based on the number of participants per clinic, excluding Voucher Only events (See Table 2, in Step 4, to see how Clinic Fees are calculated).</li></ul>2. Select desired Setup Options.<ul><li>No Clinic (Voucher Distribution Only) - Select this option to reduce the number of required fields when you are only distributing vouchers and no clinic will be performed. This option is only available when the 'Voucher: Corporate to Invoice Employer Directly' payment method has been selected.</li><li>Re-assign based on geography - Select this option when entering clinic locations that are not located near the contracting store location. The clinic will get automatically reassigned to the closest store location.</li></ul>3. Enter the Estimated Number of Shots per Immunization<br/><ul><li>Only enter the quantity that will be provided at a clinic, do not include the shots to be provided with a Voucher in stores.</li><li>Enter a '0' if a particular shot will not be provided at one of the clinic locations or only vouchers will be provided for the shot.</li></ul>4. Enter all of the contact, address, date and time information.<br/>5. Click 'Add Clinic +' to add more clinics and repeat the process from the beginning. When adding more clinics, two more setup options will be provided.<br/><ul><li>Copy Previous Contact Info - Copies contact info from the clinic above it.</li><li>Copy Previous Location - Copies the address info from the clinic above it .</li></ul>6. Click 'Next' to continue or 'Save' to continue later.</p>"
			},
			{
				"hintHdng": "<h3>Step 3 - Walgreens Pharmacy Contact Information</h3>",
				"hintMsg": "<p class=\"mb-0\">1. Enter the name, title, email and phone number of the Walgreens employee, who will be shown as the Walgreens' signer and contact on the contract. The employee listed will be required to handle any questions or changes related to the contract. The Date field will be prefilled with the current date but may be changed to reflect a different date if the contract will not be submitted that day.<br/><br/>2. Click 'Next' to continue or 'Save' to continue later.</p>"
			}, 
			{
				"hintHdng": "<h3>Step 4 - Review & Send</h3>",
				"hintMsg": "<p>1. Review the agreement in detail. Note: The client signor information and Legal Notice Address will be entered by the client.</p><p>2. If any data is missing or you find an error click on the Steps at the top of the screen to return to a previous step or click the 'Back' button at the bottom of the screen.</p><p>3. If no additional changes are required, enter the email address of the person you want to have approve the contract in the right column. </p><p>4. Review and Agree to the Service Fee Acknowledgement.</p><p>5. Click 'Send Agreement' to email the client an access link to complete and approve the agreement or 'Save Agreement' to send later.</p><p>6. When the client approves the agreement, the contracting store will receive an email and the clinics will appear under Upcoming Events. Take the next steps to confirm the clinic by clicking on the event.</p>"
			}
		]
	},
	"amendcontract": {
		"admin": [{
				"hintHdng": "<h3>Step 1 - Immunizations and Payment Methods</h3>",
				"hintMsg": "<p class='mb-0'>1. Select an Immunization.<br/>2. Select a Payment Method.<br/>3. Click the 'Add +' button.<br/><ul><li>The selections and rate will be added to the agreement.</li></ul>4. If additional immunizations or payment methods need to be added, repeat the process from the beginning until they are all displayed under 'Added Immunizations and Payment Methods'. Otherwise click 'Next' to continue.</p>"
			},
			{
				"hintHdng": "<h3>Step 2 - Clinic Locations</h3>",
				"hintMsg": "<p class='mb-0'>1. Enter the Estimated Shots for newly added immunizations. Note: There is a minimum of 50 shots per location, but this may be divided among multiple immunizations where applicable.<br/>2. To add clinic locations, click the 'Add A Clinic Location' button in the header of the orange 'Clinic Information' section. Fill out the additional location form that appears below the original.<br/>2. Click 'Next' to continue.<br/><br/>Adding a location will create a new clinic event under your 'Upcoming Events' with its own Clinic Details.</p>"
			},
			{
				"hintHdng": "<h3>Step 3 - Pharmacy Information</h3>",
				"hintMsg": "<p class=\"mb-0\">1. Update the Pharmacy Information of the contracting store if necessary.<br/><br/>2. Click 'Next' to continue.</p>"
			}, 
			{
				"hintHdng": "<h3>Step 4 - Review & Send</h3>",
				"hintMsg": "<p>1. Review the agreement in detail.</p><p>2. If anything is missing or you find an error, click on the Steps at the top of the screen to return to a previous step or click the 'Back' button at the bottom of the screen.</p><p>3. If no additional changes are required, enter the email address of the person you want to have approve the contract in the right column. </p><p>4. Click Send Agreement to email the client an access link to complete and approve the amended agreement. Note: If the amended agreement is not sent, your amendments will not be saved.</p><p>5. When the client approves the amended agreement, the contracting store will receive an email and the amended agreement will replace the original agreement. If the amended agreement is not approved or rejected the original agreement will be maintained.</p>"
			}
		]
	},
	"asolula": {
		"admin": [{
			"hintHdng": "<h3>Step 1 - Services</h3>",
			"hintMsg": "<p>1. Select a Service from the dropdown menu.<br/>2. Click the 'Add +' button and the selection and rate will be added to the agreement.<br/>3. Repeat steps 1 and 2 to add multiple services to the agreement.<br/>4. Click 'Next' to continue or 'Save' to continue later.<br/><br/>Click the 'X' button to remove a service from the agreement.</p>"
		}, {
			"hintHdng": "<h3>Step 2 - Location & Events</h3>",
			"hintMsg": "<p>1. Complete the 'Partnering Contact & Event Location' information.<br/><ul><li>The Contact is the individual at the partnering organization who is authorized to sign the LULA. Enter their name, title, phone, and email.</li></ul><br/>2. Review and check at least one of the Location Requirement options in order to accommodate a testing event at your store.<ul><li>The event location will always be the store address and store # of the contracting store and is not editable.</li></ul><br/>3. Complete the 'Event' Information.  <ul><li>Enter the Estimated Attendance for the first event.<br/></li>   <li>Select the event date and start and end times.</li></ul><br/>4. Click 'Add Event +' to add multiple event dates and/or times .<br/>5. Click 'Next' to continue or 'Save' to continue later.</p>"
		}, {
			"hintHdng": "<h3>Step 3 - Walgreens Signer</h3>",
			"hintMsg": "<p>1. Review the Walgreens Signer information. Make sure the correct regional email is entered, since the RVP from that region will be required to approve and sign the completed LULA.<br/><br/>2. Click 'Next' to continue or 'Save' to continue later.<br/><br/>Only a Regional VP's email associated with your store's region will be accepted as a valid entry.</p>"
		}, {
			"hintHdng": "<h3>Step 4 - Review & Send</h3>",
			"hintMsg": "<p>1. Review the agreement in detail. Note: The signature fields in the agreement will be completed by the testing organization and Walgreens RVP.</p><p>2. If any corrections are required, click on the 'Edit Agreement' button, or click through the Steps at the top of the screen, or click the 'Back' button at the bottom of the screen.</p><p>3. If all of the setup information is correct, enter the email address of the contact you want to have approve and sign the agreement in the right column.</p><p>4. Click 'Send Agreement' to email the partnering organization an access link to complete and approve the agreement or 'Save Agreement' to send later.</p><p>5. When the partnering organization approves the agreement, the Walgreens RVP from Step 3 will automatically receive an email to review and sign the LULA. Once the RVP submits the signed agreement, the store, RVP and the partnering organization will receive an emailed link to the final agreement.<br/><br/>The scheduled event will appear under Upcoming Events once the LULA has been signed by both parties. Take the next steps to confirm the clinic by clicking on the event.</p>"
		}, {
			"hintHdng": "<h3>Review, Approve & Sign</h3>",
			"hintMsg": "<p>1. An RVP will need to review the agreement that has been signed by the partnering organization in detail.<br/><br/>2. Download and review the proof of insurance document(s).<br/><br/>3. Check Approve or Reject in the side panel. If Approving, you will also need to electronically sign the document. If Rejecting, enter the revised location, date and time or other reason for rejection.<br/><br/>4. Click 'Submit' and an email will be sent to the testing organization and the store with a link to the completed agreement for printing or download if approved, otherwise your rejection and revisions will be sent to the initiator of the agreement.<br/><br/>After approval, the scheduled testing event will appear under Upcoming Events for the hosting store.</p>"
		}]
	},
	"edit_user": {
		"admin": [{
			"hintHdng": "<h3>User Management</h3>",
			"hintMsg": "The User Management screen provides a full listing of all users <p>with access to the Community Outreach Portal and allows admins to add, remove or editing users.<br/><br/>To locate a specific user:<br/><br/>1. Select a specific User Role to search by, from the dropdown filter at the top of the screen, if you know the user's role. Otherwise, select 'All' as the User Role.<br/><br/>2. Search by email, first or last name, region number, area number, district number or store number. Please note that user role based email accounts will not have a name associated with them.<br/><br/>3. Results may be sorted by column in ascending or descending order by clicking on a column header.<br/><br/>4. Users with multiple assignments or roles will appear in multiple rows.</p>"
		}, {
			"hintHdng": "<h3>Editing a User</h3>",
			"hintMsg": "<p>1. Once you have located the user you want to update, click the actions menu button.<br/><br/>2. Select 'Edit User Profile'<br/><br/>3. Modify any of the open fields. User role and email are not editable.<br/><br/>4. Based on the user role, additional stores, districts, areas and regions may be assigned to a user. Select the assignment from the dropdown and click the '+' add button and the assignment will be added to their list of assignments.<br/><br/>5.To remove assignments, click the 'X' button next to the assignment you want to remove. However, all users must have at least one assignment and user role based accounts cannot have their primary assignment removed.<br/><br/>6. Click 'Save' to update the User Profile.</p>"
		}, {
			"hintHdng": "<h3>Adding a User</h3>",
			"hintMsg": "<p>1. Select the '+ Add User' button to add a new user.<br/><br/>2. Enter the required 'Email' and select a 'User Role.'<br/><br/>3. Based on the selected role, add user assignments by selecting assignments from the dropdown menu and clicking the '+' button.<br/><br/>4. Click 'Save' to add the user to Community Outreach Portal.<br/><br/>5. An email with an access link will automatically be sent to the added user.<br/><br/><br/>Please note that you may not add user role based generic email users. Those are system generated.</p>"
		}, {
			"hintHdng": "<h3>Adding a New User Role</h3>",
			"hintMsg": "<p>1. Locate the user you want to add a new user role to and  click the actions menu button (New roles may only be applied to users using a personal email address account).<br/><br/>2. Click 'Add New User Role.'<br/><br/>3. Select the new role from the 'Role' dropdown.<br/><br/>4. Select the user assignment and add them with the '+' button.</p>"
		},{
			"hintHdng": "<h3>Removing a User</h3>",
			"hintMsg": "<p>1. Locate the user you want to remove from the Community Outreach Portal and  click the actions menu button.<br/><br/>2. Select 'Remove User' (This option will not be available for user role based generic email users).<br/><br/><br/>If a user has multiple assignments, removing the user will remove the user completely along with all assignments. If you only want to remove them from a single store, district area or region, select Edit User and remove the assignment from there. <br/><br/><br/>Before removing a Region user make sure they do not have any LULAs for Patient Care & Advocacy Events that need approval. These agreements require a specific regional user to sign them and will need to be revised if the user is removed.</p>"
		}]
	},
	"corporateScheduler": {
		"admin": [{
			"hintHdng": "<h3>Scheduler Site Setup</h3>",
			"hintMsg": "<p>All actions in the Appointment Scheduler begin by selecting a client from the 'Client' dropdown menu at the top of the page. Clients will only appear in this menu when 'Worksite Scheduler Needed' has a value of 'Y' for at least one uploaded clinic.<br/>Be sure to click 'Save' as you make updates. Any unsaved changes will be lost if you navigate away from the page without saving.</p>"
		}, {
			"hintHdng": "<h3>Scheduled Clinics Tab</h3>",
			"hintMsg": "<p>Once the client is selected, the 'Scheduled Clinics' tab will show all of the clinics that were uploaded with 'Worksite Scheduler Needed' as 'Y.' However, the clinics that you want to make available through the Scheduler must be checked in the far left column.<br/><br/>Some clinics may show, as disabled (greyed out). This means the clinic store assignment has not been approved by the HCS or the clinic is past its event date.<br/><br/>IMPORTANT: If you uncheck a clinic with appointments already scheduled and then save, all appointments will be cancelled and patients will immediately be sent a cancellation email. These appointments cannot be retrieved.<p>"
		}, {
			"hintHdng": "<h3>Scheduled Clinics Tab - Min & Max Immunizers</h3>",
			"hintMsg": "<p><b>'Min Immunizers'</b> is the minimum amount of immunizers that are required to perform the clinic based on the number of scheduled appointments. When 85% of the appointments are filled, the 'Min Immunizers' will automatically increase by one, until it reaches the value set for <b>'Max Immunizers,'</b> the maximum amount of immunizers  allowed for the event. If the 'Min' reaches the 'Max' and fills to 85%, the Client Services team member who setup the scheduler will receive an email alerting them to either increase the 'Max' or increase the clinic time to open more appointment slots.<p>"
		}, {
			"hintHdng": "<h3>Scheduled Clinics Tab - Sign-up End Date</h3>",
			"hintMsg": "<p>The <b>'Sign-up End Date'</b> is the last date, up until midnight, in which users can create appointments. The default date is set to the day prior to the event.<br/><br/>The Sign-up End Date can be changed to an earlier date, but cannot be set to the day of the clinic or past it, since cheduling on the day of the event would not allow the pharmacy to properly staff or prepare for the clinic.<br/><br/>If you want to prevent further appointments from being scheduled for the selected clinic without cancelling existing appointments, change the end date to yesterday's date.<br/><br/>Click 'Next' to move to the next setup tab.</p>"
		}]
	},
	"corporateSchedulerLogoNStyle": {
			"admin": [{"hintHdng": "<h3>Logo & Styles Tab - Masthead Logo</h3>",
			"hintMsg": "<p>By default, the <b>'Masthead Logo'</b> will display the Walgreens logo in the selected client's Scheduler Site. If requested, the client's logo can be displayed in place of the Walgreens logo, by selecting 'Client Logo' and uploading a logo.<br/><br/><b>Logo Specifications</b><br/><br/>Allowed Formats: .png, .jpg, .jpeg<br/><br/>Suggested Size: No larger than 280px wide and 110px high<br/><br/>Suggested Color Space: RGB<br/><br/>Be sure to 'Preview' the site to make sure the logo appears the way you expect it to.</p>"
		},{
			"hintHdng": "<h3>Logo & Styles Tab - Styles</h3>",
			"hintMsg": "<p><b>'Styles'</b> refer to the colors displayed in the scheduler site for the colored banner, text inside the banner, the button color and the text within the button.<br/><br/>Colors can be selected by entering a hex number, or more simply, by clicking the color square and using the color picker. You can return to the default colors by clicking 'Reset to Default Colors.'<br/><br/>Click 'Preview' to view your logo and color selections.<br/><br/>Click 'Next' to move to the next setup tab or 'Back' to update previous tabs.</p>"
		}]
	},
	"corporateSchedulerLandingPage": {
			"admin": [{
			"hintHdng": "<h3>Landing Page Text Tab</h3>",
			"hintMsg": "<p>The text that will appear on the landing page of the client's scheduler site can be customized on this screen. <br/><br/>Make sure to carefully proof all text changes and to 'Preview' and 'Save' your updates. <br/><br/>Click 'Reset to Default Text' to return to the default text.<br/><br/>Click 'Next' to move to the next setup tab or 'Back' to update previous tabs.</p>"
		}]
	},
	"corporateSchedulerFormFields": {
			"admin": [{
			"hintHdng": "<h3>Form Field & Site Status Tab</h3>",
			"hintMsg": "<p>The <b>'Site Status'</b> enables you to turn off the client's scheduling site, for all clinics, without cancelling any appointments.<br/><br/>The 'Form Fields' allow you to configure the fields that will appear and are required for the user to schedule their appointment. The 'Email' field is the only information that is absolutely required. All other fields can be configured based on the client's requirements.<br/><br/>Note: In order to check patients in at the clinic, it is helpful to have the user's name. Otherwise, the patient would be required to have the Confirmation ID provided in the Confirmation email.</p>"
		},{
			"hintHdng": "<h3>Access & Share the Scheduling & Tracking Sites</h3>",
			"hintMsg": "<p>At the bottom of the 'Form Field & Site Status' tab, there are buttons for accessing and sharing the scheduling and tracking sites.<br/><br/><b>Access Scheduler Site</b> - Access and review the Scheduler site. This will take you to the client's live scheduler site, so that you can review the way it looks and functions. Any appointments created will consume slots unless cancelled afterwards. <br/><br/><b>Share Scheduler Link</b> - Click this button to copy the Scheduler link that you will provide to the client for communications out to their employees.<br/><br/><b>Access Appointment Tracking</b> - Track the number of appointments scheduled and open, block and unblock appointments, and view reporting.<br/><br/><b>Share Appointment Tracking</b> - Click this button to copy the Appointment Tracking link that you will provide to the client's admin for tracking their clinic appointments. No employee information or appointment blocking function is provided from this tracking link.<br/><br/><b>Internal Only</b> Appointment reporting, with additional detail, is available through Reports > Immunization Program > Scheduled Appointments</p>"
		}]
	}
}