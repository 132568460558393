import { environment } from "../../../environments/environment";
import { covidDates, fluOppContactStatusDates, ipDates, srDates } from "../../JSON/startEndDatesForReports";
import { pcaDates } from "./../../JSON/pcaStartEndDates";
import { SessionDetails } from "../../utility/session";

export class ApplicationSettings {

  static groupIdLaunchDate="05/01/2019";
  static outreachStartDateIP="05/01/2024";
  static outreachStartDateCovid="09/01/2023";
  static reportStartYear="2014";
  static report_start_year: string;
  static pcaSeason = 2025; 
  static outreachEffortIP = 3;
  static outreachEffortSO =1;
  static outreach: any;
  constructor() {

  }
  public static getOutreachReportStartYear(): number {
    return parseInt(environment.reportStartYear);
  }

  public static getCurrentQuarter(): string {
    let quarter: string = "0";
    switch ((new Date()).getMonth() + 1) {
      case 6:
      case 7:
      case 8:
        quarter = "1";
        break;
      case 9:
      case 10:
      case 11:
        quarter = "2";
        break;
      case 12:
      case 1:
      case 2:
        quarter = "3";
        break;
      case 3:
      case 4:
      case 5:
        quarter = "4";
        break;
    }
    return quarter;
  }

  public static getSRCurrentQuarter(): string {
    let quarter: string = "0";
    switch ((new Date()).getMonth() + 1) {
      case 9:
      case 10:
      case 11:
        quarter = "1";
        break;
      case 12:
      case 1:
      case 2:
        quarter = "2";
        break;
      case 3:
      case 4:
      case 5:
        quarter = "3";
        break;
      case 6:
      case 7:
      case 8:
        quarter = "4";
        break;
    }
    return quarter;
  }

  // public static getOutreachStartDate(): Date {
  //   let configDates = SessionDetails.getConfigDates();
  //   this.outreach = configDates.filter(x => x.key == 'outreach')[0];
  //   let outreach_start_date: Date = new Date();
  //   if(this.outreach){
  //   outreach_start_date = new Date((this.outreach.startDate == "") ? outreach_start_date : new Date(this.outreach.startDate));
  //   }
  //   return outreach_start_date;
  // }
  public static getOutreachStartDate(): Date {
    let outreach_start_date: Date = new Date();
    outreach_start_date = new Date((environment.outreachStartDate == "") ? outreach_start_date : new Date(environment.outreachStartDate));

    return outreach_start_date;
  }
  public static getOutreachEndDate(): Date {
    var dt = this.getOutreachStartDate();
    var t_dt =  new Date((new Date(dt.setMonth(dt.getMonth() + 12)))/*.setDate(-1)*/);
    return new Date(t_dt.setDate(t_dt.getDate() -1));
  }

  public static getOutreachReportYears(): any {
    let report_years: any = [];
    for (let int_year: number = this.getOutreachReportStartYear(); int_year <= this.getOutreachStartDate().getFullYear(); int_year++) {
      report_years.push({"yearCode":int_year +1,"yearName":int_year + 1});
    }
    return report_years;
  }

  public static getReportYearQuarters(): any {
    let report_year_quarters: any = [];
    report_year_quarters.push({ 'id': 1, "name": "Q1" });
    report_year_quarters.push({ 'id': 2, "name": "Q2" });
    report_year_quarters.push({ 'id': 3, "name": "Q3" });
    report_year_quarters.push({ 'id': 4, "name": "Q4" });
    report_year_quarters.push({ 'id': 5, "name": "Fiscal Year to Date" });
    return report_year_quarters;
  }

  //immunization reports
  public static getCovidStartDate(year: any) {
    return (covidDates[year] ? covidDates[year].startDate : covidDates[year - 1].startDate);
  }
  public static getCovidEndDate(year: any) {
    return (covidDates[year] ? covidDates[year].endDate : covidDates[year - 1].endDate);
  }
  public static getFluOppContactStatusStartDate(year: any) {
    return (fluOppContactStatusDates[year] ? fluOppContactStatusDates[year].startDate : fluOppContactStatusDates[year - 1].startDate);
  }
  public static getFluOppContactStatusEndDate(year: any) {
    return (fluOppContactStatusDates[year] ? fluOppContactStatusDates[year].endDate : fluOppContactStatusDates[year - 1].endDate);
  }

  public static getIMmunizationStartDate(year: any) {
    return (ipDates[year] ? ipDates[year].startDate : ipDates[year - 1].startDate);
  }
  public static getImmunizationEndDate(year: any) {
    return (ipDates[year] ? ipDates[year].endDate : ipDates[year - 1].endDate);
  }
  public static getSRStartDate(year: any) {
    return (srDates[year] ? srDates[year].startDate : srDates[year - 1].startDate);
  }
  public static getSREndDate(year: any) {
    return (srDates[year] ? srDates[year].endDate : srDates[year - 1].endDate);
  }
  public static getPCAStartDate(year: any) {
    return (pcaDates[year] ? pcaDates[year].startDate : pcaDates[year - 1].startDate);
  }
  public static getPCAEndDate(year: any) {
    return (pcaDates[year] ? pcaDates[year].endDate : pcaDates[year - 1].endDate);
  }
}


