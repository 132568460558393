import { ImmunizationsClinicalRequirements } from "../config-files/ClinicalRequirements";
import * as _ from 'lodash';
import { UserRole } from "../config-files/UserRole";

export class ClinicDetailsUtilities {
  static usPhoneNoRegEx: RegExp = /^(\(?[1-9]\d{2}\)?)\D?\D?(\d{3})\D?(\d{4})$/;

  public static validatePhoneNo(phoneNo): boolean {
    let returnValue = false;
    if (!this.usPhoneNoRegEx.test(phoneNo) ||
      (phoneNo.replace(/[^0-9.]/g, "")).length > 10 ||
      (phoneNo.replace(/[^0-9.]/g, "")).length < 9 ||
      (parseInt((phoneNo.replace(/[^0-9.]/g, ""))) + 0) == 0 ||
      (parseInt((phoneNo.replace(/[^0-9.]/g, ""))) + 0) < 100) {
      returnValue = true;
    }
    return returnValue;
  }

  public static shallWeShow2WkConfirmDialog(userProfile): boolean {
    let returnValue = false;
    if (userProfile.userRole.toLowerCase() == "director" ||
      userProfile.userRole.toLowerCase() == "supervisor" || userProfile.userRole.toLowerCase() == "regionalvp" ||
      userProfile.userRole.toLowerCase() == "regionaldirector" || userProfile.userRole.toLowerCase() == "admin" ||
      userProfile.userRole.toLowerCase() == "regional vice president" ||
      userProfile.userRole.toLowerCase() == "regional healthcare director" ||
      userProfile.userRole.toLowerCase() == "healthcare supervisor" ||
      userProfile.userRole.toLocaleLowerCase() == UserRole.PODUSER || 
      userProfile.userRole.toLowerCase() == "director - rx and retail ops" ||
      userProfile.userRole.toLowerCase() == "district manager") {
      returnValue = true;
    }
    return returnValue;
  }

  public static checkIfAnyFluImmIsSelected(coClinicDetails): boolean {
    try {
      coClinicDetails.clinicImmunizationList.forEach(rec => {
        if (rec.immunizationName.toLowerCase().search('flu') != -1) {
          throw true;
        }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }

  public static getPrice(coClinicDetails, immunization_pk, paymentTypeId): Number {
    let imz_array: any[] = [];
    let price;
    price = coClinicDetails.clinicImmunizationList.filter(imz => (imz.immunizationPk == immunization_pk && imz.paymentTypeId == paymentTypeId));
    return price[0].price;
  }

  public static hasClinicalRequirements(coClinicDetails, clinic_details_update): boolean {
    let has_cl_approved: boolean = false;
    coClinicDetails.clinicList[0].clinicImzQtyList.forEach(imz => {
      if (clinic_details_update.clinicList && (clinic_details_update.clinicList[0].isReassign === 1 || (clinic_details_update.clinicList[0].reassignType &&
        clinic_details_update.clinicList[0].reassignType.toLowerCase() === 'geography')) &&
        ImmunizationsClinicalRequirements["immunizations"].indexOf(imz.immunizationName) > -1 && imz.isClinicalReqd) {
        has_cl_approved = true;
      }
    });
    return has_cl_approved;
  }

  public static calculateClinicFeeForQty(estimatedQty: number) {
    let clinicFee = 0;

    if (estimatedQty <= 50) {
      clinicFee = 600;
    }
    else if (estimatedQty >= 51 && estimatedQty <= 100) {
      clinicFee = 1000;
    }
    else if (estimatedQty >= 101 && estimatedQty <= 200) {
      clinicFee = 1500;
    }
    else if (estimatedQty >= 201 && estimatedQty <= 500) {
      clinicFee = 3000;
    }
    else if (estimatedQty > 500) {
      const excessQty = estimatedQty - 500;
      clinicFee = 3000 + (excessQty * 6);
    }

    return clinicFee;
  }
  
  public static CheckIfOnlyVoucherPaymentMethodIsSelectedForAllImmunizations(clinicImzQtyList): boolean{
    //Return false if any of the immunizations have payment methods other than Voucher: Corporate to Invoice Employer Directly
    const newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(clinicImzQtyList);
    let onlyVoucherPaymentMethodIsSelected: boolean = true;
    for (const imzQty of newClinicImzQtyList) {
      if (
        imzQty.Immzs.some(immunization => immunization.paymentTypeId != 13)
      ) {
        onlyVoucherPaymentMethodIsSelected = false;
        break;
      }
    }

    return onlyVoucherPaymentMethodIsSelected;
  }

  public static CheckIfOnlyVoucherPaymentMethodIsSelected(clinicImzQtyList): boolean{
    const newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(clinicImzQtyList);
    let onlyVoucherPaymentMethodIsSelected: boolean = false;
    for (const imzQty of newClinicImzQtyList) {
      if (
        (imzQty.Immzs.length == 1 && (imzQty.Immzs[0].paymentTypeId == 13 || parseInt(imzQty.Immzs[0].paymentTypeId) == 13))
      ) {
        onlyVoucherPaymentMethodIsSelected = true;
        break;
      }
    }

    return onlyVoucherPaymentMethodIsSelected;
  }

  public static CheckOnlyVoucherPaymentIsSelected(clinicImzQtyList): boolean {
    const newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(clinicImzQtyList);
    let onlyVoucherPaymentMethodIsSelected = false;
    onlyVoucherPaymentMethodIsSelected = newClinicImzQtyList.every((imzQty) => (imzQty.Immzs.length == 1 && (imzQty.Immzs[0].paymentTypeId == 13 || parseInt(imzQty.Immzs[0].paymentTypeId) == 13)))
    return onlyVoucherPaymentMethodIsSelected;
  }

  public static CheckIfVoucherPaymentMethodIsSelected(clinicImzQtyList): boolean{
    const newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(clinicImzQtyList);
    let voucherPaymentMethodIsSelected: boolean = false;
    for (const imzQty of newClinicImzQtyList) {
      if (imzQty.Immzs.some(immunization => immunization.paymentTypeId == 13)) {
        voucherPaymentMethodIsSelected = true;
        break;
      }
    }

    return voucherPaymentMethodIsSelected;
  }

  public static CheckIfWagsFreeFluVoucherIsSelected(clinicImzQtyList): boolean{
    const newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(clinicImzQtyList);
    let wagsFreeFluVoucherIsSelected: boolean = false;
    for (const imzQty of newClinicImzQtyList) {
      if (imzQty.Immzs.some(immunization => immunization.paymentTypeId == 14)) {
        wagsFreeFluVoucherIsSelected = true;
        break;
      }
    }

    return wagsFreeFluVoucherIsSelected;
  }

  public static GetImmunizationGroupedByImmzPk(clinicImzQtyList){
    let newClinicImzQtyList = _.chain(clinicImzQtyList)
    .groupBy("immunizationPk")
    .map((value, key) => ({ immPk: key, Immzs: value }))
    .value();

    return newClinicImzQtyList;
  }

  public static calculateVouchersDistributedCount(locationForm): number {
    // Group the elements of Array based on `immunizationPk` property
    // `key` is group's name (immunizationPk), `value` is the array of objects
    let newClinicImzQtyList = this.GetImmunizationGroupedByImmzPk(locationForm.value.clinicImzQtyList);
    let vouchersDistributedCount: number = 0;

    newClinicImzQtyList.forEach(clinicImzQty => {
      // Include the count only when Voucher: Corporate to Invoice Employer Directly or wags flu voucher
      // payment method(s) are selected in immunization
      
      if (
        clinicImzQty.Immzs.length == 1 
          && 
          (
            (clinicImzQty.Immzs[0].paymentTypeId == 13 || parseInt(clinicImzQty.Immzs[0].paymentTypeId) == 13)
            || (clinicImzQty.Immzs[0].paymentTypeId == 14 || parseInt(clinicImzQty.Immzs[0].paymentTypeId) == 14)
          )
      ) {
        if (
          (clinicImzQty.Immzs[0]?.estimatedQuantity > 0 || parseInt(clinicImzQty.Immzs[0]?.estimatedQuantity) > 0)
        ) {
          vouchersDistributedCount = vouchersDistributedCount + parseInt(clinicImzQty.Immzs[0]?.estimatedQuantity);
        }
      }
    });

    return vouchersDistributedCount;
  }

  public static GetAllEstimatedShotsValue(locationForm): number {
    // Group the elements of Array based on `immunizationPk` property
    // `key` is group's name (immunizationPk), `value` is the array of objects
    let newClinicImzQtyList = this.GetImmunizationGroupedByImmzPk(locationForm.value.clinicImzQtyList);
    let vouchersDistributedCount: number = 0;

    newClinicImzQtyList.forEach(clinicImzQty => {
      vouchersDistributedCount = vouchersDistributedCount + Math.max(...clinicImzQty.Immzs.map(o => o.estimatedQuantity));
      // clinicImzQty.Immzs.forEach(immunizationInfo => {
      //   if (
      //     (immunizationInfo?.estimatedQuantity > 0 || parseInt(immunizationInfo?.estimatedQuantity) > 0)
      //   ) {
      //     vouchersDistributedCount = vouchersDistributedCount + parseInt(immunizationInfo?.estimatedQuantity);
      //   }
      // });
    });

    return vouchersDistributedCount;
  }
}