import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionDetails } from '../utility/session';
import { UserRole } from '../config-files/UserRole';

@Injectable()
export class AdminRouteGuard  {

    constructor(
        private router: Router) {
    }

    canActivate() {     
        
        var userProfile = SessionDetails.GetUserProfile();
        if (userProfile) {
            if (userProfile.userRole.toLowerCase() == UserRole.DISTRICTMANAGER)
                return true;
            if (userProfile.userRole.toLowerCase() == UserRole.DIRECTORPHARMACYRETAILOPERATIONS)
                return true;
            else if (userProfile.userRole.toLowerCase() == UserRole.HEALTHCARESUPERVISOR)
                return true;
            else if (userProfile.userRole.toLowerCase() == UserRole.REGIONALVICEPRESIDENT)
                return true;
            else if (userProfile.userRole.toLowerCase() == UserRole.REGIONALHEALTHCAREDIRECTOR)
                return true;
            else if (userProfile.userRole.toLowerCase() == UserRole.ADMIN)
                return true;
            else if (userProfile.userRole.toLowerCase() == UserRole.STOREMANAGER)
                return true;
            else if (userProfile.userRole.toLowerCase() == UserRole.PHARMACYMANAGER)
                return true;
            else if (userProfile.userRole.toLowerCase() == UserRole.PODUSER)
                return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}