
import {debounceTime} from 'rxjs/operators';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UserProfileService } from '../../services/user-profile.service';
import { ConfirmationService, SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { SessionDetails } from '../../../../utility/session';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../../environments/environment';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
  providers: [ConfirmationService],
})
export class UserProfileComponent {
  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : (!environment.isSiteDeploying)?true:false;
  pageName: string = "edit_user";
  title = 'Welcome to my world';
  selectedUserRoleName: any = null;
  isEdit: boolean = false;
  cols:any[];
  getData: string;
  private list: any[] = [];
  private headers: any[] = [];
  loading: boolean;
  date: string;
  list1: any[] = [];
  ddlist: string;
  searchString: string;
  RoleName: string = '-1';
  cities: any[] = [];
  autoComplete: string;
  user_role: string;
  display: boolean = false;
  filteredCountriesSingle: any[];
  countries: any[];
  search_string: string;
  role_name: any;
  userProfile: any;
  displayOptions: boolean = false;
  user_details: any;
  filteredBrands: any[];
  searching = false;
  isGeneric:boolean = false;
  isAddUserRole:boolean = true;
  removeMsg:string;
  recordexists:boolean=true;
  isSuperAdmin = false;
  @ViewChild('tblUserInfo') dataTableUser: Table;
  
  constructor(private router: Router, private spinner: NgxSpinnerService, private _userservice: UserProfileService, private confirmationService: ConfirmationService) {
  }
  autocompleteSearch(event) {
    this._userservice.GetUserInformation(this.role_name).subscribe((data:any) => {
      this.getData = data;
      this.recordexists=true;
      if (this.getData.length == 0) {
        this.recordexists = false;
      }
    });
  }
  autocomplete(query, countries: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (country.username.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    return filtered;
  }
  ngOnInit() {
    SessionDetails.setProfilePk(null);
    SessionDetails.SetUserProfileData(null);
    this.role_name = 'Admin';
    this.selectedUserRoleName= 'Admin';
    this.userProfile = SessionDetails.GetUserProfile();
    this.GetUserInfo(this.role_name);
    //this.GetUserInfo(this.RoleName);
    this.GetUserRoles();
    this.cols = [
			{ field: 'email', header: 'Email' },
			{ field: 'firstName', header: 'First Name' },
			{ field: 'lastName', header: 'Last Name' },
			{ field: 'rolename', header: 'Role' },
			{ field: 'regionId', header: 'Region' },
			{ field: 'areaId', header: 'Area' },
			{ field: 'districtId', header: 'District' },
			{ field: 'storeId', header: 'Store' }
		];
    if(environment.SuperAdmins.includes(this.userProfile.userName.toLowerCase()))
      this.isSuperAdmin = true;
  }
  onChange(roleName) {
    this.autoComplete = '';
    this.role_name = roleName;
    if(this.dataTableUser){
    this.dataTableUser.sortOrder = 0;
    this.dataTableUser.sortField = '';
    this.dataTableUser.reset();
    }
   this.GetUserInfo(this.role_name);
  }
  clearSearch() {    
    this.autoComplete = '';
    //this.GetUserProfileData(this.autoComplete);
    this.GetUserInfo(this.role_name);
  }
  select(event) {
    this._userservice.GetUserInformation(this.role_name).subscribe((data:any) => {
      this.getData = data;
      this.recordexists=true;
      if(this.getData.length == 0){
        this.recordexists=false;
      }
    });
  }
  public GetUserInfo(user_role): void {
   this.spinner.show();
    this._userservice.GetUserInformation(user_role).subscribe((data:any) => {
      this.getData = data;
      this.recordexists=true;
      if(this.getData.length==0){
        this.recordexists=false;
      }
      this.spinner.hide();

    },err=>{
    this.spinner.hide();
    });
  }
  public GetUserRoles() {
    this._userservice.GetUserRoles().pipe(debounceTime(400)).subscribe((res:any) => {
      this.ddlist = res.filter(p=>p.roleName.toLowerCase().search('test')==-1);
    });
  }
  
  DeleteUser()  {
    this.displayOptions = false;
   this._userservice.EditUserProfile(this.user_details.username).subscribe((data:any) => {
    
    if(data.length==1)
    {
      this.removeMsg= 'Are you sure you want to remove this user?';
    }
    else{
    var msg=null;   
    var name;   
    data.forEach(element => {     
      
      var number;   
     
      switch (element.userRole) {
        case 10:        
        name = 'Regions:';
        number = element.regionId;
          break;
        case 11:
        name = 'Regions:'
        number = element.regionId;
        break;
        case 8:
        name = 'Areas:'
        number = element.areaId;
        break;
        case 9:
        name = 'Areas:'
        number = element.areaId;
        break;
        case 5:
        name = 'Districts:'
        number = element.districtId;
        break;   
      }
      if(msg==null)
         {
           msg=number.split('-')[0];
         }
         else
         {
           msg+=', '+number.split('-')[0];
   
         }

         });
        
         this.removeMsg=  "This user is assigned to multiple "+name+" "+msg+". All assignments will be removed with the user. To remove individual assignments from this user edit the user profile."
 
        }

    this.confirmationService.confirm({
     
      message: this.removeMsg,
      accept: () => {
        this.spinner.show();
        this._userservice.deleteUser(this.user_details.pk, this.userProfile.email).subscribe((data) => {
          this.GetUserProfileData(this.autoComplete);
          
        },
          err => {
            this.spinner.hide();
            switch (err) {
              case 500:
                alert('ErrorCode 500 received from Service');
                break;
              case 400:
                alert('Unhandled exception error code received from Service');
                break;
              default:
                alert('Unhandled exception error code received from Service');
                break;
            }
          });
      }
    });
  },
    err => {
      this.spinner.hide();
      switch (err) {
        case 500:
          alert('ErrorCode 500 received from Service');
          break;
        case 400:
          alert('Unhandled exception error code received from Service');
          break;
        default:
          alert('Unhandled exception error code received from Service');
          break;
  }
    });

  }
  showDialog(user_data: any) {
    
    this.user_details = user_data;
    this.displayOptions = true;
    this.isGeneric = true;
    SessionDetails.SetUserProfileData(user_data);
    if(user_data.rolename=="Admin")
    {
      this.isAddUserRole=false;
    }
    else
    {
      this.isAddUserRole=true;
    }
    if(user_data.isGenericEmail)
    {
     this.isGeneric = false;
     this.isAddUserRole=false;
    }
  
   
  }
  EditUser() {
   // SessionDetails.setProfilePk(this.user_details.username);
    this.router.navigateByUrl("/communityoutreach/edit_user");
  }
  AddUser() {
    SessionDetails.setProfilePk(this.user_details.username);
    this.router.navigateByUrl("/communityoutreach/add-user");
  }

  public GetUserProfileData(search_string) {
    this._userservice.GetUserInformationSearch(this.role_name, search_string).subscribe((data:any) => {
      this.getData = data;
      this.recordexists=true;
      if(this.getData.length==0){
        this.recordexists=false;
      }
      this.spinner.hide();
    });
  }
  search(event) {
    
   
    if (event.query.length >= 2 ) {
      //fisrt time dom returning null;
      let spinnerele = document.querySelector("#userProfileAutoCmplt > div > spinnericon") as HTMLElement;
      if (spinnerele != null) {
        spinnerele.style.display = '';
      }
   let closeEle = document.getElementsByClassName("fa fa-close")[0] as HTMLElement;
   closeEle.style.display = 'none';
      this._userservice.GetUserInformationSearch(this.role_name, event.query).subscribe((data:any) => { 
        let spinnerelement_1 = document.querySelector("#userProfileAutoCmplt > div > spinnericon") as HTMLElement;
        if(spinnerelement_1 != null || spinnerelement_1 != undefined){
          spinnerelement_1.style.display = 'none';
        }
        closeEle.style.display = '';
        this.getData = data;
        this.recordexists=true;
        if(this.getData.length==0){
          this.recordexists=false;
        }
     });
    }
  }

  changeSort(event: SortEvent) {    
    //event.field;
    event.data.sort((data1, data2) => {
      let value1;
      let value2 ;
if(event.field==="regionId" ||event.field==="areaId" ||event.field==="districtId" ||event.field==="storeId" ){
  if(data1[event.field]==='--') 
    value1 = Number('0');
  else 
    value1 = Number(data1[event.field]);
  if(data2[event.field]==='--')
    value2=Number('0');
  else 
    value2 = Number(data2[event.field]);
}
else{

      value1 = data1[event.field];
      value2 = data2[event.field];
}
let result = null;

      if (value1 === null && value2 !== null)
          result = -1;
      else if (value1 !== null && value2 === null)
          result = 1;
      else if (value1 === null && value2 === null)
          result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
      else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
  });
    
  
    
}

getUserMgmntCls(value:string){
  switch (value){
    case  'Store':
    case  'District':
    case  'Area':
    case  'Region':
    return 'uAw';
    case 'Email':
    return 'w300'
    case 'Role':
    return 'w200'
    default:
    return '';
  }
  }
}


