export class ActivityReport {
    userId: number;
    userRole: string;
    filterBy: number;
    regionNumber: number;
    areaNumber: number;
    podNumber: number;
    districtNumber: number;
    outreachStartDate: string;
    timePeriod: string;
    outreachEffort: number;
    isCurrentSeason: number;
    reportName: string = "";
    outputType: string = "";
    constructor(user_id: number, user_role: string, filter_by: number, regional_number: number, area_number: number, pod_number: number, district_number: number, outreach_start_date: string, time_period: string, outreach_effort: number, is_current_season: number, report_name: string, output_type: string) {
        this.userId = user_id;
        this.userRole=user_role;
        this.filterBy = filter_by;
        this.regionNumber = regional_number;
        this.areaNumber = area_number;
        this.podNumber = pod_number;
        this.districtNumber = district_number;
        this.outreachStartDate = outreach_start_date;
        this.timePeriod = time_period;
        this.outreachEffort = outreach_effort;
        this.reportName=report_name;
        this.outputType = output_type;
        this.isCurrentSeason = is_current_season;
    }
}