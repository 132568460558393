export class OpportunityInfo {
    businessPk: number;
    outreachBusinessPk: number;
    clinicAgreementPk: number;
    childClinicAgreementId: number;
    childClinicAgreementApproved:boolean;
    contractedStoreId: number;
    storeId: number;
    storeState: string;
    regionNumber: number;
    clinicType: number;
    businessName: string;
    firstName?: any;
    lastName?: any;
    businessAddress: string;
    businessAddress2: string;
    businessCity: string;
    businessState: string;
    businessZip: string;
    jobTitle?: any;
    businessContactEmail?: any;
    phone: string;
    fax: string;
    maxClinicLocationId: number;
    naClinicAddlComments?: any;
    naClinicSplBillingInstr?: any;
    recipientId: string;
    naClinicPlanId: string;
    naClinicGroupId: string;
    pharmacistName?: any;
    pharmacistPhone?: any;
    totalHours?: any;
    naClinicCopay: any;
    naClinicCoverageType: any;
    covidOpportunityType:number;
    fluOpportunityType:number;
    pk_hash:string;
    clientReportingEmail: string;
    b2BTeamEmail:string;
    salesForceOpportunityId: string;
    salesForceAccountName: string;
    clinicFee: number;
    isNoClinicFee: boolean;
    vouchersDistributed: number;
    vouchersRedeemed: number;
}

export class ClinicImzQtyList {
    clinicPk: number;
    immunizationPk: number;
    immunizationName: string;
    paymentTypeId: number;
    paymentTypeName: string;
    estimatedQuantity: number;
    totalImmAdministered?: number;
    vouchersDistributed?: number;
    paperVars?: number;
    price: any;
    showPrice: any;
    isClinicalReqd?:boolean;
    clinicalReqdApprovedBy?:number;
    clinicalReqApprovedDate?:any;
}

export class ClinicList {
    areApptsPresent:number;
    noSlotsOfBlocked:Number;
    clinicPk: number;
    clinicStoreId: number;
    clinicNumber: number;
    naClinicLocation: string;
    intakeID:string;
    naContactFirstName: string;
    naContactLastName: string;
    naContactEmail: string;
    naClinicAddress1: string;
    naClinicAddress2: string;
    naClinicCity: string;
    naClinicState: string;
    naClinicZip: string;
    naClinicContactPhone: string;
    clinicDate: any;//
    clinicScheduledOn: Date;
    naClinicStartTime: string;
    naClinicEndTime: string;
    isReassign: number;
    isNoClinic: number;
    isApptSchedulerEnabled: boolean;
    confirmedClientName?: any;
    clinicLatitude?: any;
    clinicLongitude?: any;
    isCompleted: any;
    isConfirmed: any;
    isCancelled: any;
    coPayFLUGroupId?: any;
    coPayROUTINEGroupId?: any;
    fluExpiryDate?: any;
    routineExpiryDate?: any;
    covidExpiryDate?: any;
    visitType?:any
    coOutreachTypeId?: any;
    coOutreachTypeDesc?: any;
    reassignType: string;
    reassignedTo: number;
    clinicRoom: any;
    isScheduled: any;
    naClinicContactPhoneExt: any;
    isCovidClinic:boolean;
    isFluClinic:boolean;
    clinicImzQtyList: ClinicImzQtyList[];
    manufacturer: number;
    doseNumber: number;
    apptSchedulerType?:number;
    isDataPostedToCP?:boolean;
    privateCPSchedulerLink:string;
    isCuraAppointmentScheduled?:boolean;
    isSendCancellationEmailsApproved?:boolean;
    patientGroupId:string;
    clientReportingEmail: string;
    b2BTeamEmail:string;
    vouchersDistributed: number;
    estimatedParticipants: number | null;
}

export class ClinicImmunizationList {
    pk: number;
    clinicPk: number;
    immunizationPk: number;
    immunizationName: string;
    price: number;
    paymentTypeId: number;
    paymentTypeName: string;
    sendInvoiceTo: number;
    name: string;
    address1?: any;
    address2?: any;
    city?: any;
    state?: any;
    zip?: any;
    phone?: any;
    email?: any;
    isTaxExempt?: any;
    isCopay?: any;
    copayValue: number;
    isVoucherNeeded?: any;
    voucherExpirationDate?: any;
}

export class ClinicsUpdateHistoryLogList {
    pk: number;
    updatedOn: any;
    updateAction: string;
    updatedField?: any;
    updateSubCategory?: any;
    updatedValue?: any;
    updatedBy: string;
}

export class ErrorS {
    errorCode: number;
    errorMessage?: any;
}

export class ClinicDetailsUpdate {
    userPk: number;
    clinicContactStatus?: any;
    disposition: string;
    feedback?: any;
    canReassignClinic: number;
    opportunityInfo: OpportunityInfo;
    clinicList: ClinicList[];
    clinicImmunizationList: ClinicImmunizationList[];
    clinicsUpdateHistoryLogList: ClinicsUpdateHistoryLogList[];
    isSendLocationChangeEmailToAppts: any;
    isSendRescheduleEmailToAppts: any;
    errorS: ErrorS;
    pData:string;
    constructor() {
        this.isSendLocationChangeEmailToAppts = false;
        this.isSendRescheduleEmailToAppts = false;
        this.clinicContactStatus = null;
    }
}


