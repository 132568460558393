import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '../../../services/user-profile.service';
import { SessionDetails } from '../../../../../utility/session';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { UserProfileModel, UserProfile } from '../../../../../models/UserProfileModel';
import { Util } from '../../../../../utility/util';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from "../../../../../../environments/environment"
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  userID: number;
  user: any;
  display: boolean=false;
  isGeneric: boolean=false;
  userRoleItems: any[];
  selectedUserRole: any = null;
  UserProfile: FormGroup;
  list_data:any[] = [];
  list_data_edit:any[]=[];
  name:any;
  dropDownList: any;
  isStoreDist: boolean = false;
  isAreaRegion: boolean = false;
  isPodUser =  false
  dropDownName: any;
  selectedUserRoleName:any = null;
  selected_value: any;
  errorMessageShow: any;
  errorShow: boolean = false;
  userProfileData:any;
  isAreaDist:any;
  userRoles: number;
  checkCond:boolean = true;
  email:string;
  userProfile: any;
  isSaveDisable:boolean = false;
  isSuperAdmin = false;
  constructor(private route: ActivatedRoute,private _router:Router,private spinner: NgxSpinnerService,private utility:Util, private _location: Location, private _userservice: UserProfileService, private _fb: FormBuilder) {
  }

  ngOnInit() {
    this.userProfile = SessionDetails.GetUserProfile();
    this.UserProfile = this._fb.group({
      FirstName: ['', null],
      LastName: ['', null],
      Email: ['', Validators.required],
      // Phone: ['', Validators.required]
    });
   
    this.userProfileData = SessionDetails.getUserProfileData();
    if(this.userProfileData!==null && this.userProfileData.isGenericEmail)
     this.isGeneric = true;
     
     
    
    this.editUserProfile()
    this._userservice.GetUserRoles().subscribe((res:any) => this.userRoleItems = res);
    this.route.params.subscribe((params) => {
    this.userID = params['id'];
      // this._userservice.GetUserById(this.userID).subscribe(users => {
      //   this.user = JSON.parse(users)[0];
      // });
    });
    if(environment.SuperAdmins.includes(this.userProfile.userName.toLowerCase()))
      this.isSuperAdmin = true;
  }
  errorClose() {   
    this.errorShow = false;
  }
  onSelect(value) {
    this.selected_value = value;
    //this.selectedUserRole = null;
    // for (var i = 0; i < this.userRoleItems.length; i++) {
    //   if (this.userRoleItems[i].id == userId) {
    //     this.selectedUserRole = this.userRoleItems[i];
    //   }
    // }
  }
  editUserProfile() {
    
    if(this.userProfileData!==null)
    {
    this._userservice.EditUserProfile(this.userProfileData.username).subscribe((users:any) => {
      this.UserProfile = this._fb.group({
        FirstName: [users[0].firstName, null],
        LastName: [users[0].lastName, null],
        Email: [users[0].email, [
          Validators.required,
          Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")
        ]],
        // Phone: ['', Validators.required]
      });
      this.email=users[0].email;
      this.selectedUserRole = users[0].rolename;
      this.bindDropdownList(users[0].rolename);
      users.forEach(element => {
        var number;
        this.userRoles = element.userRole;
        switch (element.userRole) {
          case 10:
          this.dropDownName = 'Region';
          this.isAreaRegion = true;
          this.name = 'Region';
          number = element.regionId;
            break;
          case 11:
          this.dropDownName = 'Region';
          this.isAreaRegion = true;
          this.isAreaRegion = true;
          this.name = 'Region'
          number = element.regionId;
          break;
          case 8:
          this.dropDownName = 'Area';
          this.isAreaRegion = true;
          this.name = 'Area(s)'
          number = element.areaId;
          break;
          case 9:
          this.dropDownName = 'Area';
          this.isAreaRegion = true;
          this.name = 'Area(s)'
          number = element.areaId;
          break;
          case 5:
          this.dropDownName = 'District';
          this.isStoreDist = true;
          this.name = 'District(s)'
          number = element.districtId;
          break;   
          case 14: 
            this.dropDownName = 'Pod';
            this.isAreaRegion = true;
            this.name = 'Pod(s)';
            number = element.podNumber;
        }

         if(element.isGenericEmail && element.email.indexOf(number.split('-')[0])>-1)
         {
           this.checkCond = false;
         }
         else{
         this.checkCond = true;
         }
       if(number!==undefined)
       
        this.list_data.push({ "value": number,"isDeleted":this.checkCond });
      });
   
    });
  }
  }
  setradio(role) {
    //user role logic here
  }
  Cancel() {
    this._location.back();
  }
  DeleteRole(delete_data) {
    
    var index = this.list_data.findIndex(item => item.value.split('-')[0] === delete_data.value.split('-')[0]);
    // if(this.list_data.length==1)
    // {
    //   this.errorShow = true;
    //   this.errorMessageShow = 'Sorry, you cannot remove this assignment. A user must have at least one assignment.';
    //   return;
    // }
    if (index > -1) {
      this.list_data.splice(index, 1);
    }
  }

  bindDropdownList(user_role) {
    this.dropDownList = [];
   // this.spinner.show();
    this._userservice.bindDropDownList(user_role).subscribe((data) => {

      this.dropDownList = data;
     // this.spinner.hide();
    },
      err => {
        switch (err) {
          case 500:
            alert('ErrorCode 500 received from Service');
            break;
          case 400:
            alert('Unhandled exception error code received from Service');
            break;
          default:
            alert('Unhandled exception error code received from Service');
            break;
        }
      });
  }
  AddRole() {
    
    var index = this.list_data.findIndex(item => item.value.split('-')[0] === this.selected_value.split('-')[0]);
    if (index == -1) {
      this.list_data_edit.push({ "value": this.selected_value });
      this.list_data.push({ "value": this.selected_value,"isDeleted":true });
      this.selected_value = null;
      this.selectedUserRoleName = null;
    }
  }
  addUserProfile() {
    this.spinner.show();
    this.isSaveDisable=true;
    if (this.UserProfile.valid) {
      var user_roles = ['11', '10', '8','9','5', '14'];
      let obj = new UserProfileModel();
      obj.pk = this.userProfileData.pk;
      obj.profile_model = new Array<UserProfile>();
      if(user_roles.indexOf(this.userRoles.toString())>-1)
      {
        if (this.list_data.length==0)
        {
          this.spinner.hide();
          this.errorShow = true;
          this.errorMessageShow = "A user must have at least one assignment";
          this.isSaveDisable=false;
          return;
        }
      }
      if (this.list_data.length >= 1) {
        this.list_data.forEach(item => {
          let profile_model = new UserProfile();
          profile_model.firstName = this.UserProfile.controls["FirstName"].value;
          profile_model.lastName = this.UserProfile.controls["LastName"].value;
          profile_model.email = this.UserProfile.controls["Email"].value;
          profile_model.isEdit = true;
          profile_model.updatedBy =  this.userProfile.email;
          profile_model.userRole = this.userRoles; //this.userRoleChange(this.userProfileData.rolename);
          profile_model.assignUserRole = Number(item.value.split('-')[0]);
          //profile_model.pk = 
          obj.profile_model.push(profile_model);
        });
      }
      else {
        //
       // this.list_data.forEach(item=>{
        let profile_model = new UserProfile();
        profile_model.firstName = this.UserProfile.controls["FirstName"].value;
        profile_model.lastName = this.UserProfile.controls["LastName"].value;
        profile_model.email = this.UserProfile.controls["Email"].value;
        profile_model.isEdit = true;
        profile_model.updatedBy =  this.userProfile.email;
        profile_model.userRole = this.userRoles;// this.userRoleChange(this.userProfileData.rolename);
        profile_model.assignUserRole =SessionDetails.GetStoreId();
        //profile_model.pk = SessionDetails.getProfilePk();
        obj.profile_model.push(profile_model);
      //});
      }
      this._userservice.AddUserProfile(obj, -1).subscribe((data:any) => {
        this.spinner.hide();
        if (data.errorS !== null) {
          if (data.errorS.errorCode !== 0) {
            this.errorShow = true;
            this.errorMessageShow = data.errorS.errorMessage;
            this.isSaveDisable=false;
            return;
          }
        }
        this._router.navigateByUrl("/communityoutreach/userprofile");
      },
        err => {
          this.spinner.hide();
          this.isSaveDisable=false;
          switch (err) {
            case 500:
              alert('ErrorCode 500 received from Service');
              break;
            case 400:
              alert('Unhandled exception error code received from Service');
              break;
            default:
              alert('Unhandled exception error code received from Service');
              break;
          }
        });
    }
    else {
      this.spinner.hide();
      this.utility.validateAllFormFields(this.UserProfile);
      this.isSaveDisable=false;
    }
  }
  error() {
    this.errorShow = false;
  }
  userRoleChange(event):any {
    // 
    // this.list_data = [];
    // this.userRoles = event;
    // this.isAreaRegion = false;
    // this.isStoreDist = false;
    // this.display = false;
    // if (event != 'Admin') {
    //   this.display = true;
    // }
    switch (event) {

      case 'Regional Healthcare Director':
      return   this.userRoles = 11;
       // break;
      case 'Regional Vice President':
      return this.userRoles = 10;
      //  break;
      case 'Healthcare Supervisor':
      return this.userRoles = 8;
       // break;
      case 'Director - Rx and Retail Ops':
      return this.userRoles = 9;
     ///   break;
      case 'District Manager':
      return this.userRoles = 5;
      //  break;
      case 'Pharmacy Manager':
      return this.userRoles = 2;
        //this.isStoreDist = true;
       // this.dropDownName = 'Store';
       // this.bindDropdownList(event);
       // break;
      case 'Store Manager':
     return this.userRoles = 3;
     case 'CP User':
      return this.userRoles = 13;
    case 'Pod Healthcare Supervisor':
      return this.userRoles = 14;
      //  this.isStoreDist = true;
        //this.dropDownName = 'Store';
       // this.bindDropdownList(event);
       // break;
    }
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  isFieldValid(field: string) {
    return !this.UserProfile.get(field).valid && this.UserProfile.get(field).touched;
  }
}
