import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApplicationSettings } from '../../../common/application-settings';
import { Router } from '../../../../../../node_modules/@angular/router';
import { SessionDetails } from '../../../../utility/session';
//import * as appReportSettings from '../../globals';
import { saveAs as importedSaveAs } from "file-saver";
import { MessageServiceService } from '../../../store/services/message-service.service';
import { StorecompliancereportService } from '../../services/storecompliancereport.service';
import { StoreComplianceSR, StoreComplianceIP } from '../../../../models/storeCompliance';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { CommonservicesService } from '../../services/commonservices.service';
import {Table} from 'primeng/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRole } from '../../../../config-files/UserRole';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-storecompliancereport',
  templateUrl: './storecompliancereport.component.html',
  styleUrls: ['./storecompliancereport.component.css']
})
export class StorecompliancereportComponent implements OnInit {
  storeComplianceArray: any;
  canExport: any;
  markets: any = [];
  areas: any = [];
  districts: any = [];
  podList : any = [];
  ddlRegion: any;
  ddlArea: any;
  ddlPod: any;
  ddlDistrict: any;
  ddlYear: any;
  years: any;
  quarters: any;
  ddlQuarter: any;
  ddlFormat: any;
  loading: boolean = true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  totalRecords: number = 0;
  private outreachEffort: number;
  showFilters: boolean = false;
  private storeId: any;
  private outreachStartDate: any;
  private dt = new Date();
  userProfile: any;

  showRegionFilter: boolean = false;
  showAreaFilter: boolean = false;
  showPodFilter = false;
  showDistrictFilter: boolean = true;
  private userType: string = "";
  recordExists: boolean = true;
  showSearchFilter: boolean = false;
  showddlState = false;
  cols: any[];
  @ViewChild('dt') private table:Table;
  
  @ViewChild('txtGlobalFilter') txtSearch:ElementRef 
  storeComplianceForm:FormGroup;

  constructor(private router: Router, private message_service: MessageServiceService, private store_compliance: StorecompliancereportService, 
    private common_services: CommonservicesService, private fb: FormBuilder, private spinner: NgxSpinnerService) {
    if (this.router.url.indexOf('immunization') > -1) {
      this.outreachEffort = ApplicationSettings.outreachEffortIP;
    } else {
      this.showFilters = true;
      this.outreachEffort = ApplicationSettings.outreachEffortSO;
    }
    this.userProfile = SessionDetails.GetUserProfile();
    this.setFormControls();
    if (!this.showFilters) {
      if (this.userProfile.userRole == "Admin") {
        this.showRegionFilter = true;
        this.showAreaFilter = true;
        this.userType = "Region";
        this.showSearchFilter = true;
        this.showPodFilter = true;
        this.showddlState = true;
      } else if (this.userProfile.userRole.toLowerCase() == UserRole.REGIONALVICEPRESIDENT || this.userProfile.userRole.toLowerCase() == UserRole.REGIONALHEALTHCAREDIRECTOR) {
        this.showAreaFilter = true;
        this.showPodFilter = true;
        this.userType = "Area";
        this.showSearchFilter = true;
        this.showddlState = false;
      } else if (this.userProfile.userRole.toLowerCase() == UserRole.HEALTHCARESUPERVISOR || this.userProfile.userRole.toLowerCase() == UserRole.DIRECTORPHARMACYRETAILOPERATIONS) {
        this.showDistrictFilter = true;
        this.showPodFilter = true;
        this.showAreaFilter = false;
        this.userType = "Pod";
        this.showSearchFilter = true;
        this.showddlState = false;
      } else if(this.userProfile.userRole.toLowerCase() == UserRole.PODUSER) {
        this.showDistrictFilter = true;
        this.showSearchFilter = true;
        this.userType = "District";
        this.showAreaFilter = false;
        this.showddlState = false;
        this.showPodFilter = false;
      }
      else if (this.userProfile.userRole.toLowerCase() == UserRole.DISTRICTMANAGER) {
        this.showDistrictFilter = true;
        this.userType = "District";
        this.showPodFilter = false;
        this.showddlState = false;
      }
      else if (this.userProfile.userRole.toLowerCase() == UserRole.STOREMANAGER || this.userProfile.userRole.toLowerCase() == UserRole.PHARMACYMANAGER) {
        this.showDistrictFilter = false;
        this.showPodFilter = false;
        this.showSearchFilter = false;
        this.showddlState = false;
      }
      if (this.userType == "") {
        this.userType = "-1";
      }
    }
    if (this.showFilters) {
      this.message_service.getStoreID().subscribe(x => {
        this.storeId = x.text;
        this.loading = true;
        this.generateReport();
      });
    }
    this.message_service.setReportTitle("STORE COMPLIANCE REPORT");
  }

  ngOnInit() {    
    // if(this.outreachEffort==1){
    //   this.outreachStartDate = ApplicationSettings.getSRStartDate(this.dt.getFullYear());
    // }
    // else{
    //   this.outreachStartDate = ApplicationSettings.getOutreachStartDate();
    // }
    // this.storeId = SessionDetails.GetStoreId();
    // this.ddlFormat = "0";
    // if (this.showFilters) {
    //   this.years = ApplicationSettings.getOutreachReportYears();
    //   this.quarters = ApplicationSettings.getReportYearQuarters();
    //   this.ddlYear = (ApplicationSettings.getOutreachStartDate().getFullYear() + 1);
    //   this.ddlQuarter = ApplicationSettings.getSRCurrentQuarter();
    // }

    if (!this.showFilters) {
      this.cols = [
        { field: 'storeId', header: 'Store ID' },
        { field: 'storeAddress', header: 'Store Address' },
        { field: 'storeState', header: 'Store State' },
        { field: 'TotalBusiness', header: 'Total Opportunities' },
        { field: 'Contacted', header: 'Total Opportunities Contacted' },
        { field: 'NotContacted', header: 'Total Opportunities Not Contacted' },
        { field: 'PercentContacted', header: '% Opportunities Contacted' },
        { field: 'PercentNotContacted', header: '% Oppotunities Not Contacted' }
      ];
    }
    this.makeAndLoadFilters();
    this.setFormControls();
    this.generateReport();
  }
  setFormControls() {
    this.storeComplianceForm = this.fb.group({
       'ddlYear': [this.ddlYear],
       'ddlQuarter': [this.ddlQuarter],
       'ddlRegion': ["0"],
       'ddlArea': ["0"],
       'ddlPod': ["0"],
       'ddlDistrict': ["0"],
       'covidOnly': [false],
       'fluRoutine': [false],
       'ddlFormat': ["xlsxv"]
     });
     //this.generateReport();
   }
  generateReport(): void {
    this.loading = true;
    let data = this.storeComplianceForm.value;
    let covidValue :any;
    if(data.covidOnly== true && data.fluRoutine==true)
    {
      covidValue =3;
    }
    else if(data.covidOnly)
    {
      covidValue=1;
    }
    else if(data.fluRoutine)
    {
      covidValue=2;
    }
    if (this.showFilters) {
      let scr: StoreComplianceSR;
      //let is_current_season = (((parseInt(this.ddlYear)) !== (new Date(this.outreachStartDate).getFullYear() + 1)) ? 0 : 1);      
      let year_start_date = ApplicationSettings.getSRStartDate(data.ddlYear - 1);
      scr = new StoreComplianceSR(this.outreachEffort.toString(), this.userProfile.userPk, year_start_date, ((data.ddlQuarter == 5) ? '0' : data.ddlQuarter), "", "");
      this.store_compliance.getLocationComplianceSO(scr).subscribe((resultArray: any) => {
        //this.totalRecords = resultArray.length;
        //console.log("totalRecords:" + this.totalRecords);
        resultArray.forEach(x => {
          x.PercentContacted = this.getPercentRounded(x.PercentContacted);
          x.PercentNotContacted = this.getPercentRounded(x.PercentNotContacted);
        });
        this.storeComplianceArray = resultArray;
        setTimeout(() => {
          this.loading = false;
          this.canExport = true;
        }, 1000)

        //console.log(res);
      }, error => {
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.storeComplianceArray = [];
      });
    } else {
      if(this.table){
        this.table.reset();
        if(this.txtSearch?.nativeElement)
          this.txtSearch.nativeElement.value ='';
      }
      this.recordExists = true;
      let scr: StoreComplianceIP;
      scr = new StoreComplianceIP(this.outreachEffort.toString(), this.userProfile.userPk, data.ddlRegion, data.ddlArea, data.ddlPod, data.ddlDistrict, "", "", "", "",covidValue );
      this.store_compliance.getStoreBusinessFeedbackPer(scr).subscribe((resultArray: any) => {
        if (resultArray && resultArray.length > 0) {
          this.totalRecords = resultArray.length;
          this.recordExists = true;
          //console.log("totalRecords:" + this.totalRecords);
          resultArray.forEach(x => {
            x.PercentContacted = this.getPercentRounded(x.PercentContacted);
            x.PercentNotContacted = this.getPercentRounded(x.PercentNotContacted);
          });
          this.storeComplianceArray = resultArray;
        } else {
          this.totalRecords = 0;
          this.recordExists = false;
          this.storeComplianceArray = [];
        }
        setTimeout(() => {
          this.loading = false;
          this.canExport = true;
        }, 1000)
      }, error => {
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.storeComplianceArray = [];
      });
    }
    this.ddlFormat = "xlsxv";
  }
  generatereportformat(): void {

    this.loading = true;
    let data = this.storeComplianceForm.value;
    let covidValue :any;
    if(data.covidOnly== true && data.fluRoutine==true)
    {
      covidValue =3;
    }
    else if(data.covidOnly)
    {
      covidValue=1;
    }
    else if(data.fluRoutine)
    {
      covidValue=2;
    }
    if (this.showFilters) {
      let scr: StoreComplianceSR;
      // let is_current_season = (((parseInt(this.ddlYear)) !== (new Date(this.outreachStartDate).getFullYear())) ? 0 : 1);
      let year_start_date = ApplicationSettings.getSRStartDate(this.ddlYear - 1);
      scr = new StoreComplianceSR(this.outreachEffort.toString(), this.userProfile.userPk, year_start_date, ((this.ddlQuarter == 5) ? '0' : this.ddlQuarter), "StoreCompliance", "xlsxv");
      this.store_compliance.exportReportDataSO(scr).subscribe((res: any) => {
        importedSaveAs(res.body, "StoreCompliance.xlsx");
        this.loading = false;
      }, error => {
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
      });
    } else {
      let scr: StoreComplianceIP;
      scr = new StoreComplianceIP(this.outreachEffort.toString(), this.userProfile.userPk, data.ddlRegion, data.ddlArea, data.ddlPod, data.ddlDistrict, "", "", "StoreCompliance", "xlsxv",covidValue);
      this.store_compliance.exportReportDataImz(scr).subscribe((res: any) => {
        importedSaveAs(res.body, "StoreCompliance.xlsx");
        this.loading = false;
      }, error => {
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.storeComplianceArray = [];
      });
    }
    this.ddlFormat = "xlsxv";
  }
  public getQuarterName(quarter: number) {
    var quarter_name = "";
    switch (quarter) {
      case 1:
        quarter_name = "Q1";
        break;
      case 2:
        quarter_name = "Q2";
        break;
      case 3:
        quarter_name = "Q3";
        break;
      case 4:
        quarter_name = "Q4";
        break;
      default:
        break;
    }
    return quarter_name;
  }
  canExportOnchange() {
    this.canExport = false;
    let month = new Date().getMonth();
    let quartersLocal = ApplicationSettings.getReportYearQuarters();
    this.quarters = ApplicationSettings.getReportYearQuarters();
    if((Number(this.storeComplianceForm.value.ddlYear)>2022)){
      if(month>=8 && month<=10){
        this.quarters = this.quarters.filter(q=>q.id===1);        
      }
      else if(month>=11 ){
        this.quarters = this.quarters.filter(q=>q.id<3);
        this.quarters.push(quartersLocal.filter(q=>q.id == 5)[0]);
      }
      else if(month>=0 && month <= 1 ){
        this.quarters = this.quarters.filter(q=>q.id<3);
      }
      else if(month>=2 && month <= 4 ){
        this.quarters = this.quarters.filter(q=>q.id<4);
      }
      else{
        this.quarters = this.quarters.filter(q=>q.id<5);
      }
    }
    else  if(Number(this.storeComplianceForm.value.ddlYear)<2021){
      this.quarters =this.quarters.filter(q=>q.id===3 || q.id===4);
    }
    else {
      this.quarters = ApplicationSettings.getReportYearQuarters();
      this.quarters = this.quarters.filter(q=>q.id<=5);
    }
  }

  getPercentRounded(value: any) {
    return value.toFixed(2);
  }

 getArea() { 
    let data = this.storeComplianceForm.value;
    this.common_services.getAreaDist('Area', this.storeComplianceForm.get('ddlArea').value).subscribe((data) => {
      this.areas = [];
      this.districts = [];
      this.podList = [];
      this.ddlPod = 0;
      this.ddlArea = 0;
      this.ddlDistrict = 0;
      let dataList = data;
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.areas.push({ key: keyValue, value: valueData });
      };
    });
    this.canExport = false;
  }

  getPod(){
    let data = this.storeComplianceForm.value;
    this.common_services.getAreaDist('pod', this.storeComplianceForm.get('ddlArea').value).subscribe((data) => {
      this.districts = [];
      this.podList = [];
      this.ddlPod = 0;
      this.ddlDistrict = 0;
      let dataList = data;
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.podList.push({ key: keyValue, value: valueData });
      };
    });
    this.canExport = false;
  }

  getDistrict() { 
    let data = this.storeComplianceForm.value;
    this.common_services.getAreaDist('podDistrict', this.storeComplianceForm.get('ddlPod').value).subscribe((data) => {
      // this.markets = formData.;
      let dataList = data;
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.districts.push({ key: keyValue, value: valueData });
      };
      this.ddlDistrict = 0;
    });
    this.canExport = false;
  }

  reset(): void {
    //this.loading = true;
    this.makeAndLoadFilters();
    if(this.table){
      this.table.reset();
      this.txtSearch.nativeElement.value ='';
    }
    this.setFormControls()
    this.generateReport();
  }

  makeAndLoadFilters() {
    if (this.outreachEffort == 1) {
      this.outreachStartDate = ApplicationSettings.getSRStartDate(this.dt.getFullYear());
    }
    else {
      this.outreachStartDate = ApplicationSettings.getOutreachStartDate();
    }
    this.storeId = SessionDetails.GetStoreId();
    this.ddlDistrict = "0";
    this.ddlArea = "0";
    this.ddlPod = "0";
    this.ddlRegion = "0";
    this.ddlFormat = "xlsxv";
    
    if (!this.showFilters) {
      if(this.userType === "Region" ){
        this.common_services.getAreaDist(this.userType, 0).subscribe((data) => {
          let dataList = data;
          this.markets = [];
          this.areas = [];
          this.podList = [];
          this.districts = [];
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.markets.push({ key: keyValue, value: valueData });
          }
          this.spinner.hide();
        });
      } else if(this.userType === "Area"){ 
        this.common_services.getAreaDist(this.userType, 0).subscribe((data) => {
          let dataList = data;
          this.areas = [];
          this.podList = [];
          this.districts = [];
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.areas.push({ key: keyValue, value: valueData });
          }
          this.spinner.hide();
        });
      } else if(this.userType === "Pod") {
      this.common_services.getAreaDist(this.userType.toLowerCase(), 0).subscribe((data) => {
        let dataList = data;
        this.podList = [];
        this.districts = [];
        for (var keyValue in dataList) {
          let valueData = dataList[keyValue];
          this.podList.push({ key: keyValue, value: valueData });
        }
        this.spinner.hide();
      });
      } else if(this.userType === "District") {
        this.common_services.getAreaDist(`pod${this.userType.toLowerCase()}`, 0).subscribe((data) => {
          let dataList = data;
          this.districts = [];
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.districts.push({ key: keyValue, value: valueData });
          }
          this.spinner.hide();
        });
      } else { 
        this.common_services.getAreaDist(this.userType, 0).subscribe(data => {
          let dataList = data;
          this.markets = [];
          this.areas = [];
          this.podList = [];
          this.districts = [];
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.markets.push({ key: keyValue, value: valueData });
          }
          this.spinner.hide();
        }); 
      }
    };
    if (this.showFilters) {
      this.years = ApplicationSettings.getOutreachReportYears();
      this.years = this.years.filter(q=>q.yearCode>=2020);
         //Commeneted below line to fix Fiscal Year binding issue:
    //  this.years.push({yearCode: this.years[this.years.length-1].yearCode+1, yearName:this.years[this.years.length-1].yearCode+1})
      this.quarters = ApplicationSettings.getReportYearQuarters();
      let quartersLocal = ApplicationSettings.getReportYearQuarters();
      let month = new Date().getMonth();
      
      if((new Date().getFullYear()>=2022)){
      if(month>=8 && month<=10){
        this.quarters = this.quarters.filter(q=>q.id===1);        
      }
      else if(month>=11 ){
        this.quarters = this.quarters.filter(q=>q.id<3);
        this.quarters.push(quartersLocal.filter(q=>q.id == 5)[0]);
      }
      else if(month>=0 && month <= 1 ){
        this.quarters = this.quarters.filter(q=>q.id<3);
      }
      else if(month>=2 && month <= 4 ){
        this.quarters = this.quarters.filter(q=>q.id===3 || q.id===4);
      }
      else{
        this.quarters = this.quarters.filter(q=>q.id<5);
      }
    }
      this.ddlYear = (ApplicationSettings.getOutreachStartDate().getFullYear()+1);
      this.ddlQuarter = ApplicationSettings.getSRCurrentQuarter();
    }
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
