import { Component, OnInit } from '@angular/core';
import { HeaderserviceService } from '../../services/headerservice.service';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { Subscription } from 'rxjs';
import { Session } from 'protractor';
import { SessionDetails } from '../../../../utility/session';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  menu: string[] 
  subscription: Subscription; 
  turnOffHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff"))=='yes')?false:true; 
  constructor(private service: HeaderserviceService, private message_service: MessageServiceService) {
    this.message_service.getTurnOnOffLocalHintsFromComponent().subscribe(message => { 
      this.turnOffHints = message.text;
  });
   
  }

  ngOnInit() {   
    this.GetFooterMenu();
  }

  checking(event: any) { 
    if(location.href.indexOf('home')>-1 && this.turnOffHints){
      this.message_service.setShowHintsOnToggleChange(true);
    }else{
      this.message_service.setTurnOnOffLocalHintsFromFooter(this.turnOffHints);   
    }
  }
 

  GetFooterMenu() {    
    var userProfile = SessionDetails.GetUserProfile();
    if (userProfile.userRole.toLowerCase() == "admin") {
      this.menu = this.service.getAdminFooterMenu();
    }
    else if (userProfile.userRole.toLowerCase().replace(new RegExp(" ", 'g'), "") == "storemanager" 
          || userProfile.userRole.toLowerCase().replace(new RegExp(" ", 'g'), "") == "pharmacymanager") {
      this.menu = this.service.getStoreManagerFooterMenu();
    }
    else if (userProfile.userRole.toLowerCase().replace(new RegExp(" ", 'g'), "") == "districtmanager") {
       this.menu = this.service.getDistrictManagerFooterMenu();
    }
    else if (userProfile.userRole.toLowerCase().replace(new RegExp(" ", 'g'), "")  == "healthcaresupervisor") {
      this.menu = this.service.getHealthcareSupervisorFooterMenu();
    }
    else if (userProfile.userRole.toLowerCase().replace(new RegExp(" ", 'g'), "")  == "director-rxandretailops") {
      this.menu = this.service.getDirectorRxRetailFooterMenu();
    }
    else if (userProfile.userRole.toLowerCase().replace(new RegExp(" ", 'g'), "") == "regionalvicepresident" 
            || userProfile.userRole.toLowerCase().replace(new RegExp(" ", 'g'), "") == "regionalhealthcaredirector") {
       this.menu = this.service.getRegionalVicePresidentFooterMenu();
    }
  }
}
