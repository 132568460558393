import { Component,  OnInit } from '@angular/core';
import {  FormGroup,FormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from "file-saver";
import { ActivityReportService } from '../../services/activityreport.services';
import { ActivityReport } from '../../../../models/ActivityReport';
import { SessionDetails } from '../../../../utility/session';
import { ApplicationSettings } from '../../../common/application-settings';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { CommonservicesService } from '../../services/commonservices.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserRole } from "../../../../config-files/UserRole";
@Component({
  selector: 'app-activityreport',
  templateUrl: './activityreport.component.html',
  styleUrls: ['./activityreport.component.css']
})

export class ActivityreportComponent implements OnInit {
  loading: boolean = true;
  
  showGrid:boolean = false;
  userProfile: any;
  activityReportForm: FormGroup;
  activityReportArray: any;
  private datasource: any;
  ddlFilterBy: any;
  ddlRegion: any;
  ddlArea: any;
  ddlPOd: any;
  ddlDistrict: any;
  ddlTimePeriod: string;
  
  private outreachEffort = ApplicationSettings.outreachEffortSO;
  totalRecords: number;
  markets: any = [];
  areas: any = [];
  districts: any = [];
  podList: any;
  ddlYear: any = [];
  ddlFiscalYear: any;
  ddlQuarter: any;
  ddlFormat: string;
  private isCurrentSeason: number;
  private outreachStartDate: Date;
  Filter: any;
  private userType: string = "";
  FilterByArray: any;
  showArea: boolean = true;
  showRegionFilter:boolean=false;
  showAreaFilter:boolean=false;
  showPodFilter = false;
  showDistrictFilter:boolean= false;
  showDistrict: boolean = true;
  showStoreId: boolean = true;
  showRegion:boolean=false;
  showFilterBy:boolean=true;
  showddlState = false;
  canExport:boolean=true;
  Quarter:any[];
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  private dt = new Date();
  private defaultQuarters:any[]=ApplicationSettings.getReportYearQuarters();
  private startMonth ={
    "2018":"5"
  }
  private month_names: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  constructor( private fb: FormBuilder, private activityreport: ActivityReportService, private spinner: NgxSpinnerService, private common_services:CommonservicesService) {
    this.userProfile = SessionDetails.GetUserProfile();
    if(this.userProfile.userRole.toLowerCase() == UserRole.ADMIN)
      this.userType = "Region";
    if (this.userProfile.userRole.toLowerCase() == UserRole.REGIONALVICEPRESIDENT || this.userProfile.userRole.toLowerCase() == UserRole.REGIONALHEALTHCAREDIRECTOR)
      this.userType = "Area";
    if (this.userProfile.userRole.toLowerCase() == UserRole.HEALTHCARESUPERVISOR || this.userProfile.userRole.toLowerCase() == UserRole.DIRECTORPHARMACYRETAILOPERATIONS)
      this.userType = "Pod";
    if(this.userProfile.userRole.toLocaleLowerCase() == UserRole.PODUSER)
      this.userType = "District";
    if(this.userProfile.userRole.toLocaleLowerCase() == UserRole.DISTRICTMANAGER)
      this.userType = "District";
    if (this.userType == "") {
      this.userType = "-1";
    }
    this.FilterByArray = new FilterByArray(null, null);
    if (this.userProfile.userRole == "Admin") {
      // this.Filter = [
      //   new FilterByArray("0", "-- Select --"),
      //   new FilterByArray("1", "Region"),
      //   new FilterByArray("2", "Area"),
      //   new FilterByArray("3", "District")
      // ];
      this.showRegionFilter=true;
      this.showAreaFilter=true;
      this.showPodFilter = true;
      this.showDistrictFilter = true;
    } else if (this.userProfile.userRole.toLowerCase() == UserRole.REGIONALVICEPRESIDENT || this.userProfile.userRole.toLowerCase() == UserRole.REGIONALHEALTHCAREDIRECTOR) {
      // this.Filter = [
      //   new FilterByArray("0", "-- Select --"),
      //   new FilterByArray("2", "Area"),
      //   new FilterByArray("3", "District")
      // ];
      this.showAreaFilter=true;
      this.showDistrictFilter = true;
      this.showPodFilter = true;
    } else if(this.userProfile.userRole.toLowerCase() == UserRole.HEALTHCARESUPERVISOR || this.userProfile.userRole.toLocaleLowerCase() == UserRole.DIRECTORPHARMACYRETAILOPERATIONS){
      this.showPodFilter = true;
      this.showDistrictFilter = true;
      this.showAreaFilter = false;
      this.showRegionFilter = false;
    } else if(this.userProfile.userRole.toLowerCase() == UserRole.PODUSER) {
      this.showDistrictFilter = true;
      this.showPodFilter = false;
      this.showAreaFilter = false;
    } else if(this.userProfile.userRole.toLowerCase()== UserRole.STOREMANAGER|| this.userProfile.userRole.toLowerCase()== UserRole.PHARMACYMANAGER || this.userProfile.userRole.toLowerCase() == UserRole.DISTRICTMANAGER ){
      this.showDistrictFilter=false;
      this.showPodFilter = false;
      this.showAreaFilter = false;
    }
    // this.showFilterBy=(this.Filter);
  }
  ngOnInit() {
    this.ddlFormat = "xlsxv";
    this.ddlFiscalYear = (new Date(ApplicationSettings.getSRStartDate(this.dt.getFullYear()))).getFullYear();
    this.ddlFilterBy = "0";
    this.ddlDistrict = "-1";
    this.ddlArea = "-1";
    this.ddlRegion = "-1";
    this.ddlPOd = "-1";
    this.ddlTimePeriod = "0";
    this.outreachStartDate = new Date(ApplicationSettings.getSRStartDate(this.dt.getFullYear()));

    this.isCurrentSeason = ((this.ddlFiscalYear) != (new Date(this.outreachStartDate).getFullYear()) ? 0 : 1);
    this.getRegionAreaDistrict(this.userType);
    this.outreachStartDate = new Date(ApplicationSettings.getSRStartDate(this.dt.getFullYear()));
    for (let int_year: number = parseInt(ApplicationSettings.reportStartYear); int_year <= this.outreachStartDate.getFullYear() + 1; int_year++) {

      this.ddlYear.push({
        "yearName": int_year.toString(),
        "yearCode": int_year.toString()
      });
    }
    if (this.ddlYear.length > 0) {
      this.ddlFiscalYear = this.outreachStartDate.getFullYear() + 1;
    }
    this.loadQuarters();
    
    this.ddlTimePeriod = ApplicationSettings.getSRCurrentQuarter().toString();
    this.getTimePeriod();
    this.setFormControls();
    //this.generateReport();
  };

  getRegionAreaDistrict(region){
    let regionVal = region == "Pod" ? "pod" : region == "District" ? "podDistrict" : region;
    this.common_services.getAreaDist(regionVal, 0).subscribe(data => {
      let dataList = data;
      this.markets = [];
      this.areas = [];
      this.podList = [];
      this.districts = [];
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        if (region == "Region")
          this.markets.push({ key: keyValue, value: valueData });
        else if(region == "Area")
          this.areas.push({ key: keyValue, value: valueData });
        else if( region == "Pod" )
          this.podList.push({ key: keyValue, value: valueData });
        else if ( region == "District")
          this.districts.push({ key: keyValue, value: valueData });
        else 
          this.markets.push({ key: keyValue, value: valueData });

      }        
    });
  }
loadQuarters():void{
  this.Quarter=[];
  this.defaultQuarters.forEach(qtr => {
    this.Quarter.push(qtr);
  });
  let months_count: number = 20;
    let curr_date: Date = new Date();
    let year_start_month = this.outreachStartDate.getMonth() + 1;
    //if(this.startMonth[this.ddlFiscalYear - 1]){
    months_count = (curr_date.getMonth() < year_start_month) ? (year_start_month + 3) + curr_date.getMonth() : curr_date.getMonth() + 1;
    //    }
    //  else
    //  months_count = 20;
   
    // let start_month_number = 8;
    // start_month_number = this.outreachStartDate.getMonth(); //(this.startMonth[this.ddlFiscalYear - 1]) ? this.startMonth[this.ddlFiscalYear - 1] : 8;
    // for (let i: number = start_month_number; i < months_count; i++) {
    //   this.Quarter.push(
    //     new QuarterArray(this.month_names[((i >= 12) ? i - 12 : i)], (this.Quarter.length + 1).toString()+"-"+this.month_names[((i >= 12) ? i - 12 : i)])
    //   );
    // }
}
setFormControls() {
  this.activityReportForm = this.fb.group({
    'ddlFormat': [this.ddlFormat],
    'ddlFiscalYear': [this.ddlFiscalYear,null],
    'ddlRegion': [this.ddlRegion,null],
    'ddlDistrict': [this.ddlDistrict,null],
    'ddlArea': [this.ddlArea,null],
    'ddlPod': [this.ddlPOd, null],
    'ddlFilterBy': [this.ddlFilterBy,null],
    'ddlTimePeriod': [this.ddlTimePeriod,null]
  });
   //this.generateReport();
 }
 

  generateReport(): void {
    if(!this.showGrid){
      this.spinner.show();
    }
    else{
      this.loading = true;
    }
    console.log("this.ddlFilterby:" + this.ddlFilterBy);
    console.log("this.ddlArea:" + this.ddlArea);
    console.log("this.ddlDistrict:" + this.ddlDistrict);
    console.log("this.ddlFiscalYear:" + this.ddlFiscalYear);
    console.log("this.ddlRegion:" + this.ddlRegion);
    console.log("this.ddlPod:" + this.ddlPOd);
   
    let outreach_start_date = this.getQuarterStartDate();
    let quarter = this.getSelectedQuarter();
    let data = this.activityReportForm.value;
    let ar: ActivityReport = new ActivityReport(this.userProfile.userPk, this.userProfile.userRole, data.ddlFilterBy, data.ddlRegion, data.ddlArea, this.ddlPOd, data.ddlDistrict, outreach_start_date, quarter.toString(), this.outreachEffort, this.isCurrentSeason, "", "");
   
    this.activityreport.getActivityReportData(ar).subscribe((resultArray: any) => {
      this.showStoreId = (data.ddlFilterBy > 0);
      this.showDistrict = ((data.ddlFilterBy > 0 || (!this.showDistrictFilter)) && data.ddlFilterBy < 3);
      this.showArea = (data.ddlFilterBy == 1 || (!this.showAreaFilter));
      this.datasource = resultArray;
      this.totalRecords = this.datasource.length;
      this.showRegion=(!this.showRegionFilter);
      this.activityReportArray = this.datasource;
      //console.log("this.activityReportArray:" + this.activityReportArray.length);
      setTimeout(() => {
        this.loading = false;
        this.spinner.hide();
        this.showGrid = true;
        this.canExport=true;
      }, 1000)
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.activityReportArray = [];
      this.totalRecords = 0;
    });
  }
  generatereportformat(): void {
      this.loading = true;
      let outreach_start_date = this.getQuarterStartDate();
      let quarter = this.getSelectedQuarter();
      let data = this.activityReportForm.value;
      let ar: ActivityReport = new ActivityReport(this.userProfile.userPk, this.userProfile.userRole, data.ddlFilterBy, data.ddlRegion, data.ddlArea, data.ddlPOd, data.ddlDistrict, outreach_start_date, quarter.toString(), this.outreachEffort, this.isCurrentSeason, "ActivityReport", "xlsxv");
     
      this.activityreport.exportReportData(ar).subscribe((res:any) => {
        importedSaveAs(res.body, "ActivityReport.xlsx");
        this.loading = false;
      },error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
      });
  }

  getArea(event) {
    let region=this.activityReportForm.get("ddlRegion").value;
    this.common_services.getAreaDist('Area', region).subscribe((data) => {
      let dataList = data;
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.areas.push({ key: keyValue, value: valueData });
      };
      this.podList = [];
      this.districts = [];
      this.ddlPOd = -1;
      this.ddlArea = -1;
      this.ddlDistrict = -1;
    });
    this.canExport=false;
  }
  getPod() {
    let area=this.activityReportForm.get("ddlArea").value;
    this.common_services.getAreaDist('pod', area).subscribe((data) => {
      let dataList = data;
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.podList.push({ key: keyValue, value: valueData });
      };
      this.districts = [];
      this.ddlPOd = -1;
      this.ddlDistrict = -1;
    });
    this.canExport=false;
  };
  getOutreachStartDate() {
    let outreach_start_date = new Date();
    // Need to select the outreachstartdate based on the program selected
    //return outreach_start_date = new Date(appReportSettings["outreachStartDate" + appReportSettings.outreachProgramSelected].Length == 0 ? new Date() : appReportSettings["outreachStartDate" + appReportSettings.outreachProgramSelected]);

  }

  getDistrict(event) {
    let podNumber=this.activityReportForm.get("ddlPod").value;
    this.common_services.getAreaDist('podDistrict', podNumber).subscribe((data) => {
      let dataList = data;
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.districts.push({ key: keyValue, value: valueData });
      };
     //this.markets = data[0];  
      this.ddlDistrict = -1;
    });
   
  
    this.canExport=false;
  }
  getTimePeriod() {
    let month = new Date().getMonth() + 1;

  }

  onFiscalYearChanged():void{
    this.loadQuarters();
    this.canExport=false;
  }

  canExportOnchange(){    
    this.canExport=false;
  }
  validateExportType(){
    if(this.ddlFormat=="0"||this.canExport==false)
    return true;
    else
     return false;
  }
  getSelectedQuarter(){
    let data = this.activityReportForm.value;
    if(data.ddlTimePeriod.length>1){
      let selected_index = parseInt(data.ddlTimePeriod.substring(0,data.ddlTimePeriod.indexOf('-')));
      return selected_index;
    }
    else{
      return data.ddlTimePeriod;
    }
  }

  getQuarterStartDate(){
    let t_dt="";
    let data = this.activityReportForm.value;
    if(data.ddlTimePeriod.length>1){
      let selected_index = parseInt(data.ddlTimePeriod.substring(0,this.ddlTimePeriod.indexOf('-')));
      let selected_month = data.ddlTimePeriod.substring(data.ddlTimePeriod.indexOf('-')+1);
      selected_month = this.month_names.indexOf(selected_month)+1;
      //if(selected_index > ((this.startMonth[this.ddlFiscalYear - 1]) ? 12 : 9)){
        if(selected_index > ((this.outreachStartDate.getMonth() +1) ? 12 : 9)){
          t_dt = selected_month + "/01/" + this.ddlFiscalYear;  
      }else{
          t_dt = selected_month + "/01/" + (this.ddlFiscalYear - 1);   
      }
    }
    else{
      this.isCurrentSeason = ((data.ddlFiscalYear) != (new Date(this.outreachStartDate).getFullYear()+1) ? 0 : 1);
      let outreach_start_date:any = new Date(new Date(((this.isCurrentSeason)?(((this.outreachStartDate.getMonth() +1)?(this.outreachStartDate.getMonth() +1) + "/01/":"09/01/")):"09/01/") + (data.ddlFiscalYear -1)));
      t_dt = ((outreach_start_date.getMonth() + 1) + "/" + outreach_start_date.getDate() + "/" + outreach_start_date.getFullYear());
    }
    return t_dt;

  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }

  reset(): void {
    //this.loading = true;
   
    this.showGrid = false;
    this.ddlFormat = "xlsxv";
    this.ddlFiscalYear = this.outreachStartDate.getFullYear() + 1;
    this.ddlFilterBy = "0";
    this.ddlDistrict = "-1";
    this.ddlArea = "-1";
    this.ddlRegion = "-1";
    let region = this.showddlState ? "Region" : "Area";
    this.getRegionAreaDistrict(region);
    this.ddlTimePeriod = ApplicationSettings.getSRCurrentQuarter().toString();
    this.setFormControls();  
 
    //this.generateReport();
  }
}
export class FilterByArray {
  constructor(public id: string, public name: string) { }
}
export class QuarterArray {
  constructor(public name: string, public id: string) { }
}

