import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { VoucherFormData } from '../../../models/voucherFormData';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ClinicdetailsService {
  constructor(private http: HttpClient, private router: Router) {
  }
  
  geLocalClinicDetails(business_clinic_pk, user_role): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_LOCAL_CLINIC_DETAILS + "/" + business_clinic_pk + "/" + user_role;
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  confirmClinicDetails(clinicDetails: any): Observable<any> {
    var url = environment.API_URL + environment.RESOURCES.CONFIRM_CLINIC_DETAILS;
    return this.http.post(url, clinicDetails, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  upDateClinicDetails(business_clinic_pk,clinic_details,is_override?:boolean) {
    var url = environment.API_URL + environment.RESOURCES.UPDATE_CLINIC_DETAILS+"/"+business_clinic_pk;
    if(is_override){
      url = url + "/" + is_override;
    }
    return this.http.post(url, clinic_details, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }
  deleteClinicDetails(clinic_details) {
    var url = environment.API_URL + environment.RESOURCES.DELETE_CLINIC_DETAILS;
    return this.http.post(url, clinic_details, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }
  deleteAmendAgreement(clinic_agreement_pk,user_pk) {
    
    var url = environment.API_URL + environment.RESOURCES.DELETE_AMEND_AGREEMENT+"/"+clinic_agreement_pk+"/"+user_pk;
 
    return this.http.post(url,'', Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }
  getAreaDist(location_type,type):Observable<any>
  {
    var url = environment.API_URL + environment.RESOURCES.AREA_REGION_LIST+"/"+location_type+"/"+type;
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }
  
  getContractVoucherFormData(voucher_form_data:VoucherFormData) {
    var url = environment.API_URL + environment.RESOURCES.GET_CONTRACT_VOUCHER_FORM_DATA;
    return this.http.post(url, voucher_form_data, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }
  removeClinicCancelledStatus(clinic_pk,status) {
    var url = environment.API_URL + environment.RESOURCES.REMOVE_CLINIC_CANCELLED_STATUS+"/"+clinic_pk+"/"+status;
    return this.http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
    deleteClinic(clinic_pk) {
      var url = environment.API_URL + "/api/ClinicDetails_deleteClinic/"+ clinic_pk;
      return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
 cacheCPTokenData() {     
    let url = environment.API_URL + environment.RESOURCES.CACHE_CP_TOKEN_DATA;
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
   
  getclinicappointmentdetails(clinicPk : any): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_CLINIC_SCHEDULAR_APPOINTMENTS + "/" + clinicPk;
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}
