import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm, FormBuilder, FormArray } from '@angular/forms';
import { STEPS, COSTEPS } from '../../workflow/workflow.model';
import { WorkflowGuard } from '../../workflow/workflow-guard.service';
import { ImmunizationsComponent } from '../immunizations/immunizations.component';
import { LocationsListComponent } from '../contract-locations-list/locations-list.component';
import { OutreachProgramService } from '../../services/outreach-program.service';
import { OutReachProgramType } from '../../../../models/OutReachPrograms';
import { KeyFilterModule, KeyFilter, KEYFILTER_VALIDATOR } from 'primeng/keyfilter';
import { Router } from '@angular/router';
import { SessionDetails } from '../../../../utility/session';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { Location, DatePipe } from '@angular/common';
import { AlertService } from '../../../../alert.service';
import { TimeToDatePipe } from '../../pipes/time-to-date.pipe';
import { String, StringBuilder } from 'typescript-string-operations';
import { ConfirmationService } from 'primeng/api';
import { OpportunitiesService } from '../../../store/services/opportunities.service';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-equityevents',
  templateUrl: './equityevents.component.html',
  styleUrls: ['./equityevents.component.css']
  , providers: [WorkflowGuard,DatePipe,TimeToDatePipe]
})
export class EquityeventsComponent implements OnInit {
  @ViewChild(ImmunizationsComponent) imzcmp: ImmunizationsComponent;
  @ViewChild(LocationsListComponent) loccmp: LocationsListComponent;
  addedImmunizations: any[] = [];
  InvoiceInformation:any[]=[];
  showHints:boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff"))=='yes')?false:true;
  pageName:string = "equityevent";

  public contractForm: FormGroup;
  step: number = 1;
  step_desc: string;
  contractData: any;
  form: NgForm
  errorse: any;
  errorstatus: any;
  OutReachProgramType: string;
  nextBtnTitle: string = 'Next';
  validateInvoceInfo: boolean = false;
  display: boolean = false;
  interimDisplay: boolean = false;
  cnfdisplay: boolean = false;
  cnfcontinue: boolean = false;
  dialogSummary: string;
  dialogMsg: string;
  //displayInventoryWarning: boolean = false;
  //displayInventoryWarningOkClicked:boolean = false;
  clinicdatavalue: any;
  cancel_save: string;
  savePopUp: boolean = false;
  invoiceInfoPopUp: boolean = false;
  clinicType:number = 6;
  isLocationsFormValid = true;
  startTimeEndTimesStep_desc:string ;
  showStartTimeEndTimeValid :boolean = false;
  isTimeValid:Number = 0;
  immunizerWarningAlert:boolean = false;
  immunizerWarningOkClicked:boolean = false;
  OverrideOrOkTitle:string = "Override";
  showCancelBtn:boolean =false;
  covidVaccineWarningOkClicked = false;
  covidVaccineSelected = false;
  isAdmin = false;

  constructor(
    private _fb: FormBuilder, 
    private _location:Location, 
    private _localContractService: OutreachProgramService, 
    private _workflowGuard: WorkflowGuard,
    private router: Router, 
    private confirmationService: ConfirmationService,
    private _alertService: AlertService, 
    private _datePipe: DatePipe, 
    private _timeToDatePipe: TimeToDatePipe,
    private _opportunitiesService: OpportunitiesService,
  ) {
  }
    
  ngOnInit() {
  
    this.OutReachProgramType = OutReachProgramType.equityevents;
    this._localContractService.clearSelectedImmunizations();
    this._localContractService.clearCOClinicLocations();
    this.immunizerWarningOkClicked = false;
    this.covidVaccineWarningOkClicked = false;
    this.isAdmin = false;
    //this.displayInventoryWarning = false;
    //this.displayInventoryWarningOkClicked = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  handlePageLeaveNavigation($event: BeforeUnloadEvent): void {
    $event.returnValue = true;
  }

  GoToStep(step: number) {    
    this.loccmp;
   
    if(this.loccmp){
      if(this.loccmp.locations_data1.value.contactPhone.indexOf("X") > -1){
        this.loccmp.locations_data1.controls.contactPhone.setValue(null);
        this.loccmp.locations_data1.controls.contactPhone.updateValueAndValidity();
        return false;
      }

      const locations = this.loccmp.contractForm.controls.locations as FormArray;

      for (const location of locations.controls) {
        const locationFormCtrl = location as FormGroup;
        if(locationFormCtrl.value.coOutreachTypeId == null || locationFormCtrl.value.coOutreachTypeId == 'null'){
          locationFormCtrl.controls['coOutreachTypeId'].setErrors({incorrect: true});
          locations.markAllAsTouched();
        }
        else{
          locationFormCtrl.controls['coOutreachTypeId'].setErrors(null);
        }
        location.updateValueAndValidity();
      }
      
      // if(!this.loccmp.contractForm.valid && !this.covidVaccineSelected){
      //   this.loccmp.contractForm.markAllAsTouched();
      //   return false;
      // }
    }
    let step_desc = this.getStepDesc(step);
    this.step_desc = this.getStepDesc(this.step);    
    if (step < this.step) {
      this.step = step;
      this.immunizerWarningOkClicked = false;
      this.covidVaccineWarningOkClicked = false;
      this.isAdmin = false;
      step >= 2 ? this.nextBtnTitle = "Submit" : this.nextBtnTitle = "Next";
     // this.displayInventoryWarningOkClicked = false;
      this.cacheInEnteredData(this.step_desc);
      return;
    }
    // if (step >= 2&&this.displayInventoryWarningOkClicked) {
    
    //   this.step = 2;
    // }
    
    if (this.OnSave(step)  ) { 
      if (this.step_desc != step_desc) {
        this.validateInvoceInfo=false;
        this.step_desc = this._workflowGuard.verifyWorkFlow(step_desc);
        step >= 2 ? this.nextBtnTitle = "Submit" : this.nextBtnTitle = "Next";
        let locSteps = COSTEPS;
        this.step = Object.keys(locSteps).findIndex(inx => inx == this.step_desc) + 1;
      }
    }
  }

  getStepDesc(step: number) {
    if (step === 1)
      return STEPS.immunization;
    if (step >= 2)
      return STEPS.location;
  }
  cacheInEnteredData(step_desc: any, currentStep?: number, prevStep?: number) {
    
    let return_value: boolean = false;
    if (this.step_desc === STEPS.immunization) {
      return_value = this.imzcmp.simpleSave();
    }
    else if (this.step_desc === STEPS.location) {
      if (this.loccmp != undefined) {
        this.loccmp.simpleSave();
        if(!this.loccmp.isFormValid()){
          this.isLocationsFormValid = false;
        } else {
          this.isLocationsFormValid = true;
        }
      }
    }
  }
  ContinueInvoiceInfo()
  {    
    this.invoiceInfoPopUp=false;     
    this.validateInvoceInfo=true;
    this.GoToStep(2);
  }
  OnSave(curentStep: Number) { 
    let return_value = false;
    let userProfile = SessionDetails.GetUserProfile();
    this.isAdmin = userProfile.userRole.toLowerCase() == "admin";
    if (this.step_desc === STEPS.immunization) {
      return_value = this.imzcmp.save(this.OutReachProgramType);
      this.addedImmunizations = this._localContractService.getImmunizationsData();      
      this.InvoiceInformation = this._localContractService.getInvoiceAndVoucherData();
      if(this.InvoiceInformation.length>0 ){
        let corp=this.addedImmunizations.filter(c=>c.paymentTypeId=="6");
        if(corp.length===0 && !this.validateInvoceInfo && this.imzcmp.isFormValid()){
          this.step = 1;
          this.invoiceInfoPopUp=true;
          this.validateInvoceInfo=true;
          return false;
        }
      }
      
      if (!return_value) {
        this.step = 1;
      }      
      // if(this.imzcmp.isFormValid()&&!this.displayInventoryWarningOkClicked&&this._localContractService.checkIfFluImmIsSelected(this.clinicType)){
      //   this.showdisplayInventoryWarningDialog("INVENTORY ALERT","Please ensure you have sufficient vaccine prior to committing to this opportunity.");
      //   return false;
      // }
    }

    // if (this.step_desc === STEPS.location && !this.loccmp.isFormValid()) {
    //   return false;
    // }
    
    let locString: String[] = [];
    if (this.step_desc === STEPS.location) {
      if(!this.covidVaccineWarningOkClicked){
        const locations = this.loccmp.contractForm.get('locations') as FormArray;
        for (const location of locations.controls) {
          const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
          let clinicDate = location.get('clinicDate').value;
          clinicDate = moment(clinicDate);
          let startDate  = moment(environment.covidVaccineNotAviableDates.startDate, "DD/MM/YYYY");
          let endDate = moment(environment.covidVaccineNotAviableDates.endDate, "DD/MM/YYYY");
          for (const clinicImzQty of clinicImzQtyList.controls) {
            if(clinicImzQty.get('immunizationName').value.toLocaleLowerCase()?.includes('covid') && (clinicDate.isBetween(startDate, endDate) || clinicDate.isSame(startDate) || clinicDate.isSame(endDate))){
              this.covidVaccineSelected = true;
              this.dialogMsg = ErrorMessages["sesionalCovid19Info"];
              this.dialogSummary = "Alert";
              clinicImzQty.get('estimatedQuantity').setErrors({'incorrect': true});
              clinicImzQty.get('estimatedQuantity').markAsDirty();
              clinicImzQty.get('estimatedQuantity').markAsTouched();
              clinicImzQty.updateValueAndValidity();
            } else {
              clinicImzQty.get('estimatedQuantity').setErrors(null);
              clinicImzQty.get('estimatedQuantity').markAsDirty();
              clinicImzQty.get('estimatedQuantity').markAsTouched();
              clinicImzQty.updateValueAndValidity();
            }
          } 
        }
        if(this.covidVaccineSelected) {
          locations.updateValueAndValidity();
          return false;
        } else {
          locations.updateValueAndValidity();
        }
      }
      if(!this.immunizerWarningOkClicked){
        let estimatedImmunizers = 2;
        let errorList:string [] = [];
        
        this.getEstimatedImmunizers(errorList);
        if(errorList.length>0){
        this.showdImmunizerWarningDialog("Warning", ErrorMessages['immunizerWarningMainPara']+errorList.join().replace(",",""));
        return false;
        }
      }
      if (this.loccmp.isClinicDateReminderBefore2WksReq(locString)) {
        let sumMsg = ErrorMessages['impRmndrFor2Wks'];
        let errMsg = ErrorMessages['new_clinicDateReminderBefore2WeeksEN'];
        var lowerCaseName = userProfile.userRole.toLowerCase(); 
          this.step = 2;
          this.OverrideOrOkTitle = "OK";
          this.showCancelBtn =false;
          this.confirm(sumMsg, errMsg);
          return true; 
      }
      
      this.isTimeValid = this.loccmp.isStartTimeEndTimeValid();
      if(this.isTimeValid){       
       this.startTimeEndTimesStep_desc = this.step_desc;
       this.dialogSummary = "Alert";
       this.showStartTimeEndTimeValid = true;
       if(this.isTimeValid==1){
       this.dialogMsg = ErrorMessages['clinicLengthMoreThan8Hrs'];
       }
       else if(this.isTimeValid == 2){
        this.dialogSummary = "Error";
         this.dialogMsg = ErrorMessages['clinicLengthAlert'];
       }
       else {
         this.dialogMsg = "Please Contact support.";
       }
       return false;
     }   
      return_value = this.loccmp.save(this.OutReachProgramType);
      if (return_value) {
        this._alertService.clear();
        if(this.loccmp.isNonNoClinicPresent()){
          this._alertService.info(ErrorMessages['coClinicDateReminderAfter2WeeksEN'],true);  
          }
        
        if (this._localContractService.saveEquityEventsData()) {
          if(this.loccmp.submitCoData(false)){
          }
        }
      }
    }
    return return_value;
  }
  getEstimatedImmunizers(errorList:string[]){
    
  let isCovidVaccineAvailable:boolean =   this.addedImmunizations.filter(p=>p.immunizationName.search(/COVID19/gi)>=0).length>0;                
  let shots_per_10_min = 4;
  // let slotTime = isCovidVaccineAvailable ? 15 : 10;
  // isCovidVaccineAvailable?shots_per_10_min=2:shots_per_10_min=4;

  let slotTime = 10;
  let shots_per_hour = (60 / slotTime) * shots_per_10_min;  
  
    this.loccmp.contractForm.value.locations.forEach(loc => {
      try {
          if (loc.startTime != "")
              try{
              loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
              }catch(e){
                  console.log(e);        
              }
          if (loc.endTime != "") {
              try{
              loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
          }catch(e){
              console.log(e);        
          }
          }
      } catch (e) {
          console.log(e); // here the exception is delibarately caught because when you click back, startTime & end
          // Time is not correct timeformat. Hence we are ignoring this by catching here.
      }
  });
  for (let count = 0; count < this.loccmp.contractForm.value.locations.length; count++) {    
    let locClinicDate:Date = new Date(this.loccmp.contractForm.value.locations[count].clinicDate);
            let locStartTime = this.loccmp.contractForm.value.locations[count].startTime;
            let locEndTime = this.loccmp.contractForm.value.locations[count].endTime;
            if (this.loccmp.contractForm.value.locations[count].isNoClinic >= 1 || 
                this.loccmp.contractForm.value.locations[count].isNoClinic === '1' || 
                this.loccmp.contractForm.value.locations[count].isReassign >= 1 || 
                this.loccmp.contractForm.value.locations[count].isReassign === '1') {
                continue;
            }
            let endDate: Date;
            if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
                endDate = this._timeToDatePipe.transform(locClinicDate, locEndTime);
            }
            else {
                endDate = locEndTime;
            }
            let startDatetmToDt: Date;
            if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
                startDatetmToDt = this._timeToDatePipe.transform(locClinicDate, locStartTime);
            }
            else {
                startDatetmToDt = locStartTime;
            }
            let locStart: Date = new Date(startDatetmToDt);
            let locEnd: Date = new Date(endDate);
            let clinicLength = (locEnd.valueOf()-locStart.valueOf())/(3600*1000);
            if(clinicLength> 0 ){   
             let clinicLoc= this.loccmp.contractForm.value.locations[count];  
             let totalImmunizations = clinicLoc.clinicImzQtyList.map(item=>item.estimatedQuantity).reduce((acc,current)=>Number(acc)+Number(current));
             // = clinicLoc.clinicImzQtyList.reduce((accum,current)=>accum+current.estimatedQuantity,0);
             let estimatedImmunizers = Math.ceil(totalImmunizations/(shots_per_hour*clinicLength))
             if(estimatedImmunizers>1){
             errorList.push(String.Format(ErrorMessages['immunizerWarning'],clinicLoc.location,estimatedImmunizers))
             }
             totalImmunizations =  0;
             estimatedImmunizers = 0;
            }
            
  }
  }
  showdImmunizerWarningDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.immunizerWarningAlert = true;
  }
  showdImmunizerWarningDialogOk(){
    this.immunizerWarningAlert = false;
    this.immunizerWarningOkClicked = true;;
    this.GoToStep(2);
  }
  controlBtnsDisplay(event){
    this.showCancelBtn = true;
    this.OverrideOrOkTitle = "Override";
  }
  editdImmunizerWarning(){
    this.immunizerWarningAlert = false;
  }
  EditTimes(){
    this.showStartTimeEndTimeValid=false;
  }
  ContinueToNextStep(){
    this.showStartTimeEndTimeValid=false;
    

  if(this.loccmp.save(OutReachProgramType.equityevents)){
    let is_override = false;
  if (this.loccmp.submitCoData(is_override)) {
    
  }
}
    else {
      this.step = 2;
    }
    return true;
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  showDialogCancel(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
  }

  showInterimDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.interimDisplay = true;
  }

  cnfshowDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.cnfdisplay = true;
  }

  confirm(hdr: string, msg: string,is_overlap?:boolean) {
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {
        this.isTimeValid = this.loccmp.isStartTimeEndTimeValid();
        if(this.isTimeValid){       
          this.showStartTimeEndTimeValid = true;
         this.startTimeEndTimesStep_desc = this.step_desc;
         this.dialogSummary = "Alert";
         if(this.isTimeValid==1) {
         this.dialogMsg = ErrorMessages['clinicLengthMoreThan8Hrs'];

         this.dialogSummary = "Error";
         }
         else if(this.isTimeValid == 2){
           this.dialogMsg = ErrorMessages['clinicLengthAlert'];
         }
         else {
           this.dialogMsg = "Please Contact support.";
         }
         return false;
       } 
        if(this.loccmp.save(OutReachProgramType.equityevents)){
          let is_override = (is_overlap)?true:false;
        if (this.loccmp.submitCoData(is_override)) {
          this._alertService.clear();
          if(this.loccmp.isNonNoClinicPresent()){
            this._alertService.info(ErrorMessages['coClinicDateReminderAfter2WeeksEN'],true);  
            }
        }
      }
        else {
          this.step = 2;
        }
        return true;
      },
      reject: () => {
        this.step = 2;
        return false;
      }
    });
  }

  okClicked() {
    this.display = false;
    if (this.router) this.router.navigate(['./communityoutreach/storehome']);//TODO
  }

  doNotSave() {
    this.savePopUp = false;

    const isReAgreement = JSON.parse(sessionStorage.getItem('NewAmendAgreement'));

    if(!isReAgreement){
      const opportunitie_data = JSON.parse(sessionStorage.getItem('opportunitie_data'));
      const outreachBusinessPk = opportunitie_data?.outreachEffort?.IP?.outreachBusinessPk;

      if(outreachBusinessPk){
        this._opportunitiesService.updateOutreachStatusToNoOutreach(outreachBusinessPk)
        .subscribe(
          data => {
            if (data == 'success') {
              this.router.navigate(['./communityoutreach/storehome']);
            }
          }
        );
      }
    }
    else{
      this.router.navigate(['./communityoutreach/storehome']);
    }
  }

  Continue() {
    this.savePopUp = false;
  }

  okInterimClicked() {
    this.interimDisplay = false;
  }

  cancelCommOutReach() {
    this.validateInvoceInfo = false;
    let return_value = false;
    if (this.step == 1) {
      return_value = this.imzcmp.cancelImmunization();
      if (return_value) {
        this.savePopUp = true;
        this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['eq_alert']);
      }
      else {
        const isReAgreement = JSON.parse(sessionStorage.getItem('NewAmendAgreement'));

        if(!isReAgreement){
          const opportunitie_data = JSON.parse(sessionStorage.getItem('opportunitie_data'));
          const outreachBusinessPk = opportunitie_data?.outreachEffort?.IP?.outreachBusinessPk;

          if(outreachBusinessPk)
          {
            this._opportunitiesService.updateOutreachStatusToNoOutreach(outreachBusinessPk)
            .subscribe(
              data => {
                if (data == 'success') {
                  this.router.navigate(['./communityoutreach/storehome']);
                }
              }
            );
          }
        }
        else{
          this.router.navigate(['./communityoutreach/storehome']);
        }
      }
    }
    if (this.step == 2) {
      return_value = this.loccmp.cancelLocationData();
      if (return_value) {
        this.savePopUp = true;
        this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['eq_alert']);
      }
      else {
        const isReAgreement = JSON.parse(sessionStorage.getItem('NewAmendAgreement'));

        if(!isReAgreement){
          const opportunitie_data = JSON.parse(sessionStorage.getItem('opportunitie_data'));
          const outreachBusinessPk = opportunitie_data?.outreachEffort?.IP?.outreachBusinessPk;

          if(outreachBusinessPk){
            this._opportunitiesService.updateOutreachStatusToNoOutreach(outreachBusinessPk)
            .subscribe(
              data => {
                if (data == 'success') {
                  this.router.navigate(['./communityoutreach/storehome']);
                }
              }
            );
          }
        }
        else{
          this.router.navigate(['./communityoutreach/storehome']);
        }
      }
    }
  }

  coSubmitBtnClicked() {
    if (this._localContractService.saveInterimCommunityOutreachData(this.step)) {
      let sumMsg = ErrorMessages['coSummary'];
      let sMsg = ErrorMessages['coSucccess'];
      this.showDialog(sumMsg, sMsg);
    }
  }

  coSaveBtnClicked() {
    if (this.step == 1) {
      if (this._localContractService.getImmunizationsData() !== '') {
        if (this._localContractService.getImmunizationsData().length > 0) {
          this.imzcmp.save(this.OutReachProgramType);
        }
        else {
          //log co contact
          return;
        }
      }
      else {
        //log co contact.
        return;
      }
    }
    else if (this.step == 2) {
      this.loccmp.save(this.OutReachProgramType);
    }
    if (this._localContractService.saveInterimCommunityOutreachData(this.step)) {
      let sumMsg = ErrorMessages['coSummary'];
      let sMsg = ErrorMessages['coSucccess'];
      this.showInterimDialog(sumMsg, sMsg);
      //this.step = 1;
    }
}
// showdisplayInventoryWarningDialog(msgSummary: string, msgDetail: string) {
//   this.dialogMsg = msgDetail;
//   this.dialogSummary = msgSummary;
//   this.displayInventoryWarning = true;
// }
// displayInventoryWarningOk(){
//   this.displayInventoryWarning = false;
//   this.displayInventoryWarningOkClicked = true;;
//   this.GoToStep(2);
// }

handleCovidVaccineAlertOk(){
  this.covidVaccineSelected = false;
}

handleCovidVaccineAdminConfirmation(){
  const locations = this.loccmp.contractForm.get('locations') as FormArray;
  this.covidVaccineSelected = false;
  this.covidVaccineWarningOkClicked = true;
  for (const location of locations.controls) {
    const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
    for (const clinicImzQty of clinicImzQtyList.controls) {
      if(clinicImzQty.get('immunizationName').value.toLocaleLowerCase()?.includes('covid')){
        clinicImzQty.get('estimatedQuantity').setErrors(null);
        clinicImzQty.get('estimatedQuantity').markAsDirty();
        clinicImzQty.get('estimatedQuantity').markAsTouched();
        clinicImzQty.updateValueAndValidity();
      }
    }
  }
  locations.updateValueAndValidity();
  this.GoToStep(2);
}

}
