import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionDetails } from '../utility/session';

@Injectable()
export class AdminRouteGuard  {

    constructor(
        private router: Router) {
    }

    canActivate() {     
        
        var userProfile = SessionDetails.GetUserProfile();
        if (userProfile) {
            if (userProfile.userRole.toLowerCase() == "district manager")
                return true;
            if (userProfile.userRole.toLowerCase() == "director - rx and retail ops")
                return true;
            else if (userProfile.userRole.toLowerCase() == "healthcare supervisor")
                return true;
            else if (userProfile.userRole.toLowerCase() == "regional vice president")
                return true;
            else if (userProfile.userRole.toLowerCase() == "regional healthcare director")
                return true;
            else if (userProfile.userRole.toLowerCase() == "admin")
                return true;
            else if (userProfile.userRole.toLowerCase() == "store manager")
                return true;
            else if (userProfile.userRole.toLowerCase() == "pharmacy manager")
                return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}