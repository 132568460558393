
import {of as observableOf,  Observable, Subscription, forkJoin } from 'rxjs';
import { Chart, LegendElement, LegendItem } from 'chart.js';
import {catchError, switchMap, tap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { OpportunitiesService } from '../../store/services/opportunities.service';
import { SessionDetails } from '../../../utility/session';
import { NgxSpinnerService } from "ngx-spinner";
import { MessageServiceService } from '../../store/services/message-service.service';
import { DashBoard } from '../../../models/dashboard';
import { ClinicdetailsService } from '../../clinicdetails/services/clinicdetails.service';
import { HeaderserviceService } from '../../common/services/headerservice.service';
import { DashboardServiceService } from '../Services/dashboard-service.service';
import { ClinicAssignment, ClinicList } from '../../../models/clinicassignment';
import { LocalLeads } from '../../../models/localLeads';
import { LocalLeadsSave, OpportunitiesList } from '../../../models/localLeadsSave';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import { String } from 'typescript-string-operations';
import { FilterPipe } from '../../store/pipes/filter-pipe';
import { Router } from '../../../../../node_modules/@angular/router';
import { ErrorMessages } from '../../../config-files/error-messages';
import { Title } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { CancelSchedulledClinics, CancelAppointments } from '../../../models/cancelSchedulledClinics';
import { TableHeaderCheckbox } from 'primeng/table';
import { noClientInterestOptions } from '../../../JSON/NoClientInterestOptions';
import { TableInterface } from '../../../shared/models/tableInterface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActionComponent } from '../leadsIntake/action/action.component';
import { LeadAwaitingResponseService } from '../Services/leadsAwaitingResponse';
import { LeadAwaitingResponse, LeadAwaitingResponseById, LeadAwaitingResponseByIdList, LeadsAwaitingResponseList, ReassignLeadToClosestStore } from '../../../../app/models/leadAwaitingResponse';
import { PotentialClientInterestService } from '../../common/services/potential-client-interest.service';
import { ChartService } from '../Services/chartService';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AnyAaaaRecord } from 'dns';
import { OpportunitiesRequiringActionResponse } from '../../../models/Chart';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [FilterPipe, DialogService]
})

export class DashboardComponent implements OnInit {
  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
  pageName: string = "home";

  displaySE: boolean = false;
  successDistStore: boolean = false;
  displayClinic: boolean = false;
  displayLocal: boolean = false;
  clinicHCSInfo: boolean = false;
  dispDlgReassignToHCS: boolean = false;
  LocalOpportunitiesInfo: boolean = false;
  DataList: any[];
  showVarHCS: boolean = false;
  HCSMetrics: any[] = [];
  HCSAction = [];
  PCAActionItems = [];
  PCAMetrics = [];
  submitEntryBtn: boolean = false;
  title: string;
  selectedData: any;
  showVarHCSLocal: boolean = false;
  HCSInfoVisible: boolean = true;
  DMInfoVisible: boolean = false;
  StoreInfoVisible: boolean = false;
  CancelInfoVisible: boolean = false;
  UnassignLocalInfoVisible: boolean = true;
  LocalDistrictInfoVisible: boolean = false;
  AssignedLocalInfoVisible: boolean = false;
  dispDlgReassign: boolean = false;
  user_profile: any;
  subscription: Subscription;
  message: any;
  store_id: any;
  searchText: any;
  area_dist = [];
  dashboard: DashBoard;
  clinicAssignment: ClinicAssignment;
  storeV: boolean = false;
  distV: boolean = false;
  ariaV: boolean = false;
  regionV: boolean = false;
  store_local: boolean = false;
  dist_local: boolean = false;
  aria_local: boolean = false;
  region_local: boolean = false;
  storeV1: boolean = false;
  distV1: boolean = false;
  ariaV1: boolean = false;
  regionV1: boolean = false;
  searching = false;
  selectOption: any = "";
  cancelClinicCols: any[];
  clinicCols: any[];
  localOppCols: any[];
  search_value: any;
  searchValue: any = "";

  search_from_list: string = "";
  search_HCS_value = "";

  search_from_list_local: any = "";
  search_local_value = "";

  searchLocalValue: any = "";
  district_number: any;
  area_number: any;
  business_pk: any;
  page_index: any = 0;
  districtList: any;
  areaList: any;
  storeList: any;
  storeTab: boolean = false;
  districtTab: boolean = false;
  HCSTab: boolean = false;
  assignReq: boolean = false;
  bulkAction_hcs: boolean = false;
  bulkAction_dm: boolean = false;
  bulkAction_store: boolean = false;
  bulkAction_cancel: boolean = false;

  bulkAction_local_hcs: boolean = false;
  bulkAction_local_dm: boolean = false;
  bulkAction_local_store: boolean = false;

  selectedDataList: any[] = [];
  selectedLocalList: any[] = [];
  selected_data_list: any[] = [];
  selectedCancelledList: any[] = [];
  selectedValue: any = "";
  clinic_type: any;
  store_number: number;
  bulk_submit: boolean = false;
  HCSClinicDetails = [];
  private hcsClinicsSource: any = {};
  hcsClinicTotalRecords: number;
  hcsClinicNoOfPageRecords: number = 10;
  cancelledClinicTotalRecords: number;
  HCSClinicDM = [];
  HCSClinicStore = [];
  HCSClinicCancelled = [];
  cancelClinicSourceList: any = {};
  cancelClinicList: any[] = [];
  //HCSClinicDetailsFilter = [];
  //HCSClinicDMFilter = [];
  //HCSClinicStoreFilter = [];

  HCSCancelStores = [];
  private hcsLocalLeadsSource: any = {};
  hcsLocalTotalRecords: number;
  hcsLocalNoOfPageRecords: number = 10;
  HCSLocalLeads = [];
  HCSLocalLeadsDM = [];
  HCSAssignedLocalLeads = [];
  out_reach_business_pk: any;
  out_reach_status_id: any;
  currentSelectedRowItem: any;
  currentDestinationStoreAddress: any;
  is_clinic: boolean = true;
  defaultDistrict: any;
  defaultAreaNumber: any;
  reassign_to_hcs: boolean = false;
  changestatusPopUp: boolean = false;
  outreachstatus: any = "";
  hcs_clinic_details: boolean = false;
  hcs_clinic_DM: boolean = false;
  hcs_clinic_store: boolean = true;
  hcs_clinic_cancel: boolean = true;
  hcs_local_details: boolean = true;
  hcs_local_DM: boolean = true;
  hcs_local_store: boolean = true;

  outreach: boolean = false;
  noClient: boolean = false;
  businessClosed: boolean = false;
  hcs: boolean = false;
  successMessage: any;
  HCS_contacted: string = '';
  HCS_contacted_value: any;
  displayStoredlg: boolean = false;
  page_index_value: any;
  districtSelected: boolean = false;
  districtValue: any = null;
  selectAllDist: boolean = false;
  grid: any;
  first: number = 0;
  bulk_action_value: any;
  bulkDisplayStoredlg: boolean = false;
  BulkdispDlgReassignToHCS: boolean = false;
  btnHide: boolean = false;
  HCSLocalLeads1 = [];
  defaultStore: any;
  default_store_id: any;
  area_user: boolean = false;
  dist_user: boolean = false;
  admin_user: boolean = false;
  region_user: boolean = false;
  clinicLatitude: any;
  clinicLongitude: any;

  hcs_local_details_search: boolean = false;
  hcs_local_DM_search: boolean = false;
  hcs_local_store_search: boolean = false;

  hcs_clinic_details_search: boolean = false;
  hcs_clinic_DM_search: boolean = false;
  hcs_clinic_store_search: boolean = false;
  select_data: boolean = false;
  selectMessage: any;
  userProfile: any;
  page_count: number = 0;
  sorting: string = "";
  PCATab: boolean = false;
  @ViewChild('dt') dataTable: Paginator;
  @ViewChild('dtDM') dataTable_DM: Paginator;
  @ViewChild('dtStore') dataTable_store: Paginator;

  @ViewChild('dtclinic') dataTableClinic: Paginator;
  @ViewChild('dtclinicdm') dataTableClinic_DM: Paginator;
  @ViewChild('dtclinicstore') dataTableClinic_store: Paginator;
  @ViewChild('dtcancelclinic') dataTableCancelClinic_store: Paginator;
  BulkdispDlgReassignToHCSStoreTab: boolean = false;
  BulkdispDlgConfirmCancelClinicTab: boolean = false;
  BulkdispDlgRevertCancelClinicTab: boolean = false;
  BulkdispDlgReassignHasSREvents: boolean = false;
  localOppHasSREvents: string = "";
  selectOptionLocal: any = "";
  page_index_sort: any;
  clinicType: boolean = false;
  errorShow: boolean = false;
  errorMessageShow: any = '';
  dialogSummary: string = "Error Message";
  errorLocalShow: boolean = false;
  errorMessageLocalShow: any = '';
  errMsgV1: string[] = [];
  localLeadsSortBy: string = "storeId";
  scheduledClinicsSortBy: string = "naClinicDate";
  localLeadsPageIndex: number = 0;
  scheduledClinicsPageIndex: number = 0;
  localLeadsSortOrder: boolean = true;//false for descending
  scheduledClinicsSortOrder: boolean = true;//false for descending
  formLoaded: boolean = false;
  confirmLabel: string = "Override";
  reassignToHCSConfirmDlg: boolean = false;
  dlgMessage: string = "";
  disposition: string = '';
  displayDispositionError = false;
  NCIOptions: string[] = [];
  columns: any;
  tableOptions: TableInterface;
  hcaTableOptions: TableInterface;
  dialogRef: DynamicDialogRef;
  showFullSubmission = false;
  showRespondStatus = false;
  showRespondAction = false;
  showRespondActionDialog = false;
  responseMessage : string = '';
  selectedResponse = '';
  showMessage = false;
  showReassignStatus = false;
  seachAwaitingLeadsResponse = false;
  leadsSearchValue = "";
  requestType = 1;
  pageIndex = 0;
  pageSize = 0;
  leadAwaitingResponseList: Array<LeadAwaitingResponse> = [];
  leadAwaitingResponseInfo: LeadAwaitingResponseById = {} as LeadAwaitingResponseById;
  leadDistrictList = [];
  leadAwaitingResponseSelectedDistrict = "";
  leadAwaitingResponseSelectedStore = "";
  selectedLeadAwaitingResponseInfo: LeadAwaitingResponse = {} as LeadAwaitingResponse;
  showReassignSuccessDialog = false;
  priorityOppourtinitesSource: any = {};
  priorityOppourtinitesTotalRecords: number;
  priorityOppourtinitesList = [];
  selectedTab: string;
  leadAwaitingActiveIndex = 0;
  localOpportunitiesActiveIndex = 0;
  opportunitiesActionPie = [] as any;
  opportunitiesActionApiData: OpportunitiesRequiringActionResponse[] = [];
  priorityOpportunitiesActionApiData:OpportunitiesRequiringActionResponse[] = [];
  priorityOpportunitiesActionPie= [] as any;
  opportunitiesActionData = {
    labels: [],
    datasets: [
        {
            data: [],
            borderColor: '#ffffff',
            fill: true,
            pointHoverRadius: 20,
            backgroundColor: [
                "#a43794",
                "#17adc2",
                "#3c7bb4",
                "#f4ae32",
                "#787878"
            ],
            // hoverBackgroundColor: [
            //   "#8389ae",
            //   "#FDFDFD"
            // ],
            // radius: 130,
            pointHoverBorderWidth:10,
            pointHoverBorderColor:[
              "#000",
              "#000",
              "#000",
              "#000",
              "#000"
            ],
            hoverOffset: 10
        }
    ]
  };

  priorityOportunitiesActionData = {
    labels: ['Priority No Outreach', 'Priority Follow Up', 'Priority Contracts Drafted but Not Sent', 'Priority Contracts with Requested Changes', 'Priority Contracts Pending Signature'],
    datasets: [
        {
            data: [400, 939, 3246, 9865, 798],
            borderColor: '#ffffff',
            fill: true,
            // radius: 120,
            backgroundColor: [
                "#a43794",
                "#17adc2",
                "#3c7bb4",
                "#f4ae32",
                "#787878"
            ],
            hoverOffset: 10
        }
    ]
  };

  @ViewChild('chkAll1') chkAll1: TableHeaderCheckbox;
  @ViewChild('chkAll2') chkAll2: TableHeaderCheckbox;
  @ViewChild('chkAll3') chkAll3: TableHeaderCheckbox;
  @ViewChild('searchClinics') clinicsEleRef: ElementRef;
  @ViewChild('searchOpportunities') opportunitiesEleRef: ElementRef;
  @ViewChild('searchLeads') searchLeadsRef: ElementRef;
  @ViewChild("leadAwaitingRef") leadAwaitingRef: ElementRef;
  @ViewChild("priorityOpportunitiesRef") priorityOpportunitiesRef: ElementRef;

  constructor(private _opportunityService: OpportunitiesService,
    private _dashboardservice: DashboardServiceService,
    private _headerservice: HeaderserviceService,
    private coClinicdetailsService: ClinicdetailsService,
    private message_service: MessageServiceService,
    private spinner: NgxSpinnerService,
    private router: Router, titleService: Title,
    private confirmationService: ConfirmationService,
    private ref: ChangeDetectorRef,
    private dialogService: DialogService,
    private leadAwaitingResponseService: LeadAwaitingResponseService,
    private potentialClientInterestService: PotentialClientInterestService,
    private chartService: ChartService
  ) {
    this.subscription = this.message_service.getDashboard().subscribe((message:any) => {
      this.message = message;
      this.store_id = this.message.text;
      if (this.store_id != "") {
        this.searchText = ""
        if (this.title != "pcadashboard") {
          this.getActionItemsCounts();
        }
        else {
          this.getPCADashboardSnapshot();
        }
        this.message.status = false;
      }
    });
    this.title = this.getTitle(router.routerState, router.routerState.root).join('-');
    titleService.setTitle(this.title);
    if (this.title == "pcadashboard") {
      this.PCATab = true;
      this.pageName = "pcadashboard";
    }
  }

  ngOnInit() {  
    Chart.register(ChartDataLabels);
    this.NCIOptions = noClientInterestOptions;
    this.userProfile = SessionDetails.GetUserProfile();
    this.area_user = false;
    this.dist_user = false;
    this.admin_user = false;
    this.user_profile = SessionDetails.GetUserProfile();
    this.columns = [
      {field: "BusinessName" , header: "Business/Organization"},
      {field: 'Address', header: 'Address'} ,
      {field: 'Area', header: "Area"} ,
      {field: 'ClosestStore', header: "Closest Store" },
      {field: 'EstimatedEmployee', header: "Estimated Employees"},
      {field: 'EstimatedResident', header: "Estimated Residents"},
      {field: 'EstimatedResidentClinics', header:"Estimated Clinics"},
      {field: 'Submitted', header:"Submitted"},
      ''
   ];
    this.cancelClinicCols = [
      { field: 'businessName', header: 'Clinic Name' },
      { field: 'naClinicLocation', header: 'Client Location' },
      { field: 'naClinicAddress1', header: 'Clinic Address' },
      { field: 'naClinicDate', header: 'Clinic Date' },
      { field: 'storeId', header: 'Store #' },
      { field: 'totalAppointments', header: 'Scheduled Appts' },
    ];
    this.clinicCols = [
      { field: 'businessName', header: 'Clinic Name' },
      { field: 'clinicType', header: 'Type' },
      { field: 'naClinicAddress1', header: 'Clinic Location' },
      { field: 'naClinicDate', header: 'Clinic Date' },
      { field: 'storeId', header: 'Store #' },
      { field: 'storeAddress', header: 'Store Location' },
    ];
    this.localOppCols = [
      { field: 'businessName', header: 'Opportunity Name & Number' },
      { field: 'address', header: 'Opportunity Address' },
      { field: 'actualLocationEmploymentSize', header: 'Emp' },
      { field: 'districtNumber', header: 'District' },
      { field: 'storeId', header: 'Assigned Store' },
      { field: 'lastContactDate', header: 'Last Contact' },
      { field: 'outreachStatus', header: 'Status' }
    ];
    this.HCS_contacted = "HCS Contacted";
    this.HCS_contacted_value = 10;
    if (this.user_profile.userRole.toLowerCase() == "admin") {
      this.storeV1 = true;
      this.distV1 = true;
      this.ariaV1 = true;
      this.regionV1 = true;
      this.assignReq = true;
      this.storeTab = true;
      this.districtTab = true;
      this.HCSTab = this.title == "pcadashboard" ? false : true;
      this.admin_user = true;
    }

    if (this.user_profile.userRole.toLowerCase() == "district manager") {
      this.HCS_contacted = "DM Contacted";
      this.HCS_contacted_value = "9";
      this.storeV1 = true;
      this.distV1 = false;
      this.ariaV1 = false;
      this.regionV1 = false;
      this.storeTab = true;
      this.districtTab = true;
      this.HCSTab = false;
      this.dist_user = true;
    }
    if (this.user_profile.userRole.toLowerCase() == "director - rx and retail ops"
      || this.user_profile.userRole.toLowerCase() == "healthcare supervisor" || this.user_profile.userRole.toLowerCase() == "pod healthcare supervisor" ) {
      this.storeV1 = true;
      this.distV1 = true;
      this.ariaV1 = false;
      this.regionV1 = false;
      this.storeTab = true;
      this.districtTab = true;
      this.HCSTab = this.title == "pcadashboard" ? false : true;
      this.area_user = true;
    }
    if (this.user_profile.userRole.toLowerCase() =="district manager"
      ) {
      this.storeV1 = false;
      this.distV1 = true;
      this.ariaV1 = false;
      this.regionV1 = false;
      this.storeTab = true;
      this.HCSTab = false;
      this.districtTab = true;
      this.admin_user= false;
      this.region_user= false;
      this.dist_user = true;      
      this.area_user = false;
    }
    if (this.user_profile.userRole.toLowerCase() == "regional vice president"
      || this.user_profile.userRole.toLowerCase() == "regional healthcare director"
      ) {
      this.storeV1 = true;
      this.distV1 = true;
      this.ariaV1 = true;
      this.regionV1 = false;
      this.storeTab = true;
      this.districtTab = true;
      this.HCSTab = this.title == "pcadashboard" ? false : true;
      this.region_user = true;
    }
    this.getLeadAwaitingResponseList();
    this.spinner.show();
    this.handleChartData();
    if (SessionDetails.GetStoreId() == null) {
      this._headerservice.getStoresProfile(this.userProfile.userPk, null, null).subscribe((res:any) => {
        SessionDetails.setStoreProfileData(res.data);
        SessionDetails.StoreId(res.data[0].storeId);
        SessionDetails.StorePk(res.data[0].storePk);
        SessionDetails.setState(res.data[0].state);
        this.selectOption = "";
        this.selectOptionLocal = "";
        if (this.title != "pcadashboard") {
          this.getActionItemsCounts();
          this.getMetricsCount();
          let tabIndex = sessionStorage["tabIndex"];
          sessionStorage.removeItem("tabIndex");
      
          if(tabIndex != undefined && tabIndex != null){
          this.page_index = Number(tabIndex);
          }
          else {
          this.page_index = this.HCSTab == false ? 1:0;
          }
        this.getAssignedToHCS(this.HCSTab == false ? 1 : tabIndex  && Number(tabIndex)>0?Number(tabIndex): 0, 0, 0);
        this.getPriorityOpportunities( 0, 0, 0);
        }
        else {
          this.getPCADashboardSnapshot();
        }
      });
    }
    else {
      this.selectOption = "";
      this.selectOptionLocal = "";
      if (this.title != "pcadashboard") {
        this.getActionItemsCounts();
        this.getMetricsCount();
      let tabIndex = sessionStorage["tabIndex"];
      sessionStorage.removeItem("tabIndex");      
      if(tabIndex != undefined && tabIndex != null){
        this.page_index = Number(tabIndex);
      }
      else {
        this.page_index = this.HCSTab == false ? 1:0;
      }
      this.getAssignedToHCS(this.HCSTab == false ? 1 : tabIndex  && Number(tabIndex)>0?Number(tabIndex): 0, 0, 0);
      this.getPriorityOpportunities( 0, 0, 0);
      }
      else {
        this.getPCADashboardSnapshot();
      }
    }
    this.cancelClinicSourceList = {
      "userPk": 41,
      "assignedTo": 0,
      "pageIndex": 0,
      "filterBy": "",
      "filterById": "",
      "searchByValue": "",
      "sortByField": "naClinicDate",
      "totalCount": 21,
      "startIndex": 0,
      "finalIndex": 20,
      "sortOrder": true,
      "clinicList": [{ "storeId": 211, "clientName": "Test 211 - Clinic 1", "clientLocation": "Test room 211", "clientAddress": "757 N MICHIGAN AVE", "clientDate": "2019-05-23T00:00:00", "scheduledAppts": "2019-06-15T00:00:00" },
      { "storeId": 1993, "clientName": "Testt 1993 - Clinic 1", "clientLocation": "Test room 1993", "clientAddress": "12312 E SPRAGUE AVE", "clientDate": "2019-06-15T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 2277, "clientName": "Test DC state - Clinic 1", "clientLocation": "Clovis Civic Center", "clientAddress": "1217 22ND ST NW", "clientDate": "2019-06-15T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 2434, "clientName": "Test 28381 - Clinic 1", "clientLocation": "Test room 1", "clientAddress": "1830 W 11TH ST", "clientDate": "2019-07-01T00:00:00", "scheduledAppts": "2019-07-24T00:00:00" },
      { "storeId": 2434, "clientName": "Test 2434 test - Clinic 1", "clientLocation": "Test room 1", "clientAddress": "1830 W 11TH ST", "clientDate": "2019-07-01T00:00:00", "scheduledAppts": "2019-07-25T00:00:00" },
      { "storeId": 2865, "clientName": "Qualcomm - Clinic 48", "clientLocation": "San Diego - AQ", "clientAddress": "10145 Pacific Heights Blvd", "clientDate": "2019-06-05T00:00:00", "scheduledAppts": "2019-07-24T00:00:00" },
      { "storeId": 2865, "clientName": "Test conflict HCS - Clinic 1", "clientLocation": "Test location 5", "clientAddress": "1360 E PACHECO BLVD", "clientDate": "2019-06-05T00:00:00", "scheduledAppts": "2019-07-25T00:00:00" },
      { "storeId": 4402, "clientName": "Test 4402 test - Clinic 3", "clientLocation": "Test room 3", "clientAddress": "1300 E HALLANDALE BEACH BLVD", "clientDate": "2019-09-03T00:00:00", "scheduledAppts": "2019-05-15T00:00:00" },
      { "storeId": 4402, "clientName": "Test 4402 test - Clinic 2", "clientLocation": "Test room 2", "clientAddress": "1300 E HALLANDALE BEACH BLVD", "clientDate": "2019-09-02T00:00:00", "scheduledAppts": "2019-06-25T00:00:00" },
      { "storeId": 4507, "clientName": "Testt 4507 - Clinic 1", "clientLocation": "Test room 4507", "clientAddress": "15025 N THOMPSON PEAK PKWY", "clientDate": "2019-07-01T00:00:00", "scheduledAppts": "2019-06-17T00:00:00" },
      { "storeId": 4659, "clientName": "Test 4569 - Clinic 1", "clientLocation": "Test room 4569", "clientAddress": "164 W JACKSON ST", "clientDate": "2019-05-30T00:00:00", "scheduledAppts": "2019-08-28T00:00:00" },
      { "storeId": 5550, "clientName": "Centene - Clinic 4", "clientLocation": "Superior Healthplan", "clientAddress": "7990 IH 10 West", "clientDate": "2019-08-11T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 6101, "clientName": "Test 6101 - Clinic 2", "clientLocation": "Test room 6101", "clientAddress": "3655 ALHAMBRA AVE", "clientDate": "2019-06-05T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 6162, "clientName": "Test 6162 - Clinic 3", "clientLocation": "Test room 6163", "clientAddress": "508 MAIN ST", "clientDate": "2019-05-09T00:00:00", "scheduledAppts": "2019-06-17T00:00:00" },
      { "storeId": 6345, "clientName": "Test 6345 test - Clinic 1", "clientLocation": "Test room 3", "clientAddress": "2400 E MIDWAY BLVD", "clientDate": "2019-09-01T00:00:00", "scheduledAppts": "2019-06-17T00:00:00" },
      { "storeId": 6345, "clientName": "Test 4402 test - Clinic 1", "clientLocation": "Test room 1", "clientAddress": "1300 E HALLANDALE BEACH BLVD", "clientDate": "2019-09-01T00:00:00", "scheduledAppts": "2019-06-17T00:00:00" },
      { "storeId": 6345, "clientName": "Test 6345 test - Clinic 3", "clientLocation": "Test room 3", "clientAddress": "2400 E MIDWAY BLVD", "clientDate": "2019-09-03T00:00:00", "scheduledAppts": "2019-06-17T00:00:00" },
      { "storeId": 6345, "clientName": "Test 6345 test - Clinic 2", "clientLocation": "Test room 3", "clientAddress": "2400 E MIDWAY BLVD", "clientDate": "2019-09-02T00:00:00", "scheduledAppts": "2019-06-24T00:00:00" },
      { "storeId": 6862, "clientName": "Test 6862 - Clinic 1", "clientLocation": "Test room 1", "clientAddress": "637 HOBOKEN RD", "clientDate": "2019-05-22T00:00:00", "scheduledAppts": "2019-06-17T00:00:00" },
      { "storeId": 6862, "clientName": "Test Overlapping CA - Clinic 9", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-10-21T00:00:00" },
      { "storeId": 7672, "clientName": "Test 7672 - Clinic 5", "clientLocation": "Test room 7676", "clientAddress": "1108 HARTMAN LN", "clientDate": "2019-08-10T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 7672, "clientName": "Test 7672 - Clinic 4", "clientLocation": "Test room 7675", "clientAddress": "1108 HARTMAN LN", "clientDate": "2019-08-07T00:00:00", "scheduledAppts": "2019-07-24T00:00:00" },
      { "storeId": 7673, "clientName": "Test 7673 - Clinic 4", "clientLocation": "Test room 8", "clientAddress": "10600 W PARMER LN", "clientDate": "2019-08-06T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 7673, "clientName": "Test 7673 - Clinic 3", "clientLocation": "Test room 7674", "clientAddress": "10600 W PARMER LN", "clientDate": "2019-07-11T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 10071, "clientName": "Upload DC Clinic - Clinic 1", "clientLocation": "Clovis Civic Center", "clientAddress": "225 22ND ST NW", "clientDate": "2019-09-15T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 6", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-15T00:00:00", "scheduledAppts": "2019-05-23T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 7", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-06-15T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 8", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-06-15T00:00:00" },
      { "storeId": 13000, "clientName": "Test Corporate - Clinic 1", "clientLocation": "Clovis Civic Center", "clientAddress": "801 East Ave", "clientDate": "2019-07-24T00:00:00", "scheduledAppts": "2019-07-01T00:00:00" },
      { "storeId": 13000, "clientName": "Test Corporate - Clinic 2", "clientLocation": "Clovis Civic Center", "clientAddress": "802 East Ave", "clientDate": "2019-07-25T00:00:00", "scheduledAppts": "2019-07-01T00:00:00" },
      { "storeId": 13000, "clientName": "Test Corporate - Clinic 3", "clientLocation": "Clovis Civic Center", "clientAddress": "80 East Ave", "clientDate": "2019-07-24T00:00:00", "scheduledAppts": "2019-06-05T00:00:00" },
      { "storeId": 13000, "clientName": "Test Corporate - Clinic 4", "clientLocation": "Clovis Civic Center", "clientAddress": "81 East Ave", "clientDate": "2019-07-25T00:00:00", "scheduledAppts": "2019-06-05T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 2", "clientLocation": "Roswell Convention & Civic Center", "clientAddress": "61 East Ave", "clientDate": "2019-05-15T00:00:00", "scheduledAppts": "2019-09-03T00:00:00" },
      { "storeId": 13000, "clientName": "Test Uploader copay - Clinic 2", "clientLocation": "Clovis Civic Center", "clientAddress": "801 East Ave", "clientDate": "2019-06-25T00:00:00", "scheduledAppts": "2019-09-02T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 15", "clientLocation": "Clovis Civic Center test", "clientAddress": "60 East Ave test", "clientDate": "2019-06-17T00:00:00", "scheduledAppts": "2019-07-01T00:00:00" },
      { "storeId": 13000, "clientName": "Test Uploader1 - Clinic 2", "clientLocation": "Clovis Civic Center 2", "clientAddress": "8601 East Ave1", "clientDate": "2019-08-28T00:00:00", "scheduledAppts": "2019-05-30T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 17", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-08-11T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 18", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-06-05T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 19", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-17T00:00:00", "scheduledAppts": "2019-05-09T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 20", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-17T00:00:00", "scheduledAppts": "2019-09-01T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 21", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-17T00:00:00", "scheduledAppts": "2019-09-01T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 22", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-17T00:00:00", "scheduledAppts": "2019-09-03T00:00:00" },
      { "storeId": 13000, "clientName": "Test Uploader copay - Clinic 1", "clientLocation": "Clovis Civic Center", "clientAddress": "800 East Ave", "clientDate": "2019-06-24T00:00:00", "scheduledAppts": "2019-09-02T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 23", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-17T00:00:00", "scheduledAppts": "2019-05-22T00:00:00" },
      { "storeId": 13000, "clientName": "Test Stage - Clinic 1", "clientLocation": "Clovis Civic Center", "clientAddress": "860 East Ave", "clientDate": "2019-10-21T00:00:00", "scheduledAppts": "2019-06-16T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 12", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-08-10T00:00:00" },
      { "storeId": 13000, "clientName": "Test Corporate - Clinic 5", "clientLocation": "Clovis Civic Center", "clientAddress": "810 East Ave", "clientDate": "2019-07-24T00:00:00", "scheduledAppts": "2019-08-07T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 11", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-08-06T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 10", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-07-11T00:00:00" },
      { "storeId": 13000, "clientName": "Test Overlapping CA - Clinic 14", "clientLocation": "Clovis Civic Center", "clientAddress": "60 East Ave", "clientDate": "2019-06-16T00:00:00", "scheduledAppts": "2019-09-15T00:00:00" }]
    }
  }
  openPowerBILink() {
    window.open("https://app.powerbi.com/groups/3681e600-a8f1-4c15-be33-bdcae71ccb43/reports/c31e78b8-5dfa-4824-a6a4-bca30590e79c/ReportSection1388318b01032aa5298c?ctid=92cb778e-8ba7-4f34-a011-4ba6e7366996", "_blank");
  }    
  resizeWidth(header_value) {
    if (header_value == "Clinic Location")
      return 'w280';
    if (header_value == "Store Location")
      return 'w221 noUnderline';
    if (header_value == "Clinic Name")
      return 'w200';
    if (header_value == "Store #")
      return 'w100p';
    if (header_value == "Clinic Date")
      return 'w120p';
    if (header_value == "Type")
      return 'w120p';
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getLeadAwaitingResponseList(){
    this.spinner.show();
    this.leadAwaitingResponseService.getLeadAwaitingResponseList({pageIndex: this.pageIndex, pageSize: this.pageSize,  requestType: this.requestType, userId: this.userProfile.userPk}).subscribe((data: LeadsAwaitingResponseList) => {
      this.leadAwaitingResponseList = data.Table;
      this.tableOptions = {
        columns: this.columns,
        data: this.leadAwaitingResponseList,
        hasPagination: true,
        isSearchable: true,
        rowsPerPage: 10,
        rowsPerPageOptions: [5,10,20],
        isSortable: true,
        actionMenuCallback: this.leadIntakeClick.bind(this),
        showBarsIcon: true,
        iconPosition: this.columns.length -1,
        tableClass: "lead-intake-card",
        sortField: "Submitted",
        sortOrder: 0
      };
      this.spinner.hide();
      this.ref.detectChanges();
    }), (err) => {
      this.spinner.hide();
    }
  }

  getLocalLeads(tab_index, start_number, page_index) {
    this.handleLocalOpportunitiesFilter();
    this.selectAllDist = false;
    this.selectedLocalList = [];
    this.dispDlgReassign = false;
    this.dispDlgReassignToHCS = false;
    this.displayStoredlg = false;
    this.outreachstatus = "";
    this.disposition = "";
    var localLeads = new LocalLeads();
    localLeads.opportunitiesList = [];
    localLeads.userPk = this.user_profile.userPk;
    localLeads.assignedTo = tab_index;
    this.localLeadsPageIndex = localLeads.pageIndex = page_index;
    localLeads.filterBy = this.search_local_value;
    localLeads.filterById = this.search_from_list_local;
    localLeads.searchByValue = this.searchValue;
    localLeads.sortByField = this.localLeadsSortBy;
    localLeads.sortOrder = this.localLeadsSortOrder;
    localLeads.totalCount = 0;
    localLeads.startIndex = 0;
    localLeads.finalIndex = 0;
    if (tab_index == 1) {
      localLeads.noOfRecordsInPage = this.hcsLocalNoOfPageRecords;
    } else {
      localLeads.noOfRecordsInPage = 50
    }
    if(tab_index == 0) {
      this.getPriorityOpportunities(tab_index, start_number, page_index);
      return;
    }
    this.page_index = tab_index;
    localLeads.assignedTo = localLeads.assignedTo - 1;
    this.spinner.show();
    this._opportunityService.getLocalLeads(localLeads).subscribe((localLeadsData) => {
      this.hcsLocalLeadsSource = localLeadsData;
      this.hcsLocalTotalRecords = localLeadsData.totalCount;
      if (tab_index == 1) {

        this.bulkAction_local_hcs = false;
        this.hcs_local_details = false;
        this.hcs_local_details_search = false;
        //this.HCSLocalLeads = localLeadsData.opportunitiesList;
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(start_number, (start_number + 10));
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(start_number, (start_number + this.hcsLocalNoOfPageRecords));
        this.HCSLocalLeads = localLeadsData.opportunitiesList;
        if (this.HCSLocalLeads.length > 0) {
          //this.dataTable.first = 0;
          //this.dataTable.paginate;
          this.bulkAction_local_hcs = true;
          this.hcs_local_details = true;
          this.hcs_local_details_search = true;
        }
      }
      else if (tab_index == 2) {
        this.bulkAction_local_dm = false;
        this.hcs_local_DM = false;
        this.hcs_local_DM_search = false;
        //this.HCSLocalLeadsDM = localLeadsData.opportunitiesList;
        this.HCSLocalLeadsDM = localLeadsData.opportunitiesList.slice(start_number, (start_number + 10));
        if (this.HCSLocalLeadsDM.length > 0) {
          // this.dataTable_DM.first = 0;
          //this.dataTable_DM.paginate;
          this.bulkAction_local_dm = true;
          this.hcs_local_DM = true;
          this.hcs_local_DM_search = true;
        }
      }
      else if (tab_index == 3) {
        this.bulkAction_local_store = false;
        this.hcs_local_store = false;
        this.hcs_local_store_search = false;
        //this.HCSAssignedLocalLeads = localLeadsData.opportunitiesList;        
        this.HCSAssignedLocalLeads = localLeadsData.opportunitiesList.slice(start_number, (start_number + 10));
        if (this.HCSAssignedLocalLeads.length > 0) {
          //this.dataTable_store.first = 0;
          // this.dataTable_store.paginate;
          this.bulkAction_local_store = true;
          this.hcs_local_store = true;
          this.hcs_local_store_search = true;
        }

      }
      this.spinner.hide();
    });
  }
  getAssignedToHCS(tab_index, start_number, page_index) {
    this.dispDlgReassign = false;
    this.dispDlgReassignToHCS = false;
    //this.bulkAction_hcs = false;
    this.selectedDataList = [];

    var dashboard = new DashBoard();
    dashboard.clinicList = [];
    dashboard.userPk = this.user_profile.userPk;
    dashboard.assignedTo = tab_index;
    this.localLeadsPageIndex = this.scheduledClinicsPageIndex = dashboard.pageIndex = page_index;
    dashboard.filterBy = this.search_HCS_value;
    dashboard.filterById = this.search_from_list;
    dashboard.searchByValue = this.searchValue;
    dashboard.sortByField = this.scheduledClinicsSortBy;
    dashboard.totalCount = 0;
    dashboard.startIndex = 0;
    dashboard.finalIndex = 0;
    dashboard.sortOrder = this.scheduledClinicsSortOrder;
    if (this.page_index == 0) {
      dashboard.noOfRecordsInPage = this.hcsClinicNoOfPageRecords;
    } else {
      dashboard.noOfRecordsInPage = 50;
    }
    this.page_index = tab_index;    
    this.spinner.show();
    this._opportunityService.getHCSClinicDetails(dashboard).subscribe((hcsclinicDetail) => {
      this.hcsClinicTotalRecords = hcsclinicDetail.totalCount;
      this.hcsClinicsSource = hcsclinicDetail;
      this.page_index = tab_index;  
      if (this.page_index == 0) {

        this.bulkAction_hcs = false;
        this.hcs_clinic_details = false;
        this.hcs_clinic_details_search = false;
        // this.HCSClinicDetailsFilter = hcsclinicDetail.clinicList;
        //this.HCSClinicDetails = hcsclinicDetail.clinicList.slice(start_number, (start_number + this.hcsClinicNoOfPageRecords));
        this.HCSClinicDetails = hcsclinicDetail.clinicList;
        if (this.HCSClinicDetails.length > 0) {
          this.hcaTableOptions = {
            columns: this.clinicCols,
            data: this.HCSClinicDetails,
            hasPagination: true,
            isSearchable: true,
            rowsPerPage: 10,
            rowsPerPageOptions: [5,10,20],
            isSortable: true,
            actionMenuCallback: this.showDialogClinic.bind(this),
            showBarsIcon: true,
            iconPosition: this.clinicCols.length -1,
            tableClass: "lead-intake-card"
          };
          this.bulkAction_hcs = true;
          this.hcs_clinic_details = true;
          this.hcs_clinic_details_search = true;
          this.ref.detectChanges();
        }
        this.spinner.hide();      
      }
      else if (this.page_index == 1) {
        this.bulkAction_dm = false;
        this.hcs_clinic_DM = false;
        this.hcs_clinic_DM_search = false;
        // this.HCSClinicDMFilter = hcsclinicDetail.clinicList;
        this.HCSClinicDM = hcsclinicDetail.clinicList.slice(start_number, (start_number + 10));
        if (this.HCSClinicDM.length > 0) {
          this.bulkAction_dm = true;
          this.hcs_clinic_DM = true;
          this.hcs_clinic_DM_search = true;
        }
        this.spinner.hide();
      }
      else if (this.page_index == 2) {
        this.bulkAction_store = false;
        this.hcs_clinic_store = false;
        this.hcs_clinic_store_search = false;
        //this.HCSClinicStoreFilter = hcsclinicDetail.clinicList;
        this.HCSClinicStore = hcsclinicDetail.clinicList.slice(start_number, (start_number + 10));
        if (this.HCSClinicStore.length > 0) {
          this.bulkAction_store = true;
          this.hcs_clinic_store = true;
          this.hcs_clinic_store_search = true;
        }
        this.spinner.hide();
      }
      else if (this.page_index == 3) {
        this.bulkAction_store = false;
        this.hcs_clinic_store = false;
        this.hcs_clinic_store_search = false;
        //this.HCSClinicStoreFilter = hcsclinicDetail.clinicList;
        this.HCSClinicCancelled = hcsclinicDetail.clinicList.slice(start_number, (start_number + 10));
        if (this.HCSClinicStore.length > 0) {
          this.bulkAction_store = true;
          this.hcs_clinic_store = true;
          this.hcs_clinic_store_search = true;
        }
        this.spinner.hide();
      }
    },
      error => {
        console.error("Error in fetching data", error);
        this.spinner.hide();
      });
    //  }
  }

  loadDataLazy(event: LazyLoadEvent, grid_source, tab_index) {
    
    let curr_page_rec_count, page_to_get, start_number;
    if (grid_source == "ClinicDetails" && tab_index == 0) {
      if (event.rows != this.hcsClinicNoOfPageRecords) {
        this.hcsClinicNoOfPageRecords = event.rows;
        event.first = 0;
        this.dataTableClinic.first = 0;
        this.dataTableClinic.changePageToFirst;
      }
      start_number = event.first;
    } else if (grid_source == "LocalLeads" && tab_index == 0) {
      if (event.rows != this.hcsLocalNoOfPageRecords) {
        this.hcsLocalNoOfPageRecords = event.rows;
        event.first = 0;
        this.dataTable.first = 0;
        this.dataTable.changePageToFirst;
      }
      start_number = event.first;
    } else {
      if(grid_source == "LocalLeads"){
          this.chkAll1.checked = false;
          this.chkAll2.checked = false;
          this.chkAll3.checked = false;
      }
      curr_page_rec_count = event.first + Number(event.rows);
      page_to_get = curr_page_rec_count / 50;
      page_to_get = Math.ceil(page_to_get);
      start_number = ((event.first > 40) ? (event.first - (50 * (page_to_get - 1))) : event.first);
    }

    var source_obj: any = {};

    if (grid_source == "ClinicDetails" || grid_source == "ClinicDM" || grid_source == "ClinicStore") {
      source_obj = this.hcsClinicsSource;
    } else if (grid_source == "LocalLeads" || grid_source == "LocalLeadsDM" || grid_source == "AssignedLocalLeads") {
      source_obj = this.hcsLocalLeadsSource;
    }
    else if (grid_source == "CancelClinic") {
      source_obj = this.cancelClinicSourceList;
    } else if (grid_source == "PriorityOpportunities") {
      source_obj = this.priorityOppourtinitesSource;
    }
    if (tab_index == 0 && grid_source == "ClinicDetails") {
      let page_idx = (event.first / event.rows);
      this.getDataFromServer(grid_source, tab_index, start_number, page_idx);
    }
    else if (tab_index == 1 && grid_source == "LocalLeads") {
      let page_idx = (event.first / event.rows);
      this.getDataFromServer(grid_source, tab_index, start_number, page_idx);
    } else {
      if (source_obj.pageIndex != (page_to_get - 1)) {
        if (source_obj.pageIndex != undefined) {
          let page_idx = page_to_get - 1;
          this.getDataFromServer(grid_source, tab_index, start_number, page_idx);
        }
      } else {
        switch (grid_source) {
          case "ClinicDetails":
            this.HCSClinicDetails = this.hcsClinicsSource.clinicList.slice(start_number, (start_number + Number(event.rows)));
            break;
          case "ClinicDM":
            this.HCSClinicDM = this.hcsClinicsSource.clinicList.slice(start_number, (start_number + Number(event.rows)));
            break;
          case "ClinicStore":
            this.HCSClinicStore = this.hcsClinicsSource.clinicList.slice(start_number, (start_number + Number(event.rows)));
            break;
          case "LocalLeads":
            this.HCSLocalLeads = this.hcsLocalLeadsSource.opportunitiesList.slice(start_number, (start_number + Number(event.rows)));
            break;
          case "LocalLeadsDM":
            this.HCSLocalLeadsDM = this.hcsLocalLeadsSource.opportunitiesList.slice(start_number, (start_number + Number(event.rows)));
            break;
          case "AssignedLocalLeads":
            this.HCSAssignedLocalLeads = this.hcsLocalLeadsSource.opportunitiesList.slice(start_number, (start_number + Number(event.rows)));
            break;
          case "CancelClinic":
            this.cancelClinicList = this.cancelClinicSourceList.clinicList.slice(start_number, (start_number + Number(event.rows)));
            break;
          case "PriorityOpportunities":
            this.priorityOppourtinitesList = this.priorityOppourtinitesSource.opportunitiesList.slice(start_number, (start_number + Number(event.rows)))
          default:
            break;
        }
      }
    }

  }

  getDataFromServer(grid_source: string, tab_index: number, start_number: number, page_index) {
    switch (grid_source) {
      case "ClinicDetails":
      case "ClinicDM":
      case "ClinicStore":
      case "CancelClinic":
        this.getAssignedToHCS(tab_index, start_number, page_index/*(page_to_get - 1)*/);
        break;
      case "LocalLeads":
      case "LocalLeadsDM":
      case "AssignedLocalLeads":
        this.getLocalLeads(tab_index, start_number, page_index/*(page_to_get - 1)*/);
        //this.getAssignedToHCS(tab_index, start_number, (page_to_get - 1));
        break;
      case "PriorityOpportunities":
        this.getPriorityOpportunities(tab_index, start_number, page_index);
        break;
      default:
        break;
    }

  }

  getActionItemsCounts() {
    this.spinner.show();
    let startTime: Date = new Date();
    this._opportunityService.getActionItemsCounts(this.user_profile.userPk, SessionDetails.GetStoreId()).subscribe((hcsdata:any) => {
      let endTime: Date = new Date();
      this.HCSAction = hcsdata;
      SessionDetails.setHCSActionItemsList(this.HCSAction);
      this.spinner.hide();
    },
      error => {
        console.error("Error in fetching data", error);
      });

  }
  getMetricsCount() {
    this.spinner.show();
    let startTime: Date = new Date();
    //console.log(String.Format("Started calling get metrics counts at {0}:{1}:{2}:{3}", startTime.getHours(), startTime.getMinutes(), startTime.getSeconds(), startTime.getMilliseconds()));

    this._opportunityService.getMetricsCounts(this.user_profile.userPk).subscribe((metrics:any) => {
      let endTime: Date = new Date();
      //console.log(String.Format("Ending get get metrics counts at {0}:{1}:{2}:{3}", endTime.getHours(), endTime.getMinutes(), endTime.getSeconds(), endTime.getMilliseconds()));

      this.HCSMetrics = metrics.dataList;
      SessionDetails.setHCSMetricsList(this.HCSMetrics);

      this.spinner.hide();
    },
      error => {
        console.error("Error in fetching data", error);
      });

  }
  getPCADashboardSnapshot() {
    this.spinner.show();
    this._opportunityService.getPCADashboardSnapshot(this.user_profile.userPk, SessionDetails.GetStoreId()).subscribe((hcsdata:any) => {
      this.PCAActionItems = hcsdata.actionItemCountsList;
      this.PCAMetrics = hcsdata.metricCounts.dataList;
      SessionDetails.setPCAActionItemsList(this.PCAActionItems);
      SessionDetails.setPCAMetricsList(this.PCAMetrics);
      this.spinner.hide();
    });    
  }

  formatter = (result: any) => result.storeId + " - " +
    result.address + ", " + result.city + ","
    + result.state + " " + result.zip || '';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this._headerservice.storeSearch(this.user_profile.userPk, term.trim(), 0).pipe(
          tap(() => {
          }),
          catchError(() => {
            return observableOf([]);
          }),)),
      tap(() => this.searching = false),);

  globalSearch() {

  }
  rowSelect() {

  }
  // onClinicSelect(assigned_to, value, selected_value) {
  //   this.HCSClinicDetails = this.HCSClinicDetailsFilter.filter(item => item.clinicType == selected_value);
  // }
  onSelect(assigned_to, value, selected_value) {
    this.selectedDataList = [];
    this.spinner.show();
    var dashboard = new DashBoard();
    dashboard.clinicList = [];
    dashboard.userPk = this.user_profile.userPk;
    dashboard.assignedTo = assigned_to;
    dashboard.pageIndex = 0;
    dashboard.filterBy = this.search_HCS_value = value;
    dashboard.filterById = this.search_from_list = value.toLowerCase() == "store" ? selected_value.item.storeId : selected_value;
    dashboard.searchByValue = this.searchValue;
    this.search_from_list = this.search_from_list == "" ? this.searchValue : this.search_from_list;
    dashboard.sortByField = this.scheduledClinicsSortBy;
    dashboard.totalCount = 0;
    dashboard.startIndex = 0;
    dashboard.finalIndex = 0;
    dashboard.sortOrder = true;
    if (assigned_to == 0) {
      dashboard.noOfRecordsInPage = this.hcsClinicNoOfPageRecords;
    } else {
      dashboard.noOfRecordsInPage = 50;
    }
    this._opportunityService.getHCSClinicDetails(dashboard).subscribe((hcsclinicDetail) => {
      this.hcsClinicTotalRecords = hcsclinicDetail.totalCount;
      this.hcsClinicsSource = hcsclinicDetail;
      if (assigned_to == 0) {
        this.hcs_clinic_details = false;
        this.hcs_clinic_details_search = true;
        this.bulkAction_hcs = false;
        //this.HCSClinicDetails = hcsclinicDetail.clinicList.slice(0, (0 + 10));
        //this.HCSClinicDetails = hcsclinicDetail.clinicList.slice(0, (0 + this.hcsClinicNoOfPageRecords));
        this.HCSClinicDetails = hcsclinicDetail.clinicList;
        if (this.HCSClinicDetails.length > 0) {
          if (this.dataTableClinic != undefined) {
            this.dataTableClinic.first = 0;
            this.dataTableClinic.changePage;
          }
          this.bulkAction_hcs = true;
          this.hcs_clinic_details = true;
        }
      }
      else if (assigned_to == 1) {
        this.hcs_clinic_DM = false;
        this.hcs_clinic_DM_search = true;
        this.bulkAction_dm = false;
        this.HCSClinicDM = hcsclinicDetail.clinicList.slice(0, (0 + 10));
        if (this.HCSClinicDM.length > 0) {
          if (this.dataTableClinic_DM != undefined) {
            this.dataTableClinic_DM.first = 0;
            this.dataTableClinic_DM.changePage;
          }
          this.bulkAction_dm = true;
          this.hcs_clinic_DM = true;
        }
      }
      else if (assigned_to == 2) {
        this.hcs_clinic_store = false;
        this.hcs_clinic_store_search = true;
        this.bulkAction_store = false;
        this.HCSClinicStore = hcsclinicDetail.clinicList.slice(0, (0 + 10));
        if (this.HCSClinicStore.length > 0) {
          if (this.dataTableClinic_store != undefined) {
            this.dataTableClinic_store.first = 0;
            this.dataTableClinic_store.changePage;
          }
          this.bulkAction_store = true;
          this.hcs_clinic_store = true;
        }
      }

      this.spinner.hide();
    },
      error => {
        console.error("Error in fetching data", error);
      });
  }
  onChange(index, reassignVal) {
    this.search_from_list = "";
    this.search_HCS_value = "";
    this.ariaV = false;
    this.storeV = false;
    this.distV = false;
    this.regionV = false;
    if (reassignVal.toLowerCase() == "store") {
      this.storeV = true;
      this.distV = false;
      this.ariaV = false;
      this.regionV = false;
    }
    if (reassignVal.toLowerCase() == "district") {
      this.distV = true;
      this.storeV = false;
      this.ariaV = false;
      this.regionV = false;
    }
    if (reassignVal.toLowerCase() == "area") {
      this.ariaV = true;
      this.storeV = false;
      this.distV = false;
      this.regionV = false;
    }
    if (reassignVal.toLowerCase() == "region") {
      this.clinicType = false;
      this.ariaV = false;
      this.storeV = false;
      this.distV = false;
      this.regionV = true;
    }

    if (reassignVal.toLowerCase() == "clinic type") {
      this.clinicType = true;
      this.ariaV = false;
      this.storeV = false;
      this.distV = false;
      this.regionV = false;

    }
    this.area_dist = [];
    if (reassignVal != "") {

      this.coClinicdetailsService.getAreaDist(reassignVal,0).subscribe(data => {

        let dataList = data;//JSON.parse(data._body)
        for (var keyValue in dataList) {
          let valueData = dataList[keyValue];
          this.area_dist.push({ key: keyValue, value: valueData })
        }
      });
    }
    else {
      if (index == 0)
        this.getAssignedToHCS(0, 0, 0);
      else if (index == 1)
        this.getAssignedToHCS(1, 0, 0);
      else if (index == 2)
        this.getAssignedToHCS(2, 0, 0);
      else if (index == 3)
        this.getAssignedToHCS(3, 0, 0);
    }
  }

  onLocalChange(index, reassignVal) {
    this.search_local_value = "";
    this.search_from_list_local = "";
    this.aria_local = false;
    this.store_local = false;
    this.dist_local = false;
    this.region_local = false;
    if (reassignVal.toLowerCase() == "store") {
      this.store_local = true;
      this.dist_local = false;
      this.aria_local = false;
      this.region_local = false;
    }
    if (reassignVal.toLowerCase() == "district") {
      this.dist_local = true;
      this.store_local = false;
      this.aria_local = false;
      this.region_local = false;
    }
    if (reassignVal.toLowerCase() == "area") {
      this.aria_local = true;
      this.store_local = false;
      this.dist_local = false;
      this.region_local = false;
    }
    if (reassignVal.toLowerCase() == "region") {
      this.aria_local = false;
      this.store_local = false;
      this.dist_local = false;
      this.region_local = true;
    }
    this.area_dist = [];
    if (reassignVal != "") {
     
      this.coClinicdetailsService.getAreaDist(reassignVal,0).subscribe(data => {
        let dataList = data;//JSON.parse(data._body)
        for (var keyValue in dataList) {
          let valueData = dataList[keyValue];
          this.area_dist.push({ key: keyValue, value: valueData })
        }
      });
    }
    else {
      if (index == 0)
        this.getLocalLeads(1, 0, 0);
      else if (index == 1)
        this.getLocalLeads(2, 0, 0);
      else if (index == 2)
        this.getLocalLeads(3, 0, 0);
      else if (index == 3)
        this.getLocalLeads(0, 0, 0);
    }
  }
  clearFilter(index) {
    this.selectOptionLocal = ""; 
    this.search_local_value = "";
    this.search_from_list_local = "";
    this.aria_local = false;
    this.store_local = false;
    this.dist_local = false;
    this.region_local = false;
    if (index == 0)
    this.getLocalLeads(1, 0, 0);
    else if (index == 1)
    this.getLocalLeads(2, 0, 0);
    else if (index == 2)
    this.getLocalLeads(3, 0, 0);
    else if (index == 3)
    this.getLocalLeads(0, 0, 0);
 }

  storeSearchHCS() {
    this.showVarHCS = !this.showVarHCS;    
    setTimeout(() => {
      this.clinicsEleRef.nativeElement.focus();
    }, 100);
  }
  searchGlobal() {
    this.selectedDataList = [];
    this.search_value = this.searchValue;
    var dashboard = new DashBoard();
    dashboard.clinicList = [];
    dashboard.userPk = this.user_profile.userPk;
    dashboard.assignedTo = this.page_index;
    dashboard.pageIndex = 0;
    dashboard.filterBy = "";
    dashboard.filterById = "";
    dashboard.searchByValue = this.searchValue;
    dashboard.sortByField = this.scheduledClinicsSortBy;
    dashboard.totalCount = 0;
    dashboard.startIndex = 0;
    dashboard.finalIndex = 0;
    dashboard.sortOrder = true;
    this.spinner.show();
    if (this.page_index == 0) {
      dashboard.noOfRecordsInPage = this.hcsClinicNoOfPageRecords;
    } else {
      dashboard.noOfRecordsInPage = 50;
    }
    this._opportunityService.getHCSClinicDetails(dashboard).subscribe((hcsclinicDetail) => {
      this.hcsClinicTotalRecords = hcsclinicDetail.totalCount;
      this.hcsClinicsSource = hcsclinicDetail;
      if (this.page_index == 0) {
        this.bulkAction_hcs = false;
        this.hcs_clinic_details = false;
        this.hcs_clinic_details_search = false;
        //this.HCSClinicDetails = hcsclinicDetail.clinicList.slice(0, (0 + 10));
        //this.HCSClinicDetails = hcsclinicDetail.clinicList.slice(0, (0 + this.hcsClinicNoOfPageRecords));
        this.HCSClinicDetails = hcsclinicDetail.clinicList;
        if (this.HCSClinicDetails.length > 0) {
          if (this.dataTableClinic != undefined) {
            this.dataTableClinic.first = 0;
            this.dataTableClinic.changePage;
          }
          this.bulkAction_hcs = true;
          this.hcs_clinic_details = true;
          this.hcs_clinic_details_search = true;
        }
      }
      else if (this.page_index == 1) {
        this.bulkAction_dm = false;
        this.hcs_clinic_DM = false;
        this.hcs_clinic_DM_search = false;
        this.HCSClinicDM = hcsclinicDetail.clinicList.slice(0, (0 + 10));
        if (this.HCSClinicDM.length > 0) {
          if (this.dataTableClinic_DM != undefined) {
            this.dataTableClinic_DM.first = 0;
            this.dataTableClinic_DM.changePage;
          }
          this.bulkAction_dm = true;
          this.hcs_clinic_DM = true;
          this.hcs_clinic_DM_search = true;
        }
      }
      else if (this.page_index == 2) {
        this.bulkAction_store = false;
        this.hcs_clinic_store = false;
        this.hcs_clinic_store_search = false;
        this.HCSClinicStore = hcsclinicDetail.clinicList.slice(0, (0 + 10));
        if (this.HCSClinicStore.length > 0) {
          if (this.dataTableClinic_store != undefined) {
            this.dataTableClinic_store.first = 0;
            this.dataTableClinic.changePage;
          }
          this.bulkAction_store = true;
          this.hcs_clinic_store = true;
          this.hcs_clinic_store_search = true;
        }
      }

      // else if (this.page_index == 3) {
      //   this.bulkAction_cancel = false;
      //   this.hcs_clinic_cancel = false;
      //   this.HCSClinicStore = hcsclinicDetail.clinicList;
      //   if (this.HCSClinicStore.length > 0) {
      //     if (this.dataTableClinic_store != undefined) {
      //       this.dataTableClinic_store.first = 0;
      //       this.dataTableClinic.changePage;
      //     }
      //     this.bulkAction_cancel = true;
      //     this.hcs_clinic_cancel = true;
      //   }
      // }

      this.spinner.hide();
    },
      error => {
        console.error("Error in fetching data", error);
      });

  }

  changeCheck() {

  }
  paginate(event) {
    let pageIndex = event.first / Number(event.rows) + 1;
    // this.page_index_sort = pageIndex;
    //this.loadDataLazy(event, "ClinicDetails", 0, true);
  }
  onRowSelect(event) {

  }
  searchLocalLeads() {
    this.selectedLocalList = [];
    var localLeads = new LocalLeads();
    localLeads.opportunitiesList = [];
    localLeads.userPk = this.user_profile.userPk;
    localLeads.assignedTo = this.page_index;
    localLeads.pageIndex = 0;
    localLeads.filterBy = "";
    localLeads.filterById = "";
    localLeads.searchByValue = this.searchLocalValue;
    localLeads.sortByField = this.localLeadsSortBy
    localLeads.totalCount = 0;
    localLeads.startIndex = 0;
    localLeads.finalIndex = 0;
    this.spinner.show();
    if(this.page_index == 0) {
      this.getPriorityOpportunities(this.page_index, 0, 0);
      return;
    }
    if (this.page_index == 0) {
      localLeads.noOfRecordsInPage = this.hcsLocalNoOfPageRecords;
    } else {
      localLeads.noOfRecordsInPage = 50
    }
    this._opportunityService.getLocalLeads(localLeads).subscribe((localLeadsData) => {

      this.HCSLocalLeads = [];
      this.hcsLocalLeadsSource = localLeadsData;
      this.hcsLocalTotalRecords = localLeadsData.totalCount;
      if (this.page_index == 0) {
        this.bulkAction_local_hcs = false;
        this.hcs_local_details = false;
        this.hcs_local_details_search = false;
        //this.HCSLocalLeads = localLeadsData.opportunitiesList;
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(0, (0 + 10));
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(0, (0 + this.hcsLocalNoOfPageRecords));
        this.HCSLocalLeads = localLeadsData.opportunitiesList;
        if (this.HCSLocalLeads.length > 0) {
          if (this.dataTable != undefined) {
            this.dataTable.first = 0;
            this.dataTable.changePage;
          }
          this.bulkAction_local_hcs = true;
          this.hcs_local_details = true;
          this.hcs_local_details_search = true;
        }
      } else if (this.page_index == 1) {
        this.bulkAction_local_hcs = false;
        this.hcs_local_details = false;
        this.hcs_local_details_search = false;
        //this.HCSLocalLeads = localLeadsData.opportunitiesList;
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(0, (0 + 10));
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(0, (0 + this.hcsLocalNoOfPageRecords));
        this.HCSLocalLeads = localLeadsData.opportunitiesList;
        if (this.HCSLocalLeads.length > 0) {
          if (this.dataTable != undefined) {
            this.dataTable.first = 0;
            this.dataTable.changePage;
          }
          this.bulkAction_local_hcs = true;
          this.hcs_local_details = true;
          this.hcs_local_details_search = true;
        }
      } else if (this.page_index == 2) {
        this.HCSLocalLeadsDM = [];
        this.bulkAction_local_dm = false;
        this.hcs_local_DM = false;
        this.hcs_local_DM_search = false;
        this.HCSLocalLeadsDM = localLeadsData.opportunitiesList.slice(0, (0 + 10));
        if (this.HCSLocalLeadsDM.length > 0) {
          if (this.dataTable_DM != undefined) {
            this.dataTable_DM.first = 0;
            this.dataTable_DM.changePage;
          }
          this.bulkAction_local_dm = true;
          this.hcs_local_DM = true;
          this.hcs_local_DM_search = true;
        }
      }
      else if (this.page_index == 3) {
        this.HCSAssignedLocalLeads = [];
        this.bulkAction_local_store = false;
        this.hcs_local_store = false;
        this.hcs_local_store_search = false;
        this.HCSAssignedLocalLeads = localLeadsData.opportunitiesList.slice(0, (0 + 10));
        if (this.HCSAssignedLocalLeads.length > 0) {
          if (this.dataTable_store != undefined) {
            this.dataTable_store.first = 0;
            this.dataTable_store.changePage;
          }
          this.bulkAction_local_store = true;
          this.hcs_local_store = true;
          this.hcs_local_store_search = true;
        }

      }
      this.spinner.hide();
    });
  }

  storeSearchHCSLocal() {
    this.showVarHCSLocal = !this.showVarHCSLocal;    
    setTimeout(() => {
      this.opportunitiesEleRef.nativeElement.focus();
    }, 100);
  }
  onClickedOutside(e: Event) {
    this.showVarHCS = false;
  }
  onClickedOutsidee(e: Event) {
    this.showVarHCSLocal = false;
  }
  showDialog() {
    this.displaySE = true;
  }
  showDialogClinic(clinicLatitude, clinicLongitude, district_number, area_number, clinic_pk, clinic_type,
    store_id, is_clinic, out_reach_business_pk, outreachStatusId, currentSelectedRowItem: any, selectedTabValue?: string) {
    this.clinicLatitude = clinicLatitude;
    this.clinicLongitude = clinicLongitude;
    this.bulk_submit = false;
    this.clinic_type = clinic_type;
    this.default_store_id = store_id;
    this.store_number = this.defaultStore = store_id;
    this.district_number = district_number;
    this.area_number = area_number;
    this.business_pk = clinic_pk;
    this.displayClinic = true;
    this.is_clinic = is_clinic;
    this.out_reach_business_pk = out_reach_business_pk;
    this.out_reach_status_id = outreachStatusId;
    this.selectedTab = selectedTabValue ? selectedTabValue : "";
    if (!is_clinic) {
      this.currentSelectedRowItem = null;
      this._headerservice.getStoresProfile(this.userProfile.userPk, store_id, null).subscribe((res:any) => {
        this.currentSelectedRowItem = res.data[0];
      });
    }
    else {
      this.currentSelectedRowItem = currentSelectedRowItem;
    }
  }
  showLocalDialog() {
    this.displayLocal = true;
  }
  DlgReassignToHCS() {
    this.defaultStore = null;
    this.areaList = [];
    this.storeList = [];
    this.reassign_to_hcs = true;
    this._dashboardservice.getClosestStore(this.user_profile.userPk, this.area_number, this.business_pk, this.is_clinic, this.reassign_to_hcs).subscribe((data:any) => {
      this.defaultAreaNumber = this.area_number;
      this.areaList = data.districtList;
      this.storeList = data.storeList;
      // if (this.page_index == 2 && this.is_clinic == true) {
      //   this.storeList.push({ storeAddress: "Cancelled Clinic", storeId: -1 });
      // }
      if (this.storeList.length > 0) {
        this.defaultStore = this.store_number;
        this.store_number = this.storeList[0].storeId;
      }
    });
    this.displayClinic = false;
    this.dispDlgReassignToHCS = true;
  }
  showDialogclinicHCSInfo() {
    this.clinicHCSInfo = !this.clinicHCSInfo;
  }
  showDialogLocalInfo() {
    this.LocalOpportunitiesInfo = !this.LocalOpportunitiesInfo;
  }

  DlgReassign() {
    this.defaultStore = null;
    this.reassign_to_hcs = false;
    this.districtList = [];
    this.storeList = [];

    this._dashboardservice.getClosestStore(this.user_profile.userPk, this.district_number, this.business_pk, this.is_clinic, this.reassign_to_hcs).subscribe((data:any) => {
      this.defaultDistrict = this.district_number;
      this.districtList = data.districtList;
      this.storeList = data.storeList;
      // if (this.page_index == 2 && this.is_clinic == true) {
      //   this.storeList.push({ storeAddress: "Cancelled Clinic", storeId: -1 });
      // }
      if (this.storeList.length > 0) {
        this.defaultStore = this.store_number;
        this.store_number = this.storeList[0].storeId;
      }
    });
    this.displayClinic = false;
    this.dispDlgReassign = true;
  }
  SetTabHCS(e) {
    this.sorting = "";
    this.searchValue = "";
    this.selectOption = "";
    this.search_from_list = "";
    this.search_HCS_value = "";
    this.ariaV = false;
    this.storeV = false;
    this.distV = false;
    this.regionV = false;
    var index = this.HCSTab == false ? e.index + 1 : e.index;
    this.page_index = index;
    if (index == "0") {
      this.HCSInfoVisible = true;
      this.DMInfoVisible = false;
      this.StoreInfoVisible = false;
      this.CancelInfoVisible = false;
      this.getAssignedToHCS(index, 0, 0);
    }
    else if (index == "1") {
      this.HCSInfoVisible = false;
      this.DMInfoVisible = true;
      this.StoreInfoVisible = false;
      this.CancelInfoVisible = false;
      this.getAssignedToHCS(index, 0, 0);
    }
    else if (index == "2") {
      this.HCSInfoVisible = false;
      this.DMInfoVisible = false;
      this.StoreInfoVisible = true;
      this.CancelInfoVisible = false;
      this.getAssignedToHCS(index, 0, 0);
    }
    else if (index == "3") {
      this.HCSInfoVisible = false;
      this.DMInfoVisible = false;
      this.StoreInfoVisible = false;
      this.CancelInfoVisible = true;
      this.getAssignedToHCS(index, 0, 0);
    }

  }
  SetTabLocal(e) {
    this.search_local_value = "";
    this.search_from_list_local = "";
    this.searchLocalValue = "";
    this.selectOptionLocal = "";
    this.aria_local = false;
    this.store_local = false;
    this.dist_local = false;
    this.region_local = false;
    var index = this.HCSTab == false &&  e?.originalEvent?.target?.innerText !="Priority Opportunities to be Contacted" ? e.index + 1 : e.index;
    this.page_index = index;
    this.localOpportunitiesActiveIndex = e.index;
    if (index == "0") {
      this.UnassignLocalInfoVisible = false;
      this.LocalDistrictInfoVisible = false;
      this.AssignedLocalInfoVisible = false;
      this.getPriorityOpportunities(index, 0, 0);
    }
    else if (index == "1") {
      this.UnassignLocalInfoVisible = false;
      this.LocalDistrictInfoVisible = true;
      this.AssignedLocalInfoVisible = false;
      this.getLocalLeads(index, 0, 0);
    }
    else if (index == "2") {
      this.UnassignLocalInfoVisible = false;
      this.LocalDistrictInfoVisible = false;
      this.AssignedLocalInfoVisible = true;
      this.getLocalLeads(index, 0, 0);
    }
    else if (index == "3") {
      this.UnassignLocalInfoVisible = true;
      this.LocalDistrictInfoVisible = false;
      this.AssignedLocalInfoVisible = false;
      this.getLocalLeads(index, 0, 0);
    }

  }
  onDistrictSelect(disrict_value, check_value = false) {

    this.reassign_to_hcs = false;
    this.district_number = disrict_value;
    if (check_value) {
      this.storeList = [];
      this._dashboardservice.getClosestStore(this.user_profile.userPk, disrict_value, this.business_pk == undefined ? -1 : this.business_pk, this.is_clinic, this.reassign_to_hcs).subscribe((data:any) => {
        this.storeList = data.storeList;
        if (this.storeList.length > 0)
          this.store_number = this.defaultStore = this.storeList[0].storeId;
        this.currentDestinationStoreAddress = this.storeList.filter(s => s.storeId == this.store_number)[0].storeAddress;
      });
      this.displayClinic = false;
      this.dispDlgReassign = true;
      this.dispDlgReassignToHCS = false;
    }
  }
  onAreaSelect(area_number, check_value = false) {

    this.area_number = area_number;
    if (check_value) {
      this.storeList = [];
      this._dashboardservice.getClosestStore(this.user_profile.userPk, area_number, this.business_pk == undefined ? -1 : this.business_pk, this.is_clinic, this.reassign_to_hcs).subscribe((data:any) => {
        this.storeList = data.storeList;
        if (this.storeList.length > 0)
          this.store_number = this.defaultStore = this.storeList[0].storeId;
        this.currentDestinationStoreAddress = this.storeList.filter(s => s.storeId == this.store_number)[0].storeAddress;
      });
      this.displayClinic = false;
      this.dispDlgReassign = false;
      this.dispDlgReassignToHCS = true;
    }
  }
  onStoreSelect(store_number) {
    this.store_number = store_number;
    this.currentDestinationStoreAddress = this.storeList.filter(s => s.storeId == this.store_number)[0].storeAddress;
  }
  bulkSubmitClinics(is_override?: boolean) {
    this.selected_data_list = [];
    var clinic_assignment = new ClinicAssignment();
    clinic_assignment.clinicList = new Array<ClinicList>();
    var obj = new ClinicList();
    var clinic_assignment = new ClinicAssignment();
    this.spinner.show();
    if (this.selectedDataList.length > 0) {
      for (let i = 0; i < this.selectedDataList.length; i++) {
        obj = new ClinicList();
        obj.clinicPk = this.selectedDataList[i].clinicPk;
        obj.clinicType = this.selectedDataList[i].clinicType;
        obj.latitude = this.selectedDataList[i].clinicLatitude;
        obj.longitude = this.selectedDataList[i].clinicLongitude;
        obj.oldStoreId = this.selectedDataList[i].storeId;
        if (this.bulk_action_value == 5)
          obj.locationId = null;
        else
          if (this.page_index == 2 && this.is_clinic == true) {
            obj.locationId = this.reassign_to_hcs == true ? this.selectedDataList[i].areaNumber : this.selectedDataList[i].districtNumber;//this.reassign_to_hcs == true ? this.area_number : this.district_number;
          }
          else {
            obj.locationId = this.reassign_to_hcs == true ? this.area_number : this.district_number;

          }

        if (this.page_index_value == 1)
          obj.storeId = this.store_number;

        else
          obj.storeId = this.selectedDataList[i].storeId;
        obj.isCanceled = 0;
        obj.dateAdded = new Date();
        this.currentSelectedRowItem = this.selectedDataList[i];
        if (this.bulk_action_value == 1 && !this.validateRestrictedStatesReassignment()) {
          this.dialogSummary = "Alert";
          this.successMessage = this.is_clinic && this.currentDestinationStoreAddress.split(', ')[2].split(' ')[0] == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
          this.successDistStore = true;

          return;
        }
        this.selected_data_list.push(obj);
      }
    }
    clinic_assignment.userPk = this.user_profile.userPk;
    clinic_assignment.updateAction = this.page_index_value;
    clinic_assignment.assignedTo = this.page_index;
    clinic_assignment.errorS = null;
    clinic_assignment.totalS = 0;
    clinic_assignment.clinicList = this.selected_data_list;

    this._dashboardservice.updateClinicAssignment(clinic_assignment, is_override).subscribe((data:any) => {
      if (data.errorS != null && data.errorS.length > 0) {
        if (data.errorS.length > 0 && data.errorS[0].ErrorCode != 0) {
          this.dispDlgReassign = false;
          this.dispDlgReassignToHCS = false;
          this.bulkDisplayStoredlg = false;
          this.BulkdispDlgReassignToHCS = false;
          this.BulkdispDlgReassignToHCSStoreTab = false;
          this.displayStoredlg = false;
          this.errMsgV1 = [];
          let error_4_msg: any = [];
          this.spinner.hide();
          data.errorS.forEach(element => {
            if (element.errorCode == -4) {
              error_4_msg.push(String.Format(ErrorMessages['dashBoardOverlapErrorMessage'], element.errorMessage.split("<")[1],
                element.errorMessage.split("<")[5], element.errorMessage.split("<")[2], element.errorMessage.split("<")[3],
                element.errorMessage.split("<")[4], element.errorMessage.split("<")[0].replace("|", "").trim()));
            }
            else if (element.errorCode == -5) {
              let clinicList: any = element.errorMessage.split('|');
              let errorString: string = "";
              for (let index = 1; index < clinicList.length; index++) {
                errorString += clinicList[index];
              }
              errorString = errorString.trim();
              this.dialogSummary = "Warning";
              this.errMsgV1.push(String.Format(ErrorMessages['DashboardReassignmentWarning'], errorString));
            }
            else {
              this.errMsgV1.push(element.errorMessage);
            }
          });
          if (error_4_msg.length > 0) {
            this.errorShow = false;
            this.confirm("Alert", error_4_msg.join("<br/><br/>"), this.page_index);
            return false;
          }
          else if (this.errMsgV1.length > 0) {
            this.errorShow = true;
            this.showDialogV2("", this.errMsgV1.join('<br/><br/>'));
            return false;
          }
          return;
        }
      }
      switch (data) {
        // case '200':
        //   this.successDistStore = true;
        //   this.successMessage = "Worksite Clinic Store Assignment saved successfully.";
        //   break;
        default:
          this.successDistStore = true;
          this.successMessage = "Worksite Clinic Store Assignment saved successfully.";
          break;
      }
      this.dispDlgReassign = false;
      this.dispDlgReassignToHCS = false;
      this.bulkDisplayStoredlg = false;
      this.BulkdispDlgReassignToHCS = false;
      this.BulkdispDlgReassignToHCSStoreTab = false;
      this.displayStoredlg = false;
      this.selectedValue = "";
      this.spinner.hide();
      this.getAssignedToHCS(this.page_index, 0, 0);
    },
      err => {
        this.spinner.hide();
        switch (err) {
          case 500:
            alert('ErrorCode 500 received from Service');
            break;
          case 400:
            alert('Unhandled exception ErrorCode received from Service');
            break;
          default:
            alert('Unhandled exception ErrorCode received from Service');
            break;
        }
        this.dispDlgReassign = false;
        this.dispDlgReassignToHCS = false;
        this.bulkDisplayStoredlg = false;
        this.BulkdispDlgReassignToHCS = false;
        this.BulkdispDlgReassignToHCSStoreTab = false;
        this.displayStoredlg = false;
        this.selectedValue = "";
      });
    // else
    // {
    //   alert('Please select');
    //   this.selectedValue = "";
    //   this.dispDlgReassign = false;
    //   this.dispDlgReassignToHCS = false;
    //   this.bulkDisplayStoredlg = false;
    //   this.BulkdispDlgReassignToHCS = false;
    //   this.displayStoredlg = false;
    // } 
  }

  showDialogV2(msgSummary: string, msgDetail: string) {
    this.errorMessageShow = msgDetail;
    //this.dialogSummary = msgSummary;
    this.errorShow = true;
  }
  submit(page_index, is_override?: boolean) {
    if (this.bulk_submit == true) {
      if (this.is_clinic == true)
        this.bulkSubmitClinics(is_override);
      else if (this.is_clinic == false)
        this.submitBulkLocalLeads(is_override);
    }
    else {
      if (this.is_clinic == true) {
        var clinic_assignment = new ClinicAssignment();
        clinic_assignment.clinicList = new Array<ClinicList>();
        var obj = new ClinicList();
        obj.clinicPk = this.business_pk;
        obj.clinicType = this.clinic_type;
        obj.storeId = this.defaultStore;
        obj.isCanceled = this.defaultStore == -1 ? 1 : 0;
        obj.dateAdded = new Date();
        obj.latitude = this.clinicLatitude;
        obj.longitude = this.clinicLongitude;
        obj.oldStoreId = this.default_store_id;

        obj.locationId = this.reassign_to_hcs == true ? this.area_number : this.district_number;

        clinic_assignment.userPk = this.user_profile.userPk;
        clinic_assignment.updateAction = page_index;
        clinic_assignment.assignedTo = this.page_index;
        clinic_assignment.errorS = null;
        clinic_assignment.totalS = 0;
        clinic_assignment.clinicList[0] = obj;

        if (!this.validateRestrictedStatesReassignment()) {

          this.dialogSummary = "Alert";
          this.successMessage = this.currentDestinationStoreAddress.split(', ')[2].split(' ')[0] == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
          this.successDistStore = true;

          return;
        }
        this.spinner.show();

        this._dashboardservice.updateClinicAssignment(clinic_assignment, is_override).subscribe((data:any) => {
          if (data.errorS != null && data.errorS.length > 0) {
            if (data.errorS.length > 0 && data.errorS[0].ErrorCode != 0) {
              this.dispDlgReassign = false;
              if (data.errorS[0].errorCode == -4) {
                let error_msg = String.Format(ErrorMessages['dashBoardOverlapErrorMessage'], data.errorS[0].errorMessage.split("<")[1],
                  data.errorS[0].errorMessage.split("<")[5], data.errorS[0].errorMessage.split("<")[2], data.errorS[0].errorMessage.split("<")[3],
                  data.errorS[0].errorMessage.split("<")[4], data.errorS[0].errorMessage.split("<")[0].replace("|", "").trim());
                //TO Do with confirm service function
                this.confirm("Alert", error_msg, page_index);
              }
              else if (data.errorS[0].errorCode == -5) {
                this.errorShow = true;
                let clinicList: any = data.errorS[0].errorMessage.split('|');
                let errorString: string = "";
                for (let index = 1; index < clinicList.length; index++) {
                  errorString += clinicList[index];
                }
                errorString = errorString.trim();
                this.dialogSummary = "Warning";
                this.errorMessageShow = String.Format(ErrorMessages['DashboardReassignmentWarning'], errorString);
              }
              else {
                this.errorShow = true;
                this.errorMessageShow = data.errorS[0].errorMessage;
              }
              this.spinner.hide();
              return;
            }
          }
          switch (data) {
            // case '200':
            //   this.successDistStore = true;
            //   this.successMessage = "Worksite Clinic Store Assignment saved successfully.";
            //   break;
            default:
              this.successDistStore = true;
              this.successMessage = "Worksite Clinic Store Assignment saved successfully.";
              break;
          }
          this.dispDlgReassign = false;
          this.getAssignedToHCS(this.page_index, 0, 0);
          this.spinner.hide();
        });
      }
      else if (this.is_clinic == false) {
        this.localLeadsSave(page_index, is_override);
      }
    }
  }
  validateRestrictedStatesReassignment() {

    let returnValue: boolean = true;
    let sourceStoreId = this.currentSelectedRowItem.storeId;

    let sourceStoreState = null;
    if (!this.is_clinic) {
      sourceStoreState = this.currentSelectedRowItem.state;
    }
    else {
      sourceStoreState = this.currentSelectedRowItem.storeAddress.split(', ')[1].split(' ')[0];
    }
    if (sourceStoreId != this.store_number) {
      var selectedStoreState = this.currentDestinationStoreAddress.split(', ')[2].split(' ')[0];

      var itemDate = new Date();
      if (this.is_clinic) {
        itemDate = new Date(this.currentSelectedRowItem.naClinicDate);
      }
      if (sourceStoreState == "MO") {
        if (selectedStoreState.length > 0 && selectedStoreState == "DC" && itemDate > new Date(environment.DC_State_From) && itemDate < new Date(environment.DC_State_TO)) {
          returnValue = false;
        }
      }
      else if (sourceStoreState == "DC") {
        if (selectedStoreState.length > 0 && selectedStoreState == "MO" && itemDate > new Date(environment.MO_State_From) && itemDate < new Date(environment.MO_State_TO)) {
          returnValue = false;
        }
      }
      else if (sourceStoreState != "MO" || sourceStoreState != "DC") {
        if (selectedStoreState.length > 0 && (selectedStoreState == "MO" && itemDate > new Date(environment.MO_State_From) && itemDate < new Date(environment.MO_State_TO))
          || (selectedStoreState == "DC" && itemDate > new Date(environment.DC_State_From) && itemDate < new Date(environment.DC_State_TO))) {
          returnValue = false;
        }
      }
    }
    return returnValue;
  }
  localLeadsSave(page_index, is_override: boolean, disposition = '') {

    this.displayClinic = false;
    this.changestatusPopUp = false;
    var local_leads = new LocalLeadsSave();
    local_leads.override_sr_events = is_override;
    local_leads.opportunitiesList = new Array<OpportunitiesList>();
    var obj = new OpportunitiesList();
    obj.businessPk = this.business_pk;
    obj.outreachBusinessPk = this.out_reach_business_pk;
    obj.storeId = this.defaultStore;
    obj.lastContactDate = new Date();
    obj.latitude = this.clinicLatitude;
    obj.longitude = this.clinicLongitude;
    obj.assignToDistrict = null;//this.selectAllDist == true ? this.districtValue : null;
    if (page_index == 6)
      obj.locationId = null;
    else
      obj.locationId = this.reassign_to_hcs == true ? this.area_number : this.district_number;
    obj.outreachStatusId = page_index == 6 ? this.outreachstatus : this.out_reach_status_id;
    obj.isStatusUpdated = this.outreachstatus == "" ? false : true;
    local_leads.assignedTo = this.page_index;
    local_leads.opportunitiesList[0] = obj;
    local_leads.updateAction = page_index;
    local_leads.userPk = this.user_profile.userPk;
    local_leads.isAssignAllInDistrict = false;
    local_leads.assignToDistrict = null;
    local_leads.disposition = disposition;
    //this.page_index = page_index;
    this.spinner.show();
    if (!this.validateRestrictedStatesReassignment()) {
      this.dialogSummary = "Alert";
      this.successMessage = !this.is_clinic && this.currentDestinationStoreAddress.split(', ')[2].split(' ')[0] == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
      this.successDistStore = true;

      return;
    }
    this._dashboardservice.updateLocalLeadsAssignment(local_leads).subscribe((data:any) => {
      this.spinner.hide();
      if (data.errorS != null) {
        this.dispDlgReassign = false;
        if (data.errorS.errorCode == -1) {
          this.confirmLabel = "Continue";
          this.errorShow = false;
          this.confirm("Alert", data.errorS.errorMessage, page_index);
          return false;
        }
      }
      switch (data) {
        // case '200':
        //   this.successDistStore = true;
        //   this.successMessage = "Local Opportunities Store Assignment saved successfully.";
        //   break;
        default:
          this.successDistStore = true;
          this.successMessage = this.selectedTab === "PriorityOpportunities" ? "Priority Opportunities Store Assignment saved successfully." : "Local Opportunities Store Assignment saved successfully.";
          break;

      }
      if(this.selectedTab === "PriorityOpportunities")
        this.getLocalLeads(0, 0 , 0);
      else
        this.getLocalLeads(this.page_index, 0, 0);
    });
  }

  submitBulkLocalLeads(is_override?: boolean) {
    this.selected_data_list = [];
    var local_leads = new LocalLeadsSave();
    local_leads.override_sr_events = is_override;
    local_leads.opportunitiesList = new Array<OpportunitiesList>();
    var obj = new OpportunitiesList();
    this.spinner.show();
    if (this.selectedLocalList.length > 0) {
      for (let i = 0; i < this.selectedLocalList.length; i++) {
        obj = new OpportunitiesList();
        obj.businessPk = this.selectedLocalList[i].businessPk;
        obj.outreachBusinessPk = this.selectedLocalList[i].outreachBusinessPk;
        if (this.page_index_value == 1)
          obj.storeId = this.store_number;
        else
          obj.storeId = this.selectedLocalList[i].storeId;

        obj.latitude = this.selectedLocalList[i].latitude;
        obj.longitude = this.selectedLocalList[i].longitude;
        if (this.bulk_action_value == 5)
          obj.locationId = null;
        else
          obj.locationId = this.reassign_to_hcs == true ? this.area_number : this.district_number;
        obj.lastContactDate = new Date();
        obj.outreachStatusId = this.selectedLocalList[i].outreachStatusId;
        obj.isStatusUpdated = false;
        obj.assignToDistrict = this.selectAllDist == true ? this.districtValue : null;
        this.currentSelectedRowItem = this.selectedLocalList[i];
        if (this.bulk_action_value == 1 && !this.validateRestrictedStatesReassignment()) {
          this.dialogSummary = "Alert";
          this.successMessage = !this.is_clinic && this.currentDestinationStoreAddress.split(', ')[2].split(' ')[0] == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
          this.successDistStore = true;
          return;
        }
        this.selected_data_list.push(obj);
      }
    }
    local_leads.assignedTo = this.page_index;
    local_leads.opportunitiesList = this.selected_data_list;
    local_leads.updateAction = this.page_index_value;
    local_leads.userPk = this.user_profile.userPk;
    local_leads.isAssignAllInDistrict = this.selectAllDist;
    local_leads.assignToDistrict = this.selectAllDist == true ? this.districtValue : null;
    if (this.selectAllDist == true) {
      local_leads.opportunitiesList = [];
    }
    this._dashboardservice.updateLocalLeadsAssignment(local_leads).subscribe((data:any) => {
      this.spinner.hide();
      if (data.errorS != null) {
        this.dispDlgReassign = false;
        if (data.errorS.errorCode == -1) {
          this.errorShow = false;
          this.confirmLabel = "Continue";
          this.confirm("Alert", data.errorS.errorMessage, this.page_index);
          return false;
        }
      }
      switch (data) {
        case '200':
          this.successDistStore = true;
          this.successMessage = this.selectedTab === "PriorityOpportunities" ? "Priority Opportunities Status Assignment saved successfully." : "Local Opportunities Store Assignment saved successfully.";
          break;
        default:
          this.successDistStore = true;
          this.successMessage = "Local Leads Store Assignment saved successfully.";
          break;
      }
      this.dispDlgReassign = false;
      this.bulkDisplayStoredlg = false;
      this.BulkdispDlgReassignToHCS = false;
      this.BulkdispDlgReassignToHCSStoreTab = false;
      this.selectedValue = "";
      if(this.selectedTab === "PriorityOpportunities")
        this.getLocalLeads(0, 0 , 0);
      else
        this.getLocalLeads(this.page_index, 0, 0);
    },
      err => {
        this.spinner.hide();
        switch (err) {
          case 500:
            alert('ErrorCode 500 received from Service');
            break;
          case 400:
            alert('Unhandled exception ErrorCode received from Service');
            break;
          default:
            alert('Unhandled exception ErrorCode received from Service');
            break;
        }
        this.dispDlgReassign = false;
        this.bulkDisplayStoredlg = false;
        this.BulkdispDlgReassignToHCS = false;
        this.BulkdispDlgReassignToHCSStoreTab = false;
        this.selectedValue = "";
      });
  }
  onActionSelect(value, is_clinic) {    
    if ((is_clinic == true ? this.selectedDataList.length > 0 : this.selectedLocalList.length > 0) || this.selectAllDist == true) {
      this.reassign_to_hcs = false;
      this.defaultStore = null;
      this.page_index_value = value;
      this.is_clinic = is_clinic;
      this.bulk_submit = true;
      if (parseInt(value) === 1) {
        this.bulk_action_value = value;
        this.reassign_to_hcs = false;
        this.dispDlgReassign = true;
        this.districtList = [];
        this.storeList = [];
        this._dashboardservice.getClosestStore(this.user_profile.userPk, -1, -1, is_clinic, false).subscribe((data:any) => {
          this.districtList = data.districtList;
          this.storeList = data.storeList;
          this.defaultDistrict = this.districtList[0].number;
          if (this.storeList.length > 0)
            this.store_number = this.storeList[0].storeId;
          this.defaultStore = this.store_number;
          this.currentDestinationStoreAddress = this.storeList.filter(s => s.storeId == this.store_number)[0].storeAddress;
        });
        this.displayClinic = false;
        this.dispDlgReassign = true;
        if (!this.is_clinic) {
          this.selectedLocalList.forEach(element => {
            this._headerservice.getStoresProfile(this.userProfile.userPk, element.storeId, null).subscribe((res:any) => {
              element.state = res.data[0].state;
            });
          });
        }
      }
      if (parseInt(value) === 3) {
        this.reassign_to_hcs = true;
        this.bulk_action_value = value;
        if (this.page_index === 2) {
          this.reassignToHCSConfirmDlg = this.BulkdispDlgReassignToHCS = false;
          let businessNameList = "";
          this.selectedLocalList.forEach(local_opp => {
            if (local_opp.hasSREvents) {
              this.localOppHasSREvents += ((this.localOppHasSREvents != "") ? "<br />" + local_opp.businessName : local_opp.businessName);
            }
            if(local_opp.outreachStatusId===10){
            businessNameList += String.Format("<b>{0}</b> (HCS Contacted)<br/>", local_opp.businessName);
            }
            else {
              businessNameList += String.Format("<b>{0}</b><br/>", local_opp.businessName);
            }
          });
          if (this.localOppHasSREvents !== "") {
            this.BulkdispDlgReassignHasSREvents = true;
          } else {

              if(is_clinic){
                this.dlgMessage = 'This action will remove all of the selected clinics from the store shown in the "Store #" column and will assign to HCS.';  
              }
              else{
                var hcsContractList = this.selectedLocalList.find(s=>s.outreachStatusId==10);
                if(hcsContractList&&(hcsContractList.Count>0|| hcsContractList)){
                this.dlgMessage = 'The assigned store(s) have already reached out to the opportunities and changed the outreach status. Resetting the opportunity will return the outreach status to "No Outreach."<br/>' + businessNameList;
                }
                else {
                  this.dlgMessage = 'This action will remove all of the selected local opportunities from the store shown in the "Assigned Store" column and will assign to HCS.';
                }
              }
  
            //this.BulkdispDlgReassignToHCSStoreTab = true;
            this.reassignToHCSConfirmDlg = true;
          }
        }
        else {
          this.BulkdispDlgReassignToHCSStoreTab = false;
          this.bulkDisplayStoredlg = false;
          this.BulkdispDlgReassignToHCS = true;
          this.areaList = [];
          this.storeList = [];
          this._dashboardservice.getClosestStore(this.user_profile.userPk, -1, -1, is_clinic, true).subscribe((data:any) => {
            this.areaList = data.districtList;
            this.storeList = data.storeList;
            this.defaultAreaNumber = this.area_number = this.areaList[0].number;
            if (this.storeList.length > 0)
              this.store_number = this.storeList[0].storeId;
          });
        }
        this.currentDestinationStoreAddress = null;
        this.currentSelectedRowItem = null;
      }
      if (parseInt(value) === 4) {
        this.bulk_action_value = value;
        if (this.page_index === 2) {
          this.reassignToHCSConfirmDlg = this.BulkdispDlgReassignToHCS = false;
          let businessNameList: string = "";
          this.selectedLocalList.forEach(local_opp => {
            if (local_opp.hasSREvents) {
              this.localOppHasSREvents += ((this.localOppHasSREvents !== "") ? "<br />" + local_opp.businessName : local_opp.businessName);
            }
            if(local_opp.outreachStatusId===10){
              businessNameList += String.Format("<b>{0}</b> (HCS Contacted)<br/>", local_opp.businessName);
              }
              else {
                businessNameList += String.Format("<b>{0}</b><br/>", local_opp.businessName);
              }
          });
          if (this.localOppHasSREvents !== "") {
            this.BulkdispDlgReassignHasSREvents = true;
          } else {
            
              if(is_clinic){
                this.dlgMessage = 'This action will remove all of the selected clinics from the store shown in the "Store #" column and will assign to DM.';  
              }
              else{
                let hcsContractList = this.selectedLocalList.find(s=>s.outreachStatusId===10);
                if(hcsContractList&&(hcsContractList.Count>0 || hcsContractList)){
                this.dlgMessage = 'The assigned store(s) have already reached out to the opportunities and changed the outreach status. Resetting the opportunity will return the outreach status to "No Outreach."<br/>' + businessNameList;
                }
                else {
                  this.dlgMessage = 'This action will remove all of the selected local opportunities from the store shown in the "Assigned Store" column and will assign to DM.';
                }
              }

            //this.BulkdispDlgReassignToHCSStoreTab = true;
            this.reassignToHCSConfirmDlg = true;
          }
        }
        else {
          this.BulkdispDlgReassignToHCSStoreTab = false;
          this.bulkDisplayStoredlg = false;
          this.BulkdispDlgReassignToHCS = true;
          this.districtList = [];
          this.storeList = [];
          this._dashboardservice.getClosestStore(this.user_profile.userPk, -1, -1, is_clinic, false).subscribe((data:any) => {
            this.districtList = data.districtList;
            this.storeList = data.storeList;
            this.defaultDistrict = this.district_number = this.districtList[0].number;
            if (this.storeList.length > 0)
              this.store_number = this.storeList[0].storeId;
          });
        }
        this.currentDestinationStoreAddress = null;
        this.currentSelectedRowItem = null;
      }
      if (parseInt(value) === 5) {
        this.bulk_action_value = value;
        this.bulkDisplayStoredlg = true;
        this.BulkdispDlgReassignToHCS = false;
        this.storeList = [];
        this.currentDestinationStoreAddress = null;
        this.currentSelectedRowItem = null;
      }
      if (parseInt(value) === 6) {
        this.BulkdispDlgConfirmCancelClinicTab = true;
      }
      else if (parseInt(value) === 7) {
        this.BulkdispDlgRevertCancelClinicTab = true;
      }
    }
    else {
      this.select_data = true;
      if (this.selectedDataList.length <= 0 && is_clinic) {
        this.selectMessage = 'Please select Clinics';
      }
      if (this.selectedLocalList.length <= 0 && is_clinic == false) {
        this.selectMessage = 'Please select Opportunities';
      }
      this.selectedValue = " ";
    }
  }
 
  isRowDisabled(row_data:any){

    return (row_data.outreachStatusId==5 ||row_data.outreachStatusId==4 ||row_data.outreachStatusId==3||row_data.outreachStatusId==8||row_data.outreachStatusId==12)

  }

  onLeadsHeaderChange(event:any,type:string){
    
    var target = event;
    setTimeout((evt)=>{ 
      let temp_local_list=[];
      this.selectedLocalList.forEach(x =>{        
          temp_local_list.push(x);        
      });
      //if(temp_local_list.length >0){
        this.selectedLocalList = temp_local_list;
        setTimeout(()=>{
          switch(type.toLowerCase()){
            case 'localleads':
               this.chkAll1.checked = (this.selectedLocalList.length == 0) ? false : true;
              break;
            case 'localleadsdm':
              this.chkAll2.checked = (this.selectedLocalList.length == 0) ? false : true;
              break;
            case 'assignedlocalleads':
              this.chkAll3.checked = (this.selectedLocalList.length == 0) ? false : true;
              break;
            default:
              break;
          }
        },50);
      //}
    }, 60);
  }

  bulkClose() {
    this.bulkDisplayStoredlg = false;
    this.selectedValue = "";
    this.selectedDataList = [];
    this.selectedLocalList = [];
  }
  bulkCloseHCS() {
    this.BulkdispDlgReassignToHCS = false;
    this.BulkdispDlgReassignToHCSStoreTab = false;
    this.BulkdispDlgReassignHasSREvents = false;
    this.localOppHasSREvents = "";
    this.selectedValue = "";
    this.selectedDataList = [];
    this.selectedLocalList = [];
  }
  Close() {
    this.dispDlgReassign = false;
    this.selectedValue = "";
    this.selectedDataList = [];
    this.selectedLocalList = [];
  }
  CloseStore() {
    this.displayStoredlg = false;
    this.selectedValue = "";
    this.selectedDataList = [];
    this.selectedLocalList = [];
  }
  CloseHCS() {
    this.dispDlgReassignToHCS = false;
    this.selectedValue = "";
    this.selectedDataList = [];
    this.selectedLocalList = [];
  }
  clFld(event: any) {
    this.searchValue = "";
    this.search_HCS_value = "";
    this.search_from_list = "";
    event.stopPropagation();
    event.preventDefault();
    this.searchGlobal();
  }
  clrFld(event: any) {
    this.search_local_value = "";
    this.search_from_list_local = "";
    this.searchLocalValue = null;
    event.stopPropagation();
    event.preventDefault();
    this.searchLocalLeads();
  }
  onLocalLeadsSelect(assigned_to, value, selected_value) {
    this.selectedLocalList = [];
    this.districtSelected = false;
    if (value.toLowerCase() == "district" && selected_value != "null" && assigned_to != 2) {
      this.districtSelected = true;
    }
    this.spinner.show();
    var localLeads = new LocalLeads();
    localLeads.opportunitiesList = [];
    localLeads.userPk = this.user_profile.userPk;
    localLeads.assignedTo = assigned_to;
    localLeads.pageIndex = 0;
    localLeads.filterBy = this.search_local_value = value;
    localLeads.filterById = this.search_from_list_local = value.toLowerCase() == "store" ? selected_value.item.storeId : selected_value == "null" ? "" : selected_value;
    this.search_from_list_local = this.search_from_list_local == "" ? "" : this.search_from_list_local;
    localLeads.searchByValue = this.searchValue;
    localLeads.sortByField = this.localLeadsSortBy;
    localLeads.totalCount = 0;
    localLeads.startIndex = 0;
    localLeads.finalIndex = 0;
    this.page_index = assigned_to;
    if (this.page_index == 0) {
      localLeads.noOfRecordsInPage = this.hcsLocalNoOfPageRecords;
    } else {
      localLeads.noOfRecordsInPage = 50
    }
    if(this.page_index == 3) {
      this.getPriorityOpportunities(assigned_to, 0, 0, true);
      return;
    }
    this._opportunityService.getLocalLeads(localLeads).subscribe((localLeadsData) => {
      this.hcsLocalLeadsSource = localLeadsData;
      this.hcsLocalTotalRecords = localLeadsData.totalCount;
      if (this.page_index == 0) {
        this.HCSLocalLeads = [];
        this.hcs_local_details = false;
        this.hcs_local_details_search = true;
        this.bulkAction_local_hcs = false;
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(0, (0 + 10));
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(0, (0 + this.hcsLocalNoOfPageRecords));
        this.HCSLocalLeads = localLeadsData.opportunitiesList;
        if (this.HCSLocalLeads.length > 0) {
          this.hcs_local_details = true;
          if (this.dataTable != undefined) {
            this.dataTable.first = 0;
            this.dataTable.changePage;
          }
          this.bulkAction_local_hcs = true;
        }
      }
      else if (this.page_index == 1) {
        this.HCSLocalLeadsDM = [];
        this.hcs_local_DM = false;
        this.hcs_local_DM_search = true;
        this.bulkAction_local_dm = false;
        this.HCSLocalLeadsDM = localLeadsData.opportunitiesList.slice(0, (0 + 10));
        if (this.HCSLocalLeadsDM.length > 0) {
          this.hcs_local_DM = true;
          if (this.dataTable_DM != undefined) {
            this.dataTable_DM.first = 0;
            this.dataTable_DM.changePage;
          }
          this.bulkAction_local_dm = true;
        }
      }
      else if (this.page_index == 2) {
        this.HCSAssignedLocalLeads = [];
        this.hcs_local_store = false;
        this.bulkAction_local_store = false;
        this.hcs_local_store_search = true;
        this.HCSAssignedLocalLeads = localLeadsData.opportunitiesList.slice(0, (0 + 10));
        if (this.HCSAssignedLocalLeads.length > 0) {
          this.hcs_local_store = true;
          this.bulkAction_local_store = true;
          if (this.dataTable_store != undefined) {
            this.dataTable_store.first = 0;
            this.dataTable_store.changePage;
          }
          this.bulkAction_local_store = true;
        }

      }
      this.spinner.hide();
    });
  }
  changeStatus() {
    this.changestatusPopUp = true;
  }
  closeStatus() {
    this.changestatusPopUp = false;
  }
  saveChangeStatus() {
    if(this.outreachstatus == 4 && this.disposition.length == 0){
      this.displayDispositionError = true;
    }
    else{
      this.localLeadsSave(6, false, this.disposition);
    }
  }
  cancelBtn() {
    this.select_data = false;
    this.selectedValue = "";
  }

  setWidth(headerv: string) {
    switch (headerv) {
      case 'Opportunity Address':
        return 'wth250';
      case 'Opportunity Name & Number':
        return 'wth200';
      case 'Emp':
        return 'wth77';
      case 'District':
        return 'wth75';
      case 'Status':
        return 'wth120';
      default:
        return '';
    }

  }

  showActionItemOrMetricsDetails(actionitem_metric_id: number, hcs_action_item_name: string, report_type:string, programType:number=-1 ) {
    if (report_type == "actionitem") {
      sessionStorage.actionItemOrMetricId = "actionitem:" + actionitem_metric_id;
    }
   else if (hcs_action_item_name == "actionitem") {
      sessionStorage.actionItemOrMetricId = "actionitem:" + actionitem_metric_id;
    }
    else {
      sessionStorage.actionItemOrMetricId = "metric:" + actionitem_metric_id;
    }
    if(actionitem_metric_id != 10 && actionitem_metric_id!= 11){
      if (this.title != "pcadashboard") {
        if(programType == 1){
        this.router.navigate(['/communityoutreach/immunizationprograms/hcsactionitems']);
      }
        else if(programType==0){
          this.router.navigate(['/communityoutreach/immunizationprograms/fluhcsactionitems']);
        }
        else {
          this.router.navigate(['/communityoutreach/immunizationprograms/fluhcsactionitems']);
        }
      }
      else {
        this.router.navigate(['/communityoutreach/patientcareadvocacy/pcaactionitems']);
      }
    } else if(actionitem_metric_id == 10){
      if (this.localOpportunitiesActiveIndex != 0 ) {
        this.SetTabLocal({index: 0});
        this.localOpportunitiesActiveIndex = 0;
        setTimeout(() => this.priorityOpportunitiesRef.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" }),500);
      }
      this.priorityOpportunitiesRef.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if(actionitem_metric_id == 11) {
      this.leadAwaitingRef.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      if(this.leadAwaitingActiveIndex != 0) {
        this.setLeadsAwaitingTab({index: 0});
        this.leadAwaitingActiveIndex = 0; 
      }
    } else {
      console.log("not implemented");
    }
  }
  customSort(sort_field, tab_index) {

    this.dispDlgReassign = false;
    this.dispDlgReassignToHCS = false;
    this.selectedDataList = [];
    var dashboard = new DashBoard();
    dashboard.clinicList = [];
    dashboard.userPk = this.user_profile.userPk;
    dashboard.assignedTo = tab_index;
    dashboard.pageIndex = this.scheduledClinicsPageIndex;
    dashboard.filterBy = "";
    dashboard.filterById = "";
    dashboard.searchByValue = this.searchValue;
    if (this.scheduledClinicsSortBy == sort_field) {
      if (!this.formLoaded) {
        this.scheduledClinicsSortOrder = !this.scheduledClinicsSortOrder;
        dashboard.sortOrder = this.scheduledClinicsSortOrder;
        this.formLoaded = true;
      }
      else {
        this.scheduledClinicsSortOrder = !this.scheduledClinicsSortOrder;
        dashboard.sortOrder = this.scheduledClinicsSortOrder;
      }
    }
    else {
      this.scheduledClinicsSortOrder = dashboard.sortOrder = true;
      this.formLoaded = true;
    }

    this.scheduledClinicsSortBy = dashboard.sortByField = sort_field;
    dashboard.totalCount = 0;
    dashboard.startIndex = 0;
    dashboard.finalIndex = 0;

    let start_number = 0;
    if (this.page_index == 0) {
      dashboard.noOfRecordsInPage = this.hcsClinicNoOfPageRecords;
    } else {
      dashboard.noOfRecordsInPage = 50;
    }
    this.spinner.show();
    this._opportunityService.getHCSClinicDetails(dashboard).subscribe((hcsclinicDetail) => {
      this.hcsClinicTotalRecords = hcsclinicDetail.totalCount;
      this.hcsClinicsSource = hcsclinicDetail;
      if (this.page_index == 0) {

        this.bulkAction_hcs = false;
        this.hcs_clinic_details = false;
        this.hcs_clinic_details_search = false;

        this.HCSClinicDetails = [];
        //this.HCSClinicDetails = hcsclinicDetail.clinicList.slice(start_number, (start_number + 10));
        //this.HCSClinicDetails = hcsclinicDetail.clinicList.slice(start_number, (start_number + this.hcsClinicNoOfPageRecords));
        this.HCSClinicDetails = hcsclinicDetail.clinicList;
        if (this.HCSClinicDetails.length > 0) {
          this.bulkAction_hcs = true;
          this.hcs_clinic_details = true;
          this.hcs_clinic_details_search = true;
        }
      }
      else if (this.page_index == 1) {
        this.bulkAction_dm = false;
        this.hcs_clinic_DM = false;
        this.hcs_clinic_DM_search = false;

        this.HCSClinicDM = [];
        this.HCSClinicDM = hcsclinicDetail.clinicList.slice(start_number, (start_number + 10));
        if (this.HCSClinicDM.length > 0) {
          this.bulkAction_dm = true;
          this.hcs_clinic_DM = true;
          this.hcs_clinic_DM_search = true;
        }
      }
      else if (this.page_index == 2) {
        this.bulkAction_store = false;
        this.hcs_clinic_store = false;
        this.hcs_clinic_store_search = false;
        this.HCSClinicStore = [];
        this.HCSClinicStore = hcsclinicDetail.clinicList.slice(start_number, (start_number + 10));
        if (this.HCSClinicStore.length > 0) {
          this.bulkAction_store = true;
          this.hcs_clinic_store = true;
          this.hcs_clinic_store_search = true;
        }
      }
      else if (this.page_index === 3) {   
        this.HCSClinicCancelled = [];
        this.HCSClinicCancelled = hcsclinicDetail.clinicList.slice(start_number, (start_number + 10));
      }
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        console.error("Error in fetching data", error);
      });
  }

  LocalcustomSort(sort_field, tab_index) {
    this.aria_local = false;
    this.store_local = false;
    this.dist_local = false;
    this.region_local = false;
    this.selectOptionLocal = "";
    this.districtSelected = false;
    this.districtValue = null;
    this.selectAllDist = false;
    this.selectedLocalList = [];
    this.dispDlgReassign = false;
    this.dispDlgReassignToHCS = false;
    this.displayStoredlg = false;
    this.outreachstatus = "";
    let start_number = 0;
    var localLeads = new LocalLeads();
    localLeads.opportunitiesList = [];
    localLeads.userPk = this.user_profile.userPk;
    localLeads.assignedTo = tab_index;
    localLeads.pageIndex = this.localLeadsPageIndex;
    localLeads.filterBy = "";
    localLeads.filterById = "";
    localLeads.searchByValue = this.searchValue;

    if (this.localLeadsSortBy == sort_field) {
      if (!this.formLoaded) {
        this.localLeadsSortOrder = !this.localLeadsSortOrder;
        localLeads.sortOrder = this.localLeadsSortOrder;
        this.formLoaded = true;
      }
      else {
        this.localLeadsSortOrder = !this.localLeadsSortOrder;
        localLeads.sortOrder = this.localLeadsSortOrder;
      }
    }
    else {
      this.localLeadsSortOrder = localLeads.sortOrder = true;
      this.formLoaded = true;
    }
    this.localLeadsSortBy = localLeads.sortByField = sort_field;
    localLeads.totalCount = 0;
    localLeads.startIndex = 0;
    localLeads.finalIndex = 0;
    this.page_index = tab_index;
    if (this.page_index == 0) {
      localLeads.noOfRecordsInPage = this.hcsLocalNoOfPageRecords;
    } else {
      localLeads.noOfRecordsInPage = 50
    }
    if (tab_index == 3) {
      this.bulkAction_local_store = false;
      this.hcs_local_store = false;
      this.hcs_local_store_search = false;   
      this.getPriorityOpportunities(0, 0, 0);
      return;
    }
    this.spinner.show();
    this._opportunityService.getLocalLeads(localLeads).subscribe((localLeadsData) => {
      this.hcsLocalLeadsSource = localLeadsData;
      this.hcsLocalTotalRecords = localLeadsData.totalCount;
      if (tab_index == 0) {

        this.bulkAction_local_hcs = false;
        this.hcs_local_details = false;
        this.hcs_local_details_search = false;

        this.HCSLocalLeads = [];
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(start_number, (start_number + 10));
        //this.HCSLocalLeads = localLeadsData.opportunitiesList.slice(start_number, (start_number + this.hcsLocalNoOfPageRecords));
        this.HCSLocalLeads = localLeadsData.opportunitiesList;
        if (this.HCSLocalLeads.length > 0) {

          this.bulkAction_local_hcs = true;
          this.hcs_local_details = true;
          this.hcs_local_details_search = true;
        }
      }
      else if (tab_index == 1) {
        this.bulkAction_local_dm = false;
        this.hcs_local_DM = false;
        this.hcs_local_DM_search = false;

        this.HCSLocalLeadsDM = [];
        this.HCSLocalLeadsDM = localLeadsData.opportunitiesList.slice(start_number, (start_number + 10));
        if (this.HCSLocalLeadsDM.length > 0) {

          this.bulkAction_local_dm = true;
          this.hcs_local_DM = true;
          this.hcs_local_DM_search = true;
        }
      }
      else if (tab_index == 2) {
        this.bulkAction_local_store = false;
        this.hcs_local_store = false;
        this.hcs_local_store_search = false;

        this.HCSAssignedLocalLeads = [];
        this.HCSAssignedLocalLeads = localLeadsData.opportunitiesList.slice(start_number, (start_number + 10));
        if (this.HCSAssignedLocalLeads.length > 0) {

          this.bulkAction_local_store = true;
          this.hcs_local_store = true;
          this.hcs_local_store_search = true;
        }

      }
      this.spinner.hide();
    });
  }
  error() {
    this.errorShow = false;
    this.selectedValue = "";
    this.dispDlgReassign = false;
    this.dispDlgReassignToHCS = false;
    this.bulkDisplayStoredlg = false;
    this.BulkdispDlgReassignToHCS = false;
    this.BulkdispDlgReassignToHCSStoreTab = false;
    this.displayStoredlg = false;
    this.getAssignedToHCS(this.page_index, 0, 0);
  }
  errorLocal() {
    this.errorLocalShow = false;
    this.selectedValue = "";
    this.getLocalLeads(this.page_index, 0, 0);
  }

  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  confirm(hdr: string, msg: string, page_index: number) {
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {
        this.submit(page_index, true);
        this.confirmLabel = "Override";
        return true;
      },
      reject: () => {
        this.confirmLabel = "Override";
        return false;
      }
    });
  }
  MoveToHCSorDM() {
    this.reassignToHCSConfirmDlg = false;
    this.submit(this.page_index, false);

  }
  confirmCancelledClinics() {
    this.BulkdispDlgConfirmCancelClinicTab = false;
    this.spinner.show();
    this.submitCancelClinics(6);

  }
  confirmRevertCancelledClinics() {
    this.BulkdispDlgRevertCancelClinicTab = false;
    this.spinner.show();
    this.submitCancelClinics(7);

  }
  submitCancelClinics(value) {
    var cancelSchedulledClinics = new CancelSchedulledClinics();
    cancelSchedulledClinics.cancelledList = [];
    cancelSchedulledClinics.userPk = this.user_profile.userPk;
    cancelSchedulledClinics.actionValue = value;

    for (let i = 0; i < this.selectedDataList.length; i++) {
      var cancelAppointments = new CancelAppointments();
      cancelAppointments.clinicPk = this.selectedDataList[i].clinicPk;
      cancelSchedulledClinics.cancelledList.push(cancelAppointments);
    }
    this._dashboardservice.updateCanceledClinics(cancelSchedulledClinics).subscribe((data) => {
      this.spinner.hide();
      this.successDistStore = true;
      if (parseInt(value) === 6) this.successMessage = "Clinic cancelled successfully.";
      else this.successMessage = "Clinic reverted successfully.";
      this.successDistStore = true;
      this.getAssignedToHCS(3, 0, 0);
      this.selectedValue="";
    },
      err => {
        this.spinner.hide();
        this.selectedValue="";
        switch (err) {
          case 500:
            this.showDialogV2(ErrorMessages['contract'], String.Format('ErrorCode 500 received from Service errorMessage:{0}', err.json().Message));
            break;
          case 400:
            this.showDialogV2(ErrorMessages['clientError'], err.json().Message);
            break;
          default:
            this.showDialogV2(ErrorMessages['contract'], String.Format('Unhandled exception ErrorCode received from Service errorMessage:{0}', err.json().Message));
            break;
        }
      });
  }

  onReasonForDeclineChange(){
    this.displayDispositionError = false;
  }

  onIPOuteachStatusChange(event){
    this.disposition = "";
    this.displayDispositionError = false;
  }

  leadIntakeClick(leadInfo: LeadAwaitingResponse) {
    // this.showLeadIntakeBtns = true;
      this.dialogRef = this.dialogService.open(ActionComponent, {
          header: 'Choose an action',
          contentStyle: { overflow: 'auto' },
          baseZIndex: 10000,
          draggable: true
      });
      this.dialogRef.onClose.subscribe((data) => {
          switch (data.action){
            case 'Submission':
              this.handleFormSubmission(leadInfo);
              break;
            case 'Respond':
              this.handleRespond(leadInfo);
              break;
            case 'Reassign':
              this.handleReassign(leadInfo);
              break;
            default: 
              this.handleFormSubmission(leadInfo);
          }
      });
  }

  handleFormSubmission(leadInfo: LeadAwaitingResponse){
    this.selectedLeadAwaitingResponseInfo = leadInfo;
    this.leadAwaitingResponseService.getLeadAwaitingResponseById(this.userProfile.userPk, leadInfo.Pk).subscribe((data: LeadAwaitingResponseByIdList) => {
      this.leadAwaitingResponseInfo = data?.Table?.[0];
      this.showFullSubmission = true;   
      this.showReassignStatus = false;
      this.showRespondStatus = false;
      this.spinner.hide();
    })
  }
  
  handleRespond(leadInfo: LeadAwaitingResponse) {
    this.selectedLeadAwaitingResponseInfo = leadInfo;
    this.spinner.hide();
    this.showFullSubmission = false;
    this.showReassignStatus = false;
    this.showRespondStatus = true;
  }
  handleRespondAction(response: string){
    this.showRespondStatus = false;
    this.showRespondAction = true;
    this.selectedResponse = response;
  }
  
  handleContinue() {
      let leadPk = this.selectedLeadAwaitingResponseInfo?.Pk;                                   
      let storeId= this.selectedLeadAwaitingResponseInfo?.ClosestStore; 
      this.spinner.show();      
      if(this.selectedResponse == 'Yes'){
        this.potentialClientInterestService.sendEmailToClientWhenStoreRespondsYes(leadPk, storeId).subscribe((data: any) => {
          this.responseMessage = data;
          this.showRespondActionDialog = true;
          this.showRespondAction = false;
          this.spinner.hide();
        });
      }  
      if(this.selectedResponse == 'No'){
        this.potentialClientInterestService.sendEmailToClientWhenStoreRespondsNo(leadPk, storeId).subscribe((data: any) => {
          this.showRespondActionDialog = true;
          this.showRespondAction = false;
          this.spinner.hide();
        });
      }              
  }
  
  handleReassign(leadInfo: LeadAwaitingResponse) {
    this.selectedLeadAwaitingResponseInfo = leadInfo;
    this.coClinicdetailsService.getAreaDist('district',0).subscribe(data => {
      let dataList = data;//JSON.parse(data._body)
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.leadDistrictList.push({ key: keyValue, value: valueData });
        this.showReassignStatus = true;
        this.showFullSubmission = false;
        this.showRespondStatus = false;
        this.spinner.hide();
      }
    });
  }

  handleLeadsSearchClick() {
    this.seachAwaitingLeadsResponse = !this.seachAwaitingLeadsResponse;    
    setTimeout(() => {
      this.searchLeadsRef.nativeElement.focus();
    }, 100);
  }
  onLeadsClickedOutside(e: Event) {
    this.seachAwaitingLeadsResponse = false;
  }

  handleLeadsAwaitingSearch(){
    this.spinner.show();
    let newArrayData = this.tableOptions.data.filter((leadObj) => {
      let newData = Object.keys(leadObj).filter((key) => typeof(leadObj[key]) === "number" ? leadObj[key] == this.leadsSearchValue: leadObj[key]?.toLowerCase()?.includes(this.leadsSearchValue?.toLocaleLowerCase()));
      if(newData.length > 0) 
        return leadObj;
      return false;
    });
    this.tableOptions =  {...this.tableOptions, data: newArrayData};
    this.ref.detectChanges();
    this.spinner.hide();
  }

  handleLeadsAwaitingSearchKeyChange(event: KeyboardEvent){

    const key = event.keyCode || event.charCode;
    if(key == 8 || key == 46){
      if(!this.leadsSearchValue?.length) {
        this.spinner.show();
        this.tableOptions = {...this.tableOptions, data:this.leadAwaitingResponseList}
        event.stopPropagation();
        event.preventDefault();
        this.spinner.hide();
      } else if(!this.tableOptions?.data?.length){
        this.spinner.show();
        this.tableOptions = {...this.tableOptions, data:this.leadAwaitingResponseList}
        event.stopPropagation();
        event.preventDefault();
        this.spinner.hide();
      }
    }
  }

  clrLeadsSearchFld(event: any) {
    this.spinner.show();
    this.leadsSearchValue = "";
    this.tableOptions = {...this.tableOptions, data:this.leadAwaitingResponseList}
    event.stopPropagation();
    event.preventDefault();
    this.spinner.hide();
    // this.searchLocalLeads();
  }

  setLeadsAwaitingTab(event: any) {
    this.spinner.show();
    this.leadAwaitingActiveIndex = event.index || 0;
    if(event.index == 1) 
      this.requestType = 0;
    else if(event.index == 0)
      this.requestType = 1;
    this.leadAwaitingResponseService.getLeadAwaitingResponseList({pageIndex: this.pageIndex, pageSize: this.pageSize,  requestType: this.requestType, userId: this.userProfile.userPk}).subscribe((data: LeadsAwaitingResponseList) => {
        this.leadAwaitingResponseList = data.Table;
        this.tableOptions = {
          columns: this.columns,
          data: this.leadAwaitingResponseList,
          hasPagination: true,
          isSearchable: true,
          rowsPerPage: 10,
          rowsPerPageOptions: [5,10,20],
          isSortable: true,
          actionMenuCallback: this.leadIntakeClick.bind(this),
          showBarsIcon: true,
          iconPosition: this.columns.length -1,
          tableClass: "lead-intake-card",
          sortField: "Submitted",
          sortOrder: 0
        };
        this.ref.detectChanges();
        this.spinner.hide();
      },
      (err) =>  {
        this.spinner.hide();
      }
    );
  }

  handleLeadDistrictSelection(disrict_value, check_value=false) {
    this.spinner.show();
    this.district_number = disrict_value;
    if (check_value && disrict_value) {
      this.storeList = [];
      this._dashboardservice.getClosestStore(this.user_profile.userPk, disrict_value, this.business_pk == undefined ? -1 : this.business_pk, this.is_clinic, this.reassign_to_hcs).subscribe((data:any) => {
        this.storeList = data.storeList;
        this.spinner.hide();
      }),
      (err: any) =>  {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }

  handleLeadAwaitingResponseReassignClose(){
    this.showReassignStatus=false;
    this.leadAwaitingResponseSelectedDistrict='';
    this.leadAwaitingResponseSelectedStore= "";
  }

  handleLeadAwaitingResponseReassignSubmit(){
    this.spinner.show();
    const requestData: ReassignLeadToClosestStore = {
      Pk: this.selectedLeadAwaitingResponseInfo.Pk,
      OldStoreId: this.selectedLeadAwaitingResponseInfo.ClosestStore,
      NewStoreId: Number(this.leadAwaitingResponseSelectedStore),
      updatedBy: this.userProfile.userPk
    }
    this.leadAwaitingResponseService.reassignLeadAwaitingResponse(requestData).subscribe((data) => {
      this.showReassignStatus=false;
      this.showReassignSuccessDialog = true;
      this.leadAwaitingResponseSelectedDistrict='';
      this.leadAwaitingResponseSelectedStore="";
      this.selectedLeadAwaitingResponseInfo = {} as LeadAwaitingResponse;
      this.spinner.hide();
    },
    error => {
      this.showReassignStatus=false;
      this.spinner.hide();
    })
  }

  handleLeadAwaitingResponseReassignSucess(){ 
    this.showReassignSuccessDialog = false;
    this.getLeadAwaitingResponseList();
  }

  getPriorityOpportunities(tab_index, start_number, page_index, selectionClicked=false){
    this.spinner.show();
    this.dispDlgReassign = false;
    this.dispDlgReassignToHCS = false;
    this.displayStoredlg = false;
    this.outreachstatus = "";
    this.disposition = "";
    if(!selectionClicked && this.selectOptionLocal.length <= 0) {
      this.aria_local = false;
      this.store_local = false;
      this.dist_local = false;
      this.region_local = false;
      this.selectOptionLocal = "";
      this.districtSelected = false;
      this.selectAllDist = false;
      this.districtValue = null;
      this.selectedLocalList = [];
    };
    this.handleLocalOpportunitiesFilter();
    let localLeads = new LocalLeads();
    localLeads.opportunitiesList = [];
    localLeads.userPk = this.user_profile.userPk;
    localLeads.assignedTo = 1;
    this.localLeadsPageIndex = localLeads.pageIndex = page_index;
    localLeads.filterBy = this.search_local_value;
    localLeads.filterById = this.search_from_list_local;
    localLeads.searchByValue = this.searchLocalValue;
    localLeads.sortByField = this.localLeadsSortBy;
    localLeads.sortOrder = this.localLeadsSortOrder;
    localLeads.totalCount = 0;
    localLeads.startIndex = 0;
    localLeads.finalIndex = 0;
    localLeads.noOfRecordsInPage = 50;
    this._opportunityService.getPriorityOpportunities(localLeads).subscribe((localLeadsData) => {
      this.priorityOppourtinitesSource = localLeadsData;
      this.priorityOppourtinitesTotalRecords = localLeadsData.totalCount;
      this.priorityOppourtinitesList = localLeadsData.opportunitiesList.slice(start_number, (start_number + 10));
      this.spinner.hide();
    },
    (err) => {
      this.spinner.hide();
    })
  }

  handleLocalOpportunitiesFilter () {
    switch(this.selectOptionLocal.toLocaleLowerCase()) {
      case "store" :
        this.store_local = true;
        this.dist_local = false;
        this.aria_local = false;
        this.region_local = false;
        this.districtSelected = false;
        this.districtValue = null;
        break;
      case "district":
        this.dist_local = true;
        this.store_local = false;
        this.aria_local = false;
        this.region_local = false;
        this.districtSelected = true;
        break;
      case "area":
        this.aria_local = true;
        this.store_local = false;
        this.dist_local = false;
        this.region_local = false;
        this.districtSelected = false;
        this.districtValue = null;
        break;
      case "region":
        this.aria_local = false;
        this.store_local = false;
        this.dist_local = false;
        this.region_local = true;
        this.districtSelected = false;
        this.districtValue = null;
        break;
      default: 
        this.aria_local = false;
        this.store_local = false;
        this.dist_local = false;
        this.region_local = false;
        this.districtSelected = false;
        this.selectOptionLocal = "";
        this.districtValue = null;
    }
  }

  handleChartData(){
    forkJoin([this.chartService.getOpportunitiesRequiringAction({userId: this.user_profile.userPk, outreachEffortId: 3}), this.chartService.getPriorityOpportunitiesRequiringAction({userId: this.user_profile.userPk, outreachEffortId: 3})]).subscribe((data: any) => {
      this.opportunitiesActionApiData = data[0];
      this.priorityOpportunitiesActionApiData = data[1];
      let olables = data[0].map(val => val.description);
      let odata = data[0].map(val => val.RecordCount);
      this.opportunitiesActionData.labels = olables;
      this.opportunitiesActionData.datasets[0].data = odata;
      let plables = data[1].map(val => val.description);
      let pdata = data[1].map(val => val.RecordCount);
      this.opportunitiesActionPie = new Chart('opportunitiesActionPie', {
      type: 'doughnut',
      data: this.opportunitiesActionData,
      options: {
        radius: 180,
        layout: {
          padding: {
            top: 100
          }
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        responsive: true,
        onClick	:  this.handleOpportunityDougnnutClick.bind(this),
        plugins: {
          datalabels:{
            formatter: function(value, ctx) {
              const total: number| any = ctx.dataset.data.reduce((accumulator:number, currentValue:number) => accumulator + currentValue) ;
              if(Number((value / total * 100).toFixed()) > 0) {
                return `${(value / total * 100).toFixed()}%`;
              } else {
                return ""
              }
            },
            color: "#fff"
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              label: function(context) {
                let currentValue:any = context.dataset.data[context.dataIndex];
                const total: number| any = context.dataset.data.reduce((accumulator:number, currentValue:number) => accumulator + currentValue) ;
                return `${context.label}\n ${currentValue}  (${(currentValue / total * 100).toFixed()}%)`;
              }
            }
          },
          legend: {
            display: true,
            position: 'chartArea',
            align: 'start',
            labels: {
              usePointStyle: true,
              boxWidth: 10,
              boxHeight	: 8,
              padding: 8,
              // filter: (legendItem: any, data) => {
              //   let currentValue:any = data.datasets[0].data[legendItem.index];
              //   const total: number| any = data.datasets[0].data.reduce((accumulator:number, currentValue:number) => accumulator + currentValue) ;
              //   let per_val =  (currentValue / total * 100).toFixed();
              //   return Number(per_val) != 0;
              // }
            },
            onHover: (e: any ,chartElement ) => {
              e.native.target.style.cursor = "pointer"
            },
            onClick: this.handleOpportunityDougnnutClick.bind(this),
          },
        },
      }, 
      // fill: true
      });

      this.priorityOportunitiesActionData.labels = plables;
      this.priorityOportunitiesActionData.datasets[0].data = pdata;
      this.priorityOpportunitiesActionPie = new Chart('priorityOpportunitiesActionPie', {
        type: 'doughnut',
        data: this.priorityOportunitiesActionData,
        options: {
          layout: {
            padding: {
              top: 100
            }
          },
          onClick	:  this.handlePriorityOpportunityDougnnutClick.bind(this),
          radius: 180,
          elements: {
            arc: {
              borderWidth: 0
            }
          },
          responsive: true,
          plugins: {
            datalabels:{
              formatter: function(value, ctx) {
                const total: number| any = ctx.dataset.data.reduce((accumulator:number, currentValue:number) => accumulator + currentValue) ;
                if(Number((value / total * 100).toFixed()) > 0) {
                  return `${(value / total * 100).toFixed()}%`;
                } else {
                  return "";
                }
              },
              color: "#fff",
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                label: function(context) {
                  let currentValue: any = context.dataset.data[context.dataIndex];
                  const total: number| any = context.dataset.data.reduce((accumulator:number, currentValue:number) => accumulator + currentValue) ;
                  return `${context.label} \n ${currentValue}  (${(currentValue / total * 100).toFixed()}%)`;
                }
              }
            },
            legend: {
              display: true,
              position: 'chartArea',
              align: 'start',
              labels: {
                usePointStyle: true,
                boxWidth: 10,
                boxHeight: 8,
                padding: 8,
                // filter: (legendItem: any, data) => data.datasets[0].data[legendItem.index] != 0
              },
              onHover: (e: any ,chartElement ) => {
                e.native.target.style.cursor = "pointer"
              },
              onClick: this.handlePriorityOpportunityDougnnutClick.bind(this),
            }
          }
        }
        // fill: true
      });
      this.spinner.hide();
    });
  }

  handleOpportunityDougnnutClick(event, legendItem, legend){
    let idx: number;
    if(legendItem?.[0])
      idx =legendItem[0]?.index;
    else 
      idx = legendItem?.index;
    let oApiData = this.opportunitiesActionApiData[idx];
    SessionDetails.SetOpportunityRequiringActionMetricId(oApiData);
    this.router.navigate(['/communityoutreach/immunizationprograms/oppourtinityrequiringaction']);
  }

  handlePriorityOpportunityDougnnutClick(event, legendItem, legend){
    let idx:number;
    if(legendItem?.[0])
      idx =legendItem[0].index;
    else 
      idx = legendItem?.index;
    let pApiData = this.priorityOpportunitiesActionApiData[idx];
    SessionDetails.SetPriorityOpportunityRequiringActionMetricId(pApiData);
    this.router.navigate(['communityoutreach/immunizationprograms/priorityoppourtinityrequiringaction']);
  }
}