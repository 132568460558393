
import { debounceTime } from 'rxjs/operators';
import { Component, OnInit, Inject, PLATFORM_ID, APP_ID, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { OpportunitiesService } from '../../../store/services/opportunities.service';
import { FilterPipe } from '../../../store/pipes/filter-pipe';
import { AppCommonSession } from '../../app-common-session';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { SessionDetails } from '../../../../utility/session';
import { environment } from '../../../../../environments/environment';
import { HeaderDetails } from '../../../../JSON/HeaderDetails';
import { ActionType, ProgramType } from '../../../../utility/enum';
import { Opportunities } from '../../../../models/opportunities';
import { Util } from '../../../../utility/util';
import { ScheduleeventService } from '../../../store/services/scheduleevent.service';
import { FollowUp } from '../../../../models/FollowUp';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { HeaderserviceService } from '../../services/headerservice.service';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { AlertService } from '../../../../alert.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { RestrictedStatesAllowedUsers } from '../../../../config-files/config-settings';
import { SelectType } from '../../../../models/OutreachOpportunity';
import { LookupService } from '../../../../lookup.service';
import { noClientInterestOptions } from '../../../../JSON/NoClientInterestOptions';

@Component({
  selector: 'app-store-opportunity',
  templateUrl: './store-opportunity.component.html',
  styleUrls: ['./store-opportunity.component.css'],
  providers: [OpportunitiesService, FilterPipe, TimeToDatePipe, DatePipe]
})
export class StoreOpportunityComponent implements OnInit {
  private subject: Subject<string> = new Subject();
  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
  turnOffHintss: boolean = false;
  pageName: string = "storehome";
  opportunitiesDataList: any[] = [];
  opportunitiesFullDataList: any[] = [];
  opportunities: any[] = [];
  scheduleClinics: any[] = [];
  upcomingEvents: any[] = [];
  pastEvents: any[] = [];
  storeStatus: any[] = [];
  dispBlockVrbl: boolean = false;
  cssColor: string;
  clickType: string;
  bsClasses: string;
  display: boolean = false;
  dispSchedulerMenu: boolean = false;
  displayInfoDlg: boolean = false;
  displayInfoDlgUpcomingEvents: boolean = false;
  displaySE: boolean = false;
  dncClients: any[] = [];
  dncTempClients: any[] = [];
  moment: any;
  dispDNCsearch: boolean = false;
  col6Cls: boolean = false;
  dialogMsg: string;
  getData: any;
  sortBy: string;
  opportunitiesData: any;
  searchText: any;
  dialogOptConLog: boolean = false;
  outreachStatus: any[] = [];
  outreachStatusData: any;
  businessContacted: any;
  defaultDate: Date = new Date();
  maxDateValue: Date = new Date();
  minDateValue: Date = new Date('Mon May 01 2017 00:00:00');
  outreachstatus: any;
  errorMessage: any;
  firstName: string;
  lastName: string;
  feedback: any = '';
  logcontact: FormGroup;
  message: any;
  selectedCountry: string;
  outreachType: any;
  outreachTypeData: any;
  outreachStatusInfo: any;
  outreachStatusValue: any = "";
  opportunities1: any[] = [];
  firstDate: Date
  secondDate: Date;
  outreachStartDateIP: string = "05/01/2016";
  message1: any;
  storePharUser: boolean = false;
  subscription: Subscription;
  businessName: string;
  jobTitle: boolean = false;
  storeProfile: any;
  displayMessage: boolean = false;
  dispContractInitiated: boolean = false;
  eventDetails: any;
  contractInitiated: boolean = false;
  editBuisnessData: any;
  contractFollowup: boolean = false;
  selectedDNCData: any[] = [];
  selectedDataList: any[] = [];
  displayDNC: boolean = false;
  defaultValue: string;
  opportunityForm: FormGroup;
  storeID: any;
  status: boolean = true;
  goTo: string = '';
  stateSpecific: string;
  stateSpecificDisplay: boolean = false;
  userProfile: any;
  DNCError: boolean = false;
  CIError: boolean = false;
  disable_button: boolean = false;
  showCorporateMenu: boolean = false;
  targetObj: any;
  storehome: boolean = false;
  pcahome: boolean = false;
  srhome: boolean = false;
  COVIDHome: boolean = false;
  title: string = '';
  display1: boolean = false;
  pcaDisplay: boolean = false;
  appOpp: boolean = false;
  assignedBusinessTitle: any = 'Outreach Opportunities';
  sortByKey = SessionDetails.GetStoreOppSortByDefaultVal();
  dialogPCAContactLog: boolean = false;
  eventListData: any[] = [];
  dialogOptConLogforPCA: boolean = false;
  displayMessageForPCA: boolean = false;
  firstdispHintDialog: boolean = false;
  ASOIntiaited: boolean = false;
  GovIntiaited: boolean = false;
  opoIntiaited: boolean = false;
  cssIntiaited: boolean = false;
  pendingRHDApproved: boolean = false;
  errorPopUp: boolean = false;
  edit_profile_text: any = 'Opportunity Profile';
  dncFilter: string = null;
  restrictedStatesOverride: boolean = false;
  attentionAlert: boolean = true;
  NCIOptions:string[] = [];
  showNCIOptions: boolean = false;
  showFeedbackNotes: boolean = true;
  isAdmin: boolean = false;
  businessType: number;
  industries: SelectType[];
  canDisplayOutreachStatus: boolean = false;


  constructor(
    private _sortByPipe: FilterPipe, 
    private formBuilder: FormBuilder, 
    private _opportunityService: OpportunitiesService, 
    private router: Router,
    private commonsession: AppCommonSession, 
    private message_service: MessageServiceService, 
    private schedule_event_service: ScheduleeventService, 
    private spinner: NgxSpinnerService, 
    private opportunitie: Opportunities,
    private _fb: FormBuilder, 
    private _datePipe: DatePipe, 
    private utility: Util,
    private _headerservice: HeaderserviceService, 
    private _timeToDatePipe: TimeToDatePipe,
    private _alertService: AlertService, 
    private titleService: Title, 
    @Inject(PLATFORM_ID) private platformId: Object, 
    @Inject(APP_ID) private appId: string,
    private _lookupService: LookupService,
    private _ref: ChangeDetectorRef
  ) {
    if (this.showHints) {
      this.showHints = false;
      this.firstdispHintDialog = true;
    }

    this.message_service.getShowHintsOnToggleChange().subscribe(message => {
      this.showHints = false;
      this.turnOffHintss = false;
      this.firstdispHintDialog = true;
    });
    
    this.scrollToTop();
    this.userProfile = SessionDetails.GetUserProfile();
    SessionDetails.EventDetails("");
    SessionDetails.opportunitiesData("");
    SessionDetails.SetLogContact("");
    SessionDetails.SetBusinessType("");
    SessionDetails.SetPageName("");
    SessionDetails.SetAgreementPK(0);
    SessionDetails.SetFollowUp(false);
    SessionDetails.fromLocalclinics(false);
    SessionDetails.fromUserPage(false);

    if (this.sortBy == '4' || this.sortBy == '6' || SessionDetails.GetStoreOppSortByDefaultVal() == 5 || SessionDetails.GetStoreOppSortByDefaultVal() == 6) {
      this.attentionAlert = false;
    }
    //this.showCorporateMenu = false;//[41, 18823].indexOf(this.userProfile.userPk) > -1;

    //this.user_info = this.commonsession.login_info;
    this.status = true;
    this.subscription = this.message_service.getStoreID().subscribe(message => {
      this.message = message;
      this.storeID = this.message.text;
      if (this.storeID != "") {
        this.searchText = "";
        this.spinner.show();
        this.getAssignedBusiness(this.storeID);
        this.message.status = false;

        setTimeout(() => {
          this.selectName(this.opportunityForm.value.defaultSortBY);
          this.spinner.hide();
          //console.log("timeout complete #382");
        }, 5000);
      }
    });

    this.title = this.getTitle(this.router.routerState, this.router.routerState.root).join();
    this.titleService.setTitle(this.title);

    if (this.title == "storehome") {
      this.storehome = true;
      this.attentionAlert = true;
      this.COVIDHome = true;
    }

    if (this.title == "pcahome") {
      SessionDetails.setIsPCA(true);
      this.pcahome = true;
      this.attentionAlert = false;
    }

    if (this.title == "srhome") {
      SessionDetails.setIsSR(true);
      this.srhome = true;
      this.storehome = true;
      this.attentionAlert = false;
    }

    if (this.title == "covidhome") {
      SessionDetails.setIsCOVID(true);
      this.COVIDHome = true;
      this.storehome = true;
      this.attentionAlert = false;
    }
  }

  ngOnInit() {
    this.spinner.show();
    let isProcessValue = sessionStorage["NewLogEntry"];
    let outreachBbusinesssPKforUpdate =sessionStorage["outreachBusinessPkForLog"];

    if(isProcessValue == 1)
    {
      this.schedule_event_service.updateStatusEvents(outreachBbusinesssPKforUpdate)
      .subscribe((data: any) => {
          if (data.errorS == null) {
            sessionStorage["NewLogEntry"] = 0;
          }
        },
        err => {
          this.disable_button = false;
        }
      );
    }

    this.NCIOptions = noClientInterestOptions;
    this.subject.pipe(debounceTime(1000))
        .subscribe(searchTextValue => {
          this.handleSearch(searchTextValue);
        });

    if (sessionStorage["AmendAgreement"] != null){
      sessionStorage.removeItem("AmendAgreement");
    }
      
    if (sessionStorage["AmendAgreementForCommOutreach"] != null){
      sessionStorage.removeItem("AmendAgreementForCommOutreach");
    }    

    if (sessionStorage["IsEquityAmendAgreement"] != null){
      sessionStorage.removeItem("IsEquityAmendAgreement");
    }

    if (sessionStorage["IsCommunityOutreachAmendAgreement"] != null){
      sessionStorage.removeItem("IsCommunityOutreachAmendAgreement");
    }

    if (sessionStorage["NewAmendAgreement"] != null){
      sessionStorage.removeItem("NewAmendAgreement");
    }

    if (sessionStorage["OldImmunizations"] != null){
      sessionStorage.removeItem("OldImmunizations");
    }

    if (sessionStorage["clinicList"] != null){
      sessionStorage.removeItem("clinicList");
    }

    if (sessionStorage["isStoreBusinessClinicAdded"] != null){
      sessionStorage.removeItem("isStoreBusinessClinicAdded");
    }

    if (SessionDetails.getIsPCA()) {
      this.pcahome = true;
      this.storehome = false;
      this.srhome = false;
      this.assignedBusinessTitle = 'PCA Organizations';
    }
    else if (SessionDetails.getIsSR()) {
      this.pcahome = false;
      this.storehome = true;
      this.srhome = true;
    }
    let sortBy = SessionDetails.GetStoreOppSortByDefaultVal();
    this.opportunityForm = this.formBuilder.group({
      //defaultSortBY: ["8", Validators.required]//this.pcahome == true ? "6" : (this.srhome==true?"5" : "8")
      defaultSortBY: this.pcahome == true ? "6" : (this.srhome == true ? "5" : sortBy == "0" ? 7 : sortBy)
    });
    var date = new Date();
    var test = date.getTime();
    this.searchText = ""
    this.logcontact = this._fb.group({
      select_outreach: this._fb.control('', Validators.required),
      businessName: this._fb.control('', Validators.required),
      firstName: this._fb.control('', Validators.required),
      lastName: this._fb.control('', Validators.required),
      jobTitle: this._fb.control('', Validators.required),
      defaultDate: this._fb.control('', Validators.required),
      outreachstatus: this._fb.control('', Validators.required),
      feedback: this._fb.control('', Validators.required),
      Disposition: this._fb.control('', null),
      industry: this._fb.control('', null),
      other: this._fb.control('', null)
    });
    this.outreachstatus = 0;
    //this.user_info = this.commonsession.login_info;
    this.getData = environment;
    this.storeProfile = SessionDetails.GetStoreId();
    this.stateSpecific = SessionDetails.GetState();
    // if (this.storeProfile != null)
    var userProfile = SessionDetails.GetUserProfile();
    if (userProfile.userRole.toLowerCase() == "pharmacy manager" || userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "admin") {
      this.storePharUser = true;
    }

    for (let i = 0; i < this.storeStatus.length; i++) {
      if (this.storeStatus.length >= 5 && this.storeStatus[i].statusCountByStore > 0 && this.storeStatus.length >= 5 && this.storeStatus[i].statusCountByUser > 0) {
        this.col6Cls = true;
      }
    }
    //Pending from getcontractagreement service
    if (this.storeProfile == null) {
      this._headerservice.getStoresProfile(userProfile.userPk, null, null).subscribe((res: any) => {
        if (res.errorS && res.errorS.errorCode === -1) {
          this.errorPopUp = true;
          this.dialogMsg = ErrorMessages['noStoreAssigned'];
          this.spinner.hide();
        }
        else {
          SessionDetails.setStoreProfileData(res.data);
          SessionDetails.StoreId(res.data[0].storeId);
          SessionDetails.StorePk(res.data[0].storePk);
          SessionDetails.setState(res.data[0].state);
          this.getAssignedBusiness(SessionDetails.GetStoreId());
        }
      });
    }
    else {
      this.getAssignedBusiness(this.storeProfile);
    }
    //this.getAssignedBusiness(this.storeProfile);
    this._alertService.clear();
    if (this.sortBy == '4' || this.sortBy == '6' || SessionDetails.GetStoreOppSortByDefaultVal() == 5 || SessionDetails.GetStoreOppSortByDefaultVal() == 6) {
      this.attentionAlert = false;
    }
    
    setTimeout(() => {
      this.selectName(this.opportunityForm.value.defaultSortBY);
      this.spinner.hide();
    }, 5000);
    this.isAdmin = ["admin_communityupdate", "admin_samuel.nass", "admin_jerry.v.thomas", "admin_bryan.shrode","admin_enterpriseimmunizationservice","admin_eli.zaguri","admin_tyrone.clark","admin_roxanne.crowley"].filter(item=>item==userProfile.userName.toLowerCase()).length>0;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  getNationalContractClients() {
    //console.log(String.Format("Started calling this._opportunityService.getNationalContractClients at {0}:{1}:{2}:{3}", startTime.getHours(), startTime.getMinutes(), startTime.getSeconds(), startTime.getMilliseconds()));

    //Gets Do Not Call Clients
    this._opportunityService.getNationalContractClients().subscribe((res: any) => {
      //console.log(String.Format("Ending this._opportunityService.getNationalContractClients() at {0}:{1}:{2}:{3}", endTime.getHours(), endTime.getMinutes(), endTime.getSeconds(), endTime.getMilliseconds()));
      this.dncClients = res
      this.dncTempClients = this.dncClients;
    });
  }
  getAssignedBusiness(store_id) {
    this.searchText = "";
    this.opportunitiesFullDataList = [];
    this.storeProfile = SessionDetails.GetStoreId();
    let sortBy = SessionDetails.GetStoreOppSortByDefaultVal();
    this.opportunityForm = this.formBuilder.group({
      //defaultSortBY: [ "8", Validators.required]//this.pcahome == true ? "6" : (this.srhome==true?"5" :
      defaultSortBY: this.pcahome == true ? "6" : (this.srhome == true ? "5" : sortBy == "0" ? 7 : sortBy)
    });
    
    this.opportunitiesDataList = [];
    this.scheduleClinics = [];
    this.upcomingEvents = [];
    this.pastEvents = [];
    this.eventListData = [];

    let startTime: Date = new Date();
    //console.log(`Started calling this._opportunityService.getOpportunities at ${startTime.getHours()}:${startTime.getMinutes()}:${startTime.getSeconds()}:${startTime.getMilliseconds()}`);
    this._opportunityService.getOpportunities(store_id, this.userProfile.userPk, this.userProfile.userRole)
      .subscribe(
        (opportunities: any) => {
          let endTime: Date = new Date();
          //console.log(`Ending this._opportunityService.getOpportunities at ${endTime.getHours()}:${endTime.getMinutes()}:${endTime.getSeconds()}:${endTime.getMilliseconds()}`);
          this.storeStatus = opportunities.workflowMonitorStatusList;
          if (this.storeStatus.length < 4) {
            let goal_count = HeaderDetails.to_goal;
            let completed_count = HeaderDetails.to_complete;
            this.storeStatus.push({ "outreachStatusId": 5, "outreachStatus": "Schedule SR Events", "statusCountByStore": (goal_count - completed_count), "statusCountByDist": null })
          }
          let upcoming_events;
          let past_events;
          let today = this._datePipe.transform(new Date(), 'MM/dd/yyyy');

          //Opportunities
          this.eventListData = opportunities.eventsList;
          // if (this.pcahome) {
          //   this.scheduleClinics = opportunities.eventsList.filter(pk => pk.outreachPk == 4);
          // }
          // else {
          //   this.scheduleClinics = opportunities.eventsList.filter(pk => pk.outreachPk != 4);
          // }
          this.scheduleClinics = opportunities.eventsList;

          //upcoming_events = this.scheduleClinics.filter(item => new Date(this._datePipe.transform(item.eventDate, 'MM/dd/yyyy')) >= new Date(today));
          upcoming_events = this.scheduleClinics.filter(function (item) {
            if (item.eventDate && item.eventDate != '0001-01-01T00:00:00' && item.isNoClinic != true) {
              var date_string = item.eventDate.substring(0, item.eventDate.indexOf('T'));
              date_string = date_string.split('-');
              date_string = date_string[1] + "/" + date_string[2] + "/" + date_string[0];
              var temp = new Date();
              if ((new Date(date_string)) >= (new Date((temp.getMonth() + 1) + "/" + temp.getDate() + "/" + temp.getFullYear()))) {
                return true;
              }
            }
            else if (item.isNoClinic == true && item.outreachStatusTitle == "Contact Client") {
              item.eventDate = null;
              return true;
            }
          });
          // if(this.userProfile.userRole !== "Admin"){
          //   upcoming_events = upcoming_events.filter(item => item.covidOpportunityType !=1);
          // }
          //past_events = this.scheduleClinics.filter(item => new Date(this._datePipe.transform(item.eventDate, 'MM/dd/yyyy')) < new Date(today));
          past_events = this.scheduleClinics.filter(function (item) {
            if (item.eventDate && item.eventDate != '0001-01-01T00:00:00' && item.isNoClinic != true) {
              var date_string = item.eventDate.substring(0, item.eventDate.indexOf('T'));
              date_string = date_string.split('-');
              date_string = date_string[1] + "/" + date_string[2] + "/" + date_string[0];
              var temp = new Date();
              if ((new Date(date_string)) < (new Date((temp.getMonth() + 1) + "/" + temp.getDate() + "/" + temp.getFullYear()))) {
                return true;
              }
            }
            else if (item.isNoClinic == true && (item.outreachStatusTitle == "Confirmed" || item.outreachStatusTitle == "Completed" || item.outreachStatusTitle == "Cancelled")) {
              item.eventDate = null;
              return true;
            }
            else if (item.eventDate == null && upcoming_events.findIndex(f => f.clinicPk == item.clinicPk && f.contactLogPk == item.contactLogPk) < 0) {
              return true;
            }
          });
          // if(this.userProfile.userRole !== "Admin"){
          //   past_events = past_events.filter(item => item.covidOpportunityType !=1);
          // }

          this.upcomingEvents = this._sortByPipe.sortByUpcoming(upcoming_events, "eventDate", "eventDate");
          this.pastEvents = this._sortByPipe.sortBy(past_events, "eventDate", "eventDate");

          this.pastEvents.forEach(item => {
            if (item.clinicType == "HD") {
              item["clinicTypeCode"] = 10;
            }
            else if (item.clinicType == "ASO") {
              item["clinicTypeCode"] = 8;
            }
            else if (item.clinicType == "CBO") {
              item["clinicTypeCode"] = 9;
            }
            else if (item.clinicType == "OPO") {
              item["clinicTypeCode"] = 11;
            }
            else if (item.clinicType == "CSS") {
              item["clinicTypeCode"] = 12;
            }
            if (item.clinicType == 'Uploaded Local' || item.clinicType == 'Uploaded Charity' || item.clinicType == 'Corporate') {
              item.businessName = item.businessName.replace('-', '- CLINIC');
            }
            item.clinicType = (item.clinicType == 'ASO' || item.clinicType == 'CBO'
              || item.clinicType == 'HD' || item.clinicType == 'OPO' || item.clinicType == 'CSS') ? "Testing Event" : item.clinicType;
            item.eventDate == '0001-01-01T00:00:00' || null ? null : item.eventDate;
            if (item.eventDate) {
              var date_string = item.eventDate.substring(0, item.eventDate.indexOf('T'));
              date_string = date_string.split('-');
              date_string = date_string[1] + "/" + date_string[2] + "/" + date_string[0];
              item.eventDate = date_string;
            }
            // if (item.visitType && item.visitType.length > 0) {
            //   item.businessName = item.businessName + ', ' + item.visitType;
            // }
          });
          this.upcomingEvents.forEach(item => {
            if (item.clinicType == "HD") {
              item["clinicTypeCode"] = 10;
            }
            else if (item.clinicType == "ASO") {
              item["clinicTypeCode"] = 8;
            }
            else if (item.clinicType == "CBO") {
              item["clinicTypeCode"] = 9;
            }
            else if (item.clinicType == "OPO") {
              item["clinicTypeCode"] = 11;
            }
            else if (item.clinicType == "CSS") {
              item["clinicTypeCode"] = 12;
            }
            if (item.clinicType == 'Uploaded Local' || item.clinicType == 'Uploaded Charity' || item.clinicType == 'Corporate') {
              item.businessName = item.businessName.replace('-', '- CLINIC');
            }
            item.clinicType = (item.clinicType == 'ASO' || item.clinicType == 'CBO' || item.clinicType == 'HD') ? "Testing Event" : item.clinicType;
            item.clinicType = (item.clinicType == 'OPO') ? "Registration Event" : item.clinicType;
            item.clinicType = (item.clinicType == 'CSS') ? "Screening Event" : item.clinicType;
            item.eventDate == '0001-01-01T00:00:00' ? null : item.eventDate;
            if (item.eventDate) {
              var date_string = item.eventDate.substring(0, item.eventDate.indexOf('T'));
              date_string = date_string.split('-');
              date_string = date_string[1] + "/" + date_string[2] + "/" + date_string[0];
              item.eventDate = date_string;
            }
            // if (item.visitType && item.visitType.length > 0) {
            //   item.businessName = item.businessName + ', ' + item.visitType;
            // }
          });
          this.defaultValue = "1";
          this.opportunities = this._sortByPipe.sortBy(opportunities.opportunityList, "isPreviousClient");

          let loopstartTime: Date = new Date();
          //console.log(`Started looping for UI binding at ${loopstartTime.getHours()}:${loopstartTime.getMinutes()}:${loopstartTime.getSeconds()}:${loopstartTime.getMilliseconds()}`);


          for (var i = 0; i < this.opportunities.length; i++) {
            var opportunitiesData = new Opportunities();
            opportunitiesData.businessPk = this.opportunities[i].businessPk;
            opportunitiesData.businessName = this.opportunities[i].businessName;
            opportunitiesData.phone = this.opportunities[i].phone;
            opportunitiesData.industry = this.opportunities[i].industry;
            opportunitiesData.others = this.opportunities[i].others;
            opportunitiesData.employmentSize = this.opportunities[i].employmentSize;
            opportunitiesData.isMilitaryOpportunity = this.opportunities[i].isMilitaryOpportunity;
            opportunitiesData.jobTitle = this.opportunities[i].jobTitle;
            opportunitiesData.firstName = this.opportunities[i].firstName;
            opportunitiesData.lastName = this.opportunities[i].lastName;
            opportunitiesData.isPreviousClient = this.opportunities[i].isPreviousClient;
            opportunitiesData.isInterestClickedInRetentionEmail = this.opportunities[i].isInterestClickedInRetentionEmail;
            opportunitiesData.isTopPriorityFromLead = this.opportunities[i].isTopPriorityFromLead;
            opportunitiesData.isHighPriority = this.opportunities[i].isHighPriority;
            opportunitiesData.isStandardized = this.opportunities[i].isStandardized;
            opportunitiesData.organizationType = this.opportunities[i].organizationType;
            opportunitiesData.isCovidOpportunity = this.opportunities[i].isCovidOpportunity;
            opportunitiesData.isFluOpportunity = this.opportunities[i].isFluOpportunity;
            opportunitiesData.isNoContractOpportunity = this.opportunities[i].isNoContractOpportunity;
            opportunitiesData.covidOpportunityType = this.opportunities[i].covidOpportunityType;
            opportunitiesData.fluOpportunityType = this.opportunities[i].fluOpportunityType;
            opportunitiesData.businessType = this.opportunities[i].businessType;
            if (this.opportunities[i].outreachEffortList.length >= 1) {
              if (this.opportunities[i].outreachEffortList[0].outreachProgram == "SR") {
                opportunitiesData.isPCA = false;
                opportunitiesData.outreachEffort.SR.outreachStatusTitle = this.opportunities[i].outreachEffortList[0].outreachStatusTitle;
                opportunitiesData.outreachEffort.SR.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;
                opportunitiesData.outreachEffort.SR.outreachProgram = this.opportunities[i].outreachEffortList[0].outreachProgram;
                opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;//this.opportunities[i].outreachEffortList[0].lastContact;
                opportunitiesData.outreachEffort.SR.outreachStatus = this.opportunities[i].outreachEffortList[0].outreachStatus;
                opportunitiesData.outreachEffort.SR.isScheduledClinics = this.opportunities[i].outreachEffortList[0].isScheduledClinics;
                opportunitiesData.outreachEffort.SR.outreachBusinessPk = this.opportunities[i].outreachEffortList[0].outreachBusinessPk;
                opportunitiesData.outreachEffort.SR.contactLogPk = this.opportunities[i].outreachEffortList[0].contactLogPk;
                opportunitiesData.outreachEffort.SR.clinicAgreementPk = this.opportunities[i].outreachEffortList[0].clinicAgreementPk;
                //  opportunitiesData.outreachEffort.SR.organizationType = null;
              }

              if (this.opportunities[i].outreachEffortList[0].outreachProgram == "IP") {
                opportunitiesData.isPCA = false;
                opportunitiesData.outreachEffort.IP.outreachStatusTitle = this.opportunities[i].outreachEffortList[0].outreachStatusTitle;
                opportunitiesData.outreachEffort.IP.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;
                opportunitiesData.outreachEffort.IP.outreachProgram = this.opportunities[i].outreachEffortList[0].outreachProgram;
                opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;//this.opportunities[i].outreachEffortList[0].lastContact;
                opportunitiesData.outreachEffort.IP.outreachStatus = this.opportunities[i].outreachEffortList[0].outreachStatus;
                opportunitiesData.outreachEffort.IP.isScheduledClinics = this.opportunities[i].outreachEffortList[0].isScheduledClinics;
                opportunitiesData.outreachEffort.IP.outreachBusinessPk = this.opportunities[i].outreachEffortList[0].outreachBusinessPk;
                opportunitiesData.outreachEffort.IP.contactLogPk = this.opportunities[i].outreachEffortList[0].contactLogPk;
                opportunitiesData.outreachEffort.IP.clinicAgreementPk = this.opportunities[i].outreachEffortList[0].clinicAgreementPk;
                // opportunitiesData.outreachEffort.IP.organizationType = null;
              }


              if (this.opportunities[i].outreachEffortList[0].outreachProgram == "PCA") {
                opportunitiesData.isPCA = true;
                opportunitiesData.outreachEffort.PCA.outreachStatusTitle = this.opportunities[i].outreachEffortList[0].outreachStatusTitle;
                opportunitiesData.outreachEffort.PCA.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;
                opportunitiesData.outreachEffort.PCA.outreachProgram = this.opportunities[i].outreachEffortList[0].outreachProgram;
                opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;//this.opportunities[i].outreachEffortList[0].lastContact;
                opportunitiesData.outreachEffort.PCA.outreachStatus = this.opportunities[i].outreachEffortList[0].outreachStatus;
                opportunitiesData.outreachEffort.PCA.isScheduledClinics = this.opportunities[i].outreachEffortList[0].isScheduledClinics;
                opportunitiesData.outreachEffort.PCA.outreachBusinessPk = this.opportunities[i].outreachEffortList[0].outreachBusinessPk;
                opportunitiesData.outreachEffort.PCA.contactLogPk = this.opportunities[i].outreachEffortList[0].contactLogPk;
                opportunitiesData.outreachEffort.PCA.clinicAgreementPk = this.opportunities[i].outreachEffortList[0].clinicAgreementPk;
                //  opportunitiesData.outreachEffort.PCA.organizationType = 3;
              }
            }

            if (this.opportunities[i].outreachEffortList.length > 1) {
              if (this.opportunities[i].outreachEffortList[1].outreachProgram == "IP") {
                opportunitiesData.isPCA = false;
                opportunitiesData.outreachEffort.IP.outreachStatusTitle = this.opportunities[i].outreachEffortList[1].outreachStatusTitle;
                opportunitiesData.outreachEffort.IP.lastContact =
                  this.opportunities[i].outreachEffortList[1].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[1].lastContact;//this.opportunities[i].outreachEffortList[1].lastContact;
                opportunitiesData.outreachEffort.IP.outreachProgram = this.opportunities[i].outreachEffortList[1].outreachProgram;
                opportunitiesData.outreachEffort.IP.outreachStatus = this.opportunities[i].outreachEffortList[1].outreachStatus;
                opportunitiesData.outreachEffort.IP.isScheduledClinics = this.opportunities[i].outreachEffortList[1].isScheduledClinics;
                opportunitiesData.outreachEffort.IP.outreachBusinessPk = this.opportunities[i].outreachEffortList[1].outreachBusinessPk;
                opportunitiesData.outreachEffort.IP.contactLogPk = this.opportunities[i].outreachEffortList[1].contactLogPk;
                opportunitiesData.outreachEffort.IP.clinicAgreementPk = this.opportunities[i].outreachEffortList[1].clinicAgreementPk;
                //opportunitiesData.outreachEffort.IP.organizationType = null;
                if (this.opportunities[i].outreachEffortList[0].lastContact == this.opportunities[i].outreachEffortList[1].lastContact) {
                  opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;
                }
                if (new Date(this.opportunities[i].outreachEffortList[0].lastContact) > new Date(this.opportunities[i].outreachEffortList[1].lastContact)) {
                  opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;
                }
                if (new Date(this.opportunities[i].outreachEffortList[1].lastContact) > new Date(this.opportunities[i].outreachEffortList[0].lastContact)) {
                  opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[1].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[1].lastContact;
                }
              }
              if (this.opportunities[i].outreachEffortList[1].outreachProgram == "SR") {
                opportunitiesData.isPCA = false;
                opportunitiesData.outreachEffort.SR.outreachStatusTitle = this.opportunities[i].outreachEffortList[1].outreachStatusTitle;
                opportunitiesData.outreachEffort.SR.lastContact = this.opportunities[i].outreachEffortList[1].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[1].lastContact;
                opportunitiesData.outreachEffort.SR.outreachProgram = this.opportunities[i].outreachEffortList[1].outreachProgram;
                opportunitiesData.outreachEffort.SR.outreachStatus = this.opportunities[i].outreachEffortList[1].outreachStatus;
                opportunitiesData.outreachEffort.SR.isScheduledClinics = this.opportunities[i].outreachEffortList[1].isScheduledClinics;
                opportunitiesData.outreachEffort.SR.outreachBusinessPk = this.opportunities[i].outreachEffortList[1].outreachBusinessPk;
                opportunitiesData.outreachEffort.SR.contactLogPk = this.opportunities[i].outreachEffortList[1].contactLogPk;
                opportunitiesData.outreachEffort.SR.clinicAgreementPk = this.opportunities[i].outreachEffortList[1].clinicAgreementPk;
                //opportunitiesData.outreachEffort.SR.organizationType = null;
                if (this.opportunities[i].outreachEffortList[0].lastContact == this.opportunities[i].outreachEffortList[1].lastContact) {
                  opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[0].lastContact = '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;
                }
                if (new Date(this.opportunities[i].outreachEffortList[0].lastContact) > new Date(this.opportunities[i].outreachEffortList[1].lastContact)) {
                  opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;
                }
                if (new Date(this.opportunities[i].outreachEffortList[1].lastContact) > new Date(this.opportunities[i].outreachEffortList[0].lastContact)) {
                  opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[1].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[1].lastContact;
                }
              }
              if (this.opportunities[i].outreachEffortList[1].outreachProgram == "PCA") {
                opportunitiesData.isPCA = true;
                opportunitiesData.outreachEffort.PCA.outreachStatusTitle = this.opportunities[i].outreachEffortList[1].outreachStatusTitle;
                opportunitiesData.outreachEffort.PCA.lastContact = this.opportunities[i].outreachEffortList[1].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[1].lastContact;
                opportunitiesData.outreachEffort.PCA.outreachProgram = this.opportunities[i].outreachEffortList[1].outreachProgram;
                opportunitiesData.outreachEffort.PCA.outreachStatus = this.opportunities[i].outreachEffortList[1].outreachStatus;
                opportunitiesData.outreachEffort.PCA.isScheduledClinics = this.opportunities[i].outreachEffortList[1].isScheduledClinics;
                opportunitiesData.outreachEffort.PCA.outreachBusinessPk = this.opportunities[i].outreachEffortList[1].outreachBusinessPk;
                opportunitiesData.outreachEffort.PCA.contactLogPk = this.opportunities[i].outreachEffortList[1].contactLogPk;
                opportunitiesData.outreachEffort.PCA.clinicAgreementPk = this.opportunities[i].outreachEffortList[1].clinicAgreementPk;
                opportunitiesData.lastContact = this.opportunities[i].outreachEffortList[0].lastContact == '0001-01-01T00:00:00' ? null : this.opportunities[i].outreachEffortList[0].lastContact;
              }
            }
            // if(this.userProfile.userRole != "Admin" && opportunitiesData.covidOpportunityType !=1){
            //   this.opportunitiesDataList.push(opportunitiesData);        
            // }
            //else if(this.userProfile.userRole === "Admin"){
            this.opportunitiesDataList.push(opportunitiesData);

            //}
          }
          this.opportunitiesFullDataList = this.opportunitiesDataList;
          this.opportunitiesDataList = this.pcahome == true ? this.opportunitiesDataList.filter(item => item.isPCA == true) : this.opportunitiesDataList.filter(item => item.isPCA == false);
          //this.opportunitiesDataList =  this.opportunitiesDataList.filter(item => item.isCovidOpportunity == true);

          this.selectName(this.opportunityForm.value.defaultSortBY);
          if (this.srhome == true) {
            this.opportunities1 = this._sortByPipe.sortBy(this.opportunitiesDataList, "outreachEffort");
            this.opportunitiesDataList = this.opportunities1;
          }
          else if (this.srhome == false && this.pcahome == false && this.COVIDHome == false) {
            this.opportunitiesDataList = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isCovidOpportunity != true), "isPreviousClient");
            this.opportunitiesDataList = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isCovidOpportunity != true), "ip");
          }

          let loopendTime: Date = new Date();

          this.opportunitiesData = opportunities;
          
          this.opportunitiesDataList = this.opportunitiesDataList.filter(item => item.businessType != 3 && item.businessType != 2 && item.businessType != 4);
          //console.log(`Loop ending for UI binding at ${loopendTime.getHours()}:${loopendTime.getMinutes()}:${loopendTime.getSeconds()}:${loopendTime.getMilliseconds()}`);
        },
        error => {
          console.error("Error in fetching opportunities", error);
        }
      );

    //setTimeout(function () {

    //}.bind(this), 5000);
  }
  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  displayFieldCss1(index: any) {
    switch (index) {
      case 'Contact Client':
        this.cssColor = "widget-info clearfix mb30 bg-cyan";
        this.goTo = "/communityoutreach/workflowmonitorreport";
        break;
      case 'Complete/Report Event':
        this.cssColor = "widget-info clearfix mb30 bg-green";
        this.goTo = "/communityoutreach/workflowmonitorreport";
        break;
      case 'Follow-up':
        this.cssColor = "widget-info clearfix mb30 bg-magenta";
        this.goTo = "/communityoutreach/workflowmonitorreport";
        break;
      case 'Host Event':
        this.cssColor = "widget-info clearfix mb30 bg-yellow";
        this.goTo = "/communityoutreach/workflowmonitorreport";
        break;
      case 'Complete SR Events':
      case 'Schedule SR Events':
      case 'Scheduled Events':
        this.cssColor = "widget-info clearfix mb30 bg-lipurple";
        this.goTo = "/communityoutreach/scheduledeventsreport";
        break;

    }
  }
  colMdClass(index: any) {
    var count: any = 0;
    for (let i = 0; i < this.storeStatus.length; i++) {
      if (this.storeStatus[i].statusCountByStore != 0 || this.storeStatus[i].statusCountByUser != 0) {
        count++;
      }
    }
    if (count >= 5) {
      switch (index) {
        case 'Contact Client':
          this.bsClasses = "col-md-3 col-12 p-5";
          break;
        case 'Host Event':
          this.bsClasses = "col-md-2 col-12 p-5";
          break;
        case 'Complete/Report Event':
          this.bsClasses = "col-md-2 col-12 p-5";
          break;
        case 'Follow-up':
          this.bsClasses = "col-md-2 col-12 p-5";
          break;
        case 'Perform Event':
          this.bsClasses = "col-md-2 col-12 p-5";
          break;
        case 'Complete SR Events':
        case 'Schedule SR Events':
        case 'Scheduled Events':
          this.bsClasses = "col-md-3 col-12 p-5";
          break;
      }
      this.dispBlockVrbl = true;
    }
    else {
      this.bsClasses = "col-md-3 col-12 p-5";
    }
    return this.bsClasses;
  }

  minH50() {
    if (this.storeStatus.length >= 5 && this.storeStatus[0].statusCountByStore || this.storeStatus.length >= 5 && this.storeStatus[0].statusCountByUser) {
      return 'mnH50';
    }
  }
  fltLeft() {
    if (this.storeStatus.length >= 5 && this.storeStatus[0].statusCountByStore || this.storeStatus.length >= 5 && this.storeStatus[0].statusCountByUser) {
      return 'float-left';
    }
  }
  col12() {
    if (this.storeStatus.length >= 5 && this.storeStatus[0].statusCountByStore || this.storeStatus.length >= 5 && this.storeStatus[0].statusCountByUser) {
      return 'col-12';
    }
  }

  pb0mb0() {
    if (this.storeStatus.length >= 5 && this.storeStatus[0].statusCountByStore || this.storeStatus.length >= 5 && this.storeStatus[0].statusCountByUser) {
      return 'pb-0 mb-0';
    }
  }


  isOppDisabled(Opportunity: any) {
    return Opportunity.imz.indexOf('No Client Interest') > -1 || Opportunity.imz.indexOf('Business Closed') > -1;
  }
  getColor(status: string, outreach_type: string) {
    if (outreach_type == "imz") {
      return (status.indexOf("Contract Initiated (Contracted Clinic)") > -1 || status.indexOf("Contract Sent") > -1) ? 'green' : '';
    }
    else if (outreach_type == "so")
      return (status.indexOf("Event Scheduled") > -1) ? 'green' : ((status.indexOf("No Outreach") > -1) ? 'red' : '');
  }

  getColor1(status: string, contact_date: string) {
        
    this.firstDate = new Date();
    this.secondDate = new Date(contact_date);
    if (contact_date != null && contact_date != "") {
      if (status == null) {
        status = "No Outreach";
      }
      if (status.indexOf("Initiated") > -1 || status.indexOf("Contract Sent") > -1)
        return '#3ba401';
      else if (status.indexOf("Contract Rejected") > -1)
        return '#cf003c'
      else if (this.calculateDays(this.firstDate, this.secondDate) >= 14)
        return '#cf003c';
      else if (this.calculateDays(this.firstDate, this.secondDate) >= 7 && this.calculateDays(this.firstDate, this.secondDate) <= 13)
        return '#fea102';
    }
    else {
      this.secondDate = new Date(this.outreachStartDateIP);
      if (this.calculateDays(this.firstDate, this.secondDate) >= 14)
        return '#cf003c';
      else if (this.calculateDays(this.firstDate, this.secondDate) >= 7 && this.calculateDays(this.firstDate, this.secondDate) <= 13)
        return '#fea102';
    }
  }

  getColor2(status: string, contact_date: string, isNoClinic: boolean) {

    this.firstDate = new Date();
    this.secondDate = new Date(contact_date);

    if (isNoClinic == true) {
      if (status.indexOf("Contact Client") > -1)
        return 'bg-danger';
      else if (status.indexOf("Confirmed") > -1)
        return 'bg-danger';
      else if (status.indexOf("Completed") != -1)
        return 'bg-success';
    }
    else if (contact_date != null && contact_date != "") {
      if (status == null)
        status = "No Outreach";
      else if (status.indexOf("Contact Client") > -1 && this.calculateDays1(this.firstDate, this.secondDate) >= 17)
        return 'bg-success';
      else if (status.indexOf("Contact Client") > -1 && this.calculateDays1(this.firstDate, this.secondDate) <= 17)
        return 'bg-danger';
      else if (status.indexOf("Confirmed") > -1 && this.calculateDays(this.firstDate, this.secondDate) <= 14 && this.calculateDays1(this.firstDate, this.secondDate) <= 17)
        return 'bg-success';
      else if (status.indexOf("Confirmed") > -1)
        return 'bg-success';
      else if (status.indexOf("Completed") != -1 && this.calculateDays(this.firstDate, this.secondDate) >= 14)
        return 'bg-success';
    }
  }

  calculateDays(date1, date2) {
    //Get 1 day in milliseconds
    var one_day = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    var difference_ms = date1_ms - date2_ms;

    // Convert back to days and return
    return Math.round(difference_ms / one_day);
  }
  calculateDays1(date1, date2) {
    //Get 1 day in milliseconds
    var one_day = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;

    // Convert back to days and return
    return Math.round(difference_ms / one_day);
  }
  opportunityClick() {
  }
  showDialog(opp: any) {
    SessionDetails.SetAgreementPK(0);
    SessionDetails.SetFollowUp(false);
    this.contractInitiated = false;
    this.ASOIntiaited = false;
    this.GovIntiaited = false;
    this.opoIntiaited = false;
    this.cssIntiaited = false;
    this.pendingRHDApproved = false;
    //this.community_outreach = false;
    this.contractFollowup = false;
    this.opportunitie = opp;
    this.businessType = opp.businessType;
    SessionDetails.opportunitiesData(opp);

    var userProfile = SessionDetails.GetUserProfile();
    if (this.opportunitie.outreachEffort.IP.outreachStatus == '3' && !this.opportunitie.outreachEffort.IP.isScheduledClinics) {
      this.contractInitiated = true;
    }
    if (this.opportunitie.outreachEffort.PCA.outreachStatus == '1' &&
      !this.opportunitie.outreachEffort.PCA.isScheduledClinics) {

      if (this.opportunitie.outreachEffort.PCA.outreachStatusTitle.search("Approved by Partner") != -1 &&
        (userProfile.userRole.toLowerCase().search("regional vice president") != -1)) {

        this.pendingRHDApproved = true;
      }
      this.ASOIntiaited = true;
    }
    if (this.opportunitie.outreachEffort.PCA.outreachStatus == '3' &&
      !this.opportunitie.outreachEffort.PCA.isScheduledClinics) {

      if (this.opportunitie.outreachEffort.PCA.outreachStatusTitle.search("Approved by Partner") != -1 &&
        (userProfile.userRole.toLowerCase().search("regional vice president") != -1)) {
        this.pendingRHDApproved = true;
      }
      this.GovIntiaited = true;
    }

    if (this.opportunitie.outreachEffort.PCA.outreachStatus == '6' &&
      !this.opportunitie.outreachEffort.PCA.isScheduledClinics) {

      if (this.opportunitie.outreachEffort.PCA.outreachStatusTitle.search("Approved by Partner") != -1 &&
        (userProfile.userRole.toLowerCase().search("regional vice president") != -1)) {
        this.pendingRHDApproved = true;
      }
      this.opoIntiaited = true;
    }
    if (this.opportunitie.outreachEffort.PCA.outreachStatus == '7' &&
      !this.opportunitie.outreachEffort.PCA.isScheduledClinics) {

      if (this.opportunitie.outreachEffort.PCA.outreachStatusTitle.search("Approved by Partner") != -1 &&
        (userProfile.userRole.toLowerCase().search("regional vice president") != -1)) {
        this.pendingRHDApproved = true;
      }
      this.cssIntiaited = true;
    }
    // else if (this.opportunitie.outreachEffort.IP.outreachStatus == '12' && !this.opportunitie.outreachEffort.IP.isScheduledClinics) {
    //   this.community_outreach = true;
    // }
    if (this.opportunitie.outreachEffort.PCA.outreachStatus == '2' || this.opportunitie.outreachEffort.IP.outreachStatus == '2' || this.opportunitie.outreachEffort.SR.outreachStatus == '2') {
      this.contractFollowup = true;
    }
    this.opportunitie.outreachEffort.PCA.outreachProgram == 'PCA' ? this.edit_profile_text = 'PCA Organization Profile' : this.edit_profile_text = 'Opportunity Profile';
    this.display = true;
  }

  showEvents(opp: any) {
    if (opp.outreachPk == 3) {
      this._alertService.sendWarningAlert(true, false, false);
    } 
    else if (opp.outreachPk == 1) {
      this._alertService.sendWarningAlert(false, true, false);
    } 
    else if (opp.outreachPk == 4) {
      this._alertService.sendWarningAlert(false, false, true);
    }

    this.eventDetails = (opp) ? opp : this.targetObj;
    this.targetObj = this.eventDetails;
    SessionDetails.EventDetails(this.eventDetails);
    //Corporate records details page

    if (
      (this.eventDetails.outreachPk === 3 
        && 
        (
          this.eventDetails.clinicTypeId === 1 
          || this.eventDetails.clinicTypeId === 2 
          || this.eventDetails.clinicTypeId === 3 
          || this.eventDetails.clinicTypeId === 5
          || this.eventDetails.clinicTypeId === 6
          || this.eventDetails.clinicTypeId === 13
        )
      )
      && this.eventDetails.isScheduled
    ) {
      this.dispSchedulerMenu = true;
    } 
    else if (this.eventDetails.outreachPk === 3 && this.eventDetails.clinicTypeId === 3) {
      this.router.navigateByUrl("/communityoutreach/CorporateClinicProgramDetails");
    }
    //SR Scheduled events details page
    else if (this.eventDetails.outreachPk === 1 && this.eventDetails.clinicTypeId === 3) {
      this.router.navigateByUrl("/communityoutreach/scheduleevent");
    }
    //Local records details page
    else if (this.eventDetails.outreachPk === 3 && this.eventDetails.clinicTypeId === 1) {
      this.router.navigateByUrl("/communityoutreach/LocalClinicProgramDetails");
    }
    //community outreach details page
    else if (this.eventDetails.outreachPk === 3 && this.eventDetails.clinicTypeId === 6) {
      this.router.navigateByUrl("/communityoutreach/CommunityOutreachProgramDetails");
    }
    // Equity Events
    else if (this.eventDetails.outreachPk === 3 && this.eventDetails.clinicTypeId === 13) {
      this.router.navigateByUrl("/communityoutreach/EquityEventDetails");
    }
    //Charity (WAG Free Flu Voucher) details page redirection
    else if (this.eventDetails.outreachPk === 3 && this.eventDetails.clinicTypeId === 2) {
      this.router.navigateByUrl("/communityoutreach/CharityProgramDetails");
    }
    //uploaded local or uploaded charity
    else if (this.eventDetails.outreachPk === 3 && (this.eventDetails.clinicTypeId === 5 || this.eventDetails.clinicTypeId === 7)) {
      this.router.navigateByUrl("/communityoutreach/CorporateClinicProgramDetails");
    }
    //pca details
    else if (this.eventDetails.outreachPk === 4) {
      this.router.navigateByUrl("/communityoutreach/pcadetails");
    }
  }
  ContactLogInfo(id) {
    SessionDetails.SetBusinessType(id);
    this.router.navigateByUrl("/communityoutreach/viewcontactlog");
  }
  showDialogOptConLog() {
    // LTCF Opportunity 
    if(this.opportunitie.covidOpportunityType === 1){
      forkJoin({
        ltcfOpportunity: this._lookupService.getLookUpValues('Industry', 'LTCFOpportunity'),
        outreachStatus: this._opportunityService.getOutreachStatus()
      })
      .subscribe((data) => {
        this.setOpportunityContactLogInfo(data.outreachStatus, data.ltcfOpportunity);
        if(this.outreachStatusValue == ''){
          this.canDisplayOutreachStatus = false;
        }
        else{
          this.canDisplayOutreachStatus = true;
        }
      })
    }
    // Standard Opportunity
    else if(this.opportunitie.covidOpportunityType === 2){
      forkJoin({
        standardOpportunity: this._lookupService.getLookUpValues('Industry', 'StandardOpportunity'),
        outreachstatus: this._opportunityService.getOutreachStatus()
      })
      .subscribe((data) => {
        this.setOpportunityContactLogInfo(data.outreachstatus, data.standardOpportunity);
        if(this.outreachStatusValue == ''){
          this.canDisplayOutreachStatus = false;
        }
        else{
          this.canDisplayOutreachStatus = true;
        }
      })
    }
    else{
      forkJoin({
        standardOpportunity: this._lookupService.getLookUpValues('Industry', 'StandardOpportunity'),
        outreachstatus: this._opportunityService.getOutreachStatus()
      })
      .subscribe((data) => {
        this.setOpportunityContactLogInfo(data.outreachstatus, data.standardOpportunity);
      })
    }
  }

  private setOpportunityContactLogInfo(outreach_status: any, industries: SelectType[]) {
    this.industries = industries;
    this.outreachStatusData = outreach_status.filter(item => (item.outreachStatusId != 0 && item.outreachStatus != 'Active' && item.isActive == true));
    // LTCF Opportunity 
    if(this.opportunitie.covidOpportunityType === 1){
      // For Equity Events, status id is 13
      const equityEventsIndex = this.outreachStatusData.findIndex(d => d.outreachStatusId === 13);
      if(equityEventsIndex > -1){
        this.outreachStatusData.splice(equityEventsIndex, 1);
      }
    }
    else{
      const equityEventsIndex = this.outreachStatusData.findIndex(d => d.outreachStatusId === 13);
      // if it is not found
      if(equityEventsIndex == -1){ 
        this.outreachStatusData.push({
          outreachStatusId: 13,
          outreachStatus: "Equity Events (No Contract)",
          outreachProgram: "IP",
          isActive: true,
          sortOrder: 5
        })
      }
    }
    this.businessName = this.opportunitie.businessName;
    if (!this.pcahome) {
      var immunization_status = ['3', '8', '12'];
      this.jobTitle = false;
      this.displayMessage = false;
      this.outreachStatusValue = "";
      this.dispContractInitiated = false;
      this.displayMessageForPCA = false;

      this.dialogOptConLog = true;
      this.dialogOptConLogforPCA = false;

      if ((this.opportunitie.outreachEffort.IP.isScheduledClinics &&
        this.opportunitie.outreachEffort.SR.outreachProgram == null) ||
        (immunization_status.indexOf(this.opportunitie.outreachEffort.IP.outreachStatus) > -1 &&
          this.opportunitie.outreachEffort.SR.outreachProgram == null)) {
        this.dialogOptConLog = false;
        this.display = false;
        this.dispContractInitiated = true;
      }
      else {
        this.outreachType = this._opportunityService.getOutreachType();
        // if (this.opportunitie.outreachEffort.split(',').length - 1 >= 2) {

        if (this.opportunitie.outreachEffort.IP.outreachProgram != null
          && this.opportunitie.outreachEffort.SR.outreachProgram != null) {
          this.outreachTypeData = this._opportunityService.getOutreachType();
        }
        else {
          //this.outreach_type_data = this.outreach_type.filter(item => item.category == this.opportunitie.outreachEffort.split(',')[1]);
          let outreachValue;
          if (this.opportunitie.outreachEffort.SR.outreachProgram != null) {
            outreachValue = this.opportunitie.outreachEffort.SR.outreachProgram;
          }
          if (this.opportunitie.outreachEffort.IP.outreachProgram != null) {
            outreachValue = this.opportunitie.outreachEffort.IP.outreachProgram;
          }
          this.outreachTypeData = this.outreachType.filter(item => item.category == outreachValue);
          this.outreachStatusValue = this.outreachTypeData[0].outreachStatus;

        }
      }
      
      this.logcontact = this._fb.group({
        select_outreach: this._fb.control('', Validators.required),
        businessName: this._fb.control(this.opportunitie.businessName, null),
        firstName: this._fb.control(this.opportunitie.firstName, null),
        lastName: this._fb.control(this.opportunitie.lastName, null),
        jobTitle: this._fb.control(this.opportunitie.jobTitle, null),
        defaultDate: this._fb.control(this.defaultDate, null),
        outreachstatus: this._fb.control('', Validators.required),
        feedback: this._fb.control('', null),
        Disposition: this._fb.control('', null),
        industry: this._fb.control(this.opportunitie.industry ?? '', null),
        other: this._fb.control(this.opportunitie.others ?? '', null)
      });

      this.outreachstatus = 0;
      this.businessContacted = this.opportunitie.businessName;
      this.display = false;
      if (this.outreachStatusValue != '') {
        this.onChange(this.outreachTypeData[0].category);
      }
    }
    if (this.pcahome) {
      this.displayMessageForPCA = false;
      if (this.opportunitie.outreachEffort.PCA.outreachProgram != null
      ) {
        this.outreachTypeData = this._opportunityService.getOutreachTypePCA();
      }
      this.dialogOptConLogforPCA = true;
      this.dialogOptConLog = false;
      this.outreachStatus = this._sortByPipe.sortBy(this.outreachStatusData.filter(
        item => {
          return (item.outreachProgram == 'PCA'
            && item.isActive == true
          )
        }), "sortOrder");
      this.logcontact = this._fb.group({
        select_outreach: this._fb.control('', Validators.required),
        businessName: this._fb.control(this.opportunitie.businessName, null),
        firstName: this._fb.control(this.opportunitie.firstName, null),
        lastName: this._fb.control(this.opportunitie.lastName, null),
        defaultDate: this._fb.control(this.defaultDate, null),
        outreachstatus: this._fb.control('', Validators.required),
        feedback: this._fb.control('', null),
        Disposition: this._fb.control('', null),
        industry: this._fb.control(this.opportunitie.industry ?? '', null),
        other: this._fb.control(this.opportunitie.others ?? '', null)
      });
      this.display = false;

      this.onPCAStatusChange(this.outreachTypeData[0].category);
    }

    if (!this.logcontact.value?.industry) {
      this.logcontact.controls['industry']?.setValidators(Validators.required);
      this.logcontact.controls['industry']?.updateValueAndValidity();
    }
    else if(this.logcontact.value?.industry) {
      this.logcontact.controls['industry']?.setValidators(null);
      this.logcontact.controls['industry']?.setErrors(null);
      this.logcontact.controls['industry']?.updateValueAndValidity();
    }

    if (this.logcontact.value.industry?.toLocaleLowerCase() === 'other' && !this.logcontact.value?.other) {
      this.logcontact.controls['other']?.setValidators(Validators.required);
      this.logcontact.controls['other']?.updateValueAndValidity();
    }
    else if(this.logcontact.value.industry?.toLocaleLowerCase() === 'other' && this.logcontact.value?.other){
      this.logcontact.controls['other']?.setValidators(null);
      this.logcontact.controls['other']?.setErrors(null);
      this.logcontact.controls['other']?.updateValueAndValidity();
    }
    this._ref.detectChanges();
  }

  onChange(select_outreach: any) {
    this.displayMessage = false;
    var immunization_status = [3, 8, 12, 13];
    this.jobTitle = false;
    if (this.outreachStatusValue != '') {
      this.logcontact = this._fb.group({
        select_outreach: this._fb.control(select_outreach, Validators.required),
        businessName: this._fb.control(this.opportunitie.businessName, null),
        firstName: this._fb.control(this.opportunitie.firstName, null),
        lastName: this._fb.control(this.opportunitie.lastName, null),
        jobTitle: this._fb.control(this.opportunitie.jobTitle, null),
        defaultDate: this._fb.control(this.defaultDate, null),
        outreachstatus: this._fb.control('', Validators.required),
        feedback: this._fb.control('', null),
        Disposition: this._fb.control('', null),
        industry: this._fb.control(this.opportunitie.industry ?? '', null),
        other: this._fb.control(this.opportunitie.others ?? '', null)
      });
    }
    let opportunityDate = SessionDetails.GetopportunitiesData();
    let isNoContractOpportunity = opportunityDate.isNoContractOpportunity;
    this.outreachStatus = this._sortByPipe.sortBy(this.outreachStatusData.filter(
      item => {
        if (opportunityDate.isNoContractOpportunity) {
          return (item.outreachProgram == select_outreach
            && item.outreachStatusId != 9 && item.outreachStatusId != 10 && item.isActive == true
            && item.outreachStatusId != 3 && item.outreachStatusId != 8
          )
        }
     
        if (opportunityDate.isFluOpportunity && opportunityDate.fluOpportunityType == 0) {
          return (item.outreachProgram == select_outreach && item.outreachStatusId != 9 
            && item.outreachStatusId != 10 && item.isActive == true
          )
        }     
        else {
          return (item.outreachProgram == select_outreach && 
            item.outreachStatusId != 8 && item.outreachStatusId != 9 
            && item.outreachStatusId != 10 && item.isActive == true
          )
        }
      }), "sortOrder");

      // 840 fix
    // if (this.opportunitie.isCovidOpportunity) {
    //   this.outreachStatus = this.outreachStatus.filter(item => item.outreachStatus != "Business Closed");
    // }
    // if (this.opportunitie.outreachStatus.split(',').length - 1 >= 2) {
    if (this.opportunitie.outreachEffort.IP.outreachProgram != null && this.opportunitie.outreachEffort.SR.outreachProgram != null) {
      if (select_outreach == 'SR')
        this.outreachStatusInfo = this.opportunitie.outreachEffort.SR.outreachStatus;
      else
        this.outreachStatusInfo = this.opportunitie.outreachEffort.IP.outreachStatus;
    }
    else {
      if (this.opportunitie.outreachEffort.IP.outreachProgram != null) {
        this.outreachStatusInfo = this.opportunitie.outreachEffort.IP.outreachStatus;
      }
      if (this.opportunitie.outreachEffort.SR.outreachProgram != null) {
        this.outreachStatusInfo = this.opportunitie.outreachEffort.SR.outreachStatus;
      }
    }

    this.logcontact.controls['outreachstatus'].setValue("");
    // if (this.outreach_status_info == '4') {
    //   this.job_title = true;
    // }
    //this.logcontact.value.outreachstatus = this.outreach_status.filter(item => item.outreachStatusId == this.outreach_status_info);
    if (immunization_status.indexOf(this.outreachStatusInfo) > -1 && select_outreach != 'SR') {
      this.displayMessage = true;
    }

    if(this.outreachStatusValue == '' && this.logcontact.controls['select_outreach'].value){
      this.canDisplayOutreachStatus = true;
    }
    else{
      this.canDisplayOutreachStatus = false;
    }
  }
  disableButton() {
    if (this.displayMessage || this.disable_button || this.displayMessageForPCA) {
      return 'false';
    }
    else {
      return '';
    }

  }
  onNCIFeedbackChange(nciOption: any) {
    if(nciOption.search(/Other/gi)>-1 && nciOption.search(/another/gi)< 0){
      this.showFeedbackNotes = true;
      this.logcontact.controls['feedback'].setValidators([Validators.required]);
      this.logcontact.controls['feedback'].updateValueAndValidity();
    }
    else {
      this.showFeedbackNotes = false;
      this.logcontact.controls['feedback'].setValidators(null);
      this.logcontact.controls['feedback'].updateValueAndValidity();
    }
  }
  onOutreachStatusChange(outreach_pk: any) {
    this.jobTitle = false;
    this.logcontact.value.outreachstatus = this.outreachStatus.filter(item => item.outreachStatusId == outreach_pk);
    this.outreachStatusInfo = outreach_pk;
    if (this.outreachStatusInfo == '4') {
      this.jobTitle = true;
      this.showNCIOptions = true;
      this.showFeedbackNotes = false;
      this.logcontact.controls['feedback'].setValidators(null);
      this.logcontact.controls['feedback'].updateValueAndValidity();
      this.logcontact.controls['Disposition'].setValidators([Validators.required]);
      this.logcontact.controls['Disposition'].updateValueAndValidity();
    }
    else if (this.outreachStatusInfo == '5' || this.outreachStatusInfo == '3' || this.outreachStatusInfo == '2') {
      this.showNCIOptions = false;
      this.showFeedbackNotes = true;
      this.logcontact.controls['feedback'].setValidators(null);
      this.logcontact.controls['feedback'].updateValueAndValidity();
      this.logcontact.controls['Disposition'].setValidators(null);
      this.logcontact.controls['Disposition'].updateValueAndValidity();
    }
    else {
      this.showNCIOptions = false;
      this.showFeedbackNotes = true;
      this.logcontact.controls['feedback'].setValidators([Validators.required]);
      this.logcontact.controls['feedback'].updateValueAndValidity();
      this.logcontact.controls['Disposition'].setValidators(null);
      this.logcontact.controls['Disposition'].updateValueAndValidity();
    }
  }

  redirectToPage(outreach_status) {
    SessionDetails.SetAgreementPK(this.opportunitie.outreachEffort.IP.clinicAgreementPk);
    if (outreach_status == '3')
      this.router.navigateByUrl("/communityoutreach/contract");
    else if (outreach_status == '12')
      this.router.navigateByUrl("/communityoutreach/communityoutreach");
    // else if (outreach_status == '12')
    // this.router.navigateByUrl("/communityoutreach/communityoutreach");
    // else if (outreach_status == '12')
    // this.router.navigateByUrl("/communityoutreach/communityoutreach");
  }
  redirectToPCAPage(outreach_status, rhdApproved) {
    SessionDetails.SetAgreementPK(this.opportunitie.outreachEffort.PCA.clinicAgreementPk);

    if (outreach_status == '1' && !rhdApproved)
      this.router.navigateByUrl("/communityoutreach/asolula");
    else if (outreach_status == '3' && !rhdApproved)
      this.router.navigateByUrl("/communityoutreach/govlula");
    else if (outreach_status == '4' && !rhdApproved)
      this.router.navigateByUrl("/communityoutreach/opolula");
    else if (outreach_status == '5' && !rhdApproved)
      this.router.navigateByUrl("/communityoutreach/csslula");
    else if (outreach_status == '3' && rhdApproved) {
      sessionStorage["clinic_type"] = 10;
      this.router.navigateByUrl("/communityoutreach/rhdContractAgreement");
    }
    else if (outreach_status == '1' && rhdApproved) {
      sessionStorage["approveRejectEmail"] = SessionDetails.GetUserProfile().email;
      sessionStorage["clinic_type"] = 8;
      this.router.navigateByUrl("/communityoutreach/rhdContractAgreement");
    }
    else if (outreach_status == '4' && rhdApproved) {
      sessionStorage["clinic_type"] = 11;
      sessionStorage["approveRejectEmail"] = SessionDetails.GetUserProfile().email;
      this.router.navigateByUrl("/communityoutreach/rhdContractAgreement");
    }
    else if (outreach_status == '5' && rhdApproved) {
      sessionStorage["clinic_type"] = 12;
      sessionStorage["approveRejectEmail"] = SessionDetails.GetUserProfile().email;
      this.router.navigateByUrl("/communityoutreach/rhdContractAgreement");
    }
  }
  onSubmit() {
    var contact_date = new Date();
    this.storeProfile = SessionDetails.GetStoreId();
    let otherSelected: boolean = false;
    if (this.outreachStatusInfo === '4') {
      this.logcontact.controls['Disposition'].setValidators([Validators.required]);
      this.logcontact.controls['Disposition'].updateValueAndValidity();
      this.logcontact.controls['outreachstatus'].setValue('4');
      if (this.logcontact.value.Disposition.search(/Other/gi) > -1 && this.logcontact.value.Disposition.search(/another/gi) < 0) {
        this.logcontact.controls['feedback'].setValidators([Validators.required]);
        this.logcontact.controls['feedback'].updateValueAndValidity();
        otherSelected = true;
      } else {
        this.logcontact.controls['feedback'].setValidators(null);
        this.logcontact.controls['feedback'].updateValueAndValidity();
      }
    }
    else {
      this.logcontact.controls['Disposition'].setValidators(null);
      this.logcontact.controls['Disposition'].updateValueAndValidity();
      this.logcontact.controls['feedback'].setValidators([Validators.required]);
      this.logcontact.controls['feedback'].updateValueAndValidity();
    }
    this.logcontact = this._fb.group({
      select_outreach: this._fb.control(this.logcontact.value.select_outreach, Validators.required),
      businessName: this._fb.control(this.opportunitie.businessName, null),
      firstName: this._fb.control(this.logcontact.value.firstName, null),
      lastName: this._fb.control(this.logcontact.value.lastName, null),
      jobTitle: this._fb.control(this.logcontact.value.jobTitle, null),
      defaultDate: this._fb.control(this.logcontact.value.defaultDate, null),
      outreachstatus: this._fb.control(this.logcontact.value.outreachstatus, Validators.required),
      feedback: this._fb.control(this.logcontact.value.feedback, otherSelected ? [Validators.required] : null),
      Disposition: this._fb.control(this.logcontact.value.Disposition, this.outreachStatusInfo === '4' ? [Validators.required] : null),
      industry: this._fb.control(this.logcontact.value.industry, null),
      other: this._fb.control(this.logcontact.value.other, null)
    });

    if(!this.logcontact.value.industry?.length){
      this.logcontact.controls['industry'].setErrors({incorrect: true});
      this.logcontact.updateValueAndValidity();
    }
    else if(this.logcontact.value.industry?.length){
      this.logcontact.controls['industry'].setErrors(null);
      this.logcontact.updateValueAndValidity();
    }

    if(this.logcontact.value.industry?.toLocaleLowerCase() === 'other' && !this.logcontact.value.other?.length){
      this.logcontact.controls['other'].setErrors({incorrect: true});
      this.logcontact.updateValueAndValidity();
    }
    else if(this.logcontact.value.industry?.toLocaleLowerCase() === 'other' && this.logcontact.value.other?.length){
      this.logcontact.controls['other'].setErrors(null);
      this.logcontact.updateValueAndValidity();
    }

    if (this.logcontact.valid) {
      this.logcontact.controls["feedback"].setValidators(null);
      this.logcontact.value.outreachstatus = this.outreachStatus.filter(item => item.outreachStatusId == this.outreachStatusInfo);

      if (this.logcontact.value.outreachstatus[0] && this.logcontact.value.outreachstatus.length > 0 && this.logcontact.value.outreachstatus[0].outreachStatusId > 0) {
        if (this.logcontact.value.outreachstatus[0].outreachStatusId == 4) {
          this.logcontact = this._fb.group({
            select_outreach: this._fb.control(this.logcontact.value.select_outreach, Validators.required),
            businessName: this._fb.control(this.opportunitie.businessName, null),
            firstName: this._fb.control(this.logcontact.value.firstName, Validators.required),
            lastName: this._fb.control(this.logcontact.value.lastName, Validators.required),
            jobTitle: this._fb.control(this.logcontact.value.jobTitle, Validators.required),
            defaultDate: this._fb.control(this.logcontact.value.defaultDate, null),
            outreachstatus: this._fb.control(this.logcontact.value.outreachstatus, Validators.required),
            feedback: this._fb.control(this.logcontact.value.feedback, this.outreachStatusInfo !== '4' ? [Validators.required] : null),
            Disposition: this._fb.control(this.logcontact.value.Disposition, this.outreachStatusInfo === '4' ? [Validators.required] : null),
            industry: this._fb.control(this.logcontact.value.industry ?? null, null),
            other: this._fb.control(this.logcontact.value.other ?? null, null)
          });
          if (this.logcontact.valid) {
          }
          else {
            this.utility.validateAllFormFields(this.logcontact);
            if (this.outreachStatusInfo == "0") {
              this.logcontact.controls['outreachstatus'].setValue("");
            }
            else {
              this.logcontact.controls['outreachstatus'].setValue(this.outreachStatusInfo);
            }
          }

        }
        var log_outreach_status = new FollowUp();
        log_outreach_status.logOutreachStatus.businessPk = this.opportunitie.businessPk;
        log_outreach_status.logOutreachStatus.firstName = this.logcontact.value.firstName;
        log_outreach_status.logOutreachStatus.lastName = this.logcontact.value.lastName;
        log_outreach_status.logOutreachStatus.jobTitle = this.logcontact.value.jobTitle;
        log_outreach_status.logOutreachStatus.feedback = this.logcontact.value.feedback;
        if (this.outreachStatusInfo === '4')
        {
          log_outreach_status.logOutreachStatus.Disposition = this.logcontact.value.Disposition && this.logcontact.value.Disposition !== "Other" ? this.logcontact.value.Disposition && this.logcontact.value.Disposition.length > 0 ? this.logcontact.value.Disposition : null : "Other";
        }
        else
        {
          log_outreach_status.logOutreachStatus.Disposition ='';
        }
        

        log_outreach_status.logOutreachStatus.contactDate = new Date(this.logcontact.value.defaultDate.getFullYear(), this.logcontact.value.defaultDate.getMonth()
            , this.logcontact.value.defaultDate.getDate(), contact_date.getHours(), contact_date.getMinutes(), contact_date.getSeconds(), contact_date.getMilliseconds());
        //log_outreach_status.logOutreachStatus.contactDate = contact_date;
        if (this.logcontact.value.select_outreach == "IP") {
          log_outreach_status.logOutreachStatus.outreachBusinessPk = this.opportunitie.outreachEffort.IP.outreachBusinessPk;
          log_outreach_status.logOutreachStatus.outreachProgram = this.logcontact.value.outreachstatus[0].outreachProgram;
          log_outreach_status.logOutreachStatus.outreachStatusTitle = this.logcontact.value.outreachstatus[0].outreachStatus;
          log_outreach_status.logOutreachStatus.outreachStatusId = this.logcontact.value.outreachstatus[0].outreachStatusId;
        }
        else if (this.logcontact.value.select_outreach == "SR") {
          log_outreach_status.logOutreachStatus.outreachBusinessPk = this.opportunitie.outreachEffort.SR.outreachBusinessPk;
          log_outreach_status.logOutreachStatus.outreachProgram = this.logcontact.value.outreachstatus[0].outreachProgram;
          log_outreach_status.logOutreachStatus.outreachStatusTitle = this.logcontact.value.outreachstatus[0].outreachStatus;
          log_outreach_status.logOutreachStatus.outreachStatusId = this.logcontact.value.outreachstatus[0].outreachStatusId;
        }

        log_outreach_status.logOutreachStatus.createdBy = this.userProfile.userPk;
        log_outreach_status.logOutreachStatus.industry = this.logcontact?.value?.industry;
        log_outreach_status.logOutreachStatus.other = this.logcontact?.value?.other;

        if (
            this.logcontact.value.outreachstatus[0].outreachStatusId == 3 
              && this.logcontact.value.outreachstatus[0].outreachProgram == 'IP' // status = 'Contract Initiated (Contracted Clinic)'
          ){ 
          if (this.opportunitie.outreachEffort.IP.isScheduledClinics) {
            alert('An Immunization Event has already been initiated or scheduled for this outreach opportunity. Click the outreach opportunity to view initiated contracts or view the opportunity’s events in the Upcoming or Past Events.');
          }
          else {
            this.logContactPrograms(log_outreach_status, 3);
          }
        }
        else if (
            this.logcontact.value.outreachstatus[0].outreachProgram == 'IP' 
              && this.logcontact.value.outreachstatus[0].outreachStatusId == 8
          ){
          SessionDetails.EventDetails(log_outreach_status);
          this.router.navigateByUrl("/communityoutreach/charityprogram");
          //this.logContactPrograms(log_outreach_status, 8);
        }
        else if (
            this.logcontact.value.outreachstatus[0].outreachProgram == 'IP' 
              && this.logcontact.value.outreachstatus[0].outreachStatusId == 13 // status = 'Equity Events (No Contract)'
          ) { 
          SessionDetails.EventDetails(log_outreach_status);
          sessionStorage["NewLogEntry"] = 1;
          this.router.navigateByUrl("/communityoutreach/equityevents");
          this.logContactPrograms(log_outreach_status, 13);
        }
        else if (
            this.logcontact.value.outreachstatus[0].outreachProgram == 'IP' 
              && this.logcontact.value.outreachstatus[0].outreachStatusId == 12 // status = 'Community Outreach (No Contract)'
          ) { 
          SessionDetails.EventDetails(log_outreach_status);
          sessionStorage["NewLogEntry"] = 1;
          this.router.navigateByUrl("/communityoutreach/communityoutreach");
          this.logContactPrograms(log_outreach_status, 12);
        }
        else if (
            this.logcontact.value.outreachstatus[0].outreachProgram == 'SR' 
              && this.logcontact.value.outreachstatus[0].outreachStatusId == 3 // status = 'Event Scheduled'
          ) {
          SessionDetails.EventDetails(log_outreach_status);
          this.router.navigateByUrl("/communityoutreach/scheduleevent");
        }
        else if (
            (this.logcontact.value.outreachstatus[0].outreachProgram == 'SR' 
              || this.logcontact.value.outreachstatus[0].outreachProgram == 'IP') 
                && this.logcontact.value.outreachstatus[0].outreachStatusId == 5) // status = 'Business Closed'
          { 
          this.logContact(log_outreach_status);
        }
        else if (
            (this.logcontact.value.outreachstatus[0].outreachProgram == 'SR' 
              || this.logcontact.value.outreachstatus[0].outreachProgram == 'IP')
                 && this.logcontact.value.outreachstatus[0].outreachStatusId == 4) // status = 'No Client Interest'
          { 
          this.dialogOptConLog = false;
          this.logContact(log_outreach_status);
        }
        else if (
            this.logcontact.value.outreachstatus[0].outreachProgram == 'SR' 
              && this.logcontact.value.outreachstatus[0].outreachStatusId == 1) // status = 'Active'
          {
          this.dialogOptConLog = false;
          this.logContact(log_outreach_status);
        }
        else if (
          (this.logcontact.value.outreachstatus[0].outreachProgram == 'SR' 
            || this.logcontact.value.outreachstatus[0].outreachProgram == 'IP') 
            && this.logcontact.value.outreachstatus[0].outreachStatusId == 2)  // status = 'Follow-up'
          {
            if (this.logcontact.value.outreachstatus[0].outreachProgram == 'SR') {
              SessionDetails.SetProgramType(ProgramType.SR);
            }
            if (this.logcontact.value.outreachstatus[0].outreachProgram == 'IP') {
              SessionDetails.SetProgramType(ProgramType.IP);
              this.logContactPrograms(log_outreach_status, 2);
            }
            SessionDetails.SetLogContact(log_outreach_status);
            this.router.navigateByUrl("/communityoutreach/followUp");
        }
      }
      else {
        this.errorMessage = "Outreach status is required";
      }
    }
    else {
      this.utility.validateAllFormFields(this.logcontact);
    }

  }
  logContactCancelClicked() {
    this.dialogOptConLog = false;
    this.showNCIOptions = false;
    this.showFeedbackNotes = true;

  }
  logContact(log_outreach_status) {
    this.disable_button = true;
    this.showNCIOptions = false;
    this.showFeedbackNotes = true;
     console.log(log_outreach_status);

    this.schedule_event_service.postSimpleUpEvent(log_outreach_status).subscribe((data: any) => {
      if (data.errorS === null) {
        this.spinner.show();
        if (data != null && data.errorS && data.errorS.errorCode == -1) {
          this.dialogOptConLog = false;
          this.dialogMsg = ErrorMessages["CIError"]
          this.CIError = true;
          return false;
        }

        this.disable_button = false;
        this.dialogOptConLog = false;
        this.dialogOptConLogforPCA = false;
        this.message = "The Business Contact was logged successfully!";
        this.getAssignedBusiness(this.storeProfile);
        setTimeout(() => {
          this.selectName(this.opportunityForm.value.defaultSortBY);
          this.spinner.hide();
        }, 5000);
        this.message_service.sendProfileChange("profilechange");
      }
    },
      err => {
        this.disable_button = false;
      }
    );
  }
  logContactPrograms(log_outreach_status, outreach_status) {
    this.disable_button = true;
    this.schedule_event_service.postSimpleUpEvent(log_outreach_status).subscribe((data: any) => {

      if (data.errorS == null) {

       
        sessionStorage["outreachBusinessPkForLog"] = log_outreach_status.logOutreachStatus.outreachBusinessPk;

        if (data != null && data.errorS && data.errorS.errorCode == -1) {
          this.dialogOptConLog = false;
          this.dialogMsg = ErrorMessages["CIError"]
          this.CIError = true;
          return false;
        }
        if (outreach_status == 3) {
          this.dialogOptConLog = false;
          this.message_service.sendProfileChange("profilechange");
          SessionDetails.SetAgreementPK(data != null ? data.logOutreachStatus.clinicAgreementPk : 0);
          this.disable_button = false;
          this.router.navigateByUrl("/communityoutreach/contract");
        }
      }
    },
      err => {
        this.disable_button = false;
      }
    );
  }
  isFieldValid(field: string) {
    return !this.logcontact.get(field).valid && this.logcontact.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  selectName(sortBy) {
    SessionDetails.setIsPCA(false);
    SessionDetails.setIsSR(false);
    SessionDetails.setIsCOVID(false);
    SessionDetails.SetStoreOppSortByDefaultVal(sortBy);
    this.opportunitiesDataList = this.opportunitiesFullDataList;
    this.assignedBusinessTitle = 'Outreach Opportunities';
    this.pcahome = false;
    this.storehome = true;
    if (sortBy == 1) {
      SessionDetails.setIsIP(true);
      SessionDetails.setIsSR(false);
      SessionDetails.setIsPCA(false);
      SessionDetails.setIsCOVID(false);
      this._alertService.sendWarningAlert(true, false, false);
      this.opportunitiesDataList = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "employmentSize");
      //this.opportunitiesDataList = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "ip");
      this.attentionAlert = true;
    }
    else if (sortBy == 2) {
      SessionDetails.setIsIP(true);
      SessionDetails.setIsSR(false);
      SessionDetails.setIsPCA(false);
      SessionDetails.setIsCOVID(false);
      this._alertService.sendWarningAlert(true, false, false);
      this.opportunitiesDataList = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "isPreviousClient");
      this.opportunitiesDataList = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "ip");
      this.attentionAlert = true;
    }
    else if (sortBy == 3) {
      SessionDetails.setIsIP(true);
      SessionDetails.setIsSR(false);
      SessionDetails.setIsPCA(false);
      SessionDetails.setIsCOVID(false);
      this._alertService.sendWarningAlert(true, false, false);
      this.opportunitiesDataList = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "lastContact");
      this.attentionAlert = true;
    }
    else if (sortBy == 4) {
      SessionDetails.setIsIP(true);
      SessionDetails.setIsSR(false);
      SessionDetails.setIsPCA(false);
      SessionDetails.setIsCOVID(false);
      this._alertService.sendWarningAlert(true, false, false);
      this.opportunitiesDataList = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "lastContact", "latest");
      this.attentionAlert = true;
    }
    else if (sortBy == 5) {
      SessionDetails.setIsIP(false);
      //for (let i = 0; i < this.opportunities.length; i++) {
      SessionDetails.setIsSR(true);
      this._alertService.sendWarningAlert(false, true, false);
      this.pcahome = false;
      this.srhome = true;
      this.storehome = true;
      SessionDetails.setIsCOVID(false);
      this.opportunities1 = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "outreachEffort");
      this.opportunitiesDataList = this.opportunities1;
      //}
      this.attentionAlert = false;
    }
    else if (sortBy == 6) {
      SessionDetails.setIsPCA(true);
      this._alertService.sendWarningAlert(false, false, true);
      // this.storeStatus.forEach(item=>{
      //   item.storeTasksStatus =  item.storeTasksStatus=="Perform Clinic"?"Host Event":item.storeTasksStatus,
      //   item.storeTasksStatus =  item.storeTasksStatus=="Complete/Report Clinic"?" Complete/Report Event":item.storeTasksStatus;
      // });
      SessionDetails.setIsIP(false);
      this.pcahome = true;
      SessionDetails.setIsPCA(true);
      this.storehome = false;
      this.assignedBusinessTitle = 'PCA Organizations';
      this.opportunitiesDataList = this.opportunitiesDataList.filter(item => item.isPCA == true);// this.opportunities1;
      this.srhome = false;
      this.message_service.setHintsType('pca');
      this.attentionAlert = false;
      SessionDetails.setIsCOVID(false);
    }
    else if (sortBy == 7) {
      //for (let i = 0; i < this.opportunities.length; i++) {
      SessionDetails.setIsSR(false);
      this._alertService.sendWarningAlert(true, false, false);
      SessionDetails.setIsIP(true);
      this.pcahome = false;
      this.srhome = false;
      this.storehome = true;
      SessionDetails.setIsCOVID(false);
        this.opportunities1 = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "ip");
        this.opportunities1 = this.opportunities1.sort((a, b) => a.isInterestClickedInRetentionEmail < b.isInterestClickedInRetentionEmail ? 1 : a.isInterestClickedInRetentionEmail > b.isInterestClickedInRetentionEmail ? -1 : 0)
        this.opportunities1 = this.opportunities1.sort((a, b) => a.isTopPriorityFromLead < b.isTopPriorityFromLead ? 1 : a.isTopPriorityFromLead > b.isTopPriorityFromLead ? -1 : 0)
        this.opportunities1 = this.opportunities1.sort((a, b) => a.isHighPriority < b.isHighPriority ? 1 : a.isHighPriority > b.isHighPriority ? -1 : 0)
      this.opportunitiesDataList = this.opportunities1;
      
      //}
      this.attentionAlert = false;
    }
    else if (sortBy == 8) {
      SessionDetails.setIsSR(false);
      this._alertService.sendWarningAlert(true, false, false);
      SessionDetails.setIsIP(true);
      this.pcahome = false;
      this.srhome = false;
      this.storehome = true;
      SessionDetails.setIsCOVID(false);
        this.opportunities1 = this._sortByPipe.sortBy(this.opportunitiesDataList.filter(item => item.isPCA == false), "ip");
        this.opportunities1 = this.opportunities1.sort((a, b) => a.isInterestClickedInRetentionEmail < b.isInterestClickedInRetentionEmail ? 1 : a.isInterestClickedInRetentionEmail > b.isInterestClickedInRetentionEmail ? -1 : 0)
        this.opportunities1 = this.opportunities1.sort((a, b) => a.isTopPriorityFromLead < b.isTopPriorityFromLead ? 1 : a.isTopPriorityFromLead > b.isTopPriorityFromLead ? -1 : 0)
        this.opportunities1 = this.opportunities1.sort((a, b) => a.isHighPriority < b.isHighPriority ? 1 : a.isHighPriority > b.isHighPriority ? -1 : 0)
      this.opportunitiesDataList = this.opportunities1;
      //}
      this.attentionAlert = false;
    }
    else {
      SessionDetails.setIsIP(false);
      this.opportunities = this.opportunitiesData;
      this.message_service.setHintsType('');
      this.attentionAlert = true;
    }
  }

  showDialogInfo() {
    this.displayInfoDlg = true;
  }
  showDialogInfoUpcomingEvents() {
    this.displayInfoDlgUpcomingEvents = true;
  }
  showErrorDialog(msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.stateSpecificDisplay = true;
  }
  addBusiness() {
    this.appOpp = true;
  
  }
  onContactDateSelected(selectedDate: Date) {
    this.logcontact.controls["defaultDate"].setValue(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12, 30, 55));
  }
  okClicked() {
    this.stateSpecificDisplay = false;
  }
  EditBusiness() {

    SessionDetails.SetActionType(ActionType.editOportunity);
    this.editBuisnessData = this.opportunitie;
    SessionDetails.opportunitiesData(this.editBuisnessData);
    if (this.opportunityForm.value.defaultSortBY == 6) {

      this.router.navigateByUrl('/communityoutreach/addpca');
    } else {

      this.router.navigateByUrl('/communityoutreach/opportunity');
    }
  }
  EventDetails() {

    if (this.eventDetails.clinicType == "Event Scheduled") {
      this.router.navigateByUrl('/communityoutreach/scheduleevent');
    }
  }

  followUpReminders() {
    SessionDetails.SetFollowUp(true);
    if (this.opportunitie.outreachEffort.SR.outreachStatus == '2') {
      SessionDetails.SetProgramType(ProgramType.SR);
    }
    if (this.opportunitie.outreachEffort.IP.outreachStatus == '2') {
      SessionDetails.SetProgramType(ProgramType.IP);
    }
    if (this.opportunitie.outreachEffort.PCA.outreachStatus == '2') {
      SessionDetails.SetProgramType(ProgramType.PCA);
    }
    if (this.opportunitie.outreachEffort.SR.outreachStatus == '2' && this.opportunitie.outreachEffort.IP.outreachStatus == '2') {
      SessionDetails.SetProgramType(ProgramType.SRIP);
    }
    this.router.navigateByUrl('/communityoutreach/followUp');
  }
  deleteDNC() {
    if (this.selectedDataList.length > 0) {
      this.displayDNC = true;
    }
  }

  cancelConfirmation() {
    this.displayDNC = false;
  }
  deleteConfirmationDNC() {
    if (this.selectedDataList.length > 0) {
      for (var i = 0; i < this.selectedDataList.length; i++) {
        this.selectedDNCData.push(this.selectedDataList[i].pk);
      }
      this._opportunityService.deleteNationalContractClients(this.selectedDNCData, this.userProfile.userPk).subscribe((data: any) => {
        if (data == "success") {
          this._opportunityService.getNationalContractClients().subscribe((res: any) => {
            this.dncTempClients = res
          });
          this.displayDNC = false;
          this.selectedDataList = [];
        }
        else {
          // alert("Issue with deleting");

        }
      },
        error => {
          if (error.indexOf("417") > -1) {
          this.DNCError = true;
          this.dialogMsg = "An error has occurred. Please contact Administrator.";
          }
        }
      );
    }
    this.displayDNC = false;

  }
  clFld() {
    this.searchText = "";
  }

  okDNCError() {
    this.DNCError = false;
  }
  okCIError() {
    this.CIError = false;
  }

  showSchedulerMenu(opp: any) {
    this.targetObj = opp;
    this.dispSchedulerMenu = true;
  }

  goToclientSchedulerApptTracker(opp: any) {
    this.eventDetails = this.targetObj;
    SessionDetails.EventDetails(this.eventDetails);
    if (opp == 'Corporate') {
      switch (Number(this.eventDetails.clinicTypeId)) {
        case 1:
          this.router.navigateByUrl('/communityoutreach/LocalClinicProgramDetails');
          break;
        case 2:
          this.router.navigateByUrl('/communityoutreach/CharityProgramDetails');
          break;
        case 6:
          this.router.navigateByUrl('/communityoutreach/CommunityOutreachProgramDetails');
          break;
        default:
          this.router.navigateByUrl('/communityoutreach/CorporateClinicProgramDetails');
          break;
      }

    } 
    else if (this.eventDetails.apptSchedulerType&&[1].indexOf(this.eventDetails.apptSchedulerType)>-1&&opp == 'Tracker'){
      sessionStorage.removeItem("sI");
      this.router.navigateByUrl('/communityoutreach/corpStoreApptTracker');
    }
    else if (this.eventDetails.apptSchedulerType&&[2,3].indexOf(this.eventDetails.apptSchedulerType)>-1){
      window.open('https://vaccine.walgreens.com');
    }
  }



  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  showDialogPCA(e) {
    if (e == "Choose an action") {
      this.display1 = true;
    }
    if (e == "Add Opportunity") {
      this.appOpp = true;
    }
  }

  appOppourtunity(e) {
    if (e == 'Add IP Opportunity') {
      this.appOpp = false;
      var errMsg: string[] = [];
      // var resrict_states = ["MO", "DC"];
      var resrict_states = []; //"DC" -- Removing DC state as per task# 1363
      var current_date = new Date();
      this.stateSpecific = SessionDetails.GetState();
      this.stateSpecificDisplay = false;
      var loggedInUserEmail = SessionDetails.GetUserProfile().email;
      // if (this.stateSpecific=="MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
      //     this.dialogMsg = String.Format(ErrorMessages['restrictedStates'], "Missouri", "20");
      //   }
      // else 
      
      // if (this.stateSpecific == "DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
      //   this.dialogMsg = String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15");
      // }

      // if (resrict_states.indexOf(this.stateSpecific) > -1 && RestrictedStatesAllowedUsers.indexOf(loggedInUserEmail) == -1 && this.userProfile.userRole != "Healthcare Supervisor") {
      //   // if (this.stateSpecific=="MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
      //   //     let sMsg = null;
      //   //     errMsg = [];
      //   //     errMsg.push(String.Format(ErrorMessages['restrictedStates'], "Missouri", "20"));
      //   //     sMsg = errMsg;
      //   //     this.showErrorDialog(sMsg);
      //   //   }
      //   // else
      //   if (this.stateSpecific == "DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
      //     let sMsg = null;
      //     errMsg = [];
      //     errMsg.push(String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15"));
      //     sMsg = errMsg;
      //     this.showErrorDialog(sMsg);
      //   }
      //   else {
      //     if (resrict_states.indexOf(this.stateSpecific) > -1 && (RestrictedStatesAllowedUsers.indexOf(loggedInUserEmail) > -1 || this.userProfile.userRole == "Healthcare Supervisor")) {
      //       this.restrictedStatesOverride = true;
      //     }
      //     else {
      //       SessionDetails.SetActionType(ActionType.addOportunity);
      //       this.router.navigateByUrl('/communityoutreach/opportunity');
      //     }
      //   }
      // }
      //else {
        if (resrict_states.indexOf(this.stateSpecific) > -1 && (RestrictedStatesAllowedUsers.indexOf(loggedInUserEmail) > -1 || this.userProfile.userRole == "Healthcare Supervisor")) {
          this.restrictedStatesOverride = true;
        }
        else {
          SessionDetails.SetActionType(ActionType.addOportunity);
          this.router.navigateByUrl('/communityoutreach/opportunity');
        }
      //}
    }

    if (e == 'Add PCA Opportunity') {
      SessionDetails.SetActionType(ActionType.addOportunity);
      this.router.navigate(['/communityoutreach/addpca']);
      this.appOpp = false;
    }

  }

  overrideRestrictedStatesRule() {
    this.restrictedStatesOverride = false;
    SessionDetails.SetActionType(ActionType.addOportunity);
    this.router.navigateByUrl('/communityoutreach/opportunity');
  }
  cancelRestrictedStatesOption() {
    this.restrictedStatesOverride = false;
  }
  pcaAdd() {
    this.display1 = true;
  }

  showPCAContactLog() {
    this.dialogPCAContactLog = true;
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (document.readyState == "complete" && pos > 0) {
          window.scrollTo(0, 0);
        } else if (pos == 0) {
          window.clearInterval(scrollToTop);
        }
      });
    }
  }

  //PCA related changes
  onPCASubmit() {
    var contact_date = new Date();
    this.storeProfile = SessionDetails.GetStoreId();
    this.logcontact = this._fb.group({
      select_outreach: this._fb.control(this.logcontact.value.select_outreach, null),
      businessName: this._fb.control(this.opportunitie.businessName, null),
      firstName: this._fb.control(this.logcontact.value.firstName, null),
      lastName: this._fb.control(this.logcontact.value.lastName, null),
      defaultDate: this._fb.control(this.logcontact.value.defaultDate, null),
      outreachstatus: this._fb.control(this.logcontact.value.outreachstatus, Validators.required),
      feedback: this._fb.control(this.logcontact.value.feedback, null),
      industry: this._fb.control(this.opportunitie.industry ?? '', null),
      other: this._fb.control(this.opportunitie.others ?? '', null)
    });
    if (this.logcontact.valid) {
      this.logcontact.controls["feedback"].setValidators(null);
      this.logcontact.value.outreachstatus = this.outreachStatus.filter(item => item.outreachStatusId == this.outreachStatusInfo);

      if (this.logcontact.value.outreachstatus[0].outreachStatusId > 0) {
        // if (this.logcontact.value.outreachstatus[0].outreachStatusId == 4) {
        this.logcontact = this._fb.group({
          select_outreach: this._fb.control(this.logcontact.value.select_outreach, Validators.required),
          businessName: this._fb.control(this.opportunitie.businessName, null),
          firstName: this._fb.control(this.logcontact.value.firstName, Validators.required),
          lastName: this._fb.control(this.logcontact.value.lastName, Validators.required),
          defaultDate: this._fb.control(this.logcontact.value.defaultDate, null),
          outreachstatus: this._fb.control(this.logcontact.value.outreachstatus, Validators.required),
          feedback: this._fb.control(this.logcontact.value.feedback, Validators.required)
        });
        var log_outreach_status = new FollowUp();
        log_outreach_status.logOutreachStatus.businessPk = this.opportunitie.businessPk;
        log_outreach_status.logOutreachStatus.firstName = this.logcontact.value.firstName;
        log_outreach_status.logOutreachStatus.lastName = this.logcontact.value.lastName;
        log_outreach_status.logOutreachStatus.feedback = this.logcontact.value.feedback;
        //log_outreach_status.logOutreachStatus.contactDate = this.logcontact.value.defaultDate;
        log_outreach_status.logOutreachStatus.contactDate = new Date(this.logcontact.value.defaultDate.getFullYear(), this.logcontact.value.defaultDate.getMonth()
          , this.logcontact.value.defaultDate.getDate(), contact_date.getHours(), contact_date.getMinutes(), contact_date.getSeconds(), contact_date.getMilliseconds());
        //log_outreach_status.logOutreachStatus.contactDate = contact_date;

        log_outreach_status.logOutreachStatus.outreachBusinessPk = this.opportunitie.outreachEffort.PCA.outreachBusinessPk;
        log_outreach_status.logOutreachStatus.outreachProgram = this.logcontact.value.outreachstatus[0].outreachProgram;
        log_outreach_status.logOutreachStatus.outreachStatusTitle = this.logcontact.value.outreachstatus[0].outreachStatus;
        log_outreach_status.logOutreachStatus.outreachStatusId = this.logcontact.value.outreachstatus[0].outreachStatusId;


        log_outreach_status.logOutreachStatus.createdBy = this.userProfile.userPk;

        if (log_outreach_status.logOutreachStatus.outreachStatusId == 2) {
          SessionDetails.SetProgramType(ProgramType.PCA);
          SessionDetails.SetLogContact(log_outreach_status);
          this.router.navigateByUrl("/communityoutreach/followUp");
        }

        else if (log_outreach_status.logOutreachStatus.outreachStatusId == 1 || log_outreach_status.logOutreachStatus.outreachStatusId == 3 ||
          log_outreach_status.logOutreachStatus.outreachStatusId == 6 || log_outreach_status.logOutreachStatus.outreachStatusId == 7) {
          this.logContactPCAPrograms(log_outreach_status, log_outreach_status.logOutreachStatus.outreachStatusId);
        }
        else {
          this.logContact(log_outreach_status);
        }
      }

    }
    else {
      this.utility.validateAllFormFields(this.logcontact);
      // if (this.outreachStatusInfo == "0") {
      //   this.logcontact.controls['outreachstatus'].setValue("");
      // }
      // else {
      //   this.logcontact.controls['outreachstatus'].setValue(this.outreachStatusInfo);
      // }
    }
  }
  onPCAStatusChange(select_outreach) {
    //var organization_types = [1,2,3,4,5];

    this.displayMessageForPCA = false;
    var immunization_status = [1, 3, 6, 7];
    this.jobTitle = false;
    if (this.outreachStatusValue != '') {
      this.logcontact = this._fb.group({
        select_outreach: this._fb.control(select_outreach, Validators.required),
        businessName: this._fb.control(this.opportunitie.businessName, null),
        firstName: this._fb.control(this.opportunitie.firstName, null),
        lastName: this._fb.control(this.opportunitie.lastName, null),
        jobTitle: this._fb.control(this.opportunitie.jobTitle, null),
        defaultDate: this._fb.control(this.defaultDate, null),
        outreachstatus: this._fb.control('', Validators.required),
        feedback: this._fb.control('', null),
        industry: this._fb.control(this.opportunitie.industry ?? '', null),
        other: this._fb.control(this.opportunitie.others ?? '', null)
      });
    }

    this.outreachStatus = this._sortByPipe.sortBy(this.outreachStatusData.filter(
      item => {
        return (item.outreachProgram == select_outreach
          && item.outreachStatusId != 9 && item.outreachStatusId != 10 && item.isActive == true
        )
      }), "sortOrder");

    if (this.opportunitie.organizationType == 1 || this.opportunitie.organizationType == 2) {
      var index = this.outreachStatus.findIndex(item => item.outreachStatusId == 3);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      index = -1;
      index = this.outreachStatus.findIndex(item => item.outreachStatusId == 6);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      index = -1;
      index = this.outreachStatus.findIndex(item => item.outreachStatusId == 7);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      if (this.opportunitie.outreachEffort.PCA.isScheduledClinics) {
        this.outreachStatus = this.outreachStatus.filter(item => item.outreachStatusId == 1);
      }
    }
    if (this.opportunitie.organizationType == 3) {
      var index = this.outreachStatus.findIndex(item => item.outreachStatusId == 1);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
        index = -1;
        index = this.outreachStatus.findIndex(item => item.outreachStatusId == 6);
        if (index > -1) {
          this.outreachStatus.splice(index, 1);
        }
        index = -1;
        index = this.outreachStatus.findIndex(item => item.outreachStatusId == 7);
        if (index > -1) {
          this.outreachStatus.splice(index, 1);
        }
        var index2 = this.outreachStatus.findIndex(item => item.outreachStatusId == 3);
        if (index2 > -1) {
          this.outreachStatus[index2].outreachStatus = SessionDetails.GetState() == 'FL' ? 'Florida Only Government LULA' :
            SessionDetails.GetState() == 'TX' ? 'Texas Only Government LULA' : this.outreachStatus[index2].outreachStatus;
        }
        if (this.opportunitie.outreachEffort.PCA.isScheduledClinics) {
          this.outreachStatus = this.outreachStatus.filter(item => item.outreachStatusId == 3);
        }
      }
    }
    if (this.opportunitie.organizationType == 4) {
      var index = this.outreachStatus.findIndex(item => item.outreachStatusId == 3);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      index = -1;
      index = this.outreachStatus.findIndex(item => item.outreachStatusId == 1);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      index = -1;
      index = this.outreachStatus.findIndex(item => item.outreachStatusId == 7);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      if (this.opportunitie.outreachEffort.PCA.isScheduledClinics) {
        this.outreachStatus = this.outreachStatus.filter(item => item.outreachStatusId == 6);
      }
    }
    if (this.opportunitie.organizationType == 5) {
      var index = this.outreachStatus.findIndex(item => item.outreachStatusId == 3);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      index = -1;
      index = this.outreachStatus.findIndex(item => item.outreachStatusId == 1);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      index = -1;
      index = this.outreachStatus.findIndex(item => item.outreachStatusId == 6);
      if (index > -1) {
        this.outreachStatus.splice(index, 1);
      }
      if (this.opportunitie.outreachEffort.PCA.isScheduledClinics) {
        this.outreachStatus = this.outreachStatus.filter(item => item.outreachStatusId == 7);
      }
    }

    // if (this.opportunitie.outreachStatus.split(',').length - 1 >= 2) {

    if (this.opportunitie.outreachEffort.PCA.outreachProgram != null) {
      this.outreachStatusInfo = this.opportunitie.outreachEffort.PCA.outreachStatus;
    }


    this.logcontact.controls['outreachstatus'].setValue("");
    // if (this.outreach_status_info == '4') {
    //   this.job_title = true;
    // }
    //this.logcontact.value.outreachstatus = this.outreach_status.filter(item => item.outreachStatusId == this.outreach_status_info);
    if (immunization_status.indexOf(this.outreachStatusInfo) > -1 && !this.opportunitie.outreachEffort.PCA.isScheduledClinics) {
      this.displayMessageForPCA = true;
    }
  }
  showHintsOnButtonClick(hints_type: string): void {
    this.firstdispHintDialog = false;
    this.message_service.setHintsType(hints_type);
    this.message_service.setShowHintsOnButtonClick(true);
  }

  logContactPCAPrograms(log_outreach_status, outreach_status) {
    this.disable_button = true;

    this.schedule_event_service.postSimpleUpEvent(log_outreach_status).subscribe((data: any) => {

      if (data.errorS == null) {
        if (outreach_status == 1) {
          this.dialogOptConLog = false;
          this.message_service.sendProfileChange("profilechange");
          SessionDetails.SetAgreementPK(data != null ? data.logOutreachStatus.clinicAgreementPk : 0);
          this.disable_button = false;
          this.router.navigateByUrl("/communityoutreach/asolula");
        }
        if (outreach_status == 3) {
          this.dialogOptConLog = false;
          this.message_service.sendProfileChange("profilechange");
          SessionDetails.SetAgreementPK(data != null ? data.logOutreachStatus.clinicAgreementPk : 0);
          this.disable_button = false;
          this.router.navigateByUrl("/communityoutreach/govlula");
        }
        if (outreach_status == 6) {

          this.dialogOptConLog = false;
          this.message_service.sendProfileChange("profilechange");
          SessionDetails.SetAgreementPK(data != null ? data.logOutreachStatus.clinicAgreementPk : 0);
          this.disable_button = false;
          this.router.navigateByUrl("/communityoutreach/opolula");
        }
        if (outreach_status == 7) {

          this.dialogOptConLog = false;
          this.message_service.sendProfileChange("profilechange");
          SessionDetails.SetAgreementPK(data != null ? data.logOutreachStatus.clinicAgreementPk : 0);
          this.disable_button = false;
          this.router.navigateByUrl("/communityoutreach/csslula");
        }
      }
    },
      err => {
        this.disable_button = false;
      }
    );
  }

  checking(event: any) {
    let ele = event.target || event.targetElement || event.currentTarget;
    this.message_service.setTurnOnOffLocalHintsFromComponent(!ele.checked);
    this.firstdispHintDialog = false;
    sessionStorage.setItem("hintsOff", "yes");
  }
  errorPopUpOkClick() {
    this.errorPopUp = false;
    this.router.navigateByUrl("/landing");
  }

  onKeyUp(searchTextValue: string) {
    this.subject.next(searchTextValue);
  }

  handleSearch(e: string) {
    this.dispDNCsearch = true;
    let newString = e.replace(/[^A-Z0-9]+/ig, "");
    this.dncTempClients = [];
    let fltrData = this.dncClients.filter(t => t.clientNameMatchKey.toLowerCase().includes(newString.toLowerCase()));
    this.dncTempClients = fltrData;
  }

  clrDNCsearch() {
    this.dncFilter = '';
    this.dispDNCsearch = false;
    this.dncTempClients = this.dncClients;
  }
  
}