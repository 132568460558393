import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CommonservicesService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;

  constructor(private http: HttpClient) { }

  public getMarket(user_id,user_type,region, area) {
    let url = this._url + "/api/JsGetAreaDistrictsAndMarkets/"+user_id + "/" + user_type + "/" + region + "/" + area;
    return this.http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}
