
export class StoreComplianceSR {
    outreachEffort: string = null;
    userPk: string;
    yearStartDate: string = null;
    quarter: string = null;
    reportName: string = "";
    outputType: string = "";
    constructor(outreach_effort: string, userPk: string, year_start_date: string, quarter, report_name: string, output_type: string) {
        this.outreachEffort = outreach_effort;
        this.userPk = userPk;
        this.yearStartDate = year_start_date;
        this.quarter = quarter;
        this.reportName = report_name;
        this.outputType = output_type;
    }
}
export class StoreComplianceIP {
    outreachEffort: string = null;
    userPk: number;
    regionNumber: number;
    areaNumber: number;
    districtNumber: number;
    yearStartDate: string = null;
    quarter: string = null;
    reportName: string = "";
    outputType: string = "";
    covidOnly: number;
    constructor(outreach_effort: string, user_pk: number, region_number: number, area_number: number, district_number: number, year_start_date: string, quarter, report_name: string, output_type: string, covid_Only: number) {
        this.outreachEffort = outreach_effort;
        this.userPk = user_pk;
        this.regionNumber = region_number;
        this.areaNumber = area_number;
        this.districtNumber = district_number;
        this.yearStartDate = year_start_date;
        this.quarter = quarter;
        this.reportName = report_name;
        this.outputType = output_type;
        this.covidOnly = covid_Only;
    }
}