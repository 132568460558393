import { Component, OnInit, ViewChild } from '@angular/core';
import { Paginator } from "primeng/paginator"
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScheduledClinicsReportService } from '../../services/scheduledclinicsreport.services';
import * as appSettings from '../../globals';
import { saveAs as importedSaveAs } from "file-saver";
import { ScheduledClinics } from '../../../../models/ScheduledClinics';
import { ApplicationSettings } from '../../../common/application-settings';
import { SessionDetails } from '../../../../utility/session';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { String } from 'typescript-string-operations';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonservicesService } from '../../services/commonservices.service';
import { states } from '../../../../JSON/States';
import { environment } from '../../../../../environments/environment';
import { UserRole } from '../../../../config-files/UserRole';
@Component({
  selector: 'app-scheduledclinicreport',
  templateUrl: './scheduledclinicsreport.component.html',
  styleUrls: ['./scheduledclinicsreport.component.css']
})

export class ScheduledclinicsreportComponent implements OnInit {

  scheduledClinicReportForm: FormGroup;
  scheduledClinicReportArray: any;
  userProfile: any;
  ddlSeason: number;
  ddlClinicStatus: any = [];
  ddlopportunityDesignationStatus: any=[];
  ddlClinicType: any = [];
  fromDate: Date;
  toDate: Date;
  ddlFormat: any;
  minDate: any;
  maxDate: any;
  outreachStartDate = ApplicationSettings.outreachStartDateIP;
  private from_date: string;
  private to_date: string;
  private outreachEffort = appSettings.outreachEffortIP;
  totalRecords: number;
  loading: boolean;
  checked: boolean = false;
  private currentSeason = appSettings.ipSeason;
  season: any = [];
  filterByDate: boolean = false;
  canExport: boolean = true;
  pageIndex: number = 1;
  isPagerClick: boolean = false;
  @ViewChild('plbcsPager') plbcsPager: Paginator;
  canShowCopay: boolean = false;
  showReport: boolean = false;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  canDisablePager:boolean = false;
  recordsExist: boolean = false;
  covidOnly: boolean = false;
  userType: string = "";
  markets: any;
  ddlRegion: any;
  ddlArea: any;
  ddlState:any= "0";
  IsPreviousSeason: boolean =false;
  states: any[];
  isAdmin: boolean = false;
  pageSize: number = 50;
  constructor(private fb: FormBuilder, private scheduleclinicreport: ScheduledClinicsReportService, 
    private common_services: CommonservicesService,
    private spinner: NgxSpinnerService) {
    this.userProfile = SessionDetails.GetUserProfile();
    this.ddlRegion = "0";
    this.ddlState = "0";
    this.ddlArea = "0";
    if (this.userProfile.userRole == "Admin") {   
      this.isAdmin = true;   
    } 
    else if (this.userProfile.userRole.toLowerCase() == "regional vice president" || this.userProfile.userRole.toLowerCase() == "regional healthcare director") {
      this.userType = "Region";
      this.isAdmin = true;
    }
    else if (this.userProfile.userRole.toLowerCase() == "healthcare supervisor" || this.userProfile.userRole.toLowerCase() == "director - rx and retail ops") {
      this.userType = "Area";
    }else if (this.userProfile.userRole.toLowerCase() == UserRole.PODUSER){
      this.userType = "Pod";
    }
    else if (this.userProfile.userRole.toLowerCase() == "district manager") {
      this.userType = "Area";
    }
    else if (this.userProfile.userRole.toLowerCase() == "store manager" || this.userProfile.userRole.toLowerCase() == "pharmacy manager") {      
      this.userType = "Area";
    }
    if (this.userType == "") {
      this.userType = "-1";
    }
    this.states = states.sort((a,b)=>a.id.localeCompare(b.id));
  }
  ClinicTypeArray = new ClinicTypeArray(null, null);
  clinictype = [
    { "name": "Local", "value": "Local" , "isDefaultChecked": true },
    { "name": "Community Outreach", "value": "Community Outreach" , "isDefaultChecked": true },
    { "name": "Charity (WAG Free Flu Voucher)", "value": "Charity (WAG Free Flu Voucher)" , "isDefaultChecked": true },
    { "name": "Equity Events (No Contract)", "value": "Equity Events (No Contract)" , "isDefaultChecked": true },
    { "name": "Uploaded Local", "value": "Uploaded Local" , "isDefaultChecked": true },
    { "name": "Uploaded Charity", "value": "Uploaded Charity" , "isDefaultChecked": true },
    { "name": "Corporate", "value": "Corporate" , "isDefaultChecked": true },
    { "name": "Vote & Vax", "value": "Vote & Vax" , "isDefaultChecked": true }
  ];
  ClinicStatusArray = new ClinicStatusArray(null, null);
  clinicstatus = [
    { "name": "Contact Client", "value": 0 , "isDefaultChecked": true },
    { "name": "Confirmed", "value": 1 , "isDefaultChecked": true },
    { "name": "Completed", "value": 3 , "isDefaultChecked": true },
    { "name": "Cancelled", "value": 2 , "isDefaultChecked": true }
  ];

  OpportunityDesignation=[
    { "name": "Flu & Routine", "value": "Flu & Routine", "isDefaultChecked": true },
    { "name": "Covid", "value": "Covid", "isDefaultChecked": true },
    { "name": "Combined", "value": "Combined", "isDefaultChecked": true } 
  ]; 


  ngOnInit() {
    this.ddlSeason = this.currentSeason;
    this.common_services.getMarket(this.userProfile.userPk, this.userType, this.ddlRegion, this.ddlArea).subscribe((data) => {
      this.markets = data[0];
    });
    this.defaultCheck();
    for (let report_year: number = parseInt(appSettings.reportStartYear); report_year <= new Date(this.outreachStartDate).getFullYear(); report_year++) {
      this.season.push({
        "yearName": report_year.toString() + " - " + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }
    this.setDates(true);
    
    this.fromDate = this.minDate;
    this.toDate = this.maxDate;
    this.ddlFormat = "xlsxv";
    this.setFormControls();
    //this.generateReport();
  };
  setFormControls(){
    this.scheduledClinicReportForm = this.fb.group({
      'fDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'tDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlSeason': [this.currentSeason],
      'ddlRegion': ["0"],
      'ddlState': ["0"],
      'ddlClinicStatus': [null],
      'ddlClinicType': [null],
      'filterByDate': [this.filterByDate],
      'ddlFormat': ["xlsxv"],
      'covidOnly': [false],
      'ddlopportunityDesignationStatus':[null]
    });
  }
  setDates(is_page_load: boolean) {
    if(this.ddlSeason == 2016){
      this.ddlClinicType.push('Vote & Vax');
      this.bindClinicType('Vote & Vax');
    }
    else{
      let ind = this.ddlClinicType.indexOf('Vote & Vax');
      if(ind > -1){
        this.ddlClinicType.splice(ind, 1);
      }
    }
    let tempMinDate: Date;
    let tempMaxDate: Date;
    this.pageIndex = 1;
    if (!is_page_load) {
      let ddlSeason = this.scheduledClinicReportForm.controls.ddlSeason.value;
      tempMinDate = new Date(ApplicationSettings.getIMmunizationStartDate(ddlSeason));
      tempMaxDate = new Date(ApplicationSettings.getImmunizationEndDate(ddlSeason));
      this.minDate = new Date(tempMinDate);
      this.maxDate = new Date(tempMaxDate);
      this.scheduledClinicReportForm.patchValue({ 'fDate': tempMinDate, 'tDate': tempMaxDate });
      this.plbcsPager.changePage(this.pageIndex - 1);
    }
    else{
      tempMinDate = new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason));
      tempMaxDate = new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason));
      this.minDate = new Date(tempMinDate);
      this.maxDate = new Date(tempMaxDate);
    }
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport = false;
    this.canDisablePager = true;
  }
  setCalendarDates() {
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport = false;
  }

  generateReport(): void {
    
    if (!this.showReport) {
      this.spinner.show();
    }
    this.recordsExist = false;
    let msg = "";
    if (this.ddlClinicType.length == 0) {
      msg = "Clinic Type";
    }
    if (this.ddlClinicStatus.length == 0) {
      msg += (msg.length > 0) ? " and Clinic Status" : "Clinic Status";
    }
    if (this.ddlClinicType.length == 0 || this.ddlClinicStatus.length == 0) {
      this.showDialog("Alert", String.Format(ErrorMessages["scheduleClinicReportFilterError"], msg), true);
      return;
    }
    this.loading = true;
    this.canShowCopay = this.ddlSeason < 2019 ? true : false;
    this.fromDate =  this.scheduledClinicReportForm.controls['fDate'].value;
    this.toDate   =  this.scheduledClinicReportForm.controls['tDate'].value;
    this.ddlSeason   =  this.scheduledClinicReportForm.controls['ddlSeason'].value;
    this.ddlFormat   =  this.scheduledClinicReportForm.controls['ddlFormat'].value;
    this.filterByDate=  this.scheduledClinicReportForm.controls['filterByDate'].value;
    let d1 = this.fromDate;
    let d2 = this.toDate;
    if (this.filterByDate) {
      this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
      this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    }
    else {
      this.from_date = "";
      this.to_date = "";
    }
    let f_date = this.fromDate.getMonth() + 1 + "/" + this.fromDate.getDate() + "/" + this.fromDate.getFullYear()
    let t_date = this.toDate.getMonth() + 1 + "/" + this.toDate.getDate() + "/" + this.toDate.getFullYear()
    let data = this.scheduledClinicReportForm.value;
    this.IsPreviousSeason =(data.ddlSeason == this.currentSeason) ? false : true;
    let scheduled_clinics = new ScheduledClinics((this.userProfile.userRole.toLowerCase() == "regional vice president" || this.userProfile.userRole.toLowerCase() == "regional healthcare director")?41:this.userProfile.userPk,
      this.outreachEffort,
      (this.ddlClinicStatus.length > 1) ? this.ddlClinicStatus.join(',') : this.ddlClinicStatus.join(),
      (this.ddlClinicType.length > 1) ? this.ddlClinicType.join(',') : this.ddlClinicType.join(),
      data.filterByDate,
      data.fDate,
      data.tDate,
      data.ddlSeason, ((data.ddlSeason == this.currentSeason) ? false : true), 
      this.pageIndex, false, Number(data.ddlRegion),data.ddlState, (this.ddlopportunityDesignationStatus.length > 1) ? this.ddlopportunityDesignationStatus.join(',') : this.ddlopportunityDesignationStatus.join(),'','',this.pageSize);
      this.scheduleclinicreport.getScheduledClinicDetails(scheduled_clinics).subscribe((resultArray: any) => {
      this.totalRecords = resultArray.Table1[0].totalScheduledClinics;
      this.scheduledClinicReportArray = resultArray.Table;
      this.recordsExist = this.totalRecords > 0 ? false : true;
      setTimeout(() => {        
        this.loading = false;
        this.spinner.hide();
        this.showReport = true;
        this.canExport = true;
        this.canDisablePager = false;
      }, 1000)
    },error =>{
      this.loading = false;
      this.spinner.hide();
      this.scheduledClinicReportArray = [];
      this.totalRecords = 0;
      this.showDialog("Error", ErrorMessages["error"], true);
    });
    this.canExport = true;
  }
  generateReportFormat(): void {
    this.loading = true;
    
    this.ddlSeason   =  this.scheduledClinicReportForm.controls['ddlSeason'].value;
    this.ddlFormat   =  this.scheduledClinicReportForm.controls['ddlFormat'].value;
    this.filterByDate=  this.scheduledClinicReportForm.controls['filterByDate'].value;
    let f_date = this.fromDate.getMonth() + 1 + "/" + this.fromDate.getDate() + "/" + this.fromDate.getFullYear();
    let t_date = this.toDate.getMonth() + 1 + "/" + this.toDate.getDate() + "/" + this.toDate.getFullYear();
    let report_name: string = this.canShowCopay ? 'ScheduledClinicsLastSeason' : 'ScheduledClinics';
    let data = this.scheduledClinicReportForm.value;
    this.IsPreviousSeason =(data.ddlSeason == this.currentSeason) ? false : true;
    let scheduled_clinics = new ScheduledClinics((this.userProfile.userRole.toLowerCase() == "regional vice president" || this.userProfile.userRole.toLowerCase() == "regional healthcare director") ? 41 : this.userProfile.userPk,
      this.outreachEffort,
      (this.ddlClinicStatus.length > 1) ? this.ddlClinicStatus.join(',') : this.ddlClinicStatus.join(),
      (this.ddlClinicType.length > 1) ? this.ddlClinicType.join(',') : this.ddlClinicType.join(),
      data.filterByDate,
      data.fDate,
      data.tDate,
      data.ddlSeason, ((data.ddlSeason == this.currentSeason) ? false : true), 0, false, Number(data.ddlRegion), data.ddlState,(this.ddlopportunityDesignationStatus.length > 1) ? this.ddlopportunityDesignationStatus.join(',') : this.ddlopportunityDesignationStatus.join(), report_name, data.ddlFormat);
    this.scheduleclinicreport.exportReportData(scheduled_clinics).subscribe((res:any) => {
      importedSaveAs(res.body, report_name+".xlsx");
      this.loading = false;
    },error =>{
      this.loading = false;
      this.showDialog("Error", ErrorMessages["error"], true);
    });
  }

  onFilterByDateChanged() {
    //console.log(this.filterByDate);
    this.canExport = false;
    this.canDisablePager = true;
    this.pageIndex = 1;
  }

  reset() {
    //this.loading = true;
    this.showReport = false;
    this.isPagerClick = true;
    this.ddlSeason = this.currentSeason;
    this.defaultCheck();
    this.ddlFormat = 0;
    this.pageIndex = 1;
    this.filterByDate = false;
    this.setDates(true);
    this.covidOnly = false;
    this.setFormControls();
    this.IsPreviousSeason =false;
  }
  
  canExportOnchange() {
    this.canExport = false;
    this.canDisablePager = true;
    this.pageIndex = 1;
  }

  paginate(event) {
    if(this.canDisablePager){
      return false;
    }
    var curr_page_rec_count = event.first + event.rows;
    var page_to_get = curr_page_rec_count / event.rows;
    page_to_get = Math.ceil(page_to_get);
    this.pageIndex = page_to_get;    
    if (event.page >= 0) {
      this.loading = true;
      if(event.page==0){
        this.pageIndex=1;
      }
      this.pageSize=event.rows;
      this.generateReport();
    }
  }
  clinicTypeChange(ev, val) {
    if (ev.target.checked) {
      this.ddlClinicType.push(val);
    } else {
      let i = this.ddlClinicType.indexOf(val);
      this.ddlClinicType.splice(i, 1);
    }
    this.canDisablePager = true;
    this.pageIndex = 1;
    this.plbcsPager.changePage(this.pageIndex - 1);
  }
  clinicStatusChange(ev, val) {
    if (ev.target.checked) {
      this.ddlClinicStatus.push(val);
    } else {
      let i = this.ddlClinicStatus.indexOf(val);
      this.ddlClinicStatus.splice(i, 1);
    }
    this.canDisablePager = true;
    this.pageIndex = 1;
    this.plbcsPager.changePage(this.pageIndex - 1);
  }
  opportunityDesignationChange(ev, val)
  {

    if (ev.target.checked) {
      this.ddlopportunityDesignationStatus.push(val);
    } else {
      let i = this.ddlopportunityDesignationStatus.indexOf(val);
      this.ddlopportunityDesignationStatus.splice(i, 1);
    }
    this.canDisablePager = true;
    this.pageIndex = 1;
    this.plbcsPager.changePage(this.pageIndex - 1);
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
  defaultCheck() {
    this.ddlClinicType = [];
    this.ddlClinicStatus = [];
    this.clinictype.forEach(v => {
      if (v.isDefaultChecked) {
        this.ddlClinicType.push(v.value)
      }
    });
    this.clinicstatus.forEach(v => {
      if (v.isDefaultChecked) {
        this.ddlClinicStatus.push(v.value)
      }
    });
    this.OpportunityDesignation.forEach(v => {
      if (v.isDefaultChecked) {
        this.ddlopportunityDesignationStatus.push(v.value)
      }
    });
  }
  bindClinicType(clinic_type: any) {
    return (this.ddlClinicType.indexOf(clinic_type) > -1) ? clinic_type : null;
  }

  bindClinicStatus(clinic_status: any) {
    return (this.ddlClinicStatus.indexOf(clinic_status) > -1) ? ((clinic_status == 0) ? true : clinic_status) : null;
  }
  bindopportunityDesignation(opportunity_Designation: any) {
    return (this.ddlopportunityDesignationStatus.indexOf(opportunity_Designation) > -1) ? ((opportunity_Designation == 0) ? true : opportunity_Designation) : null;
  }
}
export class ClinicTypeArray {
  constructor(public id: string, public name: string) { }
}
export class ClinicStatusArray {
  constructor(public id: number, public name: string) { }
}