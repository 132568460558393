import { Component, OnInit } from '@angular/core';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { CovidclinicsService } from '../../services/covidclinics.service';
import { saveAs as importedSaveAs } from "file-saver";
import { SessionDetails } from '../../../../utility/session';
import { CommonservicesService } from '../../services/commonservices.service';
import { ApplicationSettings } from '../../../common/application-settings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-covidclinicsreport',
  templateUrl: './covidclinicsreport.component.html',
  styleUrls: ['./covidclinicsreport.component.css']
})
export class CovidclinicsreportComponent implements OnInit {
  covidClinicsList: any = [];
  ddlFormat: string = "0";
  loading: boolean = true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  totalRecords: number;
  pageIndex: number = 1;
  isDisableDates:boolean=false;
  showFilters: boolean = false;
  userProfile: any;
  showRegionFilter: boolean = false;
  showAreaFilter: boolean = false;
  showDistrictFilter: boolean = true;
  userType: string = "";
  recordExists: boolean = true;
  canExport: any;
  markets: any;
  areas: any;
  districts: any;
  ddlRegion: any;
  ddlArea: any;
  ddlDistrict: any;
  covidClinicsForm: FormGroup;

  constructor(private covidclinics_service: CovidclinicsService, private common_services: CommonservicesService, private fb: FormBuilder) {
    this.userProfile = SessionDetails.GetUserProfile();
    if (!this.showFilters) {
      if (this.userProfile.userRole == "Admin") {
        this.showRegionFilter = true;
        this.showAreaFilter = true;
      } else if (this.userProfile.userRole.toLowerCase() == "regional vice president" || this.userProfile.userRole.toLowerCase() == "regional healthcare director") {
        this.showAreaFilter = true;
        this.userType = "Region";
      } else if (this.userProfile.userRole.toLowerCase() == "healthcare supervisor" || this.userProfile.userRole.toLowerCase() == "director � rx and retail ops") {
        this.showDistrictFilter = true;
        this.userType = "Area";
      }
      else if (this.userProfile.userRole.toLowerCase() == "district manager") {
        this.showDistrictFilter = true;
      }
      else if (this.userProfile.userRole.toLowerCase() == "store manager" || this.userProfile.userRole.toLowerCase() == "pharmacy manager") {
        this.showDistrictFilter = false;
      }
      if (this.userType == "") {
        this.userType = "-1";
      }
    }
  }

  ngOnInit() {
    this.makeAndLoadFilters();
    this.setFormControls();
    this.generateReport();
  }
  setFormControls() {
    this.covidClinicsForm = this.fb.group({
      'regionNumber': ["0"],
      'areaNumber': ["0"],
      'districtNumber': ["0"],
      'outputType': ["0"]
    });
  }
  generateReport() {
    this.loading = true;
    //let data = { 'regionNumber': this.ddlRegion, 'areaNumber': this.ddlArea, 'districtNumber': this.ddlDistrict };
    let data = this.covidClinicsForm.value;
    this.covidclinics_service.getCovidClinicsDetails(data).subscribe((resultArray: any) => {
      this.covidClinicsList = resultArray.Table;
      this.totalRecords = resultArray.Table.length;
      this.loading = false;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.covidClinicsList = [];
    });
    this.ddlFormat = "0";
  }
  generateReportFormat() {
    this.loading = true;
    //let data = { 'regionNumber': this.ddlRegion, 'areaNumber': this.ddlArea, 'districtNumber': this.ddlDistrict, 'reportName': 'CovidClinics', 'outputType': this.ddlFormat };
    let data = this.covidClinicsForm.value;
    this.covidclinics_service.exportReportData(data).subscribe((res: any) => {
      
      importedSaveAs(res.body, "CovidClinics.xlsx");
      this.loading = false;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
    this.ddlFormat = "0";
  }
  validateExportType() {
    if (this.ddlFormat == "0")
      return true;
    else
      return false;
  }
  getArea() {
    this.common_services.getMarket(this.userProfile.userPk, this.userType, this.ddlRegion, this.ddlArea).subscribe((data) => {
      this.areas = [];
      this.areas = data[1];
      this.districts = [];
      this.districts = data[2];
      this.ddlArea = 0;
      this.ddlDistrict = 0;
      this.covidClinicsForm.controls['areaNumber'].setValue(0);
      this.covidClinicsForm.controls['districtNumber'].setValue(0);
    });
    this.canExport = false;
  }

  getDistrict() {
    this.common_services.getMarket(this.userProfile.userPk, this.userType, this.ddlRegion, this.ddlArea).subscribe((data) => {
      // this.markets = data[0];
      this.covidClinicsForm.controls['districtNumber'].setValue(0);
      this.districts = [];
      this.districts = data[2];
      this.ddlDistrict = 0;
    });
    this.canExport = false;
  }
  canExportOnchange() {
    this.canExport = false;
  }
  reset(): void {
    this.loading = true;
    this.makeAndLoadFilters();
    this.setFormControls();
    // if(this.table){
    //   this.table.reset();
    //   this.txtSearch.nativeElement.value ='';
    // }
    this.generateReport();
  }
  makeAndLoadFilters() {

    //this.storeId = SessionDetails.GetStoreId();
    this.ddlDistrict = "0";
    this.ddlArea = "0";
    this.ddlRegion = "0";
    this.ddlFormat = "0";
    if (!this.showFilters) {
      this.common_services.getMarket(this.userProfile.userPk, this.userType, this.ddlRegion, this.ddlArea).subscribe((data) => {
        this.markets = data[0];
        this.areas = data[1];
        this.districts = data[2];
      });
    }
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
