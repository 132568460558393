import { Injectable } from '@angular/core';
import { ScheduledAppointment } from '../../../models/ScheduledAppointment';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ScheduledAppointmentsService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL; 
  constructor(private http: HttpClient) { }

  public getScheduledAppointmentsReportData(scheduled_appointments: ScheduledAppointment) {
    let url = this._url + "/api/JsScheduledAppointments_get";
    return this.http.post(url, scheduled_appointments, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  public getCorporateClients(user_role: string, user_pk: number, corporate_client: number, regionNumber:number = -1, areaNumber:number=-1, districtNumber:number=-1) {
    let url = this._url + "/api/JsGetCorporateClientsForReports/" + user_role + "/" + user_pk + "/" + corporate_client +"/" + regionNumber + "/" +areaNumber
    +"/" + districtNumber;
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  public exportReportData(scheduled_appointments: ScheduledAppointment) {
    let user_data = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,user_data.userName + ':'+ user_data.password);                    
    let url = this._url + "/api/JsScheduledAppointments_downloadReport";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    
    return this.http.post(url, scheduled_appointments, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'}).pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }
}
