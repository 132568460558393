import { of as observableOf, Observable } from 'rxjs';
import { catchError, switchMap, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AddBusinessService } from '../../services/AddBusiness.Service';
import { String } from 'typescript-string-operations';
import { ConfirmationService, Message } from 'primeng/api';
import { Util } from '../../../../utility/util';
import { AddOutreachOpportunity, OutreachEffortList, SelectType } from '../../../../models/OutreachOpportunity';
import { SessionDetails } from '../../../../utility/session';
import { ActionType } from '../../../../utility/enum';
import { DuplicateBusiness } from '../../../../models/duplicatebusiness';
import { OpportunitiesService } from '../../services/opportunities.service';
import { FollowUp } from '../../../../models/FollowUp';
import { HeaderserviceService } from '../../../common/services/headerservice.service';
import { states } from '../../../../JSON/States';
import { environment } from '../../../../../environments/environment';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { covidClinicTypeValues } from '../../../../JSON/covidClinicType';
import { LTCFTypesValues } from '../../../../JSON/LTCFTypes';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../../../../lookup.service';
import { adminUserNames } from '../../../../JSON/adminsInformation';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.css']
})
export class OpportunityComponent implements OnInit {
  openAlternateAcc:boolean;
  states: any[];
  covidClinicTypeValues:any[];
  LTCFTypeValues:any[];
  public AddBusinessView: Boolean;
  public BusinessInformation: any[] = [];
  business_info: boolean = false;
  isUserAdmin: Boolean = false;
  addBusinessForm: FormGroup;
  form_edit: FormBuilder;
  phone_search: string;
  address_search: string;
  dupilicate_search: boolean;
  LocalBusinesses: any[] = [];
  duplicate_message: string;
  duplicate_message1: string;
  duplicate_message2: string;
  search_string: string;
  duplicate_business: string;
  duplicate_business1: string = "";
  duplicate_business2: boolean;
  msgs: Message[] = [];
  phoneValue: string;
  isEdit: boolean = false;
  business_name: string;
  buttonName: string;
  outreachOpportunity: AddOutreachOpportunity;
  updateOutreachOpportunity: AddOutreachOpportunity;
  displayDNC: boolean = false;
  popupShow: boolean = false;
  dialogSummary: string = "";
  dialogMsg: string = "";
  userProfile: any;
  isShowAssignStore: boolean = false;
  isImmunizationProgram: boolean = false;
  isSeniorOutreach: boolean = false;
  popupSuccessShow: boolean = false;
  businessData: any;
  enableDisableCovidOption : boolean = false;
  noContract : boolean = true;
  isDNC: boolean = true;
  isOverrideDupCheck: boolean = false;
  canReassign: boolean = false;
  isRemoveContactLog: boolean = false;
  usPhoneNoRegEx: RegExp = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/
  ccnRegEx :RegExp =/^[a-zA-Z0-9%*#@%]*$/;
  ipProgramCheck: boolean = false;
  srProgramCheck: boolean = false;
  searching: boolean = false;
  storeSearchRsp = null;
  assignedStoreValue: any;
  updatePopupShow: boolean = false;
  overridePopupShow: boolean = false;
  duplicateErrorPopUp: boolean = false;
  logExistsPopupShow: boolean = false;
  removeIPSR: boolean = false;
  diffStateOverridePopupShow: boolean = false;
  updateBusinessShow: boolean = false;
  okCancel: string = "Ok";
  isCommunityCharity: boolean = false;
  isCharityCommunityNew: boolean = false;
  isCheckContractInitiatedCharity: boolean = false;
  isCheckContractInitiatedCommunity: boolean = false;
  disable_button: boolean = false;
  currentStateCode: string;
  loginUserEmailId : string;
  authorizedUserForUpdate :['jerry.v.thomas@walgreens.com','eli.zaguri@walgreens.com','tyrone.clark@walgreens.com',
    'csmith@tribpub.com','CommunityUpdate@WagOutreach.com','maili.dellinger@walgreens.com'];

  dncText:string = 'The Opportunity you are attempting to add is a match or partial match to a Client Name on the Do Not Call \– National Contracts list. ' +
                   'This client may already have a contract with the Walgreens Corporate Office and should not be contacted to conduct any Immunization opportunity unless assigned by Walgreens Corporate. ' +
                   'This opportunity cannot be added to your store. If you have questions regarding this opportunity or do not think the opportunity is a match to a client in the Do Not Call list, ' +
                   'please open a ticket on <a href="http://wagsam.walgreens.com/suggest/feedbackLanding" class="clLinks" target="_blank">Ask the Retail Hub</a> via Pharmacy > Community Outreach Portal > Immunizations';
  isAdmin: boolean = false;
  disableStandard: boolean = false;
  disableFluLTCFFlu: boolean = false;
  businessPk: number;
  pkhash:string
  showDeactivateOpportunity: boolean = false;
  Action: string = '';
  businessType: number = 0;
  IndustryForLTCFCovidOpp = ['LTCF', 'SNF', 'ALF', 'IDD', 'HUD', 'VET', 'RES','Long Term Care Facility','Skilled Nursing Facility',
                              'Housing and Urban Development','State Veterans Home','Assisted Living Facility', 
                              'Intellectual and Developmental Disability']
  businessNameLegthExceeded: any;
  industries: SelectType[] = [];
  canDisplayIndustries = false;
  canDisplayOtherTextboxForIndustries = false;
  isIPChecked = false;
  isSOChecked = false;
  ltcfOpportunityIndustries: SelectType[] = [];
  standardOpportunityIndustries: SelectType[] = [];
  isIPOrSR = false;
  canDisplayAutoInitiate = false;
  deleteOpportunityHeader: string = '';
  deleteOpportunityBody: string = '';
  displayDeleteDialog: boolean;

  constructor(
    protected _AddBusinessService: AddBusinessService, 
    private utility: Util,
    private formBuilder: FormBuilder, 
    private confirmationService: ConfirmationService, 
    private prouter: Router, 
    private _opportunityService: OpportunitiesService, 
    private _headerservice: HeaderserviceService, 
    private spinner: NgxSpinnerService,
    private _ref: ChangeDetectorRef,
    private _lookupService: LookupService
  ) {
    this.formControls();
    this.AddBusinessView = true;
    this.isEdit = false;
    this.buttonName = "Save"
    this.openAllAccordionTabs();
  }

  openAllAccordionTabs() { 
    // work around to make accordian work. 
    this.spinner.show();     
    setTimeout(() => {
      this.openAlternateAcc = true;        
    }, 1);
    setTimeout(() => {
      this.openAlternateAcc = false;
      this.spinner.hide();
    }, 1);
  }

  validatePhoneNo() {
    const control = this.addBusinessForm.get('Phone');
    if (control.dirty && control.touched) {
      this.phone_search = this.addBusinessForm.value.Phone;
      // Check for duplicate Opportunity 
      var duplicateAddress = new DuplicateBusiness();
      duplicateAddress.totalRecs = 0;
      duplicateAddress.totalS = 0;
      duplicateAddress.primaryAddress.phone = this.addBusinessForm.controls['Phone'].value;

      this._AddBusinessService.checkDuplicateBusiness(duplicateAddress).subscribe((data:any) => {
        if (data.duplicateAddressList.length > 0) {
          var store_id = data.duplicateAddressList[0].storeId;
          this.dialogSummary = "Duplicate Opportunity";
          this.dialogMsg = String.Format('There is more than one opportunity assigned to a Walgreens store with the same phone number ( store {0} ).  To eliminate duplicate follow up activity, this opportunity will not be added to your contacts', store_id);
          this.popupShow = true;

        }
      });
    }
  }

  displayNgMsg(msgSeverity: string, msgSummary: string, msgDetail: string) {
    this.msgs = [];
    this.msgs.push({ severity: msgSeverity, summary: msgSummary, detail: msgDetail });
  }

  onBlurMethod1() {
    if (this.AddBusinessView) {
      this.validateAddress();
    }
  }

  validateAddress() {
    const control = this.addBusinessForm.get('Address1');
    if ((this.addBusinessForm.get('Address1').dirty && this.addBusinessForm.get('Address1').touched) ||
      (this.addBusinessForm.get('Address2').dirty && this.addBusinessForm.get('Address2').touched) ||
      (this.addBusinessForm.get('City').dirty && this.addBusinessForm.get('City').touched) ||
      (this.addBusinessForm.get('state').dirty && this.addBusinessForm.get('state').touched) ||
      (this.addBusinessForm.get('ZipCode').dirty && this.addBusinessForm.get('ZipCode').touched)) {
      this.address_search = this.getFormValue();
      if (this.address_search != null) {
        this.dupilicate_search = this._AddBusinessService.duPlicateAddressSearch(this.address_search);
        if (this.dupilicate_search) {
          this.displayNgMsg('error', 'Duplicate Opportunity',
            'TODO for storeID:There is already an opportunity assigned to a Walgreens store with the same address (Store  13. How to get this number?). To eliminate duplicate follow up activity, this opportunity will not be added to your contacts');
        }
        else {
          this.duplicate_message1 = "";
        }
      }
    }
  }

  getFormValue() {
    return this.removeSpace(this.addBusinessForm.value.Address1 + this.addBusinessForm.value.Address2 + this.addBusinessForm.value.City + this.addBusinessForm.value.state + this.addBusinessForm.value.ZipCode);
  }

  removeSpace(text: string) {
    if (text.length > 0) {
      return text.replace(/[\s]/g, '');
    }
  }

  ngOnInit() {
    let userProfile = SessionDetails.GetUserProfile(); 
    this.loginUserEmailId= userProfile.email;
    console.log(this.loginUserEmailId);
    
    this.isAdmin = adminUserNames.filter(item => item.toLowerCase() == userProfile.userName.toLowerCase()).length > 0;
    this.isShowAssignStore = false;
    this.userProfile = SessionDetails.GetUserProfile();

    this._lookupService.getLookUpValues('Industry', 'StandardOpportunity')
      .subscribe((response: SelectType[]) => {
        this.standardOpportunityIndustries = response;
      });

    this._lookupService.getLookUpValues('Industry', 'LTCFOpportunity')
      .subscribe((response: SelectType[]) => {
        this.ltcfOpportunityIndustries = response;
      });

    if (this.userProfile.isAdmin) {
      this.okCancel = "Cancel";
    }
    else {
      this.okCancel = "Ok";
    }
    this.outreachOpportunity = new AddOutreachOpportunity();
    this.updateOutreachOpportunity = new AddOutreachOpportunity();
    this.addBusinessForm = this.formBuilder.group({
      BusinessSelected: ['', ''],
      BusinessInfoSelected: ['1', ''],
      BusinessName: ['', [Validators.required,Validators.maxLength(100)]],
      Phone: ['', [
        Validators.required
      ]],
      FirstName: [null, ''],
      TollFree: [null, ''],
      Ext: [null, ''],
      AlternateContactExt: [null, ''],
      LastName: [null, ''],
      EMail: [null, ''],
      JobTitle: [null, ''],
      WebsiteURL: [null, ''],
      EmploymentSize: [null, ''],
      Residents: [null, ''],
      Address2: ['', [Validators.maxLength(60)]],
      CharityHHSVoucher: [null, ''],
      CommunityOutreach: [null, ''],
      City: ['', Validators.required],
      SeniorOutreach: [null, ''],
      Industry: ['', Validators.required],
      Others: [null, ''],
      Address1: ['', [Validators.required,Validators.maxLength(40)]],
      assignedStore: [null, ''],
      //County: [null, ''],
      ZipCode: ['', Validators.required],
      state: ['', Validators.required],
      ImmunizationProgram: [null, ''],
      SeniorOutReachStore: [null, ''],
      isCovidOpportunity: [null, ''],
      isFluOpportunity: [null, ''],
      covidOpportunityType: [null, Validators.required],
      fluOpportunityType: [null, ''],
      isNoContractOpportunity:[null, ''],
      ImmunizationProgramStore: [null, ''],
      pkhash: [null, ''],
      privateClinicFees:['8.50',''],
      targetPopulation: ['', ''],
      communityPartner: ['', ''],
      communityPartnerName: ['', ''],
      healthDeptInvolved: ['', ''],
      opportunityHostLocation: ['', ''],
      vaccineInventorySource: ['', ''],
      covidClinicType:['',''],
      LTCFType:['ALF',''],
      ccn:['',[Validators.maxLength(10)]],//,Validators.pattern('')
      notes:[null,''],
      AlternateFirstName:[null,''],
      AlternateLastName:[null,''],
      AlternatePhone:['',''],
      AlternateJobTitle:[null,''],
      AlternateEMail:[null,'']
    });

    this.isUserAdmin = this.CheckIfUserIsAdmin();

    //edit
    if (SessionDetails.GetActionType() == ActionType.editOportunity) {
      this.isShowAssignStore = true;
      this.isEdit = true;
      this.buttonName = "Update"
      this.AddBusinessView = false;

      let data = SessionDetails.GetopportunitiesData();
      if(
        (JSON.stringify(data.outreachEffort.IP).length > 2 
          || JSON.stringify(data.outreachEffort.SR).length > 2)
        && JSON.stringify(data.outreachEffort.PCA).length == 2
      ){
        this.isIPOrSR = true;

        if(JSON.stringify(data.outreachEffort.IP).length > 2){
          const statusTitle: string = data.outreachEffort.IP.outreachStatusTitle;
          if(statusTitle.toLowerCase() == "no outreach" 
              || statusTitle.toLowerCase() == "follow-up"
              || statusTitle.toLowerCase() == "no client interest"
            ){
              this.canDisplayAutoInitiate = true;
          }
        }
      }

      this.business_name = data.businessName;
      if (data.businessPk != undefined && data.businessPk != null && data.businessPk != "") {
        this.onBusinessSelected(data.businessPk);
        this.businessPk= data.businessPk
      } else {
        this.displayNgMsg('error', 'Error', "An error has occurred. Pleaase contact administrartor.");
        return false;
      }
      this.BusinessInformation = this._AddBusinessService.getStoreBusinessInfo();
      
    }

    this.states = states;
    this.covidClinicTypeValues = covidClinicTypeValues;
    this.LTCFTypeValues = LTCFTypesValues;
    this.addBusinessForm.get('BusinessName').valueChanges.subscribe(val=>{this.isDNC = true;});
  }

  formControls() {
    this.addBusinessForm = this.formBuilder.group({
      BusinessSelected: ['', ''],
      BusinessName: ['', Validators.required],
      BusinessInfoSelected: ['1', ''],
      Phone: ['', [
        Validators.required
      ]],
      FirstName: [null, ''],
      EMail: [null, Validators.email],
      TollFree: [null, ''],
      Ext: [null, ''],
      AlternateContactExt: [null, ''],
      LastName: [null, ''],
      JobTitle: [null, ''],
      WebsiteURL: [null, ''],
      EmploymentSize: [null, ''],
      Residents: [null,''],
      Address2: [null, ''],
      CharityHHSVoucher: [null, ''],
      CommunityOutreach: [null, ''],
      City: [null, Validators.required],
      SeniorOutreach: [null, ''],
      Industry: [null, Validators.required],
      Others: [null, ''],
      Address1: [null, Validators.required],
      //County: [null, ''],
      ZipCode: [null, Validators.required],
      assignedStore: [null, Validators.required],
      //ZipCode4: ['', ''],
      state: [null, Validators.required],
      ImmunizationProgram: [null, ''],
      SeniorOutReachStore: [null, ''],
      isCovidOpportunity: [null, ''],
      isFluOpportunity: [null, ''],
      covidOpportunityType: [null, Validators.required],
      fluOpportunityType: [null, ''],
      isNoContractOpportunity:[null, ''],
      ImmunizationProgramStore: [null, ''],
      pkhash: [null, ''],
      privateClinicFees:['8.50',''],
      targetPopulation: ['', ''],
      communityPartner: ['', ''],
      communityPartnerName: ['', ''],
      healthDeptInvolved: ['', ''],
      opportunityHostLocation: ['', ''],
      vaccineInventorySource: ['', ''],
      covidClinicType:['',''],
      LTCFType:['ALF',''],
      ccn:['',[Validators.maxLength(10)]],//,Validators.pattern('/^[a-zA-Z0-9%*#@%]*$/')
      notes:[null,''],
      AlternateFirstName:[null,''],
      AlternateLastName:[null,''],
      AlternatePhone:[null,''],
      AlternateJobTitle:[null,''],
      AlternateEMail:[null,'']
    });
  }

  validateMaxLengths() : boolean {
    let maxLegthExceeded = false;
    if(this.addBusinessForm.controls['BusinessName'].value.length > 100)
    {
      this.businessNameLegthExceeded = true;
      maxLegthExceeded =  true;
    }  
    
    return maxLegthExceeded;
  }

  onAddEditBusinessSubmit() {
    this.validateControls();
    if(this.IndustryForLTCFCovidOpp.includes(this.addBusinessForm.controls['Industry'].value) 
        && this.addBusinessForm.controls['covidOpportunityType'].value == '2'){
      this.dialogSummary = "Error";
      this.dialogMsg = "Based on the value entered into the Industry field, the COVID Designation should be LTCF COVID Opportunity. Please update your selection to add this opportunity.";
      this.popupShow = true;
      return;
    }
    if (this.isEdit)
      this.EditBusiness();
    else
      this.AddBusiness();
  }

  isFieldValid(field: string) {
    return !this.addBusinessForm.get(field).valid && this.addBusinessForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  private selectedLink: any;
  setradio(e: any): void {
    this.selectedLink = e;
  }

  AddToStore() {
   this._AddBusinessService.AddToStore(this.selectedLink)
       .subscribe(res => {
          this.displayNgMsg('success', 'Opportunity Added', "Opportunity added successfully to selected store...")
         },
         error => this.displayNgMsg('error', 'Server Error', (<any>error).toString())
       );
  }

  AddBusiness() {
    var store_id = SessionDetails.GetStoreId();
    var store_pk = SessionDetails.GetStorePk();

    if (!this.addBusinessForm.controls['ImmunizationProgram']?.value && !this.addBusinessForm.controls['SeniorOutreach']?.value) {
      this.addBusinessForm.controls['ImmunizationProgram']?.setErrors({ 'incorrect': true });
      this.addBusinessForm.controls['SeniorOutreach']?.setErrors({ 'incorrect': true });
      this.addBusinessForm.updateValueAndValidity();
    }
    else {
      this.addBusinessForm.controls['ImmunizationProgram']?.setErrors(null);
      this.addBusinessForm.controls['SeniorOutreach']?.setErrors(null);
      this.addBusinessForm.updateValueAndValidity();
    }

    if (!this.addBusinessForm.valid) {
      this.utility.validateAllFormFields(this.addBusinessForm);
      return;
    }
    if (this.validateMaxLengths()) {
      return;
    }
    else {
      if (!this.usPhoneNoRegEx.test(this.addBusinessForm.controls['Phone'].value)) {
        if (this.addBusinessForm.controls['Phone'].value && ((this.addBusinessForm.controls['Phone'].value).indexOf("X") > -1)) {
          this.addBusinessForm.controls['Phone'].setValue(null);
          this.addBusinessForm.controls['Phone'].updateValueAndValidity();
          this.utility.validateAllFormFields(this.addBusinessForm);
          this.dialogSummary = "Error";
          this.dialogMsg = "Mandatory fields are empty or have invalid entries. Please check the fields highlighted in red and enter or correct the information.";
          this.popupShow = true;
          return false;
        }
        this.dialogSummary = "Phone number error.";
        this.dialogMsg = "Invalid phone number. Please enter valid phone number in phone field.";
        this.popupShow = true;
        return false;
      }
      // Commented as per Work Item# 1311 and 1310
      // if (this.addBusinessForm.controls['TollFree'].value!=="" && this.addBusinessForm.controls['TollFree'].value !== null 
      //   && this.addBusinessForm.controls['TollFree'].value !== undefined && !this.usPhoneNoRegEx.test(this.addBusinessForm.controls['TollFree'].value)) {
      //   if (this.addBusinessForm.controls['TollFree'].value && ((this.addBusinessForm.controls['TollFree'].value).indexOf("X") > -1)) {
      //     this.addBusinessForm.controls['TollFree'].setValue(null);
      //     this.addBusinessForm.controls['TollFree'].updateValueAndValidity();
      //     this.utility.validateAllFormFields(this.addBusinessForm);
      //     this.dialogSummary = "Error";
      //     this.dialogMsg = "Invalid toll free number. Please enter valid toll free number in toll free field.";          
      //     this.popupShow = true;
      //     return false;
      //   }
      //   this.dialogSummary = "Toll free number error.";
      //     this.dialogMsg = "Invalid toll free number. Please enter valid toll free number in toll free field.";          
      //   this.popupShow = true;
      //   return false;
      // }

      if (this.addBusinessForm.controls['covidOpportunityType'].value == null &&
        this.addBusinessForm.controls['fluOpportunityType'].value == null) {
        if (this.addBusinessForm.controls['ImmunizationProgram'].value == 1) {
          this.dialogSummary = "Designation error";
          this.dialogMsg = "Please select an Outreach Program Designation.";
          this.popupShow = true;
          return false;
        }
      }
    }

    if (this.addBusinessForm.controls['EMail'].value) {
      if (!this.utility.validateEmail(this.addBusinessForm.controls['EMail'].value)) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "Please enter a valid Email";
        this.popupShow = true;
        return;
      }
    }

    if (this.addBusinessForm.controls['AlternateEMail'].value) {
      if (!this.utility.validateEmail(this.addBusinessForm.controls['AlternateEMail'].value)) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "Please enter a valid Alternate Email";
        this.popupShow = true;
        return;
      }
    }

    // this.utility.validateAllFormFields(this.addBusinessForm);
    if (this.addBusinessForm.controls['BusinessName'].value != "" && this.addBusinessForm.controls['Phone'].value != "" &&
      this.addBusinessForm.controls['Industry'].value != "" && this.addBusinessForm.controls['Address1'].value != "" &&
      this.addBusinessForm.controls['state'].value != "" && this.addBusinessForm.controls['City'].value != "" &&
      this.addBusinessForm.controls['ZipCode'].value != "") {
      let SeniorOutReach = this.addBusinessForm.controls['SeniorOutreach'].value;
      let ImmunizationProgram = this.addBusinessForm.controls['ImmunizationProgram'].value;
      if ((SeniorOutReach == false || SeniorOutReach == null) && (ImmunizationProgram == false || ImmunizationProgram == null)) {

        this.dialogSummary = "Program Selection";
        this.dialogMsg = "Senior Outreach Program and/or Immunization Program should be selected to Add your Opportunity";
        this.popupShow = true;
        return;
      }

      let address1: string = this.addBusinessForm.controls['Address1'].value;
      let address2: string = this.addBusinessForm.controls['Address2'].value;
      let address = (address1 + address2).trim().replace(/[\[\]().-]+/g, '');
      address = address.replace(/\s/g, '');
      if (address.toLowerCase().includes("pobox")) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "Addresses containing a P.O. Box are not allowed. Please enter a valid location address.";
        this.popupShow = true;
        return;
      }

      if (this.addBusinessForm.controls['covidOpportunityType'].value == '1' && this.addBusinessForm.controls['ccn'].value &&
        !this.ccnRegEx.test(this.addBusinessForm.controls['ccn'].value)) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "CNN can only have combinition of Alphabets, Numbers or special characters";
        this.popupShow = true;
        return;
      }

      let CharityHHSVoucher = this.addBusinessForm.controls['CharityHHSVoucher'].value;
      let CommunityOutreach = this.addBusinessForm.controls['CommunityOutreach'].value;

      if (((CharityHHSVoucher == true) || (CommunityOutreach == true)) && (ImmunizationProgram != true)) {
        let msg = "";
        this.dialogSummary = "Alert";
        this.dialogMsg = "The Auto Initiate options are only available for the Immunization Program. Please include the Immunization Program in your Outreach Program selection or deselect the Auto Initiate option.";
        this.popupShow = true;
        return;
      }
      var state = SessionDetails.GetState();

      this.outreachOpportunity.businessPk = -1;
      this.outreachOpportunity.address1 = this.addBusinessForm.controls['Address1'].value;
      this.outreachOpportunity.address2 = this.addBusinessForm.controls['Address2'].value;
      this.outreachOpportunity.businessName = this.addBusinessForm.controls['BusinessName'].value;
      this.outreachOpportunity.city = this.addBusinessForm.controls['City'].value;
      this.outreachOpportunity.email = this.addBusinessForm.controls['EMail'].value;
      this.outreachOpportunity.employmentSize = +this.addBusinessForm.controls['EmploymentSize'].value;
      this.outreachOpportunity.residents = this.addBusinessForm.controls['Residents'].value;
      this.outreachOpportunity.firstName = this.addBusinessForm.controls['FirstName'].value;
      this.outreachOpportunity.industry = this.addBusinessForm.controls['Industry'].value;
      this.outreachOpportunity.others = this.addBusinessForm.controls['Others']?.value ?? null;
      this.outreachOpportunity.jobTitle = this.addBusinessForm.controls['JobTitle'].value;
      this.outreachOpportunity.lastName = this.addBusinessForm.controls['LastName'].value;
      this.outreachOpportunity.phone = this.addBusinessForm.controls['Phone'].value;
      this.outreachOpportunity.state = this.addBusinessForm.controls['state'].value;
      //this.outreachOpportunity.tollFree = this.addBusinessForm.controls['TollFree'].value;
      this.outreachOpportunity.ext = this.addBusinessForm.controls['Ext'].value;
      this.outreachOpportunity.alternateContactExt = this.addBusinessForm.controls['AlternateContactExt'].value;
      this.outreachOpportunity.zipCode = this.addBusinessForm.controls['ZipCode'].value;
      this.outreachOpportunity.pkhash = this.addBusinessForm.controls['pkhash'].value;
      this.outreachOpportunity.covidOpportunityType = this.addBusinessForm.controls['covidOpportunityType'].value;
      this.outreachOpportunity.fluOpportunityType = this.addBusinessForm.controls['fluOpportunityType'].value;
      this.outreachOpportunity.covidClinicType = this.addBusinessForm.controls['covidClinicType'].value;
      this.outreachOpportunity.LTCFType = this.addBusinessForm.controls['LTCFType'].value;
      this.outreachOpportunity.ccn = this.addBusinessForm.controls['ccn'].value;
      this.outreachOpportunity.notes = this.addBusinessForm.controls['notes'].value;
      if (this.outreachOpportunity.covidOpportunityType === null || Number(this.outreachOpportunity.covidOpportunityType) === 0) {
        this.outreachOpportunity.covidOpportunityType = 0;
      }
      this.outreachOpportunity.addressMatchKey = "";
      this.outreachOpportunity.outreachBusinessPk = -1;
      this.outreachOpportunity.totalS = 0;
      this.outreachOpportunity.isScheduledClinics = 0;
      this.outreachOpportunity.privateClinicFees = this.addBusinessForm.controls['privateClinicFees'].value;
      this.outreachOpportunity.targetPopulation = this.addBusinessForm.controls['targetPopulation'].value;
      this.outreachOpportunity.communityPartner = this.addBusinessForm.controls['communityPartner'].value;
      this.outreachOpportunity.communityPartnerName = this.addBusinessForm.controls['communityPartnerName'].value;
      this.outreachOpportunity.healthDeptInvolved = this.addBusinessForm.controls['healthDeptInvolved'].value;
      this.outreachOpportunity.opportunityHostLocation = this.addBusinessForm.controls['opportunityHostLocation'].value;
      this.outreachOpportunity.vaccineInventorySource = this.addBusinessForm.controls['vaccineInventorySource'].value;
      this.outreachOpportunity.alternateFirstName = this.addBusinessForm.controls['AlternateFirstName'].value;
      this.outreachOpportunity.alternateLastName = this.addBusinessForm.controls['AlternateLastName'].value;
      this.outreachOpportunity.alternatePhone = this.addBusinessForm.controls['AlternatePhone'].value;
      this.outreachOpportunity.alternateJobTitle = this.addBusinessForm.controls['AlternateJobTitle'].value;
      this.outreachOpportunity.alternateEMail = this.addBusinessForm.controls['AlternateEMail'].value;

      this.outreachOpportunity.outreachEffortList = [];
      if (this.addBusinessForm.controls['SeniorOutreach'].value) {
        var outreachEffort = new OutreachEffortList();
        outreachEffort.outreachBusinessPk = -1;
        outreachEffort.outreachProgram = "SR";
        outreachEffort.storeId = store_id;
        outreachEffort.storePk = store_pk;
        outreachEffort.existingStoreId = -1
        outreachEffort.isCommunityInitiated = 0; // this.addBusinessForm.controls['CommunityOutreach'].value ? 1 : 0;
        outreachEffort.isCharityInitiated = 0; //this.addBusinessForm.controls['CharityHHSVoucher'].value ? 1 : 0;
        this.outreachOpportunity.outreachEffortList.push(outreachEffort);
      }
      if (this.addBusinessForm.controls['ImmunizationProgram'].value) {
        var outreachEffort = new OutreachEffortList();
        outreachEffort.outreachBusinessPk = -1;
        outreachEffort.outreachProgram = "IP";
        outreachEffort.storeId = store_id;
        outreachEffort.storePk = store_pk;
        outreachEffort.existingStoreId = -1;
        outreachEffort.isCommunityInitiated = this.addBusinessForm.controls['CommunityOutreach'].value ? 1 : 0;
        outreachEffort.isCharityInitiated = this.addBusinessForm.controls['CharityHHSVoucher'].value ? 1 : 0;
        this.outreachOpportunity.outreachEffortList.push(outreachEffort);
        this.outreachOpportunity.isCovidOpportunity = this.addBusinessForm.controls['isCovidOpportunity'].value && Number(this.addBusinessForm.controls['covidOpportunityType'].value) != 3 ? true : false;
        this.outreachOpportunity.isFluOpportunity = this.addBusinessForm.controls['isFluOpportunity'].value && (Number(this.addBusinessForm.controls['fluOpportunityType'].value) == 3 || Number(this.addBusinessForm.controls['fluOpportunityType'].value) == 0) ? true : false;
        this.outreachOpportunity.isNoContractOpportunity = this.addBusinessForm.controls['isNoContractOpportunity'].value ? true : false;
        this.outreachOpportunity.privateClinicFees = this.addBusinessForm.controls['privateClinicFees'].value;
      }

      // Check for duplicate Opportunity 
      var duplicateAddress = new DuplicateBusiness();
      duplicateAddress.totalRecs = 0;
      duplicateAddress.totalS = 0;
      duplicateAddress.primaryAddress.storeId = store_id;
      duplicateAddress.primaryAddress.businessPk = -1;
      duplicateAddress.primaryAddress.businessName = this.addBusinessForm.controls['BusinessName'].value;
      duplicateAddress.primaryAddress.outreachPk = -1;
      duplicateAddress.primaryAddress.phone = this.addBusinessForm.controls['Phone'].value;
      duplicateAddress.primaryAddress.address1 = this.addBusinessForm.controls['Address1'].value;
      duplicateAddress.primaryAddress.address2 = this.addBusinessForm.controls['Address2'].value;
      duplicateAddress.primaryAddress.city = this.addBusinessForm.controls['City'].value;
      duplicateAddress.primaryAddress.state = this.addBusinessForm.controls['state'].value;
      duplicateAddress.primaryAddress.zipCode = this.addBusinessForm.controls['ZipCode'].value;
      // this.addBusiness(this.outreachOpportunity, this.userProfile.userPk);
      if (state != this.addBusinessForm.controls['state'].value) {
        // this.confirmState(duplicateAddress);
        this.dialogSummary = 'Alert';
        this.dialogMsg = 'The store you are assigning this opportunity to is in a different state than the opportunity’s location.';
        this.diffStateOverridePopupShow = true;
      }
      else {
        this.addBusiness(this.outreachOpportunity, this.userProfile.userPk);
      }

    }
  }
  confirmState(duplicateAddress) {
    this.confirmationService.confirm({
      message: 'The store you are assigning this opportunity to is in a different state than the opportunity’s location.',
      header: 'Store Assignment',
      accept: () => {

        this.addBusiness(this.outreachOpportunity, this.userProfile.userPk);
      },
      reject: () => {
        return;
      }
    });
  }

  checkDNC(businessName, outreachOpportunity, userPk) {
    //Gets Do Not Call Clients
    this._opportunityService.getNationalContractClients().subscribe((res:any) => {

      var data = res.filter(item => item.clientName === businessName);

      if (data.length > 0) {
        this.displayDNC = true;

      }
      else {
        this.addBusiness(outreachOpportunity, userPk);
      }

    });
  }

  DNCClick() {
    this.displayDNC = false;
  }
  overrideDNCAdd() {
    this.displayDNC = false;
    this.isDNC = false;
    this.outreachOpportunity.isCheckDnc = 0;
    this.addBusiness(this.outreachOpportunity, this.userProfile.userPk)
  }
  addBusiness(outreachOpportunity, userPk) {
    //this._AddBusinessService.saveStoreBusiness(this.addBusinessForm.value).subscribe(res => {
    this.disable_button = true;
    this._AddBusinessService.addOpportunity(outreachOpportunity, userPk, this.isDNC, this.isOverrideDupCheck).subscribe(res => {
      this.disable_button = false;
      this.businessData = res;
      if (res.errorS == null && res.businessPk > 0) {
        this.dialogSummary = "Opportunity";
        this.dialogMsg = "Opportunity Added successfully.";
        this.popupSuccessShow = true;
      }
      else if (res.errorS.errorCode == "1") {
        this.dialogSummary = "DUPLICATE WARNING";
        this.dialogMsg = String.format('There is already an opportunity assigned to a Walgreens store with the same {0}. To eliminate duplicate outreach activity, this opportunity will not be added to your contacts. Go to this opportunity’s Clinic Details to add additional clinic dates or to amend a contract, If additional vaccines or payment methods are required for the clinic.', res.errorS.errorMessage);        
        if(this.userProfile.isAdmin){
        this.overridePopupShow = true;
        }
        else
        {
          this.duplicateErrorPopUp = true;
        }
      }

      else if (res.errorS.errorCode == "-2") {
        this.dialogSummary = "Opportunity matches DNC Client";
        this.dialogMsg = this.dncText;
        this.updatePopupShow = true;
      }
      else if (res.errorS.errorCode == "-3") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.popupShow = true;
      }
      else if (res.errorS.errorCode == "-4") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.overridePopupShow = true;
      }
      else if (res.errorS.errorCode == "-5") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.overridePopupShow = true;
      }
      else if (res.errorS.errorCode == "-6") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.popupShow = true;
      }
      else if (res.errorS.errorCode == "-7") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.popupShow = true;
      }
      else if (res.errorS.errorCode == "-8") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.logExistsPopupShow = true;
      }
      else {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.popupShow = true;
      }
    },
      error => {
        this.disable_button = false;
        this.dialogSummary = "Error";
        this.dialogMsg = "Server Error";
        this.popupShow = true;
      }
      //this.displayNgMsg('error', 'Server Error', (<any>error).toString())
    );
  }
  Cancel() {
    this.prouter.navigate(['/communityoutreach/storehome']);
  }

   confirm() {
     this.confirmationService.confirm({
       message: 'The store you are assigning this opportunity to is in a different state than the opportunity’s location.?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
         this._AddBusinessService.updateStoreBusiness(this.addBusinessForm.value);
        this.displayNgMsg('success', 'Opportunity Update', "Opportunity updated successfully");
      },
      reject: () => {
        return;
      }
    });
   }
  isBusinessStateStoreStateDiff(BusinessId: Number, selectedState: string): Boolean {

    return false;
  }

  format() {
    if (this.addBusinessForm.value.Phone != undefined) {
      this.phoneValue = Util.telephoneNumberFormat(this.addBusinessForm.value.Phone);
    }
  }
  //Edit Opportunity related changes
  onBusinessSelected(businessId) {
    this._AddBusinessService.getStoreBusinessDetails(businessId).subscribe((res:any) => {
      this.outreachOpportunity = res;
      let edit_business_data = this.outreachOpportunity;
      this.pkhash=edit_business_data.pkhash;
      this.addBusinessForm.controls['Address1'].setValue(edit_business_data.address1);
      this.addBusinessForm.controls['Address2'].setValue(edit_business_data.address2);
      this.addBusinessForm.controls['BusinessName'].setValue(edit_business_data.businessName);
      this.addBusinessForm.controls['City'].setValue(edit_business_data.city);
      this.addBusinessForm.controls['EMail'].setValue(edit_business_data.email);
      this.addBusinessForm.controls['EmploymentSize'].setValue(edit_business_data.employmentSize == null ? null : edit_business_data.employmentSize);
      this.addBusinessForm.controls['Residents'].setValue(edit_business_data.residents == null ? null : edit_business_data.residents);
      this.addBusinessForm.controls['FirstName'].setValue(edit_business_data.firstName);
      this.addBusinessForm.controls['JobTitle'].setValue(edit_business_data.jobTitle);
      this.addBusinessForm.controls['LastName'].setValue(edit_business_data.lastName);
      this.addBusinessForm.controls['Phone'].setValue(edit_business_data.phone);
      this.addBusinessForm.controls['state'].setValue(edit_business_data.state);
      //this.addBusinessForm.controls['TollFree'].setValue(edit_business_data.tollFree);
      this.addBusinessForm.controls['ZipCode'].setValue(edit_business_data.zipCode);
      this.addBusinessForm.controls['pkhash'].setValue(edit_business_data.pkhash);
      this.addBusinessForm.controls['covidOpportunityType'].setValue(edit_business_data.covidOpportunityType);
      this.addBusinessForm.controls['fluOpportunityType'].setValue(edit_business_data.fluOpportunityType);
      this.addBusinessForm.controls['covidClinicType'].setValue(edit_business_data.covidClinicType);
      this.addBusinessForm.controls['LTCFType'].setValue(edit_business_data.LTCFType);
      this.addBusinessForm.controls['ccn'].setValue(edit_business_data.ccn);
      if(edit_business_data.covidOpportunityType=== null || Number(edit_business_data.covidOpportunityType=== 0)){
        this.addBusinessForm.controls['covidOpportunityType'].setValue('0');
      }
      this.addBusinessForm.controls['privateClinicFees'].setValue(edit_business_data.privateClinicFees);
      this.addBusinessForm.controls['targetPopulation'].setValue(edit_business_data.targetPopulation);
      this.addBusinessForm.controls['communityPartner'].setValue(edit_business_data.communityPartner == null ? '' : edit_business_data.communityPartner);
      this.addBusinessForm.controls['communityPartnerName'].setValue(edit_business_data.communityPartnerName);
      this.addBusinessForm.controls['healthDeptInvolved'].setValue(edit_business_data.healthDeptInvolved);
      this.addBusinessForm.controls['opportunityHostLocation'].setValue(edit_business_data.opportunityHostLocation);
      this.addBusinessForm.controls['vaccineInventorySource'].setValue(edit_business_data.vaccineInventorySource);
      this.addBusinessForm.controls['notes'].setValue(edit_business_data.notes);
      this.addBusinessForm.controls['AlternateFirstName'].setValue(edit_business_data.alternateFirstName);
      this.addBusinessForm.controls['AlternateLastName'].setValue(edit_business_data.alternateLastName);
      this.addBusinessForm.controls['AlternatePhone'].setValue(edit_business_data.alternatePhone);
      this.addBusinessForm.controls['AlternateJobTitle'].setValue(edit_business_data.alternateJobTitle);
      this.addBusinessForm.controls['AlternateEMail'].setValue(edit_business_data.alternateEMail);
      this.addBusinessForm.controls['Ext'].setValue(edit_business_data.ext);
      this.addBusinessForm.controls['AlternateContactExt'].setValue(edit_business_data.alternateContactExt);
      this.currentStateCode = edit_business_data.state;
      this.businessType = res.businessType;
      if (edit_business_data.outreachEffortList.length >= 1) {
        if (edit_business_data.outreachEffortList[0].outreachProgram == "SR") {
          this.addBusinessForm.controls['SeniorOutreach'].setValue(true);
          this.addBusinessForm.controls['assignedStore'].setValue(edit_business_data.outreachEffortList[0].storeId);
          if (!this.userProfile.isAdmin && this.isEdit) {
            this.isSeniorOutreach = true;

          }
          if (edit_business_data.outreachEffortList[0].isCommunityInitiated || edit_business_data.outreachEffortList[0].isCharityInitiated) {
            this.isCommunityCharity = true;
          }
          else {
            this.isCharityCommunityNew = true;
          }
          this.srProgramCheck = true;
          // this.ipProgramCheck=true;
          this.assignedStoreValue = edit_business_data.outreachEffortList[0].storeId;
        }
        this.enableDisableCovidOption = false;
        if (edit_business_data.outreachEffortList[0].outreachProgram == "IP") {
          this.addBusinessForm.controls['ImmunizationProgram'].setValue(true);
          edit_business_data.isCovidOpportunity ? this.addBusinessForm.controls['isCovidOpportunity'].setValue(edit_business_data.isCovidOpportunity):this.addBusinessForm.controls['isCovidOpportunity'].setValue(null);
          edit_business_data.isFluOpportunity ? this.addBusinessForm.controls['isFluOpportunity'].setValue(edit_business_data.isFluOpportunity):this.addBusinessForm.controls['isFluOpportunity'].setValue(null);
          edit_business_data.isNoContractOpportunity ? this.addBusinessForm.controls['isNoContractOpportunity'].setValue(edit_business_data.isNoContractOpportunity):this.addBusinessForm.controls['isNoContractOpportunity'].setValue(null);
          this.addBusinessForm.controls['assignedStore'].setValue(edit_business_data.outreachEffortList[0].storeId);
          this.addBusinessForm.controls['CommunityOutreach'].setValue(edit_business_data.outreachEffortList[0].isCommunityInitiated);
          this.addBusinessForm.controls['CharityHHSVoucher'].setValue(edit_business_data.outreachEffortList[0].isCharityInitiated);
          this.outreachOpportunity.outreachEffortList[0].isCharityInitiated = edit_business_data.outreachEffortList[0].isCharityInitiated;
          this.outreachOpportunity.outreachEffortList[0].isCommunityInitiated = edit_business_data.outreachEffortList[0].isCommunityInitiated;

          if (edit_business_data.outreachEffortList[0].isCommunityInitiated || edit_business_data.outreachEffortList[0].isCharityInitiated) {
            this.isCommunityCharity = true;
          }
          else {
            this.isCharityCommunityNew = true;
          }
          this.enableDisableCovidOption = true;
          if (!this.userProfile.isAdmin && this.isEdit) {
            this.isImmunizationProgram = true;
            this.enableDisableCovidOption = true;
          }
          this.ipProgramCheck = true;
          this.assignedStoreValue = edit_business_data.outreachEffortList[0].storeId;

          if (this.outreachOpportunity.isScheduledClinics) {
            this.isImmunizationProgram = true;
            this.enableDisableCovidOption = false;
          }

          if(!this.outreachOpportunity.isCovidOpportunity)
          {
            this.enableDisableCovidOption = true;
          }
        }
      }
      if (edit_business_data.outreachEffortList.length > 1) {
        if (edit_business_data.outreachEffortList[1].outreachProgram == "IP") {

          this.addBusinessForm.controls['ImmunizationProgram'].setValue(true);
          this.addBusinessForm.controls['assignedStore'].setValue(edit_business_data.outreachEffortList[1].storeId);
          this.addBusinessForm.controls['CommunityOutreach'].setValue(edit_business_data.outreachEffortList[1].isCommunityInitiated);
          this.addBusinessForm.controls['CharityHHSVoucher'].setValue(edit_business_data.outreachEffortList[1].isCharityInitiated);
          this.outreachOpportunity.outreachEffortList[1].isCharityInitiated = edit_business_data.outreachEffortList[1].isCharityInitiated;
          this.outreachOpportunity.outreachEffortList[1].isCommunityInitiated = edit_business_data.outreachEffortList[1].isCommunityInitiated;
          this.assignedStoreValue = edit_business_data.outreachEffortList[1].storeId;
          edit_business_data.isCovidOpportunity ? this.addBusinessForm.controls['isCovidOpportunity'].setValue(edit_business_data.isCovidOpportunity):this.addBusinessForm.controls['isCovidOpportunity'].setValue(null);
          edit_business_data.isFluOpportunity ? this.addBusinessForm.controls['isFluOpportunity'].setValue(edit_business_data.isFluOpportunity):this.addBusinessForm.controls['isFluOpportunity'].setValue(null);
          edit_business_data.isNoContractOpportunity ? this.addBusinessForm.controls['isNoContractOpportunity'].setValue(edit_business_data.isNoContractOpportunity):this.addBusinessForm.controls['isNoContractOpportunity'].setValue(null);

          if (edit_business_data.outreachEffortList[1].isCommunityInitiated || edit_business_data.outreachEffortList[1].isCharityInitiated) {
            this.isCommunityCharity = true;
          }
          else {
            this.isCharityCommunityNew = true;
          }
          if (!this.userProfile.isAdmin && this.isEdit) {
            this.isImmunizationProgram = true;
          }

          this.ipProgramCheck = true;
          this.enableDisableCovidOption = true;
          if (this.outreachOpportunity.isScheduledClinics) {
            this.isImmunizationProgram = true;
           this.enableDisableCovidOption = false;
          }
          
          if(!this.outreachOpportunity.isCovidOpportunity)
          {
            this.enableDisableCovidOption = true;
          }

        }
        if (edit_business_data.outreachEffortList[1].outreachProgram == "SR") {

          this.addBusinessForm.controls['SeniorOutreach'].setValue(true);
          this.addBusinessForm.controls['assignedStore'].setValue(edit_business_data.outreachEffortList[1].storeId);
          this.outreachOpportunity.outreachEffortList[1].isCharityInitiated = edit_business_data.outreachEffortList[1].isCharityInitiated;
          this.outreachOpportunity.outreachEffortList[1].isCommunityInitiated = edit_business_data.outreachEffortList[1].isCommunityInitiated;
          this.assignedStoreValue = edit_business_data.outreachEffortList[1].storeId;

          if (edit_business_data.outreachEffortList[1].isCommunityInitiated || edit_business_data.outreachEffortList[1].isCharityInitiated) {
            this.isCommunityCharity = true;
          }
          else {
            this.isCharityCommunityNew = true;
          }

          if (!this.userProfile.isAdmin && this.isEdit) {
            this.isSeniorOutreach = true;

          }
          this.srProgramCheck = true;
        }
      }
      // 792 Fix
      //   if(!this.isAdmin && this.isEdit && edit_business_data.covidOpportunityType == 1){
      //   this.disableStandard = true;
      // }
      if(!this.isAdmin && this.isEdit && this.outreachOpportunity.isScheduledClinics){
        this.disableFluLTCFFlu = true;
      }
      this.noContract = !this.addBusinessForm.controls['ImmunizationProgram'].value;
      if(this.isAdmin){
        this.noContract = false;
      }
      else {
        this.noContract = true;
      }
      // 792 fix
      // this.enableDisableCovidOption = edit_business_data.outreachEffortList.filter(item=>item.outreachProgram=="IP").length>0 && !this.outreachOpportunity.isScheduledClinics && this.isAdmin?null:false;
      this.isCommunityCharity = this.enableDisableCovidOption==null||this.enableDisableCovidOption== false?false:true;

      // Verify UserEmail this.loginUserEmailId 
      if(( this.loginUserEmailId.toLowerCase()=='jerry.v.thomas@walgreens.com' ||
      this.loginUserEmailId.toLowerCase()=='eli.zaguri@walgreens.com' ||
      this.loginUserEmailId.toLowerCase()=='tyrone.clark@walgreens.com' ||
      this.loginUserEmailId.toLowerCase()=='maili.dellinger@walgreens.com' ||
      this.loginUserEmailId.toLowerCase()=='csmith@tribpub.com' ) && this.outreachOpportunity.isScheduledClinics)
      {
         this.enableDisableCovidOption =true;
      }
      //if covidOpportunityType is 1 i.e. LTCF opportunity
      if (edit_business_data.covidOpportunityType === 1) {
        this.industries = this.ltcfOpportunityIndustries;
        
        if(this.covidClinicTypeValues?.findIndex(type => type?.name?.toLocaleLowerCase() === 'ltcf') == -1){
          this.covidClinicTypeValues.push({
            id: 'LTCF',
            name: 'LTCF'
          });
        }

        if(this.addBusinessForm.controls['covidClinicType'].value.toLowerCase() != 'ltcf'){
          this.addBusinessForm.controls['covidClinicType'].setValue('LTCF');
        }

        this.addBusinessForm.controls['covidClinicType'].disable();
      }
      //else if covidOpportunityType is 2 i.e. Standard opportunity
      else if (edit_business_data.covidOpportunityType === 2) {
        this.industries = this.standardOpportunityIndustries;

        const ltcfClinicTypeIndex = this.covidClinicTypeValues?.findIndex(type => type.name.toLocaleLowerCase() === 'ltcf');

        if(ltcfClinicTypeIndex !== -1){
          this.covidClinicTypeValues?.splice(ltcfClinicTypeIndex, 1);
          
          if(!this.covidClinicTypeValues?.some(type => type.name.toLocaleLowerCase() === 'other senior housing')){
            this.covidClinicTypeValues.push({
              id: 'Other senior housing',
              name: 'Other senior housing'
            });
          }
        }
      }

      if(edit_business_data?.industry?.length){
        this.canDisplayIndustries = true;
        this.addBusinessForm.controls['Industry'].setValue(edit_business_data.industry);
      }

      if(edit_business_data?.industry?.toLocaleLowerCase() === 'other' && edit_business_data?.others?.length){
        this.canDisplayOtherTextboxForIndustries = true;
        this.addBusinessForm.controls['Others'].setValue(edit_business_data.others);
      }
    });

  }
  onCharityHHSVoucherChange() {

    if (this.addBusinessForm.controls['CommunityOutreach'].enabled && this.addBusinessForm.controls['CharityHHSVoucher'].enabled) {
      if (this.addBusinessForm.controls['CommunityOutreach'].value == true) {
        this.dialogSummary = "Program";
        this.dialogMsg = "Community Outreach already selected, you cannot select both programs.";
        this.popupShow = true;

        this.addBusinessForm.controls['CharityHHSVoucher'].setValue(false);
        return;
      }
    }
    else {
      this.addBusinessForm.controls['CharityHHSVoucher'].setValue(this.addBusinessForm.controls['CharityHHSVoucher'].enabled);
    }

    if (this.isEdit && this.outreachOpportunity.outreachEffortList.length == 1) {
      if (this.outreachOpportunity.outreachEffortList[0].outreachProgram == "IP" && this.outreachOpportunity.outreachEffortList[0].isCharityInitiated == false && this.outreachOpportunity.outreachEffortList[0].isCommunityInitiated == false) {
        this.isCheckContractInitiatedCharity = true;
      }

    }

    if (this.isEdit && this.outreachOpportunity.outreachEffortList.length > 1) {
      if (this.outreachOpportunity.outreachEffortList[1].outreachProgram == "IP" && this.outreachOpportunity.outreachEffortList[1].isCharityInitiated == false && this.outreachOpportunity.outreachEffortList[1].isCommunityInitiated == false) {
        this.isCheckContractInitiatedCharity = true;
      }
    }
  }
  onCommunityOutreachChange() {
    if (this.addBusinessForm.controls['CommunityOutreach'].enabled && this.addBusinessForm.controls['CharityHHSVoucher'].enabled) {
      if (this.addBusinessForm.controls['CharityHHSVoucher'].value == true) {
        this.dialogSummary = "Program";
        this.dialogMsg = "Charity (WAG Free Flu Voucher) already selected, you cannot select both programs.";
        this.popupShow = true;
        this.addBusinessForm.controls['CommunityOutreach'].setValue(false);
      }
    }
    else {
      this.addBusinessForm.controls['CommunityOutreach'].setValue(this.addBusinessForm.controls['CommunityOutreach'].enabled);
    }

    if (this.isEdit && this.outreachOpportunity.outreachEffortList.length == 1) {
      if (this.outreachOpportunity.outreachEffortList[0].outreachProgram == "IP" && this.outreachOpportunity.outreachEffortList[0].isCharityInitiated == false && this.outreachOpportunity.outreachEffortList[0].isCommunityInitiated == false) {
        this.isCheckContractInitiatedCommunity = true;
      }

    }

    if (this.isEdit && this.outreachOpportunity.outreachEffortList.length > 1) {
      if (this.outreachOpportunity.outreachEffortList[1].outreachProgram == "IP" && this.outreachOpportunity.outreachEffortList[1].isCharityInitiated == false && this.outreachOpportunity.outreachEffortList[1].isCommunityInitiated == false) {
        this.isCheckContractInitiatedCommunity = true;
      }
    }
  }

  CheckIfUserIsAdmin(): Boolean {
    return true;
  }

  EditBusiness() {
    var store_id = SessionDetails.GetStoreId();
    var store_pk = SessionDetails.GetStorePk();

    if (!this.addBusinessForm.controls['ImmunizationProgram']?.value && !this.addBusinessForm.controls['SeniorOutreach']?.value) {
      this.addBusinessForm.controls['ImmunizationProgram']?.setErrors({ 'incorrect': true });
      this.addBusinessForm.controls['SeniorOutreach']?.setErrors({ 'incorrect': true });
      this.addBusinessForm.updateValueAndValidity();
    }
    else {
      this.addBusinessForm.controls['ImmunizationProgram']?.setErrors(null);
      this.addBusinessForm.controls['SeniorOutreach']?.setErrors(null);
      this.addBusinessForm.updateValueAndValidity();
    }

    if (!this.addBusinessForm.valid) {
      this.utility.validateAllFormFields(this.addBusinessForm);
      return;
    }
    if (this.validateMaxLengths()) {
      return;
    }
    else {
      if (!this.usPhoneNoRegEx.test(this.addBusinessForm.controls['Phone'].value)) {
        if (((this.addBusinessForm.controls['Phone'].value).indexOf("X") > -1)) {
          this.addBusinessForm.controls['Phone'].setValue(null);
          this.addBusinessForm.controls['Phone'].updateValueAndValidity();
          this.utility.validateAllFormFields(this.addBusinessForm);
          this.dialogSummary = "Error";
          this.dialogMsg = "Mandatory fields are empty or have invalid entries. Please check the fields highlighted in red and enter or correct the information.";
          this.popupShow = true;
          return false;
        }
        this.dialogSummary = "Phone number error.";
        this.dialogMsg = "Invalid phone number. Please enter valid phone number in phone field.";
        this.popupShow = true;
        return false;
      }
    }
    
    if (this.addBusinessForm.controls['EMail'].value) {
      if (!this.utility.validateEmail(this.addBusinessForm.controls['EMail'].value)) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "Please enter a valid Email";
        this.popupShow = true;
        return;
      }
    }

    if (this.addBusinessForm.controls['AlternateEMail'].value) {
      if (!this.utility.validateEmail(this.addBusinessForm.controls['AlternateEMail'].value)) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "Please enter a valid Alternate Email";
        this.popupShow = true;
        return;
      }
    }

    if (this.addBusinessForm.controls['covidOpportunityType'].value == '1' && this.addBusinessForm.controls['ccn'].value &&
      !this.ccnRegEx.test(this.addBusinessForm.controls['ccn'].value)) {
      this.dialogSummary = "Alert";
      this.dialogMsg = "CNN can only have combinition of Alphabets, Numbers or special characters";
      this.popupShow = true;
      return;
    }

    // if (this.addBusinessForm.controls['TollFree'].value!==""&&this.addBusinessForm.controls['TollFree'].value!==null&&this.addBusinessForm.controls['TollFree'].value!==undefined &&!this.usPhoneNoRegEx.test(this.addBusinessForm.controls['TollFree'].value)) {
    //   if (this.addBusinessForm.controls['TollFree'].value&&((this.addBusinessForm.controls['TollFree'].value).indexOf("X") > -1)) {
    //     this.addBusinessForm.controls['TollFree'].setValue(null);
    //     this.addBusinessForm.controls['TollFree'].updateValueAndValidity();
    //     this.utility.validateAllFormFields(this.addBusinessForm);
    //     this.dialogSummary = "Error";
    //     this.dialogMsg = "Invalid toll free number. Please enter valid toll free number in toll free field.";          
    //     this.popupShow = true;
    //     return ;
    //   }
    //   this.dialogSummary = "Toll free number error.";
    //     this.dialogMsg = "Invalid toll free number. Please enter valid toll free number in toll free field.";          
    //   this.popupShow = true;
    //   return ;
    // }

    if (this.addBusinessForm.controls['BusinessName'].value != "" && this.addBusinessForm.controls['Phone'].value != "" &&
      this.addBusinessForm.controls['Industry'].value != "" && this.addBusinessForm.controls['Address1'].value != "" &&
      this.addBusinessForm.controls['state'].value != "" && this.addBusinessForm.controls['City'].value != "" &&
      this.addBusinessForm.controls['ZipCode'].value != "") {
      if (!this.business_info) {
        this.utility.validateAllFormFields(this.addBusinessForm);

      }

      let SeniorOutReach = this.addBusinessForm.controls['SeniorOutreach'].value;
      let ImmunizationProgram = this.addBusinessForm.controls['ImmunizationProgram'].value;
      let store = this.addBusinessForm.controls['assignedStore'].value;

      if (SeniorOutReach == true && (store == null || store == "")) {

        this.dialogSummary = "Alert";
        this.dialogMsg = "Please enter the store id for Outreach Program.";
        this.popupShow = true;
        return;
      }
      else if (ImmunizationProgram == true && (store == null || store == "")) {

        this.dialogSummary = "Alert";
        this.dialogMsg = "Please enter the store id for Outreach Program.";
        this.popupShow = true;
        return;
      }
      var state = SessionDetails.GetState();
      if ((state == "DC") && this.outreachOpportunity.outreachEffortList.length >= 1 && this.userProfile.userRole.toLowerCase() != "healthcare supervisor") {
        var srList = this.outreachOpportunity.outreachEffortList.filter(f => f.outreachProgram == "SR");
        var ipList = this.outreachOpportunity.outreachEffortList.filter(f => f.outreachProgram == "IP");
        if (srList.length >= 1 && ipList.length <= 0 && ImmunizationProgram) {
          this.dialogSummary = "Alert";
          this.dialogMsg = state == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
          this.popupShow = true;
          return;
        }
      }
      let CharityHHSVoucher = this.addBusinessForm.controls['CharityHHSVoucher'].value;
      let CommunityOutreach = this.addBusinessForm.controls['CommunityOutreach'].value;

      if (((CharityHHSVoucher == true) || (CommunityOutreach == true)) && (ImmunizationProgram != true)) {
        let msg = "";
        this.dialogSummary = "Alert";
        this.dialogMsg = "The Auto Initiate options are only available for the Immunization Program. Please include the Immunization Program in your Outreach Program selection or deselect the Auto Initiate option.";
        this.popupShow = true;
        return;
      }
      let address1: string = this.addBusinessForm.controls['Address1'].value;
      let address2: string = this.addBusinessForm.controls['Address2'].value;
      let address = (address1 + address2).trim().replace(/[\[\]().-]+/g, '');
      address = address.replace(/\s/g, '');
      if (address.toLowerCase().includes("pobox")) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "Addresses containing a P.O. Box are not allowed. Please enter a valid location address.";
        this.popupShow = true;
        return;
      }
      if (this.outreachOpportunity.isScheduledClinics && this.isCheckContractInitiatedCharity && this.addBusinessForm.controls['CharityHHSVoucher'].value) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "The selected opportunity has already been Contract Initiated (Contracted Clinic), it cannot be changed to Charity(HHS) Voucher";
        this.popupShow = true;
        return;
      }
      if (this.outreachOpportunity.isScheduledClinics && this.isCheckContractInitiatedCommunity && this.addBusinessForm.controls['CommunityOutreach'].value) {
        this.dialogSummary = "Alert";
        this.dialogMsg = "The selected opportunity has already been Contract Initiated (Contracted Clinic), it cannot be changed to Community Outreach";
        this.popupShow = true;
        return;
      }
      this.updateOutreachOpportunity.businessPk = this.outreachOpportunity.businessPk;
      this.updateOutreachOpportunity.address1 = this.addBusinessForm.controls['Address1'].value;
      this.updateOutreachOpportunity.address2 = this.addBusinessForm.controls['Address2'].value;
      this.updateOutreachOpportunity.businessName = this.addBusinessForm.controls['BusinessName'].value;
      this.updateOutreachOpportunity.city = this.addBusinessForm.controls['City'].value;
      this.updateOutreachOpportunity.email = this.addBusinessForm.controls['EMail'].value;
      this.updateOutreachOpportunity.employmentSize = +this.addBusinessForm.controls['EmploymentSize'].value;
      this.updateOutreachOpportunity.residents = this.addBusinessForm.controls['Residents'].value;
      this.updateOutreachOpportunity.firstName = this.addBusinessForm.controls['FirstName'].value;
      this.updateOutreachOpportunity.industry = this.addBusinessForm.controls['Industry'].value;
      this.updateOutreachOpportunity.others = this.addBusinessForm.controls['Others']?.value ?? null;
      this.updateOutreachOpportunity.jobTitle = this.addBusinessForm.controls['JobTitle'].value;
      this.updateOutreachOpportunity.lastName = this.addBusinessForm.controls['LastName'].value;
      this.updateOutreachOpportunity.phone = this.addBusinessForm.controls['Phone'].value;
      this.updateOutreachOpportunity.state = this.addBusinessForm.controls['state'].value;
      //this.updateOutreachOpportunity.tollFree = this.addBusinessForm.controls['TollFree'].value;
      this.updateOutreachOpportunity.ext = this.addBusinessForm.controls['Ext'].value;
      this.updateOutreachOpportunity.alternateContactExt = this.addBusinessForm.controls['AlternateContactExt'].value;
      this.updateOutreachOpportunity.zipCode = this.addBusinessForm.controls['ZipCode'].value;
      this.updateOutreachOpportunity.pkhash = this.addBusinessForm.controls['pkhash'].value;
      this.updateOutreachOpportunity.covidOpportunityType = this.addBusinessForm.controls['covidOpportunityType'].value;
      this.updateOutreachOpportunity.fluOpportunityType = this.addBusinessForm.controls['fluOpportunityType'].value;
      this.updateOutreachOpportunity.covidClinicType = this.addBusinessForm.controls['covidClinicType'].value;
      this.updateOutreachOpportunity.LTCFType = this.addBusinessForm.controls['LTCFType'].value;
      this.updateOutreachOpportunity.ccn = this.addBusinessForm.controls['ccn'].value;
      this.updateOutreachOpportunity.notes = this.addBusinessForm.controls['notes'].value;
      if (this.updateOutreachOpportunity.covidOpportunityType == null || Number(this.updateOutreachOpportunity.covidOpportunityType) == 0) {
        this.updateOutreachOpportunity.covidOpportunityType = 0;
      }
      this.updateOutreachOpportunity.isCovidOpportunity = this.addBusinessForm.controls['isCovidOpportunity'].value && Number(this.addBusinessForm.controls['covidOpportunityType'].value) != 3 ? this.addBusinessForm.controls['isCovidOpportunity'].value : null;
      this.updateOutreachOpportunity.isFluOpportunity = (Number(this.addBusinessForm.controls['fluOpportunityType'].value) == 3 || Number(this.addBusinessForm.controls['fluOpportunityType'].value) == 0) ? true : null;
      this.updateOutreachOpportunity.isNoContractOpportunity = this.addBusinessForm.controls['isNoContractOpportunity'].value;
      this.updateOutreachOpportunity.privateClinicFees = this.addBusinessForm.controls['privateClinicFees'].value;
      this.updateOutreachOpportunity.targetPopulation = this.addBusinessForm.controls['targetPopulation'].value;
      this.updateOutreachOpportunity.communityPartner = this.addBusinessForm.controls['communityPartner'].value;
      this.updateOutreachOpportunity.communityPartnerName = this.addBusinessForm.controls['communityPartnerName'].value;
      this.updateOutreachOpportunity.healthDeptInvolved = this.addBusinessForm.controls['healthDeptInvolved'].value;
      this.updateOutreachOpportunity.opportunityHostLocation = this.addBusinessForm.controls['opportunityHostLocation'].value;
      this.updateOutreachOpportunity.vaccineInventorySource = this.addBusinessForm.controls['vaccineInventorySource'].value;
      this.updateOutreachOpportunity.alternateFirstName = this.addBusinessForm.controls['AlternateFirstName'].value;
      this.updateOutreachOpportunity.alternateLastName = this.addBusinessForm.controls['AlternateLastName'].value;
      this.updateOutreachOpportunity.alternatePhone = this.addBusinessForm.controls['AlternatePhone'].value;
      this.updateOutreachOpportunity.alternateJobTitle = this.addBusinessForm.controls['AlternateJobTitle'].value;
      this.updateOutreachOpportunity.alternateEMail = this.addBusinessForm.controls['AlternateEMail'].value;
      this.updateOutreachOpportunity.isScheduledClinics = this.outreachOpportunity.isScheduledClinics ? 1 : 0;
      this.updateOutreachOpportunity.isCheckDnc = this.isDNC ? 1 : 0;
      this.updateOutreachOpportunity.isOverrideDupCheck = this.isOverrideDupCheck ? 1 : 0;
      this.updateOutreachOpportunity.isRemoveContactLog = this.isRemoveContactLog ? 1 : 0;
      this.updateOutreachOpportunity.canReassign = this.canReassign ? 1 : 0;

      this.updateOutreachOpportunity.outreachEffortList = [];
      if (this.addBusinessForm.controls['SeniorOutreach'].value) {
        var outreachEffort = new OutreachEffortList();
        if (this.srProgramCheck) {
          for (var i = 0; i < this.outreachOpportunity.outreachEffortList.length; i++) {
            if (this.outreachOpportunity.outreachEffortList[i].outreachProgram == "SR") {
              outreachEffort.outreachBusinessPk = this.outreachOpportunity.outreachEffortList[i].outreachBusinessPk;
              outreachEffort.outreachProgram = "SR";
              outreachEffort.existingStoreId = this.outreachOpportunity.outreachEffortList[i].existingStoreId;
            }
          }
        }
        else {
          outreachEffort.outreachBusinessPk = -1;
          outreachEffort.outreachProgram = "SR";
          outreachEffort.existingStoreId = -1;
        }
        if (this.searching) {
          let destinationState: string = "";
          if (!this.validateRestrictedStatesReassignment()) {
            this.dialogSummary = "Alert";
            var storeId = this.addBusinessForm.controls['assignedStore'].value.storeId;
            if (storeId == undefined) {
              storeId = this.addBusinessForm.controls['assignedStore'].value;
            }
            var filterSearchRes = this.storeSearchRsp.filter(store => store.storeId == storeId);
            if (filterSearchRes.length > 0) {
              this.dialogMsg = filterSearchRes[0].state == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
              this.popupShow = true;
              var orginalStoreId = SessionDetails.GetStoreId();
              this.searching = false;
              this.addBusinessForm.controls['assignedStore'].setValue(orginalStoreId);
              this.addBusinessForm.controls['assignedStore'].updateValueAndValidity();
            }
            return;
          }
          if (this.addBusinessForm.controls['assignedStore'].value.storeId != undefined) {
            outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value.storeId;
            outreachEffort.storePk = this.addBusinessForm.controls['assignedStore'].value.storePk;
          }
          else {
            outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value;
          }
        }
        else {
          outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value;
          outreachEffort.storePk = store_pk;
        }

        outreachEffort.isChecked = 1;

        if (this.addBusinessForm.controls['CommunityOutreach'].value) {
          outreachEffort.isCommunityInitiated = 1
        }
        else {
          outreachEffort.isCommunityInitiated = 0;
        }
        if (this.addBusinessForm.controls['CharityHHSVoucher'].value) {
          outreachEffort.isCharityInitiated = 1
        }
        else {
          outreachEffort.isCharityInitiated = 0;
        }

        this.updateOutreachOpportunity.outreachEffortList.push(outreachEffort);
      }


      if (this.addBusinessForm.controls['ImmunizationProgram'].value) {
        var outreachEffort = new OutreachEffortList();
        if (this.ipProgramCheck) {
          for (var i = 0; i < this.outreachOpportunity.outreachEffortList.length; i++) {
            if (this.outreachOpportunity.outreachEffortList[i].outreachProgram == "IP") {
              outreachEffort.outreachBusinessPk = this.outreachOpportunity.outreachEffortList[i].outreachBusinessPk;
              outreachEffort.outreachProgram = "IP";
              outreachEffort.existingStoreId = this.outreachOpportunity.outreachEffortList[i].existingStoreId;
            }
          }
          this.updateOutreachOpportunity.isCovidOpportunity = this.addBusinessForm.controls['isCovidOpportunity'].value && Number(this.addBusinessForm.controls['covidOpportunityType'].value) != 3 ? true : false;
          this.updateOutreachOpportunity.isFluOpportunity = this.addBusinessForm.controls['isFluOpportunity'].value && (Number(this.addBusinessForm.controls['fluOpportunityType'].value) == 3 || Number(this.addBusinessForm.controls['fluOpportunityType'].value) == 0) ? true : false;
          this.updateOutreachOpportunity.isNoContractOpportunity = this.addBusinessForm.controls['isNoContractOpportunity'].value ? true : false;
        }
        else {
          outreachEffort.outreachBusinessPk = -1;
          outreachEffort.outreachProgram = "IP";
          outreachEffort.existingStoreId = -1;
        }

        if (this.searching) {
          if (this.addBusinessForm.controls['assignedStore'].value.storeId != undefined) {
            outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value.storeId;
            outreachEffort.storePk = this.addBusinessForm.controls['assignedStore'].value.storePk;
          }
          else {
            outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value;
          }
        }
        else {
          outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value;
          outreachEffort.storePk = store_pk;
        }
        outreachEffort.isChecked = 1;
        if (this.addBusinessForm.controls['CommunityOutreach'].value) {
          outreachEffort.isCommunityInitiated = 1
        }
        else {
          outreachEffort.isCommunityInitiated = 0;
        }
        if (this.addBusinessForm.controls['CharityHHSVoucher'].value) {
          outreachEffort.isCharityInitiated = 1
        }
        else {
          outreachEffort.isCharityInitiated = 0;
        }

        this.updateOutreachOpportunity.outreachEffortList.push(outreachEffort);

      }

      if (this.ipProgramCheck && this.addBusinessForm.controls['ImmunizationProgram'].value == false) {
        var outreachEffort = new OutreachEffortList();
        for (var i = 0; i < this.outreachOpportunity.outreachEffortList.length; i++) {
          if (this.outreachOpportunity.outreachEffortList[i].outreachProgram == "IP") {
            outreachEffort.outreachBusinessPk = this.outreachOpportunity.outreachEffortList[i].outreachBusinessPk;
            outreachEffort.outreachProgram = "IP";
            outreachEffort.existingStoreId = this.outreachOpportunity.outreachEffortList[i].existingStoreId;
            outreachEffort.storePk = this.outreachOpportunity.outreachEffortList[i].storePk;
            outreachEffort.storeId = this.outreachOpportunity.outreachEffortList[i].storeId;
            outreachEffort.isCharityInitiated = this.outreachOpportunity.outreachEffortList[i].isCharityInitiated;
            outreachEffort.isCommunityInitiated = this.outreachOpportunity.outreachEffortList[i].isCommunityInitiated;
          }
        }
        outreachEffort.isChecked = 0;
        this.updateOutreachOpportunity.outreachEffortList.push(outreachEffort);
      }

      if (this.srProgramCheck && this.addBusinessForm.controls['SeniorOutreach'].value == false) {
        var outreachEffort = new OutreachEffortList();
        for (var i = 0; i < this.outreachOpportunity.outreachEffortList.length; i++) {
          if (this.outreachOpportunity.outreachEffortList[i].outreachProgram == "SR") {
            outreachEffort.outreachBusinessPk = this.outreachOpportunity.outreachEffortList[i].outreachBusinessPk;
            outreachEffort.outreachProgram = "SR";
            outreachEffort.existingStoreId = this.outreachOpportunity.outreachEffortList[i].existingStoreId;
            outreachEffort.storePk = this.outreachOpportunity.outreachEffortList[i].storePk;
            outreachEffort.storeId = this.outreachOpportunity.outreachEffortList[i].storeId;
            outreachEffort.isCharityInitiated = this.outreachOpportunity.outreachEffortList[i].isCharityInitiated;
            outreachEffort.isCommunityInitiated = this.outreachOpportunity.outreachEffortList[i].isCommunityInitiated;
          }
        }
        outreachEffort.isChecked = 0;
        this.updateOutreachOpportunity.outreachEffortList.push(outreachEffort);
      }
      // Check for duplicate Opportunity 
      var duplicateAddress = new DuplicateBusiness();
      duplicateAddress.totalRecs = 0;
      duplicateAddress.totalS = 0;
      duplicateAddress.primaryAddress.storeId = 13;
      duplicateAddress.primaryAddress.businessPk = this.outreachOpportunity.businessPk;
      duplicateAddress.primaryAddress.businessName = this.addBusinessForm.controls['BusinessName'].value;
      duplicateAddress.primaryAddress.outreachPk = -1;
      duplicateAddress.primaryAddress.phone = this.addBusinessForm.controls['Phone'].value;
      duplicateAddress.primaryAddress.address1 = this.addBusinessForm.controls['Address1'].value;
      duplicateAddress.primaryAddress.address2 = this.addBusinessForm.controls['Address2'].value;
      duplicateAddress.primaryAddress.city = this.addBusinessForm.controls['City'].value;
      duplicateAddress.primaryAddress.state = this.addBusinessForm.controls['state'].value;
      duplicateAddress.primaryAddress.zipCode = this.addBusinessForm.controls['ZipCode'].value;
      var state = SessionDetails.GetState();

      if (!(this.currentStateCode == this.addBusinessForm.controls['state'].value) && state != this.addBusinessForm.controls['state'].value) {
        // this.confirmState(duplicateAddress);
        this.dialogSummary = 'Alert';
        this.dialogMsg = 'The store you are assigning this opportunity to is in a different state than the opportunity’s location.';
        this.diffStateOverridePopupShow = true;
      }
      else {
        if (!this.addBusinessForm.controls['ImmunizationProgram'].value && !this.addBusinessForm.controls['SeniorOutreach'].value) {
          this.removeIPSR = true;
        }
        else {
          if (this.searching && this.addBusinessForm.controls['assignedStore'].value.storeId == undefined) {
            this._headerservice.getStoresProfile(["District Manager", "District Lead", "Healthcare Supervisor",
              "Director - Rx and Retail Ops", "Regional Vice President",
              "Regional Healthcare Director"].indexOf(this.userProfile.userRole) > -1 ? 41 : this.userProfile.userPk, this.addBusinessForm.controls['assignedStore'].value, 0).subscribe((res: any) => {
                if (res.data.length > 0) {
                  this.storeSearchRsp = res.data;
                  if (!this.validateRestrictedStatesReassignment()) {
                    this.dialogSummary = "Alert";
                    var storeId = this.addBusinessForm.controls['assignedStore'].value;
                    var filterSearchRes = this.storeSearchRsp.filter(store => store.storeId == storeId);
                    if (filterSearchRes.length > 0) {
                      this.dialogMsg = filterSearchRes[0].state == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
                      this.popupShow = true;
                      var orginalStoreId = SessionDetails.GetStoreId();
                      this.searching = false;
                      this.addBusinessForm.controls['assignedStore'].setValue(orginalStoreId);
                      this.addBusinessForm.controls['assignedStore'].updateValueAndValidity();
                    }
                    return;
                  }
                  let storeProfile = SessionDetails.getStoreProfileData();
                  let assignedStore: Number = this.addBusinessForm.controls['assignedStore'].value;
                  let selectedStore = res.data.filter(store => store.storeId === Number(assignedStore));

                  if ((selectedStore.length > 0 && Number(storeProfile[0].regionNumber) === Number(selectedStore[0].regionNumber))
                    || this.userProfile.userRole === "Admin") {
                    this.updateOutreachOpportunity.outreachEffortList.forEach(list => {
                      list.storePk = selectedStore[0].storePk;
                    });
                    this.updateOpportunity();
                  }
                  else {
                    this.dialogSummary = "Alert";
                    this.dialogMsg = String.Format('Store #{0} is not within your area of responsibility or does not exist.', this.addBusinessForm.controls['assignedStore'].value);
                    this.popupShow = true;
                    return;
                  }
                }
                else {
                  this.dialogSummary = "Alert";
                  this.dialogMsg = String.Format('Store #{0} is not within your area of responsibility or does not exist.', this.addBusinessForm.controls['assignedStore'].value);
                  this.popupShow = true;
                  return;
                }
              });
          }
          else {
            if (!this.validateRestrictedStatesReassignment()) {
              this.dialogSummary = "Alert";
              var storeId = this.addBusinessForm.controls['assignedStore'].value.storeId;
              var filterSearchRes = this.storeSearchRsp.filter(store => store.storeId == storeId);
              if (filterSearchRes.length > 0) {
                this.dialogMsg = filterSearchRes[0].state == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
                this.popupShow = true;
                var orginalStoreId = SessionDetails.GetStoreId();
                this.searching = false;
                this.addBusinessForm.controls['assignedStore'].setValue(orginalStoreId);
                this.addBusinessForm.controls['assignedStore'].updateValueAndValidity();
              }
              return;
            }
            this.updateOpportunity();
          }

        }
      }

    }

  }

  doNotRemove() {
    this.removeIPSR = false;
  }
  validateRestrictedStatesReassignment() {
    var storeId = this.addBusinessForm.controls['assignedStore'].value.storeId;
    if (storeId == undefined || storeId == null) {
      storeId = this.addBusinessForm.controls['assignedStore'].value;
    }
    let returnValue: boolean = true;
    if (storeId != SessionDetails.GetStoreId()) {
      var current_date = new Date();
      var filterSearchRes = this.storeSearchRsp!==null&&this.storeSearchRsp!==undefined ? this.storeSearchRsp.filter(store => store.storeId == storeId):[];

      if (SessionDetails.GetState() == "MO") {
        if (filterSearchRes.length > 0 && filterSearchRes[0].state == "DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
          returnValue = false;
        }
      }
      else if (SessionDetails.GetState() == "DC") {
        if (filterSearchRes.length > 0 && filterSearchRes[0].state == "MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
          returnValue = false;
        }
      }
      else if (SessionDetails.GetState() != "MO" || SessionDetails.GetState() != "DC") {
        if (filterSearchRes.length > 0 && ((filterSearchRes[0].state == "MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO))
          || (filterSearchRes[0].state == "DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)))) {
          returnValue = false;
        }
      }
    }
    return returnValue;
  }
  removeIPSROk() {
    this.removeIPSR = false;
    this.updateOpportunity();
  }

  onUpdateSuccess() {
    if(this.businessData.outreachEffortList.filter(p=>p.outreachProgram == 'IP') && this.businessData.outreachEffortList.filter(p=>p.outreachProgram == 'IP').length > 0 && this.businessData.outreachEffortList.filter(p=>p.outreachProgram == 'IP')[0].outreachBusinessPk == -1)
    {
      this._AddBusinessService.getStoreBusinessDetails(this.businessData.businessPk).subscribe((res:any)=>
      {
        this.businessData.outreachEffortList = res.outreachEffortList;
        this.updateBusinessShow = false;
        this.onUpdateNavinagtion();
      })
    }
    else
    {
      this.updateBusinessShow = false;
      this.onUpdateNavinagtion();
    }
  }
  updateOpportunity() {
    this.disable_button = true;
    this._AddBusinessService.updateOpportunity(this.updateOutreachOpportunity, this.userProfile.userPk, this.isDNC, this.isOverrideDupCheck).subscribe(res => {
      this.disable_button = false;
      this.businessData = res;
      if (res.errorS == null) {
        //sucessfully updated.
        this.dialogSummary = "Message";
        this.dialogMsg = "Opportunity profile updated successfully.";
        this.updateBusinessShow = true;

      }
      else if (res.errorS.errorCode == "-1") {
        this.dialogSummary = "DUPLICATE WARNING";
        this.dialogMsg = String.Format('There is already an opportunity assigned to a Walgreens store with the same {0}. To eliminate duplicate follow up activity, this opportunity will not be added to your contacts, unless required to setup a COVID clinic.', res.errorS.errorMessage);
        this.overridePopupShow = true;
      }
      else if (res.errorS.errorCode == "-2") {
        this.dialogSummary = "Opportunity matches DNC Client";
        this.dialogMsg = this.dncText;
        this.updatePopupShow = true;
      }
      else if (res.errorS.errorCode == "-3") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.popupShow = true;
      }
      else if (res.errorS.errorCode == "-4") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.overridePopupShow = true;
      }
      else if (res.errorS.errorCode == "-5") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.overridePopupShow = true;
      }
      else if (res.errorS.errorCode == "-6") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.popupShow = true;
      }
      else if (res.errorS.errorCode == "-7") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.popupShow = true;
      }
      else if (res.errorS.errorCode == "-8") {
        this.dialogSummary = "Alert";
        this.dialogMsg = res.errorS.errorMessage;
        this.logExistsPopupShow = true;
      }
      else if (res.errorS.errorCode == "-9") {
        this.dialogSummary = "Alert";
        this.dialogMsg = ErrorMessages.CoCharityForUnapprovedOpportunity;
        this.popupShow = true;
      }
    },
      error => {
        this.disable_button = false;
        this.dialogSummary = "Server Error";
        this.dialogMsg = "Server issue while updating the opportunity";
        this.popupShow = true;
      }
    );
  }

  onClick(action: string) {
    if (action == 'DeactivateOpp') {
      this.Action = '';
      this.Cancel();
    }
    else
    this.popupShow = false;
  }
  onSuccess() {
    this.popupSuccessShow = false;

    if (this.addBusinessForm.controls['ImmunizationProgram'].value) {
      if (this.businessData.businessPk > 0) {
        var log_outreach_status = new FollowUp();
        log_outreach_status.logOutreachStatus.businessPk = this.businessData.businessPk;
        log_outreach_status.logOutreachStatus.firstName = this.businessData.firstName;
        log_outreach_status.logOutreachStatus.lastName = this.businessData.lastName;
        log_outreach_status.logOutreachStatus.jobTitle = this.businessData.jobTitle;
        log_outreach_status.logOutreachStatus.feedback = this.businessData.feedback;
        log_outreach_status.logOutreachStatus.contactDate = new Date();
        log_outreach_status.logOutreachStatus.createdBy = this.userProfile.userPk;
        if (this.addBusinessForm.value.CharityHHSVoucher == true) {
          this.businessData.outreachEffortList.forEach(list => {
            if (list.outreachProgram == "IP") {
              log_outreach_status.logOutreachStatus.outreachBusinessPk = list.outreachBusinessPk;
              log_outreach_status.logOutreachStatus.outreachProgram = list.outreachProgram;
            }
          });
          log_outreach_status.logOutreachStatus.outreachStatusTitle = "Charity (WAG Free Flu Voucher)";
          log_outreach_status.logOutreachStatus.outreachStatusId = 8;
          log_outreach_status.logOutreachStatus.feedback = "";
          SessionDetails.EventDetails(log_outreach_status);
          SessionDetails.opportunitiesData(this.businessData);
          this.prouter.navigate(['/communityoutreach/charityprogram']);

        }
        else if (this.addBusinessForm.value.CommunityOutreach == true) {
          this.businessData.outreachEffortList.forEach(list => {
            if (list.outreachProgram == "IP") {
              log_outreach_status.logOutreachStatus.outreachBusinessPk = list.outreachBusinessPk;
              log_outreach_status.logOutreachStatus.outreachProgram = list.outreachProgram;
            }
          });

          log_outreach_status.logOutreachStatus.outreachStatusTitle = "Community Outreach";
          log_outreach_status.logOutreachStatus.outreachStatusId = 12;
          log_outreach_status.logOutreachStatus.feedback = "";
          SessionDetails.EventDetails(log_outreach_status);
          SessionDetails.opportunitiesData(this.businessData);
          this.prouter.navigate(['/communityoutreach/communityoutreach']);

        }
        else {
          this.prouter.navigate(['/communityoutreach/storehome']);
        }
      }
      else {
        this.prouter.navigate(['/communityoutreach/storehome']);
      }
    }
    else {
      this.prouter.navigate(['/communityoutreach/storehome']);
    }
  }

  formatter = (result: any) => this.searching == true ? result.storeId + " - " +
    result.address + ", " + result.city + ","
    + result.state + " " + result.zip || '' : result;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this._headerservice.storeSearch(this.userProfile.userPk, term.trim(), 0).pipe(
          tap((data) => {
            this.storeSearchRsp = data;
          }),
          catchError(() => {
            return observableOf([]);
          }),)),)
  onDNCClick() {
    this.updatePopupShow = false;
  }
  onOverrideDNC() {
    this.isDNC = false;
    this.overrideUdpateBusiness();
  }

  overrideUdpateBusiness() {
    this.updatePopupShow = false;
    var store_id = SessionDetails.GetStoreId();
    var store_pk = SessionDetails.GetStorePk();
    this.updateOutreachOpportunity.businessPk = this.outreachOpportunity.businessPk;
    this.updateOutreachOpportunity.address1 = this.addBusinessForm.controls['Address1'].value;
    this.updateOutreachOpportunity.address2 = this.addBusinessForm.controls['Address2'].value;
    this.updateOutreachOpportunity.businessName = this.addBusinessForm.controls['BusinessName'].value;
    this.updateOutreachOpportunity.city = this.addBusinessForm.controls['City'].value;
    this.updateOutreachOpportunity.email = this.addBusinessForm.controls['EMail'].value;
    this.updateOutreachOpportunity.employmentSize = +this.addBusinessForm.controls['EmploymentSize'].value;
    this.updateOutreachOpportunity.residents = +this.addBusinessForm.controls['EmploymentSize'].value;
    this.updateOutreachOpportunity.firstName = this.addBusinessForm.controls['FirstName'].value;
    this.updateOutreachOpportunity.industry = this.addBusinessForm.controls['Industry'].value;
    this.updateOutreachOpportunity.others = this.addBusinessForm.controls['Others']?.value ?? null;
    this.updateOutreachOpportunity.jobTitle = this.addBusinessForm.controls['JobTitle'].value;
    this.updateOutreachOpportunity.lastName = this.addBusinessForm.controls['LastName'].value;
    this.updateOutreachOpportunity.phone = this.addBusinessForm.controls['Phone'].value;
    this.updateOutreachOpportunity.state = this.addBusinessForm.controls['state'].value;
    //this.updateOutreachOpportunity.tollFree = this.addBusinessForm.controls['TollFree'].value;
    this.updateOutreachOpportunity.ext = this.addBusinessForm.controls['Ext'].value;
    this.updateOutreachOpportunity.alternateContactExt = this.addBusinessForm.controls['AlternateContactExt'].value;
    this.updateOutreachOpportunity.zipCode = this.addBusinessForm.controls['ZipCode'].value;
    this.updateOutreachOpportunity.isScheduledClinics = this.outreachOpportunity.isScheduledClinics ? 1 : 0;
    this.updateOutreachOpportunity.isCheckDnc = this.isDNC ? 1 : 0;
    this.updateOutreachOpportunity.isOverrideDupCheck = this.isOverrideDupCheck ? 1 : 0;
    this.updateOutreachOpportunity.isRemoveContactLog = this.isRemoveContactLog ? 1 : 0;
    this.updateOutreachOpportunity.canReassign = this.canReassign ? 1 : 0;

    this.updateOutreachOpportunity.outreachEffortList = [];
    if (this.addBusinessForm.controls['SeniorOutreach'].value) {
      var outreachEffort = new OutreachEffortList();
      if (this.srProgramCheck) {
        for (var i = 0; i < this.outreachOpportunity.outreachEffortList.length; i++) {
          if (this.outreachOpportunity.outreachEffortList[i].outreachProgram == "SR") {
            outreachEffort.outreachBusinessPk = this.outreachOpportunity.outreachEffortList[i].outreachBusinessPk;
            outreachEffort.outreachProgram = "SR";
            outreachEffort.existingStoreId = this.outreachOpportunity.outreachEffortList[i].existingStoreId;
          }
        }
      }
      else {
        outreachEffort.outreachBusinessPk = -1;
        outreachEffort.outreachProgram = "SR";
        outreachEffort.existingStoreId = -1;
      }
      if (this.searching) {
        if (this.addBusinessForm.controls['assignedStore'].value.storeId != undefined) {
          outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value.storeId;
          outreachEffort.storePk = this.addBusinessForm.controls['assignedStore'].value.storePk;
        }
        else {
          outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value;
        }
      }
      else {
        outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value;
        outreachEffort.storePk = store_pk;
      }
      outreachEffort.isChecked = 1;

      if (this.addBusinessForm.controls['CommunityOutreach'].value) {
        outreachEffort.isCommunityInitiated = 1
      }
      else {
        outreachEffort.isCommunityInitiated = 0;
      }
      if (this.addBusinessForm.controls['CharityHHSVoucher'].value) {
        outreachEffort.isCharityInitiated = 1
      }
      else {
        outreachEffort.isCharityInitiated = 0;
      }
      this.updateOutreachOpportunity.outreachEffortList.push(outreachEffort);
    }


    if (this.addBusinessForm.controls['ImmunizationProgram'].value) {
      var outreachEffort = new OutreachEffortList();
      if (this.ipProgramCheck) {
        for (var i = 0; i < this.outreachOpportunity.outreachEffortList.length; i++) {
          if (this.outreachOpportunity.outreachEffortList[i].outreachProgram == "IP") {
            outreachEffort.outreachBusinessPk = this.outreachOpportunity.outreachEffortList[i].outreachBusinessPk;
            outreachEffort.outreachProgram = "IP";
            outreachEffort.existingStoreId = this.outreachOpportunity.outreachEffortList[i].existingStoreId;
          }
        }
        this.updateOutreachOpportunity.isCovidOpportunity =  this.addBusinessForm.controls['isCovidOpportunity'].value&&Number(this.addBusinessForm.controls['covidOpportunityType'].value)!=3? true: false;
        this.updateOutreachOpportunity.isFluOpportunity =  this.addBusinessForm.controls['isFluOpportunity'].value&&(Number(this.addBusinessForm.controls['fluOpportunityType'].value)==3 || Number(this.addBusinessForm.controls['fluOpportunityType'].value)==0)? true: false;
        this.updateOutreachOpportunity.isNoContractOpportunity =  this.addBusinessForm.controls['isNoContractOpportunity'].value? true: false;
      }
      else {
        outreachEffort.outreachBusinessPk = -1;
        outreachEffort.outreachProgram = "IP";
        outreachEffort.existingStoreId = -1;
      }
      if (this.searching) {
        if (this.addBusinessForm.controls['assignedStore'].value.storeId != undefined) {
          outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value.storeId;
          outreachEffort.storePk = this.addBusinessForm.controls['assignedStore'].value.storePk;
        }
        else {
          outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value;
        }
      }
      else {
        outreachEffort.storeId = this.addBusinessForm.controls['assignedStore'].value;
        outreachEffort.storePk = store_pk;
      }
      outreachEffort.isChecked = 1;
      if (this.addBusinessForm.controls['CommunityOutreach'].value) {
        outreachEffort.isCommunityInitiated = 1
      }
      else {
        outreachEffort.isCommunityInitiated = 0;
      }
      if (this.addBusinessForm.controls['CharityHHSVoucher'].value) {
        outreachEffort.isCharityInitiated = 1
      }
      else {
        outreachEffort.isCharityInitiated = 0;
      }
      this.updateOutreachOpportunity.outreachEffortList.push(outreachEffort);
    }

    if (this.ipProgramCheck && this.addBusinessForm.controls['ImmunizationProgram'].value == false) {
      var outreachEffort = new OutreachEffortList();
      for (var i = 0; i < this.outreachOpportunity.outreachEffortList.length; i++) {
        if (this.outreachOpportunity.outreachEffortList[i].outreachProgram == "IP") {
          outreachEffort.outreachBusinessPk = this.outreachOpportunity.outreachEffortList[i].outreachBusinessPk;
          outreachEffort.outreachProgram = "IP";
          outreachEffort.existingStoreId = this.outreachOpportunity.outreachEffortList[i].existingStoreId;
          outreachEffort.storePk = this.outreachOpportunity.outreachEffortList[i].storePk;
          outreachEffort.storeId = this.outreachOpportunity.outreachEffortList[i].storeId;
          outreachEffort.isCharityInitiated = this.outreachOpportunity.outreachEffortList[i].isCharityInitiated;
          outreachEffort.isCommunityInitiated = this.outreachOpportunity.outreachEffortList[i].isCommunityInitiated;
        }
      }
      outreachEffort.isChecked = 0;
      this.updateOutreachOpportunity.outreachEffortList.push(outreachEffort);
this.outreachOpportunity.isCovidOpportunity =  null;
      this.outreachOpportunity.isNoContractOpportunity =  null;
    }

    if (this.srProgramCheck && this.addBusinessForm.controls['SeniorOutreach'].value == false) {
      var outreachEffort = new OutreachEffortList();
      for (var i = 0; i < this.outreachOpportunity.outreachEffortList.length; i++) {
        if (this.outreachOpportunity.outreachEffortList[i].outreachProgram == "SR") {
          outreachEffort.outreachBusinessPk = this.outreachOpportunity.outreachEffortList[i].outreachBusinessPk;
          outreachEffort.outreachProgram = "SR";
          outreachEffort.existingStoreId = this.outreachOpportunity.outreachEffortList[i].existingStoreId;
          outreachEffort.storePk = this.outreachOpportunity.outreachEffortList[i].storePk;
          outreachEffort.storeId = this.outreachOpportunity.outreachEffortList[i].storeId;
          outreachEffort.isCharityInitiated = this.outreachOpportunity.outreachEffortList[i].isCharityInitiated;
          outreachEffort.isCommunityInitiated = this.outreachOpportunity.outreachEffortList[i].isCommunityInitiated;
        }
      }
      outreachEffort.isChecked = 0;
      this.updateOutreachOpportunity.outreachEffortList.push(outreachEffort);
    }
    // Check for duplicate Opportunity 
    var duplicateAddress = new DuplicateBusiness();
    duplicateAddress.totalRecs = 0;
    duplicateAddress.totalS = 0;
    duplicateAddress.primaryAddress.storeId = 13;
    duplicateAddress.primaryAddress.businessPk = this.outreachOpportunity.businessPk;
    duplicateAddress.primaryAddress.businessName = this.addBusinessForm.controls['BusinessName'].value;
    duplicateAddress.primaryAddress.outreachPk = -1;
    duplicateAddress.primaryAddress.phone = this.addBusinessForm.controls['Phone'].value;
    duplicateAddress.primaryAddress.address1 = this.addBusinessForm.controls['Address1'].value;
    duplicateAddress.primaryAddress.address2 = this.addBusinessForm.controls['Address2'].value;
    duplicateAddress.primaryAddress.city = this.addBusinessForm.controls['City'].value;
    duplicateAddress.primaryAddress.state = this.addBusinessForm.controls['state'].value;
    duplicateAddress.primaryAddress.zipCode = this.addBusinessForm.controls['ZipCode'].value;

    if (this.searching && this.addBusinessForm.controls['assignedStore'].value.storeId == undefined) {
      this._headerservice.getStoresProfile(["District Manager","District Lead","Healthcare Supervisor",
      "Director - Rx and Retail Ops","Regional Vice President",
      "Regional Healthcare Director"].indexOf(this.userProfile.userRole)>-1?41:this.userProfile.userPk, this.addBusinessForm.controls['assignedStore'].value, 0).subscribe((res:any) => {
        if (res.data.length > 0) {
          this.storeSearchRsp = res.data;
          if (!this.validateRestrictedStatesReassignment()) {
            this.dialogSummary = "Alert";
            var storeId = this.addBusinessForm.controls['assignedStore'].value;
            var filterSearchRes = this.storeSearchRsp.filter(store => store.storeId == storeId);
            if (filterSearchRes.length > 0) {
              this.dialogMsg = filterSearchRes[0].state == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
              this.popupShow = true;
              var orginalStoreId = SessionDetails.GetStoreId();
              this.searching = false;
              this.addBusinessForm.controls['assignedStore'].setValue(orginalStoreId);
              this.addBusinessForm.controls['assignedStore'].updateValueAndValidity();
            }
            return;
          }
          let storeProfile = SessionDetails.getStoreProfileData();
          let assignedStore:Number = this.addBusinessForm.controls['assignedStore'].value;
          let selectedStore = res.data.filter(store=>store.storeId===Number(assignedStore));

          if((selectedStore.length>0 && Number(storeProfile[0].regionNumber)===Number(selectedStore[0].regionNumber))
          ||this.userProfile.userRole==="Admin"){
            this.updateOutreachOpportunity.outreachEffortList.forEach(list => {
              list.storePk = selectedStore[0].storePk;
            });
            this.updateOpportunity();
          }
          else {
            this.dialogSummary = "Alert";
            this.dialogMsg = String.Format('Store #{0} is not within your area of responsibility or does not exist.', this.addBusinessForm.controls['assignedStore'].value);
            this.popupShow = true;
            return;  
          }
          
        }
        else {
          this.dialogSummary = "Alert";
          this.dialogMsg = String.Format('Store #{0} is not within your area of responsibility or does not exist.', this.addBusinessForm.controls['assignedStore'].value);
          this.popupShow = true;
          return;
        }
      });
    }
    else {
      this.updateOpportunity();
    }
  }
  onOverrideOk() {
    this.overridePopupShow = false;
  }
  onDuplicateOk() {
    this.duplicateErrorPopUp = false;
  }
  onOverride() {
    this.overridePopupShow = false;
    this.isOverrideDupCheck = true;

    if (this.businessData != undefined && this.businessData != null && this.businessData.errorS != null && (this.businessData.errorS.errorCode == -4 || this.businessData.errorS.errorCode == -5)) {
      this.canReassign = true;
    }
    if (this.isEdit) {
      if (!this.addBusinessForm.controls['ImmunizationProgram'].value && !this.addBusinessForm.controls['SeniorOutreach'].value) {
        this.removeIPSR = true;
      }
      else {
        this.overrideUdpateBusiness();
      }

    }
    else {
      this.addBusiness(this.outreachOpportunity, this.userProfile.userPk)
    }
  }
  ondiffStateOverride() {
    this.diffStateOverridePopupShow = false;
    if (this.businessData != undefined && this.businessData != null && this.businessData.errorS != null && (this.businessData.errorS.errorCode == -4 || this.businessData.errorS.errorCode == -5)) {
      this.canReassign = true;
    }
    if (this.isEdit) {
      if (!this.addBusinessForm.controls['ImmunizationProgram'].value && !this.addBusinessForm.controls['SeniorOutreach'].value) {
        this.removeIPSR = true;
      }
      else {
        this.overrideUdpateBusiness();
      }

    }
    else {
      this.isOverrideDupCheck = false;
      this.addBusiness(this.outreachOpportunity, this.userProfile.userPk)
    }
  }

  ondiffStateOverrideOk() {
    this.diffStateOverridePopupShow = false;
  }

  onLogExistOk() {
    this.logExistsPopupShow = false;
  }
  onContactLogOverride() {
    this.logExistsPopupShow = false;
    this.isRemoveContactLog = true;
    this.overrideUdpateBusiness();
  }
  onOnlyNumbers(event: any) {
    Util.onlyNumbers(event);
  }

  onUpdateNavinagtion() {
    if (this.addBusinessForm.controls['ImmunizationProgram'].value) {
      if (this.isCharityCommunityNew) {
        var log_outreach_status = new FollowUp();
        log_outreach_status.logOutreachStatus.businessPk = this.businessData.businessPk;
        log_outreach_status.logOutreachStatus.firstName = this.businessData.firstName;
        log_outreach_status.logOutreachStatus.lastName = this.businessData.lastName;
        log_outreach_status.logOutreachStatus.jobTitle = this.businessData.jobTitle;
        log_outreach_status.logOutreachStatus.feedback = this.businessData.feedback;
        log_outreach_status.logOutreachStatus.contactDate = new Date();

        log_outreach_status.logOutreachStatus.createdBy = this.userProfile.userPk;
        if (this.addBusinessForm.value.CharityHHSVoucher == true) {
          this.businessData.outreachEffortList.forEach(list => {
            if (list.outreachProgram == "IP") {
              log_outreach_status.logOutreachStatus.outreachBusinessPk = list.outreachBusinessPk;
              log_outreach_status.logOutreachStatus.outreachProgram = list.outreachProgram;
            }
          });
          log_outreach_status.logOutreachStatus.outreachStatusTitle = "Charity (WAG Free Flu Voucher)";
          log_outreach_status.logOutreachStatus.outreachStatusId = 8;
          log_outreach_status.logOutreachStatus.feedback = "";
          SessionDetails.EventDetails(log_outreach_status);
          SessionDetails.opportunitiesData(this.businessData);
          this.prouter.navigate(['/communityoutreach/charityprogram']);

        }
        else if (this.addBusinessForm.value.CommunityOutreach == true) {
          this.businessData.outreachEffortList.forEach(list => {
            if (list.outreachProgram == "IP") {
              log_outreach_status.logOutreachStatus.outreachBusinessPk = list.outreachBusinessPk;
              log_outreach_status.logOutreachStatus.outreachProgram = list.outreachProgram;
            }
          });

          log_outreach_status.logOutreachStatus.outreachStatusTitle = "Community Outreach";
          log_outreach_status.logOutreachStatus.outreachStatusId = 12;
          log_outreach_status.logOutreachStatus.feedback = "";
          SessionDetails.EventDetails(log_outreach_status);
          SessionDetails.opportunitiesData(this.businessData);
          this.prouter.navigate(['/communityoutreach/communityoutreach']);

        }
        else {
          this.prouter.navigate(['/communityoutreach/storehome']);
        }
      }
      else {
        this.prouter.navigate(['/communityoutreach/storehome']);
      }
    }
    else {
      this.prouter.navigate(['/communityoutreach/storehome']);
    }
  }
  disableButton() {
    if (this.disable_button) {
      return 'false';
    }
    else {
      return '';
    }

  }
  AllowAdminsToEditDataForRestrictedState() {
    var userProfile = SessionDetails.GetUserProfile();
    let disableControls: boolean = false;
    let stateSpecific = SessionDetails.GetState();
    var resrict_states = ["MO"]; // "DC" -- Removing DC state as per task# 1363
    var current_date = new Date();
    if (resrict_states.indexOf(stateSpecific) > -1) {
      if (userProfile.userRole.toLowerCase() == "admin"|| userProfile.userRole.toLowerCase() == "healthcare supervisor") {
        disableControls = false;
      }
      else {
        if (stateSpecific == "MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
          disableControls = true;
        }
        else if (stateSpecific == "DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
          disableControls = true;
        }
        else {
          disableControls = false;
        }
      }
      return disableControls
    }
  }
  checkForIP(checked){
    if (checked.target.checked) {
      this.isIPChecked = true;
      this.enableDisableCovidOption = true;
      if(this.isAdmin){
        this.noContract = false;
      }
      else {      
        this.noContract = true;
        this.isCommunityCharity = false;        
      }
      this.addBusinessForm.controls['ImmunizationProgram']?.setErrors(null);
      this.addBusinessForm.controls['SeniorOutreach']?.setErrors(null);
      this.addBusinessForm.controls['SeniorOutreach']?.markAsPristine();
      this.addBusinessForm.controls['SeniorOutreach']?.markAsUntouched();
      this.addBusinessForm.updateValueAndValidity();
      this.canDisplayAutoInitiate = true;
    }
    else {
      this.canDisplayAutoInitiate = false;
      this.isIPChecked = false;
      if(!this.isSOChecked){
        this.addBusinessForm.controls['covidOpportunityType'].setValue(null);
        this.addBusinessForm.controls['fluOpportunityType'].setValue(null);
        this.addBusinessForm.controls['isNoContractOpportunity'].setValue(false);
        this.addBusinessForm.controls['isCovidOpportunity'].setValue(null);
        this.addBusinessForm.controls['isFluOpportunity'].setValue(null);
        this.addBusinessForm.controls['covidClinicType'].setValue('');
        this.addBusinessForm.controls['LTCFType'].setValue('');    
        this.addBusinessForm.controls['CharityHHSVoucher'].setValue(false);      
        this.enableDisableCovidOption = false;
        this.noContract = true;
        this.isCommunityCharity = false;
        this.canDisplayIndustries = false;
        this.addBusinessForm.controls['Others'].setValue(null);
        this.addBusinessForm.controls['ImmunizationProgram']?.setErrors({'incorrect': true});
        this.addBusinessForm.controls['SeniorOutreach']?.setErrors({'incorrect': true});
        this.addBusinessForm.controls['SeniorOutreach']?.markAsDirty();
        this.addBusinessForm.controls['SeniorOutreach']?.markAsTouched();
        this.addBusinessForm.updateValueAndValidity();
      }
    }
  }

  checkForSO(checked){
    if (checked.target.checked) {
      this.isSOChecked = true;
      this.enableDisableCovidOption = true;
      this.addBusinessForm.controls['ImmunizationProgram']?.setErrors(null);
      this.addBusinessForm.controls['SeniorOutreach']?.setErrors(null);
      this.addBusinessForm.controls['ImmunizationProgram']?.markAsPristine();
      this.addBusinessForm.controls['ImmunizationProgram']?.markAsUntouched();
      this.addBusinessForm.updateValueAndValidity();
    }
    else {
      this.isSOChecked = false;
      if(!this.isIPChecked){
        this.addBusinessForm.controls['covidOpportunityType'].setValue(null);
        this.addBusinessForm.controls['fluOpportunityType'].setValue(null);
        this.addBusinessForm.controls['isNoContractOpportunity'].setValue(false);
        this.addBusinessForm.controls['isCovidOpportunity'].setValue(null);
        this.addBusinessForm.controls['isFluOpportunity'].setValue(null);
        this.addBusinessForm.controls['covidClinicType'].setValue('');
        this.addBusinessForm.controls['LTCFType'].setValue('');    
        this.addBusinessForm.controls['CharityHHSVoucher'].setValue(false);       
        this.enableDisableCovidOption = false;
        this.noContract = true;
        this.isCommunityCharity = false;
        this.canDisplayIndustries = false;
        this.addBusinessForm.controls['Others'].setValue(null);
        this.addBusinessForm.controls['ImmunizationProgram']?.setErrors({'incorrect': true});
        this.addBusinessForm.controls['SeniorOutreach']?.setErrors({'incorrect': true});
        this.addBusinessForm.controls['ImmunizationProgram']?.markAsDirty();
        this.addBusinessForm.controls['ImmunizationProgram']?.markAsTouched();
        this.addBusinessForm.updateValueAndValidity();
      }
    }
  }

  checkForCovid(checked) {
    if (checked.target.checked) {
      // Task 943 Fix
      if(Number(this.addBusinessForm.controls['covidOpportunityType'].value) == 1)
      {
        this.addBusinessForm.controls['fluOpportunityType'].setValue("3");
      }

      if(Number(this.addBusinessForm.controls['covidOpportunityType'].value) == 2)
      {
        this.addBusinessForm.controls['fluOpportunityType'].setValue("0");
      }

      Number(this.addBusinessForm.controls['covidOpportunityType'].value)!==0 ? 
      this.addBusinessForm.controls['isCovidOpportunity'].setValue(true):
      this.addBusinessForm.controls['isCovidOpportunity'].setValue(false);

      this.addBusinessForm.controls['fluOpportunityType'].value != null && 
      (Number(this.addBusinessForm.controls['fluOpportunityType'].value)==3 || 
      Number(this.addBusinessForm.controls['fluOpportunityType'].value)==0) ? 
      this.addBusinessForm.controls['isFluOpportunity'].setValue(true):
      this.addBusinessForm.controls['isFluOpportunity'].setValue(false);
  
      if(this.addBusinessForm.controls['covidOpportunityType'].value == 1 || 
      this.addBusinessForm.controls['fluOpportunityType'].value == 3)
      {
      this.addBusinessForm.controls['LTCFType'].setValue('ALF');
      }
      else
      {
        this.addBusinessForm.controls['LTCFType'].setValue('');   
      }
      if(this.isAdmin){
      this.noContract = false;
    }
    else {
        this.noContract = true;
        this.addBusinessForm.controls['CommunityOutreach'].setValue(false);
        this.addBusinessForm.controls['CharityHHSVoucher'].setValue(false);
        //Number(this.addBusinessForm.controls['covidOpportunityType'].value)===0 || this.addBusinessForm.controls['covidOpportunityType'].value===null?this.isCommunityCharity=false: this.isCommunityCharity=true;
      }
    }
    else {
      this.addBusinessForm.controls['isCovidOpportunity'].setValue(null);
      if(this.isAdmin){
      this.noContract = false;
      }
      else {
      this.noContract = true;
        this.addBusinessForm.controls['CommunityOutreach'].setValue(false);
        this.addBusinessForm.controls['CharityHHSVoucher'].setValue(false);
        this.isCommunityCharity = true;
    }
    }
  }
  validateControls(){
    if(this.addBusinessForm.value.covidOpportunityType == '1'){
      // this.addBusinessForm.controls['pkhash'].setValidators([Validators.required]);
      // this.addBusinessForm.controls['pkhash'].updateValueAndValidity();
      this.addBusinessForm.controls['LTCFType'].setValidators([Validators.required]);
      this.addBusinessForm.controls['LTCFType'].updateValueAndValidity();
    }
    else{
      this.addBusinessForm.controls['pkhash'].clearValidators();
      this.addBusinessForm.controls['pkhash'].updateValueAndValidity();
      this.addBusinessForm.controls['LTCFType'].clearValidators();
      this.addBusinessForm.controls['LTCFType'].updateValueAndValidity();
    }
    if(this.addBusinessForm.value.covidOpportunityType == '2' ||this.addBusinessForm.value.covidOpportunityType == '1'){
      this.addBusinessForm.controls['covidClinicType'].setValidators([Validators.required]);
      this.addBusinessForm.controls['covidClinicType'].updateValueAndValidity();
    }
    else {
      this.addBusinessForm.controls['covidClinicType'].clearValidators();
      this.addBusinessForm.controls['covidClinicType'].updateValueAndValidity();
    }
    
    if(this.addBusinessForm.value.covidOpportunityType == '2'){
      // this.addBusinessForm.controls['targetPopulation'].setValidators([Validators.required]);
      // this.addBusinessForm.controls['targetPopulation'].updateValueAndValidity();
      // this.addBusinessForm.controls['communityPartner'].setValidators([Validators.required]);
      // this.addBusinessForm.controls['communityPartner'].updateValueAndValidity();
      // this.addBusinessForm.controls['healthDeptInvolved'].setValidators([Validators.required]);
      // this.addBusinessForm.controls['healthDeptInvolved'].updateValueAndValidity();
      // this.addBusinessForm.controls['opportunityHostLocation'].setValidators([Validators.required]);
      // this.addBusinessForm.controls['opportunityHostLocation'].updateValueAndValidity();
      // this.addBusinessForm.controls['vaccineInventorySource'].setValidators([Validators.required]);
      // this.addBusinessForm.controls['vaccineInventorySource'].updateValueAndValidity();
    }
    else{
      this.addBusinessForm.controls['targetPopulation'].clearValidators();
      this.addBusinessForm.controls['targetPopulation'].updateValueAndValidity();
      this.addBusinessForm.controls['communityPartner'].clearValidators();
      this.addBusinessForm.controls['communityPartner'].updateValueAndValidity();
      this.addBusinessForm.controls['healthDeptInvolved'].clearValidators();
      this.addBusinessForm.controls['healthDeptInvolved'].updateValueAndValidity();
      this.addBusinessForm.controls['opportunityHostLocation'].clearValidators();
      this.addBusinessForm.controls['opportunityHostLocation'].updateValueAndValidity();
      this.addBusinessForm.controls['vaccineInventorySource'].clearValidators();
      this.addBusinessForm.controls['vaccineInventorySource'].updateValueAndValidity();
    }
    if(this.addBusinessForm.value.communityPartner == "true"){
      this.addBusinessForm.controls['communityPartnerName'].setValidators([Validators.required]);
      this.addBusinessForm.controls['communityPartnerName'].updateValueAndValidity();
    }
    else{
      this.addBusinessForm.controls['communityPartnerName'].clearValidators();
      this.addBusinessForm.controls['communityPartnerName'].updateValueAndValidity();
    }
  }
  confirmDeactivateOpportunity() {
      this.dialogMsg = "Are you sure you want to deactivate this opportunity? Deactivating it will remove it from the store's Opportunity List and all Outreach Programs. This opportunity will no longer be available through the Outreach Portal.";
      this.dialogSummary = 'Deactivate Opportunity';
      this.showDeactivateOpportunity = true;
  }
  DeactivateOpportunity(){
    this._AddBusinessService.deactivateOpportunity(this.businessPk).subscribe((res: any) => {
      if (res == "success") {
        this.showDeactivateOpportunity = false;
        this.popupShow = true;
        this.Action = "DeactivateOpp";
        this.dialogMsg = 'The Opportunity has been removed successfully.';
        this.dialogSummary = 'Deactivate Opportunity';
      }
    }, error => {
      this.showDeactivateOpportunity = false;
      this.displayNgMsg('error', 'Error', "An error has occurred. Pleaase contact administrartor.");
      return false;
    });
  }
  CheckIfOnlyCovidSelected(){   
   if(this.addBusinessForm.controls['covidOpportunityType'].value == 1 || this.addBusinessForm.controls['covidOpportunityType'].value == 2 && this.isCommunityCharity == false)
   {
     if(this.addBusinessForm.controls['fluOpportunityType'].value == 0 || this.addBusinessForm.controls['fluOpportunityType'].value == 3)
     {
     return null;
     }
     return true;
   }
   else
   {
     return null;
   }   
  }

  public displayIndustries(event: any, designationType: string) {    
    if (event) {
      this.canDisplayIndustries = true;
      this.canDisplayOtherTextboxForIndustries = false;
      this.addBusinessForm.controls['Industry']?.setValue('');
      this.addBusinessForm.controls['Others']?.clearValidators();
      this.addBusinessForm.controls['Others']?.setValue(null);

      if (designationType?.toLocaleLowerCase() === 'ltcf opportunity') {
        this.industries = this.ltcfOpportunityIndustries;
      }
      else if (designationType?.toLocaleLowerCase() === 'standard opportunity') {
        this.industries = this.standardOpportunityIndustries;
        const ltcfClinicTypeIndex = this.covidClinicTypeValues.findIndex(type => type.name.toLocaleLowerCase() === 'ltcf');

        if(ltcfClinicTypeIndex !== -1){
          this.covidClinicTypeValues.splice(ltcfClinicTypeIndex, 1);
          
          if(!this.covidClinicTypeValues.some(type => type.name.toLocaleLowerCase() === 'other senior housing')){
            this.covidClinicTypeValues.push({
              id: 'Other senior housing',
              name: 'Other senior housing'
            });
          }
        }
      }

      // hard code the clinic type to LTCF if LTCF Designation has been selected.
      if (designationType?.toLocaleLowerCase() === 'ltcf opportunity') {
        if(!this.covidClinicTypeValues.some(type => type.name.toLocaleLowerCase() === 'ltcf')){
          this.covidClinicTypeValues.push({
            id: 'LTCF',
            name: 'LTCF'
          });
        }
        this.addBusinessForm.controls['covidClinicType'].setValue('LTCF');
      }
      else{
        this.addBusinessForm.controls['covidClinicType'].setValue(null);  
      }
      this._ref.detectChanges();
    }
  }

  public addConditionForOtherIndustry(event: any){
    if(event){
      const opportunityType = this.addBusinessForm.controls['covidOpportunityType']?.value;
      const selectedIndustry = this.addBusinessForm.controls['Industry']?.value;

      // if opportunityType is LTCF OR Standard 
      // AND selectedIndustry is Others
      // then display others txt box and add required validator
      if ((opportunityType === '1' || opportunityType === 1 
            || opportunityType === '2' || opportunityType === 2) 
            && selectedIndustry.toLocaleLowerCase() === 'other'){
        this.canDisplayOtherTextboxForIndustries = true;
        this.addBusinessForm.controls['Others']?.setValidators([Validators.required]);
        this.addBusinessForm.updateValueAndValidity();
      }
      
      if(selectedIndustry.toLocaleLowerCase() !== 'other'){
        this.canDisplayOtherTextboxForIndustries = false;
        this.addBusinessForm.controls['Others']?.setValue(null);
        this.addBusinessForm.controls['Others']?.setErrors(null);
        this.addBusinessForm.controls['Others']?.clearValidators();
        this.addBusinessForm.updateValueAndValidity();
      }
      this._ref.detectChanges();
    }
  }

  public ValidateFieldForSpecialCharacters(event){
    if (event.key == '"') {
      event.preventDefault();
      return;
    }
  }

  public RemoveCharactersFromField(event, formCtrlName: string){
    if(event.target.value.length){
      // remove double quotes
      let opportunityName = event.target.value?.replace('"', '');
      opportunityName = opportunityName?.replace('"', '');
      opportunityName = opportunityName?.replace('“', '');
      opportunityName = opportunityName?.replace('”', '');

      // remove tab space
      opportunityName = opportunityName?.replace('\t', ''); 

      this.addBusinessForm.controls[formCtrlName].setValue(opportunityName);
    }
  }

  public deleteOpportunityConfirmation(){
    this.displayDeleteDialog = true;
    this.deleteOpportunityHeader = ErrorMessages['deleteOpportunityHeader'];
    this.deleteOpportunityBody = ErrorMessages['deleteOpportunityConfirmation'];
  }

  deleteOpportunityCancel() {
    this.displayDeleteDialog = false;
  }

  private deleteOpportunityData(){
    this.spinner.show();
    this._opportunityService.deleteOpporunityInfo(this.businessPk)
        .subscribe((data: string) => {
          this.spinner.hide();
          if (data?.toLowerCase() == "cannot delete the opportunity") {
            this.dialogSummary = ErrorMessages['warning'];
            this.dialogMsg = ErrorMessages['cannotDeleteOpportunity'];
            this.popupSuccessShow = true;
          }
          else if (data?.toLowerCase() == "success") {
            this.prouter.navigate(['/communityoutreach/storehome']);
          }
        },
        error => {
          this.spinner.hide();
        });
  }
}


