import { of as observableOf, Observable } from 'rxjs';
import { catchError, switchMap, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, NgForm, RequiredValidator } from '@angular/forms';
import { ClinicdetailsService } from '../../services/clinicdetails.service';
import { Router } from '@angular/router';
import { states } from '../../../../JSON/States';
import { Util } from '../../../../utility/util';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';
import { SessionDetails } from '../../../../utility/session';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorMessages } from '../../../../config-files/error-messages';
import { ClinicDetailsUpdate, OpportunityInfo, ClinicsUpdateHistoryLogList, ClinicList, ErrorS } from '../../../../models/clinicDetailsUpdate';
import { String } from 'typescript-string-operations';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ClinicDetailsDelete } from '../../../../models/clinicDetailsDelete';
import { environment } from '../../../../../environments/environment';
import { HeaderserviceService } from '../../../common/services/headerservice.service';
import { ConfirmationService } from 'primeng/api';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { AlertService } from '../../../../alert.service';
import { VoucherFormData } from '../../../../models/voucherFormData';
import { OutreachProgramService } from '../../../contractaggreement/services/outreach-program.service';
import { LocalField } from '../../../../JSON/localFields';
import { FormCanDeactivate } from '../../../../guards/form-can-deactivate/form-can-deactivate';
import { KeysLogPipe } from '../../../store/pipes/keyslog';
import { CSTDateConvertPipe } from '../../../store/pipes/cstDateConvert';
import { ImmunizationsClinicalRequirements } from '../../../../config-files/ClinicalRequirements';
import { ClinicDetailsUtilities } from '../../../../utility/clinicDetailsUtilities';
import { EncryptDecrypt } from '../../../../models/encryptDecrypt';
import { SchedulerUserInfo } from '../../../../models/schedulerUserInfo';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { COVIDVisits } from '../../../../JSON/COVIDVisits';
import * as _ from 'lodash';
import { ContractapproveService } from '../../../contractaggreement/services/contractapprove.service';
import { adminUserNames } from '../../../../JSON/adminsInformation';
import { LookupService } from '../../../../lookup.service';
import { SelectType } from '../../../../models/OutreachOpportunity';
import { getContractComponentName } from '../../../../utility/loadContracts';
import { UniquePipeClinicDetails } from '../../pipes/clinicUnique.pipe';
import * as moment from 'moment';

declare var google: any;

@Component({
  selector: 'app-equity-event-details',
  templateUrl: './equity-event-details.component.html',
  styleUrls: ['./equity-event-details.component.css'],
  providers: [TimeToDatePipe, DatePipe, KeysLogPipe, CSTDateConvertPipe, UniquePipeClinicDetails],
  outputs: ['fileUploaded']
})
export class EquityEventDetailsComponent extends FormCanDeactivate implements OnInit {
  isApptSchedulerDisabled: boolean = null;
  showAppointmentScheduler: boolean = false;
  immunizers: number;
  signUpEndDate: Date;
  dispEncryptedLink: boolean = false;
  isApptSchedulerEncLink: boolean = false;
  isApptTrackingEncLink: boolean = false;
  accessLinkHeaderTitle: string = "Shared Encrypted Access Link";
  apptSchedulerEncLink: string;
  copyApptTrackingEncLink: string;
  copyText: any;
  encryptedSiteLink: boolean = false;
  accessSchedulerEncryptedLink: any;
  encryptedClientApptTrackingLink: boolean = false;
  accessSchedulerTrackerEncryptedLink: any;
  isEncryptLinkNull: boolean = false;
  domain: any;
  protocol: any;
  port: any;
  estimatedImmunizers: Number = 1;
  form: NgForm;
  isAmendAgreementPopUp = false;
  showCanRouteChange: boolean;
  isCovid: boolean;
  isFlu: boolean;
  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
  pageName: string = "equityeventprogramdetails";
  ShowDeleteAmendAgreement: boolean = false;
  showRemoveCancelledStatus: boolean = false;
  showLastLogStatus: boolean = false;
  clinicDetailsForm: FormGroup;
  corporateForm: FormGroup;
  reassignForm: FormGroup;
  coClinicDetails: ClinicDetailsUpdate = new ClinicDetailsUpdate();
  clinic_details_update: ClinicDetailsUpdate = new ClinicDetailsUpdate();
  validateCancel: boolean = null;
  isAmendButtonEnable: boolean = true;
  disableCancelIfTotalAdmPresent: boolean = null;
  validateComplete: boolean = null;
  validateConfirm: boolean = null;
  IsAmendSubmit: boolean = false;
  IsnoclinicvouchersDistributed: boolean = false;
  states: any[];
  dlgDispCOViewAgreement: boolean = false;
  defaultDate: Date = new Date();
  maxDateValue: Date = new Date(this.defaultDate.getFullYear() + 5, this.defaultDate.getMonth(), this.defaultDate.getDate());
  minDateValue: Date = new Date();
  clinicLocation: number;
  deleteAgreement: boolean = false;
  shouldDisableVouchersDistributed: boolean = null;
  isDeleteAmendAgreement: boolean = false;
  storeV: boolean = false;
  distV: boolean = false;
  ariaV: boolean = false;
  storeV1: boolean = true;
  distV1: boolean = true;
  ariaV1: boolean = true;
  gropId: boolean = false;
  event_details: any;
  is_copay: boolean = false;
  voucher_needed: boolean = false;
  defaultStartTime: Date;
  savePopUp: boolean = false;
  vouchersDistributed: number = null;
  dialogSummary: string;
  dialogMsg: string;
  user_profile: any;
  savedViaReassignBtn: boolean = false;
  showClinicCancelDlg: boolean = false;
  locListModalDlg: boolean = false;
  getDetailsErr: boolean = false;
  userApprovedGt250: boolean = false;
  userApprovedLt25: boolean = false;
  locConfirm250ModalDlg: boolean = false;
  locConfirm25ModalDlg: boolean = false;
  is_cancelled: boolean = false;
  is_completed: boolean = false;
  is_confirmed: boolean = false;
  savePopUpStatus: boolean = false;
  is_no_clinc: number = 0;
  is_voucher_needed: boolean = null;
  is_voucher_neededForEQ: boolean = false;
  is_flu_group_id: boolean = false;
  is_routine_group_id: boolean = false;
  is_admin: boolean = false;
  area_dist = [];
  clinic_number: any = 1;
  intakeID: string = '';
  searching = false;
  searchFailed = false;
  assignType: string;
  assignStore: number;
  step_value: any = 1;
  is_flu_date: boolean = false;
  is_routine_date: boolean = false;
  isVARSectionShow: boolean = false;
  isVoucherSectionShow: boolean = false;
  isFluVoucher: boolean = false;
  isRoutineVoucher: boolean = false;
  isCovidVoucher: boolean = false;
  is_covid_date: boolean = false;
  varFormSP: string = "";
  fluFormSP: string = "";
  routingformSP: string = "";
  covidformSP: string = "";
  max_clinic_location_id: number;
  naClinicGroupId: any;
  naClinicGroupIdOriginal: any;
  is_previous_locations: boolean = false;
  previous_location_data: any[];
  display_message: boolean = false;
  previous_locations: any[];
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: any;
  updateLogHistory: any = [];
  AssignedStoreChkModalDlg: boolean = false;
  maintainStatusSelected: boolean = true;
  action_status: string = null;
  FluDosesValidUnder14DaysDlg = false;
  isfluexpirationDate: boolean = false;
  isroutineexpirationDate: boolean = false;
  isCovidExpirationDate: boolean = false;
  isNewClinicAdded: boolean = false;
  rescheduledErrorCode: number = 0;
  isOverride: boolean = false;
  errorMsgOverride: boolean = false;
  errorMsgSaveChanges: boolean = false;
  viewSteps: boolean = true;
  totalSteps: number = 3;
  currentStep: number;
  errorPopUp: boolean = false;
  usPhoneNoRegEx: RegExp = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/
  ConfirmBtnLabel = "Confirm";
  storeSearchRsp = null;
  clinicNotConfirmedMsgDlg = false;
  isNoClinic: number = 0;
  reAssignOutreachStatusLabel: string = "Contact Client";
  showRemoveCancelledStatusDlg: boolean = false;
  showRemoveCancelledStatusResult: boolean = false;
  showClinicalRequirements: boolean = false;
  clinicalRequirements: any = [];
  canDisableClinicalRequirements: boolean = null;
  clinicalReqUserNote: string;
  clinicType: number = 0;
  BillingVaccinesCol1Heading = "Vaccines Covered";
  BillingVaccinesCol2Rates = "";
  BillingVaccinesCol3PaymentMethod = "Payment Method";
  BillingVaccinesCol4EstVol = "Estimated Volume";
  BillingVaccinesCol5TtlAdmn = "";
  BillingVaccinesCol6VARs = "Paper VARs Completed";
  adminConfirmedCancellation: boolean = false;
  adminCancellationAlert: boolean = false;
  showBlockedSlotAlert = false;
  overrideBlockedSlotAlert = false;
  OverrideOrOkTitle: string = "Override";
  showCancelBtn: boolean = false;
  public myAngularxQrCode: string = null;
  isCovidVaccineAvailable: boolean = false;
  COVIDVisits: any[];
  isNonCovidVaccineAvailable: boolean = true;
  storeState: string;
  is_Admin: boolean = false;
  status: boolean = false;
  showEstQnt: boolean = false;
  showSchedulerAlert: boolean = false;
  showDeleteClinic: boolean = false;
  showAfterClinicDelete: boolean = false;
  specificAdmin: boolean = false;
  Action: string = '';
  areApptsPresent: boolean = false;
  covidOpportunityType: number = 0;
  apptSchedulerType: any = 0;
  pData: string = "";
  isremove: boolean = false;
  immunizations: any;
  headerMessage: string = "";
  confirmationMessage: string = "";
  disableTotalAdministered: boolean = null;
  clinicAgreementDateApproved: any;
  currentStoreId: any;
  showCuraScheduler: boolean = false;
  isAmendAgreementCommOutreachPopUp: boolean;
  canEditPatientGroupId: boolean = false;
  cOPForceUncheck: boolean = false;
  disableCuraPatientScheduler: boolean = false;
  isProd: boolean = false;
  envvariable: string = "";
  cancelDispositions: SelectType[] = [];
  selectedCancelDisposition: string = '';
  displayCancelError: boolean = false;
  routePageName: string = '';
  isWagsFreeFluVoucherSelected = false;
  covidVaccineWarningOkClicked = false;
  covidVaccineSelected = false;
  isSuperAdmin = false;
  clinicPK: number;
  schedularType: number;
  selectedappointementDetails: any = [];
   // visibleInoviceButton = false;
  
  constructor(
    @Inject('Window') private win: Window,
    @Inject(DOCUMENT) private document: any,
    private formBuilder: FormBuilder,
    private _headerservice: HeaderserviceService,
    private coClinicdetailsService: ClinicdetailsService,
    private _timeToDatePipe: TimeToDatePipe,
    private confirmationService: ConfirmationService,
    private _datePipe: DatePipe,
    private utility: Util,
    private _localContractService: OutreachProgramService,
    private router: Router,
    private _alertService: AlertService,
    private messageService: MessageServiceService,
    private _keysPipe: KeysLogPipe,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private _apiservice: ContractapproveService,
    private _lookupService: LookupService,
    private _uniquePipeClinicDetails: UniquePipeClinicDetails
  ) {
    super();
    this.messageService.getCanShowRouteChangeConfirmation().subscribe(message => {
      this.showCanRouteChange = message.text;
    });
    this.adminConfirmedCancellation = false;
    let userProfile = SessionDetails.GetUserProfile();
    this.is_Admin = adminUserNames.filter(item => item.toLowerCase() == userProfile.userName.toLowerCase()).length > 0;
    this.isSuperAdmin = adminUserNames.filter(item => item.toLowerCase() == userProfile.userName.toLowerCase()).length > 0;
  }

  stampImm(immunization, index) {
    // console.log(immunization.controls.immunizationName.value);
    // console.log('index-'+index);
  }

  ngOnInit() {

    this.domain = this.document.location.hostname;
    this.protocol = this.document.location.protocol;
    this.port = this.document.location.port;
    if (sessionStorage["AmendAgreement"] != null)
      sessionStorage.removeItem("AmendAgreement");

    if (sessionStorage["NewAmendAgreement"] != null)
      sessionStorage.removeItem("NewAmendAgreement");

    if (sessionStorage["isStoreBusinessClinicAdded"] != null)
      sessionStorage.removeItem("isStoreBusinessClinicAdded");

    this.isVARSectionShow = false;
    this.isVoucherSectionShow = false;
    this.isFluVoucher = false;
    this.isRoutineVoucher = false;
    this.clinicType = 0;
    this.storeState = SessionDetails.GetState();
    this.user_profile = SessionDetails.GetUserProfile();
    
    if(environment.SuperAdmins.includes(this.user_profile.userName.toLowerCase())){
      this.isSuperAdmin = true;
       this.canEditPatientGroupId = true;
    }
    if (SessionDetails.GetState() == "PR") {
      this.varFormSP = "Vaccine Administration Record (VAR) form – Puerto Rican Spanish";
      this.fluFormSP = "Flu Voucher – Puerto Rican Spanish";
      this.routingformSP = "Routine Voucher – Puerto Rican Spanish";
      this.covidformSP = "Covid Voucher – Puerto Rican Spanish";
    }
    else {
      this.varFormSP = "Vaccine Administration Record (VAR) form – Spanish";
      this.fluFormSP = "Flu Voucher – Spanish";
      this.routingformSP = "Routine Voucher – Spanish";
      this.covidformSP = "Covid Voucher – Spanish";
    }
    let today = new Date();
    var userProfile = SessionDetails.GetUserProfile();
    if (userProfile.userRole.toLowerCase() == "admin") {
      this.gropId = true;
    }
    if (this.utility.isStoreIsfromRestrictedState()) {
      if (userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "pharmacy manager") {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);// + 14
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);// + 14
      }
      else {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
      }
      if (ClinicDetailsUtilities.checkIfAnyFluImmIsSelected(this.coClinicDetails)) {
        this.maxDateValue = new Date(today.getFullYear() + 1, 3, 1, 12, 55, 55);
      }
      else {
        this.maxDateValue = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate() - 1, 12, 55, 55);
      }
    }
    else {
      if (userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "pharmacy manager") {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());// + 14
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate());// + 14

      } else {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      }
      this.maxDateValue = new Date(today.getFullYear() + 5, today.getMonth(), today.getDate() - 1);
    }
    if (['CommunityUpdate@WagOutreach.com', 'jerry.v.thomas@walgreens.com', 'samuel.nass@walgreens.com', 'carlos.flores@walgreens.com', 'enterpriseimmunizationservice@walgreens.com', 'tyrone.clark@walgreens.com'].indexOf(userProfile.email) > -1) {
      let today = new Date();
      this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);

      this.minDateValue.setMonth(this.minDateValue.getMonth() - 6);
      this.minDateValue.setDate(this.minDateValue.getDate() + 4);
    }
    this.defaultStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
    this.is_admin = this.user_profile.isAdmin;

    if ((this.user_profile.userRole.toLowerCase() == "store manager" || this.user_profile.userRole.toLowerCase() == "pharmacy manager")) {
      this.storeV1 = false;
      this.distV1 = true;
      this.ariaV1 = true;
    }
    this.corporateForm = this.formBuilder.group({
      name: ['', null],
      phone: ['', null],
      fax: ['', null],
      address1: ['', null],
      address2: ['', null],
      email1: ['', null],
      email2: ['', null],
      city: ['', null],
      state: ['', null],
      zip: ['', null],
      isTaxExempt: ['', null],
      isCopay: ['', null],
      copayValue: ['', null],
      isVoucherNeeded: ['', null],
      fluexpirationDate: ['', null],
      routineexpirationDate: ['', null],
      covidExpirationDate: ['', null],
    });
    this.reassignForm = this.formBuilder.group({
      assignType: [null, null],
      assignStoreID: [null, null],
      assignStore: ['', null],
    });

    SessionDetails.fromLocalclinics(false);
    this.event_details = SessionDetails.GetEventDetails();
    this.COVIDVisits = COVIDVisits;
    this.bindControls1();
    this.bindLocalClinicData();
    if (this.router.url.search('LocalClinicProgramDetails') > 0 || this.router.url.search('CorporateClinicProgramDetails') > 0) {
      this.totalSteps = 3;
    }
    else if (this.router.url.search('CommunityOutreachProgramDetails') > 0) {
      this.totalSteps = 2;
    }
    else if (this.router.url.search('CharityProgramDetails') > 0) {
      this.totalSteps = 2;
    }
    else if (this.router.url.search('EquityEventDetails') > 0) {
      this.totalSteps = 2;
    }
    SessionDetails.setIsPCA(false);
    SessionDetails.setIsSR(false);
    this._alertService.sendWarningAlert(true, false, false);
    this.coClinicdetailsService.cacheCPTokenData().subscribe((data: any) => {

      this.pData = data;
      this.spinner.hide();
    },
      err => {
        this.spinner.hide();
        //  this.showErrDialogThenGoToHome(ErrorMessages['clinicDetailsErrHeader'], err.json().Message);
      });

    this.currentStoreId = JSON.parse(sessionStorage.store_profile)[0].storeId;
    // this.showCuraScheduler = [59406,59407].indexOf(this.currentStoreId) > -1
    this._lookupService.getLookUpValues('disposition', 'disposition')
      .subscribe(
        (data: SelectType[]) => {
          this.cancelDispositions = data;
        },
        (error) => {
          console.log("Failed to get cancelled disposition lookup values. ", error)
        }
      );


  }
  getContractAgreementApproveData(cpk: any) {
    this._apiservice.getContractAgreementApproveData(cpk).subscribe((data: any) => {
      this.clinicAgreementDateApproved = data.dateApproved;
    },
      err => {
        this.spinner.hide();
        this.showDialog(ErrorMessages['clientError'], err.json().Message);
      });
  }

  getPaymentTypes(immunization) {

    if (this.coClinicDetails.opportunityInfo.clinicType != 3 && this.coClinicDetails.opportunityInfo.clinicType != 5) {
      let immPk = immunization.controls.immunizationPk.value;
      let paymentTypes = '';
      let immzs = this.coClinicDetails.clinicImmunizationList.filter(p => p.immunizationPk == immPk);
      immzs.forEach((element, index, array) => {
        paymentTypes += `<p class="payment-mb10"><span>`
        // this.immunizations.filter(p=>p.)
        paymentTypes += element.paymentTypeName;
        paymentTypes += ' ';
        if (element.paymentTypeId == 14) {
          paymentTypes += '';

        }
        else if (element.price > 0) {
          paymentTypes += '($';
          paymentTypes += (Math.round(element.price * 100) / 100).toFixed(2);
          paymentTypes += ')';
        }
        if (index !== (array.length - 1)) {
          paymentTypes += ',';
        }
        paymentTypes += `</span></p>`
      });
      return paymentTypes;
    }
    else if (this.coClinicDetails.opportunityInfo.clinicType == 3 || this.coClinicDetails.opportunityInfo.clinicType == 5) {
      let immPk = immunization.controls.immunizationPk.value;
      let paymentTypes = '';
      let immzs = this.coClinicDetails.clinicImmunizationList.filter(p => p.immunizationPk == immPk);
      immzs.forEach((element, index, array) => {
        paymentTypes += `<p class="payment-mb10"><span>`
        let paymentName = '';
        if (element.paymentTypeId == 7) {
          paymentName = 'Cash Paid by Employee';
        }
        else if (element.paymentTypeId == 11) {
          paymentName = 'Submit Claims to Insurance';
        }
        else if (element.paymentTypeId == 6) {
          paymentName = 'Off-Site: Corporate to Invoice Employer Directly';
        }

        paymentTypes += paymentName;
        paymentTypes += ' ';
        if (element.price > 0) {
          paymentTypes += '($';
          paymentTypes += (Math.round(element.price * 100) / 100).toFixed(2);
          paymentTypes += ')';
        }
        if (index !== (array.length - 1)) {
          paymentTypes += ',';
        }
        paymentTypes += `</span></p>`
      });
      return paymentTypes;
    }
  }

  bindLocalClinicData() {
    this.clinicalRequirements = [];
    this.spinner.show();
    this.is_flu_group_id = false;
    this.is_routine_group_id = false;
    this.showLastLogStatus = false;
    this.coClinicdetailsService.geLocalClinicDetails(this.event_details.clinicPk, this.user_profile.userRole).subscribe((data: any) => {
      this.getContractAgreementApproveData(data.opportunityInfo.clinicAgreementPk);
      //  console.log(data.opportunityInfo);
      //  
      let userProfile = SessionDetails.GetUserProfile();

      this.clinicPK = data.clinicList[0].clinicPk;
      const apptSchedulerTypeForCP= data.clinicList[0].apptSchedulerType;
      this.schedularType = apptSchedulerTypeForCP;
      
      this.coClinicdetailsService.getclinicappointmentdetails(this.clinicPK).subscribe((result: any)=> {
          this.selectedappointementDetails = result;
         });
      if (data.opportunityInfo.IsLTCFdesignatedOpprtunity == true && apptSchedulerTypeForCP == 0) {
        this.showCuraScheduler = true;
        this.isremove = true;
      }
      else if (apptSchedulerTypeForCP === 1) {
        this.showCuraScheduler = false;
        this.isremove = false;
      }
      else if (apptSchedulerTypeForCP === 2 && !this.isSuperAdmin) {
        this.showCuraScheduler = true;
        this.isremove = true;
      }
      else if(apptSchedulerTypeForCP == 1 && this.isSuperAdmin ){
        this.showCuraScheduler = true;
        this.isremove = false;
      }
      else {
        this.showCuraScheduler = false;
        this.isremove = false;
      }
      //removing visitType from response for task 839
      data.clinicList = data.clinicList.map(({ visitType, ...rest }) => {
        return rest;
      });
      if (data.errorS !== null && data.errorS.errorCode !== 0) {
        this.spinner.hide();
        this.showErrDialogThenGoToHome(ErrorMessages['clinicDetailsErrHeader'], data.errorS.errorMessage);
        return false;
      }
      // writing this to disable CP as COP has been selected in the past
      // 
      this.disableCuraPatientScheduler = data.clinicsUpdateHistoryLogList.filter(p => p.updatedField == 'Appointment Scheduler Type' && !this.isSuperAdmin && p.updatedValue == 'Community Outreach Portal Scheduler').length > 0 == true ? true : null;
      this.disableTotalAdministered = data.clinicList[0].apptSchedulerType == 2 && !this.is_admin ? true : null;
      this.areApptsPresent = data.clinicList[0].areApptsPresent;
      this.isCovid = data.clinicList[0].isCovidClinic;
      this.isFlu = data.clinicList[0].isCovidClinic;
      this.max_clinic_location_id = data.opportunityInfo.maxClinicLocationId;
      this.clinicType = data.opportunityInfo.clinicType;
      this.intakeID = data.clinicList[0].intakeID;
      for (let item in data) {
        if (data[item] != null && typeof data[item] == 'boolean') {
          data[item] = (data[item] == true) ? 1 : 0;
        } else if (data[item] != null && typeof data[item] == 'object') {
          for (var i = 0; i < data[item].length; i++) {
            for (let sub_item in data[item][i]) {
              if ((sub_item.indexOf('Date') > -1 || sub_item.indexOf('clinicScheduledOn') > -1) && typeof data[item][i][sub_item] == 'string') {
                //var dt = new Date(data[item][i][sub_item]);
                //data[item][i][sub_item] = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 8, 5, 5);  
                var temp_dt = data[item][i][sub_item].substring(0, data[item][i][sub_item].indexOf('T'));
                let temp_dt_parts = temp_dt.split('-');
                var dt = new Date(temp_dt_parts[0], temp_dt_parts[1] - 1, temp_dt_parts[2], 18, 30, 30);
                data[item][i][sub_item] = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 18, 30, 30);

              } else if ((sub_item.indexOf('Time') > -1)) {
                if (data[item][i][sub_item] != null) {
                  if (data[item][i][sub_item].indexOf('AM') > -1) {
                    data[item][i][sub_item] = data[item][i][sub_item].substring(0, data[item][i][sub_item].indexOf('AM')) + ' ' + data[item][i][sub_item].substring(data[item][i][sub_item].indexOf('AM'));
                  } else if (data[item][i][sub_item].indexOf('PM') > -1) {
                    data[item][i][sub_item] = data[item][i][sub_item].substring(0, data[item][i][sub_item].indexOf('PM')) + ' ' + data[item][i][sub_item].substring(data[item][i][sub_item].indexOf('PM'));
                  }
                }
              }
              else if (typeof data[item][i][sub_item] == 'boolean') {
                data[item][i][sub_item] = (data[item][i][sub_item] == true) ? 1 : 0;
              }
            }
          }
        }
      }

      this.coClinicDetails = data;

      var immDistinct = Array.from(new Set(this.coClinicDetails.clinicList[0].clinicImzQtyList.map(x => x.immunizationPk)));
      immDistinct.forEach(element => {
        let frmGrps = this.coClinicDetails.clinicList[0].clinicImzQtyList.filter(p => p.immunizationPk == element);
        frmGrps.forEach((element, index) => {
          if (index > 0)
            element.totalImmAdministered = 0;
        });
      });

      // this.isremove = data.opportunityInfo.covidOpportunityType==1?true:false
      if (data.opportunityInfo.clinicType == 3 || data.opportunityInfo.IsLTCFdesignatedOpprtunity == true && apptSchedulerTypeForCP == 0) {
        this.showCuraScheduler = true;
        this.isremove = true;
      }
      else if (apptSchedulerTypeForCP === 1) {
        this.showCuraScheduler = false;
        this.isremove = false;
      }
     else if (apptSchedulerTypeForCP === 2) {
        this.showCuraScheduler = true;
        this.isremove = true;
      }
      else {
        this.showCuraScheduler = false;
        this.isremove = false;
      }


      if (data.clinicList[0].isApptSchedulerEnabled === 1) {
        this.coClinicDetails.clinicList[0].isApptSchedulerEnabled = true;
      }
      if (data.clinicList[0].isApptSchedulerEnabled === 0) {
        this.coClinicDetails.clinicList[0].isApptSchedulerEnabled = false;
      }
      this.coClinicDetails.pData = this.pData;
      if (this.coClinicDetails.clinicList[0].isCancelled == true && this.is_Admin) {
        this.showRemoveCancelledStatus = true;
      }
      if ([2, 6].indexOf(this.coClinicDetails.opportunityInfo.clinicType) > -1) {
        if ((this.coClinicDetails.clinicList[0].isCancelled == true || this.coClinicDetails.clinicList[0].isCompleted == true) && this.is_Admin) {
          this.showLastLogStatus = true;
        }
      }
      else {
        if ((this.coClinicDetails.clinicList[0].isCancelled == true || this.coClinicDetails.clinicList[0].isConfirmed == true || this.coClinicDetails.clinicList[0].isCompleted == true) && this.is_Admin) {
          this.showLastLogStatus = true;
        }
      }
      if (this.coClinicDetails.opportunityInfo.childClinicAgreementId != null && this.coClinicDetails.opportunityInfo.childClinicAgreementId > 0) {
        this.ShowDeleteAmendAgreement = true;
      }
      this.isNoClinic = this.coClinicDetails.clinicList[0].isNoClinic;

      if (this.coClinicDetails.clinicList[0].isNoClinic == 1) {
        if (this.coClinicDetails.clinicList[0].isConfirmed != 1) {
          this.ConfirmBtnLabel = "Provided Vouchers";
        }
        else if (this.coClinicDetails.clinicList[0].isConfirmed == 1) {
          this.ConfirmBtnLabel = "Vouchers Provided";
        }
      }
      else {
        this.coClinicDetails.clinicList[0].isConfirmed == 1 ? this.ConfirmBtnLabel = "Confirmed" : this.ConfirmBtnLabel = "Confirm";
      }
      this.enableDisableClinicDates();
      // this.logHistory= this.historyGroup.transform(this.coClinicDetails.clinicsUpdateHistoryLogList,'updatedOn')
      var ala_sql = require('alasql');
      let source = this.coClinicDetails.clinicsUpdateHistoryLogList;
      source.reverse();
      let updateLogHistoryQuery = ala_sql("SELECT updatedOn, updateAction, ARRAY({updateSubCategory:x.updateSubCategory, updatedField:x.updatedField}) AS updateField, ARRAY({updateSubCategory:x.updateSubCategory, updatedValue:x.updatedValue}) as updatedValue, x.updatedBy updatedBy \
 FROM ? x \
 GROUP BY  updatedOn,updateAction,updatedBy ORDER BY updatedOn DESC", [source]);
      for (var i = 0; i < updateLogHistoryQuery.length; i++) {
        // if(updateLogHistoryQuery[i].updateField && updateLogHistoryQuery[i].updateField[0].key){
        updateLogHistoryQuery[i].updateField = (updateLogHistoryQuery[i].updateField == "null") ? "" : this._keysPipe.transform(updateLogHistoryQuery[i].updateField);
        updateLogHistoryQuery[i].updatedValue = this._keysPipe.transform(updateLogHistoryQuery[i].updatedValue);
      }
      // }

      // 863 fix
      const keyToRemove = "Pharmacist Information";
      updateLogHistoryQuery.forEach(element => {
        const filteredField = element.updateField.filter((item) => item.key != keyToRemove);
        const filteredValue = element.updatedValue.filter((item) => item.key != keyToRemove);

        element.updateField = [];
        element.updateField.push(...filteredField);

        element.updatedValue = [];
        element.updatedValue.push(...filteredValue);
      });

      this.updateLogHistory = updateLogHistoryQuery;

      this.naClinicGroupIdOriginal = this.coClinicDetails.opportunityInfo.naClinicGroupId;
      this.naClinicGroupId = this.coClinicDetails.opportunityInfo.naClinicGroupId;

      if (this.naClinicGroupId && this.naClinicGroupId.toLowerCase().search('(flu)') !== -1) {
        this.naClinicGroupId = this.naClinicGroupId.replace('(flu)', ' (flu)');

      }
      if (this.naClinicGroupId && this.naClinicGroupId.toLowerCase().search('(routine)') !== -1) {
        this.naClinicGroupId = this.naClinicGroupId.replace('(routine)', ' (routine)');
      }

      // 
      if (this.naClinicGroupId != null && this.naClinicGroupId != '') {
        var updategroupid = '';

        var IsFLUVVoucherIncluded = this.naClinicGroupId.includes('FLUV');
        var IsROUVVoucherIncluded = this.naClinicGroupId.includes('ROUV');
        if (IsFLUVVoucherIncluded == true || IsROUVVoucherIncluded == true) {
          var splitData = this.naClinicGroupId.split(',');
          if (splitData.length > 0) {
            splitData.forEach((element, index) => {

              if (!element.includes('FLUV') && !element.includes('ROUV')) {
                if (index == 0) {
                  updategroupid += element;
                }
                if (index > 0) {
                  updategroupid = updategroupid + ',' + element;
                }
              }

              if (element.indexOf('and') > -1) {
                var splitData = element.split('and');
                if (splitData.length > 0) {

                  splitData.forEach(element => {
                    if (!element.includes('FLUV') && !element.includes('ROUV')) {
                      if (index == 0) {
                        updategroupid += element;
                      }
                      if (index > 0) {
                        updategroupid = updategroupid + ',' + element;
                      }
                    }
                  });
                }
              }
            });
          }
          this.naClinicGroupId = updategroupid;
        }
      }

      let imz_count = 0;
      this.coClinicDetails.clinicImmunizationList.forEach(imz => {

        // if (imz.paymentTypeId == 13) {
        //   this.visibleInoviceButton = true;
        // }
        // if (imz.paymentTypeId == 6) {
        //   this.visibleInoviceButton = true;
        // }
        if (imz.paymentTypeId == 13 && imz.isVoucherNeeded) {
          this.is_voucher_needed = imz.isVoucherNeeded;
        }

        if (imz.paymentTypeId == 14 && imz.immunizationPk == 31) {
          this.is_voucher_neededForEQ = true;
        }
        else {
          this.is_voucher_neededForEQ = false;
        }


        if (ImmunizationsClinicalRequirements["immunizations"].indexOf(imz.immunizationName) > -1 && JSON.stringify(this.clinicalRequirements).indexOf(imz.immunizationName) == -1) {
          this.clinicalRequirements.push({
            "imzName": imz.immunizationName,
            "imzReqText": String.Format(ImmunizationsClinicalRequirements["imzClinicalrequirementsText"]["imzText"], ImmunizationsClinicalRequirements["imzClinicalReqAgeGroups"][imz.immunizationName], imz.immunizationName, ImmunizationsClinicalRequirements["links"][imz.immunizationName], ImmunizationsClinicalRequirements["additionalLinks"][imz.immunizationName]),
            "imzChkText": String.Format(ImmunizationsClinicalRequirements["imzClinicalrequirementsText"]["chkText"], imz.immunizationName, ImmunizationsClinicalRequirements["imzClinicalReqAgeGroups"][imz.immunizationName], ImmunizationsClinicalRequirements["links"][imz.immunizationName]),
            "imzIndex": imz_count
          });
          this.showClinicalRequirements = true;
        }

        if (imz.paymentTypeId == 13 && imz.isCopay && imz.immunizationName.toLowerCase().search('flu') !== -1 &&
          this.coClinicDetails.opportunityInfo.naClinicGroupId.search('pending copay group id') !== -1 && this.utility.isAdminAllowedToAddGroupId()) {
          this.is_flu_group_id = true;
        }
        if (imz.paymentTypeId == 13 && imz.isCopay && imz.immunizationName.toLowerCase().search('flu') == -1 &&
          this.coClinicDetails.opportunityInfo.naClinicGroupId.search('pending copay group id') !== -1 && this.utility.isAdminAllowedToAddGroupId()) {
          this.is_routine_group_id = true;
        }
        if (imz.paymentTypeId == 13 && (imz.isVoucherNeeded == true || imz.isVoucherNeeded == 1) &&
          imz.immunizationName.toLowerCase().search('flu') !== -1) {
          this.is_flu_date = true;
          this.isFluVoucher = true;
          if (!this.is_routine_group_id && !this.is_flu_group_id) {
            this.isVoucherSectionShow = true;
          }
        }
        if (imz.paymentTypeId == 13 && (imz.isVoucherNeeded == true || imz.isVoucherNeeded == 1) &&
          imz.immunizationName.toLowerCase().search('covid') !== -1) {
          this.isRoutineVoucher = true;
          this.is_routine_date = true;
        }
        if (imz.paymentTypeId == 13 && (imz.isVoucherNeeded == true || imz.isVoucherNeeded == 1) &&
          (imz.immunizationName.toLowerCase().search('flu') == -1)
        ) {
          if (imz.immunizationName.toLowerCase().search('covid') == -1) {
            this.is_routine_date = true;
            this.isRoutineVoucher = true;
          }
          if (!this.is_routine_group_id && !this.is_flu_group_id) {
            this.isVoucherSectionShow = true;
          }
        }
        if ((imz.paymentTypeId == 13 || imz.paymentTypeId == 6) && !this.coClinicDetails.clinicList[0].isNoClinic) {
          if (this.coClinicDetails.opportunityInfo.naClinicGroupId.search('pending copay group id') == -1) {
            if (this.coClinicDetails.opportunityInfo.covidOpportunityType != 1 && this.coClinicDetails.opportunityInfo.fluOpportunityType != 3) {
              this.isVARSectionShow = true;
            }
          }
        }
        imz_count++;
      });
      this.isCovidVaccineAvailable = this.coClinicDetails.clinicImmunizationList.filter(p => p.immunizationName.search(/COVID19/gi) >= 0).length > 0;
      for (let i = 0; i < this.coClinicDetails.clinicList.length; i++) {

        if (this.coClinicDetails.clinicList[i].clinicDate != null && this.coClinicDetails.clinicList[i].clinicDate != undefined) {
          var currentDate = new Date();
          var c_date = new Date(this.coClinicDetails.clinicList[i].clinicDate);
          this.coClinicDetails.clinicList[i].clinicDate = c_date.getMonth() + 1 + "/" + c_date.getDate() + "/" + c_date.getFullYear();
          if(c_date < currentDate){
            this.minDateValue = null;
          }
        } else if (this.coClinicDetails.clinicList[i].isNoClinic >= 1) {
          this.coClinicDetails.clinicList[i].clinicDate = null;
        } else {
          var c_date = new Date();
          this.coClinicDetails.clinicList[i].clinicDate = c_date.getMonth() + 1 + "/" + c_date.getDate() + "/" + c_date.getFullYear();
        }
        this.is_no_clinc = this.coClinicDetails.clinicList[i].isNoClinic;
        let is_clinical_req: boolean = false;
        this.coClinicDetails.clinicList[i].clinicImzQtyList.forEach(imzQtyItem => {
          imzQtyItem["imzNameAndPmtMethod"] = imzQtyItem.immunizationName;
          if (ImmunizationsClinicalRequirements["immunizations"].indexOf(imzQtyItem.immunizationName) > -1) {
            is_clinical_req = (imzQtyItem.isClinicalReqd) ? true : false;
          }
        });

        this.canDisableClinicalRequirements = (is_clinical_req) ? true : null;
        this.coClinicDetails['clinicalRequirements'] = (is_clinical_req) ? true : false;
      }
      if (this.showClinicalRequirements) {
        this.clinicalReqUserNote = ImmunizationsClinicalRequirements.userNote;
      }

      if (this.coClinicDetails.clinicList[0].isCompleted || Number(this.coClinicDetails.clinicList[0].isCompleted) == 1) {
        this.validateCancel = true;
        this.isAmendButtonEnable = true;


        let testsSortedByCognome = this.coClinicDetails.clinicsUpdateHistoryLogList.sort((a, b) => (a.updatedOn > b.updatedOn ? -1 : 1));
        if (testsSortedByCognome[0].updateAction.toLocaleLowerCase() == 'amended') {
          this.IsAmendSubmit = true;
          this.step_value = 2;
          this.currentStep = 1;
        }
        else {
          this.IsAmendSubmit = false;
          this.step_value = 3;
          this.currentStep = 2;
        }


        this.validateConfirm = true;
        this.is_cancelled = true;
       
      }
      else if (this.coClinicDetails.clinicList[0].isCancelled || Number(this.coClinicDetails.clinicList[0].isCancelled) == 1) {
        this.validateCancel = true;
        this.isAmendButtonEnable = false;
        this.is_cancelled = true;
        if (this.coClinicDetails.clinicList[0].isConfirmed || Number(this.coClinicDetails.clinicList[0].isConfirmed) == 1) {
          this.validateConfirm = true;
        }
        this.step_value = -1;
        this.currentStep = -1;
      }
      else if (this.coClinicDetails.clinicList[0].isConfirmed || Number(this.coClinicDetails.clinicList[0].isConfirmed) == 1) {
        this.validateConfirm = true;
        this.step_value = 2;
        this.currentStep = 1;
      }


      if (this.coClinicDetails.clinicList[0].clinicImzQtyList.filter(p => p.totalImmAdministered > 0).length > 0) {
        this.disableCancelIfTotalAdmPresent = true;
      }
      else {
        this.disableCancelIfTotalAdmPresent = null;
      }

      if (this.clinicType == 2) {
        this.BillingVaccinesCol1Heading = "Vaccines Covered";
        this.BillingVaccinesCol2Rates = "";
        this.BillingVaccinesCol3PaymentMethod = "Vouchers Distributed";
        this.BillingVaccinesCol4EstVol = "Estimated Volume";
        this.BillingVaccinesCol5TtlAdmn = "Total Administered";
      }
      if(data.clinicList[0].apptSchedulerType == 2){
        this.BillingVaccinesCol1Heading = "Immunization";
        this.BillingVaccinesCol2Rates = "Rates";
        this.BillingVaccinesCol3PaymentMethod = "Payment Method";
        this.BillingVaccinesCol4EstVol = "Est Vol";
        this.BillingVaccinesCol5TtlAdmn = "Electronic VARs";
      }
      else {
        this.BillingVaccinesCol1Heading = "Immunization";
        this.BillingVaccinesCol2Rates = "Rates";
        this.BillingVaccinesCol3PaymentMethod = "Payment Method";
        this.BillingVaccinesCol4EstVol = "Est Vol";
        this.BillingVaccinesCol5TtlAdmn = "Total Administered";
      }
      if (this.utility.isStoreIsfromRestrictedState()) {
        if ([1, 2, 6].indexOf(this.clinicType) > -1) {
          this._localContractService.getpreviousseasoncliniclocations(this.coClinicDetails.opportunityInfo.clinicAgreementPk, this.coClinicDetails.opportunityInfo.businessPk).subscribe((res: any) => {
            this.previous_location_data = res.previousLocationsList;
            if (this.previous_location_data.length > 1) {
              this.is_previous_locations = true;
              this.display_message = true;
            }
            else {
              this.display_message = false;
            }
          });
        }
      }

      this.clinicLocation = 1;
      this.states = states;
      let today = new Date();
      this.isApptSchedulerDisabled = this.coClinicDetails.clinicList[0].isCancelled
        || this.coClinicDetails.clinicList[0].isCompleted
        || this.coClinicDetails.clinicList[0].isNoClinic
        || this.coClinicDetails.clinicList[0].isApptSchedulerEnabled
        || new Date(this.coClinicDetails.clinicList[0].clinicDate) < new Date(today.getFullYear(), today.getMonth(), today.getDate()) ? true : null;

      if (this.coClinicDetails.clinicList[0].apptSchedulerType == 1) {
        if (data.appointmentScheduler) {
          this.immunizers = data.appointmentScheduler.immunizers;
          this.estimatedImmunizers = data.appointmentScheduler.estimatedImmunizers;
          this.signUpEndDate = data.appointmentScheduler.signupEndDate;
          this.accessSchedulerEncryptedLink = data.appointmentScheduler.encryptedSiteLink;
          this.accessSchedulerTrackerEncryptedLink = data.appointmentScheduler.encryptedClientApptTrackingLink;
          if (data.appointmentScheduler.enryptedClientKey !== null) {
            this.showAppointmentScheduler = data.clinicList[0].apptSchedulerType == 1 && data.clinicList[0].isApptSchedulerEnabled > 0 && data.clinicList[0].isNoClinic != true;
            this.isEncryptLinkNull = true;
            this.encryptedSiteLink = true;
            this.apptSchedulerEncLink = this.win.location.protocol + "//" + this.win.location.hostname + "/ss" + "/" + data.appointmentScheduler.enryptedClientKey;
            this.myAngularxQrCode = this.apptSchedulerEncLink;
          }
          if (data.appointmentScheduler.encryptedClientApptTrackingLink !== null) {
            this.encryptedClientApptTrackingLink = true;
            this.copyApptTrackingEncLink = this.win.location.protocol + "//" + this.win.location.hostname + "/ss" + "/" + data.appointmentScheduler.encryptedClientApptTrackingLink;
          }
        }
      }
      else if ([2, 3].indexOf(this.coClinicDetails.clinicList[0].apptSchedulerType) > -1) {
        this.immunizers = -1;
        this.estimatedImmunizers = -1;
        this.signUpEndDate = this.coClinicDetails.clinicList[0].clinicDate;
        this.isEncryptLinkNull = true;
        this.encryptedSiteLink = true;
        this.apptSchedulerEncLink = this.coClinicDetails.clinicList[0].privateCPSchedulerLink;
        this.myAngularxQrCode = this.apptSchedulerEncLink;
      }
      this.bindControls();
      this.clinicDetailsForm.updateValueAndValidity();
      this.spinner.hide();
      this.isCovidVaccineAvailable = this.coClinicDetails.clinicImmunizationList.filter(p => p.immunizationName.search(/COVID19/gi) >= 0).length > 0;
      let isFluVaccineAvailable: boolean = this.coClinicDetails.clinicImmunizationList.filter(p => p.immunizationName.search(/Influenza/gi) >= 0).length > 0;
      let isRoutineVaccineAvailable: boolean = false;





      isRoutineVaccineAvailable = this.coClinicDetails.clinicImmunizationList.filter(p => (p.immunizationName.search(/Influenza/gi) == -1) && p.immunizationName.search(/COVID19/gi) == -1).length > 0;

      if (isFluVaccineAvailable || isRoutineVaccineAvailable) {
        this.isNonCovidVaccineAvailable = true;
      }
      else {
        this.isNonCovidVaccineAvailable = false;
      }
      if (this.is_Admin && (this.clinicType == 5 || this.clinicType == 3 || this.clinicType == 7)) {
        this.specificAdmin = true;
      }

      this.covidOpportunityType = this.coClinicDetails.opportunityInfo.covidOpportunityType;
      this.apptSchedulerType = this.coClinicDetails.clinicList[0].apptSchedulerType;
    },
      err => {
        this.spinner.hide();
        this.showErrDialogThenGoToHome(ErrorMessages['clinicDetailsErrHeader'], err.message);
      });

  }
  PreviousLocationChange(event) {
    if (event != "") {
      this.display_message = false;
      this.previous_locations = this.previous_location_data.filter(item => item.rowId == event);
    }
    else {
      this.display_message = true;

    }
  }
  //noClinicOptionChanged(formIndex: number, checked: boolean) {
  noClinicOptionChanged(formIndex: number, event: any) {


    let control = <FormArray>this.clinicDetailsForm.controls['clinicList'];

    //  if(this.is_voucher_neededForEQ)
    if (control.controls[formIndex].value.clinicImzQtyList.some(imzqty => imzqty.immunizationPk == 31 && imzqty.paymentTypeId == 14) && event.target.checked) {
      event.preventDefault();
      control.controls[formIndex].get('isNoClinic').setValue(1);

    }
    else {
      event.preventDefault();
      control.controls[formIndex].get('isNoClinic').setValue(0);

    }


    if (this.clinicType == 13 && event.target.checked) {
      let noClinicOption = control.controls[formIndex].get('isNoClinic');
      if ((noClinicOption.value == 1 || noClinicOption.value == true) && event.target.checked) {
        const clinicListArray = this.clinicDetailsForm.controls['clinicList'] as FormArray;
        const specificClinic = clinicListArray.controls[formIndex] as FormGroup;
        const specificClinicImz = specificClinic.controls['clinicImzQtyList'] as FormArray;

        for (const imzQty of specificClinicImz.controls) {
          const imzCtrl = imzQty as FormGroup;
          if (imzCtrl.value.immunizationPk != 31) {
            imzCtrl.controls['estimatedQuantity'].setValue(0);
            imzCtrl.controls['estimatedQuantity'].setValue(0);
            this.IsnoclinicvouchersDistributed = true;
          }
        }

        if (specificClinicImz.value.some(imzList => imzList.immunizationPk == 31)) {

          const shotsCountOfFlu = parseInt(specificClinicImz.value.find(imzList => imzList.immunizationPk == 31).estimatedQuantity ?? 0);
          if (shotsCountOfFlu != 0 && shotsCountOfFlu > 0) {
            specificClinic.controls['vouchersDistributed'].setValue(shotsCountOfFlu);
            // this.shouldDisableVouchersDistributed = true;
            // specificClinic.controls['vouchersDistributed'].markAsUntouched();
            // specificClinic.controls['vouchersDistributed'].markAsPristine();
            // specificClinic.controls['vouchersDistributed'].disable();
            specificClinic.controls['vouchersDistributed'].setValidators(null);
            specificClinic.controls['vouchersDistributed'].updateValueAndValidity();

          }
        }
        // control.controls[formIndex].get('naClinicAddress1').setValidators(null);
        control.controls[formIndex].get('naClinicAddress2').setValidators(null);
        // control.controls[formIndex].get('naClinicCity').setValidators(null);
        // control.controls[formIndex].get('naClinicState').setValidators(null);
        // control.controls[formIndex].get('naClinicZip').setValidators(null);
        control.controls[formIndex].get('clinicDate').setValidators(null);
        control.controls[formIndex].get('naClinicStartTime').setValidators(null);
        control.controls[formIndex].get('naClinicEndTime').setValidators(null);

        control.controls[formIndex].get('naContactFirstName').updateValueAndValidity();
        control.controls[formIndex].get('naContactLastName').updateValueAndValidity();
        control.controls[formIndex].get('naClinicContactPhone').updateValueAndValidity();
        control.controls[formIndex].get('naContactEmail').updateValueAndValidity();
        control.controls[formIndex].get('naClinicAddress1').updateValueAndValidity();
        control.controls[formIndex].get('naClinicAddress2').updateValueAndValidity();
        control.controls[formIndex].get('naClinicCity').updateValueAndValidity();
        control.controls[formIndex].get('naClinicState').updateValueAndValidity();
        control.controls[formIndex].get('naClinicZip').updateValueAndValidity();
        control.controls[formIndex].get('clinicDate').updateValueAndValidity();
        control.controls[formIndex].get('naClinicStartTime').updateValueAndValidity();
        control.controls[formIndex].get('naClinicEndTime').updateValueAndValidity();
        this.isNoClinic = 1;
        this.isApptSchedulerDisabled = this.coClinicDetails.clinicList[0].isCancelled || this.coClinicDetails.clinicList[0].isCompleted ? true : null;
        this.isApptSchedulerDisabled = true;
        if (event.target.checked) {
          control.controls[0].patchValue({ apptSchedulerType: null });
        }
      }
      else {
        //
        const clinicListArray = this.clinicDetailsForm.controls['clinicList'] as FormArray;
        const specificClinic = clinicListArray.controls[formIndex] as FormGroup;
        const specificClinicImz = specificClinic.controls['clinicImzQtyList'] as FormArray;

        for (const imzQty of specificClinicImz.controls) {
          const imzCtrl = imzQty as FormGroup;
          if (imzCtrl.value.immunizationPk != 31) {
            imzCtrl.controls['estimatedQuantity'].setValue(0);
            // imzCtrl.controls['estimatedQuantity'].set(0);
            // imzCtrl.controls['estimatedQuantity'].markAsUntouched();
            // imzCtrl.controls['estimatedQuantity'].markAsPristine();
            this.IsnoclinicvouchersDistributed = true;
          }
        }

        if (specificClinicImz.value.some(imzList => imzList.immunizationPk == 31)) {

          const shotsCountOfFlu = parseInt(specificClinicImz.value.find(imzList => imzList.immunizationPk == 31).estimatedQuantity ?? 0);
          if (shotsCountOfFlu != 0 && shotsCountOfFlu > 0) {
            specificClinic.controls['vouchersDistributed'].setValue(shotsCountOfFlu);
            this.shouldDisableVouchersDistributed = false;
            // specificClinic.controls['vouchersDistributed'].markAsUntouched();
            // specificClinic.controls['vouchersDistributed'].markAsPristine();
            // specificClinic.controls['vouchersDistributed'].disable();
            specificClinic.controls['vouchersDistributed'].setValidators(Validators.required);
            specificClinic.controls['vouchersDistributed'].updateValueAndValidity();

          }
        }
        // control.controls[formIndex].get('naClinicAddress1').setValidators(null);
        control.controls[formIndex].get('naClinicAddress2').setValidators(null);
        // control.controls[formIndex].get('naClinicCity').setValidators(null);
        // control.controls[formIndex].get('naClinicState').setValidators(null);
        // control.controls[formIndex].get('naClinicZip').setValidators(null);
        control.controls[formIndex].get('clinicDate').setValidators(null);
        control.controls[formIndex].get('naClinicStartTime').setValidators(null);
        control.controls[formIndex].get('naClinicEndTime').setValidators(null);

        control.controls[formIndex].get('naContactFirstName').updateValueAndValidity();
        control.controls[formIndex].get('naContactLastName').updateValueAndValidity();
        control.controls[formIndex].get('naClinicContactPhone').updateValueAndValidity();
        control.controls[formIndex].get('naContactEmail').updateValueAndValidity();
        control.controls[formIndex].get('naClinicAddress1').updateValueAndValidity();
        control.controls[formIndex].get('naClinicAddress2').updateValueAndValidity();
        control.controls[formIndex].get('naClinicCity').updateValueAndValidity();
        control.controls[formIndex].get('naClinicState').updateValueAndValidity();
        control.controls[formIndex].get('naClinicZip').updateValueAndValidity();
        control.controls[formIndex].get('clinicDate').updateValueAndValidity();
        control.controls[formIndex].get('naClinicStartTime').updateValueAndValidity();
        control.controls[formIndex].get('naClinicEndTime').updateValueAndValidity();
        this.isNoClinic = 1;
        this.isApptSchedulerDisabled = this.coClinicDetails.clinicList[0].isCancelled || this.coClinicDetails.clinicList[0].isCompleted ? true : null;
        this.isApptSchedulerDisabled = true;
        if (event.target.checked) {
          control.controls[0].patchValue({ apptSchedulerType: null });
        }

      }
    }
  }

  trimSpaces(formCtrlName) {
    const formValue = this.clinicDetailsForm.get(formCtrlName)?.value;
    if (formValue) {
      this.clinicDetailsForm.get(formCtrlName).setValue(formValue.trim());
    }
  }

  onlyLettersSpaceAndNumbers(event) {
    return Util.onlyLettersSpaceAndNumbers(event)
  }

  onlyNumberKey(event) {
    Util.onlyNumbers(event);
  }

  onlyNumberDecimals(event) {
    Util.onlyNumberDecimals(event);
  }

  checkIfFluGroupid() {
    this.coClinicDetails.clinicImmunizationList.forEach(rec => {
      if (rec.paymentTypeId == 13 && (rec.sendInvoiceTo == 1 || rec.sendInvoiceTo === 1) &&
        (rec.isCopay === '1' || rec.isCopay === 1) && rec.immunizationName.toLowerCase().search('flu') !== -1) {
        throw true;
      }
    });
  }
  checkIfRoutineGroupid() {
    this.coClinicDetails.clinicImmunizationList.forEach(rec => {
      if ((rec.paymentTypeId == 13 && (rec.sendInvoiceTo == 1 || rec.sendInvoiceTo === 1))) {
        if (rec.isCopay == 1 || rec.isCopay == '1') {
          return true;
        }
      }
    });
  }
  checkIfFluImmForCorpInvoiceSelected() {
    try {
      this.coClinicDetails.clinicImmunizationList.forEach(rec => {
        if (rec.paymentTypeId == 13 && (rec.sendInvoiceTo == 1 || rec.sendInvoiceTo === 1) &&
          (rec.isVoucherNeeded === '1' || rec.isVoucherNeeded === 1) && rec.immunizationName.toLowerCase().search('flu') !== -1) {
          throw true;
        }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }
  checkIfVoucherYesSelectedForFluCorpInvoiceSelected() {
    let isFluYes: boolean = false;
    let count: number = 0;
    try {
      this.coClinicDetails.clinicImmunizationList.forEach(rec => {
        if ((rec.paymentTypeId == 13 && (rec.sendInvoiceTo == 1 || rec.sendInvoiceTo === 1))) { //&& rec.immunizationName.toLowerCase().search('flu')!==-1
          //throw true;
          if (rec.isVoucherNeeded == 1 || rec.isVoucherNeeded == '1') {
            isFluYes = true;
          }
          count++;
        }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    if (count <= 0) {
      return true;
    }
    else if (count > 0 && !isFluYes) {
      return false;
    }

    return true;
  }
  dlgCOViewAgreement() {
    this.dlgDispCOViewAgreement = !this.dlgDispCOViewAgreement;
  }

  formatter = (result: any) =>
    result.storeId == undefined ? "" : result.storeId + " - " +
      result.address + ", " + result.city + ","
      + result.state + " " + result.zip || '';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this._headerservice.storeSearch(this.user_profile.userPk, term.trim(), 0).pipe(
          tap((data) => {
            this.storeSearchRsp = data;
          }),
          catchError(() => {
            // this.searchFailed = true;
            return observableOf([]);
          }),)),
      tap(() => this.searching = false),);


  onChange(reassignVal) {
    this.ariaV = false;
    this.storeV = false;
    this.distV = false;
    if (reassignVal.toLowerCase() == "store") {
      this.storeV = true;
      this.distV = false;
      this.ariaV = false;
    }
    if (reassignVal.toLowerCase() == "district") {
      this.distV = true;
      this.storeV = false;
      this.ariaV = false;
    }
    if (reassignVal.toLowerCase() == "area") {
      this.ariaV = true;
      this.storeV = false;
      this.distV = false;
    }
    if (reassignVal.toLowerCase() == "geography") {
      this.ariaV = false;
      this.storeV = false;
      this.distV = false;
    }
    if (reassignVal == "" || reassignVal.toLowerCase() == "geography") {
      return;
    }
    this.coClinicdetailsService.getAreaDist(reassignVal, 0).subscribe((data: any) => {
      // 
      let dataList = data;

      this.area_dist = [];
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.area_dist.push({ key: keyValue, value: valueData })
      }
    });
  }
  closePop() {
    this.dlgDispCOViewAgreement = false;
    this.clearReassignDialogControls();
  }
  saveReassign() {
    this.reassignForm = this.formBuilder.group({
      assignType: [this.reassignForm.value.assignType, null],
      assignStoreID: [this.reassignForm.value.assignStoreID, null],
      assignStore: [this.reassignForm.value.assignStore, null],
    });
    this.dlgDispCOViewAgreement = false;
    let restrictedStateCompliance: boolean = true;
    let assignType = this.reassignForm.controls['assignType'].value;
    if (assignType == undefined || (assignType == "Store" && this.reassignForm.controls['assignStoreID'].value == undefined) ||
      ((assignType == "Area" || assignType == "District") && this.reassignForm.controls['assignStore'].value == "")) {
      this.showDialogV2("Clinic Details", "Please select Reassign Type or Reassign Category");
      return;
    }
    if (assignType.toLowerCase() == "store") {
      if (this.reassignForm.controls['assignStoreID'].value.storeId == undefined) {
        this._headerservice.storeSearch(this.user_profile.userPk, this.reassignForm.controls['assignStoreID'].value.trim(), 0).subscribe(data => {
          this.storeSearchRsp = data;
          restrictedStateCompliance = this.checkRestrictedStateCompliance();
          if (this.storeSearchRsp.length > 0 && restrictedStateCompliance) {
            this.saveChanges();
            return;
          }
          else if (this.storeSearchRsp.length <= 0) {
            this.showDialogV2("Clinic Details", "You dont have permission to Reassign to this Store. Please retry using different keyword.");
            return;
          }
        });
        return;
      }
      else {
        restrictedStateCompliance = this.checkRestrictedStateCompliance();
      }

    }
    if (restrictedStateCompliance) {
      this.saveChanges();
    }
  }
  checkRestrictedStateCompliance() {

    if (!this.validateRestrictedStatesReassignment()) {
      this.dialogSummary = "Alert";
      var storeId = this.reassignForm.controls['assignStoreID'].value.storeId;
      if (storeId == undefined || storeId == null) {
        storeId = this.reassignForm.controls['assignStoreID'].value;
      }
      var filterSearchRes = this.storeSearchRsp.filter(store => store.storeId == storeId);
      if (filterSearchRes.length > 0) {
        if (this.utility.IsReassignmentAllowedForRestrictedState(filterSearchRes[0].state)) {
          return true;
        }
        this.dialogMsg = filterSearchRes[0].state == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
        this.showDialogV2(this.dialogSummary, this.dialogMsg);
        var orginalStoreId = SessionDetails.GetStoreId();
        this.searching = false;
        this.reassignForm.controls['assignStoreID'].setValue(orginalStoreId);
        this.reassignForm.controls['assignStoreID'].updateValueAndValidity();
        this.clearReassignDialogControls();
      }
      return false;
    }
    return true;
  }
  validateRestrictedStatesReassignment() {
    var storeId = this.reassignForm.controls['assignStoreID'].value.storeId;
    if (storeId == undefined || storeId == null) {
      storeId = this.reassignForm.controls['assignStoreID'].value;
    }
    let returnValue: boolean = true;
    if (storeId != SessionDetails.GetStoreId()) {
      var current_date = new Date();
      var filterSearchRes = this.storeSearchRsp.filter(store => store.storeId == storeId);
      if (SessionDetails.GetState() == "MO") {
        if (filterSearchRes.length > 0 && filterSearchRes[0].state == "DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
          returnValue = false;
        }
      }
      else if (SessionDetails.GetState() == "DC") {
        if (filterSearchRes.length > 0 && filterSearchRes[0].state == "MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
          returnValue = false;
        }
      }
      else if (SessionDetails.GetState() != "MO" || SessionDetails.GetState() != "DC") {
        if (filterSearchRes.length > 0 && ((filterSearchRes[0].state == "MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO))
          || (filterSearchRes[0].state == "DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)))) {
          returnValue = false;
        }
      }
    }
    return returnValue;
  }
  bindControls(check: boolean = false) {

    this.clinicDetailsForm = this.formBuilder.group({
      // store information
      storeID: this.formBuilder.control(this.coClinicDetails.opportunityInfo.storeId, null),//this.coClinicDetails.opportunityInfo.storeID, Validators.required),
      // Business Information
      businessName: this.formBuilder.control(this.coClinicDetails.opportunityInfo.businessName, null),
      businessPk: this.formBuilder.control(this.coClinicDetails.opportunityInfo.businessPk, null),
      pk_hash: this.formBuilder.control(this.coClinicDetails.opportunityInfo.pk_hash, null),
      clientReportingEmail: this.formBuilder.control(this.coClinicDetails.opportunityInfo.clientReportingEmail, null),
      b2BTeamEmail: this.formBuilder.control(this.coClinicDetails.opportunityInfo.b2BTeamEmail, null),
      salesForceOpportunityId: this.formBuilder.control(this.coClinicDetails.opportunityInfo.salesForceOpportunityId, null),
      salesForceAccountName: this.formBuilder.control(this.coClinicDetails.opportunityInfo.salesForceAccountName, null),
      firstName: this.formBuilder.control(this.coClinicDetails.opportunityInfo.firstName, Validators.required),
      phone: this.formBuilder.control(this.coClinicDetails.opportunityInfo.phone, Validators.required),
      fax: this.formBuilder.control(this.coClinicDetails.opportunityInfo.fax, null),
      lastName: this.formBuilder.control(this.coClinicDetails.opportunityInfo.lastName, Validators.required),
      email: this.formBuilder.control(this.coClinicDetails.opportunityInfo.businessContactEmail, null),
      jobTitle: this.formBuilder.control(this.coClinicDetails.opportunityInfo.jobTitle, null),
      // Billing & Vaccine Information
      clinicFee: this.formBuilder.control(this.coClinicDetails.opportunityInfo.clinicFee, null),
      isNoClinicFee: this.formBuilder.control(this.coClinicDetails.opportunityInfo.isNoClinicFee, null),
      vouchersDistributed: this.formBuilder.control(this.coClinicDetails.opportunityInfo.vouchersDistributed, null),
      vouchersRedeemed: this.formBuilder.control(this.coClinicDetails.opportunityInfo.vouchersRedeemed, null),
      naClinicPlanId: this.formBuilder.control(this.coClinicDetails.opportunityInfo.naClinicPlanId, null),
      naClinicGroupId: this.formBuilder.control(this.naClinicGroupId, null),
      coPayFLUGroupId: this.formBuilder.control(null, this.is_flu_group_id == true ? Validators.required : null),
      coPayROUTINEGroupId: this.formBuilder.control(null, this.is_routine_group_id == true ? Validators.required : null),
      recipientId: this.formBuilder.control(this.coClinicDetails.opportunityInfo.recipientId, null),
      //opportunityInfo:
      clinicImmunizationList: this.initImmunization(),
      naClinicAddlComments: this.formBuilder.control(this.coClinicDetails.opportunityInfo.naClinicAddlComments, null),
      naClinicSplBillingInstr: this.formBuilder.control(this.coClinicDetails.opportunityInfo.naClinicSplBillingInstr, null),
      // reassignType: this.formBuilder.control(location.reassignType, null),
      //   reassignedTo: this.formBuilder.control(location.reassignedTo, null),
      //Clinic Contact Information
      clinicList: this.initClinicLocations(),
      //pharmacist info
      // pharmacistName: this.formBuilder.control(this.coClinicDetails.opportunityInfo.pharmacistName, null),
      // pharmacistPhone: this.formBuilder.control(this.coClinicDetails.opportunityInfo.pharmacistPhone, null),
      // totalHours: this.formBuilder.control(this.coClinicDetails.opportunityInfo.totalHours, null),
      feedbackNotes: this.formBuilder.control(this.coClinicDetails.feedback, null),

      //Clinic History Log
      clinicsUpdateHistoryLogList: this.initClinicHistoryLog(),
    });
  }
  bindControls1() {
    this.clinicDetailsForm = this.formBuilder.group({
      // store information
      storeID: ['', null],//this.coClinicDetails.opportunityInfo.storeID, Validators.required),
      // Business Information
      businessName: this.formBuilder.control('', null),
      businessPk: this.formBuilder.control('', null),
      pk_hash: this.formBuilder.control('', null),
      clientReportingEmail: this.formBuilder.control('', null),
      b2BTeamEmail: this.formBuilder.control('', null),
      salesForceOpportunityId: this.formBuilder.control('', null),
      salesForceAccountName: this.formBuilder.control('', null),
      firstName: this.formBuilder.control('', Validators.required),
      phone: this.formBuilder.control('', Validators.required),
      fax: this.formBuilder.control('', null),
      lastName: this.formBuilder.control('', Validators.required),
      email: this.formBuilder.control('', null),
      jobTitle: this.formBuilder.control('', null),
      // Billing & Vaccine Information
      naClinicPlanId: this.formBuilder.control('', Validators.required),
      naClinicGroupId: this.formBuilder.control(null, Validators.required),
      coPayFLUGroupId: this.formBuilder.control(null, null),
      coPayROUTINEGroupId: this.formBuilder.control(null, null),
      recipientId: this.formBuilder.control('', Validators.required),
      clinicImmunizationList: [],
      naClinicAddlComments: this.formBuilder.control('', null),
      naClinicSplBillingInstr: this.formBuilder.control('', null),
      vouchersDistributed: this.formBuilder.control('', null),
      //Clinic Contact Information
      clinicList: [],
      //pharmacist info
      // pharmacistName: this.formBuilder.control('', null),
      // pharmacistPhone: this.formBuilder.control('', null),
      // totalHours: this.formBuilder.control('', null),
      feedbackNotes: this.formBuilder.control(""),//this.coClinicDetails.opportunityInfo.feedbackNotes),
      //Clinic History Log
      clinicsUpdateHistoryLogList: [],
    });
  }
  initClinicLocations() {
    let start_time: Date;
    let end_time: Date;
    let loc_array: any[] = [];
    let today = new Date();

    this.coClinicDetails.clinicList.forEach(loc => {
      this.clinicLocation = this.utility.colLetterToNum([1, 2, 6].indexOf(this.clinicType) > -1 ? loc.naClinicLocation.toUpperCase().replace("LOCATION ", "").trim() : loc.naClinicLocation);
      let start_time = loc.naClinicStartTime;
      let end_time = loc.naClinicEndTime;
      if (loc.naClinicStartTime != null) {
        if (loc.naClinicStartTime.indexOf(' ') == -1) {
          start_time = loc.naClinicStartTime.toLocaleLowerCase().replace('am', ' AM').replace('pm', ' PM');
        }
      }
      if (loc.naClinicEndTime != null) {
        if (loc.naClinicEndTime.indexOf(' ') == -1) {
          end_time = loc.naClinicEndTime.toLocaleLowerCase().replace('am', ' AM').replace('pm', ' PM');
        }
      }
      let cDate: any = '';
      let dateSplitString: any;
      if (loc.clinicDate !== null && loc.clinicDate !== undefined) {
        let dateString = loc.clinicDate;
        dateSplitString = dateString.split('/');
        cDate = (new Date(dateSplitString[2], Number(dateSplitString[0]) - 1, dateSplitString[1], 18, 30, 30));
      }
      this.coClinicDetails.clinicImmunizationList.forEach(rec => {

        if (rec.voucherExpirationDate != null && rec.voucherExpirationDate != undefined) {
          if (rec.immunizationName.toLowerCase().search('flu') !== -1 && rec.voucherExpirationDate.valueOf() > 0) {
            loc.fluExpiryDate = rec.voucherExpirationDate;
          }
          if (rec.immunizationName.toLowerCase().search('flu') === -1 && rec.voucherExpirationDate.valueOf() > 0
            && rec.immunizationName.toLowerCase().search('covid') === -1) {
            loc.routineExpiryDate = rec.voucherExpirationDate;
          }
          if (rec.immunizationName.toLowerCase().search('covid') !== -1 && rec.voucherExpirationDate.valueOf() > 0) {
            loc.covidExpiryDate = rec.voucherExpirationDate;
          }
        }
      });
      loc_array.push(
        this.formBuilder.group(
          {
            clinicPk: this.formBuilder.control(loc.clinicPk),
            clinicStoreId: this.formBuilder.control(loc.clinicStoreId),
            clinicNumber: this.formBuilder.control(loc.clinicNumber),
            naClinicLocation: this.formBuilder.control([1, 2, 6].indexOf(this.clinicType) > -1 ? loc.naClinicLocation.toUpperCase().replace("LOCATION ", "").trim() : loc.naClinicLocation),
            intakeID: this.formBuilder.control(loc.intakeID),
            naClinicAddress1: this.formBuilder.control(this.address1 = loc.naClinicAddress1, [Validators.required, Validators.maxLength(40)]),
            naClinicAddress2: this.formBuilder.control(this.address2 = loc.naClinicAddress2, [Validators.maxLength(60)]),
            naClinicCity: this.formBuilder.control(this.city = loc.naClinicCity, Validators.required),
            naClinicState: this.formBuilder.control(this.state = loc.naClinicState, Validators.required),
            naClinicZip: this.formBuilder.control(this.zipCode = loc.naClinicZip, Validators.required),
            vouchersDistributed: this.formBuilder.control(this.vouchersDistributed = loc.vouchersDistributed, null),
            clinicDate: this.formBuilder.control((cDate != '' && cDate != null) ? new Date(dateSplitString[2], Number(dateSplitString[0]) - 1, Number(dateSplitString[1]), 18, 30, 30) : null, (loc.isNoClinic != null && loc.isNoClinic == 1) ? null : [Validators.required]),
            naClinicStartTime: this.formBuilder.control(start_time, Validators.required),//Need to change
            naClinicEndTime: this.formBuilder.control(end_time, Validators.required),//Need to change
            naContactFirstName: this.formBuilder.control(loc.naContactFirstName, Validators.required),
            patientGroupId: this.formBuilder.control(loc.patientGroupId, null),
            naContactLastName: this.formBuilder.control(loc.naContactLastName, Validators.required),
            confirmedClientName: this.formBuilder.control(loc.confirmedClientName, null),
            naClinicContactPhone: this.formBuilder.control(loc.naClinicContactPhone, Validators.required),
            naContactEmail: this.formBuilder.control(loc.naContactEmail, Validators.required),
            isCancelled: this.formBuilder.control(loc.isCancelled ? 1 : 0, null),
            isConfirmed: this.formBuilder.control(loc.isConfirmed ? 1 : 0, null),
            isCompleted: this.formBuilder.control(loc.isCompleted ? 1 : 0, null),
            previousContact: this.formBuilder.control('', null),
            previousLocation: this.formBuilder.control('', null),
            isReassign: this.formBuilder.control(loc.isReassign, null),
            coPayFLUGroupId: this.formBuilder.control(loc.coPayFLUGroupId, null),
            coPayROUTINEGroupId: this.formBuilder.control(loc.coPayROUTINEGroupId, null),
            fluExpiryDate: this.formBuilder.control(loc.fluExpiryDate != null ? new Date(loc.fluExpiryDate) : new Date(today.getFullYear() + 1, today.getMonth(), today.getDate(), 18, 30, 30), null),
            routineExpiryDate: this.formBuilder.control(loc.routineExpiryDate != null ? new Date(loc.routineExpiryDate) : new Date(today.getFullYear() + 1, today.getMonth(), today.getDate(), 18, 30, 30), null),
            covidExpiryDate: this.formBuilder.control(loc.covidExpiryDate != null ? new Date(loc.covidExpiryDate) : new Date(today.getFullYear() + 1, today.getMonth(), today.getDate(), 18, 30, 30), null),
            // visitType: this.formBuilder.control(loc.visitType !==null?loc.visitType.toString():"", this.isCovidVaccineAvailable?Validators.required:null),
            coOutreachTypeId: this.formBuilder.control(loc.coOutreachTypeId, this.clinicType == 6 ? Validators.required : null),
            coOutreachTypeDesc: this.formBuilder.control(loc.coOutreachTypeDesc, null),
            clinicLatitude: this.formBuilder.control(loc.clinicLatitude, null),
            clinicLongitude: this.formBuilder.control(loc.clinicLongitude, null),
            reassignType: this.formBuilder.control(loc.reassignType, null),
            reassignedTo: this.formBuilder.control(loc.reassignedTo, null),
            clinicRoom: this.formBuilder.control(loc.clinicRoom, null),
            apptSchedulerType: this.formBuilder.control(loc.apptSchedulerType, null),
            isApptSchedulerEnabled: this.formBuilder.control(loc.isApptSchedulerEnabled, null),
            isCuraAppointmentScheduled: this.formBuilder.control(loc.isCuraAppointmentScheduled, null),
            isSendCancellationEmailsApproved: this.formBuilder.control(loc.isSendCancellationEmailsApproved, null),
            isNoClinic: this.formBuilder.control((loc.isNoClinic != null && loc.isNoClinic == 1) ? 1 : 0, null),
            clientReportingEmail: this.formBuilder.control(this.coClinicDetails.opportunityInfo.clientReportingEmail, null),
            b2BTeamEmail: this.formBuilder.control(this.coClinicDetails.opportunityInfo.b2BTeamEmail, null),
            salesForceOpportunityId: this.formBuilder.control(this.coClinicDetails.opportunityInfo.salesForceOpportunityId, null),
            salesForceAccountName: this.formBuilder.control(this.coClinicDetails.opportunityInfo.salesForceAccountName, null),
            clinicImzQtyList: this.AddInitialdummyClinicImmunizations(),
            paperVars: this.formBuilder.control(loc.paperVars, null ),
          }
        )
      )
    });
    return this.formBuilder.array(loc_array);

  }

  initClinicHistoryLog() {
    let start_time: Date;
    let end_time: Date;
    let loc_array: any[] = [];
    this.coClinicDetails.clinicsUpdateHistoryLogList.forEach(loc => {

      loc_array.push(
        this.formBuilder.group(
          {
            userPk: this.formBuilder.control(this.user_profile.userPk, null),
            updatedOn: this.formBuilder.control(loc.updatedOn, Validators.required),
            updateAction: this.formBuilder.control(loc.updateAction),
            updatedField: this.formBuilder.control(loc.updatedField),
            updateSubCategory: this.formBuilder.control(loc.updateSubCategory),
            updatedValue: this.formBuilder.control(loc.updatedValue),
            updatedBy: this.formBuilder.control(loc.updatedBy)

          }
        )

      )
    });
    return this.formBuilder.array(loc_array);
  }
  AddInitialdummyClinicImmunizations() {
    let imz_array: any[] = [];
    this.coClinicDetails.clinicList[0].clinicImzQtyList.forEach(imz => {
      let imz_app_date: any = '';
      let dateSplitString: any;
      if (imz.clinicalReqApprovedDate !== null && imz.clinicalReqApprovedDate !== undefined) {
        //cDate = new Date(new Date(loc.clinicDate));
        var temp_dt = imz.clinicalReqApprovedDate.toString().substring(0, imz.clinicalReqApprovedDate.toString().indexOf('T'));
        if (temp_dt.indexOf('-') > -1) {
          let temp_dt_parts = temp_dt.split('-');
          var dt = new Date(temp_dt_parts[0], temp_dt_parts[1] - 1, temp_dt_parts[2], 18, 30, 30);
          imz.clinicalReqApprovedDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 18, 30, 30);
        }
        //let dateString = imz.clinicalReqApprovedDate;
        //dateSplitString = dateString.split('/');
        //imz_app_date = (new Date(dateSplitString[2],Number(dateSplitString[0])-1,dateSplitString[1], 18, 30, 30));
        imz_app_date = imz.clinicalReqApprovedDate;
      }
      imz_array.push(
        this.formBuilder.group(
          {
            clinicPk: this.formBuilder.control(imz.clinicPk),
            immunizationPk: this.formBuilder.control(imz.immunizationPk),
            estimatedQuantity: this.formBuilder.control(imz.estimatedQuantity),
            immunizationName: this.formBuilder.control(imz.immunizationName),
            paymentTypeName: this.formBuilder.control(imz.paymentTypeName),
            paymentTypeId: this.formBuilder.control(imz.paymentTypeId),
            totalImmAdministered: this.formBuilder.control(imz.totalImmAdministered),
            paperVars:this.formBuilder.control(imz.paperVars),
            vouchersDistributed: this.formBuilder.control(imz.vouchersDistributed),
            isClinicalReqd: this.formBuilder.control(imz.isClinicalReqd ? true : null),
            clinicalReqdApprovedBy: this.formBuilder.control(imz.clinicalReqdApprovedBy),
            clinicalReqApprovedDate: this.formBuilder.control((imz_app_date != '' && imz_app_date != null) ? /*new Date(dateSplitString[2], Number(dateSplitString[0]) - 1, Number(dateSplitString[1]), 18, 30, 30)*/imz_app_date : null)
          }
        )
      )
    });

    return this.formBuilder.array(imz_array);

  }
  labelClinicNames(remove_loation_id) {
    let max_Location_id = 0;
    max_Location_id = this.max_clinic_location_id;
    var location_count = max_Location_id - 1;
    const control = <FormArray>this.clinicDetailsForm.controls['clinicList'];

    for (let index = 1; index < control.length + 1; index++) {
      let element = control.controls[index - 1];
      if (index <= this.clinicLocation) {
        if (remove_loation_id != -1)
          element.get('naClinicLocation').setValue(this.utility.numberToLetters(location_count));
        location_count++;
      }
    };
  }

  removeLocation(i: number) {
    let max_Location_id = 0;
    const control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    control.removeAt(i);
    this.clinicLocation--;
    max_Location_id = this.max_clinic_location_id;
    var location_count = max_Location_id - 1;
    for (let index = 1; index < control.length + 1; index++) {
      let element = control.controls[index - 1];
      if (index <= this.clinicLocation) {
        if (element.value.clinicPk == null) {
          element.get('naClinicLocation').setValue(this.utility.numberToLetters(location_count));
        }
        location_count++;
      }
    };
  }
  initImmunization() {
    let imz_array: any[] = [];
    this.coClinicDetails.clinicList[0].clinicImzQtyList.forEach(imz => {
      let imz_app_date: any = '';
      let dateSplitString: any;
      if (imz.clinicalReqApprovedDate !== null && imz.clinicalReqApprovedDate !== undefined) {
        //cDate = new Date(new Date(loc.clinicDate));
        var temp_dt = imz.clinicalReqApprovedDate.toString().substring(0, imz.clinicalReqApprovedDate.toString().indexOf('T'));
        if (temp_dt.indexOf('-') > -1) {
          let temp_dt_parts = temp_dt.split('-');
          var dt = new Date(temp_dt_parts[0], temp_dt_parts[1] - 1, temp_dt_parts[2], 18, 30, 30);
          imz.clinicalReqApprovedDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 18, 30, 30);
        }
        //let dateString = imz.clinicalReqApprovedDate;
        //dateSplitString = dateString.split('/');
        imz_app_date = imz.clinicalReqApprovedDate;
        //imz_app_date = (new Date(dateSplitString[2],Number(dateSplitString[0])-1,dateSplitString[1], 18, 30, 30));
      }
      imz_array.push(
        this.formBuilder.group(
          {
            pk: this.formBuilder.control(imz.clinicPk),
            immunizationPk: this.formBuilder.control(imz.immunizationPk),
            estimatedQuantity: this.formBuilder.control(imz.estimatedQuantity),
            vouchersDistributed: this.formBuilder.control(imz.vouchersDistributed),
            immunizationName: this.formBuilder.control(imz.immunizationName),
            paymentTypeName: this.formBuilder.control(imz.paymentTypeName),
            paymentTypeId: this.formBuilder.control(imz.paymentTypeId),
            price: this.formBuilder.control(ClinicDetailsUtilities.getPrice(this.coClinicDetails, imz.immunizationPk, imz.paymentTypeId)),
            totalImmAdministered: this.formBuilder.control(imz.totalImmAdministered),
            paperVars:this.formBuilder.control(imz.paperVars),
            isClinicalReqd: this.formBuilder.control((imz.isClinicalReqd ? true : (imz.isClinicalReqd == false) ? false : null)),
            clinicalReqdApprovedBy: this.formBuilder.control(imz.clinicalReqdApprovedBy),
            clinicalReqApprovedDate: this.formBuilder.control((imz_app_date != '' && imz_app_date != null) ? /*new Date(dateSplitString[2], Number(dateSplitString[0]) - 1, Number(dateSplitString[1]), 18, 30, 30)*/imz_app_date : null)
          }
        )
      )
    });

    this.isWagsFreeFluVoucherSelected = ClinicDetailsUtilities.CheckIfWagsFreeFluVoucherIsSelected(this.coClinicDetails.clinicImmunizationList);
    return this.formBuilder.array(imz_array);
  }

  updateClinicalRequirements(imz_name: string, imz_index: number, event: any) {
    for (let control of this.clinicDetailsForm.get('clinicImmunizationList')['controls']) {
      if (control.controls.immunizationName.value === imz_name) {
        control.controls.isClinicalReqd.value = (event.target.checked) ? true : null;
        this.clinicDetailsForm.value['clinicalRequirements'] = true;
        control.controls.isClinicalReqd.updateValueAndValidity();
        this.canDisableClinicalRequirements = null;
      }
    }
  }

  addLocation(location) {
    if (this.coClinicDetails.opportunityInfo.childClinicAgreementId != null && this.coClinicDetails.opportunityInfo.childClinicAgreementId > 0) {
      this.clinicNotConfirmedMsgDlg = true;
      this.showClinicNotConfirmedDialog('Amend Agreement', ErrorMessages['StopAddLocationAmend']);
      return false;
    }
    const control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    let max_Location_id = 0;
    max_Location_id = this.max_clinic_location_id;
    var location_count = max_Location_id - 1;
    this.labelClinicNames(-1);
    if (!this.user_profile.isAdmin) {
      var current_date = new Date();
      var storeState = SessionDetails.GetState();
      if (storeState != "MO" || storeState != "DC") {
        if (current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO) && storeState != "MO") {
          this.states = this.states.filter(s => s.id != "MO");
        }
        if (current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO) && storeState != "DC") {
          this.states = this.states.filter(s => s.id != "DC");
        }
      }
    }
    const addrCtrl = this.initAddClinicLocation(location);
    if(!addrCtrl.value.naClinicLocation.startsWith('CLINIC')){
      addrCtrl.controls.naClinicLocation.setValue(`CLINIC ${addrCtrl.value.naClinicLocation}`);
    }
    control.push(addrCtrl);
    this.isNewClinicAdded = true;
    if ([1, 2, 3, 5, 6].indexOf(this.clinicType) > -1 && this.isCovidVaccineAvailable) {
      const ctrl = <FormArray>this.clinicDetailsForm.controls['clinicList'];
      let index = ctrl.length;
      if ([3, 5].indexOf(this.clinicType) > -1) {
        this.copyPreviousContactInfo(index - 1, true);
      }
      this.copyPreviousLocation(index - 1, true);
    }
  }

  initAddClinicLocation(location: ClinicList) {

    if (location === undefined) {
      location = new ClinicList();
    }
    const control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    let max_Location_id = 0;
    max_Location_id = this.max_clinic_location_id;
    var location_count = max_Location_id + control.length - 1;
    let previous_display: boolean = true;
    if (this.previous_locations != undefined) {
      if (this.previous_locations.length > 0) {
        this.address1 = this.previous_locations[0].address;
        this.address2 = this.previous_locations[0].address2;
        this.city = this.previous_locations[0].city;
        this.state = this.previous_locations[0].state;
        this.zipCode = this.previous_locations[0].zip;
        previous_display = false;
      }
    }
    location.naClinicLocation = [3, 5, 7].indexOf(this.clinicType) > -1 ? this.coClinicDetails.clinicList[0].naClinicLocation : this.utility.numberToLetters(location_count);
    this.clinic_number = this.utility.colLetterToNum(this.utility.numberToLetters(location_count));//location.naClinicLocation
    location.clinicStoreId = this.coClinicDetails.clinicList[0].clinicStoreId;
    this.clinicLocation++;
    let today = new Date();
    let imzCount = 0;
    this.coClinicDetails.clinicImmunizationList.forEach(rec => {

      if (rec.voucherExpirationDate != null && rec.voucherExpirationDate != undefined) {
        if (rec.immunizationName.toLowerCase().search('flu') !== -1 && rec.voucherExpirationDate.valueOf() > 0) {
          location.fluExpiryDate = rec.voucherExpirationDate;
        }
        if ((rec.immunizationName.toLowerCase().search('flu') === -1 || rec.immunizationName.toLowerCase().search('covid') === -1) && rec.voucherExpirationDate.valueOf() > 0) {
          location.routineExpiryDate = rec.voucherExpirationDate;
        }
        if (rec.immunizationName.toLowerCase().search('covid') !== -1 && rec.voucherExpirationDate.valueOf() > 0) {
          location.covidExpiryDate = rec.voucherExpirationDate;
        }
      }
      imzCount = imzCount + 1;
    });
    return (this.formBuilder.group(
      {
        clinicPk: this.formBuilder.control(location.clinicPk),
        clinicStoreId: this.formBuilder.control(location.clinicStoreId),
        clinicNumber: this.formBuilder.control(this.clinic_number),
        naClinicLocation: this.formBuilder.control([1, 2, 6].indexOf(this.clinicType) > -1 ? location.naClinicLocation.toUpperCase().replace("LOCATION ", "").trim() : location.naClinicLocation.trim()),
        intakeID: this.formBuilder.control(this.intakeID),
        naClinicAddress1: this.formBuilder.control(this.utility.isStoreIsfromRestrictedState() ? this.address1 : location.naClinicAddress1, Validators.required),
        naClinicAddress2: this.formBuilder.control(this.utility.isStoreIsfromRestrictedState() ? this.address2 : location.naClinicAddress2, null),
        naClinicCity: this.formBuilder.control(this.utility.isStoreIsfromRestrictedState() ? this.city : location.naClinicCity, Validators.required),
        naClinicState: this.formBuilder.control(this.utility.isStoreIsfromRestrictedState() ? this.state : location.naClinicState == null ? "" : location.naClinicState, Validators.required),
        naClinicZip: this.formBuilder.control(this.utility.isStoreIsfromRestrictedState() ? this.zipCode : location.naClinicZip, Validators.required),
        clinicDate: this.formBuilder.control('', Validators.required),//this.defaultDate
        naClinicStartTime: this.formBuilder.control('', Validators.required),
        naClinicEndTime: this.formBuilder.control('', Validators.required),
        naContactFirstName: this.formBuilder.control(location.naContactFirstName, Validators.required),
        patientGroupId: this.formBuilder.control(location.patientGroupId, null),
        naContactLastName: this.formBuilder.control(location.naContactLastName, Validators.required),
        confirmedClientName: this.formBuilder.control(location.confirmedClientName, null),
        naClinicContactPhone: this.formBuilder.control(location.naClinicContactPhone, Validators.required),
        naContactEmail: this.formBuilder.control(location.naContactEmail, Validators.required),
        isCancelled: this.formBuilder.control((location.isCancelled != null || location.isCancelled ? 1 : 0), null),
        isConfirmed: this.formBuilder.control((location.isConfirmed != null || location.isConfirmed ? 1 : 0), null),
        isCompleted: this.formBuilder.control((location.isCompleted != null || location.isCompleted ? 1 : 0), null),
        previousContact: this.formBuilder.control('', null),
        previousLocation: this.formBuilder.control('', null),
        isReassign: this.formBuilder.control((location.isReassign == null || location.isReassign == undefined) ? 0 : 1, null),
        coPayFLUGroupId: this.formBuilder.control(location.coPayFLUGroupId, null),
        coPayROUTINEGroupId: this.formBuilder.control(location.coPayROUTINEGroupId, null),
        fluExpiryDate: this.formBuilder.control(location.fluExpiryDate != null ? new Date(location.fluExpiryDate) : new Date(today.getFullYear() + 1, today.getMonth(), today.getDate(), 12, 30, 30), null),
        routineExpiryDate: this.formBuilder.control(location.routineExpiryDate != null ? new Date(location.routineExpiryDate) : new Date(today.getFullYear() + 1, today.getMonth(), today.getDate(), 12, 30, 30), null),
        covidExpiryDate: this.formBuilder.control(location.covidExpiryDate != null ? new Date(location.covidExpiryDate) : new Date(today.getFullYear() + 1, today.getMonth(), today.getDate(), 12, 30, 30), null),
        // visitType: this.formBuilder.control(location.visitType&&location.visitType !==null?location.visitType.toString():"", this.isCovidVaccineAvailable?Validators.required:null),
        coOutreachTypeId: this.formBuilder.control(location.coOutreachTypeId, Validators.required),
        coOutreachTypeDesc: this.formBuilder.control(location.coOutreachTypeDesc, null),
        clinicLatitude: this.formBuilder.control(location.clinicLatitude, null),
        clinicLongitude: this.formBuilder.control(location.clinicLongitude, null),
        clinicRoom: this.formBuilder.control(location.clinicRoom, null),
        reassignType: this.formBuilder.control(location.reassignType, null),
        reassignedTo: this.formBuilder.control(location.reassignedTo, null),
        apptSchedulerType: this.formBuilder.control(location.apptSchedulerType, null),
        isApptSchedulerEnabled: this.formBuilder.control(location.isApptSchedulerEnabled, null),
        vouchersDistributed: this.formBuilder.control(location.vouchersDistributed, null),
        clinicImzQtyList: this.AddAddlClinicImmunizations(),
        isNoClinic: this.formBuilder.control((location.isNoClinic != null || this.is_voucher_neededForEQ) ? 1 : 0, null)
        // isNoClinic: this.formBuilder.control((location.isNoClinic != null && location.isNoClinic == 1) ? ((this.is_voucher_neededForEQ)?1:null) : 0, null),
      }
    ));
  }
  AddAddlClinicImmunizations() {

    // 

    let imz_array: any[] = [];
    this.coClinicDetails.clinicList[0].clinicImzQtyList.forEach(imz => {
      if (this.coClinicDetails.clinicList[0].clinicImzQtyList.length == 1) {
        if (imz.immunizationPk == 31 && imz.paymentTypeId == 14) {
          this.is_voucher_neededForEQ = true;
        }
      }
      else {
        this.is_voucher_neededForEQ = false;
      }
      let imz_app_date: any = '';
      if (imz.clinicalReqApprovedDate !== null && imz.clinicalReqApprovedDate !== undefined) {
        var temp_dt = imz.clinicalReqApprovedDate.toString().substring(0, imz.clinicalReqApprovedDate.toString().indexOf('T'));
        let temp_dt_parts = temp_dt.split('-');
        var dt = new Date(temp_dt_parts[0], temp_dt_parts[1] - 1, temp_dt_parts[2], 18, 30, 30);
        imz.clinicalReqApprovedDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 18, 30, 30);
        imz_app_date = imz.clinicalReqApprovedDate;
      }
      imz_array.push(
        this.formBuilder.group(
          {
            clinicPk: this.formBuilder.control(null),
            immunizationPk: this.formBuilder.control(imz.immunizationPk),
            estimatedQuantity: this.formBuilder.control('', Validators.required),
            immunizationName: this.formBuilder.control(imz.immunizationName),
            paymentTypeName: this.formBuilder.control(imz.paymentTypeName),
            paymentTypeId: this.formBuilder.control(imz.paymentTypeId),
            totalImmAdministered: this.formBuilder.control(imz.totalImmAdministered),
            paperVars:this.formBuilder.control(imz.paperVars),
            vouchersDistributed: this.formBuilder.control(this.clinicType != 2 ? imz.vouchersDistributed : null),
            isClinicalReqd: this.formBuilder.control(imz.isClinicalReqd),
            clinicalReqdApprovedBy: this.formBuilder.control(imz.clinicalReqdApprovedBy),
            clinicalReqApprovedDate: this.formBuilder.control((imz_app_date != '' && imz_app_date != null) ? /*new Date(dateSplitString[2], Number(dateSplitString[0]) - 1, Number(dateSplitString[1]), 18, 30, 30)*/imz_app_date : null)
          }
        )
      )
    });
    return this.formBuilder.array(imz_array);
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  calculateBS4GridSize() {
    if (this.clinicType == 2) {
      return {
        'col-md-5': true
      }
    }
    else {
      return {
        'col-md-8': true
      }
    }
  }
  displayFieldCssForArray(field: string, index: number) {
    let return_value = this.isValidArrayField(field, index);
    return {
      'has-error': return_value,
      'has-feedback': return_value
    };
  }

  displayFieldCssForTable(field: string, index: number) {
    const clinicImmzList = this.clinicDetailsForm.controls['clinicImmunizationList'] as FormArray;
    const transformedList = this._uniquePipeClinicDetails.transform(clinicImmzList.controls);

    let return_value = transformedList[index].controls[field].status == 'INVALID';
    return {
      'has-error': return_value,
      'has-feedback': return_value
    };
  }

  displayFieldCssForLocImm(field: string, i: number, im: number) {
    const control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    const immArray = <FormArray>control.controls[i].get('clinicImzQtyList');
    const estQty = immArray.controls[im].get('estimatedQuantity');
    let return_val = !estQty.valid && estQty.touched;
    return {
      'has-error': return_val,
      'has-feedback': return_val
    };
  }

  isFieldValid(field: string) {
    if (this.clinicDetailsForm.get(field) != null)
      return !this.clinicDetailsForm.get(field).valid && this.clinicDetailsForm.get(field).touched;
  }

  isValidArrayField(fields: string, index: number) {
    let return_value = false;
    Object.keys(this.clinicDetailsForm.controls).forEach(field => {
      const control = this.clinicDetailsForm.get(field);
      if (control instanceof FormArray) {
        if (control.controls[index] !== undefined) {
          if (control.controls[index].get(fields) != null) {
            return_value = !control.controls[index].get(fields).valid && control.controls[index].get(fields).touched;
          }
        }
      }
    });
    return return_value;
  }

  isFormValid(): boolean {
    let frmar: FormArray = this.clinicDetailsForm.get('clinicList') as FormArray;
    for (let index = 0; index < frmar.length; index++) {
      const element = frmar.controls[index] as FormGroup;
      this.utility.validateAllFormFields(element);
    }
    return this.clinicDetailsForm.valid;
  }
  confirmClinic() {

    if (this.coClinicDetails.opportunityInfo.childClinicAgreementId != null && this.coClinicDetails.opportunityInfo.childClinicAgreementId > 0 && !this.coClinicDetails.opportunityInfo.childClinicAgreementApproved) {
      this.clinicNotConfirmedMsgDlg = true;
      this.showClinicNotConfirmedDialog('Amend Agreement', ErrorMessages['DisbaleButtonsAmend']);
      return false;
    }

    let control_imz = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
    let index1;
    for (index1 = 0; index1 < control_imz.length; index1++) {
      control_imz.controls[index1].get('estimatedQuantity').setValidators(Validators.required);
      control_imz.controls[index1].get('estimatedQuantity').updateValueAndValidity();
      control_imz.controls[index1].get('totalImmAdministered').setValidators(null);
      control_imz.controls[index1].get('totalImmAdministered').updateValueAndValidity();

      if (this.reassignForm.value.assignType !== null && this.reassignForm.value.assignType !== "") {
        control_imz.controls[index1].get('isClinicalReqd').setValidators(null);
        control_imz.controls[index1].get('isClinicalReqd').updateValueAndValidity();
      } else {
        control_imz.controls[index1].get('isClinicalReqd').setValidators((ImmunizationsClinicalRequirements["immunizations"].indexOf(control_imz.controls[index1].get('immunizationName').value) > -1 ? Validators.requiredTrue : Validators.nullValidator));
        control_imz.controls[index1].get('isClinicalReqd').updateValueAndValidity();
      }

      this.utility.validateAllFormFields(<FormGroup>control_imz.controls[index1]);
    }
    let control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    let index;
    for (index = 0; index < control.length; index++) {
      control.controls[0].get('confirmedClientName').setValidators([1, 3, 5].indexOf(this.clinicType) > -1 ? [Validators.required] : null);
      control.controls[0].get('confirmedClientName').updateValueAndValidity();
      this.utility.validateAllFormFields(<FormGroup>control.controls[index]);
    }
    this.isNoclinicValidations();
    this.clinicDetailsForm.controls["feedbackNotes"].setValidators(null);
    this.clinicDetailsForm.controls["feedbackNotes"].updateValueAndValidity();
    // this.clinicDetailsForm.controls["pharmacistName"].setValidators(null);
    // this.clinicDetailsForm.controls["pharmacistName"].updateValueAndValidity();
    // this.clinicDetailsForm.controls["pharmacistPhone"].setValidators(null);
    // this.clinicDetailsForm.controls["pharmacistPhone"].updateValueAndValidity();
    // this.clinicDetailsForm.controls["totalHours"].setValidators(null);
    // this.clinicDetailsForm.controls["totalHours"].updateValueAndValidity();

    if (this.clinicDetailsForm.valid) { // && control.controls[0].get('confirmedClientName').value?.trim().length > 0
      this.is_confirmed = true;

      if (((this.clinicDetailsForm.value.recipientId != null ||
        this.clinicDetailsForm.value.recipientId != undefined) &&
        this.clinicDetailsForm.value.recipientId.trim().length <= 0) ||
        ((this.clinicDetailsForm.value.naClinicPlanId != null ||
          this.clinicDetailsForm.value.naClinicPlanId != undefined) &&
          this.clinicDetailsForm.value.naClinicPlanId.trim().length <= 0)) {
        this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
        return false;
      }
      if (this.clinicDetailsForm.value.email != null || this.clinicDetailsForm.value.email != undefined) {
        if (this.clinicDetailsForm.value.email.trim().length > 0) {
          if (!this.utility.validateSingleEmail(this.clinicDetailsForm.value.email)) {
            this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidEmail']);
            return false;
          }
        }
      }
      if (this.clinicDetailsForm.value.phone != null || this.clinicDetailsForm.value.phone != undefined) {
        if (this.clinicDetailsForm.value.phone.trim().length > 0) {
          if (!this.utility.validatePhoneNo(this.clinicDetailsForm.value.phone.trim())) {
            this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidPhoneFormat']);
            return false;
          }
        }
      }
      if (this.clinicDetailsForm.value.pharmacistPhone != null ||
        this.clinicDetailsForm.value.pharmacistPhone != undefined) {
        if (this.clinicDetailsForm.value.pharmacistPhone.trim().length > 0) {
          if (!this.utility.validatePhoneNo(this.clinicDetailsForm.value.pharmacistPhone.trim())) {
            this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidPhoneFormat']);
            return false;
          }
        }
      }
      if (!this.isAssignedStoreValid()) {
        if (this.coClinicDetails.clinicList[0].isConfirmed == true || this.coClinicDetails.clinicList[0].isConfirmed == 1 ||
          this.coClinicDetails.clinicList[0].isCompleted == true || this.coClinicDetails.clinicList[0].isCompleted == 1 ||
          this.coClinicDetails.clinicList[0].isCancelled == true || this.coClinicDetails.clinicList[0].isCancelled == 1) {
          this.showDialog(ErrorMessages['reAssignStoreInvalidActionHdr'], ErrorMessages['reAssignStoreInvalidActionMsg']);
          return false;
        }
      }
      let controlclinicList = <FormArray>this.clinicDetailsForm.controls['clinicList'];
      let index_clinic;
      for (index_clinic = 0; index_clinic < controlclinicList.length; index_clinic++) {

        let address1: string = controlclinicList.controls[index_clinic].get('naClinicAddress1').value;
        let address2: string = controlclinicList.controls[index_clinic].get('naClinicAddress2').value;

        let address = (address1 + address2).trim().replace(/[\[\]().-]+/g, '');
        address = address.replace(/\s/g, '');
        if (address.toLowerCase().includes("pobox")) {
          this.dialogSummary = "Alert";
          this.dialogMsg = "Addresses containing a P.O. Box are not allowed. Please enter a valid location address.";

          this.showDialogV2(this.dialogSummary, this.dialogMsg);
          return;
        }

      }
      this.action_status = "Confirmed";
      if (!this.validateReduceEstQnt()) {
        return;
      }
      let clinicItem = <FormArray>(this.clinicDetailsForm.controls['clinicList']);
      //clinicItem.controls[0].get('apptSchedulerType').value
      if (!this.alertUserAboutScheduler()) {
        return;
      }
      if (!this.isClinicDateReminderBefore2WksReq("Confirmed")) {
        this.continueWithSaving("Confirmed");
      }
      else {
        let sumMsg = ErrorMessages['impRmndrFor2Wks'];
        let errMsg = ErrorMessages['new_clinicDateReminderBefore2WeeksEN'];
        var userProfile = SessionDetails.GetUserProfile();
        //if (ClinicDetailsUtilities.shallWeShow2WkConfirmDialog(userProfile)) {
        this.OverrideOrOkTitle = "OK";
        this.showCancelBtn = false;
        this.confirm(sumMsg, errMsg, "Confirmed");
        //return;
        //}
        // else {
        //   errMsg = ErrorMessages['clinicDateReminderBefore2WeeksEN'];
        //   this.showDialog(sumMsg, errMsg);
        //   return
        // }
      }
    }
    else {
      control = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
      let imz_names: string = this.validateClinicalRequirements(control);
      if (imz_names.length > 0) {
        this.showDialogV2(ErrorMessages['serverError'], String.Format(ErrorMessages['clinicalRequirementsError'], imz_names));
        return;
      }
      if (this.clinicDetailsForm.value.email != null && this.clinicDetailsForm.value.email != undefined &&
        this.clinicDetailsForm.value.email.trim().length > 0
        && !this.utility.validateSingleEmail(this.clinicDetailsForm.value.email)) {
        this.utility.validateAllFormFields(this.clinicDetailsForm);
        this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidEmail']);
        return;
      }
      this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
      this.utility.validateAllFormFields(this.clinicDetailsForm);
      return;
    }
  }

  completeClinic() {

    if (this.coClinicDetails.opportunityInfo.childClinicAgreementId != null && this.coClinicDetails.opportunityInfo.childClinicAgreementId > 0 && !this.coClinicDetails.opportunityInfo.childClinicAgreementApproved) {
      this.clinicNotConfirmedMsgDlg = true;
      this.showClinicNotConfirmedDialog('Amend Agreement', ErrorMessages['DisbaleButtonsAmend']);
      return false;
    }

    if (this.coClinicDetails.clinicList[0].isConfirmed == false) {
      this.clinicNotConfirmedMsgDlg = true;
      this.showClinicNotConfirmedDialog('Alert', ErrorMessages['clinicNotConfirmedMsg']);
      return false;
    }
    else {
      let control = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
      let index;
      let totalImmAdministeredIsOK = true;

      for (index = 0; index < control.length; index++) {
        control.controls[index].get('totalImmAdministered').setValidators([Validators.required]);
        control.controls[index].get('estimatedQuantity').setValidators(Validators.required);
        control.controls[index].get('estimatedQuantity').updateValueAndValidity();
        control.controls[index].get('totalImmAdministered').updateValueAndValidity();
        this.utility.validateAllFormFields(<FormGroup>control.controls[index]);

        if (totalImmAdministeredIsOK && control.controls[index].get('totalImmAdministered').value != null)
          if (Number(control.controls[index].get('totalImmAdministered').value) < 0 && control.controls[index].get('totalImmAdministered').value != "") {
            totalImmAdministeredIsOK = false;
          }
        if (this.reassignForm.value.assignType !== null && this.reassignForm.value.assignType !== "") {
          control.controls[index].get('isClinicalReqd').setValidators(null);
          control.controls[index].get('isClinicalReqd').updateValueAndValidity();
        }
        else {
          control.controls[index].get('isClinicalReqd').setValidators((ImmunizationsClinicalRequirements["immunizations"].indexOf(control.controls[index].get('immunizationName').value) > -1 ? Validators.requiredTrue : Validators.nullValidator));
          control.controls[index].get('isClinicalReqd').updateValueAndValidity();
        }

      }
      control = <FormArray>this.clinicDetailsForm.controls['clinicList'];

      for (index = 0; index < control.length; index++) {
        control.controls[0].get('confirmedClientName').setValidators([1, 3, 5].indexOf(this.clinicType) > -1 ? [Validators.required] : null);
        control.controls[0].get('confirmedClientName').updateValueAndValidity();
        this.utility.validateAllFormFields(<FormGroup>control.controls[index]);
      }

      this.isNoclinicValidations();
      this.clinicDetailsForm.controls["feedbackNotes"].setValidators(null);
      this.clinicDetailsForm.controls["feedbackNotes"].updateValueAndValidity();
      // this.clinicDetailsForm.controls["pharmacistName"].setValidators(Validators.required);
      // this.clinicDetailsForm.controls["pharmacistName"].updateValueAndValidity();
      // this.clinicDetailsForm.controls["pharmacistPhone"].setValidators(Validators.required);
      // this.clinicDetailsForm.controls["pharmacistPhone"].updateValueAndValidity();
      // this.clinicDetailsForm.controls["totalHours"].setValidators(Validators.required);
      // this.clinicDetailsForm.controls["totalHours"].updateValueAndValidity();
      // if (this.clinicDetailsForm.value.recipientId.trim().length <= 0 || this.clinicDetailsForm.value.naClinicPlanId.trim().length <= 0) {
      //   this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
      //   return false;
      // }
      if (this.clinicDetailsForm.valid) {
        if (!this.usPhoneNoRegEx.test(this.clinicDetailsForm.value.phone)) {
          this.showDialogV2(ErrorMessages['OpportunityInformationHeading'], ErrorMessages['OpportunityInformationMessage']);
          return false;
        }
        if (!totalImmAdministeredIsOK) {
          this.showDialogV2(ErrorMessages['clinicDetails'], ErrorMessages['totalAdministeredIsNOK']);
          return false;
        }
        for (let i = 0; i < this.clinicDetailsForm.value.clinicList.length; i++) {
          if (!this.usPhoneNoRegEx.test(this.clinicDetailsForm.value.clinicList[i].naClinicContactPhone)) {
            this.showDialogV2(ErrorMessages['ClinicInformationHeading'], ErrorMessages['ClinicInformationMessage']);
            return false;
          }
        }

        if (this.clinicDetailsForm.value.pharmacistPhone) {
          if (((this.clinicDetailsForm.value.pharmacistPhone).indexOf("X") > -1)) {
            this.clinicDetailsForm.controls.pharmacistPhone.setValue(null);
            this.clinicDetailsForm.controls.pharmacistPhone.updateValueAndValidity();
            this.utility.validateAllFormFields(this.clinicDetailsForm);
            this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
            return false;
          }

          if (!this.usPhoneNoRegEx.test(this.clinicDetailsForm.value.pharmacistPhone)) {
            this.showDialogV2(ErrorMessages['PharmacistPostClinicInformationHeading'], ErrorMessages['PharmacistPostClinicInformationMessage']);
            return false;
          }
        }
        if (((this.clinicDetailsForm.value.recipientId != null ||
          this.clinicDetailsForm.value.recipientId != undefined) &&
          this.clinicDetailsForm.value.recipientId.trim().length <= 0) ||
          ((this.clinicDetailsForm.value.naClinicPlanId != null ||
            this.clinicDetailsForm.value.naClinicPlanId != undefined) &&
            this.clinicDetailsForm.value.naClinicPlanId.trim().length <= 0)) {
          this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
          return false;
        }
        if (this.clinicDetailsForm.value.email != null || this.clinicDetailsForm.value.email != undefined) {
          if (this.clinicDetailsForm.value.email.trim().length > 0) {
            if (!this.utility.validateSingleEmail(this.clinicDetailsForm.value.email)) {
              this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidEmail']);
              return false;
            }
          }
        }
        if (this.clinicDetailsForm.value.phone != null || this.clinicDetailsForm.value.phone != undefined) {

          if (this.clinicDetailsForm.value.phone.trim().length > 0) {
            if (!this.utility.validatePhoneNo(this.clinicDetailsForm.value.phone.trim())) {
              this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidPhoneFormat']);
              return false;
            }
          }
        }
        if (this.clinicDetailsForm.value.pharmacistPhone != null || this.clinicDetailsForm.value.pharmacistPhone != undefined) {
          if (this.clinicDetailsForm.value.pharmacistPhone.trim().length > 0) {
            if (!this.utility.validatePhoneNo(this.clinicDetailsForm.value.pharmacistPhone.trim())) {
              this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidPhoneFormat']);
              return false;
            }
          }
        }
        if (!this.isAssignedStoreValid()) {
          if (this.coClinicDetails.clinicList[0].isConfirmed || this.coClinicDetails.clinicList[0].isConfirmed == 1 ||
            this.coClinicDetails.clinicList[0].isCompleted || this.coClinicDetails.clinicList[0].isCompleted == 1 ||
            this.coClinicDetails.clinicList[0].isCancelled || this.coClinicDetails.clinicList[0].isCancelled == 1) {
            this.showDialog(ErrorMessages['reAssignStoreInvalidActionHdr'], ErrorMessages['reAssignStoreInvalidActionMsg']);
            return false;
          }
        }
        let controlclinicList = <FormArray>this.clinicDetailsForm.controls['clinicList'];
        let index_clinic;
        for (index_clinic = 0; index_clinic < controlclinicList.length; index_clinic++) {

          let address1: string = controlclinicList.controls[index_clinic].get('naClinicAddress1').value;
          let address2: string = controlclinicList.controls[index_clinic].get('naClinicAddress2').value;

          let address = (address1 + address2).trim().replace(/[\[\]().-]+/g, '');
          address = address.replace(/\s/g, '');
          if (address.toLowerCase().includes("pobox")) {
            this.dialogSummary = "Alert";
            this.dialogMsg = "Addresses containing a P.O. Box are not allowed. Please enter a valid location address.";

            this.showDialogV2(this.dialogSummary, this.dialogMsg);
            return;
          }

        }
        this.action_status = "Completed";
        if (!this.validateReduceEstQnt()) {
          return;
        }
        //let clinicItem = <FormArray>(this.clinicDetailsForm.controls['clinicList']);
        if (!this.alertUserAboutScheduler()) {
          return;
        }
        this.is_completed = true;
        let ret: Boolean = false;
        if (this.clinicDetailsForm.value.clinicList.length > 1) {
          ret = this.isClinicDateReminderBefore2WksReq("Completed");
        }
        if (!ret || (ret && this.clinicDetailsForm.value.clinicList.length <= 1)) {
          this.continueWithSaving("Completed");
        }
        else {
          let sumMsg = ErrorMessages['impRmndrFor2Wks'];
          let errMsg = ErrorMessages['new_clinicDateReminderBefore2WeeksEN'];
          //      if (ClinicDetailsUtilities.shallWeShow2WkConfirmDialog(SessionDetails.GetUserProfile())) {
          this.OverrideOrOkTitle = "OK";
          this.showCancelBtn = false;
          this.confirm(sumMsg, errMsg, "Completed");
          //            return
          //        }
          // else {
          //   errMsg = ErrorMessages['clinicDateReminderBefore2WeeksEN'];
          //   this.showDialog(sumMsg, errMsg);
          //   return
          // }

        }
      }
      else {
        control = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
        let imz_names: string = this.validateClinicalRequirements(control);
        if (imz_names.length > 0) {
          this.showDialogV2(ErrorMessages['serverError'], String.Format(ErrorMessages['clinicalRequirementsError'], imz_names));
          return;
        }
        if (this.clinicDetailsForm.value.email != null && this.clinicDetailsForm.value.email != undefined &&
          this.clinicDetailsForm.value.email.trim().length > 0
          && !this.utility.validateSingleEmail(this.clinicDetailsForm.value.email)) {
          this.utility.validateAllFormFields(this.clinicDetailsForm);
          this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidEmail']);
          return;
        }
        this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
        this.utility.validateAllFormFields(this.clinicDetailsForm);

        return;
      }
    }
  }

  saveChanges() {
    this.adminConfirmedCancellation = false;
    if (this.coClinicDetails.opportunityInfo.childClinicAgreementId != null && this.coClinicDetails.opportunityInfo.childClinicAgreementId > 0 &&
      !this.coClinicDetails.opportunityInfo.childClinicAgreementApproved) {
      this.clinicNotConfirmedMsgDlg = true;
      this.showClinicNotConfirmedDialog('Amend Agreement', ErrorMessages['DisbaleButtonsAmend']);
      return false;
    }
    this.action_status = null;
    let control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    let index;
    for (index = 0; index < control.length; index++) {
      if (this.coClinicDetails.clinicList[0].isCompleted && index == 0) {
        control.controls[index].get('confirmedClientName').setValidators([1, 3, 5].indexOf(this.clinicType) > -1 ? [Validators.required] : null);
        control.controls[index].get('confirmedClientName').updateValueAndValidity();
      } else {
        control.controls[index].get('confirmedClientName').setValidators(null);
        control.controls[index].get('confirmedClientName').updateValueAndValidity();
      }
      this.utility.validateAllFormFields(<FormGroup>control.controls[index]);
    }

    if(!this.covidVaccineWarningOkClicked){
      const locations = this.clinicDetailsForm.controls["clinicList"] as FormArray;
      for (const location of locations.controls) {
        const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
        let clinicDate = location.get('clinicDate').value;
        clinicDate = moment(clinicDate);
        let startDate  = moment(environment.covidVaccineNotAviableDates.startDate, "DD/MM/YYYY");
        let endDate = moment(environment.covidVaccineNotAviableDates.endDate, "DD/MM/YYYY");
        for (const clinicImzQty of clinicImzQtyList.controls) {
          if(clinicImzQty.get('immunizationName').value.toLocaleLowerCase()?.includes('covid') && (clinicDate.isBetween(startDate, endDate) || clinicDate.isSame(startDate) || clinicDate.isSame(endDate))){
            this.covidVaccineSelected = true;
            this.dialogMsg = ErrorMessages["sesionalCovid19Info"];
            this.dialogSummary = "Alert";
            clinicImzQty.get('estimatedQuantity').setErrors({'incorrect': true});
            clinicImzQty.get('estimatedQuantity').markAsDirty();
            clinicImzQty.get('estimatedQuantity').markAsTouched();
            clinicImzQty.updateValueAndValidity();
          } else {
            clinicImzQty.get('estimatedQuantity').setErrors(null);
            clinicImzQty.get('estimatedQuantity').markAsDirty();
            clinicImzQty.get('estimatedQuantity').markAsTouched();
            clinicImzQty.updateValueAndValidity();
          }
        } 
      }
      if(this.covidVaccineSelected) {
        locations.updateValueAndValidity();
        return false;
      } else {
        locations.updateValueAndValidity();
      }
    } else {
      this.covidVaccineWarningOkClicked = false;
    }
    
    control = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
    let totalImmAdministeredIsOK = true;
    if (control != null || control != undefined) {
      for (index = 0; index < control.length; index++) {
        // if (this.coClinicDetails.clinicList[0].isCompleted) {
        //   control.controls[index].get('totalImmAdministered').setValidators([Validators.required]);
        //   control.controls[index].get('totalImmAdministered').updateValueAndValidity();
        // }
        // else {
        //   control.controls[index].get('totalImmAdministered').setValidators(null);
        //   control.controls[index].get('totalImmAdministered').updateValueAndValidity();
        // }
        control.controls[index].get('estimatedQuantity').setValidators(Validators.required);
        control.controls[index].get('estimatedQuantity').updateValueAndValidity();

        if ([1, 3, 5, 6, 13].indexOf(this.clinicType) > -1) {
          if (this.reassignForm.value.assignType != null && this.reassignForm.value.assignType != "") {
            control.controls[index].get('isClinicalReqd').setValidators(null);
          } else {
            control.controls[index].get('isClinicalReqd').setValidators((ImmunizationsClinicalRequirements["immunizations"].indexOf(control.controls[index].get('immunizationName').value) > -1 ? Validators.requiredTrue : Validators.nullValidator));
          }
          control.controls[index].get('isClinicalReqd').updateValueAndValidity();
        }

        this.utility.validateAllFormFields(<FormGroup>control.controls[index]);
        if (totalImmAdministeredIsOK && control.controls[index].get('totalImmAdministered').value != null) {
          if (Number(control.controls[index].get('totalImmAdministered').value) < 0 && control.controls[index].get('totalImmAdministered').value != "") {
            totalImmAdministeredIsOK = false;
          }
        }

        if (
          (this.coClinicDetails.clinicList[0].isCompleted)
            && (
              control.controls[index].get('totalImmAdministered').value == null 
              || control.controls[index].get('totalImmAdministered').value?.toString().length == 0
            )
          ){
          let formGroup: FormGroup = control.controls[index] as FormGroup;
          formGroup.controls['totalImmAdministered'].setValidators([Validators.required]);
          formGroup.controls['totalImmAdministered'].setErrors({ 'incorrect': true });
          formGroup.controls['totalImmAdministered'].markAsDirty();
          formGroup.controls['totalImmAdministered'].markAsTouched();
        }
      }
    }

    this.isNoclinicValidations();

    this.clinicDetailsForm.controls["feedbackNotes"].setValidators(null);
    this.clinicDetailsForm.controls["feedbackNotes"].updateValueAndValidity();



    if (this.clinicDetailsForm.valid) {
      if (!this.validateReduceEstQnt()) {
        return;
      }
      //let clinicItem = <FormArray>(this.clinicDetailsForm.controls['clinicList']);
      if (!this.alertUserAboutScheduler()) {
        return;
      }
      // if (this.coClinicDetails.clinicList[0].isCompleted && this.clinicDetailsForm.value.pharmacistName != null &&
      //   this.clinicDetailsForm.value.pharmacistName != undefined &&
      //   this.clinicDetailsForm.value.pharmacistName.trim().length <= 0) {
      //   this.showDialogV2(ErrorMessages['PharmacistPostClinicInfoHeading'], ErrorMessages['PharmacistPostClinicErrorMsg']);
      //   return false;
      // }
      if (((this.clinicDetailsForm.value.phone).indexOf("X") > -1)) {
        this.clinicDetailsForm.controls.phone.setValue(null);
        this.clinicDetailsForm.controls.phone.updateValueAndValidity();
        this.utility.validateAllFormFields(this.clinicDetailsForm);
        this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
        return false;
      }
      if ([1, 2, 6].indexOf(this.clinicType) > -1) {
        if (!this.usPhoneNoRegEx.test(this.clinicDetailsForm.value.phone)) {
          this.showDialogV2(ErrorMessages['OpportunityInformationHeading'], ErrorMessages['OpportunityInformationMessage']);
          return false;
        }
      }
      for (let i = 0; i < this.clinicDetailsForm.value.clinicList.length; i++) {
        if (!this.usPhoneNoRegEx.test(this.clinicDetailsForm.value.clinicList[i].naClinicContactPhone)) {
          if (((this.clinicDetailsForm.value.clinicList[i].naClinicContactPhone).indexOf("X") > -1)) {
            let icontrol = <FormArray>this.clinicDetailsForm.controls['clinicList'];
            icontrol.controls[i].get('naClinicContactPhone').setValue(null);
            icontrol.controls[i].get('naClinicContactPhone').setValidators([Validators.required]);
            icontrol.controls[i].get('naClinicContactPhone').updateValueAndValidity();
            this.utility.validateAllFormFields(<FormGroup>icontrol.controls[i]);
            this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
            return false;
          }
          else {
            if ([1, 2, 6].indexOf(this.clinicType) > -1) {
              this.showDialogV2(ErrorMessages['ClinicInformationHeading'], ErrorMessages['ClinicInformationMessage']);
              return false;
            }
          }
        }
      }
      if (!totalImmAdministeredIsOK) {
        if (this.clinicType != 13) {
          this.showDialogV2(ErrorMessages['clinicDetails'], ErrorMessages['totalAdministeredIsNOK']);
          return false;
        }

      }
      if (this.clinicDetailsForm.value.pharmacistPhone) {
        if (!this.usPhoneNoRegEx.test(this.clinicDetailsForm.value.pharmacistPhone)) {
          this.clinicDetailsForm.controls.pharmacistPhone.setValue(null);
          this.clinicDetailsForm.controls.pharmacistPhone.updateValueAndValidity();
          this.showDialogV2(ErrorMessages['PharmacistPostClinicInformationHeading'], ErrorMessages['PharmacistPostClinicInformationMessage']);
          return false;
        }
      }
      for (let i = 0; i < this.clinicDetailsForm.value.clinicList.length; i++) {
        if (this.clinicDetailsForm.value.clinicList[i].assignedStore == 0) {//this.clinicDetailsForm.value.clinicList[i].assignedStore ||
          this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['assignedStore']);
          return false;
        }
      }
      if (((this.clinicDetailsForm.value.recipientId != null ||
        this.clinicDetailsForm.value.recipientId != undefined) &&
        this.clinicDetailsForm.value.recipientId.trim().length <= 0) ||
        ((this.clinicDetailsForm.value.naClinicPlanId != null ||
          this.clinicDetailsForm.value.naClinicPlanId != undefined) &&
          this.clinicDetailsForm.value.naClinicPlanId.trim().length <= 0)) {
        this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
        return false;
      }
      // let errCOVIDVisits:string[] = this.getCOVIDVisitTypesValidity();
      // if(errCOVIDVisits.length > 0){
      //     this.showDialogV2(ErrorMessages['resolve'], errCOVIDVisits.join('<br/><br/>'));
      //     return false;
      // }
      let controlclinicList = <FormArray>this.clinicDetailsForm.controls['clinicList'];
      let index_clinic;
      for (index_clinic = 0; index_clinic < controlclinicList.length; index_clinic++) {

        let address1: string = controlclinicList.controls[index_clinic].get('naClinicAddress1').value;
        let address2: string = controlclinicList.controls[index_clinic].get('naClinicAddress2').value;

        let address = (address1 + address2).trim().replace(/[\[\]().-]+/g, '');
        address = address.replace(/\s/g, '');
        if (address.toLowerCase().includes("pobox")) {
          this.dialogSummary = "Alert";
          this.dialogMsg = "Addresses containing a P.O. Box are not allowed. Please enter a valid location address.";

          this.showDialogV2(this.dialogSummary, this.dialogMsg);
          return;
        }

      }

      if (Number(this.coClinicDetails.clinicList[0].noSlotsOfBlocked) > 0 && !this.overrideBlockedSlotAlert
        && (this.coClinicDetails.clinicList[0].naClinicStartTime != this.clinicDetailsForm.value.clinicList[0].naClinicStartTime
          || this.coClinicDetails.clinicList[0].naClinicEndTime != this.clinicDetailsForm.value.clinicList[0].naClinicEndTime)) {
        this.dialogSummary = "Alert";
        this.dialogMsg = ErrorMessages['slotsBlockedWarning'];
        this.showBlockedSlotAlert = true;
        this.clinic_details_update.clinicContactStatus = "Submit"
        return;
      }
      if (!this.isClinicDateReminderBefore2WksReq("Submit")) {
        this.clinic_details_update = this.saveData(null);
        this.clinic_details_update.clinicContactStatus = "Submit";
        if (this.dateTimeConflict()) {
          // if (this.MinimumShotValidation("Submit", false)) {
          if (this.isAssignedStoreValid()) {
            this._alertService.clear();
            this._alertService.info(ErrorMessages['clinicDateReminderAfter2WeeksEN'], false);
            this.scrolling();
            this.spinner.show();
            this.rescheduledErrorCode = 0;
            this.clinic_details_update.isSendLocationChangeEmailToAppts = false;
            // this.clinic_details_update.opportunityInfo.b2BTeamEmail = '';
            // this.clinic_details_update.opportunityInfo.clientReportingEmail = '';
            this.clinic_details_update.isSendRescheduleEmailToAppts = false;
            this.coClinicdetailsService.upDateClinicDetails(this.coClinicDetails.clinicList[0].clinicPk,
              this.clinic_details_update).subscribe((res: any) => {
                this.spinner.hide();
                //if (res.status == "200") {
                if (res.errorS != null) {
                  if (res.errorS.errorCode != 0) {
                    let errorCode = res.errorS.errorCode;
                    let errorMessage = res.errorS.errorMessage;
                    switch (errorCode) {
                      case -4:
                        let sumMsg = ErrorMessages['eventDateTimeConflict'];
                        let finalErrMsg = String.Format("{0}<br/><br/>{1}", res.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']);
                        this.OverrideOrOkTitle = "Override";
                        this.showCancelBtn = true;
                        this.confirm(sumMsg, finalErrMsg, null, true);
                        break;
                      case -6:
                        this.rescheduledErrorCode = errorCode;
                        this.showClinicLocationDetailsSaveChangesError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsLocationChangedWarning']);
                        break;
                      case -5:
                        //      if (this.isAdmin()) {
                        this.rescheduledErrorCode = errorCode;
                        this.showUpdateClinicDetailsRspError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsChangedWarning'].replace('{1}', errorMessage.replace(',', ', ')));
                        // }
                        // else {
                        //   this.showDialogV2(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsChangedErrMsg'].replace('{1}', errorMessage.replace(',', ', ')));
                        // }
                        break;
                      case -7:
                        this.showDialog(ErrorMessages['clinicDetails'], errorMessage);
                        break;
                      default:
                        this.showDialogError(ErrorMessages['clinicDetails'], res.json().errorS.errorMessage);
                        break;
                    }
                    return;
                  }
                  else {
                    this.rescheduledErrorCode = 0;
                    let msg = ErrorMessages['clinicSave'];
                    if (this.clinic_details_update.clinicList[0].apptSchedulerType === 1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
                      msg = ErrorMessages['clinicSavedWithSchdlr']
                    }
                    else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
                      msg = ErrorMessages['clinicSavedWithCPSchdlr']
                    }
                    this.showDialog(ErrorMessages['clinicDetails'], msg);
                  }
                }
                else {
                  this.rescheduledErrorCode = 0;
                  let msg = ErrorMessages['clinicSave'];
                  if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
                    msg = ErrorMessages['clinicSavedWithSchdlr'];
                  }
                  else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
                    msg = ErrorMessages['clinicSavedWithCPSchdlr'];
                  }
                  this.showDialog(ErrorMessages['clinicDetails'], msg);
                }
                //}
              },
                err => {
                  this.spinner.hide();
                  this.showDialog(ErrorMessages['serverError'], err.message);
                });
          } else {
            this.showReAssignStoreDialog(null);
          }
          //}
        }
      }
      else {

        var userProfile = SessionDetails.GetUserProfile();
        if (userProfile.userRole.toLowerCase() == "admin" && !this.isFluDosesValidUnder14DaysTimeFrame()) {
          this.showFluDosesValidityDialog(ErrorMessages['resolve'],
            ErrorMessages['moreDosesInDetailsPageError'], null);
          return;
        }
        else if (!this.isFluDosesValidUnder14DaysTimeFrame()) {
          this.showDialog(ErrorMessages['resolve'], ErrorMessages['moreDosesInDetailsPageError']);
          return;
        }
        let sumMsg = ErrorMessages['impRmndrFor2Wks'];
        let errMsg = ErrorMessages['new_clinicDateReminderBefore2WeeksEN'];
        //        if (ClinicDetailsUtilities.shallWeShow2WkConfirmDialog(userProfile)) {
        this.OverrideOrOkTitle = "OK";
        this.showCancelBtn = false;
        this.confirm(sumMsg, errMsg, null);
        //        return;
        //    }
        // else {
        //   errMsg = ErrorMessages['clinicDateReminderBefore2WeeksEN'];
        //   this.showDialog(sumMsg, errMsg);
        //   return;
        // }
      }
    }
    else {
      control = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
      let imz_names: string = this.validateClinicalRequirements(control);
      if (imz_names.length > 0) {
        this.showDialogV2(ErrorMessages['serverError'], String.Format(ErrorMessages['clinicalRequirementsError'], imz_names));
        return;
      }
      if (this.clinicDetailsForm.value.email != null && this.clinicDetailsForm.value.email != undefined &&
        this.clinicDetailsForm.value.email.trim().length > 0
        && !this.utility.validateSingleEmail(this.clinicDetailsForm.value.email.trim())) {
        this.utility.validateAllFormFields(this.clinicDetailsForm);
        this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidEmail']);
        return;
      }
      for (let i = 0; i < this.clinicDetailsForm.value.clinicList.length; i++) {
        if (((this.clinicDetailsForm.value.clinicList[i].naClinicContactPhone) && (this.clinicDetailsForm.value.clinicList[i].naClinicContactPhone).indexOf("X") > -1) || (this.clinicDetailsForm.value.clinicList[i].naClinicContactPhone) == '') {
          let xcontrol = <FormArray>this.clinicDetailsForm.controls['clinicList'];
          xcontrol.controls[i].get('naClinicContactPhone').setValue(null);
          xcontrol.controls[i].get('naClinicContactPhone').setValidators([Validators.required]);
          xcontrol.controls[i].get('naClinicContactPhone').updateValueAndValidity();
          this.utility.validateAllFormFields(<FormGroup>xcontrol.controls[i]);
          this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
          return false;
        }
      }
      this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
      this.utility.validateAllFormFields(this.clinicDetailsForm);
      return;
    }
  }
  getCOVIDVisitTypesValidity() {
    var re = /COVID19/gi;
    var dose1Re = /First Dose/gi;
    var dose2Re = /Second Dose/gi;
    let isValid = false;
    let errMsgs: string[] = [];
    for (let count = 0; count < this.clinicDetailsForm.value.clinicList.length; count++) {
      // let visitType:number = this.clinicDetailsForm.value.clinicList[count].visitType;
      let totalCovidImmCount: number = 0;
      let covidImmList = null;
      // bug 834 fix

    }
    return errMsgs;
  }

  validateClinicalRequirements(control_list: any) {
    let imz_names: string = "";
    let cnt = 0;
    if (control_list !== null || control_list !== undefined) {
      for (let index = 0; index < control_list.length; index++) {
        if ((ImmunizationsClinicalRequirements["immunizations"].indexOf(control_list.controls[index].get('immunizationName').value) > -1 && control_list.controls[index].get('isClinicalReqd').status === 'INVALID')) {
          if (imz_names.indexOf(control_list.controls[index].get('immunizationName').value) == -1) {
            imz_names = imz_names + ((cnt === this.clinicalRequirements.length - 1) ? ((this.clinicalRequirements.length === 1) ? '' : ' and ') : ((imz_names.length > 0) ? ', ' : '')) + control_list.controls[index].get('immunizationName').value;
            cnt++;
          }
        }
      }
    }
    return imz_names;
  }

  isAssignedStoreValid() {
    let is_valid_assigned_store: boolean = true;
    let is_reassigning: boolean = false;
    let has_clinical_req_approved: boolean = false;
    has_clinical_req_approved = this.hasClinicalRequirements();
    if ((this.clinicDetailsForm.value.storeID !== null && this.clinicDetailsForm.value.storeID !== undefined) &&
      (this.reassignForm.value.assignStoreID !== null && this.reassignForm.value.assignStoreID !== undefined) &&
      ((this.reassignForm.value.assignStoreID.storeId !== null ||
        this.reassignForm.value.assignStoreID.storeId !== undefined))) {
      if (Number(this.coClinicDetails.opportunityInfo.storeId) !== Number(this.reassignForm.value.assignStoreID.storeId) &&
        (this.coClinicDetails.clinicList[0].isConfirmed === true || Number(this.coClinicDetails.clinicList[0].isConfirmed) === 1 ||
          (this.coClinicDetails.clinicList[0].isCompleted === true || Number(this.coClinicDetails.clinicList[0].isCompleted) === 1) ||
          (this.coClinicDetails.clinicList[0].isCancelled === true || Number(this.coClinicDetails.clinicList[0].isCancelled) === 1))) {
        is_valid_assigned_store = false;
        if (Number(this.coClinicDetails.opportunityInfo.storeId) !== Number(this.reassignForm.value.assignStoreID.storeId) || this.reassignForm.value.assignType.toLowerCase() === 'geography') {
          is_reassigning = true;
        }
      }
    }

    if ((!is_reassigning || (this.coClinicDetails.clinicList && (this.coClinicDetails.clinicList[0].isCancelled !== true && Number(this.coClinicDetails.clinicList[0].isCancelled) !== 1) &&
      (this.coClinicDetails.clinicList[0].isCompleted !== true && Number(this.coClinicDetails.clinicList[0].isCompleted) !== 1))) &&
      ((!is_valid_assigned_store && has_clinical_req_approved) || (is_valid_assigned_store && has_clinical_req_approved)
        || (is_valid_assigned_store && !has_clinical_req_approved))) {
      if (this.clinic_details_update.clinicList && this.clinic_details_update.clinicList[0].isReassign === 1) {
        this.clinic_details_update.clinicList[0].isCancelled = false;
        //this.clinic_details_update.clinicList[0].isCompleted = false;
        this.clinic_details_update.clinicList[0].isConfirmed = false;
      }
      return true;
    } else {
      return false;
    }
  }

  hasClinicalRequirements(): boolean {
    let has_cl_approved: boolean = false;
    this.coClinicDetails.clinicList[0].clinicImzQtyList.forEach(imz => {
      if (this.clinic_details_update.clinicList && (this.clinic_details_update.clinicList[0].isReassign === 1 || (this.clinic_details_update.clinicList[0].reassignType && this.clinic_details_update.clinicList[0].reassignType.toLowerCase() === 'geography')) && ImmunizationsClinicalRequirements["immunizations"].indexOf(imz.immunizationName) > -1 && imz.isClinicalReqd) {
        has_cl_approved = true;
      }
    });
    return has_cl_approved;
  }

  clearReassignDialogControls() {
    this.reassignForm = this.formBuilder.group({
      assignType: [null, null],
      assignStoreID: [null, null],
      assignStore: ['', null],
    });
  }
  maintainStatusClicked() {
    this.AssignedStoreChkModalDlg = false;
    this.maintainStatusSelected = true;
    this.clinic_details_update = this.saveData(null);
    this.clinic_details_update.clinicContactStatus = "Submit";
    this._alertService.clear();
    this._alertService.info(ErrorMessages['clinicDateReminderAfter2WeeksEN'], false);
    this.scrolling();
    this.spinner.show();
    this.coClinicdetailsService.upDateClinicDetails(this.coClinicDetails.clinicList[0].clinicPk,
      this.clinic_details_update).subscribe((res: any) => {
        this.spinner.hide();
        //if (res.status == "200") {
        if (res.errorS != null) {
          if (res.errorS.errorCode != 0) {
            switch (res.errorS.errorCode) {
              case -4:
                let sumMsg = ErrorMessages['eventDateTimeConflict'];
                let finalErrMsg = String.Format("{0}<br/><br/>{1}", res.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']);
                this.OverrideOrOkTitle = "Override";
                this.showCancelBtn = true;
                this.confirm(sumMsg, finalErrMsg, null, true);
                break;
              default:
                this.showDialogError(ErrorMessages['clinicDetails'], res.errorS.errorMessage);
                break;
            }
            return;
          }
          else {
            this.showDialog(ErrorMessages['clinicDetails'], ErrorMessages['clinicSave']);
          }
        }
        else {
          this.showDialog(ErrorMessages['clinicDetails'], ErrorMessages['clinicSave']);
        }
        //}
      },
        err => {
          this.spinner.hide();
          this.showDialog(ErrorMessages['serverError'], err.Message);
        });
  }
  contactClientClicked() {
    this.AssignedStoreChkModalDlg = false;
    this.maintainStatusSelected = false;
    this.clinic_details_update = this.saveData(null);
    this.clinic_details_update.clinicContactStatus = "Submit";
    this.clinic_details_update.clinicList[0].isConfirmed = [2, 6].indexOf(this.clinicType) > -1 ? 1 : 0;
    this.clinic_details_update.clinicList[0].isCancelled = 0;
    this.clinic_details_update.clinicList[0].isCompleted = 0;
    this.maintainStatusSelected = true;
    this._alertService.clear();
    this._alertService.info(ErrorMessages['clinicDateReminderAfter2WeeksEN'], false);
    this.scrolling();
    this.spinner.show();
    this.coClinicdetailsService.upDateClinicDetails(this.coClinicDetails.clinicList[0].clinicPk,
      this.clinic_details_update).subscribe((res: any) => {
        this.spinner.hide();
        //if (res.status == "200") {
        if (res.errorS != null) {
          if (res.errorS.errorCode != 0) {
            switch (res.errorS.errorCode) {
              case -4:
                let sumMsg = ErrorMessages['eventDateTimeConflict'];
                let finalErrMsg = String.Format("{0}<br/><br/>{1}", res.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']);
                this.OverrideOrOkTitle = "Override";
                this.showCancelBtn = true;
                this.confirm(sumMsg, finalErrMsg, null, true);
                break;
              default:
                this.showDialogError(ErrorMessages['clinicDetails'], res.errorS.errorMessage);
                break;
            }
            return;
          }
          else {
            this.showDialog(ErrorMessages['clinicDetails'], ErrorMessages['clinicSave']);
          }
        }
        else {
          this.showDialog(ErrorMessages['clinicDetails'], ErrorMessages['clinicSave']);
        }
        //}
      },
        err => {
          this.spinner.hide();
          this.showDialog(ErrorMessages['serverError'], err.Message);
        });
  }
  showReAssignStoreDialog(action_status: string) {
    this.dialogSummary = ErrorMessages['reassignStoreChkHdr'];
    let control = <FormArray>this.clinicDetailsForm.controls['clinicList'];

    if (control.value[0].isNoClinic == 1) {
      if (this.coClinicDetails.clinicList[0].isConfirmed == 1 || this.coClinicDetails.clinicList[0].isCompleted == 1)
        this.reAssignOutreachStatusLabel = "Provide Vouchers";
    }
    else {
      this.reAssignOutreachStatusLabel = [1].indexOf(this.clinicType) > -1 ? "Contact Client" : "Confirmed";
    }
    this.dialogMsg = String.Format(ErrorMessages['reassignStoreChkMsg'], this.reAssignOutreachStatusLabel);
    this.AssignedStoreChkModalDlg = true;
  }
  isNoclinicValidations() {
    let control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    let index;
    for (index = 0; index < control.length; index++) {
      if (control.value[index].isNoClinic == 1) {
        control.controls[index].get('clinicDate').setValidators(null);
        control.controls[index].get('clinicDate').setValue(null);
        control.controls[index].get('clinicDate').updateValueAndValidity();
        control.controls[index].get('naClinicStartTime').setValidators(null);

        control.controls[index].get('naClinicStartTime').updateValueAndValidity();
        control.controls[index].get('naClinicStartTime').setValue(null);
        control.controls[index].get('naClinicEndTime').setValidators(null);
        control.controls[index].get('naClinicEndTime').updateValueAndValidity();
        control.controls[index].get('naClinicEndTime').setValue(null);
        this.utility.validateAllFormFields(<FormGroup>control.controls[index]);
      }
      else {
        control.controls[index].get('clinicDate').setValidators([Validators.required]);
        control.controls[index].get('clinicDate').updateValueAndValidity();
        control.controls[index].get('naClinicStartTime').setValidators([Validators.required]);
        control.controls[index].get('naClinicStartTime').updateValueAndValidity();
        control.controls[index].get('naClinicEndTime').setValidators([Validators.required]);
        control.controls[index].get('naClinicEndTime').updateValueAndValidity();
        this.utility.validateAllFormFields(<FormGroup>control.controls[index]);
      }
    }
  }

  continueWithSaving(action_status, is_override?: boolean) {
    var error_message = "";
    this.clinic_details_update = this.saveData(action_status);

    if (this.dateTimeConflict()) {

      let min_shot_validation = true;
      // min_shot_validation = this.MinimumShotValidation(action_status, is_override);
      if (min_shot_validation) {
        this.spinner.show();
        if (action_status != null) {
          if (action_status.toLowerCase() == 'completed') {
            error_message = ErrorMessages['clinicComplete'];
            if (this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
              error_message = ErrorMessages['clinicCompleteWithSchdlr']
            }
          }
          if (action_status.toLowerCase() == 'confirmed') {
            error_message = ErrorMessages['clinicConfirm'];
            if (this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
              if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1) {
                error_message = ErrorMessages['clinicConfirmWithSchdlr'];
              }
              else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1) {
                error_message = ErrorMessages['clinicConfirmWithCPSchdlr'];
              }
            }
          }

          if (action_status.toLowerCase() == 'cancelled') {
            error_message = ErrorMessages['clinicCancel'];
            this.clinic_details_update.disposition = this.selectedCancelDisposition;
          }
          if (action_status.toLowerCase() == 'submit') {
            error_message = ErrorMessages['clinicSave'];
            if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
              error_message = ErrorMessages['clinicSavedWithSchdlr']
            }
            else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
              error_message = ErrorMessages['clinicSavedWithCPSchdlr'];
            }
          }
        }
        else {
          error_message = ErrorMessages['clinicSave'];
          if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
            error_message = ErrorMessages['clinicSavedWithSchdlr']
          }
          else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
            error_message = ErrorMessages['clinicSavedWithCPSchdlr'];
          }
        }

        this._alertService.clear();
        if (action_status == null || action_status.toLowerCase() != 'completed') {
          this._alertService.info(ErrorMessages['clinicDateReminderAfter2WeeksEN'], false);
        }
        this.scrolling();
        this.rescheduledErrorCode = 0;
        var areApptsPresent = this.coClinicDetails.clinicList[0].areApptsPresent;
        var noSlotsOfBlocked = this.coClinicDetails.clinicList[0].noSlotsOfBlocked;
        var isAdmin = SessionDetails.GetUserProfile().isAdmin;
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        var clinicDate = new Date(this.coClinicDetails.clinicList[0].clinicDate);
        clinicDate.setHours(0, 0, 0, 0);
        this.clinic_details_update.isSendLocationChangeEmailToAppts = false;
        this.clinic_details_update.isSendRescheduleEmailToAppts = false;
        this.coClinicdetailsService.upDateClinicDetails(this.coClinicDetails.clinicList[0].clinicPk,
          this.clinic_details_update, is_override).subscribe((res: any) => {
            this.spinner.hide();
            //if (res.status == "200") {
            if (res.errorS != null) {
              if (res.errorS.errorCode != 0) {
                let errorCode = res.errorS.errorCode;
                let errorMessage = res.errorS.errorMessage;
                this.isOverride = is_override;
                switch (errorCode) {
                  case -4:
                    let sumMsg = ErrorMessages['eventDateTimeConflict'];
                    let finalErrMsg = String.Format("{0}<br/><br/>{1}", res.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']);
                    this.OverrideOrOkTitle = "Override";
                    this.showCancelBtn = true;
                    this.confirm(sumMsg, finalErrMsg, this.clinic_details_update.clinicContactStatus, true);
                    break;
                  case -6:
                    this.rescheduledErrorCode = errorCode;
                    this.showClinicLocationDetailsSaveChangesError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsLocationChangedWarning']);
                    break;
                  case -5:
                    // if (this.isAdmin()) {                          
                    this.rescheduledErrorCode = errorCode;
                    this.showUpdateClinicDetailsRspError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsChangedWarning'].replace('{1}', errorMessage.replace(',', ', ')));
                    // }
                    // else {
                    //   this.showDialogError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsChangedErrMsg'].replace('{1}', errorMessage.replace(',', ', ')));
                    // }
                    break;
                  case -7:
                    this.showDialog(ErrorMessages['clinicDetails'], errorMessage);
                    break;
                  default:
                    this.showDialogError(ErrorMessages['clinicDetails'], res.json().errorS.errorMessage);
                    break;
                }
                return;
              }
              else {
                this.isOverride = false;
                this.rescheduledErrorCode = 0;
                if (this.coClinicDetails.opportunityInfo.clinicType == 3) {
                  this.showDialog(ErrorMessages['clinicDetails'], action_status && action_status.toLowerCase() == 'cancelled' && areApptsPresent && (isAdmin == false || isAdmin == undefined)
                    && clinicDate >= new Date(now.getFullYear(), now.getMonth(), now.getDate()) ? String.Format(ErrorMessages['clinicCancelForClinicType3And5'], this.coClinicDetails.clinicList[0].clinicNumber) : error_message);
                }
                else {
                  this.showDialog(ErrorMessages['clinicDetails'], error_message);
                }
              }
            }
            else {
              this.isOverride = false;
              this.rescheduledErrorCode = 0;
              if (this.coClinicDetails.opportunityInfo.clinicType == 3) {
                this.showDialog(ErrorMessages['clinicDetails'], action_status && action_status.toLowerCase() == 'cancelled' && areApptsPresent && (isAdmin == false || isAdmin == undefined)
                  && clinicDate >= new Date(now.getFullYear(), now.getMonth(), now.getDate()) ? String.Format(ErrorMessages['clinicCancelForClinicType3And5'], this.coClinicDetails.clinicList[0].clinicNumber) : error_message);
              }
              else {
                this.showDialog(ErrorMessages['clinicDetails'], error_message);
              }
            }
            //}
          },
            err => {
              this.spinner.hide();
              this.isOverride = false;
              this.rescheduledErrorCode = 0;
              this.showDialog(ErrorMessages['serverError'], err.message);
            });
      }
    }
  }

  confirm(hdr: string, msg: string, action_status, is_overlap?: boolean) {
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {
        if (is_overlap) {
          this.continueWithSaving(action_status, true);
        } else {
          this.continueWithSaving(action_status);
        }
        return true;
      },
      reject: () => {
        this.isOverride = false;
        return false;
      }
    });
  }
  showFluDosesValidityDialog(hdr: string, msg: string, action_status) {
    this.dialogMsg = msg;
    this.dialogSummary = hdr;
    this.FluDosesValidUnder14DaysDlg = true;
  }

  OverrideFluDosesValidUnder14Days() {
    this.FluDosesValidUnder14DaysDlg = false;
    this.continueWithSaving(null);
  }

  CancelFluDosesValidUnder14Days() {
    this.FluDosesValidUnder14DaysDlg = false;
  }

  isFluDosesValidUnder14DaysTimeFrame(): boolean {
    let count: number = 0;
    try {
      this.coClinicDetails.clinicList[0].clinicImzQtyList.forEach(imz => {
        let qty = Number(this.clinicDetailsForm.controls.clinicImmunizationList['controls'][count].get('estimatedQuantity').value)
        if (Number(imz.estimatedQuantity) < qty) {
          throw false;
        }
        count++;
      });
    } catch (e) {
      if (e === false) {
        return e;
      }
    }
    return true;
  }
  Status() {
    this.savePopUpStatus = false;
    window.scrollTo(0, 0);
    this.bindLocalClinicData();
  }

  cancelClinic() {
    if (this.selectedCancelDisposition.length) {
      this.displayCancelError = false;
    }
    else {
      this.displayCancelError = true;
      return;
    }

    this.showClinicCancelDlg = false;
    if (this.coClinicDetails.opportunityInfo.childClinicAgreementId != null && this.coClinicDetails.opportunityInfo.childClinicAgreementId > 0 && !this.coClinicDetails.opportunityInfo.childClinicAgreementApproved) {
      this.clinicNotConfirmedMsgDlg = true;
      this.showClinicNotConfirmedDialog('Amend Agreement', ErrorMessages['DisbaleButtonsAmend']);
      return false;
    }
    let control = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
    let index;
    for (index = 0; index < control.length; index++) {
      control.controls[index].get('totalImmAdministered').setValidators(null);
      control.controls[index].get('totalImmAdministered').updateValueAndValidity();
      control.controls[index].get('estimatedQuantity').setValidators(Validators.required);
      control.controls[index].get('estimatedQuantity').updateValueAndValidity();

      control.controls[index].get('isClinicalReqd').setValidators(null);
      control.controls[index].get('isClinicalReqd').updateValueAndValidity();
    }
    control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    for (index = 0; index < control.length; index++) {
      control.controls[0].get('confirmedClientName').setValidators(null);
      control.controls[0].get('confirmedClientName').updateValueAndValidity();
      this.utility.validateAllFormFields(<FormGroup>control.controls[index]);
    }
    this.isNoclinicValidations();
    // this.clinicDetailsForm.controls["pharmacistName"].setValidators(null);
    // this.clinicDetailsForm.controls["pharmacistName"].updateValueAndValidity();
    // this.clinicDetailsForm.controls["pharmacistPhone"].setValidators(null);
    // this.clinicDetailsForm.controls["pharmacistPhone"].updateValueAndValidity();
    // this.clinicDetailsForm.controls["totalHours"].setValidators(null);
    // this.clinicDetailsForm.controls["totalHours"].updateValueAndValidity();

    this.clinicDetailsForm.controls["feedbackNotes"].setValidators(null);
    this.clinicDetailsForm.controls["feedbackNotes"].updateValueAndValidity();

    this.clinicDetailsForm.controls["naClinicAddlComments"].setValidators(Validators.required);
    this.clinicDetailsForm.controls["naClinicAddlComments"].updateValueAndValidity();


    if (this.clinicDetailsForm.valid) {
      if (((this.clinicDetailsForm.value.recipientId != null ||
        this.clinicDetailsForm.value.recipientId != undefined) &&
        this.clinicDetailsForm.value.recipientId.trim().length <= 0) ||
        ((this.clinicDetailsForm.value.naClinicPlanId != null ||
          this.clinicDetailsForm.value.naClinicPlanId != undefined) &&
          this.clinicDetailsForm.value.naClinicPlanId.trim().length <= 0)) {
        this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
        return false;
      }
      if ((this.clinicDetailsForm.value.email != null || this.clinicDetailsForm.value.email != undefined) &&
        this.clinicDetailsForm.value.email.trim().length > 0) {
        if (!this.utility.validateSingleEmail(this.clinicDetailsForm.value.email.trim())) {
          if ([1, 6].indexOf(this.clinicType) > -1) {
            this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidEmail']);
            return false;
          }
        }
      }

      if ((this.clinicDetailsForm.value.phone != null || this.clinicDetailsForm.value.phone != undefined) &&
        this.clinicDetailsForm.value.phone.trim().length > 0) {
        if (!this.utility.validatePhoneNo(this.clinicDetailsForm.value.phone.trim())) {
          this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidPhoneFormat']);
          return false;
        }
      }
      if ((this.clinicDetailsForm.value.pharmacistPhone != null || this.clinicDetailsForm.value.pharmacistPhone != undefined) &&
        this.clinicDetailsForm.value.pharmacistPhone.trim().length > 0) {
        if (!this.utility.validatePhoneNo(this.clinicDetailsForm.value.pharmacistPhone.trim())) {
          this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidPhoneFormat']);
          return false;
        }
      }
      if (!this.isAssignedStoreValid()) {
        if (this.coClinicDetails.clinicList[0].isConfirmed == true || this.coClinicDetails.clinicList[0].isConfirmed == 1 ||
          this.coClinicDetails.clinicList[0].isCompleted == true || this.coClinicDetails.clinicList[0].isCompleted == 1 ||
          this.coClinicDetails.clinicList[0].isCancelled == true || this.coClinicDetails.clinicList[0].isCancelled == 1) {
          this.showDialog(ErrorMessages['reAssignStoreInvalidActionHdr'], ErrorMessages['reAssignStoreInvalidActionMsg']);
          return false;
        }
      }
      let controlclinicList = <FormArray>this.clinicDetailsForm.controls['clinicList'];
      let index_clinic;
      for (index_clinic = 0; index_clinic < controlclinicList.length; index_clinic++) {

        let address1: string = controlclinicList.controls[index_clinic].get('naClinicAddress1').value;
        let address2: string = controlclinicList.controls[index_clinic].get('naClinicAddress2').value;

        let address = (address1 + address2).trim().replace(/[\[\]().-]+/g, '');
        address = address.replace(/\s/g, '');
        if (address.toLowerCase().includes("pobox")) {
          this.dialogSummary = "Clinic Details";
          this.dialogMsg = "Addresses containing a P.O. Box are not allowed. Please enter a valid location address.";

          this.showDialogV2(this.dialogSummary, this.dialogMsg);
          return;
        }

      }
      this.is_cancelled = true;
      this.action_status = "Cancelled";
      if (!this.validateReduceEstQnt()) {
        return;
      }
      //let clinicItem = <FormArray>(this.clinicDetailsForm.controls['clinicList']);
      //clinicItem.controls[0].get('apptSchedulerType').value
      if (!this.alertUserAboutScheduler()) {
        return;
      }
      var now = new Date();
      now.setHours(0, 0, 0, 0);
      var clinicDate = new Date(this.coClinicDetails.clinicList[0].clinicDate);
      clinicDate.setHours(0, 0, 0, 0);
      var areApptsPresent = this.coClinicDetails.clinicList[0].areApptsPresent;
      var isAdmin = SessionDetails.GetUserProfile().isAdmin;
      if (this.coClinicDetails.clinicList[0].areApptsPresent &&
        this.adminConfirmedCancellation === false && //SessionDetails.GetUserProfile().isAdmin === true
        clinicDate >= new Date(now.getFullYear(), now.getMonth(), now.getDate())) {
        this.dialogSummary = "Clinic Details";
        this.dialogMsg = String.Format(ErrorMessages['clinicCancelForClinicType3And5ForAdmin'], [3, 5, 7].indexOf(this.clinicType) > -1 ? this.coClinicDetails.clinicList[0].clinicNumber : this.utility.numberToLetters(this.coClinicDetails.clinicList[0].clinicNumber - 1));
        this.adminCancellationAlert = true;
        return;
      }
      // 
      if (this.coClinicDetails.clinicList[0].isCuraAppointmentScheduled &&
        this.adminConfirmedCancellation === false && //SessionDetails.GetUserProfile().isAdmin === true
        clinicDate >= new Date(now.getFullYear(), now.getMonth(), now.getDate())) {
        this.dialogSummary = "Clinic Details";
        this.dialogMsg = String.Format(ErrorMessages['clinicCancelForClinicType3And5ForAdmin'], [3, 5, 7].indexOf(this.clinicType) > -1 ? this.coClinicDetails.clinicList[0].clinicNumber : this.utility.numberToLetters(this.coClinicDetails.clinicList[0].clinicNumber - 1));
        this.adminCancellationAlert = true;
        return;
      }

      let ret = this.isClinicDateReminderBefore2WksReq("Cancelled");
      if (!ret || (ret && this.clinicDetailsForm.value.clinicList.length <= 1)) {
        this.continueWithSaving("Cancelled");
      }
      else {
        let sumMsg = ErrorMessages['impRmndrFor2Wks'];
        let errMsg = ErrorMessages['new_clinicDateReminderBefore2WeeksEN'];
        var userProfile = SessionDetails.GetUserProfile();
        //if (ClinicDetailsUtilities.shallWeShow2WkConfirmDialog(userProfile)) {
        this.OverrideOrOkTitle = "OK";
        this.showCancelBtn = false;
        this.confirm(sumMsg, errMsg, "Cancelled");
        //          return;
        //      }
        // else {
        //   errMsg = ErrorMessages['clinicDateReminderBefore2WeeksEN'];
        //   this.showDialog(sumMsg, errMsg);
        //   return;
        // }
      }
    }
    else {
      if (this.clinicDetailsForm.value.email != null && this.clinicDetailsForm.value.email != undefined &&
        this.clinicDetailsForm.value.email.trim().length > 0
        && !this.utility.validateSingleEmail(this.clinicDetailsForm.value.email)) {
        this.utility.validateAllFormFields(this.clinicDetailsForm);
        if ([1, 6].indexOf(this.clinicType) > -1) {
          this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['invalidEmail']);
          return false;
        }
      }
      this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
      this.utility.validateAllFormFields(this.clinicDetailsForm);
      return;
    }
  }

  ContinueCancellation() {
    this.adminConfirmedCancellation = true;
    this.adminCancellationAlert = false;
    this.cancelClinic();
  }

  userCancelledCancellation() {
    this.adminConfirmedCancellation = false;
    this.adminCancellationAlert = false;
  }
  saveToDB() {
    let error_message = ErrorMessages['clinicSave'];
    if (this.clinic_details_update.clinicContactStatus == "Submit") {
      error_message = ErrorMessages['clinicSave'];
      if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
        error_message = ErrorMessages['clinicSavedWithSchdlr']
      }
      else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
        error_message = ErrorMessages['clinicSavedWithCPSchdlr'];
      }
    }
    if (this.clinic_details_update.clinicContactStatus == "Cancelled") {
      error_message = ErrorMessages['clinicCancel'];
    }
    if (this.clinic_details_update.clinicContactStatus == "Completed") {
      error_message = ErrorMessages['clinicComplete'];
      if (this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
        error_message = ErrorMessages['clinicCompleteWithSchdlr']
      }
    }
    if (this.clinic_details_update.clinicContactStatus == "Confirmed") {
      error_message = ErrorMessages['clinicConfirm'];
      if (this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
        if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1) {
          error_message = ErrorMessages['clinicConfirmWithSchdlr'];
        }
        else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1) {
          error_message = ErrorMessages['clinicConfirmWithCPSchdlr'];
        }
      }
    }
    this.scrolling();
    this.spinner.show();
    this.coClinicdetailsService.upDateClinicDetails(this.coClinicDetails.clinicList[0].clinicPk,
      this.clinic_details_update).subscribe((res: any) => {
        this.spinner.hide();
        //if (res.status == "200") {
        if (res.errorS != null) {
          if (res.errorS.errorCode != 0) {
            let errorMessage = res.errorS.errorMessage;
            let errorCode = res.errorS.errorCode;
            switch (res.errorS.errorCode) {
              case -4:
                let sumMsg = ErrorMessages['eventDateTimeConflict'];
                let finalErrMsg = String.Format("{0}<br/><br/>{1}", res.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']);
                this.confirm(sumMsg, finalErrMsg, this.clinic_details_update.clinicContactStatus, true);
                this.OverrideOrOkTitle = "Override";
                this.showCancelBtn = true;
                break;
              case -6:
                this.rescheduledErrorCode = errorCode;
                this.showClinicLocationDetailsSaveChangesError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsLocationChangedWarning']);
                break;
              case -5:
                //      if (this.isAdmin()) {
                this.rescheduledErrorCode = errorCode;
                this.showUpdateClinicDetailsRspError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsChangedWarning'].replace('{1}', errorMessage.replace(',', ', ')));
                // }
                // else {
                //   this.showDialogV2(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsChangedErrMsg'].replace('{1}', errorMessage.replace(',', ', ')));
                // }
                break;
              case -7:
                this.showDialog(ErrorMessages['clinicDetails'], errorMessage);
                break;
              default:
                this.showDialogError(ErrorMessages['clinicDetails'], res.errorS.errorMessage);
                break;
            }
            return;
          }
          else {
            this.showDialog(ErrorMessages['clinicDetails'], error_message);
          }
        }
        else {
          this.showDialog(ErrorMessages['clinicDetails'], error_message);
        }
        //}
      },
        err => {
          this.spinner.hide();
          this.showDialog(ErrorMessages['serverError'], err.Message);
        });
  }


  deleteAgreementData() {
    if (this.clinicType !== 13) {
      this.headerMessage = 'Deleting An Off-Site Agreement';
      this.confirmationMessage = 'Deleting the Community Off-Site Agreement cannot be undone. If you "Continue" the contract, contract approval, all clinic locations associated with this business, all of their program details, and any pending amended contracts will be permanently deleted.'

    }
    else {
      this.headerMessage = 'Deleting An Equity Event';
      this.confirmationMessage = 'Deleting an Equity Event cannot be undone. If you "Continue" all events associated with this business will be permanently deleted.'

    }
    if (this.coClinicDetails.clinicList[0].apptSchedulerType == 2 && this.coClinicDetails.clinicList[0].isCuraAppointmentScheduled && this.coClinicDetails.clinicList[0].isCancelled == false) {

      this.dialogMsg = 'This agreement has scheduled clinics with appointments. Please cancel the clinics associated with this agreement.';
      this.dialogSummary = ErrorMessages['clinicDetails'];
      this.showAfterClinicDelete = true;
      this.Action = 'scheduledappointments';

      return;
    }
    this.deleteAgreement = true;
  }

  deleteAmendAgreementData() {
    this.isDeleteAmendAgreement = true;
  }
  deleteClinicDetails() {

    this.deleteAgreement = false;
    let clinic_details = new ClinicDetailsDelete();
    clinic_details.userPk = this.user_profile.userPk;
    clinic_details.username = this.user_profile.userName;
    clinic_details.businessName = this.coClinicDetails.opportunityInfo.businessName;
    clinic_details.clinicPk = this.coClinicDetails.clinicList[0].clinicPk;
    clinic_details.clinicStoreId = this.coClinicDetails.clinicList[0].clinicStoreId;
    clinic_details.contractedStoreId = this.coClinicDetails.opportunityInfo.contractedStoreId;
    this.clinicType = clinic_details.clinicType = this.coClinicDetails.opportunityInfo.clinicType;
    clinic_details.clinicName = [1, 2, 6].indexOf(this.clinicType) > -1 ? this.coClinicDetails.clinicList[0].naClinicLocation.replace("LOCATION ", "CLINIC ") : this.coClinicDetails.clinicList[0].naClinicLocation;
    clinic_details.pData = this.pData;

    this.coClinicdetailsService.deleteClinicDetails(
      clinic_details).subscribe((res: any) => {
        if (res.errorS.errorCode == -1) {
          this.showDialogError(ErrorMessages['clinicDetails'], "This agreement has scheduled clinics with appointments. Please cancel the clinics associated with this agreement.");
        }
        else if (res.errorS.errorCode == -2) {
          this.showDialogError(ErrorMessages['clinicDetails'], "The agreement cannot be deleted until the client has approved the Cancellation emails to be sent.");
        }
        else if (res.errorS.errorCode == -7) {
          this.showDialogError(ErrorMessages['clinicDetails'], " CURA Patient Error: " + res.errorS);
        }
        else {
          this.showErrDialogThenGoToHome(ErrorMessages['clinicDetails'], ErrorMessages['clinicDetailsDelete']);
          //showErrDialogThenGoToHome(msgSummary: string, msgDetail: string) {

          //this.router.navigateByUrl("/communityoutreach/storehome");
        }
      });
  }
  deleteAgreementCancel() {
    this.deleteAgreement = false;
  }

  deleteAmendAgreementCancel() {
    this.isDeleteAmendAgreement = false;
  }

  deleteAmendAgreement() {
    this.isDeleteAmendAgreement = false;

    this.coClinicdetailsService.deleteAmendAgreement(
      this.coClinicDetails.opportunityInfo.childClinicAgreementId, this.user_profile.userPk).subscribe((res: any) => {

        if (res.status == "200") {
          this.showDialog(ErrorMessages['clinicDetails'], ErrorMessages['clinicDetailsDelete']);
          this.router.navigateByUrl("/communityoutreach/storehome");
        }
      });

  }
  removeCancelledStatus(status: boolean) {
    this.status = status;
    this.dialogSummary = status ? 'Remove Cancelled Status' : 'Remove Last Logged Status';
    this.dialogMsg = status ? ErrorMessages['RemoveCancelled'] : ErrorMessages['RemoveLogStatus'];
    this.showRemoveCancelledStatusDlg = true;
  }
  cancelRemoveCancelledStatus() {
    this.showRemoveCancelledStatusDlg = false;
  }

  continueToRemoveCancelledStatus() {
    this.spinner.show();
    var clinic_pk = this.coClinicDetails.clinicList[0].clinicPk;
    console.log('TODO');
    this.coClinicdetailsService.removeClinicCancelledStatus(
      clinic_pk, this.status).subscribe((res: any) => {
        if (res == "success") {
          this.showRemoveCancelledStatusDlg = false;
          this.showRemoveCancelledStatusDialogResult(ErrorMessages['clinicDetails'], this.status ? ErrorMessages['removeCancelledClinicStatus'] : 'The last logged status has been removed successfully.');
        }
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.showRemoveCancelledStatusDlg = false;
        this.showDialogError(ErrorMessages['clinicDetails'], ErrorMessages['error']);
      });
  }
  confirmDeleteClinic() {
    if (this.areApptsPresent || (this.coClinicDetails.clinicList[0].apptSchedulerType == 2 && this.coClinicDetails.clinicList[0].isCuraAppointmentScheduled)) {
      this.dialogMsg = 'This clinic has scheduled appointments. Please cancel the clinic prior to deleting it.';
      this.dialogSummary = ErrorMessages['clinicDetails'];
      this.showAfterClinicDelete = true;
      this.Action = 'scheduledappointments';
    }
    else {
      this.dialogMsg = 'Deleting an Uploaded Clinic cannot be undone. If you "Continue" the clinic, will be permanently deleted.';
      this.dialogSummary = 'Deleting an Uploaded Clinic';
      this.showDeleteClinic = true;
    }
  }
  deleteClinic() {
    var clinic_pk = this.coClinicDetails.clinicList[0].clinicPk;
    //console.log("TODO");
    this.coClinicdetailsService.deleteClinic(clinic_pk).subscribe((res: any) => {
      if (res == "success") {
        this.showDeleteClinic = false;
        this.showAfterClinicDelete = true;
        this.dialogMsg = 'The Clinic has been removed successfully.';
        this.dialogSummary = ErrorMessages['clinicDetails'];
      }
    }, error => {
      this.showDeleteClinic = false;
      this.showDialogError(ErrorMessages['clinicDetails'], ErrorMessages['error']);
      return false;
    });
  }
  loadClinicDataAfterRemove() {
    this.showRemoveCancelledStatusResult = false;
    this.validateCancel = null;
    this.validateComplete = null;
    this.isAmendButtonEnable = true;
    this.validateConfirm = null;
    this.showRemoveCancelledStatus = false;
    this.IsAmendSubmit = false;
    this.bindLocalClinicData();
    this.clinicDetailsForm.updateValueAndValidity();
  }
  onlyNumbers(event: any) {
    Util.onlyNumbers(event);
  }
  copyPreviousLocation(formIndex: number, checked: boolean) {
    if (!checked) {
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicAddress1').setValue('');
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicAddress2').setValue('');
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicCity').setValue('');
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicState').setValue('');
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicZip').setValue('');
    }
    else {
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicAddress1').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naClinicAddress1').value);
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicAddress2').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naClinicAddress2').value);
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicCity').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naClinicCity').value);
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicState').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naClinicState').value);
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicZip').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naClinicZip').value);
    }
    this.onBlurMethod(formIndex);
  }

  copyPreviousContactInfo(formIndex: number, checked: boolean) {
    if (!checked) {
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naContactFirstName').setValue('');
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naContactLastName').setValue('');
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naContactEmail').setValue('');
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicContactPhone').setValue('');
    }
    else {
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naContactFirstName').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naContactFirstName').value);
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naContactLastName').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naContactLastName').value);
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naContactEmail').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naContactEmail').value);
      this.clinicDetailsForm.controls.clinicList['controls'][formIndex].get('naClinicContactPhone').setValue(this.clinicDetailsForm.controls.clinicList['controls'][formIndex - 1].get('naClinicContactPhone').value);
    }
  }
  viewAgreement() {

    let showPreUniversalContract = false;
    if (this.clinicAgreementDateApproved) {
      this.clinicAgreementDateApproved = new Date(this.clinicAgreementDateApproved);
      this.routePageName = getContractComponentName(this.clinicAgreementDateApproved, 'localclincdetails-viewcontract');
    }

    sessionStorage["clinic_agreement_pk"] = this.coClinicDetails.opportunityInfo.clinicAgreementPk;
    SessionDetails.fromLocalclinics(true);
    // if(showPreUniversalContract)
    // this.router.navigateByUrl("/viewcontract");
    // else
    // this.router.navigateByUrl("/viewcontract-2022-1");

    this.router.navigateByUrl(this.routePageName);
  }
  modifyAgreement() {
    SessionDetails.SetAgreementPK(this.coClinicDetails.opportunityInfo.clinicAgreementPk);
    SessionDetails.fromUserPage(true);
    SessionDetails.setOpportunitiesData(JSON.stringify(this.coClinicDetails.opportunityInfo));
    this.router.navigateByUrl("/communityoutreach/contract");
  }
 
  Ok() {
    this.savePopUp = false;
    if (this.reassignForm.value.assignType == "Area" || this.reassignForm.value.assignType == "District") {
      this.savedViaReassignBtn = true;
      this.clearReassignDialogControls();
      if (this.user_profile.userRole.toLowerCase() == "store manager" || this.user_profile.userRole.toLowerCase() == "pharmacy manager") {
        this.router.navigateByUrl("/communityoutreach/storehome");
      }
      else {
        this.router.navigateByUrl("/communityoutreach/home");
      }
    }
    else if (this.reassignForm.value.assignType == "Store" || this.reassignForm.value.assignType == "Geography") {
      this.savedViaReassignBtn = true;
      this.clearReassignDialogControls();
      this.router.navigateByUrl("/communityoutreach/storehome");
    }
    else {
      this.savedViaReassignBtn = false;
      this.bindLocalClinicData();
    }
  }
  Error() {
    this.errorPopUp = false;
  }
  clinicNotConfirmedMsgOk() {
    this.clinicNotConfirmedMsgDlg = false;
  }

  showClinicNotConfirmedDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.clinicNotConfirmedMsgDlg = true;
  }
  showCancelClinicConfirmation() {
    this.dialogMsg = ErrorMessages['confirmClinicCancellation'];
    this.dialogSummary = "Review Clinic Cancellation";
    this.showClinicCancelDlg = true;
  }
  StayonDetailsPage() {
    this.showClinicCancelDlg = false;
    this.selectedCancelDisposition = '';
    this.displayCancelError = false;
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.savePopUp = true;
  }
  showDialogError(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.errorPopUp = true;
  }

  showDialogStatus(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.savePopUpStatus = true;
  }
  showRemoveCancelledStatusDialogResult(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.showRemoveCancelledStatusResult = true;
  }

  saveData(action_status): ClinicDetailsUpdate {
    this.updateDataFromFormFields(this.clinic_details_update);

    this.clinic_details_update.clinicsUpdateHistoryLogList = [];

    this.clinic_details_update.clinicList.forEach(item => {
      try {
        item.naClinicStartTime = this._datePipe.transform(item.naClinicStartTime, "shortTime");
      } catch (e) {
        console.log('save changes' + e);
      }
      try {
        item.naClinicEndTime = this._datePipe.transform(item.naClinicEndTime, "shortTime");
      } catch (e) {
        console.log('save changes' + e);
      }
    });

    this.clinic_details_update.userPk = this.coClinicDetails.userPk;
    this.clinic_details_update.canReassignClinic = this.coClinicDetails.canReassignClinic;

    this.clinic_details_update.clinicContactStatus = action_status == null ? "Submit" : this.coClinicDetails.clinicContactStatus;
    //Identifying the updated value
    let data: any = this.utility.getDifference(this.coClinicDetails, this.clinic_details_update);
    //console.log(data);
    let localAliasName = LocalField;

    for (let item in data) {
      if (item != "userPk" && item != "clinicContactStatus" && item != "appointmentScheduler") {
        //Commenting as this is not being used anywhere. Issue occured when item is undefined.
        // let opp: any = Object.getOwnPropertyNames(data[item]);
        if (typeof data[item] == 'object') {
          var temp_obj;
          if (item == "clinicList") {
            temp_obj = data[item][0];
          } else {
            temp_obj = data[item];
          }
          for (let updated_item in temp_obj) {
            let value = temp_obj[updated_item];
            if (typeof value == 'object') {

              for (let updated_imz_item in temp_obj[updated_item]) {
                for (let updated_imz_qty in temp_obj[updated_item][updated_imz_item]) {
                  value = temp_obj[updated_item][updated_imz_item][updated_imz_qty];
                  let alias_and_group_name = localAliasName.map(group_item => {
                    if (group_item.aliasNames[updated_imz_qty]) {
                      return group_item.aliasNames[updated_imz_qty] + "~" + group_item.groupName
                    }
                  }).filter(test_item => test_item != undefined);
                  if (alias_and_group_name.length > 0) {
                    let alias_name = alias_and_group_name[0].substring(0, alias_and_group_name[0].indexOf('~'));
                    let group_name = alias_and_group_name[0].substring(alias_and_group_name[0].indexOf('~') + 1)
                    alias_name = temp_obj[updated_item][updated_imz_item]["imzNameAndPmtMethod"] + '<br />' + alias_name;
                    this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Update", value, group_name, alias_name));
                  }
                }
              }

            }
            else if (updated_item == 'coOutreachTypeId') {
              let alias_and_group_name = localAliasName.map(group_item => {
                if (group_item.aliasNames[updated_item]) {
                  return group_item.aliasNames[updated_item].label + "~" + group_item.groupName
                }
              }).filter(test_item => test_item != undefined);
              if (alias_and_group_name.length > 0) {
                let alias_name = alias_and_group_name[0].substring(0, alias_and_group_name[0].indexOf('~'));
                let group_name = alias_and_group_name[0].substring(alias_and_group_name[0].indexOf('~') + 1)
                if ([3, 6].indexOf(this.clinicType) > -1 && alias_name === "No Clinic - Voucher Distribution Only") {
                  //continue;
                }
                else {
                  let val = group_name == "Clinic Information" ? localAliasName.filter(x => x.groupName == group_name)[0].aliasNames['coOutreachTypeId'].values[value] : value;
                  this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Update", val, group_name, alias_name));
                }
              }
            }
            else if (updated_item == 'apptSchedulerType') {
              let alias_and_group_name = localAliasName.map(group_item => {
                if (group_item.aliasNames[updated_item]) {
                  return group_item.aliasNames[updated_item].label + "~" + group_item.groupName
                }
              }).filter(test_item => test_item != undefined);
              if (alias_and_group_name.length > 0) {
                let alias_name = alias_and_group_name[0].substring(0, alias_and_group_name[0].indexOf('~'));
                let group_name = alias_and_group_name[0].substring(alias_and_group_name[0].indexOf('~') + 1)
                if ([3, 6].indexOf(this.clinicType) > -1 && alias_name === "No Clinic - Voucher Distribution Only") {
                  //continue;
                }
                else {
                  let val = group_name == "Clinic Information" ? localAliasName.filter(x => x.groupName == group_name)[0].aliasNames['apptSchedulerType'].values[value] : value;
                  this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Update", val, group_name, alias_name));
                }
              }
            }
            else {
              let alias_and_group_name = localAliasName.map(group_item => {
                if (group_item.aliasNames[updated_item]) {
                  return group_item.aliasNames[updated_item] + "~" + group_item.groupName
                }
              }).filter(test_item => test_item != undefined);
              if (alias_and_group_name.length > 0) {
                let alias_name = alias_and_group_name[0].substring(0, alias_and_group_name[0].indexOf('~'));
                let group_name = alias_and_group_name[0].substring(alias_and_group_name[0].indexOf('~') + 1)
                if ([3, 6].indexOf(this.clinicType) > -1 && alias_name === "No Clinic - Voucher Distribution Only" || (alias_name == "Other" && value == null)) {
                  //continue;
                }
                else {
                  this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Update", value, group_name, alias_name));
                }
              }
            }
          }
        }
        else {
          let value = data[item];
          if (value && item === 'clinicalRequirements') {
            this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Approved Clinical Requirements", "", "", ""));
          }
          let alias_and_group_name = localAliasName.map(group_item => {
            if (group_item.aliasNames[item]) {
              return group_item.aliasNames[item] + "~" + group_item.groupName
            }
          }).filter(test_item => test_item != undefined);
          if (alias_and_group_name.length > 0) {
            let alias_name = alias_and_group_name[0].substring(0, alias_and_group_name[0].indexOf('~'));
            let group_name = alias_and_group_name[0].substring(alias_and_group_name[0].indexOf('~') + 1);
            this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Update", value, group_name, alias_name));
          }
        }
      }
    }

    this.clinic_details_update.userPk = this.user_profile.userPk;
    if (action_status != null) {
      if (action_status.toLowerCase() == 'submit') {
        this.clinic_details_update.clinicContactStatus = "Submit";
      }
      if (action_status.toLowerCase() == "cancelled") {
        this.clinic_details_update.clinicContactStatus = "Cancelled";
        this.clinic_details_update.disposition = this.selectedCancelDisposition;
        this.clinic_details_update.clinicList[0].isCancelled = 1;
        this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Cancelled", "", "", ""));
      }
      if (action_status.toLowerCase() == "completed") {
        this.clinic_details_update.clinicContactStatus = "Completed";
        this.clinic_details_update.clinicList[0].isCancelled = 0;
        this.clinic_details_update.clinicList[0].isCompleted = 1;
        this.clinic_details_update.clinicList[0].isConfirmed = 0;
        this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Completed", "", "", ""));
      }
      if (action_status.toLowerCase() == "confirmed") {
        this.clinic_details_update.clinicContactStatus = "Confirmed";
        this.clinic_details_update.clinicList[0].isConfirmed = 1;
        var confirmed_status = (this.clinic_details_update.clinicList[0].isNoClinic) ? 'Vouchers Provided' : 'Confirmed';
        this.clinic_details_update.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem(confirmed_status, "", "", ""));
      }
    }

    if (!this.maintainStatusSelected) {
      this.clinic_details_update.clinicList[0].isConfirmed = [2, 6].indexOf(this.clinicType) > -1 ? 1 : 0;
      this.clinic_details_update.clinicList[0].isCancelled = 0;
      this.clinic_details_update.clinicList[0].isCompleted = 0;
      this.maintainStatusSelected = true;
    }

    this.clinic_details_update.clinicList.forEach(item => {
      try {
        if (item.naClinicLocation != null && item.naClinicLocation.length > 0 && item.naClinicLocation.search("CLINIC") == -1 &&
          [1, 2, 6].indexOf(this.clinicType) > -1) {
          item.naClinicLocation = "CLINIC " + item.naClinicLocation;
        }
        item.naClinicLocation = item.naClinicLocation != null && item.naClinicLocation.length > 0 ? item.naClinicLocation.trim() : item.naClinicLocation;

        item.clinicImzQtyList.forEach(imzQtyItem => {
          delete imzQtyItem["imzNameAndPmtMethod"];
        });

        item.isNoClinic != null ? item.isNoClinic = Number(item.isNoClinic) : item.isNoClinic = 0;
        item.isReassign != null ? item.isReassign = Number(item.isReassign) : item.isReassign = 0;
        item.naClinicContactPhone = item.naClinicContactPhone;
      } catch (e) {
        console.log('save changes' + e);
      }
    });

    delete this.clinic_details_update["clinicalRequirements"];

    this.clinic_details_update.canReassignClinic = 0;
    this.clinic_details_update.opportunityInfo.pharmacistPhone = this.clinic_details_update.opportunityInfo.pharmacistPhone;
    this.clinic_details_update.opportunityInfo.phone = this.clinic_details_update.opportunityInfo.phone;
    if (this.coClinicDetails.clinicList[0].isCovidClinic) {
      for (var i = 0; i < this.clinic_details_update.clinicList.length; i++) {
        let dose1 = 0, dose2 = 0, manufacturer = 0;
        let immunizer1 = this.clinic_details_update.clinicList[i].clinicImzQtyList.filter(x => x.immunizationName.includes("Dose 1"));
        let immunizer2 = this.clinic_details_update.clinicList[i].clinicImzQtyList.filter(x => x.immunizationName.includes("Dose 2"));
        if (immunizer1.length > 0 && immunizer1[0].estimatedQuantity > 0) {
          dose1 = 1;
          manufacturer = immunizer1[0].immunizationName.includes("Pfizer") ? 1 : 2;
        }
        if (immunizer2.length > 0 && immunizer2[0].estimatedQuantity > 0) {
          dose2 = dose1 == 0 ? 2 : 3;
          manufacturer = immunizer2[0].immunizationName.includes("Pfizer") ? 1 : 2;
        }
        this.clinic_details_update.clinicList[i].manufacturer = manufacturer;
        this.clinic_details_update.clinicList[i].doseNumber = dose2 == 0 ? dose1 : dose2;
      }
    }
    if (this.clinic_details_update.clinicList.length >= 1 && this.clinic_details_update.clinicList[0].vouchersDistributed == null) {
      this.clinic_details_update.clinicList[0].vouchersDistributed = this.clinic_details_update.opportunityInfo.vouchersDistributed;
    }
    return this.clinic_details_update;

  }

  updateDataFromFormFields(target_obj) {

    if (!target_obj.opportunityInfo) {
      target_obj.opportunityInfo = new OpportunityInfo();
    }
    if (!target_obj.errorS) {
      target_obj.errorS = new ErrorS();
    }
    target_obj.opportunityInfo.storeId = this.clinicDetailsForm.value.storeID;
    target_obj.opportunityInfo.businessAddress = this.coClinicDetails.opportunityInfo.businessAddress;
    target_obj.opportunityInfo.businessAddress2 = this.coClinicDetails.opportunityInfo.businessAddress2;
    target_obj.opportunityInfo.businessCity = this.coClinicDetails.opportunityInfo.businessCity;
    target_obj.opportunityInfo.businessContactEmail = this.clinicDetailsForm.value.email;
    target_obj.opportunityInfo.businessName = this.clinicDetailsForm.value.businessName;
    target_obj.opportunityInfo.businessPk = this.coClinicDetails.opportunityInfo.businessPk;
    target_obj.opportunityInfo.b2BTeamEmail = this.clinicDetailsForm.value.b2BTeamEmail;
    target_obj.opportunityInfo.clientReportingEmail = this.clinicDetailsForm.value.clientReportingEmail;
    target_obj.opportunityInfo.salesForceOpportunityId = this.clinicDetailsForm.value.salesForceOpportunityId;
    target_obj.opportunityInfo.salesForceAccountName = this.clinicDetailsForm.value.salesForceAccountName;
    target_obj.opportunityInfo.businessState = this.coClinicDetails.opportunityInfo.businessState;
    target_obj.opportunityInfo.businessZip = this.coClinicDetails.opportunityInfo.businessZip;
    target_obj.opportunityInfo.clinicAgreementPk = this.coClinicDetails.opportunityInfo.clinicAgreementPk;
    this.clinicType = target_obj.opportunityInfo.clinicType = this.coClinicDetails.opportunityInfo.clinicType;
    target_obj.opportunityInfo.contractedStoreId = this.coClinicDetails.opportunityInfo.contractedStoreId;
    target_obj.opportunityInfo.firstName = this.clinicDetailsForm.value.firstName;
    target_obj.opportunityInfo.jobTitle = this.clinicDetailsForm.value.jobTitle;
    target_obj.opportunityInfo.lastName = this.clinicDetailsForm.value.lastName;
    target_obj.opportunityInfo.maxClinicLocationId = this.coClinicDetails.opportunityInfo.maxClinicLocationId;
    target_obj.opportunityInfo.naClinicAddlComments = this.clinicDetailsForm.value.naClinicAddlComments;
    if ([3, 4, 5, 7].indexOf(this.clinicType) > -1) {
      target_obj.opportunityInfo.naClinicSplBillingInstr = this.clinicDetailsForm.value.naClinicSplBillingInstr;
    }
    target_obj.opportunityInfo.naClinicGroupId = this.clinicDetailsForm.value.naClinicGroupId;
    target_obj.opportunityInfo.naClinicPlanId = this.clinicDetailsForm.value.naClinicPlanId;
    target_obj.opportunityInfo.naClinicSplBillingInstr = this.clinicDetailsForm.value.naClinicSplBillingInstr;;
    target_obj.opportunityInfo.outreachBusinessPk = this.coClinicDetails.opportunityInfo.outreachBusinessPk;
    // target_obj.opportunityInfo.pharmacistName = (this.coClinicDetails.opportunityInfo.pharmacistName == null && !this.clinicDetailsForm.value.pharmacistName) ? this.coClinicDetails.opportunityInfo.pharmacistName : this.clinicDetailsForm.value.pharmacistName;
    // target_obj.opportunityInfo.pharmacistPhone = (this.coClinicDetails.opportunityInfo.pharmacistPhone == null && !this.clinicDetailsForm.value.pharmacistPhone) ? this.coClinicDetails.opportunityInfo.pharmacistPhone : this.clinicDetailsForm.value.pharmacistPhone;
    this.coClinicDetails.opportunityInfo.pharmacistPhone = this.coClinicDetails.opportunityInfo.pharmacistPhone;
    target_obj.opportunityInfo.phone = this.clinicDetailsForm.value.phone;
    this.coClinicDetails.opportunityInfo.phone = this.coClinicDetails.opportunityInfo.phone;
    target_obj.opportunityInfo.recipientId = this.clinicDetailsForm.value.recipientId;
    target_obj.opportunityInfo.clinicFee = this.clinicDetailsForm.value.clinicFee;
    target_obj.opportunityInfo.isNoClinicFee = this.clinicDetailsForm.value.isNoClinicFee;
    target_obj.opportunityInfo.vouchersDistributed = this.clinicDetailsForm.value.vouchersDistributed;
    target_obj.opportunityInfo.vouchersRedeemed = this.clinicDetailsForm.value.vouchersRedeemed;
    target_obj.opportunityInfo.regionNumber = this.coClinicDetails.opportunityInfo.regionNumber;
    target_obj.opportunityInfo.storeState = this.coClinicDetails.opportunityInfo.storeState;
    // target_obj.opportunityInfo.totalHours = (this.coClinicDetails.opportunityInfo.totalHours == null && !this.clinicDetailsForm.value.totalHours) ? this.coClinicDetails.opportunityInfo.totalHours : this.clinicDetailsForm.value.totalHours;

    target_obj.opportunityInfo.naClinicCopay = this.coClinicDetails.opportunityInfo.naClinicCopay;
    target_obj.opportunityInfo.naClinicCoverageType = this.coClinicDetails.opportunityInfo.naClinicCoverageType;

    target_obj.opportunityInfo.childClinicAgreementApproved = this.coClinicDetails.opportunityInfo.childClinicAgreementApproved;
    target_obj.opportunityInfo.childClinicAgreementId = this.coClinicDetails.opportunityInfo.childClinicAgreementId;

    target_obj.clinicImmunizationList = this.coClinicDetails.clinicImmunizationList;

    target_obj.clinicList = this.clinicDetailsForm.value.clinicList;
    if (this.coClinicDetails.pData === "") {
      this.coClinicDetails.pData = this.pData;
    }
    target_obj.pData = this.pData;
    target_obj.errorS.errorCode = 0;
    target_obj.errorS.errorMessage = null;
    target_obj.clinicList.forEach(list => {
      list.naClinicContactPhone = list.naClinicContactPhone;
      if (list.clinicDate != null && list.clinicDate != '') {
        var c_date = new Date(list.clinicDate);
        list.clinicDate = c_date.getMonth() + 1 + "/" + c_date.getDate() + "/" + c_date.getFullYear();
      }
      else {
        list.clinicDate = null;
      }
      list.isCovidClinic = this.coClinicDetails.clinicList[0].isCovidClinic;
      list.isFluClinic = this.coClinicDetails.clinicList[0].isFluClinic;

      // conditions for isNOclinic
      if (this.coClinicDetails.clinicList[0].isNoClinic == null && list.isNoClinic == 0) {
        this.coClinicDetails.clinicList[0].isNoClinic = list.isNoClinic;
      }
      else if (this.coClinicDetails.clinicList[0].isNoClinic != list.isNoClinic) {
        list.isNOclinic = list.isNoClinic;
      }

      //  list.isNoClinic = list.isNoClinic?list.isNoClinic:this.coClinicDetails.clinicList[0].isNoClinic;//this.coClinicDetails.clinicList[0].isNoClinic
      //Bug 834 fix
      // if(typeof (list.visitType) == "string"){
      // list.visitType = list.visitType&&list.visitType.length>0?Number(list.visitType): this.coClinicDetails.clinicList[0].visitType == null ? null : list.visitType=Number(this.coClinicDetails.clinicList[0].visitType);
      // }
      // else {
      //   list.visitType = list.visitType?Number(list.visitType): this.coClinicDetails.clinicList[0].visitType == null ? null : list.visitType=Number(this.coClinicDetails.clinicList[0].visitType);
      // }
      //list.isDataPostedToCP = false;
      if (Number(list.apptSchedulerType) > 0) {

        list.isApptSchedulerEnabled = true;
      }
      // else if(Number(list.apptSchedulerType)<=0){
      //   list.isApptSchedulerEnabled=list.isApptSchedulerEnabled;
      // }
    });
    this.coClinicDetails.clinicList.forEach(list => {
      list.naClinicContactPhone = list.naClinicContactPhone;
      list.naClinicLocation = list.naClinicLocation && list.naClinicLocation.length > 0 ? list.naClinicLocation.trim() : list.naClinicLocation;
    });

    target_obj.clinicList[0].isDataPostedToCP = this.coClinicDetails.clinicList[0].isDataPostedToCP;
    target_obj.clinicList[0].reassignType = this.reassignForm.value.assignType;
    if ((this.reassignForm.value.assignType === "" || this.reassignForm.value.assignType === null || this.reassignForm.value.assignType.toLowerCase() === "geography")) {
      this.reassignForm.value.assignType = null;
      target_obj.clinicList[0].reassignedTo = null;
      target_obj.clinicList[0].isReassign = 0;
      if (this.reassignForm.value.assignType && this.reassignForm.value.assignType.toLowerCase() == "geography") {
        target_obj.clinicList[0].isReassign = 1;
      }
    }
    else {
      target_obj.clinicList[0].reassignedTo = this.reassignForm.value.assignType.toLowerCase() == "store" ? this.reassignForm.value.assignStoreID != null ? Number(this.reassignForm.value.assignStoreID.storeId) : null : Number(this.reassignForm.value.assignStore);
      target_obj.clinicList[0].isReassign = 1;
    }

    let is_clinical_req: boolean = false;
    for (let i = 0; i < this.clinicDetailsForm.value.clinicList.length; i++) {
      for (let j = 0; j < this.clinicDetailsForm.value.clinicImmunizationList.length; j++) {
        target_obj.clinicList[i].clinicImzQtyList[j].totalImmAdministered =
          this.clinicDetailsForm.value.clinicImmunizationList[j].totalImmAdministered;
          target_obj.clinicList[i].clinicImzQtyList[j].paperVars =
          this.clinicDetailsForm.value.clinicImmunizationList[j].paperVars;
        //Clinical Requirements -- START --
        if (ImmunizationsClinicalRequirements.immunizations.indexOf(this.clinicDetailsForm.value.clinicImmunizationList[j].immunizationName) > -1) {
          if (this.clinicDetailsForm.value.clinicList[i].isReassign !== 1 && (this.clinicDetailsForm.value.storeID === this.clinicDetailsForm.value.clinicList[i].clinicStoreId)) {
            target_obj.clinicList[i].clinicImzQtyList[j].isClinicalReqd = (ImmunizationsClinicalRequirements.immunizations.indexOf(this.clinicDetailsForm.value.clinicImmunizationList[j].immunizationName) > -1) ? this.clinicDetailsForm.value.clinicImmunizationList[j].isClinicalReqd : null;
            target_obj.clinicList[i].clinicImzQtyList[j].clinicalReqdApprovedBy = (target_obj.clinicList[i].clinicImzQtyList[j].isClinicalReqd) ? ((this.coClinicDetails.clinicList[i] && this.coClinicDetails.clinicList[i].clinicImzQtyList[j].clinicalReqdApprovedBy) ? this.coClinicDetails.clinicList[i].clinicImzQtyList[j].clinicalReqdApprovedBy : this.user_profile.userPk) : null;
            target_obj.clinicList[i].clinicImzQtyList[j].clinicalReqApprovedDate = (this.coClinicDetails.clinicList[i]) ? this.coClinicDetails.clinicList[i].clinicImzQtyList[j].clinicalReqApprovedDate : null;
            if (i === 0) {
              is_clinical_req = (this.coClinicDetails.clinicList[i] && (this.coClinicDetails.clinicList[i].clinicImzQtyList[j].isClinicalReqd !== target_obj.clinicList[i].clinicImzQtyList[j].isClinicalReqd)) ? true : false;
            }
          } else if ((this.clinicDetailsForm.value.clinicList[i].isReassign === 1 && (this.clinicDetailsForm.value.clinicList[i].isCompleted === 1 && !this.maintainStatusSelected) || (this.clinicDetailsForm.value.clinicList[i].isCompleted === 0)) || this.clinicDetailsForm.value.storeID !== this.clinicDetailsForm.value.clinicList[i].clinicStoreId) {
            target_obj.clinicList[i].clinicImzQtyList[j].isClinicalReqd = (ImmunizationsClinicalRequirements.immunizations.indexOf(this.clinicDetailsForm.value.clinicImmunizationList[j].immunizationName) > -1) ? 0 : null;
            target_obj.clinicList[i].clinicImzQtyList[j].clinicalReqdApprovedBy = null;
            target_obj.clinicList[i].clinicImzQtyList[j].clinicalReqApprovedDate = null;
          }
        }
        //Clinical Requirements -- END --
      }
      if (!i) {
        this.clinicDetailsForm.value.clinicList[i].confirmedClientName = (this.coClinicDetails.clinicList[i].confirmedClientName == null && !this.clinicDetailsForm.value.clinicList[i].confirmedClientName) ? this.coClinicDetails.clinicList[i].confirmedClientName : this.clinicDetailsForm.value.clinicList[i].confirmedClientName.trim();
        target_obj.clinicList[i].areApptsPresent = this.coClinicDetails.clinicList[i].areApptsPresent;// this should be from from get object which has index 0.
      }

    }

    target_obj['clinicalRequirements'] = (is_clinical_req) ? true : false;

    target_obj.clinicList[0].clinicScheduledOn = this.coClinicDetails.clinicList[0].clinicScheduledOn;
    // if ([1, 2, 6].indexOf(this.clinicType) > -1) {
    //   target_obj.clinicList[0].clinicRoom = this.coClinicDetails.clinicList[0].clinicRoom;
    // }
    target_obj.clinicList[0].isScheduled = this.coClinicDetails.clinicList[0].isScheduled;
    target_obj.clinicList[0].naClinicContactPhoneExt = this.coClinicDetails.clinicList[0].naClinicContactPhoneExt;
    target_obj.clinicList[0].naClinicLocation = this.coClinicDetails.clinicList[0].naClinicLocation;

    target_obj.clinicList[0].coPayFLUGroupId = this.is_flu_group_id == true ? this.clinicDetailsForm.value.coPayFLUGroupId : this.coClinicDetails.clinicList[0].coPayFLUGroupId;
    target_obj.clinicList[0].coPayROUTINEGroupId = this.is_routine_group_id == true ? this.clinicDetailsForm.value.coPayROUTINEGroupId : this.coClinicDetails.clinicList[0].coPayROUTINEGroupId;

    // for (let j = 0; j < target_obj.clinicList.length; j++) {
    for (let i = 0; i < target_obj.clinicList[0].clinicImzQtyList.length; i++) {
      target_obj.clinicList[0].clinicImzQtyList[i].estimatedQuantity =
        this.clinicDetailsForm.value.clinicImmunizationList[i].estimatedQuantity;

      target_obj.clinicList[0].clinicImzQtyList[i]["imzNameAndPmtMethod"] = target_obj.clinicList[0].clinicImzQtyList[i].immunizationName + "<br />" + target_obj.clinicList[0].clinicImzQtyList[i].paymentTypeName;

      target_obj.clinicList[0].clinicImzQtyList[i].showPrice =
        this.coClinicDetails.clinicList[0].clinicImzQtyList[i].showPrice;
      target_obj.clinicList[0].clinicImzQtyList[i].price =
        this.coClinicDetails.clinicList[0].clinicImzQtyList[i].price;
    }
    target_obj.feedback = (this.clinicDetailsForm.value.feedbackNotes) ? this.clinicDetailsForm.value.feedbackNotes : this.coClinicDetails.feedback;
  }

  makeLogHistoryItem = function (update_status, updated_value, group_name, item_alias_name) {
    var logHistory = new ClinicsUpdateHistoryLogList();
    logHistory.pk = 0;
    logHistory.updateAction = update_status;
    logHistory.updatedField = item_alias_name//corporateAliasName[0][opp[i]];
    logHistory.updatedValue = updated_value;
    logHistory.updateSubCategory = group_name;//"Billing & Vaccine Information:";
    logHistory.updatedBy = this.user_profile.email;
    logHistory.updatedOn = new Date();
    return logHistory;
  }

  isClinicDateReminderBefore2WksReq(action_status: string): Boolean {
    let isClinicDateReminder: Boolean = false;

    try {
      let today = new Date();
      let start_index = 0;
      if (action_status != null && (action_status.toLowerCase() == "completed" || action_status.toLowerCase() == "cancelled")) {
        if (this.clinicDetailsForm.value.clinicList.length > 1) {
          start_index = 1;
        }
      }
      for (let index = start_index; index < this.clinicDetailsForm.value.clinicList.length; index++) {
        const loc = this.clinicDetailsForm.value.clinicList[index];
        let clinicDate: Date = null;
        let initialClinicDate: Date = null;
        if (typeof (loc.clinicDate) == "string") {
          var dt = loc.clinicDate.split("/");

          clinicDate = new Date(dt[2], dt[0] - 1, dt[1]);
        } else {
          clinicDate = loc.clinicDate;
        }
        if (typeof (this.coClinicDetails.clinicList[0].clinicDate) == "string") {
          var idt = this.coClinicDetails.clinicList[0].clinicDate.split("/");
          initialClinicDate = new Date(parseInt(idt[2]), parseInt(idt[0]) - 1, parseInt(idt[1]));
        } else {
          initialClinicDate = this.coClinicDetails.clinicList[0].clinicDate;
        }
        if (Number(loc.isNoClinic) <= 0) {
          if (initialClinicDate != null && index <= 0 &&
            (initialClinicDate.getFullYear() !== clinicDate.getFullYear() || initialClinicDate.getMonth() !== clinicDate.getMonth() ||
              initialClinicDate.getDate() != clinicDate.getDate())) {
            // let diffDays: number = clinicDate.getMonth() == today.getMonth() ? Math.round(clinicDate.getDate() - today.getDate())
            //   : this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            //     new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
            var loc_date = (typeof clinicDate == 'string') ? new Date(clinicDate) : clinicDate;
            let diffDays: number = this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
              new Date(loc_date.getFullYear(), loc_date.getMonth(), loc_date.getDate(), 0, 0, 0));
            if (diffDays < 14) {
              isClinicDateReminder = true;
            }
          }
          else if (this.clinicDetailsForm.value.clinicList.length > 1 && index > 0) {
            // let diffDays: number = clinicDate.getMonth() == today.getMonth() ? Math.round(clinicDate.getDate() - today.getDate())
            // : this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            //   new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
            var loc_date = (typeof clinicDate == 'string') ? new Date(clinicDate) : clinicDate;
            let diffDays: number = this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
              new Date(loc_date.getFullYear(), loc_date.getMonth(), loc_date.getDate(), 0, 0, 0));
            if (diffDays < 14) {
              isClinicDateReminder = true;
            }
          }
          else if (initialClinicDate == null) {
            // let diffDays: number = clinicDate.getMonth() == today.getMonth() ? Math.round(clinicDate.getDate() - today.getDate())
            // : this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            //   new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
            var loc_date = (typeof clinicDate == 'string') ? new Date(clinicDate) : clinicDate;
            let diffDays: number = this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
              new Date(loc_date.getFullYear(), loc_date.getMonth(), loc_date.getDate(), 0, 0, 0));
            if (diffDays < 14) {
              isClinicDateReminder = true;
            }
          }
        }
      }
    } catch (e) {

      return e;
    }
    return isClinicDateReminder;
  }
  MinimumShotValidation(action_status: string, is_override?: boolean) {
    if ([3].indexOf(this.clinicType) > -1) {
      return true;
    }
    var errMsg: string[] = [];
    var estimatedQty = 0;
    var location25Names: string[] = [];
    var location250Names = [];
    var errMsg: string[] = [];
    var moreThan255ImmunizationError = false;

    for (let i = 0; i < this.clinicDetailsForm.value.clinicList.length; i++) {
      var estimatedQty = 0;
      this.userApprovedGt250 = false;
      this.userApprovedLt25 = false
      for (let j = 0; j < this.clinicDetailsForm.value.clinicList[i].clinicImzQtyList.length; j++) {
        let qty: number = +this.clinicDetailsForm.value.clinicList[i].clinicImzQtyList[j].estimatedQuantity;
        let vaccineName: string = this.clinicDetailsForm.value.clinicList[i].clinicImzQtyList[j].immunizationName;

        if ((qty >= 250) && vaccineName.search("Vaccine Voucher") === -1) {
          moreThan255ImmunizationError = true;
          location250Names.push({
            estimatedQuantity: qty, immunizationName: this.clinicDetailsForm.value.clinicList[i].clinicImzQtyList[j].immunizationName,
            location: this.clinicDetailsForm.value.clinicList[i].naClinicLocation
          });
        }
        else {
          if (qty < 250) {
            if (vaccineName.search("Vaccine Voucher") === -1) {
              estimatedQty += qty;
            }
          }
        }
      }

      if (estimatedQty < 50 && !moreThan255ImmunizationError && (
        this.clinicDetailsForm.value.clinicList[i].isNoClinic == null ||
        this.clinicDetailsForm.value.clinicList[i].isNoClinic == undefined ||
        Number(this.clinicDetailsForm.value.clinicList[i].isNoClinic) <= 0)) {
        //if(!this.isCovidVaccineAvailable || this.isNonCovidVaccineAvailable){                   
        if (this.coClinicDetails.opportunityInfo.covidOpportunityType == 1 && estimatedQty < 15 && this.clinicType !== 6) {
          location25Names.push(this.clinicDetailsForm.value.clinicList[i].naClinicLocation);
        }
        else if (this.coClinicDetails.opportunityInfo.covidOpportunityType !== 1 && this.clinicType !== 6) {
          location25Names.push(this.clinicDetailsForm.value.clinicList[i].naClinicLocation);
        }
        estimatedQty = 0;
        moreThan255ImmunizationError = false;
        //}
        // else if(this.isCovidVaccineAvailable && estimatedQty <1){  
        //     location25Names.push(this.clinicDetailsForm.value.clinicList[i].naClinicLocation);
        //     estimatedQty = 0;
        //     moreThan255ImmunizationError = false;    
        // }
      }
      else {
        estimatedQty = 0;
      }
      moreThan255ImmunizationError = false;
      if (!this.userApprovedGt250 && location250Names.length > 0) {
        this.userApprovedGt250 = false;
        this.userApprovedLt25 = false;
      }
      else {
        this.userApprovedGt250 = true;
      }
      if (!this.userApprovedLt25 && location25Names.length > 0) {
        this.userApprovedLt25 = false;
      }
      else {
        this.userApprovedLt25 = true;
      }

    }
    if (!is_override && !this.userApprovedGt250) {
      location250Names.forEach(item => {
        errMsg.push(String.Format(ErrorMessages['moreThan250ImmunizationsForDetails'], item.estimatedQuantity, item.immunizationName, item.location.replace(/CLINIC/g, ' LOCATION')));
      });
      this.showConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
      return false;
    }
    if (action_status !== "Completed") {
      if (!is_override && !this.userApprovedLt25) {
        // var userProfile = SessionDetails.GetUserProfile();
        //   if (userProfile.userRole.toLowerCase() == "admin") {
        if (this.coClinicDetails.opportunityInfo.covidOpportunityType == 1) {
          this.showConfirm25ImmDialog(ErrorMessages['MinNumberOfShotsForDetailsHeader'],
            String.Format([1, 5].indexOf(this.clinicType) > -1 ? ErrorMessages['MinNumberOfShotsForLTCFDetails'] : ErrorMessages['MinNumberOfShotsForLTCFDetails_2_7_6'], location25Names.join(',')));
        }
        else {
          this.showConfirm25ImmDialog(ErrorMessages['MinNumberOfShotsForDetailsHeader'],
            String.Format([1, 5].indexOf(this.clinicType) > -1 ? ErrorMessages['MinNumberOfShotsForDetails'] : ErrorMessages['MinNumberOfShotsForDetails_2_7_6'], location25Names.join(',')));
        }
        // }
        // else {
        //   this.showDialogV2(ErrorMessages['resolve'], String.Format(ErrorMessages['MinNumberOfShotsForDetails'], location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
        // }
        return false;
      }
      if (!is_override && !this.userApprovedGt250) {
        location250Names.forEach(item => {
          errMsg.push(String.Format(ErrorMessages['moreThan250ImmunizationsForDetails'], item.estimatedQuantity, item.immunizationName, item.location.replace(/CLINIC/g, ' LOCATION')));
        });
        this.showConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
        return false;
      }
      if (!is_override && !this.userApprovedLt25) {
        // var userProfile = SessionDetails.GetUserProfile();
        // if (userProfile.userRole.toLowerCase() == "admin") {
        if (this.coClinicDetails.opportunityInfo.covidOpportunityType == 1) {
          this.showConfirm25ImmDialog(ErrorMessages['resolve'],
            String.Format(ErrorMessages['MinNumberOfShotsForLTCFDetails'], location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
        }
        else {
          this.showConfirm25ImmDialog(ErrorMessages['resolve'],
            String.Format(ErrorMessages['MinNumberOfShotsForDetails'], location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
        }
        // }
        // else {
        //   this.showDialogV2(ErrorMessages['resolve'], String.Format(ErrorMessages['MinNumberOfShotsForDetails'], location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
        // }
      }
    }
    return true;
  }

  showConfirm250ImmDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.locConfirm250ModalDlg = true;
  }
  showConfirm25ImmDialog(msgSummary: string, msgDetail: string) {

    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.locConfirm25ModalDlg = true;
  }
  acceptLt25Imm() {

    this.userApprovedLt25 = true;

    this.locConfirm25ModalDlg = false;
    if (this.userApprovedGt250 && this.userApprovedLt25) {
      if (this.isAssignedStoreValid()) {
        this.saveToDB();
      }
      else {
        this.showReAssignStoreDialog(this.action_status);
      }
    }
  }
  acceptGt250Imm() {
    this.userApprovedGt250 = true;

    this.locConfirm250ModalDlg = false;
    if (this.userApprovedGt250) {
      if (this.isAssignedStoreValid()) {
        this.saveToDB();
      }
      else {
        this.showReAssignStoreDialog(this.action_status);
      }
    }
  }
  rejectLt25Imm() {
    this.locConfirm25ModalDlg = false;
    return;
  }
  rejectGt250Imm() {
    this.locConfirm250ModalDlg = false;
    return;
  }
  cancelBlockedSlotAlert() {
    this.showBlockedSlotAlert = false;

  }
  continueBlockedSlotAlert() {
    this.showBlockedSlotAlert = false;
    this.overrideBlockedSlotAlert = true;
    if (this.clinic_details_update.clinicContactStatus == "Submit") {
      this.saveChanges();
    }
    if (this.clinic_details_update.clinicContactStatus == "Cancelled") {
      this.cancelClinic();
    }
    if (this.clinic_details_update.clinicContactStatus == "Completed") {
      this.completeClinic();
    }
    if (this.clinic_details_update.clinicContactStatus == "Confirmed") {
      this.confirmClinic();
    }
  }
  dateTimeConflict(): boolean {
    var locConflictList = [];
    var errMsgV1: string[] = [];
    if ([3, 5, 7].indexOf(this.clinicType) > -1) {
      return true;
    }
    try {
      for (let count = 0; count < this.clinicDetailsForm.value.clinicList.length; count++) {
        try {
          let locClinicDate: Date = new Date(this.clinicDetailsForm.value.clinicList[count].clinicDate);
          let stTime = null;
          let locStartTime = null;
          try {
            if (this.clinicDetailsForm.value.clinicList[count].naClinicStartTime.search("am") !== -1 ||
              this.clinicDetailsForm.value.clinicList[count].naClinicStartTime.search("AM") !== -1 ||
              this.clinicDetailsForm.value.clinicList[count].naClinicStartTime.search("PM") !== -1 ||
              this.clinicDetailsForm.value.clinicList[count].naClinicStartTime.search("pm") !== -1) {
              locStartTime = this._timeToDatePipe.transform(locClinicDate, this.clinicDetailsForm.value.clinicList[count].naClinicStartTime);
            } else {
              stTime = new Date(this.clinicDetailsForm.value.clinicList[count].naClinicStartTime);
              locStartTime = this._timeToDatePipe.transform(locClinicDate, this._datePipe.transform(stTime, "shortTime"));
            }
          } catch (e) {
            stTime = new Date(this.clinicDetailsForm.value.clinicList[count].naClinicStartTime);
            locStartTime = this._timeToDatePipe.transform(locClinicDate, this._datePipe.transform(stTime, "shortTime"));
          }


          let enTime = null;
          let locEndTime = null;
          try {
            if (this.clinicDetailsForm.value.clinicList[count].naClinicEndTime.search("am") !== -1 ||
              this.clinicDetailsForm.value.clinicList[count].naClinicEndTime.search("AM") !== -1 ||
              this.clinicDetailsForm.value.clinicList[count].naClinicEndTime.search("PM") !== -1 ||
              this.clinicDetailsForm.value.clinicList[count].naClinicEndTime.search("pm") !== -1) {
              locEndTime = this._timeToDatePipe.transform(locClinicDate,
                this.clinicDetailsForm.value.clinicList[count].naClinicEndTime);
            } else {
              enTime = new Date(this.clinicDetailsForm.value.clinicList[count].naClinicEndTime);
              locEndTime = this._timeToDatePipe.transform(locClinicDate, this._datePipe.transform(enTime, "shortTime"));
            }
          } catch (e) {
            enTime = new Date(this.clinicDetailsForm.value.clinicList[count].naClinicEndTime);
            locEndTime = this._timeToDatePipe.transform(locClinicDate, this._datePipe.transform(enTime, "shortTime"));
          }
          if (this.clinicDetailsForm.value.clinicList[count].isNoClinic >= 1 ||
            this.clinicDetailsForm.value.clinicList[count].isNoClinic === '1' ||
            this.clinicDetailsForm.value.clinicList[count].isReassign >= 1 ||
            this.clinicDetailsForm.value.clinicList[count].isReassign === '1' ||
            this.clinicDetailsForm.value.clinicList[count].clinicStoreId != this.clinicDetailsForm.value.clinicList[0].clinicStoreId
            || (count == 0 && (this.reassignForm.value.assignType == "Store" || this.reassignForm.value.assignType == "Geogrpahy") && this.clinicDetailsForm.value.clinicList[0].clinicStoreId != this.reassignForm.value.assignStoreID.storeId)
            || (count == 0 && (this.reassignForm.value.assignType == "Area" || this.reassignForm.value.assignType == "District"))) {
            continue;
          }
          let locationsCpy = JSON.parse(JSON.stringify(this.clinicDetailsForm.value.clinicList));
          var index = locationsCpy.findIndex(it => it.naClinicLocation === this.clinicDetailsForm.value.clinicList[count].naClinicLocation);
          if (index > -1) {
            locationsCpy.splice(index, 1);
          }
          locationsCpy.forEach(element => {
            element.clinicDate = new Date(element.clinicDate);
          });
          let conflictLocs = locationsCpy.filter(locItem => locClinicDate.getFullYear() === locItem.clinicDate.getFullYear() && locClinicDate.getMonth() === locItem.clinicDate.getMonth() && locClinicDate.getDate() === locItem.clinicDate.getDate() && (locItem.isNoClinic <= 0 || locItem.isNoClinic === '0' || locItem.isNoClinic === undefined || locItem.isNoClinic === null) && (locItem.isReassign <= 0 || locItem.isReassign === '0' || locItem.isReassign === undefined || locItem.isReassign === null) && (locItem.clinicStoreId == this.clinicDetailsForm.value.storeID));
          let endDateAdd39Mts: Date;
          if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
            endDateAdd39Mts = this._timeToDatePipe.transform(locClinicDate, locEndTime);
          }
          else {
            endDateAdd39Mts = locEndTime;
          }
          let startDatetmToDt: Date;
          if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
            startDatetmToDt = this._timeToDatePipe.transform(locClinicDate, locStartTime);
          }
          else {
            startDatetmToDt = locStartTime;
          }
          let add30MinTS: Date = new Date(locClinicDate.getFullYear(), locClinicDate.getMonth(), locClinicDate.getDate(), endDateAdd39Mts.getHours(), endDateAdd39Mts.getMinutes() + 30, endDateAdd39Mts.getSeconds());
          let locStart: Date = new Date(startDatetmToDt);

          if (conflictLocs.length > 0) {
            var conflictLocsCpy = JSON.parse(JSON.stringify(conflictLocs));
            try {
              conflictLocsCpy.forEach(element => {
                element.clinicDate = new Date(element.clinicDate);
                if (element.naClinicStartTime.search("am") != -1 || element.naClinicStartTime.search("AM") != -1 ||
                  element.naClinicStartTime.search("pm") != -1 || element.naClinicStartTime.search("PM") != -1) {
                  element.naClinicStartTime = this._timeToDatePipe.transform(element.clinicDate, element.naClinicStartTime);
                }
                else {
                  let shrtTime = this._datePipe.transform(new Date(element.naClinicStartTime), "shortTime");
                  element.naClinicStartTime = this._timeToDatePipe.transform(element.clinicDate, shrtTime);
                }
                if (element.naClinicEndTime.search("am") != -1 || element.naClinicEndTime.search("AM") != -1 ||
                  element.naClinicEndTime.search("pm") != -1 || element.naClinicEndTime.search("PM") != -1) {
                  element.naClinicEndTime = this._timeToDatePipe.transform(element.clinicDate, element.naClinicEndTime);
                }
                else {
                  let shrtTime = this._datePipe.transform(new Date(element.naClinicEndTime), "shortTime");
                  element.naClinicEndTime = this._timeToDatePipe.transform(element.clinicDate, shrtTime);
                }
              });
            } catch (e) { console.log(e); }
            conflictLocs = conflictLocsCpy.filter(locItem => locClinicDate.getFullYear() === locItem.clinicDate.getFullYear() && locClinicDate.getMonth() === locItem.clinicDate.getMonth() && locClinicDate.getDate() === locItem.clinicDate.getDate() && ((locItem.naClinicStartTime.valueOf() >= locStart.valueOf() && locItem.naClinicStartTime.valueOf() < add30MinTS.valueOf()) || (locItem.naClinicEndTime.valueOf() > locStart.valueOf() && locItem.naClinicEndTime.valueOf() < add30MinTS.valueOf())));
            if (conflictLocs.length > 0) {
              locConflictList.push({
                srcLocName: this.clinicDetailsForm.value.clinicList[count],
                dstLocName: conflictLocs
              });

            }
          }
        }
        catch (e) {
          console.log(e);
        }
      }
      let conflictClinicList: any[] = [];
      locConflictList.forEach(item => {
        item.dstLocName.forEach(element => {
          let duplicateEntry = conflictClinicList.filter(l => l.naClinicLocation == element.naClinicLocation);
          if (duplicateEntry.length <= 0) {
            conflictClinicList.push(element);
          }
        });

      });

      if (conflictClinicList.length > 0) {
        conflictClinicList.sort((leftItem, rightItem): number => {
          if (leftItem.naClinicLocation < rightItem.naClinicLocation) return -1;
          if (leftItem.naClinicLocation > rightItem.naClinicLocation) return 1;
          return 0;
        });
        if (conflictClinicList.length <= 2 && this.clinicDetailsForm.value.clinicList.length <= 2) {
          if (conflictClinicList.length == 2) {
            errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeSingleConflict'], conflictClinicList[1].naClinicLocation.replace(/CLINIC/g, 'LOCATION'), this._datePipe.transform(conflictClinicList[1].clinicDate, "shortDate"), this._datePipe.transform(conflictClinicList[1].naClinicStartTime, 'shortTime'), this._datePipe.transform(conflictClinicList[1].naClinicEndTime, 'shortTime'), this.coClinicDetails.opportunityInfo.businessName));
          } else {
            errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeSingleConflict'], conflictClinicList[0].naClinicLocation.replace(/CLINIC/g, 'LOCATION'), this._datePipe.transform(conflictClinicList[0].clinicDate, "shortDate"), this._datePipe.transform(conflictClinicList[0].naClinicStartTime, 'shortTime'), this._datePipe.transform(conflictClinicList[0].naClinicEndTime, 'shortTime'), this.coClinicDetails.opportunityInfo.businessName));
          }
          this.showDialogV2(ErrorMessages['resolve'], errMsgV1.join('<br/><br/>'));
          return false;
        }
        if (conflictClinicList.length >= 1) {
          conflictClinicList.forEach(loc => {
            errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeMultipleConflict'], loc.naClinicLocation.replace(/CLINIC/g, 'LOCATION'), this._datePipe.transform(loc.clinicDate, "shortDate"), this._datePipe.transform(loc.naClinicStartTime, 'shortTime'), this._datePipe.transform(loc.naClinicEndTime, 'shortTime'), this.coClinicDetails.opportunityInfo.businessName));
          });
          this.showDialogV2(ErrorMessages['resolve'], String.Format("{0}{1}", ErrorMessages['multipleClinicDatesConflictLineItem'], errMsgV1.join('<br/><br/>')));
          return false;
        }
      }
      return true;
    } catch (e) {

      console.log(e);
    }

    return true;
  }

  showDialogV2(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.locListModalDlg = true;
  }

  okClickedV2() {
    this.locListModalDlg = false;
  }
  showErrDialogThenGoToHome(msgSummary: string, msgDetail: string) {
    this.dialogSummary = ErrorMessages['clinicDetailsErrHeader'];
    this.dialogMsg = msgDetail;
    this.getDetailsErr = true;
  }
  goToHome(action: string = '') {
    if (action == "scheduledappointments") {
      this.Action = '';
      this.showAfterClinicDelete = false;
    }
    else
      this.router.navigateByUrl("/communityoutreach/storehome");
  }
  goToStoreHome() {
    window.location.href = '/communityoutreach/storehome';
  }
  download(value: any) {

    var voucher_form_data = new VoucherFormData();
    voucher_form_data.outreachBusinessPk = this.coClinicDetails.opportunityInfo.outreachBusinessPk;
    voucher_form_data.clinicPk = this.coClinicDetails.clinicList[0].clinicPk;
    voucher_form_data.downloadType = value;
    voucher_form_data.pdfPath = "";

    voucher_form_data.covidOpportunityType = this.coClinicDetails.opportunityInfo.covidOpportunityType;
    if (this.coClinicDetails.opportunityInfo.naClinicGroupId.includes("pending copay group id")) {
      this.showDialogV2("Message", "This document cannot be downloaded until a Group ID is assigned");
      return;
    }
    this.coClinicdetailsService.getContractVoucherFormData(voucher_form_data).subscribe((data: any) => {
      var res = data;
      if (res.pdfPath != "") {
        let url = /*environment.WalgreenDomain*/ res.pdfPath;
        localStorage.setItem("isApp", "yes");
        window.open(url);
      }

    });
  }

  getModelDiff() {

    let temp: any = JSON.stringify(this.coClinicDetails);
    temp = JSON.parse(temp);

    this.updateDataFromFormFields(temp);
    temp.clinicList[0].privateCPSchedulerLink = this.coClinicDetails.clinicList[0].privateCPSchedulerLink;
    temp.clinicList.forEach(item => {
      try {
        item.naClinicLocation = [1, 2, 6].indexOf(this.clinicType) > -1 ? item.naClinicLocation.toUpperCase().replace('CLINIC ', '').replace('LOCATION ', '') : item.naClinicLocation;
        delete item['noSlotsOfBlocked'];
        item.clinicImzQtyList.forEach(imzQtyItem => {
          delete imzQtyItem["imzNameAndPmtMethod"];
        });
      } catch (e) {
        console.log('save changes' + e);
      }
    });
    delete temp['clinicalRequirements'];
    delete temp['opportunityInfo'].naClinicGroupId
    let obj1 = this.deepCopy(this.coClinicDetails);
    obj1.clinicList.forEach(item => {
      try {
        delete item['noSlotsOfBlocked'];
        item.naClinicLocation = [1, 2, 6].indexOf(this.clinicType) > -1 ? item.naClinicLocation.toUpperCase().replace('CLINIC ', '').replace('LOCATION ', '') : item.naClinicLocation;
        item.clinicImzQtyList.forEach(imzQtyItem => {
          delete imzQtyItem["imzNameAndPmtMethod"];
        });
        temp.clinicList[0].isApptSchedulerEnabled = obj1.clinicList[0].isApptSchedulerEnabled;
      } catch (e) {
        console.log('save changes' + e);
      }
    });
    delete obj1['clinicalRequirements'];
    delete obj1['opportunityInfo'].naClinicGroupId
    //let diff = this.utility.getDifference(obj1, temp);
    let diff = this.utility.getObjDiff(obj1, temp);
    if (this.savedViaReassignBtn) {
      diff = {};
    }
    if ((obj1.clinicList[0].naClinicStartTime && obj1.clinicList[0].naClinicStartTime.toString().toLowerCase().search('12:00 am') !== -1 && temp.clinicList[0].naClinicStartTime.toString().toLowerCase().search('00:00 am') !== -1) ||
      (obj1.clinicList[0].naClinicStartTime && obj1.clinicList[0].naClinicStartTime.toString().toLowerCase().search('12:30 am') !== -1 && temp.clinicList[0].naClinicStartTime.toString().toLowerCase().search('00:30 am') !== -1)) {
      diff = {};
    }
    return diff;
  }

  deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
      newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (var i in oldObj) {
        newObj[i] = this.deepCopy(oldObj[i]);
      }
    }
    return newObj;
  }

  leaveRoute() {
    this.showCanRouteChange = false;
    this.messageService.setCanShowRouteChangeConfirmation(false);
    this.messageService.setCanRouteChange(true);
  }
  cancelRoute() {
    this.showCanRouteChange = false;
    this.messageService.setCanShowRouteChangeConfirmation(false);
    this.messageService.setCanRouteChange(false);
  }

  updateLabel(obj: any) {
    obj.value["imzNameAndPmtMethod"] = obj.value.immunizationName + "<br />" + obj.value.paymentTypeName;

    let estimatedQty = obj.controls.estimatedQuantity.value;
    let totalImmAdmintered = obj.controls.totalImmAdministered.value;
    let paperVars = obj.controls.paperVars.value;

    // let estimatedQty = obj.controls.estimatedQuantity.value;
    let frmGrps = this.clinicDetailsForm.controls.clinicImmunizationList['controls'].filter(p => p.controls.immunizationPk.value == obj.controls.immunizationPk.value);
    frmGrps.forEach((element, index) => {
      if (index > 0) {
        element.patchValue({
          estimatedQuantity: 0
        });
        element.patchValue({
          totalImmAdministered: 0
        });
      }
    });
  }

  updateEstQty(obj: any, clinicIndex) {
    // let estimatedQty = obj.controls.estimatedQuantity.value;
    let frmar = this.clinicDetailsForm.controls.clinicList['controls'] as FormArray;
    let frmGrp = frmar[clinicIndex] as FormGroup;
    let clinicImzQtyListArr: any = frmGrp.controls['clinicImzQtyList'] as FormArray;
    let matchedImms = clinicImzQtyListArr.controls.filter(p => p.controls['immunizationPk'].value == obj.controls.immunizationPk.value);
    matchedImms.forEach((element, index) => {
      // if(this.clinicType == 13)
      // {
      //   if( element.controls.immunizationPk.value ==31 && element.controls.estimatedQuantity.value >0 )
      //   {
      //     frmar[clinicIndex].controls['vouchersDistributed'].setValue(element.controls.estimatedQuantity.value);
      //     this.shouldDisableVouchersDistributed = true;
      //     frmar[clinicIndex].controls['vouchersDistributed'].markAsUntouched();
      //     frmar[clinicIndex].controls['vouchersDistributed'].markAsPristine(); 
      //     return;
      //   }
      // }

      if (index > 0) {
        element.patchValue({
          estimatedQuantity: 0
        });
      }
    });
  }
  doesClinicsHaveNoClinicAndMiscOptions(): boolean {
    let hasCorpInvNdVchNd: boolean = this.checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded();
    let hasNoClinicAndMiscOptions: boolean = false;

    this.clinicDetailsForm.value.clinicList.forEach(element => {
      let isNoClinic: boolean = false;
      element.isNoClinic >= 1 && hasCorpInvNdVchNd ? isNoClinic = true : isNoClinic = false;
      hasNoClinicAndMiscOptions = hasNoClinicAndMiscOptions || isNoClinic;
    });
    return hasNoClinicAndMiscOptions;
  }
  checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded() {
    try {
      let hasCorpInvoiceNdVchrNd: Boolean = false;
      this.coClinicDetails.clinicImmunizationList.forEach(rec => {

        if (Number(rec.paymentTypeId) == 13 &&
          (rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1)) {
          hasCorpInvoiceNdVchrNd = true;
        }
      });
      if (hasCorpInvoiceNdVchrNd == true)
        return true;
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }

  getPrevSiblingInHist(obj: any, index: number, inner_index: number, value: any) {
    try {
      if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length < 40)
        return 'minHeight65';
      else if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 500) {
        return 'enableScroller';
      }
      else if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 400) {
        return 'minHeight150';
      }
      else if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 300) {
        return 'minHeight100';
      }
      else if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 80) {
        return 'minHeight110';
      }
      else if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 60) {
        return 'minHeight90';
      }
      else if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 41) {
        return 'minHeight65';
      }
      else
        return '';
    }
    catch (e) {
      return '';
    }
  }

  getNextSiblingInHist(obj: any, index: number, inner_index: number) {
    try {
      if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length < 40)
        return 'minHeight65';
      else if ((obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 500)) {
        return 'enableScrollerHeight';
      }
      else if ((obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 400)) {
        return 'minHeight150';
      }
      else if ((obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 300)) {
        return 'minHeight100';
      }
      else if ((obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 80)) {
        return 'minHeight110';
      }
      else if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 60) {
        return 'minHeight90';
      }
      else if ((obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].length > 41)) {
        return 'minHeight65';
      }
      else
        return '';
    }
    catch (e) {
      return '';
    }
  }

  enableDisableClinicDates() {
    var userProfile = SessionDetails.GetUserProfile();
    let today = new Date();
    if (this.utility.isStoreIsfromRestrictedState()) {
      if (userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "pharmacy manager") {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);// + 14
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);// + 14
      }
      else {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
      }
      if (ClinicDetailsUtilities.checkIfAnyFluImmIsSelected(this.coClinicDetails)) {
        this.maxDateValue = new Date(today.getFullYear() + 1, 3, 1, 12, 55, 55);
      }
      else {
        this.maxDateValue = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate() - 1, 12, 55, 55);
      }
    }
  }

  onBlurMethod(i: number) {
    let controlclinicList = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    let address1: string = controlclinicList.controls[i].get('naClinicAddress1').value;
    let address2: string = controlclinicList.controls[i].get('naClinicAddress2').value;
    let city: string = controlclinicList.controls[i].get('naClinicCity').value;
    let state: string = controlclinicList.controls[i].get('naClinicState').value;
    let zipCode: string = controlclinicList.controls[i].get('naClinicZip').value;
    let address = address1 + ", " + address2 + ", " + city + ", " + state + " " + zipCode;
    this.getLatitudeLongitude(address, i);

    let isReassignGeographySelected: boolean = controlclinicList.controls[i].get('isReassign').value;
    let reAssigAllowed = this.utility.IsReassignmentAllowedForRestrictedState(state);
    if (isReassignGeographySelected && !reAssigAllowed) {
      let dialogMsg = state == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20");
      if (i > 0) {
        controlclinicList.controls[i].get('naClinicState').setValue('');
      }
      else {
        controlclinicList.controls[i].get('naClinicState').setValue(this.coClinicDetails.clinicList[0].naClinicState);
      }
      this.showDialogV2("Alert", dialogMsg);
    }
  }

  //geocoder to get the latitude and longitude 
  getLatitudeLongitude(fulladdress, index) {

    let controlclinicList = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': fulladdress }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        controlclinicList.controls[index].get('clinicLatitude').setValue(results[0].geometry.location.lat());
        controlclinicList.controls[index].get('clinicLongitude').setValue(results[0].geometry.location.lng());
      }
    });

  }
  //end geocoder 
  AllowAdminsToEditDataForRestrictedState() {
    return this.utility.AllowAdminsToEditDataForRestrictedState();
  }
  isStoreIsfromRestrictedState() {
    return this.utility.isStoreIsfromRestrictedState();
  }
  scrolling() {
    window.scrollTo(0, 0);
  }
  //geocoder to get the latitude and longitude
  getAddr(idx, addr, sourceobject, result) {
    sourceobject.clinicList[idx].latitude = result.lat();
    sourceobject.clinicList[idx].longitude = result.lng();
  };

  OnAmendAgreementClick() {

    let control = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
    let index;
    for (index = 0; index < control.length; index++) {
      if (this.reassignForm.value.assignType !== null && this.reassignForm.value.assignType !== "") {
        control.controls[index].get('isClinicalReqd').setValidators(null);
        control.controls[index].get('isClinicalReqd').updateValueAndValidity();
      }
    }

    sessionStorage["AmendAgreement"] = "true";
    if (this.coClinicDetails.opportunityInfo.childClinicAgreementId != null && this.coClinicDetails.opportunityInfo.childClinicAgreementId > 0) {
      sessionStorage["NewAmendAgreement"] = "false"
      SessionDetails.SetAgreementPK(this.coClinicDetails.opportunityInfo.childClinicAgreementId);
      SessionDetails.setOpportunitiesData(JSON.stringify(this.coClinicDetails.opportunityInfo));
      this.router.navigateByUrl("/communityoutreach/contract");
    }
    else {
      sessionStorage["NewAmendAgreement"] = "true"
      SessionDetails.SetAgreementPK(this.coClinicDetails.opportunityInfo.clinicAgreementPk);
      this.isAmendAgreementPopUp = true;
    }
  }

  OnAmendAgreementForCommOutreachClick() {

    let control = <FormArray>this.clinicDetailsForm.controls['clinicImmunizationList'];
    let index;
    for (index = 0; index < control.length; index++) {
      if (this.reassignForm.value.assignType !== null && this.reassignForm.value.assignType !== "") {
        control.controls[index].get('isClinicalReqd').setValidators(null);
        control.controls[index].get('isClinicalReqd').updateValueAndValidity();
      }
    }
    sessionStorage["AmendAgreementForCommOutreach"] = "true";
    if (this.coClinicDetails.opportunityInfo.childClinicAgreementId != null && this.coClinicDetails.opportunityInfo.childClinicAgreementId > 0) {
      sessionStorage["NewAmendAgreement"] = "false"
      SessionDetails.SetAgreementPK(this.coClinicDetails.opportunityInfo.childClinicAgreementId);
      SessionDetails.setOpportunitiesData(JSON.stringify(this.coClinicDetails.opportunityInfo));

      if (this.coClinicDetails.opportunityInfo.clinicType == 13) {
        this.pageName = 'quity Event Clinic Details';
        this.router.navigateByUrl("/communityoutreach/equityevents");
      }
      else {
        this.router.navigateByUrl("/communityoutreach/communityoutreach");
      }

    }
    else {
      sessionStorage["NewAmendAgreement"] = "true"
      SessionDetails.SetAgreementPK(this.coClinicDetails.opportunityInfo.clinicAgreementPk);
      this.isAmendAgreementCommOutreachPopUp = true;
      sessionStorage["IsEquityAmendAgreement"] = "true";
    }
  }

  CancelAmendAgreement() {
    this.isAmendAgreementPopUp = false;
  }
  CancelAmendAgreementCommOutreach() {
    this.isAmendAgreementCommOutreachPopUp = false;
  }

  ContinueAmendAgreement() {
    this.isAmendAgreementPopUp = false;
    SessionDetails.setOpportunitiesData(JSON.stringify(this.coClinicDetails.opportunityInfo));
    if (this.coClinicDetails.opportunityInfo.clinicType == 13) {
      this.router.navigateByUrl("/communityoutreach/equityevents");
    }
    else if (this.coClinicDetails.opportunityInfo.clinicType == 6) {
      this.router.navigateByUrl("/communityoutreach/communityoutreach");
    }
    else {
      this.router.navigateByUrl("/communityoutreach/contract");

    }
  }

  ContinueAmendAgreementCommOutreach() {
    this.isAmendAgreementPopUp = false;
    SessionDetails.setOpportunitiesData(JSON.stringify(this.coClinicDetails.opportunityInfo));
    if (this.coClinicDetails.opportunityInfo.clinicType == 13) {
      this.router.navigateByUrl("/communityoutreach/equityevents");
    }
    else if (this.coClinicDetails.opportunityInfo.clinicType == 6) {
      this.router.navigateByUrl("/communityoutreach/communityoutreach");
    }
    else {
      this.router.navigateByUrl("/communityoutreach/contract");

    }
  }

  checkRestrictedStatesCompliance(index: number) {
    let reAssignedStoreId = this.clinicDetailsForm.controls.clinicList['controls'][index].get('clinicStoreId').value;
    if (reAssignedStoreId.trim().length > 0) {
      //get restricted store state using userPk 41, this is necessary as store date is not fetched for non authorized users, but as per business req, an rxm can assign.
      this._headerservice.storeSearch(41, reAssignedStoreId.trim(), 0).subscribe(data => {
        if (reAssignedStoreId.trim() != SessionDetails.GetStoreId()) {
          let returnValue = true;
          var filterSearchRes = data.filter(store => store.storeId == reAssignedStoreId);
          if (this.utility.IsReassignmentAllowedForRestrictedState(filterSearchRes[0].state)) {
            returnValue = true;;
          }
          else if (SessionDetails.GetState() == "MO") {

            if (filterSearchRes.length > 0 && filterSearchRes[0].state == "DC") {
              returnValue = false;
            }
          }
          else if (SessionDetails.GetState() == "DC") {
            if (filterSearchRes.length > 0 && filterSearchRes[0].state == "MO") {
              returnValue = false;
            }
          }
          else if (SessionDetails.GetState() != "MO" || SessionDetails.GetState() != "DC") {
            if (filterSearchRes.length > 0 && (filterSearchRes[0].state == "MO" || filterSearchRes[0].state == "DC")) {
              returnValue = false;
            }
          }
          if (!returnValue) {
            let dialogMsg = filterSearchRes[0].state == "DC" ? String.Format(ErrorMessages['restrictedStates'], "Columbia ", "15") : String.Format(ErrorMessages['restrictedStates'], "Missouri ", "20")
            this.clinicDetailsForm.controls.clinicList['controls'][index].get('clinicStoreId').setValue(this.coClinicDetails.clinicList[0].clinicStoreId);
            this.showDialogV2("Alert", dialogMsg);
            this.clinicDetailsForm.controls.clinicList['controls'][index].updateValueAndValidity();
          }
        }
      });
    }
  }
  onvisitTypeClicked(locationForm: any) {
  }
  onOtherRadioClicked(locationForm: any) {
    if (locationForm.controls['coOutreachTypeId'].value === 4) {
      locationForm.controls['coOutreachTypeDesc'].enable();
      locationForm.controls['coOutreachTypeDesc'].setValidators([Validators.required]);
      locationForm.controls['coOutreachTypeDesc'].updateValueAndValidity();
    }
    else {
      locationForm.controls['coOutreachTypeDesc'].setValidators(null);
      locationForm.controls['coOutreachTypeDesc'].setErrors(null);
      locationForm.controls['coOutreachTypeDesc'].updateValueAndValidity();
      locationForm.controls['coOutreachTypeDesc'].disable();
    }
  }

  onOtherTextChanged(obj: any) {
    obj.value.coOutreachTypeId = obj.value.coOutreachTypeId + "_U";
  }

  displayFieldCssOutReachType(field: string, i: number) {
    try {
      const control = <FormArray>this.clinicDetailsForm.controls['clinicList'];
      const outReach = control.controls[i].get(field);
      let return_val = !outReach.valid && outReach.touched;
      return {
        'has-error': return_val,
        'has-feedback': return_val
      };

    } catch (e) {
      console.log(e);
    }
  }

  isAdmin() {
    if ([1, 2, 3, 4, 5, 7].indexOf(this.clinicType) > -1) {
      let userProfile = SessionDetails.GetUserProfile();
      if (userProfile != null && userProfile.userRole.toLowerCase() == 'admin') {
        return true;
      }
    }
    return false;
  }

  showUpdateClinicDetailsRspError(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.errorMsgOverride = true;
  }

  showClinicLocationDetailsSaveChangesError(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.errorMsgSaveChanges = true;
  }

  ErrorMsgContinueClicked() {
    this.errorMsgOverride = false;
    this.errorMsgSaveChanges = false;
    this.HandleUserResponse();
  }

  ErrorMsgSaveChangesClicked() {
    this.errorMsgSaveChanges = false;
    this.HandleUserResponse();
  }

  HandleUserResponse() {
    switch (this.rescheduledErrorCode) {
      case -5:
        this.clinic_details_update.isSendLocationChangeEmailToAppts = false;
        this.clinic_details_update.isSendRescheduleEmailToAppts = true;
        break;
      case -6:
        this.clinic_details_update.isSendLocationChangeEmailToAppts = true;
        this.clinic_details_update.isSendRescheduleEmailToAppts = false;
        break;
      default:
        this.clinic_details_update.isSendLocationChangeEmailToAppts = false;
        this.clinic_details_update.isSendRescheduleEmailToAppts = false;
        break;
    }
    this.scrolling();
    this.spinner.show();
    this.coClinicdetailsService.upDateClinicDetails(this.coClinicDetails.clinicList[0].clinicPk,
      this.clinic_details_update, this.isOverride).subscribe((res: any) => {
        this.spinner.hide();
        this.rescheduledErrorCode = 0;
        //if (res.status == "200") {
        let msg = ErrorMessages['clinicSave'];
        if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
          msg = ErrorMessages['clinicSavedWithSchdlr']
        }
        else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
          msg = ErrorMessages['clinicSavedWithCPSchdlr'];
        }
        if (this.action_status != null) {
          if (this.action_status.toLowerCase() == 'cancelled') {
            msg = ErrorMessages['clinicCancel'];
          } else if (this.action_status.toLowerCase() == 'confirmed') {
            msg = ErrorMessages['clinicConfirm'];
            if (this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
              if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1) {
                msg = ErrorMessages['clinicConfirmWithSchdlr'];
              }
              else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1) {
                msg = ErrorMessages['clinicConfirmWithCPSchdlr'];
              }
            }
          } else if (this.action_status.toLowerCase() == 'completed') {
            msg = ErrorMessages['clinicComplete'];
            this.IsAmendSubmit = false;
            if ([1].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1 && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && this.clinic_details_update.clinicList[0].isApptSchedulerEnabled && !this.coClinicDetails.clinicList[0].isScheduled) {
              msg = ErrorMessages['clinicCompleteWithSchdlr'];
            }
            else if ([2, 3].indexOf(this.clinic_details_update.clinicList[0].apptSchedulerType) > -1) {
              msg = ErrorMessages['clinicCompleteWithCPSchdlr'];
            }
          }
        }
        if (res.errorS != null) {
          if (res.errorS.errorCode != 0) {
            let errorCode = res.json().errorS.errorCode;
            let errorMessage = res.json().errorS.errorMessage;
            switch (res.errorS.errorCode) {
              case -4:
                let sumMsg = ErrorMessages['eventDateTimeConflict'];
                let finalErrMsg = String.Format("{0}<br/><br/>{1}", res.json().errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']);
                this.OverrideOrOkTitle = "Override";
                this.showCancelBtn = true;
                this.confirm(sumMsg, finalErrMsg, this.clinic_details_update.clinicContactStatus, true);
                break;
              case -6:
                this.rescheduledErrorCode = errorCode;
                this.showClinicLocationDetailsSaveChangesError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsLocationChangedWarning']);
                break;
              case -5:
                //   if (this.isAdmin()) {                 
                this.rescheduledErrorCode = errorCode;
                this.showUpdateClinicDetailsRspError(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsChangedWarning'].replace('{1}', errorMessage.replace(',', ', ')));
              // }
              // else {
              //   this.showDialogV2(ErrorMessages['CorpClinicDetailsRspErrorSummary'], ErrorMessages['CorpClinicDetailsChangedErrMsg'].replace('{1}', errorMessage.replace(',', ', ')));
              // }
              case -7:
                this.showDialog(ErrorMessages['clinicDetails'], errorMessage);
                break;

              default:
                this.showDialogV2(ErrorMessages['CorpClinicDetailsRspErrorSummary'], errorMessage);
                break;
            }
            return;
          }
          else {
            this.isOverride = false;
            this.showDialog(ErrorMessages['clinicDetails'], msg);
            window.scrollTo(0, 0);
          }
        }
        else {
          this.clinic_details_update.isSendLocationChangeEmailToAppts = false;
          this.clinic_details_update.isSendRescheduleEmailToAppts = false;
          this.showDialog(ErrorMessages['clinicDetails'], msg);
          window.scrollTo(0, 0);
        }

      },
        err => {
          this.isOverride = false;
          this.rescheduledErrorCode = 0;
          this.spinner.hide();
          this.showDialogV2(ErrorMessages['serverError'], err.Message != null && err.Message != undefined ? err.Message : err.statusText);
        });
  }

  ErrorMsgCancelClicked() {
    this.errorMsgOverride = false;
    this.rescheduledErrorCode = 0;
    this.isOverride = false;
    this.bindLocalClinicData();
  }

  ErrorMsgSaveChangesCancelClicked() {
    this.errorMsgSaveChanges = false;
    this.rescheduledErrorCode = 0;
    this.bindLocalClinicData();
  }
  getSchedulerDisabledStatus(index: number, locationForm: any) {
    if (index === 0 && this.isApptSchedulerDisabled && !this.isSuperAdmin) {
      return true;
    }
    else if (index > 0) {
      return null;
    }
    else {
      return null;
    }
  }

  uncheckCOPRadio(locationForm: any, event) {
    let schedulerType: any = locationForm.controls['apptSchedulerType'].value
    if(!this.isSuperAdmin){
    if (schedulerType == 1) {
      this.clinicDetailsForm.controls.clinicList['controls'][0].get('isApptSchedulerEnabled').setValue(false);
      locationForm.controls['apptSchedulerType'].patchValue(0);
      schedulerType = 0
      this.cOPForceUncheck = true;
    }
    else if (typeof schedulerType == 'object' && schedulerType == 1) {
      this.clinicDetailsForm.controls.clinicList['controls'][0].get('isApptSchedulerEnabled').setValue(false);
      locationForm.controls['apptSchedulerType'].patchValue(0);
      schedulerType = 0
      this.cOPForceUncheck = true;
    }
    else if (typeof schedulerType == 'object' && (schedulerType == null || schedulerType == 0)) {
      schedulerType = 1
      locationForm.controls['apptSchedulerType'].patchValue(1);
      this.cOPForceUncheck = false;
    }
    else if (typeof schedulerType != 'object' && (schedulerType == null || schedulerType == 0)) {
      schedulerType = 1
      locationForm.controls['apptSchedulerType'].patchValue(1);
      this.cOPForceUncheck = false;
    }
  } else if(this.isSuperAdmin) {
    locationForm.controls['apptSchedulerType'].patchValue(1);
  }
    this.showAppointmentScheduler = schedulerType == 1 && this.isEncryptLinkNull;
    if (schedulerType <= 0 && this.coClinicDetails.clinicList[0].isScheduled) {
      this.showDialogV2("Appointment Scheduler", ErrorMessages['turnOffApptSchdWarning']);
    }
    if (schedulerType == 2 && !this.is_admin)
      this.disableTotalAdministered = true;
    else
      this.disableTotalAdministered = null;
    event.preventDefault();
  }

  onScheduleChange(locationForm: any) {
    // 
    if (this.cOPForceUncheck) {
      locationForm.controls['apptSchedulerType'].patchValue({ apptSchedulerType: null });
      this.cOPForceUncheck = false;
    }
    let schedulerType: number = locationForm.controls['apptSchedulerType'].value;
    this.showAppointmentScheduler = schedulerType == 1 && this.isEncryptLinkNull;
    if (schedulerType <= 0 && this.coClinicDetails.clinicList[0].isScheduled) {
      this.showDialogV2("Appointment Scheduler", ErrorMessages['turnOffApptSchdWarning']);
    }
    if (schedulerType == 2 && !this.is_admin)
      this.disableTotalAdministered = true;
    else
      this.disableTotalAdministered = null;
    // if(schedulerType==1 || schedulerType==2){
    //   this.clinicDetailsForm.controls['isApptSchedulerEnabled'].setValue(true);
    // }
    // else {
    //   this.clinicDetailsForm.controls['isApptSchedulerEnabled'].setValue(null);
    // }
  }
  copyEncryptedLink(linkText) {
    this.copyText = document.getElementById(linkText);
    this.copyText.select();
    document.execCommand("copy");
  }
  accessCPScheduler() {
    window.open("https://vaccine.walgreens.com/login");
  }
  accessScheduler() {
    let today = new Date();
    if (!this.accessSchedulerEncryptedLink) {
      return;
    }

    // if( (new Date (this.coClinicDetails.clinicList[0].clinicDate)) < new Date(today.getFullYear(),today.getMonth(),today.getDate()) && this.coClinicDetails.clinicList[0].isScheduled){
    //   this.showDialogV2("Scheduler", "Scheduler is not available for past clinics.");
    //   return;
    // }
    //sessionStorage.removeItem("registrationDetails");
    this.navigateToSchedulerSites("schedulerLanding");
  }
  accessTracker() {
    if (!this.accessSchedulerTrackerEncryptedLink) {
      return;
    }
    this.navigateToSchedulerSites("clientSchedulerApptTracker");
  }
  navigateToSchedulerSites(page_name: string) {
    var encrypt_data = new EncryptDecrypt();
    encrypt_data.encryptedS = this.accessSchedulerTrackerEncryptedLink;
    encrypt_data.decryptedS = "";
    this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
      let decrypted_data = data.decryptedS;
      if (decrypted_data !== null || decrypted_data !== undefined) {
        let splited_Data = SessionDetails.getD(SessionDetails.absJ, decrypted_data).split("|");
        var scheduleuserInfo = new SchedulerUserInfo();
        scheduleuserInfo.userName = splited_Data[0];
        scheduleuserInfo.userPk = Number(splited_Data[1]);
        scheduleuserInfo.password = splited_Data[2];
        scheduleuserInfo.designPk = Number(splited_Data[3]);
        scheduleuserInfo.appointmentPK = (splited_Data.length <= 5 && (!splited_Data[4] || typeof (splited_Data[4]) === 'string') ? -1 : Number(splited_Data[4]));
        scheduleuserInfo.redirectPage = ''// splited_Data[5];
        scheduleuserInfo.clinicPK = splited_Data[6] ? Number(splited_Data[6]) : -1;
        SessionDetails.setSchedulerUserInfo(scheduleuserInfo);
        let path = this.protocol + "//" + this.domain + ((this.port) ? ":" + this.port : "");
        window.open(path + '/' + page_name);
      }
    });
  }
  showEncryptedLinkDlg(ptype: any) {
    if (ptype == 'Tracking') {
      this.dispEncryptedLink = true;
      this.isApptTrackingEncLink = true;
      this.accessLinkHeaderTitle = "Shared Encrypted Access Link";
      this.isApptSchedulerEncLink = false;
    }
    if (ptype == 'Scheduler') {
      this.dispEncryptedLink = true;
      this.isApptSchedulerEncLink = true;
      this.accessLinkHeaderTitle = "Shared Encrypted Access Link & QR Code";
      this.isApptTrackingEncLink = false;
    }
    if (ptype == 'CPScheduler') {
      this.dispEncryptedLink = true;
      this.isApptSchedulerEncLink = true;
      this.accessLinkHeaderTitle = "Share CURA Patient Scheduler Link & QR Code";
      this.isApptTrackingEncLink = false;
    }
  }
  // downloadImage(){
  //   this.href= document.getElementsByTagName('img')[4].src;
  // }
  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }
  saveAsImage(parent) {
    // fetches base 64 date from image
    const parentElement = parent.qrcElement.nativeElement.querySelector("img").src;

    // converts base 64 encoded image to blobData
    let blobData = this.convertBase64ToBlob(parentElement);
    let winnavigator: any;
    winnavigator = window.navigator;
    // saves as image    
    if (window.navigator && winnavigator.msSaveOrOpenBlob) { //IE
      winnavigator.msSaveOrOpenBlob(blobData, this.myAngularxQrCode.substr(this.myAngularxQrCode.lastIndexOf('/') + 1));
    } else { // chrome
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = this.myAngularxQrCode.substr(this.myAngularxQrCode.lastIndexOf('/') + 1);
      link.click();
    }

  }
  validateReduceEstQnt() {
    let ret: boolean = true;
    let clinicList = this.clinicDetailsForm.get("clinicList").value[0];
    if (clinicList.isApptSchedulerEnabled == true && this.coClinicDetails.clinicList[0].isCovidClinic) {
      let newQuant = 0;
      this.clinicDetailsForm.controls.clinicImmunizationList['controls'].forEach(
        x => newQuant += Number(x.value.estimatedQuantity));
      let oldQuant = 0;
      this.coClinicDetails.clinicList[0].clinicImzQtyList.forEach(x => oldQuant += x.estimatedQuantity);
      if (newQuant < oldQuant) {
        this.dialogSummary = ErrorMessages['warning'];
        this.dialogMsg = "Reducing the Estimated Quantity with the Scheduler enabled will not remove appointment slots or cancel appointments that have already been created for this clinic.";
        this.showEstQnt = true;
        ret = false;
      }
      else
        ret = true;
    }
    return ret;
  }
  continueEstQntAlert() {
    this.showEstQnt = false;
    this.continueWithSaving(this.action_status);
  }
  alertUserAboutScheduler() {
    let ret: boolean = true;
    let showAlert: boolean = false;
    let messageString: string = "";
    let clinicList = this.clinicDetailsForm.get("clinicList").value;
    let alertClinicsList = [];
    //clinicItem.controls[0].get('apptSchedulerType').value
    if (clinicList.length > 1) {
      let index: number = 0;
      clinicList.forEach(clinic => {
        if (clinic.apptSchedulerType > 0 && index > 0) {
          showAlert = true;
          let name: string = clinic.apptSchedulerType == 1 ? "(Community Outreach Portal Scheduler)" : clinic.apptSchedulerType == 2 ? "(CURA Patient Scheduler)" : "(CURA Patient Regional Scheduler)";
          alertClinicsList.push("CLINIC - " + clinic.naClinicLocation + " " + name);
        }
        index++;
      });
    }
    if (clinicList[0].apptSchedulerType > 0 && this.coClinicDetails.clinicList[0].isApptSchedulerEnabled !== true && this.coClinicDetails.clinicList[0].isDataPostedToCP !== true) {
      let res: boolean = Number(this.coClinicDetails.clinicList[0].isApptSchedulerEnabled) != 1 && Number(this.coClinicDetails.clinicList[0].isDataPostedToCP) != 1;
      showAlert = clinicList[0].apptSchedulerType > 0 && res;
      let name: string = clinicList[0].apptSchedulerType == 1 ? "(Community Outreach Portal Scheduler)" : clinicList[0].apptSchedulerType == 2 ? "(CURA Patient Scheduler)" : "(CURA Patient Regional Scheduler)";
      alertClinicsList.push("CLINIC - " + clinicList[0].naClinicLocation + " " + name);
    }
    // 
    // this.coClinicDetails.clinicList[0].isCuraAppointmentScheduled = true;
    // if(clinicList[0].apptSchedulerType == 2 &&this.coClinicDetails.clinicList[0].isCuraAppointmentScheduled ==true){      
    //   showAlert = true;
    //   let name:string  = clinicList[0].apptSchedulerType==1?"Community Outreach Portal":clinicList[0].apptSchedulerType==2?"CURA Patient Scheduler":"";
    //   alertClinicsList.push("CLINIC - " + clinicList[0].naClinicLocation + " " + name);
    // }

    if (showAlert && !this.showSchedulerAlert) {

      this.dialogSummary = "Alert";
      this.dialogMsg = String.Format("Are you sure you want to update the following clinics with the selected appointment schedulers? Once selected, you will not be able to change your selection.<br/><br/> {0}", alertClinicsList.join('<br/>'));
      this.showSchedulerAlert = true;
      ret = false;
    }
    else {
      ret = true;
    }
    return ret;
  }
  continueSchedulerAlertClicked() {
    this.showSchedulerAlert = false;
    this.continueWithSaving(this.action_status);
  }

  updateClinicFee(state) {
    if (state.target.checked) {
      this.clinicDetailsForm.controls['isNoClinicFee'].patchValue(true);
    }
    else {
      this.clinicDetailsForm.controls['isNoClinicFee'].patchValue(false);
    }
  }

  onSelectedCancelDisposition(event) {
    if (event && this.selectedCancelDisposition.length) {
      this.displayCancelError = false;
    }
    else {
      this.displayCancelError = true;
    }
  }

  onClinicDateSelected(selectedDate: Date) {
    for(let immunization of this.clinicDetailsForm.controls.clinicImmunizationList['controls']) {
      if(immunization.get('immunizationName').value.toLocaleLowerCase()?.includes('covid')){
       let startDate  = moment(environment.covidVaccineNotAviableDates.startDate, "DD/MM/YYYY");
       let endDate = moment(environment.covidVaccineNotAviableDates.endDate, "DD/MM/YYYY");
       let comparisonDate = moment(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 18, 0, 0), "DD/MM/YYYY");

       if(comparisonDate.isBetween(startDate, endDate) || comparisonDate.isSame(startDate) || comparisonDate.isSame(endDate)){
         this.showDialogV2("Warning", ErrorMessages["sesionalCovid19Info"]);
         // immunization.estimatedQuantity.setErrors({ 'incorrect': true });
       }
      }
    }
  }

  handleCovidVaccineAdminConfirmation(){
    const locations = this.clinicDetailsForm.controls["clinicList"] as FormArray;
    this.covidVaccineSelected = false;
    this.covidVaccineWarningOkClicked = true;
    for (const location of locations.controls) {
      const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
      for (const clinicImzQty of clinicImzQtyList.controls) {
        if(clinicImzQty.get('immunizationName').value.toLocaleLowerCase()?.includes('covid')){
          clinicImzQty.get('estimatedQuantity').setErrors(null);
          clinicImzQty.get('estimatedQuantity').markAsDirty();
          clinicImzQty.get('estimatedQuantity').markAsTouched();
          clinicImzQty.updateValueAndValidity();
        }
      }
    }
    locations.updateValueAndValidity();
    this.saveChanges();
  }

  handleCovidVaccineAlertOk(){
    this.covidVaccineSelected = false;
  }
}
