export enum UserRole{
    STOREMANAGER                      = 'store manager',
    PHARMACYMANAGER                   = 'pharmacy manager',
    DISTRICTMANAGER                   = 'district manager',
    HEALTHCARESUPERVISOR              = 'healthcare supervisor',
    DIRECTORPHARMACYRETAILOPERATIONS  = 'director - rx and retail ops',
    REGIONALVICEPRESIDENT             = 'regional vice president',
    REGIONALHEALTHCAREDIRECTOR        = 'regional healthcare director',
    ADMIN                             = 'admin',
    DIRECTOR                          = 'director'
} 