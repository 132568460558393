import { UserLoginInfo } from "../models/userLoginInfo";
import { UserInfo } from "../models/userInfo";
import { ThankyouData } from "../models/thankyouData";
import { SchedulerUserInfo } from "../models/schedulerUserInfo";
import * as CryptoJS from 'crypto-js';
import { t1 } from "../JSON/MaxImmunizers";
import { t2 } from "../JSON/MinImmunizers";
import { t3 } from "../JSON/States";
import { OppourtunityInterface } from "./utility.model";


export class SessionDetails {
    
 public static absJ = t1+t2+t3;
    constructor() {}
    public static SetRoleID(roleID: string) {
        sessionStorage["roleID"] = JSON.stringify(roleID);
    }
    public static GetRoleID() {
        if (sessionStorage["roleID"] != null)
            return JSON.parse(sessionStorage["roleID"]);
        return null;
    }
    public static StoreId(storeId: string) {
        sessionStorage["storeId"] = JSON.stringify(storeId);
    }
    public static StorePk(storePk: string) {
        sessionStorage["storePk"] = JSON.stringify(storePk);
    }
    public static setStoreProfileData(store_profile: any) {
        sessionStorage["store_profile"] = JSON.stringify(store_profile);
    }
    public static getStoreProfileData() {
        if (sessionStorage["store_profile"] != null)
            return JSON.parse(sessionStorage["store_profile"]);
        return null;
    }
    public static GetStorePk() {
        if (sessionStorage["storePk"] != null)
            return JSON.parse(sessionStorage["storePk"]);
        return null;
    }
    public static GetStoreId() {
        if (sessionStorage["storeId"] != null)
            return JSON.parse(sessionStorage["storeId"]);
        return null;
    }
    public static setState(state: string) {
        sessionStorage["state"] = JSON.stringify(state);
    }
    public static GetState() {
        if (sessionStorage["state"] != null)
            return JSON.parse(sessionStorage["state"]);
        return null;
    }
    public static SetActionType(details: any) {
        sessionStorage["actionType"] = JSON.stringify(details);

    }

    public static GetActionType() {
        if (sessionStorage["actionType"] != null)
            return JSON.parse(sessionStorage["actionType"]);
        return 0;
    }
    public static EventDetails(event_details: any) {
        if (event_details != "") {
            sessionStorage["event_details"] = JSON.stringify(event_details);
        } else {
            sessionStorage.removeItem("event_details");
        }
    } 
    public static GetEventDetails() {
        if (sessionStorage["event_details"] != null)
            return JSON.parse(sessionStorage["event_details"]);
        return null;
    }

    public static opportunitiesData(opportunitie_data: string) {
        sessionStorage["opportunitie_data"] = JSON.stringify(opportunitie_data);
    }
    public static GetopportunitiesData() {
        if (sessionStorage["opportunitie_data"] != null)
            return JSON.parse(sessionStorage["opportunitie_data"]);
        return null;
    }
    public static setOpportunitiesData(opportunitie_data: string) {
        sessionStorage["opportunitie_data"] = opportunitie_data;
    }
    public static SetProgramType(details: any) {
        sessionStorage["programType"] = JSON.stringify(details);

    }
    public static setWagsContactInfo(contactInfo: any) {
        sessionStorage["WagsContactInfo"] = JSON.stringify(contactInfo);

    }
    
    public static getWagsContactInfo() {
        if (sessionStorage["WagsContactInfo"] != null)
            return JSON.parse(sessionStorage["WagsContactInfo"]);
        return null;
    }
    public static GetProgramType() {
        if (sessionStorage["programType"] != null)
            return JSON.parse(sessionStorage["programType"]);
        return 0;
    }
    public static SetLogContact(log_contact: any) {
        sessionStorage["log_contact"] = JSON.stringify(log_contact);
    }


    public static GetStoreOppSortByDefaultVal() {
        if (sessionStorage["storeoppsortbydefaultval"] != null)
            return JSON.parse(sessionStorage["storeoppsortbydefaultval"]);
        return 0;
    }
    public static SetStoreOppSortByDefaultVal(setSortBy: any) {
        sessionStorage["storeoppsortbydefaultval"] = JSON.stringify(setSortBy);
    }

    public static GetLogContact() {
        if (sessionStorage["log_contact"] != null)
            return JSON.parse(sessionStorage["log_contact"]);
        return 0;
    }

    public static SetBusinessType(business_type: any) {
        sessionStorage["business_type"] = JSON.stringify(business_type);

    }

    public static GetBusinessType() {
        if (sessionStorage["business_type"] != null)
            return JSON.parse(sessionStorage["business_type"]);
        return 0;
    }

    public static SetFollowUp(followp_flag: boolean) {
        sessionStorage["followp_flag"] = JSON.stringify(followp_flag);

    }

    public static GetFollowUp() {
        if (sessionStorage["followp_flag"] != null)
            return JSON.parse(sessionStorage["followp_flag"]);
        return false;
    }

    public static SetPageName(page_name: any) {
        sessionStorage["page_name"] = JSON.stringify(page_name);

    }

    public static GetPageName() {
        if (sessionStorage["page_name"] != null)
            return JSON.parse(sessionStorage["page_name"]);
        return "";
    }
    public static SetAgreementPK(agreement_pk: any) {
        sessionStorage["clinic_agreement_pk"]= sessionStorage["agreement_pk"] = JSON.stringify(agreement_pk);        
    }

    public static GetAgreementPK() {
        if (sessionStorage["agreement_pk"] != null)
            return JSON.parse(sessionStorage["agreement_pk"]);
        return 0;
    }

    public static SetUserInfo(userData: UserLoginInfo) {
        sessionStorage["cI"] =SessionDetails.getE(SessionDetails.absJ,JSON.stringify(userData));
    }
    public static GetUserInfo() {
        
        if (sessionStorage["cI"] != null){
           return JSON.parse(SessionDetails.getD(SessionDetails.absJ,sessionStorage["cI"]))
           }
        return null;
    }
    public static getE(keys, value){
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        return encrypted.toString();
      }

      //The get method is use for getD the value.
     public static getD(keys, value){
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        var getDed = CryptoJS.AES.decrypt(value, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        return getDed.toString(CryptoJS.enc.Utf8);
      }
    public static SetUserProfile(userData: UserInfo) {
                                        sessionStorage["cP"] = SessionDetails.getE(SessionDetails.absJ,JSON.stringify(userData));
    }
    public static GetUserProfile() {
        if (sessionStorage["cP"] != null)
            return JSON.parse(SessionDetails.getD(SessionDetails.absJ,sessionStorage["cP"]));
        return null;
    }

    public static fromUserPage(user_page: boolean) {
        sessionStorage["user_page"] = JSON.stringify(user_page);
    }
    public static GetfromUserPage() {
        if (sessionStorage["user_page"] != null)
            return JSON.parse(sessionStorage["user_page"]);
        return false;
    }

    public static fromLocalclinics(local_clinic: boolean) {
        sessionStorage["local_clinic"] = JSON.stringify(local_clinic);
    }
    public static GetfromLocalclinics() {
        if (sessionStorage["local_clinic"] != null)
            return JSON.parse(sessionStorage["local_clinic"]);
        return false;
    }
    public static setThankUDetails(thankudata: ThankyouData) {
        sessionStorage["thankuData"] = JSON.stringify(thankudata);
    }
    public static GetThankUDetails() {
        if (sessionStorage["thankuData"] != null)
            return JSON.parse(sessionStorage["thankuData"]);
        return false;
    }

    public static EmitFunction(emit_function: boolean) {
        sessionStorage["thankuData"] = JSON.stringify(emit_function);
    }
    public static GetEmitFunction() {
        if (sessionStorage["emit_function"] != null)
            return JSON.parse(sessionStorage["emit_function"]);
        return true;
    }

    public static setHCSActionItemsList(hcs_action_items_list:any){        
        sessionStorage["hcsActionItemsList"] = JSON.stringify(hcs_action_items_list);
    }

    public static getHCSActionItemsList(){        
        if (sessionStorage["hcsActionItemsList"] != null)
            return JSON.parse(sessionStorage["hcsActionItemsList"]);
        return false;
    }

    public static setHCSMetricsList(hcs_metrics_list:any){        
        sessionStorage["hcsMetricsList"] = JSON.stringify(hcs_metrics_list);
    }

    public static getHCSMetricsList(){        
        if (sessionStorage["hcsMetricsList"] != null)
            return JSON.parse(sessionStorage["hcsMetricsList"]);
        return false;
    }
    public static setPCAActionItemsList(pca_action_items_list:any){        
        sessionStorage["pcaActionItemsList"] = JSON.stringify(pca_action_items_list);
    }

    public static getPCAActionItemsList(){        
        if (sessionStorage["pcaActionItemsList"] != null)
            return JSON.parse(sessionStorage["pcaActionItemsList"]);
        return false;
    }
    public static setPCAMetricsList(pca_metrics_list:any){        
        sessionStorage["pcaMetricsList"] = JSON.stringify(pca_metrics_list);
    }

    public static getPCAMetricsList(){        
        if (sessionStorage["pcaMetricsList"] != null)
            return JSON.parse(sessionStorage["pcaMetricsList"]);
        return false;
    }

    public static setSchedulerSiteSetUp(scheduler_site_set_up:any){        
        sessionStorage["scheduler_site_set_up"] = JSON.stringify(scheduler_site_set_up);
    }

    public static getSchedulerSiteSetUp(){        
        if (sessionStorage["scheduler_site_set_up"] != null)
            return JSON.parse(sessionStorage["scheduler_site_set_up"]);
        return null;
    }

    public static setSchedulerSiteSetUpData(scheduler_site_set_up_data:any){        
        sessionStorage["scheduler_site_set_up_data"] = JSON.stringify(scheduler_site_set_up_data);
    }

    public static getSchedulerSiteSetUpData(){        
        if (sessionStorage["scheduler_site_set_up_data"] != null)
            return JSON.parse(sessionStorage["scheduler_site_set_up_data"]);
        return null;
    }

    public static setSchedulerUserInfo(schedulerInfo: SchedulerUserInfo) {
        sessionStorage["sI"] = SessionDetails.getE(SessionDetails.absJ,JSON.stringify(schedulerInfo));
    }
    public static getSchedulerUserInfo() {
        if (sessionStorage["sI"] != null)
            return JSON.parse(SessionDetails.getD(SessionDetails.absJ,sessionStorage["sI"]));
        return false;
    }

    public static getProfilePk(){        
        if (sessionStorage["profile_pk"] != null)
            return JSON.parse(sessionStorage["profile_pk"]);
        return null;
    }

    public static setProfilePk(profile_pk){        
        sessionStorage["profile_pk"] = JSON.stringify(profile_pk);
    }

    public static setIsPCA(is_pca) {
        sessionStorage["is_pca"] = JSON.stringify(is_pca);
    }
    public static getIsPCA() {
        if (sessionStorage["is_pca"] != null)
            return JSON.parse(sessionStorage["is_pca"]);
        return false;
    }

    public static setIsSR(is_sr) {
        sessionStorage["is_sr"] = JSON.stringify(is_sr);
    }
    public static getIsSR() {
        if (sessionStorage["is_sr"] != null)
            return JSON.parse(sessionStorage["is_sr"]);
        return false;
    }
    public static setIsCOVID(is_sr) {
        sessionStorage["is_COVID"] = JSON.stringify(is_sr);
    }
    public static getIsCOVID() {
        if (sessionStorage["is_COVID"] != null)
            return JSON.parse(sessionStorage["is_COVID"]);
        return false;
    }
    
    public static setIsIP(is_ip) {
        sessionStorage["is_ip"] = JSON.stringify(is_ip);
    }
    public static getIsIP() {
        if (sessionStorage["is_ip"] != null)
            return JSON.parse(sessionStorage["is_ip"]);
        return false;
    }

    public static SetUserProfileData(user_profile_data: any) {
        sessionStorage["c_p_d"] = SessionDetails.getE(SessionDetails.absJ,JSON.stringify(user_profile_data));
    }
    public static getUserProfileData() {
        if (sessionStorage["c_p_d"]  != null)
        return JSON.parse(SessionDetails.getD(SessionDetails.absJ,sessionStorage["c_p_d"]));
    return false;
    }
    public static SetTotalCancerEvents(totalCancerEvents: any) {
        sessionStorage["TotalCancerEvents"] = JSON.stringify(totalCancerEvents);
    }
    public static GetTotalCancerEvents() {
        if (sessionStorage["TotalCancerEvents"] != null)
            return JSON.parse(sessionStorage["TotalCancerEvents"]);
        return 0;
    }

    public static SetImmunizationCheckPK(immunizationCheckPK:number){
        sessionStorage["ImmunizationCheckPK"] = JSON.stringify(immunizationCheckPK);
    }
    public static GetImmunizationCheckPK(){
        if (sessionStorage["ImmunizationCheckPK"] != null)
            return JSON.parse(sessionStorage["ImmunizationCheckPK"]);
        return 0;
    }

    public static SetServiceAgreementStatus(hasServiceAgreementAccepted: boolean) {
        sessionStorage["hasServiceAgreementAccepted"] = JSON.stringify(hasServiceAgreementAccepted);
    }

    public static GetServiceAgreementStatus() {
        return JSON.parse(sessionStorage["hasServiceAgreementAccepted"]) != null ? JSON.parse(sessionStorage["hasServiceAgreementAccepted"]) : 0;
    }

    public static SetOpportunityRequiringActionMetricId(recordInfo: OppourtunityInterface){
        sessionStorage["opportunityRequiringActionMetricId"] = JSON.stringify(recordInfo);
    }

    public static GetOpportunityRequiringActionMetricId(): OppourtunityInterface{
        return JSON.parse(sessionStorage["opportunityRequiringActionMetricId"]) != null ? JSON.parse(sessionStorage["opportunityRequiringActionMetricId"]): undefined;
    }

    public static SetPriorityOpportunityRequiringActionMetricId(redcordInfo: OppourtunityInterface){
        sessionStorage["priorityOpportunityRequiringActionMetricId"] = JSON.stringify(redcordInfo);
    }

    public static GetPriorityOpportunityRequiringActionMetricId(): OppourtunityInterface{
        return JSON.parse(sessionStorage["priorityOpportunityRequiringActionMetricId"]) != null ? JSON.parse(sessionStorage["priorityOpportunityRequiringActionMetricId"]): undefined;
    }
}
