import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ScheduledAppointmentsService } from '../../services/scheduledAppointments.service';
import { ScheduledAppointment } from '../../../../models/ScheduledAppointment';
import { saveAs as importedSaveAs } from "file-saver";
import { Router } from '@angular/router';
import { SessionDetails } from '../../../../utility/session';
import { SchedulerregistrationService } from '../../../admin/services/schedulerregistration.service';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { SortEvent } from 'primeng/api';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";
import { type } from 'os';
import { ClinicdetailsService } from '../../../clinicdetails/services/clinicdetails.service';

@Component({
  selector: 'app-appointmentsreport',
  templateUrl: './scheduledappointmentsreport.component.html',
  styleUrls: ['./scheduledappointmentsreport.component.css']
})
export class ScheduledAppointmentsreportComponent implements OnInit {
  AppointmentsreportForm: FormGroup;
  AppointmentsreportArray: any;
  ddlCorporateClient: any;
  ddlClinicDate: any;
  ddlRoom: any;
  ddlArea ;
  ddlDistrict;
  ddlRegion;
  ddlPod;
  ddlAppointments: any;
  regionList = [];
  areaList = [];
  districtList = [];
  podList = [];
  loading: boolean = false;
  totalRecords: number = 0;
  corporateClients: any;
  clinicDates: any = [];
  clinicRooms: any = [];
  clinicDetails: any;
  allClinicDetails: any;
  ddlFormat: any;
  trackingflag: boolean = true;
  clinicLocationPk: number = 0;
  clinicPk: number = 0;
  locations: any;
  allLocations: any;
  showAllLocationsOption: boolean = true;
  alaSQL: any;
  canExport: boolean = true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  showGrid:boolean=false;
  private userprofile = JSON.parse(SessionDetails.getD(SessionDetails.absJ,sessionStorage.getItem("cP")));

  @Input('clientInfo') clientInfo: any;
  @Input() clinicEventDetails: any;
  @Input() corpDesignInfo: any;

  constructor(
    private fb: FormBuilder, private scheduledAppointments: ScheduledAppointmentsService, private router: Router,
     private scheduler_service: SchedulerregistrationService, private messageService: MessageServiceService,
     private spinner: NgxSpinnerService,
     private coClinicdetailsService: ClinicdetailsService,) {
    this.alaSQL = require('alasql');
    if (this.userprofile.userName == 'sm_schedule.appointment') {
      var temp = SessionDetails.GetUserInfo();
      this.userprofile.userRole = 'Client';//temp.role;
      this.userprofile.userPk = temp.pk;
    }
    //let clinic_details = SessionDetails.GetEventDetails();
  }

  ngOnInit() {
    // if (sessionStorage["isOpenedFromReminderEmail"]) { //From Reminder Email
    //   this.clinicLocationPk = this.clinicEventDetails.clinicPk;
    //   this.clinicPk = this.clinicEventDetails.clinicPk;
    //   //this.ddlCorporateClient = this.clinicEventDetails.clientId;
    //   this.ddlClinicDate = (this.clinicEventDetails.eventDate) ? this.clinicEventDetails.eventDate.replace(/-/g, '/') : this.clinicEventDetails.clinicDate.replace(/-/g, '/');
    //   //this.ddlRoom = this.clinicEventDetails.clinicRoom;
    //   this.ddlAppointments = '1';
    // } else if (this.clinicEventDetails && this.clinicEventDetails.clinicPk > 0) {
    //   this.clinicPk = this.clinicEventDetails.clinicPk;
    //   this.clinicLocationPk = this.clinicEventDetails.clinicPk;
    //   this.ddlClinicDate = (this.clinicEventDetails.eventDate) ? this.clinicEventDetails.eventDate.replace(/-/g, '/') : this.clinicEventDetails.clinicDate.replace(/-/g, '/');
    // } else {
    //   this.clinicPk = 0;
    //   this.clinicLocationPk = 0;
    // }
    // //this.clientInfo = SessionDetails.getSchedulerUserInfo();
    // //console.log(this.clientInfo);
    // /*if (sessionStorage["corporateTrackInfo"]) {
    //  this.clientInfo = JSON.parse(sessionStorage["corporateTrackInfo"]);
    //   sessionStorage.removeItem("corporateTrackInfo");
    // }*/

    // //if (this.router.url.indexOf('communityoutreach') > -1) {
    // if (!this.clientInfo && !this.clinicPk) {
    //   this.trackingflag = false;
    // } else {
    //   this.trackingflag = true;
    // }

    // this.ddlAppointments = "0";
    // this.ddlFormat = "0";
    // this.ddlClinicDate = "0";
    // this.ddlRoom = "0";
    // this.AppointmentsreportForm = this.fb.group({
    //   'ddlCorporateClient': [null],
    //   'ddlClinicDate': [null],
    //   'ddlRoom': [null],
    //   'ddlAppointments': [null],
    //   'ddlFormat': [null],
    //   'ddlLocation': [null]
    // });
    // this.setCorportateClientPk();
    // this.loadFilters();
    //this.generateReport();
    this.makeAndSetFilters();
    this.ddlAppointments = '1';
  }

  setCorportateClientPk() {
    let is_opened_from_reminder_email;
    if(typeof(sessionStorage["isOpenedFromReminderEmail"]) === "string"){
      is_opened_from_reminder_email = JSON.parse(sessionStorage["isOpenedFromReminderEmail"]);
    }else{
      is_opened_from_reminder_email = sessionStorage["isOpenedFromReminderEmail"];
    }
    if (is_opened_from_reminder_email) { //From Reminder Email
      //this.ddlCorporateClient = this.clinicEventDetails.clientId;      
      this.ddlClinicDate = (this.clinicEventDetails.eventDate) ? this.clinicEventDetails.eventDate.replace(/-/g, '/') : this.clinicEventDetails.clinicDate.replace(/-/g, '/');
      //this.ddlRoom = this.clinicEventDetails.clinicRoom;
      this.ddlCorporateClient = this.clientInfo.designPk;
      this.AppointmentsreportForm.controls['ddlCorporateClient'].setValue(this.ddlCorporateClient);
      this.ddlAppointments = '1';
    }
    else if (this.clientInfo && this.clientInfo.designPk && this.clientInfo.designPk > 0) {
      this.ddlCorporateClient = this.clientInfo.designPk;
      this.AppointmentsreportForm.controls['ddlCorporateClient'].setValue(this.ddlCorporateClient);
    } else {
      this.ddlCorporateClient = "0";
      if (this.clinicPk > 0) {
        //this.ddlClinicDate = this.clinicEventDetails.eventDate.replace(/-/g, '/');
        this.ddlClinicDate = (this.clinicEventDetails.eventDate) ? this.clinicEventDetails.eventDate.replace(/-/g, '/') : this.clinicEventDetails.clinicDate.replace(/-/g, '/');
      }
    }
  }

  generateReport(): void {
    if(!this.showGrid){
      this.spinner.show();
    }else{
      this.loading = true;
    }
    if (this.clinicEventDetails != null && this.clinicEventDetails != undefined) {
      this.clinicLocationPk = this.clinicEventDetails.clinicPk;
    }
    else {
      this.clinicLocationPk = this.AppointmentsreportForm.controls['ddlLocation'].value;
    }
    
    this.ddlRegion = this.AppointmentsreportForm.controls['ddlRegion'].value;
    this.ddlArea = this.AppointmentsreportForm.controls['ddlArea'].value;
    this.ddlPod = this.AppointmentsreportForm.controls['ddlPod'].value;
    this.ddlDistrict = this.AppointmentsreportForm.controls['ddlDistrict'].value;
    this.ddlRoom = this.AppointmentsreportForm.controls['ddlRoom'].value;
    this.ddlCorporateClient = this.AppointmentsreportForm.controls['ddlCorporateClient'].value?this.AppointmentsreportForm.controls['ddlCorporateClient'].value:"0";
    // this.clinicLocationPk = this.AppointmentsreportForm.controls['ddlLocation'].value?this.AppointmentsreportForm.controls['ddlLocation'].value:this.clinicEventDetails.clinicPk;
    this.ddlClinicDate = this.AppointmentsreportForm.controls['ddlClinicDate'].value?this.AppointmentsreportForm.controls['ddlClinicDate'].value:"0";
    this.ddlAppointments = this.AppointmentsreportForm.controls['ddlAppointments'].value?this.AppointmentsreportForm.controls['ddlAppointments'].value:"1";
    let sa: ScheduledAppointment = new ScheduledAppointment(this.userprofile.userRole, parseInt(this.userprofile.userPk), 
    this.ddlCorporateClient, this.clinicLocationPk, this.ddlAppointments, this.ddlRegion<=0?0:this.ddlRegion,
    this.ddlArea<=0?0:this.ddlArea,this.ddlPod<=0?0:this.ddlPod,this.ddlDistrict<=0?0:this.ddlDistrict,((this.ddlRoom == "0") ? null : this.ddlRoom), 
    ((this.ddlClinicDate == "0") ? null : this.ddlClinicDate) );
    
    this.scheduledAppointments.getScheduledAppointmentsReportData(sa).subscribe((data:any) => {
      this.AppointmentsreportArray = data;
      this.loading = false;
      this.canExport = true;
      this.spinner.hide();
      this.showGrid=true;
      this.totalRecords = data.length;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.AppointmentsreportArray = [];
      this.totalRecords = 0;
    });
    this.canExport = true;
  }

  onCorporateClientChange() {
    this.ddlClinicDate = 0;
    this.clinicLocationPk = 0;
    this.loadFilters();
    this.canExportOnchange();
    this.ddlRegion = this.AppointmentsreportForm.controls['ddlRegion'].value;
    this.ddlArea = this.AppointmentsreportForm.controls['ddlArea'].value;
    this.ddlPod = this.AppointmentsreportForm.controls['ddlPod'].value;
    this.ddlDistrict = this.AppointmentsreportForm.controls['ddlDistrict'].value;
    this.ddlRoom = this.AppointmentsreportForm.controls['ddlRoom'].value;
    this.getSchedulerClients(this.ddlRegion,this.ddlArea,this.ddlDistrict, this.ddlPod);
  }

  loadFilters(): void {
    if(this.trackingflag){
      this.getSchedulerClients(0,0,0, 0);
    }
    else {
    this.coClinicdetailsService.getAreaDist("Region",0).subscribe((data:any) => {
      let dataList = data;
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        this.regionList.push({ key: keyValue, value: valueData });        
      }
      this.getSchedulerClients(0,0,0, 0);
    });
  }
  }
  getSchedulerClients(regionNumber:number,areaNumber:number,districtNumber:number, podNumber: number){
    this.ddlCorporateClient = this.AppointmentsreportForm.controls['ddlCorporateClient'].value?this.AppointmentsreportForm.controls['ddlCorporateClient'].value:0;
    this.scheduledAppointments.getCorporateClients(this.userprofile.userRole, this.userprofile.userPk, this.ddlCorporateClient,
      regionNumber,areaNumber,districtNumber, podNumber).subscribe((data:any) => {
      this.corporateClients = data.Table;
      this.clinicDetails = data.Table1;
      this.allClinicDetails = data.Table1;
      this.locations = data.Table2;
      this.allLocations = data.Table2;
      this.clinicDates = [];
      this.clinicRooms = [];
      if (this.locations.length > 1) {
        this.showAllLocationsOption = true;
      } else {
        this.showAllLocationsOption = false;
      }
      this.clinicDetails.forEach(element => {
        if (element.clinicDate && element.clinicDate.indexOf(",") > -1) {
          let temp_list = element.clinicDate.split(",");
          for (let i = 0; i < temp_list.length; i++) {
            if (temp_list[i]) {
              if (this.clinicDates.findIndex(item => item == temp_list[i]) == -1) {
                this.clinicDates.push(
                  temp_list[i]
                );
              }
            }
          }
        }
        else if (element.clinicDate) {
          if (this.clinicDates.findIndex(item => item == element.clinicDate) == -1) {
            this.clinicDates.push(
              element.clinicDate
            );
          }
        }
      });
      this.clinicDates = this.clinicDates.sort();      
      if (this.clinicPk > 0) {
        this.allLocations.forEach(element => {
          if (element.clinicPk == this.clinicPk && element.clinicDate == this.ddlClinicDate) {
            if (element.clinicRoom) {
              this.clinicRooms.push({
                'clinicRoomName': element.clinicRoom,
                'clinicRoomValue': element.clinicRoom
              });
            }
            else {
              this.clinicRooms.push({
                'clinicRoomName': 'Not Specified',
                'clinicRoomValue': ''
              });
            }
          }
        });
      } else {        
        this.clinicDetails.forEach(element => {
          if (element.clinicRoom && element.clinicRoom.indexOf(",") > -1) {
            let temp_list = element.clinicRoom.split(",");
            for (let i = 0; i < temp_list.length; i++) {
              if (temp_list[i]) {
                if (this.clinicRooms.findIndex(item => item == temp_list[i]) == -1) {
                  this.clinicRooms.push(
                    {
                      'clinicRoomName': temp_list[i],
                      'clinicRoomValue': temp_list[i]
                    }
                  );
                }
              } else {
                if (this.clinicRooms.findIndex(item => item == 'Not Specified') == -1) {
                  this.clinicRooms.push(
                    {
                      'clinicRoomName': 'Not Specified',
                      'clinicRoomValue': ''
                    }
                  );
                }
              }
            }
          }
          else {            
            if (element.clinicRoom) {
              if (this.clinicRooms.findIndex(item => item == element.clinicRoom) == -1) {
                this.clinicRooms.push(
                  {
                    'clinicRoomName': element.clinicRoom,
                    'clinicRoomValue': element.clinicRoom
                  });
              }
            } else {
              this.clinicRooms.push(
                {
                  'clinicRoomName': 'Not Specified',
                  'clinicRoomValue': ''
                });
            }
          }
        });
      }      
      this.clinicRooms = this.clinicRooms.sort();
      if (this.clinicRooms.length == 1) {
        this.ddlRoom = this.clinicRooms[0].clinicRoomValue;
      }
    });

  }
  onDistrictSelect(value, selected_value) {    
    this.ddlRegion = this.AppointmentsreportForm.controls['ddlRegion'].value;
    this.ddlArea = this.AppointmentsreportForm.controls['ddlArea'].value;
    this.ddlPod = this.AppointmentsreportForm.controls['ddlPod'].value
    this.ddlDistrict = this.AppointmentsreportForm.controls['ddlDistrict'].value;
    this.ddlRoom = this.AppointmentsreportForm.controls['ddlRoom'].value;
    this.getSchedulerClients(this.ddlRegion,this.ddlArea,this.ddlDistrict, this.ddlPod);
    this.showAllLocationsOption = true;
  }
  onSelect(value, selected_value) {
    
    switch(value){
      case 'Area':
        this.areaList = [];
        this.districtList = [];
        this.ddlArea='0';
        this.ddlDistrict='0';
        this.coClinicdetailsService.getAreaDist(value,selected_value).subscribe(data => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.areaList.push({ key: keyValue, value: valueData });
          }
          this.ddlRegion = this.AppointmentsreportForm.controls['ddlRegion'].value;
          this.ddlArea = this.AppointmentsreportForm.controls['ddlArea'].value;
          this.ddlDistrict = this.AppointmentsreportForm.controls['ddlDistrict'].value;
          this.ddlRoom = this.AppointmentsreportForm.controls['ddlRoom'].value;
          this.getSchedulerClients(this.ddlRegion,this.ddlArea>0?this.ddlArea:0,this.ddlDistrict>0?this.ddlDistrict:0, this.ddlPod > 0 ? this.ddlPod: 0);
          this.showAllLocationsOption = true;
        }, error => {
          this.spinner.hide();
          this.showDialog("Error", ErrorMessages["error"], true);
        });
        break;
      case 'Pod':
        this.spinner.show();
        this.podList = [];
        this.districtList = [];
        this.ddlPod = 0;
        this.ddlDistrict='0';
        this.coClinicdetailsService.getAreaDist(value.toLowerCase(),selected_value).subscribe(data => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.podList.push({ key: keyValue, value: valueData });
          }
          this.ddlRegion = this.AppointmentsreportForm.controls['ddlRegion'].value;
          this.ddlArea = this.AppointmentsreportForm.controls['ddlArea'].value;
          this.ddlPod = this.AppointmentsreportForm.controls['ddlPod'].value;
          this.ddlDistrict = this.AppointmentsreportForm.controls['ddlDistrict'].value;
          this.ddlRoom = this.AppointmentsreportForm.controls['ddlRoom'].value;
          this.getSchedulerClients(this.ddlRegion,this.ddlArea>0?this.ddlArea:0, this.ddlDistrict>0?this.ddlDistrict:0, this.ddlPod > 0 ? this.ddlPod: 0);
          this.showAllLocationsOption = true;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.showDialog("Error", ErrorMessages["error"], true);
        });
        break;
      case "District":
        this.districtList = [];
        this.ddlDistrict = '0';
        this.coClinicdetailsService.getAreaDist(`pod${value.toLowerCase()}`,selected_value).subscribe((data:any) => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.districtList.push({ key: keyValue, value: valueData });
          }
          this.ddlRegion = this.AppointmentsreportForm.controls['ddlRegion'].value;
          this.ddlArea = this.AppointmentsreportForm.controls['ddlArea'].value;
          this.ddlPod = this.AppointmentsreportForm.controls['ddlPod'].value;
          this.ddlDistrict = this.AppointmentsreportForm.controls['ddlDistrict'].value;
          this.ddlRoom = this.AppointmentsreportForm.controls['ddlRoom'].value;
          this.getSchedulerClients(this.ddlRegion,this.ddlArea,this.ddlDistrict>0?this.ddlDistrict:0, this.ddlPod > 0 ? this.ddlPod: 0);
          this.showAllLocationsOption = true;
        });
        break;
        default:
          this.areaList = [];
          this.districtList = [];
          this.coClinicdetailsService.getAreaDist(value,0).subscribe((data:any) => {
            let dataList = data;
            for (var keyValue in dataList) {
              let valueData = dataList[keyValue];
              this.regionList.push({ key: keyValue, value: valueData });
            }
          });
          break;
    }
  }
  generateReportFormat(): void {
    this.loading = true;
    let report_name: string = "ScheduledAppointments";
    this.ddlRegion = this.AppointmentsreportForm.controls['ddlRegion'].value;
    this.ddlArea = this.AppointmentsreportForm.controls['ddlArea'].value;
    this.ddlPod = this.AppointmentsreportForm.controls['ddlPod'].value;
    this.ddlDistrict = this.AppointmentsreportForm.controls['ddlDistrict'].value;
    this.ddlRoom = this.AppointmentsreportForm.controls['ddlRoom'].value;
    this.ddlCorporateClient = this.AppointmentsreportForm.controls['ddlCorporateClient'].value?this.AppointmentsreportForm.controls['ddlCorporateClient'].value:0;
    this.ddlAppointments = this.AppointmentsreportForm.controls['ddlAppointments'].value?this.AppointmentsreportForm.controls['ddlAppointments'].value:"1";
    this.ddlClinicDate = this.AppointmentsreportForm.controls['ddlClinicDate'].value;
    this.ddlFormat = "xlsxv";//this.AppointmentsreportForm.controls['ddlFormat'].value;
    this.clinicLocationPk = this.AppointmentsreportForm.controls['ddlLocation'].value;
    report_name = (this.clinicPk > 0 || (!this.clientInfo && !this.clinicPk && !this.trackingflag)) ? report_name : report_name + 'Tracking';
    let sa: ScheduledAppointment = new ScheduledAppointment(this.userprofile.userRole, this.userprofile.userPk, this.ddlCorporateClient, this.clinicLocationPk, this.ddlAppointments,this.ddlRegion<=0?0:this.ddlRegion,
      this.ddlArea<=0?0:this.ddlArea, this.ddlPod <=0 ?0 : this.ddlPod, this.ddlDistrict<=0?0:this.ddlDistrict, ((this.ddlRoom == "0") ? null : this.ddlRoom), ((this.ddlClinicDate == "0") ? null : this.ddlClinicDate), this.ddlFormat, report_name);
    this.scheduledAppointments.exportReportData(sa).subscribe((res:any) => {
      importedSaveAs(res.body, "ScheduledAppointments.xlsx");
      this.loading = false;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  // validateExportType() {
  //   this.ddlFormat = this.AppointmentsreportForm.controls['ddlFormat'].value;
  //   if (this.ddlFormat == "0" || !this.canExport)
  //     return true;
  //   else
  //     return false;
  // }
  navigateBackToTracking() {
    this.messageService.setNavigationClicked(false);
    if (this.clientInfo) {
      sessionStorage["corporateTrackInfo"] = JSON.stringify(this.clientInfo);
    }
    if (this.clinicEventDetails) {
      sessionStorage["event_details"] = JSON.stringify(this.clinicEventDetails);
      sessionStorage.removeItem("isOpenedFromReminderEmail");
    }
    if (this.clinicPk > 0) {
      this.router.navigateByUrl('/communityoutreach/corpStoreApptTracker');
    } else {
      this.router.navigate(['/clientSchedulerApptTracker']);
    }
  }

  getLocations() {
    if (this.allLocations && this.allLocations.length > 0) {
      if (this.ddlClinicDate == '0') {
        this.locations = this.allLocations;
      } else {
        this.locations = this.alaSQL("SELECT distinct * FROM ? \
    WHERE clinicDate = '" + this.ddlClinicDate + "' ORDER BY clinicDate ASC", [this.allLocations]);
      }
      if (this.locations.length > 1) {
        this.clinicLocationPk = 0;
        this.showAllLocationsOption = true;
      }
      else {
        this.clinicLocationPk = ((this.locations.length == 1) ? this.locations[0].clinicPk : 0);
        this.showAllLocationsOption = false;
      }
    }
    this.canExportOnchange();
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes["clientInfo"]) {
      this.clientInfo = changes["clientInfo"].currentValue;
    }
    if (changes["clinicEventDetails"]) {
      this.clinicEventDetails = changes["clinicEventDetails"].currentValue;
    }
  }
  canExportOnchange() {
    this.canExport = false;
  }

  customSort(event: SortEvent) {
    if (event.field == 'apptDate' || event.field == 'clinicAddress' || event.field == 'businessClinic') {
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;
      var tmp = this.AppointmentsreportArray.sort((data1, data2) => {

        let value1: any = data1[event.field];
        let value2: any = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
          if (event.field == 'businessClinic' || event.field == 'clinicAddress') {
            var aA = value1.replace(reA, "");
            var bA = value2.replace(reA, "");
            if (aA === bA) {
              var aN = parseInt(value1.replace(reN, ""), 10);
              var bN = parseInt(value2.replace(reN, ""), 10);
              result = aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
              result = aA > bA ? 1 : -1;
            }
          } else if (event.field == 'apptDate') {
            result = new Date(value1).getTime() - new Date(value2).getTime();
          }
          else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        return result;
      });
      if (event.order < 0) {
        tmp.reverse();
      }
      const thisRef = this;
      this.AppointmentsreportArray = [];
      tmp.forEach(function (row: any) {
        thisRef.AppointmentsreportArray.push(row);
      });
    }
  }
  reset(): void {
    this.showGrid = false;
    this.loading = false;
    this.makeAndSetFilters();
  }

  makeAndSetFilters() {
    let is_opened_from_reminder_email;
    if(typeof(sessionStorage["isOpenedFromReminderEmail"]) === "string"){
      is_opened_from_reminder_email = JSON.parse(sessionStorage["isOpenedFromReminderEmail"]);
    }else{
      is_opened_from_reminder_email = sessionStorage["isOpenedFromReminderEmail"];
    }
    if (is_opened_from_reminder_email) { //From Reminder Email
      this.clinicLocationPk = this.clinicEventDetails.clinicPk;
      this.clinicPk = this.clinicEventDetails.clinicPk;
      //this.ddlCorporateClient = this.clinicEventDetails.clientId;
      this.ddlClinicDate = (this.clinicEventDetails.eventDate) ? this.clinicEventDetails.eventDate.replace(/-/g, '/') : this.clinicEventDetails.clinicDate.replace(/-/g, '/');
      //this.ddlRoom = this.clinicEventDetails.clinicRoom;
      this.ddlAppointments = '1';
    } else if (this.clinicEventDetails && this.clinicEventDetails.clinicPk > 0) {
      this.clinicPk = this.clinicEventDetails.clinicPk;
      this.clinicLocationPk = this.clinicEventDetails.clinicPk;
      this.ddlClinicDate = (this.clinicEventDetails.eventDate) ? this.clinicEventDetails.eventDate.replace(/-/g, '/') : this.clinicEventDetails.clinicDate.replace(/-/g, '/');
    } else {
      this.clinicPk = 0;
      this.clinicLocationPk = 0;
    }
    //this.clientInfo = SessionDetails.getSchedulerUserInfo();
    //console.log(this.clientInfo);
    /*if (sessionStorage["corporateTrackInfo"]) {
     this.clientInfo = JSON.parse(sessionStorage["corporateTrackInfo"]);
      sessionStorage.removeItem("corporateTrackInfo");
    }*/

    //if (this.router.url.indexOf('communityoutreach') > -1) {
    if (!this.clientInfo && !this.clinicPk) {
      this.trackingflag = false;
    } else {
      this.trackingflag = true;
    }
    this.ddlAppointments = "0";
    this.ddlFormat = "xlsxv";
    this.ddlClinicDate = "0";
    this.ddlRoom = "0";
    this.ddlArea = "0";
    this.ddlDistrict="0";
    this.ddlPod = "0";
    this.ddlRegion="0";
    this.ddlCorporateClient="0";
    this.regionList = [];
    this.areaList = [];
    this.podList = [];
    this.districtList = [];
    this.AppointmentsreportForm = this.fb.group({
      'ddlRegion':[this.ddlRegion,null],
      'ddlArea':[this.ddlArea,null],
      'ddlPod':[this.ddlPod, null],
      'ddlDistrict':[this.ddlDistrict,null],
      'ddlCorporateClient': [this.ddlCorporateClient,null],
      'ddlClinicDate': [this.ddlClinicDate,null],
      'ddlRoom': [this.ddlRoom,null],
      'ddlAppointments': [this.ddlAppointments,null],
      'ddlFormat': [this.ddlFormat,null],
      'ddlLocation': [this.showAllLocationsOption?"0":this.clinicLocationPk,null]
    });
    this.setCorportateClientPk();
    this.loadFilters();
  }

  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}