import { Component, OnInit } from '@angular/core';
import { OutreachProgramService } from '../../services/outreach-program.service';
import { WalgreenCo } from '../../../../models/contract';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Util } from '../../../../utility/util';
import { SessionDetails } from '../../../../utility/session';
import { ErrorMessages } from '../../../../config-files/error-messages';

@Component({
  selector: 'app-witness',
  templateUrl: './witness.component.html',
  styleUrls: ['./witness.component.css'],
  providers: [DatePipe]
})
export class WitnessComponent implements OnInit {
  preparedDate: Date;
  witnessInfo1: any;
  witnessInfo: WalgreenCo;
  witnessForm: FormGroup;
  witness_form_cancel: FormGroup;
  from_local_clinic: boolean = false;
  minDate = new Date();
  profile_data: any;
  dialogMsg:string ;
  dialogSummary:string ;
  pharmacyInfoModalDlg:boolean = false;
  //voucherExpirationDate : Date = new Date();
  usPhoneNoRegEx: RegExp = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/
  constructor(private _fb: FormBuilder, private _localContractService: OutreachProgramService,
    private _datePipe: DatePipe, private utility: Util) { }

  ngOnInit() {
    this.from_local_clinic = SessionDetails.GetfromUserPage();
    this.witnessInfo1 = this._localContractService.fetchContractAgreementData();
    this.witnessInfo = this.witnessInfo1.walgreenCoInfo;
    if (this.witnessInfo == null) {
      this.witnessInfo = new WalgreenCo();
    }
    this.profile_data = SessionDetails.getStoreProfileData();
    var userProfile = SessionDetails.GetUserProfile();
    if((this.witnessInfo1.isApproved==1||this.witnessInfo1.isApproved==0) &&this.witnessInfo1.isEmailSent==1){
      // this means its an amended agreement or rejected agreement
      this.preparedDate = new Date();
    }
    else {
      this.preparedDate = this.witnessInfo !== null && this.witnessInfo.preparedDate !== '' ? new Date(this.witnessInfo.preparedDate) : new Date();
    }
    if(userProfile.email && userProfile.email!="" && !this.witnessInfo.contactEmail && this.witnessInfo.contactEmail == ""){
      this.witnessInfo.contactEmail = userProfile.email;
    }

    this.witnessForm = this._fb.group({
      name: this._fb.control(this.witnessInfo.name, Validators.required),
      title: this._fb.control((this.witnessInfo.title && this.witnessInfo.title != "") ?  this.witnessInfo.title:userProfile.userRole, Validators.required),
      preparedDate: this._fb.control(this.preparedDate, Validators.required),
      //districtNumber: this._fb.control((this.witnessInfo.districtNumber == null || this.witnessInfo.districtNumber == "") ? this.profile_data[0].districtNumber : this.witnessInfo.districtNumber, Validators.required),
      //contactName: this._fb.control((this.witnessInfo.contactName && this.witnessInfo.contactName != "") ?  this.witnessInfo.contactName:"", Validators.required),
      contactPhone: this._fb.control((this.witnessInfo.contactPhone && this.witnessInfo.contactPhone != "") ?  this.witnessInfo.contactPhone:"", Validators.required),
      contactEmail: this._fb.control((this.witnessInfo.contactEmail && this.witnessInfo.contactEmail != "") ?  this.witnessInfo.contactEmail:"", Validators.required),
    });
    this.witness_form_cancel = Object.assign([], this.witnessForm);
    
    //work around to fill the input mask fields
    setTimeout(() => {
      const maskedInputControl = this.witnessForm.get('contactPhone') as FormControl;
      maskedInputControl.setValue(maskedInputControl.value);
    });
  }
  save(): boolean {
    this.witnessInfo.preparedDate = this._datePipe.transform(this.preparedDate, "shortDate");
    // if (!this.isFormValid()) {
    //   this.utility.validateAllFormFields(this.witnessForm);
    //   return false;
    // }    
    if (!this.usPhoneNoRegEx.test(this.witnessForm.controls['contactPhone'].value)) {      
       this.dialogMsg = ErrorMessages['walgreensMandatoryEmail'];
      this.dialogSummary = ErrorMessages['errMsg'];
       this.pharmacyInfoModalDlg = true;
      return false;      
    }
    this._localContractService.setWalgreensWitnessData(this.witnessForm.value);
    return true;
  }
  simpleSave(): boolean {    
    if (!this.isFormValidForSimpleSave()) { return false; }
    this.witnessInfo.preparedDate = this._datePipe.transform(this.preparedDate, "shortDate");
    this._localContractService.setWalgreensWitnessData(this.witnessForm.value);
    return true;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  isFieldValid(field: string) {
    if (this.witnessForm.get(field) != null) {
      let ret: boolean = !this.witnessForm.get(field).valid && this.witnessForm.get(field).touched;
      if (ret) {
        return ret;
      } else {
        if (field.search('name') !== -1 || field.search('title') !== -1) {
          let validField: boolean = this.witnessForm.controls[field].value!=null? this.witnessForm.controls[field].value.trim().length > 0:false;
          return !validField;
        }
        // else if (field.search('districtNumber') !== -1) {
        //   let validNo: number = +this.witnessForm.controls[field].value;
        //   if (validNo > 0) {
        //     return false;
        //   }
        //   else {
        //     return true;
        //   }
        // }
      }
      return false;
    }
  }
  isFormValid() {
    let validForm: boolean = true;
    if (this.witnessForm.controls['name'].value != null && this.witnessForm.controls['title'].value != null ) {
      validForm = this.witnessForm.controls['name'].value.trim().length > 0 && this.witnessForm.controls['title'].value.trim().length > 0
      
      //let num: number = +this.witnessForm.controls['districtNumber'].value;
      //validForm = num > 0 ? validForm && true : validForm && false;
      if(!this.witnessForm.valid){
        this.utility.validateAllFormFields(this.witnessForm);
        return false;
      }
      // if(!walgreensContactEmailListTokens.some(element=>this.witnessForm.controls['contactEmail'].value.trim().toLowerCase().includes(element.toLowerCase()))){
      //   this.dialogMsg = ErrorMessages['walgreensMandatoryEmail'];
      //   this.dialogSummary = ErrorMessages['errMsg'];
      //   this.pharmacyInfoModalDlg = true;
      //   return false;
      // }
      
    }
    return validForm;
  }
  isFormValidForSimpleSave() {    
    let validForm: boolean = false;
    if (this.witnessForm.controls['name'].value != null && this.witnessForm.controls['title'].value != null && this.witnessForm.valid) {
      validForm = this.witnessForm.controls['name'].value.trim().length >= 0 && this.witnessForm.controls['title'].value.trim().length >= 0;
      if(validForm){
        if(!this.witnessForm.valid){
          this.utility.validateAllFormFields(this.witnessForm);
          return false;
        }
      }
      
    }
    if(!this.witnessForm.valid){
      this.utility.validateAllFormFields(this.witnessForm);
      return false;
    }
    return validForm;
  }
  getPharmacyContactEmail(){
    return this.witnessForm.controls['contactEmail'].value.trim();
  }


  onlyNumberKey(event) {
    Util.onlyNumbers(event);
  }
  cancelWitness(): boolean {
    var compare_objects = false;
    compare_objects = this.utility.compareTwoObjects(this.witness_form_cancel.value, this.witnessForm.value);
    return compare_objects;
  }

  onExpirationDateSelected(selectedDate: Date) {
    this.witnessForm.controls["preparedDate"].setValue(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12, 30, 55));
  }
}
