import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs as importedSaveAs } from "file-saver";
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorMessages } from "../../../../config-files/error-messages";
import { FluOpportunitiesContactStatusService } from '../../services/flu-opportunities-contact-status.service';
import * as xlsx from 'xlsx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClinicdetailsService } from '../../../../modules/clinicdetails/services/clinicdetails.service';
import { MessageServiceService } from '../../../../modules/store/services/message-service.service';
import { PotentialLocalBusinessAndContactStatusService } from '../../services/potential-local-business.service';
import { ScheduledAppointmentsService } from '../../services/scheduledAppointments.service';
import { ApplicationSettings } from '../../../../modules/common/application-settings';
import { SessionDetails } from '../../../../utility/session';
import { states } from '../../../../JSON/States';
import { Paginator } from 'primeng/paginator';
import { JsFluOppContactStatusModel } from './../../../../models/JsFluOppContactStatusModel';
@Component({
  selector: 'app-flu-opportunities-contact-status',
  templateUrl: './flu-opportunities-contact-status.component.html',
  styleUrls: ['./flu-opportunities-contact-status.component.css'],
  providers: [FluOpportunitiesContactStatusService]
})
export class FluOpportunitiesContactStatusComponent implements OnInit {
  ddlReport: string = "FluOpportunitiesContactStatus";
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  fluOppOutreachstatusForm: FormGroup;
  fromDate: any;
  toDate: any;
  outreachEffort: any;
  outreachStartDate: any;
  outreachEndDate: any;
  userProfile: any;
  showddlState: boolean = false;
  states: any[];
  storeId: any;
  loading: boolean;



  ddlFormat: any;
  canExport: any;
  outreachstatus: any;
  contacted: any;
  status: any;  

  minDate: any;
  maxDate: any;
  from_date: any;
  to_date: any;
  isDisable: boolean = false;

  potentialLocalBusinessContactStatusReportArray: any = [];
  potentialLocalBusinessContactStatusReportSource: any;
  totalRecords: number = 0;
  allStatuses: any = [];
  canShowNoBusinessesText: boolean = false;

  regionList = [];
  areaList = [];
  districtList = [];
  //storeList = [];
  ddlArea: string = "0";
  ddlDistrict: string = "0";
  ddlRegion: string = "0";
  ddlStoreId: string = "0";
  @ViewChild('plbcsPager', { static: false }) plbcsPager: Paginator;

  ddlState: any = "0";

  isDisableDates: boolean;

  postModel = new JsFluOppContactStatusModel();
  postCountModel = new JsFluOppContactStatusModel();
  totalRecordsCount: number;
  countCall:boolean = true;
  noOfCallsNeeded: number;
  counter: number = 1;
  downloadStartedSpinner: boolean;


  constructor(private _fluOpportunitiesContactStatusService: FluOpportunitiesContactStatusService,
    private router: Router, private potential_service: PotentialLocalBusinessAndContactStatusService,
    private message_service: MessageServiceService,
    private coClinicdetailsService: ClinicdetailsService, private storeIdListService: ScheduledAppointmentsService
    , private spinner: NgxSpinnerService, private fb: FormBuilder) {

    this.outreachEffort = "3";
    this.outreachStartDate = ApplicationSettings.getFluOppContactStatusStartDate(new Date().getFullYear());
    this.outreachEndDate = ApplicationSettings.getFluOppContactStatusEndDate(new Date().getFullYear());

    this.userProfile = SessionDetails.GetUserProfile();
    if (this.userProfile.userRole == "Admin" || this.userProfile.userRole.toLowerCase() == "regional vice president" || this.userProfile.userRole.toLowerCase() == "regional healthcare director") {
      this.showddlState = true;
    }

    this.states = states.sort((a, b) => a.id.localeCompare(b.id));

  }

  ngOnInit() {
    
    this.spinner.show();
    this.ddlReport = "0";

    this.postCountModel.areaNumber = 0;
    this.postCountModel.contacted = -1;
    this.postCountModel.districtNumber = 0;    
    this.postCountModel.outreachEffort = 3;
    this.postCountModel.regionNumber = 0;
    this.postCountModel.state = "0";
    this.postCountModel.status = "-1";    
    this._fluOpportunitiesContactStatusService.getTotalRecordsCountForFluOppContactStatusReport(this.postCountModel).subscribe((res: any) => {
        
      this.totalRecordsCount = +res;
      this.noOfCallsNeeded =  Math.ceil(this.totalRecordsCount / 50000);
      this.countCall = false;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.showDialog("Error", ErrorMessages["error"], true);
    });


    this.storeId = SessionDetails.GetStoreId();
    this.isDisableDates = true;
    this.potential_service.getOutreachStatus().subscribe((resultArray: any) => {
      this.spinner.show();
      let outreach_program = ((this.outreachEffort == "1") ? "SR" : "IP");
      this.allStatuses = resultArray;
      var temp = resultArray.filter(x => x.outreachProgram == outreach_program && x.isActive /*== true*/ && [0, 9, 10].indexOf(x.outreachStatusId) == -1);
      this.status = temp;          
    });

    let region = this.showddlState ? "Region" : "Area";
    this.coClinicdetailsService.getAreaDist(region, 0).subscribe(data => {
      let dataList = data;
      for (var keyValue in dataList) {
        let valueData = dataList[keyValue];
        if (region == "Region")
          this.regionList.push({ key: keyValue, value: valueData });
        else
          this.areaList.push({ key: keyValue, value: valueData });
      }        
    });
    this.setFormControls();
    

    // this.fluOppOutreachstatusForm.controls['areaNumber'].patchValue("0");

  }

  setDates() {
    
    this.minDate = this.fromDate = new Date(this.outreachStartDate);
    this.maxDate = this.toDate = new Date(this.outreachEndDate);
  }

  reset() {
    this.contacted = "3";
    this.ddlFormat = "0";
    this.outreachstatus = -1;
    this.setDates();
    this.ddlFormat = "0";
    this.ddlRegion = "0";
    this.ddlArea = "0";
    this.ddlDistrict = "0";
    this.ddlStoreId = "0";
    this.setFormControls();
    //this.generateReport();
  }

  generatereportformat(): void {
        
    this.downloadStartedSpinner = true;
    this.postModel.areaNumber = +this.fluOppOutreachstatusForm.controls['areaNumber'].value;
    this.postModel.contacted = +this.fluOppOutreachstatusForm.controls['contacted'].value;
    this.postModel.districtNumber = +this.fluOppOutreachstatusForm.controls['districtNumber'].value
    this.postModel.fromDate = this.fluOppOutreachstatusForm.controls['fromDate'].value
    this.postModel.toDate = this.fluOppOutreachstatusForm.controls['toDate'].value
    this.postModel.outreachEffort = 3;
    this.postModel.regionNumber = +this.fluOppOutreachstatusForm.controls['regionNumber'].value
    this.postModel.state = this.fluOppOutreachstatusForm.controls['state'].value;
    this.postModel.status = this.fluOppOutreachstatusForm.controls['status'].value;
    this.postModel.pageIndex = this.counter;
    
    this.spinner.show();
    this._fluOpportunitiesContactStatusService.exportData(this.postModel).subscribe((res: any) => {
      let responseJsonForExcel = JSON.parse(res);  
      this.exportToExcel(responseJsonForExcel,this.counter);
      this.counter++  
      if(this.counter > this.noOfCallsNeeded)
      {
        this.downloadStartedSpinner = false;
        this.spinner.hide();    
      }                    
      if(this.counter <= this.noOfCallsNeeded){
        this.generatereportformat()
       }
       else
       {
        this.counter = 1;
       }       

    }, error => {
      this.spinner.hide();
      this.showDialog("Error", ErrorMessages["error"], true);
    });  
}

  updateOutreachStatus() {
    
    let data=this.fluOppOutreachstatusForm.value;
    let outreach_program = ((this.outreachEffort == "1") ? "SR" : "IP");
    if (data.contacted == "0") {
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && [0,2].indexOf(x.outreachStatusId) > -1);
      this.status = temp;
      // this.isDisable = true;
      this.outreachstatus = 0;
      this.isDisableDates = true;
    }
    else if (data.contacted == "1") {
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && [0, 9, 10].indexOf(x.outreachStatusId) == -1 && x.outreachStatusId!=2);
      this.status = temp;
      // this.isDisable = true;
      this.outreachstatus = 0;
      this.isDisableDates = false;
    }
    else {
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && x.outreachStatusId!=9 && x.outreachStatusId!=10);// && [9, 10].indexOf(x.outreachStatusId) == -1 && x.outreachStatusId> -1);
      this.status = temp;
      // this.isDisable = false;
      this.outreachstatus = -1;
      this.isDisableDates = true;
    }
    this.canExportOnchange();
  }

  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }

  exportToExcel(responseJsonForExcel: any,counter:any) {
    
    let size  = (counter-1) * (50000) + 1 +'_'+ (counter * 50000)		
    const name = "OpportunitiesContactStatusReport";
    const ext = ".xlsx";
    const fileName = name +'_'+ size + ext;
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(responseJsonForExcel);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "OpportunitiesContactStatus");
    xlsx.writeFile(wb, fileName);
  }

  setFormControls() {
    
    this.setDates();
    this.fluOppOutreachstatusForm = this.fb.group({
      'fromDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'toDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'regionNumber': ["0"],
      'areaNumber': ["0"],
      'districtNumber': ["0"],
      'state': ["0"],
      'contacted': ["-1"],
      'status': ["-1"],
      'outputType': ["0"]
    });
  }

  paginate(event) {
    this.potentialLocalBusinessContactStatusReportArray = [];
    this.loading = true;
    var curr_page_rec_count = event.first + event.rows;
    var page_to_get = curr_page_rec_count / 50;
    page_to_get = Math.ceil(page_to_get);
    var start_number = ((event.first < 50) ? (event.first - (50 * (page_to_get - 1))) : event.first);
    if (this.potentialLocalBusinessContactStatusReportSource) {
      var temp = this.potentialLocalBusinessContactStatusReportSource.slice(start_number, (start_number + 50));
      this.potentialLocalBusinessContactStatusReportArray = temp;
      this.totalRecords = this.potentialLocalBusinessContactStatusReportSource.length;
    }
    this.loading = false;
  }
  canExportOnchange() {
    this.canExport = false;
  }
  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport) {
      return true;
    }
    else {
      return false;
    }
  }
  onSelect(value, selected_value) {
    
    switch (value) {
      case 'Area':
        this.spinner.show();        

        this.areaList = [];
        this.districtList = [];
        this.ddlArea = '0';
        this.fluOppOutreachstatusForm.controls['areaNumber'].setValue('0');
        this.ddlDistrict = '0';
        this.coClinicdetailsService.getAreaDist(value, selected_value).subscribe(data => {          
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.areaList.push({ key: keyValue, value: valueData });
          }
          
          // get the count of records
        this.countCall = true;
        this.postCountModel.regionNumber = +selected_value   
        this.postCountModel.districtNumber = +this.fluOppOutreachstatusForm.controls['districtNumber'].value
        this.postCountModel.areaNumber = +this.fluOppOutreachstatusForm.controls['areaNumber'].value      
          this._fluOpportunitiesContactStatusService.getTotalRecordsCountForFluOppContactStatusReport(this.postCountModel).subscribe((res: any) => {
            
            this.totalRecordsCount = +res;
            this.noOfCallsNeeded = Math.ceil(this.totalRecordsCount / 50000);
            this.countCall = false;
            this.spinner.hide();
          }, error => {
            this.spinner.hide();
            this.showDialog("Error", ErrorMessages["error"], true);
          });

        });
        break;
      case "District":
        this.spinner.show();
       
        
        this.districtList = [];
        this.ddlDistrict = '0';
        this.fluOppOutreachstatusForm.controls['districtNumber'].setValue('0');
        this.coClinicdetailsService.getAreaDist(value, selected_value).subscribe(data => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.districtList.push({ key: keyValue, value: valueData });
          }

           // get the count of records    
        this.countCall = true;   
        this.postCountModel.areaNumber = +selected_value  
        this.postCountModel.regionNumber = +this.fluOppOutreachstatusForm.controls['regionNumber'].value;        
        this.postCountModel.districtNumber = +this.fluOppOutreachstatusForm.controls['districtNumber'].value             
        this._fluOpportunitiesContactStatusService.getTotalRecordsCountForFluOppContactStatusReport(this.postCountModel).subscribe((res: any) => {
          
          this.totalRecordsCount = +res;
          this.noOfCallsNeeded = Math.ceil(this.totalRecordsCount / 50000);
          this.countCall = false;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.showDialog("Error", ErrorMessages["error"], true);
        });
        });
        break;
      default:
        this.spinner.show();
       
        this.areaList = [];
        this.districtList = [];
        this.coClinicdetailsService.getAreaDist(value, 0).subscribe(data => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.regionList.push({ key: keyValue, value: valueData });
          }

           // get the count of records
        this.countCall = true;
        this.postCountModel.areaNumber = +this.fluOppOutreachstatusForm.controls['areaNumber'].value;
        this.postCountModel.regionNumber = +this.fluOppOutreachstatusForm.controls['regionNumber'].value;
        this.postCountModel.districtNumber = +this.fluOppOutreachstatusForm.controls['districtNumber'].value
        this.postCountModel.outreachEffort = 3;
        this.postCountModel.state = this.fluOppOutreachstatusForm.controls['state'].value;
        this._fluOpportunitiesContactStatusService.getTotalRecordsCountForFluOppContactStatusReport(this.postCountModel).subscribe((res: any) => {
          
          this.totalRecordsCount = +res;
          this.noOfCallsNeeded = Math.ceil(this.totalRecordsCount / 50000);
          this.countCall = false;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.showDialog("Error", ErrorMessages["error"], true);
        });
        
        });
        break;
    }   
  }

  onDistrictStateChange(value, selected_value) {
    
    switch (value) {
      case 'District':
        this.spinner.show();
        // get the count of records
        this.countCall = true;
        this.postCountModel.areaNumber = +this.fluOppOutreachstatusForm.controls['areaNumber'].value;
        this.postCountModel.regionNumber = +this.fluOppOutreachstatusForm.controls['regionNumber'].value;
        this.postCountModel.districtNumber = +selected_value
        this.postCountModel.outreachEffort = 3;
        this.postCountModel.state = this.fluOppOutreachstatusForm.controls['state'].value;
        this._fluOpportunitiesContactStatusService.getTotalRecordsCountForFluOppContactStatusReport(this.postCountModel).subscribe((res: any) => {
          
          this.totalRecordsCount = +res;
          this.noOfCallsNeeded = Math.ceil(this.totalRecordsCount / 50000);
          this.countCall = false;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.showDialog("Error", ErrorMessages["error"], true);
        
        });
        break;
        case 'State':
          this.spinner.show();
          // get the count of records
          this.countCall = true;
          this.postCountModel.areaNumber = +this.fluOppOutreachstatusForm.controls['areaNumber'].value;
          this.postCountModel.regionNumber = +this.fluOppOutreachstatusForm.controls['regionNumber'].value;
          this.postCountModel.districtNumber = +this.fluOppOutreachstatusForm.controls['districtNumber'].value;
          this.postCountModel.outreachEffort = 3;
          this.postCountModel.state = selected_value;
          this._fluOpportunitiesContactStatusService.getTotalRecordsCountForFluOppContactStatusReport(this.postCountModel).subscribe((res: any) => {
            
            this.totalRecordsCount = +res;
            this.noOfCallsNeeded = Math.ceil(this.totalRecordsCount / 50000);
            this.countCall = false;
            this.spinner.hide();
          }, error => {
            this.spinner.hide();
            this.showDialog("Error", ErrorMessages["error"], true);
          
          });
          break;

      default:
        this.spinner.show();
        // get the count of records
        this.countCall = true;
        this.postCountModel.areaNumber = +this.fluOppOutreachstatusForm.controls['areaNumber'].value;
        this.postCountModel.regionNumber = +this.fluOppOutreachstatusForm.controls['regionNumber'].value;
        this.postCountModel.districtNumber = +this.fluOppOutreachstatusForm.controls['districtNumber'].value
        this.postCountModel.outreachEffort = 3;
        this.postCountModel.state = this.fluOppOutreachstatusForm.controls['state'].value;
        this._fluOpportunitiesContactStatusService.getTotalRecordsCountForFluOppContactStatusReport(this.postCountModel).subscribe((res: any) => {
          
          this.totalRecordsCount = +res;
          this.noOfCallsNeeded = Math.ceil(this.totalRecordsCount / 50000);
          this.countCall = false;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.showDialog("Error", ErrorMessages["error"], true);
        });
        
        this.areaList = [];
        this.districtList = [];
        this.coClinicdetailsService.getAreaDist(value, 0).subscribe(data => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.regionList.push({ key: keyValue, value: valueData });
          }
        });
        break;
    }
  }

}
