export const adminUserNames:string[] = [
    "admin_communityupdate", 
    "admin_samuel.nass", 
    "admin_jerry.v.thomas", 
    "admin_bryan.shrode",
    "admin_enterpriseimmunizationservice",
    "admin_carlos.flores",
    "admin_eli.zaguri",
    "admin_tyrone.clark",
    "admin_roxanne.crowley"
  ]