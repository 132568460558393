export class ScheduledAppointment {
  userRole: string = "";
  userId: number;
  clientId: number;
  clinicPk: number = 0;
  apptType: number;
  room:string=null;
  clinicDate:string=null;
  outputType: string = null;
  reportName: string = null;
  regionNumber:number = -1;
  areaNumber:number = -1;
  districtNumber:number = -1
  constructor(user_role: string, user_pk: number, client_pk: number, clinic_pk: number, appts_type: number,
    regionNumber:number , areaNumber:number , districtNumber:number,
    appt_room?:string,appt_date?:string, output_type?: string, report_name?: string,
     ) {
    this.userRole = user_role;
    this.userId = user_pk;
    this.clientId = client_pk;
    this.clinicPk = clinic_pk;
    this.apptType = appts_type;
    this.room=appt_room;
    this.clinicDate=appt_date;
    this.outputType = output_type;
    this.reportName = report_name;
    this.regionNumber = regionNumber;
    this.areaNumber = areaNumber;
    this.districtNumber = districtNumber;
  }
}